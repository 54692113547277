<template>
    <div>
        <div class="my-box">
            <el-form ref="formRef" :model="form" :rules="rules" label-width="140px" :disabled="isDisabled">
                <el-form-item label="规则名称：" prop="rule_name">
                    <el-input v-model="form.rule_name" clearable maxlength="50" show-word-limit />
                </el-form-item>
                <el-form-item label-width="0px">
                    <div v-if="flag == 1 || flag == 2">
                        <el-form-item label="邀请收益：" prop="content.data" :rules="rules.content_data">
                            <tablePlayInvite :tabletitle="invite_tabletitle" :tabledata="form.content.data">
                                <template #contentShop="{ scope }">
                                    <div v-for="(ktem, kndex) in scope.row['shop_invite']" :key="kndex" class="flex-box-line">
                                        <el-button v-if="kndex == 0" type="primary" size="mini" @click="addEvent('shop_invite', form.content.data[scope.$index])">添加</el-button>
                                        <el-button v-else type="danger" size="mini" @click="delEvent('shop_invite', form.content.data[scope.$index], kndex)">删除</el-button>
                                    </div>
                                </template>
                                <template #contentUser="{ scope }">
                                    <div v-for="(ktem, kndex) in scope.row['user_buy']" :key="kndex" class="flex-box-line">
                                        <el-button v-if="kndex == 0" type="primary" size="mini" @click="addEvent('user_buy', form.content.data[scope.$index])">添加</el-button>
                                        <el-button v-else type="danger" size="mini" @click="delEvent('user_buy', form.content.data[scope.$index], kndex)">删除</el-button>
                                    </div>
                                </template>
                                <template #contentInput="{ scope, prop1, prop2 }">
                                    <div v-for="(ktem, kndex) in scope.row[prop1]" :key="kndex" class="flex-box-line">
                                        <div v-if="prop2 == 'invite_num'">
                                            <el-form-item label-width="0px" :prop="'content.data.' + scope.$index + '.' + prop1 + '.' + kndex + '.' + prop2" :rules="rules[prop2]">
                                                <div class="invite-time-box" style="gap: 5px;">
                                                    <div style="width: 45px"><span>>=</span></div>
                                                    <div><el-input v-model.number="form.content.data[scope.$index][prop1][kndex][prop2]" clearable maxlength="20"></el-input></div>
                                                </div>
                                            </el-form-item>
                                        </div>
                                        <div v-else-if="prop2 == 'invite_income'">
                                            <el-form-item label-width="0px" :prop="'content.data.' + scope.$index + '.' + prop1 + '.' + kndex + '.' + prop2" :rules="rules[prop2]">
                                                <el-input v-model="form.content.data[scope.$index][prop1][kndex][prop2]" clearable maxlength="20"></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </template>
                            </tablePlayInvite>
                        </el-form-item>
                        <el-form-item label="收益发放时间：" prop="content.valid_time" :rules="rules.content_valid_time" style="margin-top: 20px">
                            <div class="invite-time-box">
                                <div><span>邀请成功</span></div>
                                <div><el-input v-model.number="form.content.valid_time" placeholder="天数" clearable maxlength="20" /></div>
                                <div><span>天后</span></div>
                            </div>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-form-item label="商家券：" prop="content.data" :rules="rules.content_data">
                            <tablePlayInvite :tabletitle="shop_tabletitle" :tabledata="form.content.data">
                                <template #contentShop="{ scope }">
                                    <div v-for="(ktem, kndex) in scope.row['shop_invite']" :key="kndex" class="flex-box-line">
                                        <el-button
                                            v-if="scope.row['shop_invite'].length > 0"
                                            type="danger"
                                            size="mini"
                                            @click="delEvent('shop_invite', form.content.data[scope.$index], kndex)"
                                            >删除</el-button
                                        >
                                    </div>
                                    <div class="flex-box-line-button">
                                        <modelBindCommon
                                            :ModelValue="form.content.data[scope.$index]['shop_invite']"
                                            :DialogTan="true"
                                            PostUrl="/admin/Coupon/index"
                                            PostAxios="post"
                                            :PostDatas="{ type: 5, status: 1 }"
                                            :SearchModel="agent_forminfo"
                                            :TableTitle="coupon_tabletitle"
                                            :ButtonSet="{ text: '选择商家券', size: 'small', plain: true }"
                                            TanTitle="选择商家券"
                                            @modelValue="(data) => modelValueEvent(data, 'shop_invite', scope.$index)"
                                        />
                                    </div>
                                </template>
                                <template #contentUser="{ scope }">
                                    <div v-for="(ktem, kndex) in scope.row['user_buy']" :key="kndex" class="flex-box-line">
                                        <el-button
                                            v-if="scope.row['user_buy'].length > 0"
                                            type="danger"
                                            size="mini"
                                            @click="delEvent('user_buy', form.content.data[scope.$index], kndex)"
                                            >删除</el-button
                                        >
                                    </div>
                                    <div class="flex-box-line-button">
                                        <modelBindCommon
                                            :ModelValue="form.content.data[scope.$index]['user_buy']"
                                            :DialogTan="true"
                                            PostUrl="/admin/Coupon/index"
                                            PostAxios="post"
                                            :PostDatas="{ type: 5, status: 1 }"
                                            :SearchModel="agent_forminfo"
                                            :TableTitle="coupon_tabletitle"
                                            :ButtonSet="{ text: '选择商家券', size: 'small', plain: true }"
                                            TanTitle="选择商家券"
                                            @modelValue="(data) => modelValueEvent(data, 'user_buy', scope.$index)"
                                        />
                                    </div>
                                </template>
                                <template #contentInput="{ scope, prop1, prop2 }">
                                    <div v-if="form.content.data[scope.$index][prop1].length > 0">
                                        <div v-for="(ktem, kndex) in scope.row[prop1]" :key="kndex" class="flex-box-line">
                                            <div v-if="prop2 == 'title'">
                                                <span>{{ form.content.data[scope.$index][prop1][kndex][prop2] }}</span>
                                            </div>
                                            <div v-else-if="prop2 == 'num'">
                                                <el-form-item
                                                    label-width="0px"
                                                    :prop="'content.data.' + scope.$index + '.' + prop1 + '.' + kndex + '.' + prop2"
                                                    :rules="rules[prop2]"
                                                >
                                                    <el-input v-model.number="form.content.data[scope.$index][prop1][kndex][prop2]" clearable maxlength="20"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>
                                        <div class="flex-box-line-button"></div>
                                    </div>
                                </template>
                            </tablePlayInvite>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="状态：" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio label="1">开启</el-radio>
                        <el-radio label="-1">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item> <el-button type="primary" style="width: 200px" @click="submitEvent('formRef')" :disabled="button_disable">保存</el-button></el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import tablePlayInvite from './tablePlayInvite.vue'
import modelBindCommon from './modelBindCommon.vue'

export default defineComponent({
    name: 'inviteToBalanceOrCoupon',
    inject: ['reload'], //刷新引用
    props: {
        flag: {
            type: Number,
            default: 1
        },
        formData: {
            type: Object,
            default() {
                return {
                    type: '',
                    rule_name: '',
                    content: {},
                    status: ''
                }
            }
        },
        isDisabled: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    watch: {
        formData: {
            handler(newVal) {
                this.form = this.deepcopy(newVal)
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            // 邀请送余额-邀请收益-tabletitle
            invite_tabletitle: [
                {
                    prop: 'name',
                    label: '会员等级',
                    width: '200'
                },
                {
                    prop: 'shop_invite',
                    label: '商家邀请',
                    width: '',
                    children: [
                        {
                            prop: 'invite_num',
                            label: '邀请人数(人)',
                            width: ''
                        },
                        {
                            prop: 'invite_income',
                            label: '邀请收益(元/人)',
                            width: ''
                        },
                        {
                            prop: 'shop_paly_option',
                            label: '操作',
                            width: '80'
                        }
                    ]
                },
                {
                    prop: 'user_buy',
                    label: '用户自购',
                    width: '',
                    children: [
                        {
                            prop: 'invite_num',
                            label: '邀请人数(人)',
                            width: ''
                        },
                        {
                            prop: 'invite_income',
                            label: '邀请收益(元/人)',
                            width: ''
                        },
                        {
                            prop: 'user_paly_option',
                            label: '操作',
                            width: '80'
                        }
                    ]
                }
            ],
            // 邀请送商家券-商家券-tabletitle
            shop_tabletitle: [
                {
                    prop: 'name',
                    label: '会员等级',
                    width: '200'
                },
                {
                    prop: 'shop_invite',
                    label: '商家邀请',
                    width: '',
                    children: [
                        {
                            prop: 'title',
                            label: '商家券',
                            width: ''
                        },
                        {
                            prop: 'num',
                            label: '数量',
                            width: ''
                        },
                        {
                            prop: 'shop_paly_option',
                            label: '操作',
                            width: '120'
                        }
                    ]
                },
                {
                    prop: 'user_buy',
                    label: '用户自购',
                    width: '',
                    children: [
                        {
                            prop: 'title',
                            label: '商家券',
                            width: ''
                        },
                        {
                            prop: 'num',
                            label: '数量',
                            width: ''
                        },
                        {
                            prop: 'user_paly_option',
                            label: '操作',
                            width: '120'
                        }
                    ]
                }
            ],
            form: {
                type: '',
                rule_name: '',
                content: {},
                status: ''
            },
            rules: {
                rule_name: [
                    {
                        required: true,
                        message: '规则名称为必填项',
                        trigger: 'blur'
                    }
                ],
                content_data: [
                    {
                        required: true,
                        message: '必填项不能为空',
                        trigger: 'blur'
                    }
                ],
                content_valid_time: [
                    {
                        required: true,
                        message: '收益发放时间为必填项',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/ 或 /^(([1-9]{1}\d*)|(0{1}))?$/
                        pattern: /^(?:([1-9]\d{0,2})|(0{1})|1000)$/,
                        message: '0<=正整数<=1000',
                        trigger: ['blur', 'change']
                    }
                ],
                invite_num: [
                    {
                        required: true,
                        message: '必填项不能为空',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/ 或 /^(([1-9]{1}\d*)|(0{1}))?$/
                        pattern: /^(?:([1-9]\d{0,2})|(0{1})|1000)$/,
                        message: '0<=正整数<=1000',
                        trigger: ['blur', 'change']
                    }
                ],
                invite_income: [
                    {
                        required: true,
                        message: '必填项不能为空',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                        // 正则表达式(0<=正数或保留两位小数的小数<=1000)：/^(\d{1,3}(\.\d{1,2})?|1000(\.00)?)$/
                        pattern: /^(\d{1,3}(\.\d{1,2})?|1000(\.00)?)$/,
                        message: '0<=正数或保留两位小数的小数<=1000',
                        trigger: ['blur', 'change']
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态为必选项',
                        trigger: 'blur'
                    }
                ],
                num: [
                    {
                        required: true,
                        message: '必填项不能为空',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/ 或 /^(([1-9]{1}\d*)|(0{1}))?$/
                        pattern: /^(?:([1-9]\d{0,2})|(0{1})|1000)$/,
                        message: '0<=正整数<=1000',
                        trigger: ['blur', 'change']
                    }
                ]
            },

            coupon_tabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'title',
                    label: '商家券名称',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status_name',
                    label: '商家券状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'money_name',
                    label: '面额',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'limits_name',
                    label: '使用门槛',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'use_time',
                    label: '有效时间',
                    width: ''
                }
            ],
            agent_forminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '商家券名称',
                            name: 'title',
                            content: '',
                            class: 'input-mydemo'
                        }
                    ]
                }
            },

            // 前端防抖
            button_disable: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')) //获取权限
        }
    },
    methods: {
        // 添加
        addEvent(flag, rows) {
            let add_data = {
                invite_num: '',
                invite_income: ''
            }
            rows[flag].push(add_data)
        },
        // 删除
        delEvent(flag, rows, index) {
            rows[flag].splice(index, 1)
        },
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },

        // 选择商家券-多选
        modelValueEvent(data, flag, index) {
            this.form.content.data[index][flag] = data.map((n) => {
                return {
                    ...n,
                    coupon_id: n.id,
                    title: n.title,
                    num: n.num ? n.num : 1
                }
            })
        },

        // 数据过滤：[coupon_id，name，num]
        filterDataEvent(data) {
            return data.map((n) => {
                return {
                    id: n.id,
                    coupon_id: n.id,
                    title: n.title,
                    num: n.num
                }
            })
        },
        // 校验-数据结构&异常抛出
        isDetermine(getdatas) {
            if (getdatas.content.data.length != 0) {
                getdatas.content.data.map((n) => {
                    if (!n.shop_invite || n.shop_invite.length == 0 || !n.user_buy || n.user_buy.length == 0) {
                        setTimeout(() => {
                            ElMessage({
                                message: '商家券为必填项，不能有空值',
                                type: 'error'
                            })
                        }, 1)
                        throw new Error('')
                    } else {
                        n.shop_invite = this.filterDataEvent(this.deepcopy(n.shop_invite))
                        n.user_buy = this.filterDataEvent(this.deepcopy(n.user_buy))
                        return n
                    }
                })
            }
            return getdatas
        },
        // 提交-保存
        submitEvent(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let save_getdatas = getdatas
                    // 校验-当type=2时，商家券相关的值不能为空
                    if (getdatas['type'] == 2) {
                        getdatas = this.deepcopy(this.isDetermine(getdatas))
                    }

                    this.button_disable = true
                    axios
                        .post('/admin/vipRule/add', getdatas, {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.button_disable = false
                                        this.reload()
                                    }
                                })
                            } else {
                                this.button_disable = false
                            }
                        })
                        .catch(function (error) {
                            this.button_disable = false
                        })
                } else {
                    return false
                }
            })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            let routedatas = this.$route.query
            if (routedatas.id) {
                // 编辑-规则设置
                axios
                    .post(
                        '/admin/config/info',
                        {
                            id: 'vip_show_style'
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        if (response.data.result) {
                            this.form = response.data.result
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            } else {
                // 添加
                this.form['content'] = []
            }
        }
    },
    components: {
        tablePlayInvite,
        modelBindCommon
    }
})
</script>
<style scoped>
.my-box {
    background-color: white;
    border-radius: 4px;
    padding: 30px 20px;
}
.invite-time-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
}
.flex-box-line {
    height: 60px;
}
.flex-box-line-button {
    height: 40px;
}
</style>
