<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--添加XX按钮-点击跳转添加类页面-->
                        <el-button type="primary" plain class="max_search" @click="tanChangePidEvent">批量修改负责人</el-button>

                        <el-button type="primary" plain class="max_search" @click="routeraddrClick('/agentlistadd')" v-if="mypowerlimits.dlslb_tj">添加</el-button>
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="this.realtabledata"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @select-all="handleSelectionChange"
                        @select="handleSelectionChange"
                        @cell-click="handleOneData"
                        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                        row-key="id"
                        :expand-row-keys="this.expands"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" :selectable="selectableEvent" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="mytabletitle.prop === 'status' || mytabletitle.prop === 'name' || mytabletitle.prop === 'level' || mytabletitle.prop === 'user_type'"
                            >
                                <!--代理商名称-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'name'">
                                    <div class="mytabletext" @click="routeraddrClick('/agentlistinfo', scope.row)">
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                </div>
                                <!--代理等级-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'level'">
                                    <div v-if="scope.row.level == 1" style="cursor: pointer">
                                        <span style="color: #465eff">一级代理 <i class="el-icon-loading" v-if="scope.row.isshowloading"></i></span>
                                    </div>
                                    <div v-if="scope.row.level == 2" style="cursor: pointer">
                                        <span style="color: #f56c6c">二级代理 <i class="el-icon-loading" v-if="scope.row.isshowloading"></i></span>
                                    </div>
                                    <div v-if="scope.row.level == 3">
                                        <span style="color: #333333">三级代理 </span>
                                    </div>
                                </div>
                                <!--状态-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div id="switch" v-if="scope.row.status != '-2'">
                                        <el-switch
                                            v-model="scope.row.status"
                                            active-text="开启"
                                            inactive-text="关闭"
                                            :active-value="1"
                                            :inactive-value="-1"
                                            active-color="#13ce66"
                                            @click="changeStatus(scope.row)"
                                            inactive-color="#cbcbcb"
                                        >
                                        </el-switch>
                                    </div>
                                    <div v-else>
                                        <span style="color: red">已删除</span>
                                    </div>
                                </div>
                                <!-- 类型 -->
                                <div v-if="mytabletitle.prop === 'user_type'">
                                    <span>{{ scope.row.user_type ? this.CSP_type.filter((n) => n.value == scope.row.user_type)[0]['label'] : '' }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-代理商列表-->
                                <div v-if="mainthreetableope === 1">
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/agentlistedit', scope.row)" v-if="mypowerlimits.dlslb_bj">编辑</el-button>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/dvclist', scope.row)" v-if="scope.row.status != '-2'">设备</el-button>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="addTanClick(scope.row, 1, (dialogVisible1 = true))"
                                        v-if="mypowerlimits.dlslb_tcpt && scope.row.status != '-2'"
                                        >退出平台
                                    </el-button>
                                    <!-- <el-button type="primary" size="mini" v-if="scope.row.level != 1 && mypowerlimits.dlslb_sjyd && scope.row.status != '-2'"  @click="addTanClick(scope.row, (dialogVisible = true))">
                                        升级为一代
                                    </el-button> -->
                                    <el-button type="primary" size="mini" @click="doLogin(scope.row)" v-if="mypowerlimits.dlslb_dl && scope.row.status != '-2'">登录 </el-button>
                                    <el-button type="success" size="mini" @click="huifuEvent(scope.row)" v-if="mypowerlimits.dlslb_schf && scope.row.status == '-2'"
                                        >恢复</el-button
                                    >
                                    <el-button type="danger" size="mini" @click="delGuanxiEvent(scope.row)" v-if="mypowerlimits.dlslb_sc && scope.row.status != '-2'"
                                        >删除</el-button
                                    >
                                    <el-button type="primary" size="mini" @click="tanHockMoney(scope.row)" v-if="mypowerlimits.dlslb_yjqx">押金 </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!--弹出框-升级一代-->
            <el-dialog v-model="dialogVisible" title="升级一代" :width="this.searchwidth" :before-close="handleCloseSenior">
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                    <div class="change-one-list">
                        <el-form-item label="佣金比例：" prop="ratio">
                            <el-input v-model="ruleForm.ratio" type="number" max="1" min="0" step="0.1" maxlength="3" clearable> </el-input>
                        </el-form-item>
                        <el-form-item>
                            <div>
                                <span style="color: red">注意：升级为一代，升级将不可逆转</span>
                            </div>
                            <div>
                                <span style="color: red">如该代理下有商户需调整佣金比例，请单独调整</span>
                            </div>
                        </el-form-item>
                        <div style="text-align: right">
                            <el-button @click="resetForm('ruleForm')">取消</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
                        </div>
                    </div>
                </el-form>
            </el-dialog>

            <!-- 弹出框-退出平台 -->
            <el-dialog v-model="dialogVisible1" title="退出平台" :width="this.searchwidth" :before-close="handleCloseSenior">
                <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="150px" class="demo-ruleForm">
                    <div class="dialog-list">
                        <div style="margin-bottom: 20px">
                            <el-alert title="注意：操作不可逆，请看清楚再操作！！！" type="error" effect="dark" :closable="false" />
                        </div>
                        <el-form-item label="未提现余额：">
                            <div>￥{{ ruleForm1.apply_money }}</div>
                        </el-form-item>
                        <el-form-item label="设备数：">
                            <div>直属{{ ruleForm1.use_client_num }}台，下级{{ ruleForm1.bottom_client_num }}台</div>
                        </el-form-item>
                        <el-form-item label="选择处理方式：" prop="type">
                            <div>
                                <el-radio-group v-model="ruleForm1.type">
                                    <el-radio :label="1">仅清空提现账户</el-radio>
                                    <el-radio :label="2">清空提现账户，解绑设备，设备返回上级</el-radio>
                                </el-radio-group>
                            </div>
                        </el-form-item>
                        <el-form-item label="状态：" prop="status">
                            <div>
                                <el-radio-group v-model="ruleForm1.status">
                                    <el-radio :label="1">启用</el-radio>
                                    <el-radio :label="-1">禁用</el-radio>
                                </el-radio-group>
                            </div>
                        </el-form-item>
                        <div style="text-align: right">
                            <el-button @click="resetFormGoout('ruleForm1')">取消</el-button>
                            <el-button type="primary" @click="submitFormGoout('ruleForm1')" :disabled="mydisabled">确认</el-button>
                        </div>
                    </div>
                </el-form>
            </el-dialog>

            <!-- 批量修改负责人 -->
            <el-dialog v-model="dialogVisible2" title="批量修改负责人" :width="this.searchwidth" :before-close="handleCloseSeniorChangePid">
                <el-form ref="ruleForm2" :model="ruleForm2" :rules="rules2" label-width="150px" class="demo-ruleForm">
                    <div class="dialog-list">
                        <el-form-item label="负责人：" prop="pid">
                            <div>
                                <treeCascaderBox
                                    :ModelValue="ruleForm2.pid"
                                    :Placeholder="'请选择负责人'"
                                    :Options="pid_options"
                                    :Props="pid_options_props"
                                    @changedatas="changedatasEvent"
                                />
                            </div>
                        </el-form-item>
                        <div style="text-align: right">
                            <el-button @click="resetFormChangePid('ruleForm2')">取消</el-button>
                            <el-button type="primary" @click="submitFormChangePid('ruleForm2')" :disabled="mydisabled">确认</el-button>
                        </div>
                    </div>
                </el-form>
            </el-dialog>

            <!-- 押金 -->
            <el-dialog v-model="dialogVisible3" title="押金" :width="this.searchwidth" :before-close="handleCloseSeniorChangeHock">
                <div v-loading="loading_hock" element-loading-text="正在飞速加载中...">
                    <el-form ref="ruleForm3" :model="ruleForm3" :rules="rules3" label-width="150px" class="demo-ruleForm">
                        <div class="dialog-list">
                            <el-form-item label="有无押金权限：" prop="is_hock">
                                <el-radio-group v-model="ruleForm3.is_hock">
                                    <el-radio :label="1">有</el-radio>
                                    <el-radio :label="-1">无</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="押金金额：" prop="hock_money">
                                <el-radio-group v-model="ruleForm3.hock_money">
                                    <el-radio :label="item.label" v-for="(item, index) in hock_options" :key="index">{{ item.value }}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <div style="text-align: right">
                                <el-button @click="resetFormChangeHock('ruleForm3')">取消</el-button>
                                <el-button type="primary" @click="submitFormChangeHock('ruleForm3')" :disabled="mydisabled_hock">确认</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import CPS from '@/assets/js/componentProperties' // 公共数据

export default defineComponent({
    name: 'vagentlist',
    inject: ['reload'], //刷新引用
    data() {
        // 升级一代弹窗-佣金比例-number校验
        var checkBigDay = (rule, value, callback) => {
            //校验判断
            if (!value) {
                return callback(new Error('佣金比例不能为空'))
            }
            setTimeout(() => {
                // 当输入为整数的判断
                if (!Number.parseFloat(value)) {
                    callback(new Error('请输入小数'))
                } else {
                    if (value > 1) {
                        callback(new Error('值需小于等于1'))
                    } else {
                        callback()
                    }
                }
            }, 1000)
        }
        return {
            mainonebread: [
                {
                    name: '合作伙伴'
                },
                {
                    name: '代理商管理'
                },
                {
                    name: '代理商列表'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: true, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '160px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '负责人：',
                            placeholder: '请输入负责人', //此name为键值
                            name: 'win_user_id',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Statistics/userList',
                            posttype: 'get',
                            inputval: { truename: '' } //这里记录需要传的参数
                        }
                    ]
                },
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '170px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '代理商名称/手机号',
                            name: 'keywords',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '代理商ID：',
                            placeholder: '请输入代理商ID',
                            name: 'shop_id',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '100px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '类型：',
                            placeholder: '全部',
                            name: 'user_type',
                            value: '',
                            options: [
                                ...[
                                    {
                                        value: '0',
                                        label: '全部'
                                    }
                                ],
                                ...CPS.get('winuserinfo').type
                            ]
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '代理等级：',
                            placeholder: '全部',
                            name: 'level',
                            value: '',
                            options: [
                                {
                                    value: '0',
                                    label: '全部'
                                },
                                {
                                    value: '1',
                                    label: '一级代理'
                                },
                                {
                                    value: '2',
                                    label: '二级代理'
                                },
                                {
                                    value: '3',
                                    label: '三级代理'
                                }
                            ]
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    value: '0',
                                    label: '全部'
                                },
                                {
                                    value: '-1',
                                    label: '禁用'
                                },
                                {
                                    value: '1',
                                    label: '启用'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowset: true, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 370, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'id',
                    label: 'ID',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '代理商名称',
                    width: '300'
                },
                {
                    fixedstatu: false,
                    prop: 'user_type',
                    label: '类型',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'win_user_id',
                    label: '负责人',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号/账号',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'level',
                    label: '代理等级',
                    width: '110'
                },
                {
                    fixedstatu: false,
                    prop: 'ratio',
                    label: '佣金比例',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'province',
                    label: '省级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'city',
                    label: '市级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'county',
                    label: '县/区级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '添加时间',
                    width: '120'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //高级筛选
            myinputishow: true, //高级筛选-input单框
            myinput: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%',
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '输入搜索：',
                        placeholder: '代理商名称/手机号',
                        name: 'keywords',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '代理商ID：',
                        placeholder: '请输入代理商ID',
                        name: 'shop_id',
                        content: ''
                    }
                ]
            },
            myselectisshow: true, //高级筛选-select单框
            myselect: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '类型：',
                        placeholder: '全部',
                        name: 'user_type',
                        value: '',
                        options: [
                            ...[
                                {
                                    value: '0',
                                    label: '全部'
                                }
                            ],
                            ...CPS.get('winuserinfo').type
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '代理等级：',
                        placeholder: '全部',
                        name: 'level',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '一级代理'
                            },
                            {
                                value: '2',
                                label: '二级代理'
                            },
                            {
                                value: '3',
                                label: '三级代理'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '状态:',
                        placeholder: '全部',
                        name: 'status',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '-1',
                                label: '禁用'
                            },
                            {
                                value: '1',
                                label: '启用'
                            }
                        ]
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '添加时间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'addtime',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },
            myselectcityisshow: true, //高级筛选-selectcity选框
            myselectcity: {
                selectwidth: '100%',
                select: {
                    ismust: false,
                    name: 'area_code',
                    valuesheng: '',
                    valueshi: '',
                    valuequ: '',
                    disshi: true,
                    disqu: true
                }
            },
            myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
            myinputonefullgao: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%', //input宽度
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '负责人：',
                        placeholder: '请输入负责人', //此name为键值
                        name: 'win_user_id',
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/admin/Statistics/userList',
                        posttype: 'get',
                        inputval: { truename: '' } //这里记录需要传的参数
                    }
                ]
            },
            // 负责人类型
            CSP_type: CPS.get('winuserinfo').type,

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100, 1000],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',

            // 要展开的行，数值的元素是row的key值
            expands: [],

            //【升级一代】弹窗相关数据
            dialogVisible: ref(false), //弹窗显示&隐藏
            ruleForm: {
                id: '', //当前行代理商id
                ratio: '' //当前行佣金比例
            },
            //【升级一代】弹窗中-form表单-必填规则
            rules: {
                ratio: [
                    {
                        required: true,
                        validator: checkBigDay,
                        trigger: 'change'
                    }
                ]
            },

            //【退出平台】弹窗相关数据
            dialogVisible1: ref(false), //弹窗显示&隐藏
            ruleForm1: {
                id: '',
                apply_money: '',
                level: '',
                grandpa_id: '',
                parent_id: '',
                use_client_num: '',
                bottom_client_num: '',
                type: 1,
                status: 1
            },
            //【退出平台】弹窗中-form表单-必填规则
            rules1: {
                type: [
                    {
                        required: true,
                        message: '处理方式为必选项',
                        trigger: 'change'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态为必选项',
                        trigger: 'change'
                    }
                ]
            },

            // 【批量修改负责人】
            dialogVisible2: ref(false),
            ruleForm2: {
                pid: ''
            },
            rules2: {
                pid: [
                    {
                        required: true,
                        message: '负责人不能为空',
                        trigger: 'change'
                    }
                ]
            },
            pid_options_props: { value: 'id', label: 'truename', children: 'child', checkStrictly: true },
            pid_options: [], // 直属上级id下拉
            changePids: [], //多选选中的代理商ids

            // 【押金相关】
            dialogVisible3: ref(false),
            ruleForm3: {
                id: '', // 代理商id
                is_hock: ref(-1),
                hock_money: 0
            },
            rules3: {
                is_hock: [
                    {
                        required: true,
                        message: '有无押金权限为必选项',
                        trigger: 'change'
                    }
                ],
                hock_money: [
                    {
                        required: false,
                        message: '押金金额为必选项',
                        trigger: 'change'
                    }
                ]
            },
            hock_options: [],
            loading_hock: false,
            mydisabled_hock: false,

            // table表单的数据
            // 由于table表单与分页结合，导致点击单选框或者其余弹窗事件时，多选框状态会被清空！
            // 因此将双向绑定的动态数据重新赋值给realtabledata做数据断离层，即可解决选中状态被清空问题！！！
            realtabledata: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            mydisabled: false // 防抖-前端维护
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 【押金】弹窗
        tanHockMoney(data) {
            this.dialogVisible3 = ref(true)
            this.loading_hock = true
            // 获取代理商详情
            var p1 = axios
                .post(
                    '/admin/shopUser/info',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let datas = response.data.result.info
                    this.ruleForm3 = {
                        id: data.id,
                        is_hock: datas.is_hock,
                        hock_money: datas.hock_money
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })

            Promise.all([p1])
                .then(() => {
                    this.loading_hock = false
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                })
        },
        // 【押金】押金金额-配置
        getHockOptions() {
            axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'agent_deposit'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    // console.log(response.data.result)
                    let options = response.data.result
                    let keys = Object.keys(options)
                    this.hock_options = keys.map((n) => {
                        let label = n
                        let value = options[n]
                        return { label: parseInt(label), value: value }
                    })
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 【押金】提交
        submitFormChangeHock(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.mydisabled_hock = true
                    axios
                        .post(
                            '/admin/shopUser/upHock',
                            {
                                id: getdatas.id,
                                is_hock: getdatas.is_hock,
                                hock_money: getdatas.hock_money
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.resetFormChangeHock()
                                        this.multipleSelection = []
                                        this.handleCurrentChange(this.currentPage) // 保留当前筛选条件与页数-重新查询数据
                                        this.mydisabled_hock = false
                                    }
                                })
                            }else{
                                this.mydisabled_hock = false
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            this.mydisabled_hock = false
                        })
                } else {
                    return false
                }
            })
        },
        // 【押金】取消
        resetFormChangeHock() {
            this.dialogVisible3 = ref(false)
            this.$refs['ruleForm3'].resetFields()
        },
        handleCloseSeniorChangeHock() {
            this.resetFormChangeHock()
        },

        // 【批量修改负责人】多选-禁用二三级代理
        selectableEvent(row) {
            if (row.level != 1) {
                return false
            } else {
                return true
            }
        },
        //【批量修改负责人】选择上级
        changedatasEvent(data) {
            this.ruleForm2.pid = data
        },
        // 【批量修改负责人】弹窗
        tanChangePidEvent() {
            if (this.multipleSelection.length > 0) {
                this.changePids = this.multipleSelection.map((n) => {
                    return n.id
                })
                this.dialogVisible2 = true
            } else {
                ElMessage({
                    type: 'error',
                    message: '请至少选择一个代理商，再批量修改负责人！'
                })
            }
        },
        // 【批量修改负责人】获取下拉
        finduserPidOptions() {
            axios
                .post(
                    '/admin/winUser/list',
                    {},
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.pid_options = response.data.result
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 【批量修改负责人】确定
        submitFormChangePid(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 前端防抖
                    this.mydisabled = true
                    axios
                        .post(
                            '/admin/shopUser/allotWinUser',
                            {
                                id: this.changePids,
                                win_user_id: this.ruleForm2.pid.pop()
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.resetFormChangePid()
                                        this.multipleSelection = []
                                        this.handleCurrentChange(this.currentPage) // 保留当前筛选条件与页数-重新查询数据
                                        this.mydisabled = false
                                    }
                                })
                            }else{
                                this.mydisabled = false
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    return false
                }
            })
        },
        // 【批量修改负责人】取消
        resetFormChangePid() {
            this.dialogVisible2 = false
            this.ruleForm2.pid = []
        },
        handleCloseSeniorChangePid() {
            this.resetFormChangePid()
        },
        // 恢复
        huifuEvent(data) {
            axios
                .post(
                    '/admin/shopUser/deleteRestore',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message,
                            duration: 1000,
                            onClose: () => {
                                this.handleCurrentChange(this.currentPage) // 保留当前筛选条件与页数-重新查询数据
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 删除
        delGuanxiEvent(data) {
            ElMessageBox.confirm('确定删除 ' + data.name + ' 么？', '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/shopUser/delete',
                            {
                                id: data.id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage) // 保留当前筛选条件与页数-重新查询数据
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除'
                    })
                    this.mydisabled = false
                })
        },
        // table列表-登录按钮-点击跳转登录-商户后台
        doLogin(data) {
            axios
                .post(
                    '/admin/Login/gologin',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        let Authen = response.data.result.Authen

                        let username = response.data.result.username
                        let url = this.$suburl + '?username=' + username + '&passwd=' + Authen + '&model=modelLogin'

                        window.open(url, '_blank')
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // table表单-状态改变-status值通过后台返回
        changeStatus(data) {
            console.log('切换状态的data值：' + data.status)
            axios
                .post(
                    '/admin/shopUser/disable',
                    {
                        id: data.id,
                        status: data.status
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //table表单-点击单位格-带参跳转等
        handleOneData(row, column) {
            // 点击时候先删除之前存在的expands
            this.expands.splice(0, 1)
            // console.log(row); //获取当前行数据
            // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
            // console.log(event); //获取当前指针事件
            // console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用

            //获取所点击按钮的id值-并根据不同的id值进行跳转&传值等
            //   if (event.path[1].id) {
            //     //由操作-合作-公司列表-向-公司关联设备页面传值
            //     if (event.path[1].id === "companydvcid") {
            //       this.$router
            //         .push({
            //           path: "/companydvclist",
            //           query: { id: row.id, name: row.name },
            //         })
            //         .catch((error) => error);
            //     }
            //   }

            // 渲染-代理商相关子代理-展开
            if (column.property == 'level') {
                if (row.level < 3) {
                    // loading过渡特效-开启
                    row.isshowloading = true
                    axios
                        .post(
                            '/admin/shopUser/index',
                            {
                                parent_id: row.id,
                                level: parseInt(row.level) + 1,
                                page_size: 10000 // 由于该接口设置有默认条数，但本数据不需默认条数，需要全部数据，隐藏将page_size数量设置10000（超大），以防止子代理商漏的状况
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            var newdata = response.data.result.data
                            if (newdata != '') {
                                for (var i = 0; i < newdata.length; i++) {
                                    //该字段表示search按钮，且status为1表示启用，2表示禁用
                                    newdata[i].switchstatus = 4
                                    //将省市区分割
                                    var arrcity = newdata[i].area_code.split(',')
                                    newdata[i].province = arrcity[0]
                                    newdata[i].city = arrcity[1]
                                    newdata[i].county = arrcity[2]
                                    //转换添加时间
                                    if (newdata[i].addtime) {
                                        if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                                            newdata[i].addtime = '无添加时间'
                                        } else {
                                            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                                        }
                                    } else {
                                        newdata[i].addtime = '无添加时间'
                                    }
                                }
                                row.children = newdata

                                // 点击后-自动展开子代理
                                this.expands.splice(0, 1, row.id.toString())
                            } else {
                                ElMessage({
                                    type: 'error',
                                    message: row.level + '级代理商: ' + row.name + ' , 无下级代理'
                                })
                            }
                            // loading过渡特效-关闭
                            row.isshowloading = false
                        })
                        .catch(function (error) {
                            // 请求失败处理
                            console.log(error)
                        })
                }
            }
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            // console.log('this is:' , val,val.length)
            this.multipleSelection = val
        },
        // table表中-清除多选反显
        handleClearChangeEvent() {
            this.$refs.multipleTable.clearSelection()
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.pagesdatas.page_size = val
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shopUser/index',
                    {
                        keywords: this.pagesdatas.keywords,
                        level: this.pagesdatas.level,
                        status: this.pagesdatas.status,
                        area_code: this.pagesdatas.area_code,
                        addtime: this.pagesdatas.addtime,
                        shop_id: this.pagesdatas.shop_id,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.pagesdatas.page = val
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shopUser/index',
                    {
                        keywords: this.pagesdatas.keywords,
                        level: this.pagesdatas.level,
                        status: this.pagesdatas.status,
                        area_code: this.pagesdatas.area_code,
                        addtime: this.pagesdatas.addtime,
                        shop_id: this.pagesdatas.shop_id,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row'
            }
            return ''
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo, flag) {
            console.log(taninfo)
            // flag标识- 1退出平台；0升级一代
            if (flag == 1) {
                axios
                    .post(
                        'admin/shopUser/exitPlatformInfo',
                        {
                            shop_id: taninfo.id
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        console.log(response.data.result)
                        let getdatas = response.data.result
                        this.ruleForm1 = {
                            id: taninfo.id,
                            apply_money: getdatas.apply_money,
                            level: getdatas.level,
                            grandpa_id: getdatas.grandpa_id,
                            parent_id: getdatas.parent_id,
                            use_client_num: getdatas.use_client_num,
                            bottom_client_num: getdatas.bottom_client_num,
                            type: 1,
                            status: getdatas.status
                        }
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            } else {
                this.ruleForm.id = taninfo.id
                this.ruleForm.ratio = taninfo.ratio
            }
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 携带参数跳转
                if (routeraddr === '/agentlistedit123') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: rowdata
                        })
                        .catch((error) => error)
                } else {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.id, name: rowdata.name }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    '/admin/shopUser/index',
                    {
                        keywords: this.pagesdatas.keywords,
                        level: this.pagesdatas.level,
                        status: this.pagesdatas.status,
                        area_code: this.pagesdatas.area_code,
                        addtime: this.pagesdatas.addtime,
                        shop_id: this.pagesdatas.shop_id,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        export: 1
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        // 【升级一代】弹出框-提交表单
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 前端防抖
                    this.mydisabled = true

                    ElMessageBox.confirm('是否升级为一代，升级将不可逆转！！！', '提示：', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                            var getdatas = this.$refs[formName].model
                            axios
                                .post(
                                    '/admin/shopUser/toTop',
                                    {
                                        id: getdatas.id,
                                        ratio: getdatas.ratio
                                    },
                                    {
                                        headers: {
                                            Authentication: localStorage.getItem('token')
                                        }
                                    }
                                )
                                .then((response) => {
                                    if (response.data.code === 0) {
                                        this.dialogVisible = ref(false) //关闭弹窗
                                        ElMessage({
                                            type: 'success',
                                            message: '升级成功！',
                                            duration: 1000,
                                            onClose: () => {
                                                this.reload() //刷新
                                                this.mydisabled = false
                                            }
                                        })
                                    } else {
                                        this.mydisabled = false
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error)
                                    this.mydisabled = false
                                })
                        })
                        .catch(() => {
                            ElMessage({
                                type: 'info',
                                message: '取消删除'
                            })
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 【升级一代】弹出框-重置表单
        resetForm() {
            // this.$refs[formName].resetFields();
            this.dialogVisible = ref(false) //关闭弹窗
        },
        // 【退出平台】弹出框-提交表单
        submitFormGoout(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    ElMessageBox.confirm('是否退出平台，退出将不可逆转！！！', '提示：', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                            var getdatas = this.$refs[formName].model
                            console.log(getdatas)
                            axios
                                .post(
                                    '/admin/shopUser/exitPlatform',
                                    {
                                        shop_id: getdatas.id,
                                        type: getdatas.type,
                                        status: getdatas.status
                                    },
                                    {
                                        headers: {
                                            Authentication: localStorage.getItem('token')
                                        }
                                    }
                                )
                                .then((response) => {
                                    if (response.data.code === 0) {
                                        this.dialogVisible1 = ref(false) //关闭弹窗
                                        ElMessage({
                                            type: 'success',
                                            message: response.data.message,
                                            duration: 1000,
                                            onClose: () => {
                                                this.reload() //刷新
                                            }
                                        })
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error)
                                })
                        })
                        .catch(() => {
                            ElMessage({
                                type: 'info',
                                message: '取消删除'
                            })
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 【退出平台】弹出框-重置表单-取消关闭弹窗
        resetFormGoout(formName) {
            this.$refs[formName].resetFields()
            this.dialogVisible1 = ref(false) //关闭弹窗
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
        searchFormEvent(data) {
            console.log('高级筛选-开始检索返回数据(页面):' + JSON.stringify(data))
            //加载loading
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //判断-申请时间段状态
            var getaddtime = null
            if (data.addtime.start == '') {
                getaddtime = ''
            } else {
                if (data.addtime.end == '') {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.start + 86400 - 1)
                } else {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.end + 86400 - 1)
                }
            }
            this.pagesdatas.addtime = getaddtime
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shopUser/index',
                    {
                        keywords: data.keywords,
                        level: data.level,
                        status: data.status,
                        area_code: data.area_code,
                        addtime: getaddtime,
                        shop_id: data.shop_id,
                        user_type: data.user_type,
                        win_user_id: data.win_user_id,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })

            // 清空多选
            this.handleSelectionChange([])
            this.handleClearChangeEvent()
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shopUser/index',
                    {
                        keywords: data.keywords,
                        level: data.level,
                        status: data.status,
                        shop_id: data.shop_id,
                        user_type: data.user_type,
                        win_user_id: data.win_user_id,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })

            // 清空多选
            this.handleSelectionChange([])
            this.handleClearChangeEvent()
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //该字段表示search按钮，且status为1表示启用，2表示禁用
                newdata[i].switchstatus = 4
                //将省市区分割
                var arrcity = newdata[i].area_code.split(',')
                newdata[i].province = arrcity[0]
                newdata[i].city = arrcity[1]
                newdata[i].county = arrcity[2]
                //转换添加时间
                if (newdata[i].addtime) {
                    if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                        newdata[i].addtime = '无添加时间'
                    } else {
                        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime).substr(0, 10)
                    }
                } else {
                    newdata[i].addtime = '无添加时间'
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false

            // 将双向绑定的值赋值给realtabledata做断离层，避免弹窗清空多选被选中的状态！
            this.realtabledata = this.mainthreetableData.slice((this.currentPage - this.lastpage) * this.PageSize, this.currentPage * this.PageSize)
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            // 获取负责人下拉
            this.finduserPidOptions()

            // 获取【押金】押金金额配置
            this.getHockOptions()

            // 权限控制-启用禁用-[当其为false的时候不显示该列]
            if (this.mypowerlimits.dlslb_qy == false) {
                this.mainthreetabletitle.splice(this.mainthreetabletitle.length - 2, 1)
            }

            // 定义
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query
            if (routerinfo.id) {
                this.pagesdatas = {
                    shop_id: routerinfo.id,
                    page: 1,
                    page_size: this.PageSize
                }
                this.maintwoforminfo.myinputone.input[1].content = routerinfo.id
            }
            if (routerinfo.agentname) {
                this.pagesdatas = {
                    keywords: routerinfo.agentname,
                    page: 1,
                    page_size: this.PageSize
                }
                this.maintwoforminfo.myinputone.input[0].content = routerinfo.agentname
            }

            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .post('/admin/shopUser/index', this.pagesdatas, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
.dialog-list {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 0 1% 0 0;
}

.change-one-list {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 1% 0 0;
}
</style>
