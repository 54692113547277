<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div class="boxlist">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width"
              :min-width="mytabletitle.minwidth">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status'
              ">
                <!--状态-样式等渲染-->
                <div v-if="mytabletitle.prop === 'status'">
                  <el-button
                    :type="scope.row.printstatus == 0 ? 'primary' : (scope.row.printstatus == 1 ? 'success' : 'danger')"
                    size="mini">{{ this.$utils.printStatusData( this.myprintstatusoption ,scope.row.printstatus != 0 ?
                    scope.row.printstatus : '0') }}
                  </el-button>
                  <el-button :type="scope.row.is_online == 1 ? 'success' : 'danger'" size="mini">{{ scope.row.is_online
                  == 1 ? '在线' : '离线'
                  }}</el-button>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-合伙人列表-->
                <div v-if="mainthreetableope === 7">
                  <el-button type="primary" size="mini" id="partnerdvcid"
                    @click="chuliTanClick(scope.row, (dialogVisible = true))" v-if="mypowerlimits.sbhhrlb_ghsb">更换设备
                  </el-button>
                  <el-button v-if="
                    scope.row.sign_type != 1 && mypowerlimits.sbhhrlb_scsb
                  " @click.prevent="deleteRow(scope.$index, mainthreetableData)" type="danger" size="mini"
                    icon="el-icon-delete"></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!--弹出框-关联设备-->
    <el-dialog v-model="dialogVisible" title="选择设备" :width="this.searchwidth" :before-close="handleCloseSenior">
      <el-form ref="seniorForm" :model="seniorForm" :rules="rules">
        <!--查询条件-->
        <el-form-item>
          <div class="tan-search-box">
            <div class="tan-search-box-info">
              <div class="tan-search-box-info-title">输入搜索：</div>
              <div>
                <el-input v-model="seniorForm.keywords" placeholder="设备编号/代理商名称" clearable></el-input>
              </div>
            </div>
            <div class="tan-search-box-info">
              <div class="tan-search-box-info-title">选择区域：</div>
              <div>
                <mainselect v-model="seniorForm.area_code" :myselectcityleftisshow="tanpages.myselectcityisshow"
                  :myselectcity="tanpages.myselectcity" v-on:getcitycode="getCityCodeEvent"></mainselect>
              </div>
            </div>
            <div class="tan-search-box-button">
              <el-button type="primary" @click="onSubmitTan('seniorForm')">查询</el-button>
            </div>
          </div>
        </el-form-item>
        <!-- table表单 -->
        <el-form-item>
          <el-table :data="
            tableData.slice(
              (tanpages.currentPage - tanpages.lastpage) * tanpages.PageSize,
              tanpages.currentPage * tanpages.PageSize
            )
          " ref="multipleTable" style="width: 100%" max-height="450" border v-loading="tanpages.loading"
            element-loading-text="正在飞速加载中...">
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column label="设备编号" prop="client" width="150" />
            <el-table-column label="投放商家" prop="shop_name" />
            <el-table-column label="投放地址" prop="address" />
            <el-table-column label="投放场景" prop="push_type" width="150" />
            <el-table-column label="状态" prop="status" width="180">
              <template #default="scope">
                <div class="tan-table-status">
                  <div style="margin-right: 10px">
                    <el-button
                      :type="scope.row.printstatus == 0 ? 'primary' : (scope.row.printstatus == 1 ? 'success' : 'danger')"
                      size="mini">{{ this.$utils.printStatusData( this.myprintstatusoption ,scope.row.printstatus != 0 ?
                      scope.row.printstatus : '0') }}
                    </el-button>
                    <el-button :type="scope.row.is_online == 1 ? 'success' : 'danger'" size="mini">{{
                    scope.row.is_online
                    == 1 ? '在线' : '离线'
                    }}</el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="90" fixed="right">
              <template #header>
                <div>操作</div>
              </template>
              <template #default="scope">
                <el-button type="primary" size="mini" @click="handleTrue(scope.row)">选择设备
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-row>
          <!-- 分页 -->
          <el-col :span="24" :xs="24" :sm="24">
            <div style="margin-top: 5px; text-align: center">
              <el-pagination background @size-change="handleSizeChangetan" @current-change="handleCurrentChangetan"
                pager-count="5" :current-page="tanpages.currentPage" :page-sizes="tanpages.pageSizes"
                :page-size="tanpages.PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="tanpages.totalCount">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import mainselect from "@/components/Mainselect";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vderpartnerdvclist",
  inject: ["reload"],
  data() {
    return {
      mainonebread: [
        {
          name: "金融产品",
        },
        {
          name: "合伙人",
        },
        {
          name: "合伙人列表",
          path: "/derpartnerlist",
        },
        {
          name: "合伙人设备列表",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputonefullisshow: true, //筛选查询-自动建议下拉框
        myinputonefull: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "设备编号：",
              placeholder: "请输入设备编号", //此name为键值
              name: "client_id",
              content: "",
              id: "", //此id为给后台的id值
              url: "/admin/getClientList",
              inputval: { client: "" }, //这里记录需要传的参数
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 7, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 140,
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "client_no",
          label: "合同编号设备序号",
          width: "340",
        },
        {
          fixedstatu: false,
          prop: "client",
          label: "设备编号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "income_money",
          label: "设备累计收益",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "address",
          label: "设备定位地址",
          width: "",
          minwidth:"200"
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "添加时间",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "状态",
          width: "160",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      // 设备打印状态数据
      myprintstatusoption: componentProperties.get('printstatusoption').options,

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      //存储点击关联设备按钮行的数据
      setonehangdata: null,
      //弹框-查询设备-form表单数据
      seniorForm: {
        keywords: "",
        area_code: "",
      },
      dialogVisible: false,
      searchwidth: "1024px",
      //弹窗中-table表单
      tableData: [],
      tableseniorForm: {
        agreement_id: "",
        dvcids: [],
        alldvc: [],
        is_all: 0,
      },
      tanpages: {
        //弹窗中-selectcity选框
        myselectcityisshow: true,
        myselectcity: {
          selectwidth: "100%",
          maxwidth: "500px",
          marginright: "0",
          select: {
            ismust: false,
            name: "area_code",
            valuesheng: "",
            valueshi: "",
            valuequ: "",
            disshi: true,
            disqu: true,
          },
        },
        //弹窗中-table表单-分页器
        totalCount: 0, //数据列表中-数据总条数
        currentpage: 1, //数据列表中-数据当前页数
        lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
        currentPage: 1,
        pageSizes: [10, 20, 50, 100, 1000],
        PageSize: 10,
        loading: false, //初始化-loading加载动效，默认是false关闭

        sign_type: "", //弹出框中签约类型
        pagesdatas: [], //存储(弹窗中)最近一次的筛选条件，供分页使用

        tandisabled: false, //控制弹窗中的确定按钮
      },

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  methods: {
    //点击按钮-弹出弹框
    chuliTanClick(data) {
      //存储当前行的数据
      this.setonehangdata = data;
      //存储当前行的合同id
      this.tableseniorForm.agreement_id = data.id;
      // 存储当前行的签约类型
      this.tanpages.sign_type = data.sign_type;
      // 存储弹窗中的pagesdatas
      this.tanpages.pagesdatas.partner_id = data.partner_id;
      this.tanpages.pagesdatas.sign_type = data.sign_type;
      this.tanpages.pagesdatas.keywords = "";
      this.tanpages.pagesdatas.area_code = "";
      this.tanpages.pagesdatas.page = 1;
      this.tanpages.pagesdatas.page_size = 10;
      this.tanpages.PageSize = 10;

      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    // 弹窗中-获取数据的函数
    tanGetInfoEvent(pagesdatas) {
      if (pagesdatas.sign_type == 1) {
        //点击进入弹窗-加载tabledata
        this.tanpages.loading = true;
        axios
          .post(
            "/admin/partner/getInvestClient",
            {
              keywords: pagesdatas.keywords,
              area_code: pagesdatas.area_code,
              page: pagesdatas.page,
              page_size: pagesdatas.page_size,
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            var successdata = response.data.result;
            var newdata = successdata.data;
            for (var i = 0; i < newdata.length; i++) {
              //渲染-状态-该字段表示设备状态按钮
              newdata[i].devstatus = 1;
            }
            this.tableData = newdata;
            this.tanpages.totalCount = successdata.total;
            this.tanpages.currentpage = successdata.current_page; //当前页面页数
            this.tanpages.lastpage = successdata.last_page;
            this.tanpages.loading = false;
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        //点击进入弹窗-加载tabledata
        this.tanpages.loading = true;
        axios
          .post(
            "/admin/partner/getBuyClient",
            {
              partner_id: pagesdatas.partner_id,
              keywords: pagesdatas.keywords,
              area_code: pagesdatas.area_code,
              page: pagesdatas.page,
              page_size: pagesdatas.page_size,
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            var successdata = response.data.result;
            var newdata = successdata.data;
            for (var i = 0; i < newdata.length; i++) {
              //渲染-状态-该字段表示设备状态按钮
              newdata[i].devstatus = 1;
            }
            this.tableData = newdata;
            this.tanpages.totalCount = successdata.total;
            this.tanpages.currentpage = successdata.current_page; //当前页面页数
            this.tanpages.lastpage = successdata.last_page;
            this.tanpages.loading = false;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    //弹窗中-分页器中-当选择条数时触发该函数
    handleSizeChangetan(val) {
      console.log(`每页 ${val} 条`);
      this.tanpages.PageSize = val;
      this.tanpages.currentPage = 1;

      this.tanpages.pagesdatas.page = 1;
      this.tanpages.pagesdatas.page_size = val;
      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    //弹窗中-分页器中-当选择分页时触发该函数
    handleCurrentChangetan(val) {
      console.log(`当前页: 第${val}页`);
      this.tanpages.currentPage = val;

      this.tanpages.pagesdatas.page = val;
      this.tanpages.pagesdatas.page_size = this.tanpages.PageSize;
      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    //获取公共组件回传的值-citycode
    getCityCodeEvent(data) {
      console.log("当前获取的citycode值为：" + data);
      this.seniorForm.area_code = data;
    },
    //弹窗中-查询按钮-提交
    onSubmitTan(formName) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tanpages.pagesdatas = getdatas;
          this.tanpages.pagesdatas.sign_type = this.tanpages.sign_type;
          this.tanpages.pagesdatas.page = 1;
          this.tanpages.pagesdatas.page_size = this.tanpages.PageSize;
          this.tanGetInfoEvent(this.tanpages.pagesdatas);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-选择设备-确认
    handleTrue(formData) {
      console.log(formData);
      axios
        .post(
          "/admin/partner/updateClient1",
          {
            pac_id: this.tableseniorForm.agreement_id,
            client_id: formData.id,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            ElMessage({
              type: "success",
              message: "更换成功",
              duration: 1000,
              onClose: () => {
                this.dialogVisible = false; //关闭弹窗
                setTimeout(() => {
                  this.reload(); //刷新
                }, 500);
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //点击关闭-弹窗
    handleCloseSenior(done) {
      done();
    },
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row);  //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      console.log("index rows:" + index, rows);
      ElMessageBox.confirm(
        "确定删除该条设备编号为：' " + rows[index].client + " '的数据吗?",
        "提示：",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          axios
            .post(
              "/admin/partner/deleteClient1",
              {
                pac_id: rows[index].id,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "删除成功",
                });
                //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
                rows.splice(index, 1);
              }
            })
            .catch(function (error) {
              // 请求失败处理
              ElMessage({
                type: "error",
                message: "删除失败！" + error,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消删除",
          });
        });
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Lease/clientList",
          {
            lease_id: this.$route.query.id,
            client_id: this.pagesdatas.client_id,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Lease/clientList",
          {
            lease_id: this.$route.query.id,
            client_id: this.pagesdatas.client_id,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        this.$router
          .push({ path: routeraddr, query: { id: rowdata.id } })
          .catch((error) => error);
      } else {
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/admin/Lease/clientList",
          {
            lease_id: this.$route.query.id,
            client_id: this.pagesdatas.client_id,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 1100) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "1024px";
      }
    },
    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Lease/clientList",
          {
            lease_id: this.$route.query.id,
            client_id: data.client_id,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //渲染-状态-该字段表示设备状态按钮，且devstatus为1表示使用&在线，2表示空闲&在线，3表示空闲&离线
        if (newdata[i].printstatus === 1 && newdata[i].is_online === 1) {
          newdata[i].devstatus = 1;
        } else {
          if (newdata[i].printstatus === 0 && newdata[i].is_online === 1) {
            newdata[i].devstatus = 2;
          } else {
            if (newdata[i].printstatus === 0 && newdata[i].is_online === -1) {
              newdata[i].devstatus = 3;
            } else {
              newdata[i].devstatus = 0;
            }
          }
        }
        //转换添加时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无添加时间";
          } else {
            newdata[i].addtime = this.$utils
              .formatDate(newdata[i].addtime)
              .substr(0, 10);
          }
        } else {
          newdata[i].addtime = "无添加时间";
        }
      }
      // console.log("获取数据:" + JSON.stringify(newdata));
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Lease/clientList",
          {
            lease_id: this.$route.query.id,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
    mainselect,
  },
});
</script>

<style scoped>
/* 弹出框-搜索栏 */
.tan-search-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.tan-search-box-info {
  display: flex;
  flex-direction: row;
  margin: 0 10px 10px 0;
}

.tan-search-box-info-title {
  min-width: 80px;
}

.tan-table-status {
  display: flex;
  flex-direction: row;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.boxlist {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  line-height: 40px;
  padding: 0 20px;
}
</style>
