<template>
    <div>
        <el-table
            :header-cell-style="{ background: '#F5F7FA' }"
            ref="multipleTableRef"
            :data="this.mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
            style="width: 100%"
            min-height="900"
            border
            v-loading="loading"
            element-loading-text="正在飞速加载中..."
            @select-all="handleSelectionChange"
            @select="handleSelectionChangeOnline"
            @cell-click="handleOneData"
        >
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" :fixed="true" />
            <el-table-column
                v-for="(mytabletitle, i) in mainthreetabletitle"
                :key="i"
                :fixed="mytabletitle.fixedstatu"
                :prop="mytabletitle.prop"
                :label="mytabletitle.label"
                :width="mytabletitle.width"
            >
                <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                <template #default="scope" v-if="mytabletitle.prop === 'level'">
                    <!--代理商名称-样式等渲染-->
                    <div v-if="mytabletitle.prop === 'level'">
                        <div>
                            <span>{{ scope.row.level + '级' }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'modelTable',
    inject: ['reload'],
    props: ['tabletitle', 'tabledata', 'totalcount', 'currentpage', 'pagesize', 'Lastpage'],
    watch: {
        tabletitle: {
            handler(newVal) {
                this.mainthreetabletitle = newVal
            },
            deep: true,
            immediate: true
        },
        tabledata: {
            handler(newVal) {
                this.mainthreetableData = newVal
            },
            deep: true,
            immediate: true
        },
        totalcount: {
            handler(newVal) {
                this.totalCount = newVal
            },
            deep: true,
            immediate: true
        },
        currentpage: {
            handler(newVal) {
                this.currentPage = newVal
            },
            deep: true,
            immediate: true
        },
        pagesize: {
            handler(newVal) {
                this.PageSize = newVal
            },
            deep: true,
            immediate: true
        },
        Lastpage: {
            handler(newVal) {
                this.lastpage = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            loading: false, //初始化-loading加载动效，默认是false关闭

            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'id',
                    label: 'ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '代理商名称',
                    width: '130'
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号/账号',
                    width: '130'
                },
                {
                    fixedstatu: false,
                    prop: 'level',
                    label: '代理等级',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'province',
                    label: '省级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'city',
                    label: '市级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'county',
                    label: '县/区级',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            currentPage: 1,
            PageSize: 10,
            multipleSelection: []
        }
    },
    methods: {
        // 多选-全选
        handleSelectionChange(data) {
            this.$emit('handleSelection', data)
        },
        // 多选-单选
        handleSelectionChangeOnline(data, row) {
            if (data.length > 0) {
                let has_ids = data.map((n) => {
                    return n.id
                })

                if (has_ids.indexOf(row.id) != -1) {
                    // 选中
                    this.$emit('handleSelection', data)
                } else {
                    // 取消
                    let end_data = data.filter((n) => n.id != row.id)
                    this.$emit('handleSelectionOnline', { datas: end_data, row: row })
                }
            } else {
                this.$emit('handleSelection', [])
            }
        },
        // 反显
        backShow(datas) {
            this.$nextTick(() => {
                if (datas.length > 0) {
                    let key_array = datas
                        .map((n) => {
                            return parseInt(n.id)
                        })
                        .filter((n) => n)

                    let backshow = this.mainthreetableData
                        .map((n) => {
                            if (key_array.indexOf(n.id) > -1) {
                                return n
                            } else {
                                return
                            }
                        })
                        .filter((n) => n)

                    // 赋值前，先清空
                    this.$refs.multipleTableRef.clearSelection()

                    backshow.forEach((element) => {
                        // 单行-返显赋值
                        this.$refs.multipleTableRef.toggleRowSelection(element, true)
                    })
                } else {
                    this.$refs.multipleTableRef.clearSelection()
                }
            })
        }
    },
    created() {},
    components: {}
})
</script>

<style scoped></style>
