<template>
    <div>
        <el-cascader
            ref="cascaderRef"
            style="width: 100%"
            v-model="modelvalue"
            :options="options ? options : areacode_options"
            :props="props ? props : {}"
            :placeholder="placeholder ? placeholder : ''"
            :show-all-levels="showalllevels ? showalllevels : true"
            :collapse-tags="collapsetags ? collapsetags : false"
            :collapse-tags-tooltip="collapsetagstooltip ? collapsetagstooltip : false"
            :filterable="filterable ? filterable : false"
            clearable
            @change="changeEvent"
        />
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import areacode from '@/assets/json/areacode.json'

export default defineComponent({
    name: 'modelCity',
    inject: ['reload'],
    props: ['value', 'options', 'props', 'placeholder', 'showalllevels', 'collapsetags', 'collapsetagstooltip', 'filterable'],
    watch: {
        value: {
            handler(newVal) {
                this.modelvalue = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            // 地址options
            areacode_options: areacode,

            modelvalue: ''
        }
    },
    methods: {
        changeEvent(data) {
            this.$emit('changecity', data)
        }
    },
    components: {}
})
</script>

<style scoped></style>
