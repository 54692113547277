<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box"  >
                <div class="box_title"  >
                    <div class="box_title_font">添加流水</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form
                                ref="ruleForm"
                                :model="ruleForm"
                                :rules="rules"
                                label-width="120px"
                                class="demo-ruleForm"
                            >
                                <el-form-item label="名称：" prop="name">
                                    <el-input v-model.trim="ruleForm.name"></el-input>
                                </el-form-item>
                                <el-form-item label="广告图片：" prop="img">
                                    <mainimgesup
                                        v-model="ruleForm.img"
                                        :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow"
                                        :filelist="ruleForm.img_list"
                                        v-on:getuploadfile="
                                            (data) => getUploadFileEvent(data)
                                        "
                                    >
                                    </mainimgesup>
                                </el-form-item>
                                <el-form-item label="库存：" prop="stock_num">
                                    <el-input-number
                                        v-model="ruleForm.stock_num" :min="0"
                                    />
                                </el-form-item>
                                <el-form-item label="状态：">
                                    <el-radio-group v-model="ruleForm.is_use">
                                        <el-radio label="1">上架</el-radio>
                                        <el-radio label="2">下架</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="详情说明：" prop="remark">
                                    <TEditor ref="editorfile" v-model="ruleForm.remark" v-on:geteditordatas="(data)=> getEditorDatasEvent(data)"/>
                                </el-form-item>
                                <el-form-item>
                                    <el-button
                                        type="primary"
                                        @click="submitForm('ruleForm')"
                                        style="width: 40%; margin-right: 10px"
                                        >确认
                                    </el-button>
                                    <el-button @click="resetForm('ruleForm')"
                                        >重置</el-button
                                    >
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainimgesup from "@/components/Mainimgesup.vue";
import TEditor from "@/components/Maineditor.vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import { defineComponent } from "vue";

export default defineComponent({
    name: "vpostgoodslistadd",
    data() {
        return {
            mainonebread: [
                {
                    name: "运营",
                },
                {
                    name: "余额充值",
                },
                {
                    name: "赠品管理",
                },
                {
                    name: "添加",
                },
            ], // 当前页-【面包屑导航】

            myimgesup: {
                uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: "/admin/upload/file", //upload上传API地址
                headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
                title: "点击上传", //button-按钮文字
                tips: "建议尺寸：200*200px像素，png、jpg、jpeg格式", //提示&建议文字
                limit: "1", //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 2000, //文件大小（单位kb）
                        type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
                        typechart: "jpg/png", //文件格式提示规则
                    },
                },
            },

            ruleForm: {
                name: "",
                img: "",
                stock_num: 1,
                is_use: "1",
                remark: "",
            }, //form表单
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称为必填项",
                        trigger: "blur",
                    },
                ],
                stock_num: [
                    {
                        required: true,
                        message: "库存为必填项",
                        trigger: "blur",
                    },
                ],
                remark: [
                    {
                        required: true,
                        message: "详情说明为必填项",
                        trigger: "blur",
                    },
                ],
                img: [
                    {
                        required: true,
                        message: "请上传图片",
                        trigger: "change",
                    },
                ],
            }, //form表单-必填规则

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
        };
    },

    methods: {
        //from表单-提交表单
        submitForm(formName) {
            var getdata = this.$refs[formName].model;
            // console.log(getdata.month.valueOf() / 1000);
            // console.log(getdata.month);
            // var monthdata = getdata.month.valueOf() / 1000;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            "/admin/member/cashGiftAdd",
                            {
                                name: getdata.name,
                                img: getdata.img,
                                stock_num: getdata.stock_num,
                                is_use: getdata.is_use,
                                remark: getdata.remark,
                            },
                            {
                                headers: {
                                    Authentication:
                                        localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: "success",
                                    message: "提交成功！",
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.go(-1); // 返回上一页
                                    },
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //from表单-重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.ruleForm.remark = null
            this.$refs.editorfile.getClear()
        },

        getUploadFileEvent(data) {
            console.log("当前获取的回传数据：" + JSON.stringify(data));
            this.ruleForm.img = data.url;
        },

        getEditorDatasEvent(datas) {
            this.ruleForm.remark = datas;
        },

    },
    created() {
        if (
            localStorage.getItem("token") === null ||
            !localStorage.getItem("token")
        ) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        }
    },
    components: {
        mainone,
        mainimgesup,
        TEditor,
    },
});
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
