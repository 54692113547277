<template>
    <div id="mymain">
        <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">用户信息</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px"></maindetails>
                    <div style="margin-bottom: 20px; margin-top: 10px">
                        <div style="min-width: 80px; float: left; margin-right: 20px">
                            <span style="font-size: 14px; color: #333333; margin-left: 38px">用户头像:</span>
                        </div>
                        <div>
                            <mainimgesshow :imgesdata="mainpagedata.imgesdata"></mainimgesshow>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <!--          <maindetails :detailsdata="mainpagedata.details1"></maindetails>-->
                    <!--[由于牵扯特殊弹框组件-且只用一次，因此未使用公用组件]-->
                    <el-row>
                        <el-col style="font-weight: 400">
                            <div class="box-list">
                                {{ mainpagedata.details1.title }}
                            </div>
                            <div v-for="(detailsdatainfo, l) in mainpagedata.details1.content" :key="l">
                                <div style="font-size: 14px; color: #333333; line-height: 40px">
                                    <div style="float: left; min-width: 500px">
                                        <div style="float: left; margin-right: 20px; min-width: 100px">
                                            <div style="float: right">{{ detailsdatainfo.name }}</div>
                                        </div>
                                        <div style="float: left; margin-right: 20px">
                                            {{ detailsdatainfo.value }}
                                        </div>
                                        <div style="color: #465eff; cursor: pointer" @click="routeraddrClick(detailsdatainfo.jump, detailsdatainfo.jumpdatas)">
                                            {{ detailsdatainfo.jumpbt }}
                                        </div>
                                        <div v-if="detailsdatainfo.btnname">
                                            <el-button
                                                size="small"
                                                type="primary"
                                                @click="addTanClick(getrowdata, (dialogVisible = true))"
                                                v-if="detailsdatainfo.btnname == '充扣款' && this.mypowerlimits.yhlb_ckk"
                                            >
                                                {{ detailsdatainfo.btnname }}
                                            </el-button>
                                            <el-button
                                                size="small"
                                                type="primary"
                                                @click="addTanClick1(getrowdata, (dialogVisible1 = true))"
                                                v-if="detailsdatainfo.btnname == '赠送会员' && this.mypowerlimits.yhlb_zshy"
                                            >
                                                {{ detailsdatainfo.btnname }}
                                            </el-button>
                                            <el-button
                                                size="small"
                                                type="primary"
                                                @click="addTanClick2(getrowdata, (dialogVisible2 = true))"
                                                v-if="detailsdatainfo.btnname == '修改到期时间' && this.mypowerlimits.yhlb_xgdqsj"
                                            >
                                                {{ detailsdatainfo.btnname }}
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <!--标签信息 （待后期-解禁）-->
                    <div id="tags-models" style="margin-bottom: 30px">
                        <div style="font-size: 14px; font-weight: bold; margin-bottom: 10px">用户标签</div>
                        <div v-if="dynamicTags.length > 0" style="margin-top: 20px">
                            <div :class="is_show_sub == 1 ? 'no-warp-2' : ''">
                                <el-tag v-for="(tag, index) in dynamicTags" :key="index">
                                    {{ tag }}
                                </el-tag>
                            </div>
                            <div v-if="is_show_tags == true" class="show-box" @click="showEvent(is_show_sub)">
                                <span>{{ is_show_sub == 1 ? '展开' : '收起' }}</span>
                            </div>
                        </div>
                        <div v-else style="margin: 20px 0 0 35px; font-size: 14px">
                            <span>暂无标签</span>
                        </div>
                    </div>

                    <!-- 关联信息 -->
                    <div style="margin-bottom: 30px">
                        <el-divider></el-divider>
                        <div style="font-size: 14px; font-weight: bold; margin-bottom: 10px">关联信息</div>
                        <div style="margin-top: 20px; padding: 0 35px; font-size: 14px">
                            <div style="width: 90px">
                                <span>收货地址：</span>
                            </div>
                            <div>
                                <el-table :data="tableData" :header-cell-style="{ background: '#F5F7FA' }" style="width: 100%; margin-top: 20px" min-height="200" border>
                                    <el-table-column label="姓名" prop="name" />
                                    <el-table-column label="手机号" prop="mobile" />
                                    <el-table-column label="所在地区" prop="areaStr" />
                                    <el-table-column label="详细地址" prop="address" />
                                    <el-table-column label="默认地址" prop="isdefault">
                                        <template #default="scope">
                                            <span>{{ scope.row.isdefault == 1 ? '是' : '否' }}</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>

                    <!-- 兼容家庭版 -->
                    <!-- 关联设备 -->
                    <div style="margin-bottom: 30px">
                        <el-divider></el-divider>
                        <div style="font-size: 14px; font-weight: bold; margin-bottom: 10px">关联设备</div>
                        <div style="margin-top: 20px; padding: 0 35px; font-size: 14px">
                            <div>
                                <el-table :data="tableDataDvc" :header-cell-style="{ background: '#F5F7FA' }" style="width: 100%; margin-top: 20px" min-height="200" border>
                                    <el-table-column type="index" width="65" label="序号" />
                                    <el-table-column label="设备编号" prop="client">
                                        <template #default="scope">
                                            <div
                                                :class="this.mypowerlimits.jqkz == true ? 'mytabletext' : ''"
                                                @click="this.mypowerlimits.jqkz == true ? routeraddrClick('/sharedvccontrol', scope.row) : ''"
                                            >
                                                <span>{{ scope.row.client }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="打印机类型" prop="printname" />
                                    <el-table-column label="订单量" prop="orderNum">
                                        <template #default="scope">
                                            <div
                                                :class="this.mypowerlimits.ddlb == true ? 'mytabletext' : ''"
                                                @click="this.mypowerlimits.ddlb == true ? routeraddrClick('/orderlist', scope.row) : ''"
                                            >
                                                <span>{{ scope.row.orderNum }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="成员类型" prop="user_type">
                                        <template #default="scope">
                                            <span>{{ scope.row.user_type == 1 ? '管理员' : '成员' }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="状态" prop="status">
                                        <template #default="scope">
                                            <div class="tan-table-status">
                                                <el-button :type="scope.row.printstatus == 0 ? 'primary' : scope.row.printstatus == 1 ? 'success' : 'danger'" size="mini"
                                                    >{{ this.$utils.printStatusData(this.myprintstatusoption, scope.row.printstatus != 0 ? scope.row.printstatus : '0') }}
                                                </el-button>
                                                <el-button :type="scope.row.is_online == 1 ? 'success' : 'danger'" size="mini">{{
                                                    scope.row.is_online == 1 ? '在线' : '离线'
                                                }}</el-button>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :fixed="false" label="操作" :width="handle" prop="myoperation" id="operation">
                                        <template #default="scope">
                                            <el-button type="danger" size="mini" @click="delRelationEvent(scope.row, scope.$index)">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>

                    <!-- 权益会员 -->
                    <div style="margin-bottom: 30px">
                        <el-divider></el-divider>
                        <div style="font-size: 14px; font-weight: bold; margin-bottom: 10px">权益会员</div>
                        <div style="margin-top: 20px; padding: 0 35px; font-size: 14px">
                            <div>
                                <el-table :data="tableDataVip" :header-cell-style="{ background: '#F5F7FA' }" style="width: 100%; margin-top: 20px" min-height="200" border>
                                    <el-table-column label="会员等级" prop="set_name" />
                                    <el-table-column label="会员类型" prop="type">
                                        <template #default="scope">
                                            <span>{{ type_options[scope.row.type] }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="绑定设备" prop="client" />
                                    <el-table-column label="商家权益" prop="rule_name" />
                                    <el-table-column label="已核销商家券" prop="coupon1" />
                                    <el-table-column label="待核销商家券" prop="coupon0" />
                                    <el-table-column label="已作废商家券" prop="coupon2" />
                                    <el-table-column label="购买时间" prop="pay_time" width="200px">
                                        <template #default="scope">
                                            <span> {{ scope.row.pay_time == 0 ? '' : this.$utils.formatDate(scope.row.pay_time) }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="到期时间" prop="end_time" width="200px">
                                        <template #default="scope">
                                            <span> {{ scope.row.end_time == 0 ? '' : this.$utils.formatDate(scope.row.end_time) }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="会员状态" prop="pay_status">
                                        <template #default="scope">
                                            <span>{{ pay_status_options[scope.row.pay_status] }}</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--弹出框-充扣款-->
        <el-dialog v-model="dialogVisible" title="充扣款" :width="this.searchwidth" :before-close="handleCloseSenior">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                <div class="tan-chongkoukuan">
                    <el-form-item label="类型：" prop="resource">
                        <el-radio-group v-model="ruleForm.resource">
                            <el-radio label="1">充值</el-radio>
                            <el-radio label="2">扣款</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="金额：" prop="money">
                        <el-input v-model="ruleForm.money"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号：" prop="phone">
                        <el-input v-model="ruleForm.phone" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="微信昵称：" prop="nickname">
                        <el-input v-model="ruleForm.nickname" disabled></el-input>
                    </el-form-item>

                    <el-form-item label="备注：" prop="desc">
                        <el-input v-model="ruleForm.desc" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item style="text-align: right">
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                        <el-button type="primary" @click="submitForm('ruleForm')" :disabled="mydisabled">确认</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </el-dialog>

        <!--弹出框-赠送会员-->
        <el-dialog v-model="dialogVisible1" title="赠送会员" :width="this.searchwidth" :before-close="handleCloseSenior">
            <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" label-width="120px" class="demo-ruleForm">
                <div class="tan-zengsonghuiyuan">
                    <el-form-item label="PlUS类型：" prop="type">
                        <el-select v-model="ruleForm1.type" placeholder="请选择" style="width: 100%">
                            <el-option :label="gettype.name" :value="gettype.id" v-for="(gettype, j) in getplustyppe" :key="j"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注：" prop="desc">
                        <el-input v-model="ruleForm1.desc" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item style="text-align: right">
                        <el-button @click="resetForm('ruleForm1')">重置</el-button>
                        <el-button type="primary" @click="submitForm1('ruleForm1')" :disabled="mydisabled">确认</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </el-dialog>

        <!--弹出框-修改到期时间-->
        <el-dialog v-model="dialogVisible2" title="修改到期时间" :width="this.searchwidth" :before-close="handleCloseSenior">
            <el-form ref="ruleForm2" :model="ruleForm2" :rules="rules2" label-width="120px" class="demo-ruleForm">
                <div class="tan-xiugaishijian">
                    <el-form-item label="到期时间：" prop="end_time">
                        <el-date-picker v-model="ruleForm2.end_time" type="date" placeholder="请选择日期" style="width: 100%"> </el-date-picker>
                    </el-form-item>
                    <el-form-item label="备注：" prop="desc">
                        <el-input v-model="ruleForm2.desc" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item style="text-align: right">
                        <el-button @click="resetForm('ruleForm2')">重置</el-button>
                        <el-button type="primary" @click="submitForm2('ruleForm2')" :disabled="mydisabled">确认</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import maindetails from '@/components/Maindetails'
import mainimgesshow from '@/components/Mainimgesshow'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import axios from 'axios'
import { ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
    name: 'vuserinfolist',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainpagedata: {
                mainonebread: [
                    {
                        name: '用户'
                    },
                    {
                        name: '用户管理'
                    },
                    {
                        name: '用户列表',
                        path: '/userlist'
                    },
                    {
                        name: '用户信息',
                        path: '/userinfolist'
                    }
                ], // 当前页-【面包屑导航】

                //关于详情信息的组件
                details: {
                    showstatus: 1, //确定样式模块
                    title: '基础信息',
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '用户ID:'
                        },
                        {
                            name: '用户昵称:'
                        },
                        {
                            name: '手机号:'
                        },
                        {
                            name: '实名认证:'
                        },
                        {
                            name: '姓名:'
                        },
                        {
                            name: '身份证号:'
                        },
                        {
                            name: '性别:'
                        },
                        {
                            name: '所属地区:'
                        },
                        {
                            name: '注册时间:'
                        },
                        {
                            name: '关注公众号:'
                        },
                        {
                            name: '关注时间:'
                        },
                        {
                            name: '用户类型:'
                        },
                        {
                            name: '平台来源:'
                        },
                        {
                            name: '最后登录时间:'
                        }
                    ]
                },
                //关于详情信息的组件
                details1: {
                    showstatus: 1, //确定样式模块
                    title: '账户信息',
                    button: {
                        isshow: false,
                        name: ''
                    },
                    content: [
                        {
                            name: '微信openid:'
                        },
                        {
                            name: '微信unionid:'
                        },
                        {
                            name: '支付宝user_id:'
                        },
                        {
                            name: '余额:'
                        },
                        {
                            name: '可提现佣金:'
                        },
                        {
                            name: '京币:'
                        },
                        {
                            name: '用户等级:'
                        },
                        {
                            name: 'PLUS:'
                        },
                        {
                            name: 'PLUS到期时间:'
                        },
                        {
                            name: '打印折扣:'
                        },
                        {
                            name: '打印订单数:'
                        },
                        {
                            name: '未使用优惠券:'
                        }
                    ]
                },
                //关于图片展示的组件
                imgesdata: {
                    showstatus: 2,
                    content: [
                        // {
                        //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                        //   preview: [
                        //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                        //   ],
                        // },
                    ]
                }
            }, // 当前Pagedata所需的data数据与控制
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            getplustyppe: [], //获取【赠送会员】中-plus类型-下拉框数据
            getrowdata: {}, //获取当前页面数据
            getrowtitle: null, //获取当前页面title
            searchwidth: '580px',
            //弹窗相关数据 - (充扣款)
            dialogVisible: ref(false), //弹窗显示&隐藏
            //弹窗中-form表单 - (充扣款)
            ruleForm: {
                id: '', //当前行用户id
                thirdparty: '', //当前行用户-所属平台
                resource: ref('1'),
                money: '',
                phone: '',
                nickname: '',
                desc: ''
            },
            //弹窗中-form表单-必填规则 - (充扣款)
            rules: {
                money: [
                    {
                        required: true,
                        message: '金额为必填项',
                        trigger: 'blur'
                    }
                ],
                phone: [
                    {
                        required: false,
                        message: '请输入手机号',
                        trigger: 'change'
                    }
                ],
                nickname: [
                    {
                        required: false,
                        message: '请输入微信昵称',
                        trigger: 'change'
                    }
                ],
                resource: [
                    {
                        required: true,
                        message: '请至少选择一个类型',
                        trigger: 'change'
                    }
                ],
                desc: [
                    {
                        required: false,
                        message: 'Please input activity form',
                        trigger: 'blur'
                    }
                ]
            },

            //弹窗相关数据 - (赠送会员)
            dialogVisible1: ref(false), //弹窗显示&隐藏
            //弹窗中-form表单 - (赠送会员)
            ruleForm1: {
                id: '', //当前用户id
                thirdparty: '', //当前行用户-所属平台
                type: '',
                desc: ''
            },
            //弹窗中-form表单-必填规则 - (赠送会员)
            rules1: {
                type: [
                    {
                        required: true,
                        message: 'PLUS类型为必填项',
                        trigger: 'blur'
                    }
                ],
                desc: [
                    {
                        required: false,
                        message: '备注为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            //弹窗相关数据 - (修改到期时间)
            dialogVisible2: ref(false), //弹窗显示&隐藏
            //弹窗中-form表单 - (修改到期时间)
            ruleForm2: {
                id: '', //当前用户id
                thirdparty: '', //当前行用户-所属平台
                end_time: '',
                desc: ''
            },
            //弹窗中-form表单-必填规则 - (修改到期时间)
            rules2: {
                end_time: [
                    {
                        required: true,
                        message: '到期时间为必填项',
                        trigger: 'blur'
                    }
                ],
                desc: [
                    {
                        required: false,
                        message: '备注为必填项',
                        trigger: 'blur'
                    }
                ]
            },

            //标签-相关数据
            dynamicTags: [],
            inputVisible: false,
            inputValue: '',
            is_show_sub: 2,
            is_show_tags: false,

            // 平台来源-维护字段
            thirdparty: '',

            mydisabled: false,

            // 收货地址-列表数据
            tableData: [],

            // 兼容家庭版：关联设备
            tableDataDvc: [],
            // 设备打印状态数据
            myprintstatusoption: componentProperties.get('printstatusoption').options,

            // 权益会员-table数据
            tableDataVip: [],
            // 权益会员-会员状态options
            pay_status_options: { '-1': '待支付', 1: '已支付', 2: '已失效', 3: '已退款' },
            // 权益会员-会员类型options
            type_options: { 1: '商家邀请', 2: '用户自购', 3: '全平台' },

            loading: true
        }
    },
    methods: {
        showEvent(data) {
            data == 1 ? (this.is_show_sub = 2) : (this.is_show_sub = 1)
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置（充扣款）
        addTanClick(taninfo) {
            var getdata = taninfo
            this.ruleForm.id = getdata.id
            this.ruleForm.thirdparty = this.$route.query.thirdparty
            this.ruleForm.phone = getdata.mobile
            this.ruleForm.nickname = getdata.nickname
        },
        //弹出框-提交表单（充扣款）
        submitForm(formName) {
            // 前端防抖
            this.mydisabled = true

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model
                    axios
                        .post(
                            '/admin/Member/balanceAction',
                            {
                                receive_uid: getdatas.id,
                                platform: getdatas.thirdparty,
                                type: getdatas.resource,
                                price: getdatas.money,
                                receive_mobile: getdatas.phone,
                                remark: getdatas.desc
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.reload() //刷新
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置（赠送会员）
        addTanClick1(taninfo) {
            var getdata = taninfo
            this.ruleForm1.id = getdata.id
            this.ruleForm1.thirdparty = this.$route.query.thirdparty
        },
        //弹出框-提交表单（赠送会员）
        submitForm1(formName) {
            // 前端防抖
            this.mydisabled = true

            var getdatas = this.$refs[formName].model
            console.log(getdatas)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/Member/givePlus',
                            {
                                thirdparty: getdatas.thirdparty,
                                id: getdatas.id,
                                code: getdatas.type,
                                remark: getdatas.desc
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible1 = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.mydisabled = false
                                        this.reload() //刷新
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置（修改到期时间）
        addTanClick2(taninfo) {
            var getdata = taninfo
            this.ruleForm2.id = getdata.id
            this.ruleForm2.thirdparty = this.$route.query.thirdparty
        },
        //弹出框-提交表单（修改到期时间）
        submitForm2(formName) {
            // 前端防抖
            this.mydisabled = true

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model
                    axios
                        .post(
                            '/admin/Member/updateExpTime',
                            {
                                thirdparty: getdatas.thirdparty,
                                id: getdatas.id,
                                end_time: getdatas.end_time.valueOf() / 1000,
                                remark: getdatas.desc
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible2 = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.mydisabled = false
                                        this.reload() //刷新
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function (error) {
                            this.mydisabled = false
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //弹出框-重置表单(充扣款&赠送会员&修改到期时间)
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        //弹窗-关闭
        handleCloseSenior(done) {
            done()
        },

        //点击进入路由页面
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                if (routeraddr == '/orderlist') {
                    // 兼容-跳转-订单列表
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                client: rowdata.client,
                                id: this.$route.query.id,
                                thirdparty: this.$route.query.thirdparty // 目前整体跳转平台来源1
                            }
                        })
                        .catch((error) => error)
                } else if (routeraddr == '/sharedvccontrol') {
                    // 兼容-跳转-设备编辑
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                clientnumber: rowdata.client
                            }
                        })
                        .catch((error) => error)
                } else {
                    this.$router.push({ path: routeraddr, query: rowdata }).catch((error) => error)
                }
            } else {
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //标签-删除标签
        handleClose(tag) {
            this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
        },
        //标签-填写标签
        showInput() {
            this.inputVisible = true
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus()
            })
        },
        //标签-生成标签
        handleInputConfirm() {
            const inputValue = this.inputValue
            if (inputValue) {
                this.dynamicTags.push(inputValue)
            }
            this.inputVisible = false
            this.inputValue = ''
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },
        // 获取相关数据
        getalldatasEvent(rowtitle, rowdata) {
            this.getrowtitle = rowtitle
            this.getrowdata = rowdata

            var title = rowtitle
            var datas = rowdata

            //获取当前页面中的title-基础信息
            var titlebase = []
            for (let i in title.base) {
                if (i == 'id') {
                    titlebase.push({
                        name: title.base[i] + ':',
                        value: datas[i],
                        jumpbt: '[查看用户消费明细]',
                        jump: '/statementagent',
                        jumpdatas: { id: datas.id, thirdparty: this.thirdparty }
                    })
                } else {
                    // 用户头像
                    if (i == 'avatar') {
                        titlebase.push({})
                    } else {
                        // 实名认证
                        if (i == 'is_real') {
                            if (datas[i] == '-1') {
                                titlebase.push({
                                    name: title.base[i] + ':',
                                    value: '未实名'
                                })
                            } else {
                                titlebase.push({
                                    name: title.base[i] + ':',
                                    value: '实名'
                                })
                            }
                        } else {
                            // 关注公众号
                            if (i == 'subscribe') {
                                if (datas[i] == '-1') {
                                    titlebase.push({
                                        name: title.base[i] + ':',
                                        value: '未关注'
                                    })
                                } else {
                                    titlebase.push({
                                        name: title.base[i] + ':',
                                        value: '关注'
                                    })
                                }
                            } else {
                                // 性别
                                if (i == 'gender') {
                                    if (datas[i] == '0') {
                                        titlebase.push({
                                            name: title.base[i] + ':',
                                            value: '未知'
                                        })
                                    } else {
                                        if (datas[i] == '1') {
                                            titlebase.push({
                                                name: title.base[i] + ':',
                                                value: '男'
                                            })
                                        } else {
                                            titlebase.push({
                                                name: title.base[i] + ':',
                                                value: '女'
                                            })
                                        }
                                    }
                                } else {
                                    // 注册时间
                                    if (i == 'addtime') {
                                        if (datas[i] != 0 || datas[i] != '') {
                                            titlebase.push({
                                                name: title.base[i] + ':',
                                                value: this.$utils.formatDate(datas[i])
                                            })
                                        }
                                    } else {
                                        // 平台来源
                                        if (i == 'thirdparty') {
                                            if (datas[i] == 1) {
                                                titlebase.push({
                                                    name: title.base[i] + ':',
                                                    value: '微信自助版'
                                                })
                                            }
                                            if (datas[i] == 2) {
                                                titlebase.push({
                                                    name: title.base[i] + ':',
                                                    value: '支付宝自助版'
                                                })
                                            }
                                            if (datas[i] == 3) {
                                                titlebase.push({
                                                    name: title.base[i] + ':',
                                                    value: '微信企业版'
                                                })
                                            }
                                        } else {
                                            // 身份证号
                                            if (i == 'id_card') {
                                                titlebase.push({
                                                    name: title.base[i] + ':',
                                                    value: this.hiddenCardId(datas[i])
                                                })
                                            } else {
                                                titlebase.push({
                                                    name: title.base[i] + ':',
                                                    value: datas[i]
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.mainpagedata.details.content = titlebase

            //获取当前页面中图片信息
            this.mainpagedata.imgesdata.content = [
                {
                    url: datas.avatar,
                    preview: [datas.avatar]
                }
            ]

            //获取当前页面中的title-账户信息
            var titleuser = []

            let is_control_flag = {}
            for (let j in title.user) {
                let push_data = {
                    keys: j,
                    name: title.user[j] + ':',
                    value: datas[j]
                }

                if (j == 'coin') {
                    push_data = { ...push_data, jumpbt: '[查看详情]', jump: '/yingbiconsume', jumpdatas: { id: datas.id, thirdparty: this.thirdparty } }
                } else if (j == 'order_num') {
                    push_data = { ...push_data, jumpbt: '[查看详情]', jump: '/orderlist', jumpdatas: { id: datas.id, thirdparty: this.thirdparty } }
                } else if (j == 'cash') {
                    push_data = { ...push_data, btnname: '充扣款' }
                } else if (j == 'is_plus') {
                    // 当PLUS显示【否】时，显示【赠送会员】按钮
                    if (datas[j] == '-1') {
                        push_data = { ...push_data, value: '否', btnname: '赠送会员' }
                    } else {
                        push_data = { ...push_data, value: '是' }
                    }
                } else if (j == 'end_time') {
                    // PLUS到期时间
                    if (datas[j] != 0 || datas[j] != '') {
                        push_data = { ...push_data, value: this.$utils.formatDate(datas[j]), btnname: '修改到期时间' }
                    } else {
                        push_data = { ...push_data }
                    }
                } else if (j == 'user_type') {
                    // 用户类型
                    var getvalue = ''
                    // 用户类型为数组
                    datas[j].forEach((element) => {
                        if (element == 1) {
                            getvalue = '个人,' + getvalue
                        } else {
                            if (element == 2) {
                                getvalue = '商户,' + getvalue
                            } else {
                                getvalue = '合伙人,' + getvalue
                            }
                        }
                    })
                    push_data = { ...push_data, value: getvalue.substring(0, getvalue.lastIndexOf(',')) }
                } else if (j == 'last_login_time' || j == 'vip_end_time') {
                    //最后登录时间 ||  权益会员到期时间
                    if (datas[j] && (datas[j] != 0 || datas[j] != '')) {
                        push_data = { ...push_data, value: this.$utils.formatDate(datas[j]) }
                    } else {
                        push_data = { ...push_data, value: datas[j] == 0 ? '' : datas[j] }
                    }
                } else if (j == 'discount') {
                    // 折扣
                    if (datas[j] == '') {
                        push_data = { ...push_data }
                    } else {
                        push_data = { ...push_data, value: datas[j] + '折' }
                    }
                } else if (j == 'vip_name') {
                    // 会员等级
                    if (datas[j]) {
                        push_data = { ...push_data }
                    } else {
                        push_data = {}
                    }
                } else if (j == 'is_vip') {
                    // 权益会员
                    if (datas[j] == '-1') {
                        push_data = { ...push_data, value: '否' }
                        is_control_flag['vip_end_time'] = () => {
                            titleuser = titleuser.filter((n) => n.keys != 'vip_end_time')
                        }
                    } else {
                        push_data = { ...push_data, value: '是' }
                    }
                } else {
                    push_data = { ...push_data }
                }

                titleuser.push(push_data)
            }

            // 标识：通过is_control字段空置
            if (Object.keys(is_control_flag).length > 0) {
                let keys = Object.keys(is_control_flag)
                keys.map((n) => {
                    if (typeof is_control_flag[n] === 'function') {
                        is_control_flag[n]()
                    }
                })
            }

            this.mainpagedata.details1.content = titleuser

            //获取当前页面中的title-标签
            this.dynamicTags = datas.tags
            var solider = document.getElementById('tags-models')
            setTimeout(() => {
                // console.log(solider.clientHeight)
                if (solider.clientHeight > 123) {
                    this.is_show_tags = true
                    this.is_show_sub = 1
                } else {
                    this.is_show_tags = false
                }
            }, 100)

            // 获取当前页面-关联信息-收货地址列表
            this.tableData = datas.address
            // 获取当前用户-关联设备-列表
            this.tableDataDvc = datas.relateClient
            // 权益会员-列表
            this.tableDataVip = datas.vipList
        },
        // 身份证信息-用*号替代
        hiddenCardId(str, frontLen = 8, endLen = 1) {
            //str：要进行隐藏的变量  frontLen: 前面需要保留几位    endLen: 后面需要保留几位
            // var len = str.length - frontLen - endLen;
            // var start = "";
            // for (var i = 0; i < len; i++) {
            //   start += "*";
            // }
            var start = '*********'
            // 最后的返回值由三部分组成
            return str.substring(0, frontLen) + start + str.substring(str.length - endLen)
        },

        // 兼容家庭版
        // 删除设备关联事件
        delRelationEvent(data, index) {
            ElMessageBox.confirm('确定删除关联么？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/Member/delRelateClient',
                            {
                                id: data.id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.reload()
                                        this.tableDataDvc.splice(index, 1)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消关停'
                    })
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            this.loading = true
            // 平台来源-维护字段
            this.thirdparty = this.$route.query.thirdparty

            // 获取title数据&数据详情
            var p1 = axios
                .get('/admin/Member/detail', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        thirdparty: this.$route.query.thirdparty,
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    let infos = response.data.result
                    this.getrowtitle = infos.dtitle
                    this.getrowdata = infos.info
                    this.getalldatasEvent(this.getrowtitle, this.getrowdata)
                })
                .catch(function (error) {
                    console.log(error)
                })

            //获取-赠送会员按钮中-PlUS类型-下拉框数据
            var p2 = axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'member_plus_set'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.getplustyppe = response.data.result
                })
                .catch(function (error) {
                    console.log(error)
                })

            Promise.all([p1, p2])
                .then(() => {
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

/*标签*/
.el-tag {
    margin-right: 10px;
    margin-bottom: 10px;
}

.el-tag + .el-tag {
    margin-right: 10px;
    margin-bottom: 10px;
}

.button-new-tag {
    margin-right: 10px;
    margin-bottom: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: bottom;
}

.tan-chongkoukuan {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}

.tan-zengsonghuiyuan {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}

.tan-xiugaishijian {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}

.tan-table-status {
    display: flex;
    flex-direction: row;
}

.box-list {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.no-warp-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.no-warp-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.show-box {
    text-align: center;
    padding: 8px;
    border-radius: 4px;
    background-color: #33333315;
    font-size: 12px;
    color: var(--el-color-primary);
}
.show-box:hover {
    background-color: #33333380;
    cursor: pointer;
    color: #fff;
}
</style>
