<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <el-button type="primary" plain @click="routeraddrClick('/useradd')" v-if="mypowerlimits.yhgl_tj">添加
            </el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'status'">
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.switchstatus === 4" id="switch">
                    <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭" :active-value="1"
                      :inactive-value="-1" active-color="#13ce66"
                      @click="this.mypowerlimits.yhgl_qy ? changeStatus(scope.row) : ''"
                      :disabled="!this.mypowerlimits.yhgl_qy" inactive-color="#cbcbcb">
                    </el-switch>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-权限-用户管理-->
                <div v-if="mainthreetableope === 45">
                  <el-button type="primary" size="mini" @click="routeraddrClick('/useredit', scope.row)"
                    v-if="mypowerlimits.yhgl_bj">编辑</el-button>
                  <!-- 设置 v-if="mypowerlimits.yhgl_sz" -->
                  <el-button type="primary" size="mini" v-if="scope.row.fundSet == 1"
                    @click="routeraddrClick('/userset', scope.row)">设置</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vuser",
  data() {
    return {
      mainonebread: [
        {
          name: "权限",
        },
        {
          name: "权限管理",
        },
        {
          name: "用户管理",
          path: "/user",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "用户名/姓名",
              name: "keywords",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "所属部门：",
              placeholder: "全部",
              name: "dept_id",
              value: "",
              options: [],
            },
            {
              selectisshow: true,
              ismust: false,
              title: "所属权限：",
              placeholder: "全部",
              name: "group_id",
              value: "",
              options: [],
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: true, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 45, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "id",
          label: "ID",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "username",
          label: "用户名",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "truename",
          label: "姓名",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "dept_id_name",
          label: "所属部门",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "group_id_name",
          label: "所属权限",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "添加时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "last_login_time",
          label: "最后登录时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "last_login_ip",
          label: "最后登录IP",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "是否启用",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // table表单-状态改变
    changeStatus(data) {
      axios
        .post(
          "/admin/Admin/userSwitch",
          {
            id: data.id,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: response.data.message,
            });
          } else {
            // 切换状态失败-复原状态
            if (data.status == 1) {
              data.status = -1;
            } else {
              data.status = 1;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Admin/userList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: this.pagesdatas.keywords,
            dept_id: this.pagesdatas.dept_id,
            group_id: this.pagesdatas.group_id,
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Admin/userList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: this.pagesdatas.keywords,
            dept_id: this.pagesdatas.dept_id,
            group_id: this.pagesdatas.group_id,
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, data) {
      if (data) {
        this.$router
          .push({ path: routeraddr, query: { id: data.id } })
          .catch((error) => error);
      } else {
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Admin/userList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: data.keywords,
            dept_id: data.dept_id,
            group_id: data.group_id,
            page: 1,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
        //转换添加时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无添加时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无添加时间";
        }
        //转换最后登录时间
        if (
          newdata[i].last_login_time === 0 ||
          newdata[i].last_login_time === null ||
          newdata[i].last_login_time === ""
        ) {
          newdata[i].last_login_time = "无最后登录时间";
        } else {
          newdata[i].last_login_time = this.$utils.formatDate(
            newdata[i].last_login_time
          );
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },

    // 所属部门-下拉渲染
    deptSelectEvent() {
      axios
        .get("/admin/Admin/deptSelect", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {},
        })
        .then((response) => {
          var alldatas = Array();
          var num = 0;
          alldatas[num] = { value: '', label: '全部' }
          for (var key in response.data.result) {
            num = num + 1;
            alldatas[num] = {
              value: response.data.result[key].id,
              label: response.data.result[key].name,
            };

          }
          //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
          this.maintwoforminfo.selectleft.select[0].options = alldatas;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 所属权限组-下拉渲染
    groupSelectEvent() {
      axios
        .get("/admin/Admin/groupSelect", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {},
        })
        .then((response) => {
          var alldatas = Array();
          var num = 0;
          alldatas[num] = { value: '', label: '全部' }
          for (var key in response.data.result) {
            num = num + 1;
            alldatas[num] = {
              value: response.data.result[key].id,
              label: response.data.result[key].name,
            };

          }
          //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
          this.maintwoforminfo.selectleft.select[1].options = alldatas;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      // 权限控制-启用禁用-[当其为false的时候不显示该列]
      if (this.mypowerlimits.yhgl_qy == false) {
        this.mainthreetabletitle.splice(this.mainthreetabletitle.length - 1, 1);
      }

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 判断是否路由传参
      let routerdata = this.$route.query;
      let paramsdata = {
        page_size: this.PageSize,
      };
      if (routerdata.dept_id) {
        paramsdata["dept_id"] = routerdata.dept_id;
        // 部门-回显
        this.maintwoforminfo.selectleft.select[0].value = parseInt(
          routerdata.dept_id
        );
      }
      if (routerdata.group_id) {
        paramsdata["group_id"] = routerdata.group_id;
        // 部门-回显
        this.maintwoforminfo.selectleft.select[1].value = parseInt(
          routerdata.group_id
        );
      }
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Admin/userList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: paramsdata,
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });

      // 所属部门-下拉
      this.deptSelectEvent();

      // 所属权限组
      this.groupSelectEvent();
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>