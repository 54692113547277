<template>
    <div class="dpw-upload-excel">
        <div>
            <mainimgesup
                ref="uploadfile"
                :myimgesup="myimgesup"
                :myimgesupisshow="myimgesupisshow"
                v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                v-on:deluploadfile="(data) => deluploadfileEvent(data)"
                @getfiletype="clickUploadTimeEvent"
                @hasSucBackTime="hasSucBackTimeEvent"
            >
            </mainimgesup>
        </div>
        <div v-if="download && !download.is_show ? download.is_show : true">
            <el-button
                :type="download && download.type ? download.type : 'primary'"
                :size="download && download.size ? download.size : 'small'"
                @click="changeDomeEvent(downloadName)"
            >
                {{ download && download.btn_title ? download.btn_title : '下载Excel模板' }}
            </el-button>
        </div>
    </div>
    <div v-if="open == true && activeSchedule == true">
        <activeSchedule :Status="status" :Tips="'正在上传，请勿进行其余操作...'" />
    </div>
    <div class="dpw-tips-box" v-if="tips">
        <span>{{ tips }}</span>
    </div>
</template>

<script>
import mainimgesup from '@/components/Mainimgesup.vue'
import activeSchedule from './activeSchedule.vue'
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
    name: 'modelUploadExcel',
    inject: ['reload'],
    props: ['PostUrl', 'PostDatas', 'Download', 'DownloadName', 'Tips', 'ActiveSchedule'],
    watch: {
        PostUrl: {
            handler(newVal) {
                this.myimgesup.action = newVal ? newVal : '/admin/Service/uploadTemplate'
            },
            deep: true,
            immediate: true
        },
        PostDatas: {
            handler(newVal) {
                this.myimgesup.data = newVal ? newVal : {}
            },
            deep: true,
            immediate: true
        },
        Download: {
            handler(newVal) {
                this.download = newVal
            },
            deep: true,
            immediate: true
        },
        DownloadName: {
            handler(newVal) {
                this.downloadName = newVal
            },
            deep: true,
            immediate: true
        },
        Tips: {
            handler(newVal) {
                this.tips = newVal
            },
            deep: true,
            immediate: true
        },
        ActiveSchedule: {
            handler(newVal) {
                this.activeSchedule = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            download: {
                is_show: false,
                btn_title: '下载Excel模板'
            },
            downloadName: '',
            activeSchedule: false,

            //上传-【Excel批量】-imgesuploade上传图片
            myimgesupisshow: true,
            //上传-【Excel批量】-Excel批量-文件
            myimgesup: {
                uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                data: {}, // upload上传时的携带参数
                action: '', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                multiple: false, //上传多个文件
                title: 'Excel批量导入', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_size: 'small', //button-按钮大小，medium / small / mini
                title_plain: true, //button-按钮是否为线性（plain）
                isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
                tips: '', //提示&建议文字
                limit: '', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    files: {
                        size: 10000, //文件大小（单位kb）
                        type: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], //文件格式
                        typechart: 'Excel' //文件格式提示规则
                    }
                }
            },

            tips: '',

            // 进度条
            status: 1,
            open: false
        }
    },
    methods: {
        // 点击导入按钮开始计时
        clickUploadTimeEvent() {
            this.status = 1
            this.open = true
        },
        hasSucBackTimeEvent() {
            this.status = 2
            setTimeout(() => {
                this.open = false
            }, 500)
        },
        // 【下载批量模板】
        changeDomeEvent(templateName) {
            axios
                .get('/admin/Equipment/downTemplate', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        templateName: templateName
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        let url = response.data.result.download
                        window.open(url, '_blank')
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 【Excel批量】-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            this.$emit('getUploadDatas', data.list)
            // 删除upload列队中的数据
            this.$refs.uploadfile.handleRemove(data)
        },

        changeEvent(data) {
            this.$emit('get-back-data', data)
        }
    },
    components: {
        mainimgesup,
        activeSchedule
    }
})
</script>

<style scoped>
.dpw-upload-excel {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: top;
    gap: 10px;
}

.dpw-tips-box {
    font-size: 13px;
    color: #777777;
    line-height: 28px;
    white-space: pre;
}
</style>
