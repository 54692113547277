<template>
  <div id="mymain">
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box"  >
        <div class="box_title"  >
          <div class="box_title_font">
            <!-- <i class="el-icon-video-camera"></i> -->
            <span style="margin-right: 40px"> 播放详情</span>
          </div>
          <div class="box_title_button">
            <div class="box_title_button-radio">
              <el-radio-group v-model="radio" size="small">
                <el-radio-button :label="7" @change="
                  getDvcInfoEvent(this.id, [this.cid], 2, null, '本周'), (this.selecttime = false)
                ">本周</el-radio-button>
                <el-radio-button :label="30" @change="
                  getDvcInfoEvent(this.id, [this.cid], 2, null, '本月'), (this.selecttime = false)
                ">本月</el-radio-button>
                <el-radio-button :label="365" @change="
                  getDvcInfoEvent(this.id, [this.cid], 2, null, '本年'), (this.selecttime = false)
                ">本年</el-radio-button>
                <el-radio-button :label="2" @change="this.selecttime = true">自定义时间</el-radio-button>
              </el-radio-group>
            </div>
            <div class="box_title_button-select">
              <div v-if="this.selecttime == true">
                <mainselect :myselectleftisshow="myselectleftisshow" :myselectleft="myselectleft"
                  v-on:gettimesrange="(data) => myorderline(data, 2)"></mainselect>
              </div>
            </div>
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <div style="margin-top: 30px">
            <!--折现图-渐变堆叠-->
            <mainecharts ref="linechart" :linegradualisshow="linegradualisshow" :linegradualdata="linegradual">
            </mainecharts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainecharts from "@/components/Mainecharts.vue";
import mainselect from "@/components/Mainselect";
import * as echarts from "echarts";
import { defineComponent, ref } from "vue";
import axios from "axios";

export default defineComponent({
  name: "dvcisonline",
  data() {
    return {
      mainonebread: [
        {
          name: "运营",
        },
        {
          name: "设备广告管理",
        },
        {
          name: "广告列表",
          path: "/advertlist",
        },
        {
          name: "查看广告详情",
        },
        {
          name: "查看设备",
        },
        {
          name: "播放详情",
        },
      ], // 当前页-【面包屑导航】

      // 默认选中
      radio: ref(7),

      linegradualisshow: true, //折现图-渐变堆叠
      linegradual: [],

      //获取相关的设备id
      saveids: [],

      // 广告id
      id: '',
      // 设备id
      cid: '',
      // 设备编号
      client:'',

      selecttime: false,
      myselectleftisshow: true, //筛选查询-左侧字+select单框||select时间
      myselectleft: {
        width: "none",
        divinterval: "", //框右侧距离
        selectwidth: "150px",
        selectinterval: "", //select右侧距离
        select: [
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "ordertime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
    };
  },
  methods: {
    // 自定义时间选择
    myorderline(data, mytimetype) {
      let start = data.valuestart.valueOf() / 1000;
      let end = data.valueend.valueOf() / 1000;

      var getordertime = "";
      if (start == "") {
        getordertime = "";
      } else {
        if (end == "") {
          getordertime = start + " ~ " + (start + 86400 - 1);
        } else {
          getordertime = start + " ~ " + (end + 86400 - 1);
        }
      }
      this.getDvcInfoEvent(this.id, [this.cid], mytimetype, null, getordertime)
    },

    // 时间函数-事件
    getTimeEvent(flag) {
      // 获取今天的时间戳 10位
      let toData = new Date(new Date().toLocaleDateString()).getTime() / 1000;

      // 获取-昨日-时间段
      if (flag == -1) {
        let todayStart = toData - 86400; //开始时间
        let todayEnd = todayStart + 86400 - 1; //结束时间
        let timeduan = todayStart + " ~ " + todayEnd;
        return timeduan;
      }
      // 获取-本日-时间段
      if (flag == 1) {
        let todayStart = toData; //开始时间
        let todayEnd = todayStart + 86400 - 1; //结束时间
        let timeduan = todayStart + " ~ " + todayEnd;
        return timeduan;
      }
      // 获取-最近7日-时间段
      if (flag == 7) {
        let past7daysStart = toData - 7 * 3600 * 24;
        let past7daysEnd = toData - 1;
        let timeduan = past7daysStart + " ~ " + past7daysEnd;
        return timeduan;
      }
      // 获取-最近30日-时间段
      if (flag == 30) {
        let past30daysStart = toData - 30 * 3600 * 24;
        let past30daysEnd = toData - 1;
        let timeduan = past30daysStart + " ~ " + past30daysEnd;
        return timeduan;
      }
    },

    // 循环获取设备信息
    getDvcInfoEvent(advertid, ids, mytimetype, fleg, selecttime) {
      for (var i = 0; i < ids.length; i++) {
        // 标识：1表示需要push
        if (fleg == 1) {
          let datas = {
            idname: "name" + i,
            width: "100%",
            height: "400px",
            text: "播放详情（" + this.client + "）", //折线图-标题
            maincolor: ["#80FFA5", "#00DDFF"], //定义主色调，且有几个模块，定义几个，[数组string类型]
            legenddata: ["播放次数（次）"], //定义模块，[数组string类型]
            subtextx: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], //图表X轴-标题
            gridleft: "5%", //图表距离左侧距离
            gridright: "5%", //图表距离右侧距离
            gridbottom: "5%", //图表距离底部距离
            data: [
              {
                name: "播放次数（次）", //与上方模块定义的名对应
                type: "line",
                stack: "total",
                smooth: true,
                lineStyle: {
                  width: 0,
                },
                showSymbol: false,
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(128, 255, 165)", //渐变的颜色1
                    },
                    {
                      offset: 1,
                      color: "rgba(1, 191, 236)", //渐变的颜色2
                    },
                  ]),
                },
                emphasis: {
                  focus: "series",
                },
                data: [], //对应图表X轴上的数据
              },
            ],
          };
          this.linegradual.push(datas);
        }
        this.ajaxEvent(advertid, ids[i], mytimetype, i, selecttime);
      }
    },

    // ajax传输数据
    ajaxEvent(advertid, clientid, mytimetype, ide, selecttime) {
      let mysearchtime = "";
      // 本周
      if (mytimetype == 7) {
        mysearchtime = this.getTimeEvent(7);
      }
      // 本月
      if (mytimetype == 30) {
        mysearchtime = this.getTimeEvent(30);
      }
      // 本年
      if (mytimetype == 365) {
        mysearchtime = this.getTimeEvent(365);
      }
      // 自定义
      if (mytimetype == 2) {
        mysearchtime = selecttime;
      }

      // 获取设备在线数据
      axios
        .post(
          "/admin/ClientAdvert/clientTimesChart",
          {
            id: advertid,
            cid: clientid,
            search_time: mysearchtime,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            let dataX = response.data.result.show;
            let dataY = response.data.result.playtimes;

            if (dataX.length == 1) {
              dataX = [
                "0:00-2:00",
                "2:00-4:00",
                "4:00-6:00",
                "6:00-8:00",
                "8:00-10:00",
                "10:00-12:00",
                "12:00-14:00",
                "14:00-16:00",
                "16:00-18:00",
                "18:00-20:00",
                "20:00-22:00",
                "22:00-24:00",
              ];
            }
            if (dataY.length == 1) {
              let newdatay = [];
              for (let i = 0; i < dataX.length; i++) {
                newdatay.push(dataY[0]);
              }
              dataY = newdatay;
            }

            if (response.data.code == 0) {
              this.linegradual[ide].subtextx = dataX;
              this.linegradual[ide].data[0].data = dataY;
              this.$refs.linechart.echartGradual(this.linegradual, ide);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {

      this.id = this.$route.query.id;
      this.cid = this.$route.query.cid;
      this.client = this.$route.query.client;

      // 获取今日数据
      this.getDvcInfoEvent(this.id, [this.cid], 2, 1, '本周');
    }
  },
  components: {
    mainone,
    mainecharts,
    mainselect
  },
});
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
  min-height: 750px;
}

.box_title {
  line-height: 40px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.box_title_button-radio {
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
  margin-right: 20px;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}
</style>