<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        >
            <template v-slot:searchModel>
                <div style="display: flex; flex-direction: row; justify-content: left; align-items: center; flex-wrap: wrap; gap: 20px">
                    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; color: #000; font-size: 14px; margin-right: 10px">
                        <div><span>数据来源：</span></div>
                        <div>
                            <el-select v-model="seniorform.data_source" placeholder="数据来源" @change="clickDataSourceEvent" style="width: 160px">
                                <el-option v-for="(item, index) in data_source_options" :key="index" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; color: #000; font-size: 14px; margin-right: 10px">
                        <div><span>报单人：</span></div>
                        <div>
                            <el-input
                                v-model="seniorform.create_name"
                                placeholder="请输入"
                                style="width: 160px"
                                @change="changeCreateNameEvent"
                                :disabled="disabled_input"
                            ></el-input>
                        </div>
                    </div>
                </div>
            </template>
        </maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="mytabletitle.prop === 'name' || mytabletitle.prop === 'status' || mytabletitle.prop === 'addtime' || mytabletitle.prop === 'uptime'"
                            >
                                <!-- 商户名称 -->
                                <div v-if="mytabletitle.prop === 'name'" class="shop-name-box">
                                    <div
                                        :class="this.mypowerlimits.dlslb == true && scope.row.status == 3 ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.dlslb == true && scope.row.status == 3 ? routeraddrClick('/agentlist', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.name }}</span>
                                    </div>
                                    <div
                                        class="shop-name-icon"
                                        :style="{ 'background-color': scope.row.source_type_name == '新' ? 'var(--el-color-success)' : 'var(--el-color-warning)' }"
                                    >
                                        <span>{{ scope.row.source_type_name }}</span>
                                    </div>
                                </div>
                                <!-- 处理状态 -->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <span>{{
                                        scope.row.status ? this.maintwoforminfo.selectleft.select[0].options.filter((n) => n.value == scope.row.status)[0]['label'] : ''
                                    }}</span>
                                </div>
                                <!-- 报单时间 -->
                                <div v-if="mytabletitle.prop === 'addtime'">
                                    <div>
                                        <span> {{ scope.row.addtime !== 0 && scope.row.addtime !== null ? this.$utils.formatDate(scope.row.addtime) : '' }}</span>
                                    </div>
                                </div>
                                <!-- 审核时间 -->
                                <div v-if="mytabletitle.prop === 'uptime'">
                                    <div>
                                        <span> {{ scope.row.uptime !== 0 && scope.row.uptime !== null ? this.$utils.formatDate(scope.row.uptime) : '' }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column :fixed="'right'" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <div>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/reportsingleinfo', scope.row)" v-if="mypowerlimits.bdsq_xq">查看详情</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vreportsingle',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '合作伙伴'
                },
                {
                    name: '代理商管理'
                },
                {
                    name: '报单列表',
                    path: '/reportsingle',
                    icon: true
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '商户名称/手机号',
                            name: 'keywords',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号:',
                            placeholder: '设备编号',
                            name: 'client',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '20px', //框右侧距离
                    selectwidth: '160px',
                    selectinterval: '', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '处理状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: '0'
                                },
                                {
                                    label: '待处理',
                                    value: '1'
                                },
                                {
                                    label: '已驳回',
                                    value: '2'
                                },
                                {
                                    label: '已通过',
                                    value: '3'
                                }
                            ]
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '审核时间：',
                            placeholder: {
                                placeholderstart: '开始时间',
                                placeholderend: '结束时间'
                            },
                            name: 'uptime',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            divinterval: '1%', //上方字中-框右侧距离
                            selectwidth: '100%', //上方字中-总框长度
                            selectmaxwidth: '400px', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        }
                    ]
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '68px', //标题宽度
                    inputwidth: '180px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '负责人：',
                            placeholder: '请输入负责人', //此name为键值
                            name: 'win_user_id',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Statistics/userList',
                            posttype: 'get',
                            inputval: { truename: '', search: 1 } //这里记录需要传的参数
                        }
                    ]
                }
            }, // 筛选-控件信息

            disabled_input: true,
            seniorform: {
                data_source: '0',
                create_name: ''
            },
            data_source_options: [
                {
                    label: '全部',
                    value: '0'
                },
                {
                    label: '业务平台',
                    value: '1'
                },
                {
                    label: '商家助手',
                    value: '2'
                }
            ],
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 6, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 100,
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: '90'
                },
                {
                    fixedstatu: false,
                    prop: 'source_name',
                    label: '数据来源',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '商户名称',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'parent_shop',
                    label: '上级代理商',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'client',
                    label: '设备编号',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'user_type_name',
                    label: '类型',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'idcard_no',
                    label: '身份证号',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号',
                    width: '130'
                },
                {
                    fixedstatu: false,
                    prop: 'ratio',
                    label: '默认佣金比例',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'area_code_1',
                    label: '省级',
                    width: '90'
                },
                {
                    fixedstatu: false,
                    prop: 'area_code_2',
                    label: '市级',
                    width: '90'
                },
                {
                    fixedstatu: false,
                    prop: 'area_code_3',
                    label: '县/区',
                    width: '90'
                },
                {
                    fixedstatu: false,
                    prop: 'contract_source',
                    label: '合同签约来源',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'policy_program',
                    label: '政策方案',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '处理状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'win_truename',
                    label: '负责人',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'create_name',
                    label: '报单人',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '报单时间',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'admin_truename',
                    label: '审核人',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'uptime',
                    label: '审核时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    setup() {},
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            // console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                keywords: this.pagesdatas.keywords,
                win_user_id: this.pagesdatas.win_user_id,
                status: this.pagesdatas.status,
                data_source: this.pagesdatas.data_source,
                create_name: this.pagesdatas.create_name,
                uptime: this.pagesdatas.uptime,
                client: this.pagesdatas.client,
                page: 1,
                page_size: val
            }
            this.pagesdatas['win_user_id_name'] = this.maintwoforminfo.myinputonefull.input[0].content
            this.pagesdatas.totalCount = this.totalCount

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                keywords: this.pagesdatas.keywords,
                win_user_id: this.pagesdatas.win_user_id,
                status: this.pagesdatas.status,
                data_source: this.pagesdatas.data_source,
                create_name: this.pagesdatas.create_name,
                uptime: this.pagesdatas.uptime,
                client: this.pagesdatas.client,
                page: val,
                page_size: this.PageSize
            }
            this.pagesdatas['win_user_id_name'] = this.maintwoforminfo.myinputonefull.input[0].content
            this.pagesdatas.totalCount = this.totalCount

            this.postAxiosEvent(this.pagesdatas)
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                if (routeraddr == '/agentlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.shop_id }
                        })
                        .catch((error) => error)
                }
                if (routeraddr == '/reportsingleinfo') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.id, pagesdatas: JSON.stringify(this.pagesdatas) }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    '/admin/business/index',
                    {
                        keywords: this.pagesdatas.keywords,
                        win_user_id: this.pagesdatas.win_user_id,
                        status: this.pagesdatas.status,
                        data_source: this.pagesdatas.data_source,
                        create_name: this.pagesdatas.create_name,
                        uptime: this.pagesdatas.uptime,
                        client: this.pagesdatas.client,
                        export: 1
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //提交等相关event事件函数
        clickDataSourceEvent(data) {
            this.disabled_input = data == 0 ? true : false
            this.seniorform.create_name = data == 0 ? '' : this.seniorform.create_name
        },
        changeCreateNameEvent(data) {
            this.seniorform.create_name = data
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            var uptime = null
            if (data.uptime.start == '') {
                uptime = ''
            } else {
                if (data.uptime.end == '') {
                    uptime = data.uptime.start + ' ~ ' + (data.uptime.start + 86400 - 1)
                } else {
                    uptime = data.uptime.start + ' ~ ' + (data.uptime.end + 86400 - 1)
                }
            }

            this.pagesdatas = {
                keywords: data.keywords,
                win_user_id: data.win_user_id,
                status: data.status,
                data_source: this.seniorform.data_source,
                create_name: this.seniorform.create_name,
                uptime: uptime,
                client: data.client,
                page: 1,
                page_size: this.PageSize
            }

            // 兼容-设备编号-模糊查询-下拉-反显
            this.pagesdatas['win_user_id_name'] = this.maintwoforminfo.myinputonefull.input[0].content
            this.pagesdatas.totalCount = this.totalCount

            this.postAxiosEvent(this.pagesdatas)
        },
        // 获取数据Axios
        postAxiosEvent(postdata) {
            //加载loading
            this.loading = true
            axios
                .post('/admin/business/index', postdata, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },

        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //将省市区分割
                var arrcity = newdata[i].show_name ? newdata[i].show_name.split(',') : ['', '', '']
                newdata[i].area_code_1 = arrcity[0]
                newdata[i].area_code_2 = arrcity[1] ? arrcity[1] : ''
                newdata[i].area_code_3 = arrcity[2] ? arrcity[2] : ''
            }
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false

            this.pagesdatas.totalCount = this.totalCount
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            let routerinfo = this.$route.query
            if (routerinfo.pagesdatas) {
                this.pagesdatas = JSON.parse(routerinfo.pagesdatas)
                this.PageSize = this.pagesdatas.page_size
                this.totalCount = this.pagesdatas.totalCount

                // 反显-检索的条件等
                // 商户名称/手机号-反显
                if (this.pagesdatas.keywords) {
                    this.maintwoforminfo.myinputone.input[0].content = this.pagesdatas.keywords.toString()
                }
                // 设备编号-反显
                if (this.pagesdatas.client) {
                    this.maintwoforminfo.myinputone.input[1].content = this.pagesdatas.client.toString()
                }
                // 处理状态-反显
                if (this.pagesdatas.status) {
                    this.maintwoforminfo.selectleft.select[0].value = this.pagesdatas.status.toString()
                }
                // 审核时间-反显
                if (this.pagesdatas.uptime) {
                    this.pagesdatas['uptime'] = this.pagesdatas.uptime
                    this.maintwoforminfo.selectleft.select[1].value.valuestart = this.pagesdatas.uptime.split(' ~ ')[0].valueOf() * 1000
                    this.maintwoforminfo.selectleft.select[1].value.valueend = (parseInt(this.pagesdatas.uptime.split(' ~ ')[1]) + 1) * 1000 - 86400000
                }
                // 负责人-反显
                if (this.pagesdatas.win_user_id) {
                    this.maintwoforminfo.myinputonefull.input[0].id = this.pagesdatas.win_user_id
                    this.maintwoforminfo.myinputonefull.input[0].content = this.pagesdatas.win_user_id_name
                }
                // 数据来源-反显
                if (this.pagesdatas.data_source) {
                    this.seniorform.data_source = this.pagesdatas.data_source
                    this.clickDataSourceEvent(this.pagesdatas.data_source)
                }
                // 报单人-反显
                if (this.pagesdatas.create_name) {
                    this.seniorform.create_name = this.pagesdatas.create_name
                }

                this.handleCurrentChange(this.pagesdatas.page)
            } else {
                this.pagesdatas = {
                    page: 1,
                    page_size: this.PageSize
                }
                this.postAxiosEvent(this.pagesdatas)
            }
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.shop-name-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: flex-start;
    gap: 10px;
}

.shop-name-icon {
    border-radius: 4px;
    padding: 0 8px;
    color: #ffffff;
    line-height: 24px;
}
</style>
