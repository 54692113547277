<template>
  <div>
    <!-- 页面 -->
    <div>
      <!-- 标题 -->
      <div class="pages-box-small-title small-content-title-title-one">
        表单设置
      </div>
      <div class="pages-box-small-content">
        <el-tabs tab-position="top" style="height: 100%;margin-top: 10px;">
          <el-tab-pane label="表单样式">
            <div class="pages-box-small-content-form">
              <!-- 页面标题 -->
              <div>
                <div class="small-content-title-title">
                  <div>表单名称</div>
                  <div>
                    <el-radio-group v-model="this.titleisshow" @change="(data)=>changeFormTitleEvent(data,this.dataForm)">
                      <el-radio label="1">显示</el-radio>
                      <el-radio label="2">隐藏</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="small-content-title-content" v-if="this.titleisshow == '1'">
                  <el-input v-model="this.title" placeholder="请输入表单名称" clearable
                    @change="(data)=>inputFormTimeEvent(data,this.dataForm)"></el-input>
                </div>
              </div>
              <!-- 题目名称 -->
              <div>
                <div class="small-content-title-title">
                  <div>题目名称</div>
                  <div>
                    <el-radio-group v-model="this.dataForm.formdata.isshow" @change="subformEvent(this.dataForm)">
                      <el-radio label="1">显示</el-radio>
                      <el-radio label="2">隐藏</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="content-box-line">
                  <div v-for="(item, index) in this.dataForm.formdata.forminfo" :key="index"
                    :class="item.changebox ? 'change-box' : ''" class="small-content-title-content-div"
                    @click="this.changeTableModelEvent(index);">
                    <div style="display: flex;flex-direction: row;">
                      <!-- 题目内容-左侧 -->
                      <div>
                        <div class="small-content-title-title">
                          <div class="small-content-title-title-div">题目名称</div>
                          <div class="small-content-title-title-content">
                            <el-input v-model="item.name" placeholder="请输入题目名称" clearable></el-input>
                          </div>
                        </div>
                        <div class="small-content-title-title">
                          <div class="small-content-title-title-div">是否必填</div>
                          <div class="small-content-title-title-content">
                            <el-radio-group v-model="this.dataForm.formdata.rules['field' + item.key][0].required"
                              @change="(data) => changeismustEvent(data, this.dataForm.formdata.rules, item.key)">
                              <el-radio :label="true">必填</el-radio>
                              <el-radio :label="false">非必填</el-radio>
                            </el-radio-group>
                          </div>
                        </div>
                        <div class="small-content-title-title">
                          <div class="small-content-title-title-div">选框格式</div>
                          <div class="small-content-title-title-content">
                            <el-select v-model="item.inputboxtype.type" placeholder="请选择输入框类型" style="width: 100%;">
                              <el-option v-for="(jtem, jndex) in inputtypeoptions" :key="jndex" :label="jtem.label"
                                :value="jtem.value"
                                @click="(data) => clickselectEvent(data, this.dataForm.formdata.forminfo, index, item.inputboxtype.type)" />
                            </el-select>
                          </div>
                        </div>
                        <div class="small-content-title-title" v-if="item.inputboxtype.type == 2">
                          <div class="small-content-title-title-div">下拉数据</div>
                          <div class="small-content-title-title-content">
                            <el-input v-model="item.inputboxtype.optionsvalue" autosize type="textarea"
                              placeholder="多项之间用“#”号隔开" clearable
                              @input="(data) => changeinputEvent(data, this.dataForm.formdata.forminfo, index, item.inputboxtype.type)">
                            </el-input>
                          </div>
                        </div>
                      </div>
                      <!-- 题目内容-操作 -->
                      <div class="padding-box-right-icon">
                        <div v-if="index != 0" @click="caretTopEvent(item, this.dataForm.formdata.forminfo, index)"><i
                            class="el-icon-caret-top"></i></div>
                        <div v-if="index != this.dataForm.formdata.forminfo.length - 1"
                          @click="caretBottomEvent(item, this.dataForm.formdata.forminfo, index)"><i
                            class="el-icon-caret-bottom"></i>
                        </div>
                        <div @click="delSolidEvent(item, this.dataForm.formdata.forminfo, index)"><i
                            class="el-icon-delete-solid"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 添加按钮 -->
                  <div style="margin: 30px 0 10px 0">
                    <el-button type="primary" plain style="width: 100%;"
                      @click="buttonAddModelEvent(this.dataForm.formdata.forminfo, this.dataForm.formdata.rules)">点击添加
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="表单按钮">
            <mybuttonset :buttonform="this.dataForm.button" v-on:getbuttonsetdata="getbuttonsetdataEvent"
              ref="refmybuttonset"></mybuttonset>
          </el-tab-pane>
        </el-tabs>

      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import mybuttonset from "@/components/weipages/subset/buttonset/buttonset.vue"
import getareajson from '@/assets/json/area.json'

export default {
  name: "formset",
  props: ["formform"],
  setup(props) {
    const dataForm = reactive(props["formform"])
    
    return {
      dataForm,
    };
  },
  data() {
    return {
      inputtypeoptions: [
        {
          value: 1,
          label: '输入框',
        },
        {
          value: 2,
          label: '下拉框',
        },
        {
          value: 3,
          label: '省市区',
        }
      ],

      // [表单名称]是否显示的字段-暂时用data数据控制-未知原因
      titleisshow: '1',
      title:''
    }
  },
  methods: {
    // 选中表单名称单选-是否显示
    changeFormTitleEvent(data,dataforms){
        dataforms.valueisshow = data;
        this.subformEvent(dataforms)
    },
    // 输入表单名称
    inputFormTimeEvent(data,dataforms){
        dataforms.value = data;
        this.subformEvent(dataforms)
    },
    // 鼠标在模块上
    mouseenterEvent(data) {
      console.log('鼠标在模块上：', data)
    },
    // 选中-模块
    changeTableModelEvent(ide) {
      console.log(ide)
      // 移除带有changebox的对象值
      this.dataForm.formdata.forminfo.forEach((element) => {
        if (element.changebox) {
          Reflect.deleteProperty(element, "changebox");
        }
      });

      // 对选中的对象进行changebox赋值
      this.dataForm.formdata.forminfo[ide].changebox = true;
    },
    // 上移-【组件】模块
    caretTopEvent(data, datas, ide) {
      // 删除当前模块
      this.delSolidEvent(data, datas, ide, 1)
      // 向下插入-当前模块
      datas.splice(ide - 1, 0, data);
      // 重新排序数组key
      let newdatas = this.resultKeyEvent(datas)
      // 【由于刷新不及时-加入延时】
      this.dataForm.formdata.forminfo = [];
      setTimeout(() => {
        this.dataForm.formdata.forminfo = newdatas;
      }, 10);
      this.changePhonemodelEvent(ide - 1);
    },
    // 下移-【组件】模块
    caretBottomEvent(data, datas, ide) {
      // 删除当前模块
      this.delSolidEvent(data, datas, ide, 1)
      // 向下插入-当前模块
      datas.splice(ide + 1, 0, data);
      // 重新排序数组key
      let newdatas = this.resultKeyEvent(datas)
      // 【由于刷新不及时-加入延时】
      this.dataForm.formdata.forminfo = [];
      setTimeout(() => {
        this.dataForm.formdata.forminfo = newdatas;
      }, 10);
      this.changePhonemodelEvent(ide + 1);
    },
    // 删除-【组件】模块
    delSolidEvent(data, datas, ide, flag) {
      if (flag) {
        datas.splice(ide, 1)
        let newdatas = this.resultKeyEvent(datas)
        this.dataForm.formdata.forminfo = newdatas
      } else {
        datas.splice(ide, 1)
        let newdatas = this.resultKeyEvent(datas)
        this.dataForm.formdata.forminfo = newdatas
        // 点击删除之后-向上锁定模块
        this.changePhonemodelEvent(ide - 1);
      }
    },
    // 【组件】模块-key重新排序-前端维护key值
    resultKeyEvent(datas) {
      // 重新排-key值
      for (let i = 0; i < datas.length; i++) {
        datas[i].paikey = i
      }
      return datas
    },

    // 选中-是否为必填项
    changeismustEvent(changedata, rules, key) {
      rules['field' + key][0].required = changedata

      this.dataForm.formdata.rules = rules
      this.subformEvent(this.dataForm)
    },
    // 选中-输入框类型
    clickselectEvent(data, formdata, ide, flag) {
      // 选中-下拉框
      if (flag == 2) {
        if (!formdata[ide].inputboxtype.optionsvalue) {
          formdata[ide].inputboxtype.optionsvalue = ''
          formdata[ide].inputboxtype.options = []
        }
        this.dataForm.formdata.forminfo = formdata
      }
      // 选中-省市区
      if (flag == 3) {
        formdata[ide].inputboxtype.optionsvalue = ''
        formdata[ide].inputboxtype.options = getareajson;
        this.dataForm.formdata.forminfo = formdata
      }

      this.subformEvent(this.dataForm)
    },
    // 自定义下拉数据-输入框
    changeinputEvent(data, formdata, ide, flag) {
      if (flag == 2) {
        let newdata = data.split('#');
        formdata[ide].inputboxtype.options = newdata;
        this.dataForm.formdata.forminfo = formdata
      }

      this.subformEvent(this.dataForm)
    },
    // 递归函数-遍历key值
    diguiEvent(keys, index) {
      if (keys.indexOf(index) == -1) {
        return index
      } else {
        index += 1;
        let result = this.diguiEvent(keys, index)
        // 递归中-数据的返回，这一步很重要！！！
        if (result) {
          return result;
        }
      }
    },
    // 添加-表题目model模块
    buttonAddModelEvent(data, rules) {
      let paikey = 0;
      let key = 1;
      // 比较key-赋值key
      if (data.length > 0) {
        paikey = data.length;
        let keys = []
        data.forEach(element => {
          keys.push(element.key)
        });

        let getkey = this.diguiEvent(keys, 1);
        key = getkey;
      }

      // 添加显示模块
      let newdata = {
        paikey: paikey,  // 前端维护的排序key
        key: key,  // 后端固定的key
        name: '题目名称',
        value: '',
        inputboxtype: {
          type: 1, // 输入框类型：1输入框，2下拉框，3多级联选
        },
        changebox: true,
      }
      data.push(newdata);
      this.dataForm.formdata.forminfo = data;

      // 添加-校验对象
      rules['field' + key] = [
        {
          required: true,
          message: "必填项",
          trigger: "blur",
        },
      ];
      this.dataForm.formdata.rules = rules

      this.changeTableModelEvent(paikey);

      this.subformEvent(this.dataForm)
    },

    // 组件数据返回到父组件
    subformEvent(data) {
      this.$emit("getformsetdata", data);
    },
  },
  watch: {
    formform: {
      handler(newVal) {
        //监听form的数据变化
        this.dataForm = newVal;
        this.titleisshow = newVal.valueisshow;
        this.title = newVal.value;
        console.log('newVal is:', newVal)
      },
      deep: true, // 深度监听
      immediate: true, // 是否首次进入触发监听
    },
  },
  components: {
    mybuttonset,
  },
};
</script>

<style scoped>
.small-content-title-title-one {
  border-bottom: 1px solid #e8eaec;
  padding-bottom: 10px;
}

.small-content-title-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
}

.small-content-title-title div {
  margin-right: 20px;
}

.small-content-title-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.small-content-title-content-div {
  padding: 10px;
  border: 1px solid #e8eaec;
  border-radius: 4px;
  margin-bottom: 10px;
}

.small-content-title-content-div:hover {
  cursor: pointer;
  border: 1px dashed var(--el-color-primary);
}

.change-box {
  border: 1px solid var(--el-color-primary);
}

.content-box-line {
  padding: 10px;
  border: 1px solid #e8eaec;
  border-radius: 4px;
  margin-bottom: 10px;
}

.small-content-title-title-div {
  width: 20%;
  min-width: 65px;
  margin-bottom: 10px;
}

.small-content-title-title-content {
  width: 80%;
  min-width: 150px;
  margin-bottom: 10px;
}

.padding-box-right-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50px;
  min-height: 140px;
  background-color: #465fff27;
  border-radius: 2px;
}

.padding-box-right-icon:hover {
  background-color: #465fff38;
}

.padding-box-right-icon div {
  margin-bottom: 10px;
  text-align: center;
  color: #b8b8b8;
}

.padding-box-right-icon div:hover {
  color: var(--el-color-primary);
  cursor: pointer;
}

.pages-box-small-content-form {
  height: 620px;
  overflow: hidden;
  overflow-y: visible;
}
</style>

