<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div class="box-list">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width" :min-width="mytabletitle.minwidth">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'payway' ||
                mytabletitle.prop === 'remark' ||
                mytabletitle.prop === 'relation' ||
                mytabletitle.prop === 'user_account'
              ">
                <div v-if="mytabletitle.prop === 'relation'">
                  <!--金融产品合伙人用户id-->
                  <div v-if="scope.row.user_type == 5" :class="this.mypowerlimits.yhlb == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.yhlb == true ? routeraddrClick('/userlist', scope.row) : ''">
                    <span>{{ scope.row.user_account }}</span>
                  </div>

                </div>
                <!-- 提现账户-渲染数据 -->
                <div v-if="mytabletitle.prop === 'user_account'">
                  <div v-if="scope.row.payway == 3">
                    <span>{{ scope.row.bank_card }}</span>
                  </div>
                  <div v-else>
                    <span>{{ scope.row.user_account }}</span>
                  </div>
                </div>
                <!-- 提现方式- -->
                <div v-if="mytabletitle.prop === 'payway'">
                  <div>
                    <span>{{ scope.row.payway == 1 ? "微信" : scope.row.payway == 2 ? "支付宝" : "银行卡" }}{{ scope.row.is_auto
                        == 1 ? "自动提现" : (scope.row.payway != 3 ? "手动提现" : '提现')
                    }}</span>
                  </div>
                </div>
                <!-- 状态-渲染数据 -->
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.textstatus">
                    <span>{{ scope.row.textstatus }}</span>
                  </div>
                </div>
                <!-- 处理备注-渲染数据 -->
                <div v-if="mytabletitle.prop === 'remark'">
                  <div>
                    <el-popover placement="top" trigger="hover" title="处理备注">
                      <template #reference>
                        <div class="box-table-addr">
                          <span :ref="'btnShowNum' + scope.$index"><span>{{ scope.row.remark }}</span></span>
                        </div>
                      </template>
                      <template #default>
                        <div style="margin-top: 10px">
                          <span>{{ scope.row.remark }}</span>
                        </div>
                      </template>
                    </el-popover>
                  </div>
                </div>
              </template>
            </el-table-column>

            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" prop="myoperation" id="operation" :width="mainthreehandle">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-代理商列表-->
                <!-- -->
                <div>
                  <!-- 暂时关闭 -->
                  <el-button v-if="(scope.row.status == 2)" type="primary" size="mini"
                    @click="addTanClick(scope.row, (dialogVisible = true))">处理</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" :layout="Layout"
              :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!--弹出框-处理-->
    <el-dialog v-model="dialogVisible" title="处理" :width="this.searchwidth" :before-close="handleCloseSenior">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
        <div class="deal-box">
          <el-form-item label="财务审核：" prop="status">
            <el-radio-group v-model="ruleForm.status">
              <el-radio :label="1">审核通过</el-radio>
              <el-radio :label="2">审核不通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="ruleForm.remark" :autosize="{ minRows: 2 }" type="textarea" placeholder="请填写备注"
              maxlength="100">
            </el-input>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="resetForm()">取消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')" :disabled="mydisabled">确认</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vderwithdrawalrecord",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "金融产品",
        },
        {
          name: "财务",
        },
        {
          name: "金融产品提现记录",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "用户ID",
              name: "keywords",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: "申请时间：",
              placeholder: {
                placeholderstart: "请选择开始时间",
                placeholderend: "请选择结束时间",
              },
              name: "addtime",
              value: {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
              },
              rangeseparator: "至", //分隔符
              selectmaxwidth: "100%", //时间段-最大宽度
              marginright: "0", //与右侧距离
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "thirdparty",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      }, // 筛选-【筛选查询】-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 33, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: false,
          prop: "trade_no",
          label: "提现单号",
          width: "250",
        },
        {
          fixedstatu: false,
          prop: "relation",
          label: "用户ID",
          minwidth: "120",
        },
        {
          fixedstatu: false,
          prop: "amount",
          label: "提现金额",
          minwidth: "120",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "申请时间",
          width: "110",
        },
        {
          fixedstatu: false,
          prop: "finishtime",
          label: "完成时间",
          width: "110",
        },
        {
          fixedstatu: false,
          prop: "user_account",
          label: "提现账户",
          minwidth: "150",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "状态",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "remark",
          label: "处理备注",
          minwidth: "200",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1000, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      Layout: " sizes, prev, pager, next, jumper",

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "用户ID",
            name: "keywords",
            content: "",
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "提现单号：",
            placeholder: "请输入提现单号",
            name: "trade_no",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "状态:",
            placeholder: "全部",
            name: "status",
            value: "",
            options: [
              {
                value: "",
                label: "全部",
              },
              {
                value: "-1",
                label: "申请中",
              },
              {
                value: "1",
                label: "提现成功",
              },
              {
                value: "2",
                label: "提现失败",
              },
              {
                value: "3",
                label: "处理中",
              },
              {
                value: "4",
                label: "已拒绝",
              },
            ],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "申请时间区间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "addtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myselecttopisshow: true, //高级筛选-select单框-放input框之前
      myselecttop: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "平台来源：",
            placeholder: "全部",
            name: "thirdparty",
            value: "1", //显示值，初始状况，显示值与默认值需相同
            valueisshow: true,
            valuedefault: "1", //设置默认值
            options: componentProperties.get('thirdpartyoption').options,
          },
        ],
      },

      //分页
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      //弹窗相关数据
      dialogVisible: ref(false), //弹窗显示&隐藏
      ruleForm: {
        id: "", //当前	提现记录id
        status: 2, //处理状态 1通过 2不通过
        remark: "",
      }, //弹窗中-form表单
      rules: {
        status: [
          {
            required: true,
            message: "财务审核为必填项",
            trigger: "blur",
          },
        ],
      }, //弹窗中-form表单-必填规则

      // 下载发票
      dialogVisible1: ref(false),
      fapiaosdata: [],

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用

      mydisabled: false
    };
  },
  setup() { },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 点击下载发票-按钮
    clickfapiaoEvent(data) {
      this.fapiaosdata = data.fapiao.split(',')
    },
    // 下载发票-事件
    downfapiaoEvent(data) {
      window.open(data);
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/cashflow/withdraw",
          {
            keywords: this.pagesdatas.keywords,
            thirdparty: this.pagesdatas.thirdparty,
            status: this.pagesdatas.status,
            user_type: '5',
            trade_no: this.pagesdatas.trade_no,
            addtime: this.pagesdatas.addtime,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / this.PageSize == this.lastpage) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage += 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      // console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/cashflow/withdraw",
          {
            keywords: this.pagesdatas.keywords,
            thirdparty: this.pagesdatas.thirdparty,
            status: this.pagesdatas.status,
            user_type: '5',
            trade_no: this.pagesdatas.trade_no,
            addtime: this.pagesdatas.addtime,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / val == this.PageSize) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage = 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      this.ruleForm.id = taninfo.id;
      this.ruleForm.status = 2;
      this.ruleForm.remark = "";
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 跳转-代理商列表
        if (routeraddr == "/agentlist") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                id: rowdata.relation_id,
                thirdparty: rowdata.thirdparty
              },
            })
            .catch((error) => error);
        }
        // 跳转-用户列表
        if (routeraddr == "/userlist") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                userid: rowdata.user_account,
                thirdparty: rowdata.thirdparty,
              },
            })
            .catch((error) => error);
        }
        // 跳转-合伙人列表
        if (routeraddr == "/partnerlist") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                userid: rowdata.user_account,
              },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //弹出框-提交表单
    submitForm(formName) {
      // 前端防抖
      this.mydisabled = true;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          var getdatas = this.$refs[formName].model;
          axios
            .post(
              "/admin/cashflow/withdrawHandle",
              {
                id: getdatas.id,
                status: getdatas.status,
                remark: getdatas.remark,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = ref(false); //关闭弹窗
                ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                    this.mydisabled = false;
                  },
                });
              } else {
                this.mydisabled = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              this.mydisabled = false;
            });
        } else {
          console.log("error submit!!");
          this.mydisabled = false;
          return false;
        }
      });
    },
    //弹出框-取消表单
    resetForm() {
      this.dialogVisible = ref(false); //关闭弹窗
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-申请时间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.addtime = getaddtime;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/cashflow/withdraw",
          {
            keywords: data.keywords,
            thirdparty: data.thirdparty,
            status: data.status,
            trade_no: data.trade_no,
            user_type: '5',
            addtime: getaddtime,
            page_size: this.PageSize,
            page: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-申请时间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.addtime = getaddtime;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/cashflow/withdraw",
          {
            keywords: data.keywords,
            thirdparty: data.thirdparty,
            addtime: getaddtime,
            user_type: '5',
            page_size: this.PageSize,
            page: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata, flag) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //渲染-状态
        if (newdata[i].status) {
          if (newdata[i].status == -1) {
            newdata[i].textstatus = "申请中";
          }
          if (newdata[i].status == 1) {
            newdata[i].textstatus = "提现成功";
          }
          if (newdata[i].status == 2) {
            newdata[i].textstatus = "提现失败";
          }
          if (newdata[i].status == 3) {
            newdata[i].textstatus = "处理中";
          }
          if (newdata[i].status == 4) {
            newdata[i].textstatus = "已拒绝";
          }
        }

        // 提现金额-分转元（前端维护）
        if (newdata[i].amount) {
          newdata[i].amount = (newdata[i].amount / 100).toFixed(2);
        }

        // 转换-申请时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime == 0 || newdata[i].addtime == null) {
            newdata[i].addtime = "";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "";
        }

        // 转换-完成时间
        if (newdata[i].finishtime) {
          if (newdata[i].finishtime == 0 || newdata[i].finishtime == null) {
            newdata[i].finishtime = "";
          } else {
            newdata[i].finishtime = this.$utils.formatDate(
              newdata[i].finishtime
            );
          }
        } else {
          newdata[i].finishtime = "";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      // this.totalCount = successdata.total;
      // this.currentpage = successdata.current_page; //当前页面页数
      // this.lastpage = successdata.last_page;
      this.loading = false;

      if (successdata.total == null) {
        // 数据小于当前页数时判断
        if (newdata.length < this.PageSize) {
          this.totalCount = this.PageSize - 1;
        } else {
          if (this.totalCount < this.PageSize) {
            // 重设置-初始值
            this.totalCount = 1000;
            this.Layout = "sizes, prev, pager, next, jumper";
          } else {
            if (flag == 1) {
              this.currentPage = 1;
            }
          }
        }
      } else {
        this.totalCount = successdata.total;
        this.currentpage = successdata.current_page; //当前页面页数
        this.lastpage = successdata.last_page;
        this.Layout = "total, sizes, prev, pager, next, jumper";
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      // 定义
      this.pagesdatas = {
        thirdparty: "1",
        user_type: '5',
        page_size: this.PageSize,
        page: 1,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      // 角色
      if (routerinfo.user_type) {
        this.pagesdatas.user_type = routerinfo.user_type;
        this.myselect.select[1].value = routerinfo.user_type
      }

      //通过Ajax向后台获取数据
      axios
        .post("/admin/cashflow/withdraw", this.pagesdatas, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
.box-list {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  line-height: 40px;
  padding: 0 20px;
}

.deal-box {
  max-height: 450px;
  overflow: hidden;
  overflow-y: visible;
  margin: 20px 5% 0 0;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-table-addr {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.mytabletext,
.box-table-addr:hover {
  color: var(--el-color-primary);
  cursor: pointer;
}

/* 下载发票 */
.downfapiao-box {
  background-color: rgba(236, 236, 236, 0.473);
  border-radius: 4px;
}

.downfapiao-box:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.downfapiao-box:hover .downfapiao-box-flex-span {
  color: var(--el-color-primary);
}

.downfapiao-box-flex-image:hover {
  border: 1px solid var(--el-color-primary);
}

.downfapiao-box-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px 20px;
}

.downfapiao-box-flex-left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.downfapiao-box-flex-image {
  width: 100px;
  height: 60px;
  vertical-align: middle;
  border: 1px solid #3333331c;
  border-radius: 2px;
  margin-right: 10px;
}

.downfapiao-box-flex-span {
  color: #333333;
}
</style>