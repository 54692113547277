<template>
    <div class="block" style="text-align: left; margin: 20px 0">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalcount"
        >
        </el-pagination>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'modelPages',
    inject: ['reload'],
    props: ['totalCount', 'currentPage', 'pageSizes', 'PageSize'],
    watch: {
        totalCount: {
            handler(newVal) {
                this.totalcount = newVal
            },
            deep: true,
            immediate: true
        },
        currentPage: {
            handler(newVal) {
                this.currentpage = newVal
            },
            deep: true,
            immediate: true
        },
        pageSizes: {
            handler(newVal) {
                this.pagesizes = newVal
            },
            deep: true,
            immediate: true
        },
        PageSize: {
            handler(newVal) {
                this.pagesize = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            totalcount: 1, //数据列表中-数据总条数
            currentpage: 1,
            pagesizes: [10, 20, 50, 100, 1000, 10000],
            pagesize: 10
        }
    },
    methods: {
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            this.$emit('size-change', val)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            this.$emit('current-change', val)
        }
    },
    components: {}
})
</script>

<style scoped></style>
