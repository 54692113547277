<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'is_use' || mytabletitle.prop === 'uid'
              ">
                <!-- 是否启用-渲染数据 -->
                <div v-if="mytabletitle.prop === 'is_use'">
                  <div v-if="scope.row.switchstatus === 4" id="switch">
                    <el-switch v-model="scope.row.is_use" active-text="开启" inactive-text="关闭" :active-value="1"
                      :inactive-value="2" active-color="#13ce66"
                      @click="this.mypowerlimits.ggzlb_qy == true ? changeStatus(scope.row) : ''"
                      :disabled="!this.mypowerlimits.ggzlb_qy" inactive-color="#cbcbcb"></el-switch>
                  </div>
                </div>
                <!-- 用户ID-渲染数据 -->
                <div v-if="mytabletitle.prop === 'uid'">
                  <span :class="this.mypowerlimits.yhlb == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.yhlb == true ? routeraddrClick('/userlist', scope.row) : ''">
                    {{ scope.row.uid }}</span>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-订单-广告主列表-->
                <div v-if="mainthreetableope === 20">
                  <el-button type="primary" size="mini" @click="routeraddrClick('/advertiserslistinfo', scope.row)">查看
                  </el-button>
                  <el-button v-if="
                    scope.row.checker != '系统' &&
                    scope.row.status == '待审核' &&
                    this.mypowerlimits.ggzlb_sh
                  " type="primary" size="mini" @click="addTanClick(scope.row), (this.dialogVisible = true)">审核
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-审核-->
      <el-dialog v-model="dialogVisible" title="广告主审核" :width="searchwidth" :before-close="handleCloseSenior">
        <el-form ref="check" :model="this.check" :rules="this.rules" label-width="150px" style="padding-right: 20px">
          <!--以下是左侧字-->
          <el-form-item label="商家名称：" prop="name" required="true">
            <div>{{ check.name }}</div>
          </el-form-item>
          <el-form-item label="审核结果：" prop="radios">
            <el-radio-group v-model="check.radios">
              <el-radio label="1">通过</el-radio>
              <el-radio label="2">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="审核备注：" prop="remark">
            <el-input type="textarea" :rows="4" placeholder="请填写备注" v-model="check.remark">
            </el-input>
          </el-form-item>
          <div style="text-align: right">
            <el-button @click="resetFormSenior('check')">取消 </el-button>
            <el-button type="primary" @click="onSubmitSenior('check')">确定
            </el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vadvertiserslist",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "订单",
        },
        {
          name: "广告主管理",
        },
        {
          name: "广告主列表",
          path: "/advertiserslist",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "商家名称/用户id",
              name: "keywords",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "审核状态：",
              placeholder: "全部",
              name: "status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "待审核",
                  value: "0",
                },
                {
                  label: "审核通过",
                  value: "1",
                },
                {
                  label: "审核拒绝",
                  value: "2",
                },
              ],
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "platform",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 20, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 120, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "uid",
          label: "用户ID",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "company",
          label: "商家名称",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "category",
          label: "商家业态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "tel",
          label: "联系电话",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "address",
          label: "地址",
          width: "300",
        },
        {
          fixedstatu: false,
          prop: "checkstatus",
          label: "审核状态",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "checker",
          label: "审核人",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "check_note",
          label: "审核备注",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "is_use",
          label: "是否启用",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "uptime",
          label: "修改时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      dialogVisible: false,
      check: {
        id: "",
        name: "",
        radios: "2",
        remark: "",
      },
      rules: {
        radios: [
          {
            required: true,
            message: "审核结果为必选项",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "审核备注为必填项",
            trigger: "blur",
          },
        ],
      },

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // table表单-状态改变
    changeStatus(data) {
      axios
        .post(
          "/admin/Advertiser/changeStatus",
          {
            id: data.id,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: response.data.message,
            });
          } else {
            // 切换状态失败-复原状态
            if (data.is_use == 1) {
              data.is_use = 2;
            } else {
              data.is_use = 1;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/index",
          {
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            platform: this.pagesdatas.platform,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/index",
          {
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            platform: this.pagesdatas.platform,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置-审核按钮
    addTanClick(taninfo) {
      console.log(taninfo);
      this.check.id = taninfo.id;
      this.check.name = taninfo.company;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 跳转-用户列表
        if (routeraddr == "/userlist") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                userid: rowdata.uid,
                thirdparty: this.pagesdatas.platform,
              },
            })
            .catch((error) => error);
        }
        // 跳转-广告主详情
        if (routeraddr == "/advertiserslistinfo") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                id: rowdata.id,
                thirdparty: this.pagesdatas.platform,
              },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },

    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //审核弹出框--确认
    onSubmitSenior(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          console.log("------------");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-取消
    resetFormSenior(formData) {
      this.dialogVisible = ref(false); //添加奖品弹窗
      this.$refs[formData].resetFields(); //重置弹窗内容
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/index",
          {
            keywords: data.keywords,
            status: data.status,
            platform: data.platform,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;

        // 转换-审核状态
        if (newdata[i].status) {
          newdata[i].checkstatus = newdata[i].status;
        }

        // 转换-修改时间
        if (newdata[i].uptime) {
          if (newdata[i].uptime === 0 || newdata[i].uptime === null) {
            newdata[i].uptime = "无修改时间";
          } else {
            newdata[i].uptime = this.$utils.formatDate(newdata[i].uptime);
          }
        } else {
          newdata[i].uptime = "无修改时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 定义
      this.pagesdatas = {
        platform: "1",
        page_size: this.PageSize,
        page: 1,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.userid) {
        this.pagesdatas = {
          keywords: routerinfo.userid,
          platform: routerinfo.thirdparty,
          page_size: this.PageSize,
          page: 1,
        };
        this.maintwoforminfo.myinputone.input[0].content = routerinfo.userid;
      }

      //通过Ajax向后台获取数据
      axios
        .post("/admin/Advertiser/index", this.pagesdatas, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
    // maininput,
    // mainselect,
  },
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>