<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="primary" plain @click="routeraddrClick('/couponadd')" v-if="this.mypowerlimits.yhq_tj">添加 </el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'images' || mytabletitle.prop === 'add_user_info' || mytabletitle.prop === 'addtime'">
                                <div v-if="mytabletitle.prop === 'images'">
                                    <div v-if="scope.row.images">
                                        <el-image class="imagesshow" :src="scope.row.images" :preview-src-list="[scope.row.images]" fit="cover"></el-image>
                                    </div>
                                </div>
                                <!-- 创建人 -->
                                <div v-if="mytabletitle.prop === 'add_user_info'">
                                    <div>
                                        <span>姓名：</span><span>{{ scope.row.truename }}</span>
                                    </div>
                                    <div>
                                        <span>账户名：</span><span>{{ scope.row.username }}</span>
                                    </div>
                                </div>
                                <!-- 创建时间 -->
                                <div v-if="mytabletitle.prop === 'addtime'">
                                    <div>
                                        <span>{{ scope.row.addtime != 0 ? this.$utils.formatDate(scope.row.addtime) : '' }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-运营-优惠券-->
                                <div v-if="mainthreetableope === 26">
                                    <div v-if="scope.row.status === -1">
                                        <el-button type="primary" size="mini" v-if="this.mypowerlimits.yhq_bj" @click="routeraddrClick('/couponedit', scope.row)">编辑</el-button>
                                        <el-button
                                            v-if="this.mypowerlimits.yhq_sc"
                                            @click.prevent="deleteRow(scope.$index, mainthreetableData)"
                                            type="danger"
                                            size="mini"
                                            icon="el-icon-delete"
                                        ></el-button>
                                    </div>
                                    <div v-if="scope.row.status === 1">
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            v-if="scope.row.is_show === -1 && scope.row.source !== 2 && this.mypowerlimits.yhq_lqm"
                                            @click="getCodeCardEvent(scope.row), (dialogVisible1 = true)"
                                            >领券码
                                        </el-button>
                                        <el-button type="primary" size="mini" @click="routeraddrClick('/couponinfo', scope.row)">查看 </el-button>
                                        <el-button type="primary" size="mini" @click="routeraddrClick('/coupondata', scope.row)" v-if="this.mypowerlimits.yhq_qsj">数据 </el-button>
                                        <el-button v-if="scope.row.source !== 2 && this.mypowerlimits.yhq_zjfxl" type="primary" size="mini" @click="add(scope.row)"
                                            >增加发行量
                                        </el-button>
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            v-if="scope.row.source !== 2 && this.mypowerlimits.yhq_sx"
                                            @click.prevent="loseRow(scope.$index, mainthreetableData)"
                                            >失效
                                        </el-button>
                                    </div>
                                    <div v-if="scope.row.status === 2 || scope.row.status === 3">
                                        <el-button type="primary" size="mini" @click="routeraddrClick('/couponinfo', scope.row)">查看 </el-button>
                                        <el-button type="primary" size="mini" @click="routeraddrClick('/coupondata', scope.row)" v-if="this.mypowerlimits.yhq_qsj">数据 </el-button>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!-- 弹出框-领券码 -->
            <el-dialog v-model="this.dialogVisible1" title="领券码" :width="this.searchwidth" :before-close="handleCloseSenior1">
                <div class="dialog-box-flex">
                    <!-- 小程序兑换码code -->
                    <div style="text-align: center">
                        <span style="color: red; font-size: 14px"> 小程序兑换码 : {{ this.minicode }} </span>
                    </div>
                    <div v-if="this.downcodeurl == ''" style="text-align: center">
                        <i class="el-icon-loading"></i>
                    </div>
                    <div style="text-align: center">
                        <img :src="this.downcodeurl" style="width: 300px; margin-top: 10px; border: 1px solid #ececec" />
                    </div>
                </div>
                <div style="margin-top: 20px; text-align: center">
                    <el-button type="primary" style="width: 30%" @click="downloadImageEvent(this.downcodeurl)">下载领券码</el-button>
                </div>
            </el-dialog>

            <!--增加发行量弹出框-->
            <el-dialog v-model="dialogVisible" :title="addnum.type == 5 ? '增加商家券发行量' : '增加优惠券发行量'" :width="searchwidth" :before-close="handleCloseSenior">
                <el-form ref="addnum" :model="addnum" :rules="ruless" label-width="150px" style="padding-right: 20px">
                    <!--以下是左侧字-->
                    <el-form-item label="当前发行量：" prop="name">
                        <el-input v-model="addnum.number" :disabled="true" style="text-align: right; width: 80%"></el-input>
                    </el-form-item>
                    <el-form-item label="新增发行量：" prop="newnum">
                        <el-input v-model="addnum.newnum" :min="0" style="text-align: right; width: 80%" @input="addnums" type="number">
                            <template #append>张</template>
                        </el-input>
                    </el-form-item>
                    <el-divider></el-divider>
                    <el-form-item label="增加后发行量：" prop="allnum">
                        <el-input v-model="addnum.allnum" :disabled="true" style="text-align: right; width: 80%">
                            <template #append>张</template>
                        </el-input>
                        <div style="height: 20px; line-height: 20px">
                            <span style="font-size: 12px; color: #999999">总发行量{{ addnum.type == 5 ? '不能低于1000000' : '不可超过10000' }}</span>
                        </div>
                    </el-form-item>
                    <div style="text-align: right">
                        <el-button @click="resetFormSenior('addnum')">取消 </el-button>
                        <el-button type="primary" @click="onSubmitSenior('addnum')">确定 </el-button>
                    </div>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
// import {ElMessage} from "element-plus";
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import dict from '@/assets/js/dict.js'

export default defineComponent({
    name: 'vcoupon',
    inject: ['reload'], //刷新引用
    data() {
        return {
            // 优惠券发行量
            addnum: {
                type: '',
                id: '',
                number: '',
                newnum: '',
                allnum: ''
            },
            dialogVisible: false,
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '营销玩法'
                },
                {
                    name: '优惠券',
                    path: '/coupon'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '优惠券名称',
                            name: 'title',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '创建人姓名/账户名',
                            name: 'keywords',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: ''
                                },
                                {
                                    label: '未开始',
                                    value: '-1'
                                },
                                {
                                    label: '进行中',
                                    value: '1'
                                },
                                {
                                    label: '已结束',
                                    value: '2'
                                },
                                {
                                    label: '已失效',
                                    value: '3'
                                }
                            ]
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '适用渠道：',
                            placeholder: '全部',
                            name: 'type',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: ''
                                },
                                ...Object.keys(dict.coupon.use_type).map((n) => {
                                    return {
                                        label: dict.coupon.use_type[n],
                                        value: n
                                    }
                                })
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 26, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 320, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'title',
                    label: '优惠券名称',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '名称备注',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'type_name',
                    label: '适用渠道',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'is_new_name',
                    label: '仅限新用户',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'status_name',
                    label: '优惠券状态',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'number',
                    label: '发行量',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'money',
                    label: '面值',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'limits_name',
                    label: '每人限领',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'use_time',
                    label: '有效时间',
                    width: '240'
                },
                {
                    fixedstatu: false,
                    prop: 'add_user_info',
                    label: '创建人',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '创建时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {}
            }, //弹框

            // 领券码
            dialogVisible1: ref(false), //弹窗显示&隐藏
            downcodeurl: '', // 下载领券码-当前行信息
            minicode: '',

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            // rows.splice(index, 1)
            console.log('shanchu :' + rows[index].id)
            ElMessageBox.confirm("确定删除该条 ' " + rows[index].title + " ' 优惠券吗?", '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/Coupon/del',
                            {
                                id: rows[index].id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功'
                                })
                                rows.splice(index, 1)
                            }
                        })
                        .catch(function (error) {
                            // 请求失败处理
                            ElMessage({
                                type: 'error',
                                message: '删除失败！' + error
                            })
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除'
                    })
                })
        },
        //table表-操作中-失效按钮（函数）
        loseRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            // rows.splice(index, 1)
            console.log('shanchu :' + rows[index].id)
            ElMessageBox.confirm('优惠券失效后，买家无法再领取该优惠券，但之前已领取的优惠券，在有效期内仍可继续使用。', '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/Coupon/cancel',
                            {
                                id: rows[index].id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '失效成功'
                                })
                                // rows.splice(index, 1);
                                // this.$router.go(0)
                                this.reload() //刷新
                            }
                        })
                        .catch(function (error) {
                            // 请求失败处理
                            ElMessage({
                                type: 'error',
                                message: '失效失败！' + error
                            })
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消失效'
                    })
                })
        },
        downloadIamge(imgsrc, name) {
            //下载图片地址和图片名
            let image = new Image()
            // 解决跨域 Canvas 污染问题
            image.setAttribute('crossOrigin', 'anonymous')
            image.onload = function () {
                let canvas = document.createElement('canvas')
                canvas.width = image.width
                canvas.height = image.height
                let context = canvas.getContext('2d')
                context.drawImage(image, 0, 0, image.width, image.height)
                let url = canvas.toDataURL('image/png') //得到图片的base64编码数据
                let a = document.createElement('a') // 生成一个a元素
                let event = new MouseEvent('click') // 创建一个单击事件
                a.download = name || 'photo' // 设置图片名称
                a.href = url // 将生成的URL设置为a.href属性
                a.dispatchEvent(event) // 触发a的单击事件
            }
            image.src = imgsrc
        },

        // 领券码-点击事件
        getCodeCardEvent(rowdata) {
            this.minicode = rowdata.code
            axios
                .post(
                    '/admin/Coupon/code',
                    {
                        id: rowdata.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        this.downcodeurl = response.data.result
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    ElMessage({
                        type: 'error',
                        message: '请求失败！' + error
                    })
                })
        },
        // 下载领券码
        downloadImageEvent(codeurl) {
            window.open(codeurl)
            setTimeout(() => {
                this.dialogVisible1 = false
            }, 500)
        },

        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/Coupon/index',
                    {
                        keywords: this.pagesdatas.keywords,
                        title: this.pagesdatas.title,
                        status: this.pagesdatas.status,
                        type: this.pagesdatas.type,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/Coupon/index',
                    {
                        keywords: this.pagesdatas.keywords,
                        title: this.pagesdatas.title,
                        status: this.pagesdatas.status,
                        type: this.pagesdatas.type,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row'
            }
            return ''
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            this.tancontent.content = taninfo.content
            this.tancontent.title = taninfo.title
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, data) {
            if (data) {
                this.$router.push({ path: routeraddr, query: { id: data.id } }).catch((error) => error)
            } else {
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //增加发行量输入框弹出框-取消
        handleCloseSenior() {
            this.dialogVisible = ref(false) //添加奖品弹窗
            //弹框-form表单数据
            var addnum = {
                type: '',
                id: '',
                number: '',
                newnum: '',
                allnum: ''
            }
            this.addnum = addnum
            this.$refs['addnum'].resetFields() //重置弹窗内容
        },
        //增加发行量输入框弹出框-取消
        resetFormSenior(formData) {
            this.dialogVisible = ref(false) //添加奖品弹窗
            //弹框-form表单数据
            var addnum = {
                type: '',
                id: '',
                number: '',
                newnum: '',
                allnum: ''
            }
            this.addnum = addnum
            this.$refs[formData].resetFields() //重置弹窗内容
        },
        //增加发行量输入框监听
        addnums(e) {
            this.addnum.allnum = this.addnum.number - 0 + (e - 0)
        },
        //增加发行量弹窗
        add(data) {
            this.addnum.id = data.id
            this.addnum.number = data.number
            // 兼容商家券的适用渠道
            this.addnum.type = data.type
            this.dialogVisible = ref(true) //增加发行量弹窗
        },
        //发行量弹框确认
        onSubmitSenior(formData) {
            let getdatas = this.$refs[formData].model
            if (!getdatas.newnum) {
                this.$message({
                    message: '请填写新增发行量',
                    type: 'warning'
                })
                throw new Error()
            }
            if (getdatas.type !== 5) {
                if (getdatas.allnum - 0 > 10000) {
                    this.$message({
                        message: '总发行量不可超过10000',
                        type: 'warning'
                    })
                    throw new Error()
                }
            }

            this.$refs[formData].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/Coupon/addNum',
                            {
                                id: getdatas.id,
                                number: getdatas.newnum - 0
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '增加成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.dialogVisible = ref(false) //关闭弹窗
                                        this.$refs[formData].resetFields() //重置弹窗内容
                                        this.reload() //刷新
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },

        //导出数据
        exportDatas(tableData) {
            console.log(tableData)
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/Coupon/index',
                    {
                        keywords: data.keywords,
                        title: data.title,
                        status: data.status,
                        type: data.type,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //该字段表示search按钮，且status为1表示启用，2表示禁用
                newdata[i].switchstatus = 4
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/Coupon/index',
                    {
                        page: 1,
                        page_size: 20
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
        // maininput,
        // mainselect,
    }
})
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
