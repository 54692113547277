<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="
                margin-top: 10px;
                background-color: #ffffff;
                border-radius: 4px;
                line-height: 40px;
                padding: 0 20px;
                ">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!-- 添加-按钮 -->
                        <el-button type="primary" plain class="max_search" @click="routeraddrClick('/weipagesadd')">添加
                        </el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
                        :row-class-name="tableRowClassName" :data="
                            mainthreetableData.slice(
                                (currentPage - lastpage) * PageSize,
                                currentPage * PageSize
                            )
                        " style="width: 100%" min-height="900" border v-loading="loading"
                        element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange"
                        @cell-click="handleOneData">
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i"
                            :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label"
                            :width="mytabletitle.width">
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'status'">
                                <!--用户ID-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div>
                                        <span>{{ scope.row.status == 1 ? '发布' : '未发布' }}</span>
                                    </div>
                                </div>

                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
                            prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-意向客户列表-->
                                <div v-if="mainthreetableope === 6">
                                    <el-button type="primary" size="mini"
                                        @click="routeraddrClick('/weipagesedit', scope.row)">编辑</el-button>
                                    <el-button type="primary" size="mini" v-if="scope.row.status == 1"
                                        @click="addTanClick(scope.row, (dialogVisible = true))">推广</el-button>
                                    <el-button @click.prevent="deleteRow(scope.row, scope.$index)" type="danger"
                                        size="mini" icon="el-icon-delete"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!--弹出框-推广-->
            <el-dialog v-model="dialogVisible" title="推广" :width="this.searchwidth" :before-close="handleCloseSenior">
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                    <div class="dialog-box-flex">
                        <div style="text-align: center;">
                            <span>{{ this.weipagesForm.title }}</span>
                        </div>
                        <div v-if="this.weipagesForm.image == ''" style="text-align: center">
                            <i class="el-icon-loading"></i>
                        </div>
                        <div>
                            <img :src="this.weipagesForm.image" alt=""
                                style="width: 100%; margin-top: 10px; border: 1px solid #ececec" />
                        </div>
                        <div
                            style="margin-top: 20px;display: flex;flex-direction: row;flex-wrap: wrap;justify-content: center;">
                            <div style="margin-right: 20px;">
                                <el-button type="primary" @click="downQrCodeEvent(this.weipagesForm.image)">下载二维码
                                </el-button>
                            </div>
                            <div>
                                <el-button type="primary" plain @click="fuzhiQrCodeEvent(this.weipagesForm.url)">复制链接
                                </el-button>
                            </div>
                        </div>
                    </div>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import axios from "axios";
import { defineComponent, ref } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";

export default defineComponent({
    name: "vintention",
    inject: ["reload"], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: "店铺",
                },
                {
                    name: "装修",
                },
                {
                    name: "微页面",
                    path: "/weipages",
                },
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: "0", //框右侧距离
                    titlewidth: "74px", //标题宽度
                    inputwidth: "200px", //input宽度
                    inputinterval: "20px", //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: "页面名称：",
                            placeholder: "请输入页面名称",
                            name: "title",
                            content: "",
                        },
                    ],
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: "none",
                    divinterval: "20px", //框右侧距离
                    selectwidth: "200px",
                    selectinterval: "", //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: "发布状态：",
                            placeholder: "全部",
                            name: "status",
                            value: "",
                            options: [
                                {
                                    label: "全部",
                                    value: "0",
                                },
                                {
                                    label: "发布",
                                    value: "1",
                                },
                                {
                                    label: "未发布",
                                    value: "-1",
                                },
                            ],
                        },
                    ],
                },
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 6, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 180,
            mainthreetabletitle: [
                {
                    fixedstatu: "left",
                    prop: "title",
                    label: "页面名称",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "status",
                    label: "发布状态",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "ip",
                    label: "访客数",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "pv",
                    label: "浏览量",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "truename",
                    label: "创建人",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "addtime",
                    label: "创建时间",
                    width: "200",
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: "", //通过路由获取当前页面的path值
            searchwidth: "400px",

            //弹窗相关数据
            dialogVisible: ref(false), //弹窗显示&隐藏
            weipagesForm: {
                id: '',
                title: '',
                image: '',
                url: ''
            },

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
        };
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path;
    },
    methods: {
        // 下载二维码
        downQrCodeEvent(data) {
            window.open(data, "_blank");
        },
        // 复制二维码
        fuzhiQrCodeEvent(data) {
            var textarea = document.createElement('textarea');
            document.body.appendChild(textarea);
            // 隐藏此输入框
            textarea.style.position = 'absolute';
            textarea.style.clip = 'rect(0 0 0 0)';
            // 赋值
            textarea.value = data;
            // 选中
            textarea.select();
            // 复制
            document.execCommand('copy', true);

            ElMessage({
                type: "success",
                message: "复制成功！",
            });
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(rowdata, index) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            ElMessageBox.confirm(
                "确定删除该条 ' " + rowdata.title + " ' 数据吗?",
                "提示：",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .then(() => {
                    axios
                        .post(
                            "/admin/pages/delete",
                            {
                                id: rowdata.id,
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: "success",
                                    message: "删除成功",
                                    duration: 1000,
                                    onClose: () => {
                                        this.mainthreetableData.splice(index, 1);
                                        this.reload(); //刷新
                                    },
                                });
                            } 
                        })
                        .catch(function (error) {
                            // 请求失败处理
                            ElMessage({
                                type: "error",
                                message: "删除失败！" + error,
                            });
                        });
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "取消删除",
                    });
                });
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log("this is:" + val);
            this.multipleSelection = val;
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.PageSize = val;
            this.currentPage = 1;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/pages/index",
                    {
                        title: this.pagesdatas.title,
                        status: this.pagesdatas.status,
                        page: 1,
                        page_size: val,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`);
            this.currentPage = val;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/pages/index",
                    {
                        title: this.pagesdatas.title,
                        status: this.pagesdatas.status,
                        page: val,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            axios
                .post(
                    "/admin/pages/getQrcode",
                    {
                        page_id: taninfo.id,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    })
                .then((response) => {
                    if (response.data.code == 0) {
                        this.weipagesForm = {
                            id: taninfo.id,
                            title: taninfo.title,
                            image: response.data.result.image,
                            url: response.data.result.url,
                        };
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                if (routeraddr == '/weipagesedit') {
                    this.$router.push({
                        path: routeraddr,
                        query: {
                            id: rowdata.id
                        }
                    }).catch((error) => error);
                }
            } else {
                this.$router.push(routeraddr).catch((error) => error);
            }

        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight;
            this.sreenwidth = document.documentElement.clientWidth;
            if (this.sreenwidth < 420) {
                this.searchwidth = this.sreenwidth * 0.8 + "px";
            } else {
                this.searchwidth = "400px";
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/pages/index",
                    {
                        title: data.title,
                        status: data.status,
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data;

            for (var i = 0; i < newdata.length; i++) {
                //转换-创建时间
                if (newdata[i].addtime) {
                    if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                        newdata[i].addtime = "无创建时间";
                    } else {
                        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
                    }
                } else {
                    newdata[i].addtime = "无创建时间";
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata;
            this.totalCount = successdata.total;
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;
        },
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo();

            //加载loading
            this.loading = true; //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/pages/index",
                    {
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    components: {
        mainone,
        maintwo,
    },
});
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

/* 推广码 */
.dialog-box-flex {
    display: flex;
    flex-direction: column;
    justify-self: center;
}
</style>