<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <el-button type="primary" plain @click="routeraddrClick('/fileclassifyadd')">添加</el-button>
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas(mainthreetableData)">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table ref="multipleTable" :row-class-name="tableRowClassName" :data="
            mainthreetableData.slice(
              (currentPage - lastpage) * PageSize,
              currentPage * PageSize
            )
          " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'images' ||
                mytabletitle.prop === 'set'
              ">
                <div v-if="mytabletitle.prop === 'images'">
                  <div v-if="scope.row.images">
                    <el-image class="imagesshow" :src="scope.row.images" :preview-src-list="[scope.row.images]"
                      fit="cover"></el-image>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.switchstatus === 4" id="switch">
                    <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭" :active-value="1"
                      :inactive-value="2" active-color="#13ce66" inactive-color="#cbcbcb"
                      @change="changeSwitchEvent(scope.row.status, scope.row.id)">
                    </el-switch>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation"
              id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-文档分类-->
                <div v-if="mainthreetableope === 13">
                  <el-button type="primary" size="mini" v-if="scope.row.level !== '四级'"
                    :disabled="scope.row.status == 2"
                    @click="routeraddrClick('/fileclassifyaddlast', scope.row)">新增下级</el-button>
                  <el-button type="primary" size="mini" v-if="scope.row.level !== '四级'"
                    @click="seeLowerEvent(scope.row)">查看下级</el-button>
                  <el-button type="primary" size="mini"
                    @click="routeraddrClick('/fileclassifyedit', scope.row)">编辑</el-button>
                  <el-button @click.prevent="
                    deleteRow(scope.$index, mainthreetableData, scope.row)
                  " type="danger" size="mini" icon="el-icon-delete"></el-button>
                  <el-button type="primary" size="mini"
                    @click="addTanClick(scope.row, 1, (dialogVisible = true))">复制文档</el-button>
                  <!-- v-if="scope.row.level === '四级'" -->
                  <el-button type="primary" size="mini"
                    @click="addTanClick(scope.row, 2, (dialogVisible = true))">转移文档</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog v-model="dialogVisible" :title="tancontent.title" :width="this.searchwidth"
        :before-close="handleCloseSenior">
        <el-form ref="seniorform" :model="seniorform" label-width="120px">
          <div style="
              max-height: 450px;
              overflow: hidden;
              overflow-y: visible;
              margin: 20px 0;
            ">
            <el-row>
              <el-col :span="24">
                <el-form-item label="原文档分类:">
                  <el-row :gutter="10" style="margin-top: 10px">
                    <el-col :span="24" :xs="24" :sm="24" :lg="12">
                      <el-select v-model="seniorform.version" placeholder="请选择" disabled>
                        <el-option :label="getsales.cate_name" :value="getsales.id"
                          v-for="(getsales, chicundex) in gettype" :key="chicundex"
                          @click="finduserSaleList(getsales)"></el-option>
                      </el-select>
                    </el-col>
                    <!-- 子分类-多级联选 -->
                    <!-- :props="{value:'id',label:'name'}" 自定义字段-->
                    <el-col :span="24" :xs="24" :sm="24" :lg="12">
                      <el-cascader v-model="seniorform.version_data" :options="gettypechild"
                        :placeholder="seniorform.cate_name" :show-all-levels="false" :props="{
                          value: 'id',
                          label: 'cate_name',
                          children: 'child',
                        }" clearable @change="handleChange"></el-cascader>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="目标文档分类:">
                  <el-row :gutter="10" style="margin-top: 10px">
                    <el-col :span="24" :xs="24" :sm="24" :lg="12">
                      <el-select v-model="seniorform.version_change" placeholder="请选择">
                        <el-option :label="getsales1.cate_name" :value="getsales1.id"
                          v-for="(getsales1, chicundex1) in gettypemubiao" :key="chicundex1"
                          @click="finduserSaleListmubiao(getsales1)"></el-option>
                      </el-select>
                    </el-col>
                    <!-- 子分类-多级联选 -->
                    <!-- :props="{value:'id',label:'name'}" 自定义字段-->
                    <el-col :span="24" :xs="24" :sm="24" :lg="12">
                      <el-cascader v-model="seniorform.version_data_change" :options="gettypechildmubiao"
                        :show-all-levels="false" :props="{
                          value: 'id',
                          label: 'cate_name',
                          children: 'child',
                        }" clearable :disabled="seniorform.disabled" @change="handleChange"></el-cascader>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-form-item>
            <div style="text-align: right">
              <el-button @click="resetFormSenior('seniorform')">
                <i class="el-icon-refresh-left"></i> 重置
              </el-button>
              <el-button type="primary" @click="onSubmitSenior('seniorform', this.tancontent.btnapi)">确定
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import axios from "axios";
import { ElMessage } from "element-plus";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vfileclassify",
  data() {
    return {
      mainonebread: [
        {
          name: "教育",
        },
        {
          name: "文档管理",
        },
        {
          name: "文档分类",
          path: "/fileclassify",
        },
      ], // 当前页-【面包屑导航】
      mainthreeisshowbut: true, // 数据列表中-【添加按钮】
      mainthreeadddata: "/fileclassifyadd", //点击添加按钮-跳转路由
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 13, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 420, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: false,
          prop: "id",
          label: "ID",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "plat",
          label: "平台",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "cate_name",
          label: "分类名称",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "level",
          label: "级别",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "source_num",
          label: "文档数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "是否显示",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "sort",
          label: "排序",
          width: "200",
        },
      ], // 数据列表中-数据表头

      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      //获取的所有分类
      getdata: [],

      gettype: [], //原分类-配置
      gettypechild: [], // 原分类-子分类
      gettypemubiao: [], //目标分类-配置
      gettypechildmubiao: [], //目标分类-子分类
      seniorform: {
        cate_name: "",
        version: "",
        version_data: "",
        version_change: "",
        version_data_change: "",
        disabled: true,
      },
      chuanid: "", //存储-该条数据平台-携带参数
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
        btnapi: "",
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  setup() {
    const dialogVisible = ref(false);
    const handleCloseSenior = (done) => {
      done();
    };

    return {
      dialogVisible,
      handleCloseSenior,
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      // console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用

      //获取所点击按钮的id值-并根据不同的id值进行跳转&传值等
      if (event.path[1].id) {
        //由操作-合作-公司列表-向-公司关联设备页面传值
        if (event.path[1].id === "companydvcid") {
          this.$router
            .push({
              path: "/companydvclist",
              query: { id: row.id, name: row.name },
            })
            .catch((error) => error);
        }
        //由操作-合作-设备合伙人列表-向-合伙人关联设备页面传值
        if (event.path[1].id === "partnerdvcid") {
          this.$router
            .push({ path: "/partnerdvclist", query: { id: row.id } })
            .catch((error) => error);
        }
      }

      // console.log("当前的column.property 是：" + column.property);
      if (column.property === "secondagent") {
        //跳转二级代理
        this.$router.push("/agentcensustwo").catch((error) => error);
      }
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows, getdata) {
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/SharePrintSource/deleteCate",
          {
            id: getdata.id,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: "提交成功！",
            });
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 2;
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/SharePrintSource/indexCate",
          {
            page: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //switch-按钮回调函数
    changeSwitchEvent(val, valueid) {
      //通过API获取配置数据-无限级分类列表
      axios
        .post(
          "/admin/SharePrintSource/editCate",
          {
            id: valueid,
            status: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: "切换成功！",
            });
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //用于下拉框-点击&选择事件-控制框是否可选择-原文档
    finduserSaleList(data) {
      if (data.child) {
        this.seniorform.disabled = false;
        this.gettypechild = data.child;
      } else {
        this.seniorform.disabled = true;
        this.seniorform.version_data = "";
      }
    },
    //用于下拉框-点击&选择事件-控制框是否可选择-目标文档
    finduserSaleListmubiao(data) {
      if (data.child) {
        this.seniorform.disabled = false;
        this.gettypechildmubiao = data.child;
      } else {
        this.seniorform.disabled = true;
        this.seniorform.version_data_change = "";
      }
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo, btninfo) {
      console.log(taninfo, btninfo);
      this.seniorform.cate_name = taninfo.cate_name;

      if (btninfo == 1) {
        this.tancontent.title = "复制分类下文档到新分类";
        this.tancontent.btnapi = "/admin/SharePrintSource/cobyCate";
      } else {
        this.tancontent.title = "转移文档";
        this.tancontent.btnapi =
          "/admin/SharePrintSource/transferCate";
      }
      if (taninfo.pid > 3) {
        this.gettypechild = this.gettype[this.chuanid - 1].child;
        this.gettypechildmubiao = this.gettype[this.chuanid - 1].child;
        this.seniorform.version = this.chuanid;
        this.seniorform.version_data = taninfo.id;
        this.seniorform.version_change = this.chuanid;
        this.seniorform.disabled = false;
      } else {
        this.gettypechild = this.gettype[taninfo.pid - 1].child;
        this.gettypechildmubiao = this.gettype[taninfo.pid - 1].child;
        this.seniorform.version = taninfo.pid;
        this.seniorform.version_data = taninfo.id;
        this.seniorform.version_change = taninfo.pid;
        this.seniorform.disabled = false;
      }
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, data) {
      console.log("当前data获取得到的值：", data);
      if (data) {
        this.$router
          .push({ path: routeraddr, query: data })
          .catch((error) => error);
      } else {
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    // 点击-查看下级信息
    seeLowerEvent(datas) {
      // console.log("查看下级信息：" + JSON.stringify(datas));

      //存储-携带平台id参数
      if (datas.pid < 4) {
        this.chuanid = datas.pid;
      }
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/SharePrintSource/indexCate",
          {
            pid: datas.id,
            page_size: 20,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //弹出框-提交表单
    onSubmitSenior(formName, getbtnapi) {
      this.dialogVisible = false; //隐藏高级筛选模块
      var newid = this.$refs[formName].model.version_data_change.pop();
      var oldid = 0;
      if (this.$refs[formName].model.version_data !== null) {
        if (typeof this.$refs[formName].model.version_data === "number") {
          oldid = this.$refs[formName].model.version_data;
        } else {
          oldid = this.$refs[formName].model.version_data.pop();
        }
      } else {
        ElMessage({
          type: "success",
          message: "提交失败！原文档分类未选择！！",
        });
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          //通过API获取配置数据-无限级分类列表
          axios
            .post(
              getbtnapi,
              {
                old_cate_id: oldid,
                new_cate_id: newid,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                });
              }
            })
            .catch(function (error) {
              // 请求失败处理
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      console.log(getbtnapi);
    },
    //弹出框-重置
    resetFormSenior(formName) {
      this.$refs[formName].resetFields();
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },
    //提交等相关event事件函数
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      // console.log(JSON.stringify(newdata));
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;

        //渲染-设置-按钮
        if (newdata.level == "四级") {
          newdata[i].setadd = false;
          newdata[i].setsee = false;
        } else {
          newdata[i].setadd = true;
          newdata[i].setsee = true;
        }

        newdata[i].chuanid = this.chuanid;
        if (this.chuanid == 1) {
          newdata[i].plat = "自助版";
        } else {
          if (this.chuanid == 2) {
            newdata[i].plat = "企业版";
          } else {
            if (this.chuanid == 3) {
              newdata[i].plat = "家庭版";
            }
          }
        }
      }
      // console.log("当前页面的data:" + JSON.stringify(newdata));
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  watch: {
    //检测父组件传来的currentpagenum值变化
    currentpagenum: function () {
      // console.log('检测到的数据：' + this.currentpagenum)
      if (this.currentpagenum) {
        this.currentPage = this.currentpagenum;
      } else {
        this.currentPage = 1;
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //通过API获取配置数据-无限级分类列表
      axios
        .post(
          "/admin/SharePrintSource/sourceCateInfo",
          {
            id: -1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var getdata = response.data;
          console.log(getdata);
          this.gettype = getdata;
          this.gettypemubiao = getdata;
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/SharePrintSource/indexCate",
          {
            pid: 0,
            page_size: 20,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>