<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box"  >
        <div class="box_title"  >
          <div class="box_title_font">添加广告</div>
          <div class="box_title_button">
            <!--可添加按钮-->
            <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="6">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="10">
              <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="160px" class="demo-ruleForm">
                <el-form-item label="广告主题：" prop="name">
                  <el-input v-model="ruleForm.name" clearable></el-input>
                  <div style="height: 20px; line-height: 20px">
                    <span style="font-size: 12px; color: #999999">广告主题只是作为辨别多个广告条目之用，并不显示在广告中</span>
                  </div>
                </el-form-item>
                <el-form-item label="投放设备：" required="true">
                  <el-form-item style="width: 100%">
                    <el-button type="primary" icon="el-icon-upload" style="margin: 5px 20px 10px 0; float: left"
                      size="small" @click="chuliTanClick((dialogVisible = true))">
                      请选择设备</el-button>
                    <mainimgesup ref="myimges1" :myimgesup="myimgesup1" :myimgesupisshow="myimgesupisshow1"
                      v-on:getuploadfile="(data) => getUploadFileEvent1(data)"
                      v-on:deluploadfile="(data) => deluploadfileEvent1(data)" v-on:getfiletype="getfiletypeEvent1">
                    </mainimgesup>
                  </el-form-item>
                  <el-form-item prop="push_device">
                    <el-select v-model="ruleForm.push_device" filterable multiple allow-create
                      style="min-width:200px;width:100%" placeholder="请选择" @change="eleChange">
                      <el-option v-for="item in push_alldvc" :key="item.id" :label="item.client" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form-item>
                <el-form-item label="开始时间：" prop="begin_time">
                  <el-date-picker v-model="ruleForm.begin_time" type="datetime" placeholder="请选择时间" style="width: 100%"
                    @change="(value) => changeTimes(value, 'begin_time')">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="投放天数：" prop="push_day">
                  <el-input v-model="ruleForm.push_day" placeholder="请输入整数" type="number" min="1" clearable></el-input>
                </el-form-item>
                <el-form-item label="联系人：" prop="guest_name">
                  <el-input v-model="ruleForm.guest_name" clearable></el-input>
                </el-form-item>
                <el-form-item label="联系人手机号：" prop="guest_mobile">
                  <el-input v-model="ruleForm.guest_mobile" clearable></el-input>
                </el-form-item>
                <el-form-item label="广告图片/视频：" prop="files">
                  <mainimgesup ref="myimges" v-model="ruleForm.files" :myimgesup="myimgesup"
                    :myimgesupisshow="myimgesupisshow" :filelist="filelist"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                    v-on:deluploadfile="(data) => deluploadfileEvent(data)" v-on:getfiletype="getfiletypeEvent">
                  </mainimgesup>
                </el-form-item>
                <el-form-item label="单次广告时长：" prop="duration" v-if="this.ruleForm.type == 1">
                  <el-select v-model="ruleForm.duration" placeholder="请选择" style="width: 100%">
                    <el-option :label="getonetime.lable" :value="getonetime.value"
                      v-for="(getonetime, index) in onetimes" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="单次广告时长：" prop="duration" v-if="this.ruleForm.type == 2">
                  <el-input style="width:100%" v-model="ruleForm.duration" placeholder="请填写当前视频时长" disabled="true">
                    <template #append>秒</template>
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认
                  </el-button>
                  <el-button @click="resetForm('ruleForm', this.filedata, this.filelist)">重置</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <el-dialog v-model="dialogVisible" title="选择设备" :width="searchwidth" :before-close="handleCloseSenior">
      <el-form ref="seniorForm" :model="seniorForm" :rules="rules">
        <!--查询条件-->
        <el-form-item>
          <el-row :gutter="10" style="padding-right: 11px">
            <el-col :span="24" :xs="24" :sm="8">
              <el-row>
                <el-col :span="24" :xs="24" :sm="6">
                  <span>输入搜索：</span>
                </el-col>
                <el-col :span="24" :xs="24" :sm="18">
                  <el-input v-model="seniorForm.search" placeholder="设备编号/代理商名称" clearable></el-input>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" :xs="24" :sm="14">
              <el-row>
                <el-col :span="24" :xs="24" :sm="4">
                  <span>选择区域：</span>
                </el-col>
                <el-col :span="24" :xs="24" :sm="20">
                  <mainselect v-model="seniorForm.area_code" :myselectcityleftisshow="myselectcityisshow"
                    :myselectcity="myselectcity" v-on:getcitycode="getCityCodeEvent"></mainselect>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" :xs="24" :sm="2">
              <el-button type="primary" @click="onSubmitTan('seniorForm')">查询</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- table表单 :data="tableData.slice((currentPage - lastpage) * PageSize,currentPage * PageSize)" -->
        <el-form-item>
          <el-table :data="
            tableData.slice(
              (currentPage - lastpage) * PageSize,
              currentPage * PageSize
            )
          " ref="multipleTable" style="width: 100%" max-height="420" border v-loading="loading"
            element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" />
            <el-table-column label="设备编号" prop="client" width="150" />
            <el-table-column label="投放商家" prop="shop_code_name"></el-table-column>
            <el-table-column label="投放地址" prop="area_code_name" />
            <el-table-column label="投放场景" prop="push_type_name" width="150" />
            <el-table-column label="状态" prop="devstatus" width="150">
              <template #default="scope">
                <div v-if="scope.row.devstatus == 1">
                  <el-button type="primary" size="mini" v-if="scope.row.printstatus_name">{{ scope.row.printstatus_name
                  }}</el-button>
                  <el-button type="success" size="mini" v-if="scope.row.online_name == '在线'">{{ scope.row.online_name }}
                  </el-button>
                  <el-button type="danger" size="mini" v-if="scope.row.online_name == '离线'">{{ scope.row.online_name }}
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-row>
          <el-col :span="24" :xs="24" :sm="6">
            <el-checkbox v-model="this.tableseniorForm.is_all" :true-label="1" :false-label="0" size="large" border
              style="margin-right: 10px" @change="
                (value) => {
                  toggleSelection(value, tableData, this.totalCount);
                }
              ">全选</el-checkbox>
            <span v-if="tableseniorForm.is_all == '1'">已选择 {{ this.totalCount }} 台设备</span>
          </el-col>
          <el-col :span="24" :xs="24" :sm="14">
            <div style="margin-top: 5px">
              <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                pager-count="4" :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
              </el-pagination>
            </div>
          </el-col>
          <el-col :span="24" :xs="24" :sm="4">
            <div style="float: right">
              <el-button @click="
                resetFormSenior(tableseniorForm, (dialogVisible = false))
              ">取消</el-button>
              <el-button type="primary" @click="
                onSubmitSenior(tableseniorForm, (dialogVisible = false))
              ">确定</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainselect from "@/components/Mainselect";
import mainimgesup from "@/components/Mainimgesup.vue";
import { ref } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export default {
  name: "vadvertlistadd",
  watch: {},
  data() {
    return {
      adtimeisshow: false,
      mainonebread: [
        {
          name: "运营",
        },
        {
          name: "设备广告管理",
        },
        {
          name: "广告列表",
          path: "/advertlist",
        },
        {
          name: "添加广告",
        },
      ], // 当前页-【面包屑导航】

      myimgesupisshow: true, //form表单-imgesuploade上传图片
      myimgesup: {
        uploadsize: 1, //选择上传图片的展现框-1阿里oss直传，2PHP的接口传输
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/admin/upload/file", //upload上传API地址
        headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
        title: "选择上传文件", //button-按钮文字
        tips: "(1)建议尺寸：1070*1830像素; (2)视频仅持格式：*.mp4; (3)图片支持格式：*.jpg , *.png; (4)最大支持上传20M视频文件", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 50, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 10000, //文件大小（单位kb）
            type: ["image/jpg", "image/png", 'image/jpeg'], //文件格式
            suffix: ['jpg', 'png'], //文件后缀-用于判断
            typechart: "jpg/png", //文件格式提示规则
          },
          video: {
            size: 20000, //文件大小（单位kb）
            type: ["video/mp4"], //文件格式
            typechart: "mp4", //文件格式提示规则
          },
        },
      },
      filelist: [
        // {
        //   name: "food.jpeg",
        //   url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        // },
      ],
      onetimes: [
        {
          lable: "15s",
          value: "15",
        },
        {
          lable: "30s",
          value: "30",
        },
      ], //配置-单次广告时长


      //Excel导入设备-上传Excel文件
      myimgesupisshow1: true, //form表单-imgesuploade上传图片
      myimgesup1: {
        uploadsize: 2, //选择上传图片的展现框-1阿里oss直传，2PHP的接口传输
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        showfilelist: false, //list展示列表是否显示，显示true，隐藏false
        ismust: true, //是否必填，true表示必填
        action: "/admin/ClientAdvert/excelImport", //upload上传API地址
        headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
        multiple: false, //上传多个文件
        title: "Excel批量导入", //button-按钮文字
        title_type: "primary", //button-按钮显示样式,primary&text
        title_size: "small", //button-按钮大小，medium / small / mini
        title_plain: false, //button-按钮是否为线性（plain）
        isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
        tips: "", //提示&建议文字
        limit: "", //上传数量限制
        target_id: '', //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          files: {
            size: 5000, //文件大小（单位kb）
            type: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/x-xls", 'application/vnd.ms-excel'], //文件格式
            typechart: "Excel", //文件格式提示规则
          },
        },
      },

      ruleForm: {
        name: "",
        push_device: "",
        push_num: "",
        begin_time: ref(""),
        push_day: "",
        guest_name: "",
        guest_mobile: "",
        type: "", //广告类型 1-图片 2-视频
        files: "",
        duration: "",
      }, //form表单
      rules: {
        name: [
          {
            required: true,
            message: "广告主题为必填项",
            trigger: "blur",
          },
        ],
        push_device: [
          {
            required: true,
            message: "投放设备不能为空",
            trigger: "blur",
          },
        ],
        begin_time: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        push_day: [
          {
            required: true,
            message: "请填写投放天数",
            trigger: "change",
          },
        ],
        guest_name: [
          {
            required: true,
            message: "联系人不能为空",
            trigger: "change",
          },
        ],
        guest_mobile: [
          {
            required: true,
            message: "联系人手机号不能为空",
            trigger: "change",
          },
        ],
        files: [
          {
            required: true,
            message: "请上传广告图片/视频",
            trigger: "change",
          },
        ],
        duration: [
          {
            required: true,
            message: "请选择单次广告时长",
            trigger: "change",
          },
        ],
      }, //form表单-必填规则
      tagshownum: 10, //tag显示数量，默认是10条
      push_alldvc: [], //获取的投放设备-与tableseniorForm中的alldvc同步，通过弹窗【确认】获得

      //弹框-查询设备-form表单数据
      seniorForm: {
        search: "",
        area_code: "",
      },
      dialogVisible: false,
      searchwidth: "1224px",
      //弹窗中-selectcity选框
      myselectcityisshow: true,
      myselectcity: {
        selectwidth: "100%",
        select: {
          ismust: false,
          name: "area_code",
          valuesheng: "",
          valueshi: "",
          valuequ: "",
          disshi: true,
          disqu: true,
        },
      },
      //弹窗中-table表单
      tableData: [],
      tableseniorForm: {
        dvcids: [],
        alldvc: [],
        is_all: 0,
      },
      //弹窗中-table表单-分页器
      totalCount: 0, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      currentPage: 1,
      pageSizes: [10, 20, 50, 100, 1000],
      PageSize: 10,

      loading: false,
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
      changeAllDvc: [], //等待全选的所有数据

      uploadParams: {},
    };
  },
  methods: {
    // Excel上传
    getUploadFileEvent1(data) {
      console.log("当前获取的(Excel)回传数据：", data);
      let alldvc = this.push_alldvc;
      let dvcids = [];
      if (data.data) {
        data.data.forEach(element => {
          let data = {
            id: element.id,
            client: element.client,
          }
          alldvc.push(data);
        });
      }

      let arr2 = [];
      arr2 = this.unique(alldvc, "id");
      this.push_alldvc = arr2;
      arr2.forEach(element => {
        dvcids.push(element.id);
      });
      this.ruleForm.push_device = dvcids;

    },
    //[广告图片/视频]-获取upload子组件回传的数据-file文件url等数据
    getUploadFileEvent(data) {
      console.log("当前获取的回传数据：", data);

      if (data.type == "mp4") {
        this.ruleForm.files = data.res.requestUrls[0]
        this.ruleForm.type = 2;
        this.ruleForm.duration = data.fileLen; // 视频时长
      } else {
        this.ruleForm.files = data.url
        this.ruleForm.type = 1;
        this.ruleForm.duration = 15; // 图片时长
      }
    },
    //[广告图片/视频]-删除上传的文件-事件
    deluploadfileEvent(data) {
      console.log("当前删除事件-返回的数据：" + JSON.stringify(data));
      this.ruleForm.files = '';
      this.ruleForm.type = '';
    },
    getfiletypeEvent(data) {
      console.log('文件后缀：', data)
    },
    //弹窗中-分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      this.loading = true;
      axios
        .post(
          "/admin/ClientAdvert/device",
          {
            search: this.pagesdatas.search,
            area_code: this.pagesdatas.area_code,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var successdata = response.data.result.res;
          var newdata = successdata.data;
          for (var i = 0; i < newdata.length; i++) {
            //渲染-状态-该字段表示设备状态按钮
            newdata[i].devstatus = 1;
          }
          this.tableData = newdata;
          this.totalCount = successdata.total;
          this.currentpage = successdata.current_page; //当前页面页数
          this.lastpage = successdata.last_page;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //弹窗中-分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      this.loading = true;
      axios
        .post(
          "/admin/ClientAdvert/device",
          {
            search: this.pagesdatas.search,
            area_code: this.pagesdatas.area_code,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var successdata = response.data.result.res;
          var newdata = successdata.data;
          for (var i = 0; i < newdata.length; i++) {
            //渲染-状态-该字段表示设备状态按钮
            newdata[i].devstatus = 1;
          }
          this.tableData = newdata;
          this.totalCount = successdata.total;
          this.currentpage = successdata.current_page; //当前页面页数
          this.lastpage = successdata.last_page;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //弹窗中-table表单-多选按钮
    handleSelectionChange(val) {
      // 存储所选择的设备
      this.tableseniorForm.alldvc = val;
    },
    //弹窗中-table表单-全选按钮
    toggleSelection(value, rows) {
      if (value == 1) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      } else {
        this.$refs.multipleTable.clearSelection();
      }

      console.log("获取的row is : " + rows);
      // 存储所选择的设备
      this.tableseniorForm.alldvc = rows;
    },
    //获取公共组件回传的值-citycode
    getCityCodeEvent(data) {
      console.log("当前获取的citycode值为：" + data);
      this.seniorForm.area_code = data;
    },
    //弹窗中-查询按钮-提交
    onSubmitTan(formName) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.pagesdatas = getdatas; //将本次的筛选条件存储在pagesdatas中，便于切换pages时携带参数！
          this.loading = true;
          axios
            .post(
              "/admin/ClientAdvert/device",
              {
                search: getdatas.search,
                area_code: getdatas.area_code,
                page_size: 10,
                page: 1,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              var successdata = response.data.result.res;
              var newdata = successdata.data;
              for (var i = 0; i < newdata.length; i++) {
                //渲染-状态-该字段表示设备状态按钮
                newdata[i].devstatus = 1;
              }
              this.tableData = newdata;
              this.totalCount = successdata.total;
              this.currentpage = successdata.current_page; //当前页面页数
              this.lastpage = successdata.last_page;
              this.loading = false;
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 1230) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "1224px";
      }
    },
    //去重-数组中除去数组对象的重！
    unique(arr, val) {
      const res = new Map();
      return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
    },
    //弹出框-选择设备-确认
    onSubmitSenior(formData) {
      console.log("点击确认获取所选设备数据:", formData.alldvc);
      let alldvc = this.push_alldvc;
      let dvcids = [];
      if (formData.alldvc) {
        formData.alldvc.forEach(element => {
          let data = {
            id: element.id,
            client: element.client,
          }
          alldvc.push(data);
        });
      }

      let arr2 = [];
      arr2 = this.unique(alldvc, "id");
      this.push_alldvc = arr2;
      arr2.forEach(element => {
        dvcids.push(element.id);
      });
      this.ruleForm.push_device = dvcids;

    },
    //弹出框-选择设备-取消
    resetFormSenior() {
      // this.$refs["multipleTable"].clearSelection();
      // console.log("this.tableseniorForm:" + JSON.stringify(datas));
      this.tableseniorForm.is_all = 0; //全选按钮-状态，0未全选，1全选
      this.seniorForm = {
        search: "",
        area_code: "",
      };
      this.myselectcity.select = {
        ismust: false,
        name: "area_code",
        valuesheng: "",
        valueshi: "",
        valuequ: "",
        disshi: true,
        disqu: true,
      };
    },
    //点击按钮-弹出弹框
    chuliTanClick() {
      //点击进入弹窗-加载tabledata
      this.loading = true;
      axios
        .post(
          "/admin/ClientAdvert/device",
          {
            search: "",
            area_code: "",
            page_size: this.PageSize,
            page: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var successdata = response.data.result.res;
          var newdata = successdata.data;
          for (var i = 0; i < newdata.length; i++) {
            //渲染-状态-该字段表示设备状态按钮
            newdata[i].devstatus = 1;
          }
          this.tableData = newdata;
          this.totalCount = successdata.total;
          this.currentpage = successdata.current_page; //当前页面页数
          this.lastpage = successdata.last_page;
          this.loading = false;

          this.changeAllDvc = newdata;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //点击关闭-弹窗
    handleCloseSenior(done) {
      done();
    },
    //选择时间并转换
    changeTimes(data, chart) {
      console.log("当前获取的时间：" + data);
      this.ruleForm[chart] = new Date(data).getTime();

      //比较开始时间与结束时间
      if (this.ruleForm.begin_time == "") {
        ElMessage({
          type: "warning",
          message: "请先选择开始时间！",
        });
        this.ruleForm.end_time = "";
      } else {
        if (this.ruleForm.end_time != "") {
          if (this.ruleForm.begin_time > this.ruleForm.end_time) {
            ElMessage({
              type: "warning",
              message: "选择的结束时间必须大于开始时间！",
            });
            this.ruleForm.end_time = "";
          }
        }
      }
    },
    //提交form表单-并判断是否必填
    submitForm(formName) {
      // 存储所选择设备的id，并用逗号隔开
      var getdvcids = [];
      this.push_alldvc.forEach((element) => {
        getdvcids.push(element.id);
      });
      this.ruleForm.push_device = getdvcids.join(",");
      this.ruleForm.push_num = getdvcids.length;

      //提交表单数据
      var formdatas = this.$refs[formName].model;
      console.log("formdata is : " + JSON.stringify(formdatas));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("formdatas is：" + JSON.stringify(formdatas));

          axios
            .post(
              "/admin/ClientAdvert/add",
              {
                name: formdatas.name,
                push_device: formdatas.push_device,
                push_num: formdatas.push_num,
                begin_time: formdatas.begin_time / 1000,
                push_day: formdatas.push_day,
                guest_name: formdatas.guest_name,
                guest_mobile: formdatas.guest_mobile,
                type: formdatas.type,
                file_url: formdatas.files,
                duration: formdatas.duration,
                is_all: this.tableseniorForm.is_all
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    // 提交成功后，跳转至list列表页
                    this.$router.push("/advertlist").catch((error) => error);
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置form表单中填写的数据
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.tableseniorForm.dvcids = [];
      this.tableseniorForm.alldvc = [];
      this.push_alldvc = [];
      // 重置upload中的filelist列表
      this.filelist = [];
      // 重置myExcel中的filelist列表
      this.myExcel.filelist = [];
    },
    beforUploadFileEvent(file) {
      //第二种在上传成功后获取
      var url = URL.createObjectURL(file);
      var audioElement = new Audio(url);
      var duration;
      let _this = this
      audioElement.addEventListener("loadedmetadata", function () {
        duration = parseInt(audioElement.duration); //时长为秒
        _this.ruleForm.duration = duration;
        console.log(duration);
      });
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();
    }
  },
  components: {
    mainone,
    mainselect,
    mainimgesup,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}
</style>