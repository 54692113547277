<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <!--内容-->
                <div class="box_content" id="switch">
                    <el-tabs v-model="activeName" @tab-change="handleClick">
                        <!-- 权益会员 -->
                        <el-tab-pane label="权益会员" name="1">
                            <vipEquity />
                        </el-tab-pane>
                        <!-- 余额充值 -->
                        <el-tab-pane label="余额充值" name="2">
                            <balanceRecharge />
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import vipEquity from './vipEquity.vue'
import balanceRecharge from './balanceRecharge.vue'
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
    name: 'vpaymoneypostgoods',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '余额充值'
                },
                {
                    name: '赠品配送信息'
                }
            ], // 当前页-【面包屑导航】
            activeName: '1', //tabs标签-默认显示页

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')) //获取权限
        }
    },
    methods: {
        handleClick(activename) {
            this.activeName = activename
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            this.handleClick(this.activeName)
        }
    },
    components: {
        mainone,
        vipEquity,
        balanceRecharge
    }
})
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 10px;
}

.box_content :deep(.el-form-item__error) {
    position: static !important;
}

.box_content :deep(.el-upload) {
    display: block !important;
}

.box_content {
    border-top: 0px solid #eeeeee;
}
</style>
