<template>
  <mainone :mainonebread="mainonebread"></mainone>
  <div>
    <!--主list列表-->
    <div style="
        margin-top: 10px;
        background-color: #ffffff;
        border-radius: 4px;
        line-height: 40px;
        padding: 0 20px 20px 20px;
      ">
      <div style="line-height: 60px">
        <!--左侧名称-->
        <div style="float: left; font-size: 14px">数据列表</div>
        <!--右侧按钮-->
        <div style="float: right">
          <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
          <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
          <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
          <!--添加XX按钮-点击跳转添加类页面-->
          <el-button type="primary" plain class="max_search" @click="routeraddrClick('/setfooteradd', null, 1)"
            v-if="mypowerlimits.dbdh_tj">添加</el-button>
        </div>
      </div>

      <div>
        <!--table表单-->
        <el-table :data="this.result" border>
          <el-table-column prop="id" label="ID" width="100"> </el-table-column>
          <el-table-column prop="title" label="导航名称"> </el-table-column>
          <el-table-column prop="icon" label="未选中图片">
            <template #default="scope">
              <el-image style="width: 50px; height: 50px" :src="scope.row.icon"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="click_icon" label="选中图片">
            <template #default="scope">
              <el-image style="width: 50px; height: 50px" :src="scope.row.click_icon"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="status" label="状态" v-if="mypowerlimits.dbdh_qy">
            <template #default="scope">
              {{ scope.row.status == 1 ? '启用' : '禁用' }}
            </template>
          </el-table-column>
          <el-table-column prop="url_link" label="链接地址" min-width="300">
          </el-table-column>
          <el-table-column prop="" label="操作">
            <template #default="scope">
              <el-button type="primary" @click="routeraddrClick('/setfooteradd', scope.row, 2)"
                v-if="mypowerlimits.dbdh_bj" size="mini">编辑</el-button>
              <el-button @click="doDel(scope.$index, scope.row.id)" v-if="mypowerlimits.dbdh_sc" type="danger"
                size="mini" icon="el-icon-delete"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { defineComponent } from "vue";
import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
export default defineComponent({
  name: "vfooter",
  data() {
    return {
      mainonebread: [
        {
          name: "店铺",
        },

        {
          name: "装修",
        },
        {
          name: "底部导航",
          path: "/setfooter",
        },
      ],
      result: [],

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  methods: {
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata, flage) {
      if (flage == 1) {
        this.$router
          .push({
            path: routeraddr,
            query: { flage: flage },
          })
          .catch((error) => error);
      } else {
        this.$router
          .push({
            path: routeraddr,
            query: { flage: flage, id: rowdata.id },
          })
          .catch((error) => error);
      }
    },

    // toadd() {
    //   this.$router
    //     .push({
    //       path: "/setfooteradd",
    //       query: { id: rowid },
    //     })
    //     .catch((error) => error);
    // },
    // toEdit(rowid) {
    //   this.$router
    //     .push({
    //       path: "/setfooteradd",
    //       query: { id: rowid },
    //     })
    //     .catch((error) => error);
    // },
    doDel(val, rowid) {
      ElMessageBox.confirm("确定要删除吗", "确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.result.splice(val, 1);
        axios
          .post(
            "/admin/Diy/delBottom",
            { id: rowid },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.code == 0) {
              ElMessage({
                type: "success",
                message: response.data.message,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      //this.getwindowinfo()

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      this.re;
      axios
        .post(
          "/admin/Diy/indexBottom",
          { page_size: 20, page: 1 },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            this.result = response.data.result.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
});
</script>

<style>
</style>
