<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <!--内容-->
                <div>
                    <el-tabs v-model="activeName" @tab-click="(tab) => handleClick(tab)">
                        <el-tab-pane label="充值会员默认设置" name="one">
                            <rechargeMemberDefSet />
                        </el-tab-pane>
                        <el-tab-pane label="代理商独立设置" name="two">
                            <agentAloneSet />
                        </el-tab-pane>
                        <el-tab-pane label="设备商独立设置" name="three">
                            <dvcMerchantAloneSet />
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import mainone from "@/components/Mainone";
import { defineComponent } from "vue";

export default defineComponent({
    name: "vrechargememberset",
    inject: ["reload"], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: "设置",
                },
                {
                    name: "会员设置",
                },
                {
                    name: "充值会员设置",
                },
            ], // 当前页-【面包屑导航】
            activeName: "one", //tabs标签-默认显示页
            buttondisable: false, // 控制提交保存按钮-防止多次点击


            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
        };
    },
    methods: {
        handleClick(tab){
            this.$router.push({ path: '/rechargememberset', query: { activename: tab.props.name } }).catch((error) => error);
        }
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.$router.push("/").catch((error) => error);
        } else {
            if (this.$route.query.activename) {
                this.activeName = this.$route.query.activename;
            }
        }
    },
    components: {
        mainone,
    },
});
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
    font-size: 14px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}
</style>