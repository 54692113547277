<template>
    <div class="page-container">
        <div class="container-filter">
            <el-form ref="filterRef" :model="params" inline>
                <div>
                    <el-form-item prop="cid" label="设备编号：">
                        <el-input v-model="params.cid" placeholder="请输入设备编号" clearable />
                    </el-form-item>
                    <el-form-item prop="client_with_id" label="负责人：">
                        <span v-if="main_id">{{ main_name }}</span>
                        <el-cascader
                            v-model="params.client_with_id"
                            :options="tree"
                            :props="{ label: 'truename', value: 'id', children: 'child', checkStrictly: true }"
                            placeholder="请选择负责人"
                            clearable
                            v-else
                        />
                    </el-form-item>
                </div>
                <div>
                    <el-button @click="resetFilter">重置</el-button>
                    <el-button type="primary" @click="getTableData()" :loading="loading">查询</el-button>
                </div>
            </el-form>
        </div>
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <template v-if="mypowerlimits.zdsblb2_fzrplzysbdr">
                        <el-button type="default" @click="downloadTemplate">模板下载</el-button>
                        <el-button type="primary" @click="uploadTemplate">批量转移设备导入</el-button>
                    </template>
                    <el-button type="primary" @click="exportRecord">导出</el-button>
                </div>
            </div>
            <el-table :border="true" :data="table.data" @selection-change="selectionChange" v-loading="loading">
                <el-table-column type="selection" width="55" align="center" />
                <el-table-column prop="client" label="设备编号" />
                <el-table-column prop="main_id" label="负责人ID" />
                <el-table-column prop="truename" label="指定负责人" />
                <el-table-column prop="shop_code_name" label="代理商" width="240" />
                <el-table-column prop="printname" label="打印机类型" />
                <el-table-column prop="run_type_name" label="运营状态" />
                <el-table-column prop="putintime" label="投放时间">
                    <template #default="{ row }">
                        <span>{{ row.putintime ? $utils.formatDate(row.putintime) : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="addtime" label="操作时间">
                    <template #default="{ row }">
                        <span>{{ row.addtime ? $utils.formatDate(row.addtime) : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="admin_id_name" label="操作人" />
                <el-table-column label="操作" width="100">
                    <template #default="{ row }">
                        <el-button type="danger" size="small" @click="handleDelete(row)" v-if="mypowerlimits.zdsblb2_sc">移除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="tabler-pagination">
                <el-button type="primary" @click="recordsRemove" v-if="mypowerlimits.zdsblb2_plsc">批量移除</el-button>
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[10, 20, 50, 100]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps } from 'vue'
import { ElMessageBox, ElNotification, ElMessage } from 'element-plus'
import axios from 'axios'

// 获取权限
const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authentication: localStorage.getItem('token')
    }
})
const props = defineProps({
    main_id: [String, Number],
    main_name: String
})

// 负责人
const tree = ref([])
const getTreeData = async () => {
    const { data } = await http.get('/admin/Equipment/fzrTree')
    tree.value = data.result
}

// 过滤
const filterRef = ref()
const params = reactive({
    cid: '',
    client_with_id: [],
    page: 1,
    page_size: 10
})

// 表格
const loading = ref(false)
const records = ref([])
const table = reactive({
    data: [],
    columns: [],
    total: 0
})
const selectionChange = (val) => {
    records.value = val
}
const getTableData = async (page = 1) => {
    params.page = page
    loading.value = true
    try {
        const { data } = await http.get('admin/Equipment/fzrClientList', { params: { ...params, client_with_id: props.main_id || [...params.client_with_id].pop() }   })
        Object.assign(table, data.result)
    } catch (e) {
        console.log(e)
    }
    loading.value = false
}

// 操作
// 重置查询条件
const resetFilter = () => {
    filterRef.value?.resetFields()
    params.cid = ''
    params.client_with_id = []
    getTableData()
}
// 移除
const handleDelete = async (row) => {
    await ElMessageBox.confirm(`是否移除设备${row.client}？`, '提示', { type: 'warning' })
    await http.post('admin/Equipment/fzrClientDel', { id: row.id, status: -2 })
    ElNotification.success({ title: '提示', type: 'success', message: '移除成功' })
    getTableData()
}
// 批量移除
const recordsRemove = async () => {
    if (records.value.length === 0) {
        ElMessage({ type: 'warning', message: '请选择要移除的设备', duration: 3000 })
        return
    }
    await ElMessageBox.confirm(`是否移除设备${records.value.map((item) => item.client).join(',')}？`, '提示', { type: 'warning' })
    const { data } = await http.post('admin/Equipment/fzrClientDel', { id: records.value.map((item) => item.id) })
    ElMessage({ type: 'success', message: data.message, duration: 3000 })
    getTableData()
}

// 下载模板
const downloadTemplate = async () => {
    const { data } = await http.get('admin/Equipment/downTemplate', { params: { templateName: 'fzr_transfer_client_template' } })
    const link = document.createElement('a')
    link.href = data.result.download
    link.click()
}
// 批量导入指定设备
const uploadTemplate = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.xlsx,.xls'
    input.click()
    input.onchange = async (e) => {
        const file = e.target.files[0]
        if (!file) return
        if (file.size > 1024 * 1024 * 10) {
            ElMessage({ type: 'warning', message: '文件大小不能超过10M', duration: 3000 })
            return
        }
        const formData = new FormData()
        formData.append('file', file)
        const { data } = await http.post('admin/Equipment/fzrClientTransfer', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        const { total, succ, fail, msg } = data.result
        let message = `共${total || 0}条记录`
        if (succ > 0) message += `<br />导入成功${succ}条`
        if (fail > 0) message += `<br />导入失败${fail}条`
        if (fail > 0) {
            message += `<br />失败原因：`
            for (let key in msg) {
                message += `<br />第${key}行：${msg[key]}`
            }
        }
        ElMessageBox.alert(message, '提示', { type: 'info', dangerouslyUseHTMLString: true })
        getTableData()
    }
}
// 导出
const exportRecord = async () => {
    const { data } = await http.get('/admin/Equipment/fzrClientList', { params: { ...params, client_with_id: props.main_id || [...params.client_with_id].pop(), export: 1 } })
    ElNotification({ title: '提示', type: data.code === 0 ? 'success' : 'warning', message: data.message })
}

getTableData()
getTreeData()
</script>

<style lang="scss" scoped>
.page-container {
    background-color: #e9eef3;
    .container-bread {
        font-size: 14px;
        color: #666;
        display: flex;
        align-items: center;
    }
    .container-filter {
        padding: 0px 24px 12px;
        background-color: #fff;
        .el-form {
            display: flex;
            justify-content: space-between;
        }
        .el-form-item {
            margin-right: 20px;
            margin-bottom: 0px;
        }
    }

    .container-table {
        margin-top: 4px;
        padding: 24px;
        background-color: #fff;
        .table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }
        .tabler-pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
        }
    }
}

.container-drawer {
    .filter-tree {
        margin-top: 24px;
    }
    .drawer-footer {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
