<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div class="boxlist">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width"
              :min-width="mytabletitle.minwidth">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'images'
              ">
                <div v-if="mytabletitle.prop === 'images'">
                  <div v-if="scope.row.images">
                    <el-image class="imagesshow" :src="scope.row.images" :preview-src-list="[scope.row.images]"
                      fit="cover"></el-image>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.textstatus">
                    <span>{{ scope.row.textstatus }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vderpartnercash",
  data() {
    return {
      mainonebread: [
        {
          name: "金融产品",
        },
        {
          name: "合伙人",
        },
        {
          name: "邀请返现记录",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "合同编号/邀请用户ID/姓名",
              name: "sponsor",
              content: "",
            },
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "被邀请人ID/姓名",
              name: "user",
              content: "",
            },
          ],
        },
        // selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        // selectleft: {
        //   width: "none",
        //   divinterval: "20px", //框右侧距离
        //   selectwidth: "200px",
        //   selectinterval: "", //select右侧距离
        //   select: [
        //     {
        //       selectisshow: true,
        //       ismust: false,
        //       title: "签约类型：",
        //       placeholder: "全部",
        //       name: "sign_type",
        //       value: "",
        //       options: [
        //         {
        //           label: "全部",
        //           value: "0",
        //         },
        //         {
        //           label: "投资（收益权）",
        //           value: "1",
        //         },
        //         {
        //           label: "设备（购买权）",
        //           value: "2",
        //         },
        //         {
        //           label: "设备（购买权分期）",
        //           value: "3",
        //         },
        //       ],
        //     },
        //   ],
        // },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 4, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150,
      mainthreetabletitle: [
        {
          fixedstatu: false,
          prop: "trade_no",
          label: "合同编号",
          width: "300",
        },
        // {
        //   fixedstatu: false,
        //   prop: "sign_type",
        //   label: "签约类型",
        //   width: "150",
        // },
        {
          fixedstatu: false,
          prop: "sponsor_uid",
          label: "邀请用户ID",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "sponsor_name",
          label: "邀请用户姓名",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "uid",
          label: "被邀请人ID",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "uname",
          label: "被邀请人姓名",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "money",
          label: "支付金额",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "total_price",
          label: "返现金额",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "remark",
          label: "备注",
          minwidth: "",
        },
        {
          fixedstatu: false,
          prop: "signtime",
          label: "签约时间",
          width: "120",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  setup() {
    const dialogVisible = ref(false);
    const handleCloseSenior = (done) => {
      done();
    };

    return {
      dialogVisible,
      handleCloseSenior,
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Lease/cashback",
          {
            sponsor: this.pagesdatas.sponsor,
            user: this.pagesdatas.user,
            // sign_type: this.pagesdatas.sign_type,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Lease/cashback",
          {
            sponsor: this.pagesdatas.sponsor,
            user: this.pagesdatas.user,
            // sign_type: this.pagesdatas.sign_type,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      this.$router.push(routeraddr).catch((error) => error);
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/admin/Lease/cashback",
          {
            sponsor: this.pagesdatas.sponsor,
            user: this.pagesdatas.user,
            // sign_type: this.pagesdatas.sign_type,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);
     
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Lease/cashback",
          {
            sponsor: data.sponsor,
            user: data.user,
            // sign_type: data.sign_type,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        // //渲染-签约类型
        // if (newdata[i].sign_type === 1) {
        //   newdata[i].sign_type = "投资（收益权）";
        // } else {
        //   if (newdata[i].sign_type === 2) {
        //     newdata[i].sign_type = "设备（购买权）";
        //   } else {
        //     if (newdata[i].sign_type === 3) {
        //       newdata[i].sign_type = "设备（购买权分期）";
        //     }
        //   }
        // }
        //转换-签约时间
        if (newdata[i].signtime) {
          if (newdata[i].signtime === 0 || newdata[i].signtime === null) {
            newdata[i].signtime = "--";
          } else {
            newdata[i].signtime = this.$utils
              .formatDate(newdata[i].signtime)
              .substr(0, 10);
          }
        } else {
          newdata[i].signtime = "--";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Lease/cashback",
          {
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>
<style scoped>
.boxlist {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  line-height: 40px;
  padding: 0 20px;
}
</style>