<template>
    <div>
        <!--详情信息：第一种样式类型-->
        <div v-if="detailsData.showstatus === 1">
            <el-row>
                <el-col style="font-weight: 400">
                    <div style="font-size: 14px; font-weight: bold; color: #333333; margin-bottom: 10px" v-if="detailsData.title">
                        {{ detailsData.title }}
                        <el-button
                            v-if="detailsData.button.isshow"
                            style="margin-left: 30px"
                            size="small"
                            type="primary"
                            @click="routeraddrClick(detailsData.button.jump, detailsData.button.jumpcontent)"
                        >
                            {{ detailsData.button.name }}
                        </el-button>
                    </div>
                    <div class="flex-box">
                        <div v-for="(detailsdatainfo, i) in detailsData.content" :key="i" class="flex-box-item flex-box">
                            <div class="flex-box-item-name" :style="{ 'min-width': detailsData.titlewidth ? detailsData.titlewidth : '100px' }">
                                <span>{{ detailsdatainfo.name }}</span>
                            </div>
                            <div style="white-space: pre-line" class="flex-box-item-value flex-box">
                                <div>
                                    <span>{{ detailsdatainfo.value }}</span>
                                </div>
                                <div>
                                    <slot name="contentJumpbt" :detailsdatainfo="detailsdatainfo">
                                        <div style="color: #465eff; cursor: pointer" @click="routeraddrClick(detailsdatainfo.jump, detailsdatainfo.jumpdatas)">
                                            {{ detailsdatainfo.jumpbt }}
                                        </div>
                                    </slot>
                                </div>
                            </div>
                            <div v-if="detailsdatainfo.subbt" style="color: #465eff; cursor: pointer" @click="subBtEvent">{{ detailsdatainfo.subbt }}</div>
                            <div>
                                <slot name="contentValue"></slot>
                            </div>
                        </div>
                        <slot name="contentImages"></slot>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!--详情信息：第二种样式类型-->
        <div v-if="detailsData.showstatus === 2"></div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'Maindetails',
    props: ['detailsdata'],
    setup(props) {
        const detailsData = reactive(props['detailsdata'])
        return {
            detailsData
        }
    },
    methods: {
        //点击进入路由页面&携带参数跳转
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 携带参数跳转
                this.$router.push({ path: routeraddr, query: rowdata }).catch((error) => error)
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },

        // 返回到父组件的按钮事件
        subBtEvent() {
            this.$emit('subbt', true)
        }
    }
}
</script>

<style scoped>
.flex-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}

.flex-box-item {
    font-size: 14px;
    color: #333333;
    line-height: 40px;
}

.flex-box-item-name {
    min-width: 100px;
    text-align: right;
}

.flex-box-item-value {
    min-width: 260px;
    max-width: 300px;
}
</style>
