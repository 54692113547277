<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box"  >
                <div class="box_title"  >
                    <div class="box_title_font">操作记录</div>
                    <div class="box_title_button">
                        <el-button type="primary" @click="this.$router.go(-1)">关闭</el-button>
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <div>
                        <!--table表单-->
                        <el-table
                            :header-cell-style="{ background: '#F5F7FA' }"
                            ref="multipleTable"
                            :row-class-name="tableRowClassName"
                            :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                            style="width: 100%"
                            min-height="900"
                            border
                            v-loading="loading"
                            element-loading-text="正在飞速加载中..."
                            @selection-change="handleSelectionChange"
                            @cell-click="handleOneData"
                        >
                            <!--多选框-用于用户选择性导出数据-->
                            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                            <el-table-column
                                v-for="(mytabletitle, i) in mainthreetabletitle"
                                :key="i"
                                :fixed="mytabletitle.fixedstatu"
                                :prop="mytabletitle.prop"
                                :label="mytabletitle.label"
                                :width="mytabletitle.width"
                                :min-width="mytabletitle.minwidth"
                            >
                                <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                                <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                                <template #default="scope" v-if="mytabletitle.prop === 'addtime'">
                                    <!-- 入账时间-数据渲染 -->
                                    <div v-if="mytabletitle.prop === 'addtime'">
                                        <div>
                                            <span>{{ scope.row.addtime != 0 ? this.$utils.formatDate(scope.row.addtime) : '' }}</span>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!--分页器-->
                        <div class="block" style="text-align: center; margin: 30px 0">
                            <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                                :page-sizes="pageSizes"
                                :page-size="PageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="totalCount"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import axios from 'axios'

export default {
    name: 'vsetderivativeslog',
    data() {
        return {
            mainonebread: [
                {
                    name: '金融产品'
                },
                {
                    name: '设置'
                },
                {
                    name: '金融产品设置',
                    path: '/setderivativeslist'
                },
                {
                    name: '操作记录'
                }
            ], // 当前页-【面包屑导航】

            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 9, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 150, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '操作时间',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'admin_info',
                    label: '操作人',
                    width: '200',
                    minwidth: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'contents',
                    label: '操作事件',
                    width: '',
                    minwidth: '300'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/lease/configLog', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id,
                        page: 1,
                        page_size: val
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/lease/configLog', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id,
                        page: val,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },

        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 跳转-用户列表
                if (routeraddr == '/userlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                userid: rowdata.uid,
                                thirdparty: rowdata.thirdparty
                            }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            //
        },

        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            // 获取操作记录
            axios
                .get('/admin/lease/configLog', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    padding: 5px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}

.belong-input {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.belong-input-box {
    width: 100%;
    margin-right: 20px;
}

.belong-text {
    font-size: 16px;
    color: #333333;
    margin-right: 30px;
}

.find-box {
    font-size: 16px;
}
</style>
