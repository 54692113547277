<template>
	<div class="wrap">
		<el-dialog title="修改绑定设备" v-model="dialogDisplay" @close="onCancel">
            <el-form ref="ruleFormRef" :model="formData" :rules="rules" label-width="180px" style="padding: 0 20px 0 0">
				<el-form-item label="绑定设备：" prop="client">
                    <el-input v-model="formData.client" style="width: 80%" placeholder="" maxlength="50"></el-input>
                </el-form-item>
			</el-form>
			<div style="text-align: right">
				<el-button @click="onCancel">取消 </el-button>
				<el-button type="primary" @click="onSubmit">确定 </el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script setup>
import { ref, reactive, watch, defineProps, defineEmits, watchEffect } from 'vue'
import http from '@/assets/js/http'

const dialogDisplay = ref(false)
const ruleFormRef = ref()
const formData = ref({})
const emit = defineEmits(['success', 'cancel'])

const props = defineProps({
	data: {
		type: Object,
		default: ()=> {
			return {}
		}
	},
	deviceDialog: {
		type: Boolean,
		default: ()=> {
			return false
		}
	}
})

const rules = reactive({
	client: [
		{
			required: true,
			message: '请输入名称',
			trigger: 'blur',
		},
	],
})

watch(props, (newVal)=> {
	const { id, client } = newVal.data
	formData.value = { id, client }
	dialogDisplay.value = newVal.deviceDialog
})

// 提交
const onSubmit = async ()=> {
	try {
		await ruleFormRef.value.validate()
	} catch (err) {
		return false
	}

	const { id, client } = formData.value

	await http.post('Member/updateBind', { id, client })
	
	emit('success')
}

// 取消
const onCancel = ()=> {
	emit('cancel')
	// dialogDisplay.value = false
}

</script>

<style lang="scss" scoped>

</style>