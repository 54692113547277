<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        >
            <template v-slot:searchModel>
                <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; color: #000; font-size: 14px; margin-right: 10px">
                    <div><span>用户标签：</span></div>
                    <treeCascaderBox
                        :ModelValue="change_tags"
                        :Placeholder="'请选择用户标签'"
                        :Options="pid_options"
                        :Props="pid_options_props"
                        :CollapseTags="true"
                        @changedatas="changedatasEvent"
                    />
                </div>
            </template>
        </maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <!-- <el-button type="warning"  plain  @click="exportDatas()">导出</el-button > -->
                    </div>
                </div>
                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, key, idex) in mainnewtititle"
                            :key="idex"
                            :prop="key"
                            :label="mytabletitle"
                            :width="width_option[key] ? width_option[key] : '100px'"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="key == 'id' || key == 'coin' || key == 'order_num' || key == 'tags' || key == 'is_vip' || key == 'vip_end_time'">
                                <!--用户ID-样式等渲染-->
                                <div v-if="key == 'id'">
                                    <div class="mytabletext" @click="routeraddrClick('/userinfolist', scope.row)">
                                        <span>{{ scope.row.id }}</span>
                                    </div>
                                </div>
                                <!--京币-样式等渲染-->
                                <div v-if="key == 'coin'">
                                    <div
                                        :class="this.mypowerlimits.ybffxfjl == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.ybffxfjl == true ? routeraddrClick('/yingbiconsume', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.coin }}</span>
                                    </div>
                                </div>
                                <!--打印订单数-样式等渲染-->
                                <div v-if="key == 'order_num'">
                                    <div
                                        :class="this.mypowerlimits.ddlb == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.ddlb == true ? routeraddrClick('/orderlist', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.order_num }}</span>
                                    </div>
                                </div>
                                <!-- 标签渲染 -->
                                <div v-if="key == 'tags'" style="white-space: pre-wrap">
                                    <el-popover placement="left" title="" :width="300" trigger="click" :content="scope.row.tags">
                                        <div style="display: flex; flex-direction: row; justify-content: left; align-items: center; flex-wrap: wrap; gap: 10px">
                                            <el-tag v-for="(tag, index) in scope.row.tags" :key="index" style="flex: 1">
                                                {{ tag }}
                                            </el-tag>
                                        </div>
                                        <template #reference>
                                            <div :class="is_show_sub == 1 ? 'no-warp-2' : ''" class="show-box">
                                                <!-- <span>{{ scope.row.tags && scope.row.tags.length > 0 ? scope.row.tags.join('\n') : '' }}</span> -->
                                                <span>{{ scope.row.tags && scope.row.tags.length > 0 ? scope.row.tags.join(', ') : '' }}</span>
                                            </div>
                                        </template>
                                    </el-popover>
                                </div>
                                <!-- 权益会员 -->
                                <div v-if="key == 'is_vip'">
                                    <span>{{ is_vip_option[scope.row.is_vip] }}</span>
                                </div>
                                <!-- 到期时间 -->
                                <div v-if="key == 'vip_end_time'">
                                    <div>
                                        <span>{{
                                            scope.row.vip_end_time != ''
                                                ? scope.row.vip_end_time
                                                      .split('\n')
                                                      .map((n) => {
                                                          return this.$utils.formatDate(n)
                                                      })
                                                      .join('\n')
                                                : ''
                                        }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-代理商列表-->
                                <div v-if="this.mainthreetableope === 1">
                                    <el-button type="primary" size="mini" @click="tanDongEvent(scope.row)" v-if="scope.row.frozen === -1 && mypowerlimits.yhlb_djje"
                                        >冻结余额
                                    </el-button>
                                    <el-button type="info" size="mini" @click="tanJieEvent(scope.row)" v-if="scope.row.frozen === 1 && mypowerlimits.yhlb_djje"
                                        >解冻余额
                                    </el-button>
                                    <el-button type="primary" size="mini" @click="addTanClick(scope.row, (dialogVisible = true))" v-if="mypowerlimits.yhlb_ckk">充扣款</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器 layout="total, sizes, prev, pager, next, jumper" -->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            :layout="Layout"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!--弹出框-充扣款-->
            <el-dialog v-model="dialogVisible" title="充扣款" :width="this.searchwidth" :before-close="handleCloseSenior">
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                    <div class="tan-box-list">
                        <el-form-item label="类型：" prop="resource">
                            <el-radio-group v-model="ruleForm.resource">
                                <el-radio label="1">充值</el-radio>
                                <el-radio label="2">扣款</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="金额：" prop="money">
                            <el-input v-model="ruleForm.money" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="手机号：" prop="phone">
                            <el-input v-model="ruleForm.phone" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="微信昵称：" prop="nickname">
                            <el-input v-model="ruleForm.nickname" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="备注：" prop="desc">
                            <el-input v-model="ruleForm.desc" type="textarea"></el-input>
                        </el-form-item>
                        <el-form-item style="text-align: right">
                            <el-button @click="resetForm('ruleForm')">重置</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm')" :disabled="mydisabled">确认</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vuserlist',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '用户'
                },
                {
                    name: '用户管理'
                },
                {
                    name: '用户列表',
                    path: '/userlist'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: true, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '180px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '用户ID/用户昵称/手机号',
                            name: 'keywords',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '会员等级：',
                            placeholder: '请输入会员等级',
                            name: 'vip_name',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '180px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: 'Plus会员：',
                            placeholder: '全部',
                            name: 'is_plus',
                            value: '',
                            options: [
                                {
                                    value: '0',
                                    label: '全部'
                                },
                                {
                                    value: '1',
                                    label: '是'
                                },
                                {
                                    value: '-1',
                                    label: '否'
                                }
                            ]
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '权益会员：',
                            placeholder: '全部',
                            name: 'is_vip',
                            value: '',
                            options: [
                                {
                                    value: '0',
                                    label: '全部'
                                },
                                {
                                    value: '1',
                                    label: '是'
                                },
                                {
                                    value: '-1',
                                    label: '否'
                                }
                            ]
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '到期时间：',
                            placeholder: {
                                placeholderstart: '开始时间',
                                placeholderend: '结束时间'
                            },
                            name: 'vip_end_time',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            selectmaxwidth: '400px', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        }
                    ]
                },
                selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
                selecttop: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '180px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '平台来源：',
                            placeholder: '全部',
                            name: 'thirdparty',
                            value: '1', //显示值，初始状况，显示值与默认值需相同
                            valueisshow: true,
                            valuedefault: '1', //设置默认值
                            options: componentProperties.get('thirdpartyoption').options
                        }
                    ]
                }
            }, // 筛选-【筛选查询】-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 180, // 数据列表中-操作-宽度
            mainthreetabletitle: [], // 数据列表中-数据表头
            mainnewtititle: {}, //最新的title-数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1000, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            Layout: ' sizes, prev, pager, next, jumper',

            //高级筛选
            myinputishow: true, //高级筛选-input单框
            myinput: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%',
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '输入搜索：',
                        placeholder: '用户ID/用户昵称/手机号',
                        name: 'keywords',
                        content: ''
                    }
                ]
            },
            myselectisshow: true, //高级筛选-select单框
            myselect: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: 'PLUS会员：',
                        placeholder: '全部',
                        name: 'is_plus',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '是'
                            },
                            {
                                value: '-1',
                                label: '否'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '用户类型:',
                        placeholder: '全部',
                        name: 'user_type',
                        value: '',
                        options: [
                            {
                                label: '全部',
                                value: '0'
                            },
                            {
                                label: '用户',
                                value: '1'
                            },
                            {
                                label: '商户',
                                value: '2'
                            },
                            {
                                label: '合伙人',
                                value: '3'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '关注公众号:',
                        placeholder: '全部',
                        name: 'subscribe',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '已关注'
                            },
                            {
                                value: '-1',
                                label: '未关注'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '实名认证:',
                        placeholder: '全部',
                        name: 'is_real',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '是'
                            },
                            {
                                value: '-1',
                                label: '否'
                            }
                        ]
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '注册时间区间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'addtime',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '最后登录时间区间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'last_login_time',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },
            myselecttopisshow: true, //高级筛选-select单框-放input框之前
            myselecttop: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '平台来源：',
                        placeholder: '全部',
                        name: 'thirdparty',
                        value: '1', //显示值，初始状况，显示值与默认值需相同
                        valueisshow: true,
                        valuedefault: '1', //设置默认值
                        options: componentProperties.get('thirdpartyoption').options
                    }
                ]
            },

            // 标签下拉
            pid_options_props: { value: 'id', label: 'name', children: 'child', multiple: true, checkStrictly: false, emitPath: false },
            pid_options: [], // 标签id下拉
            change_tags: '',

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',

            //弹窗相关数据
            dialogVisible: ref(false), //弹窗显示&隐藏
            ruleForm: {
                id: '', //当前行用户id
                thirdparty: '', //当前行用户-所属平台
                resource: ref('1'),
                money: '',
                phone: '',
                nickname: '',
                desc: ''
            }, //弹窗中-form表单
            rules: {
                money: [
                    {
                        required: true,
                        message: '金额为必填项',
                        trigger: 'blur'
                    }
                ],
                phone: [
                    {
                        required: false,
                        message: '请输入手机号',
                        trigger: 'change'
                    }
                ],
                nickname: [
                    {
                        required: false,
                        message: '请输入微信昵称',
                        trigger: 'change'
                    }
                ],
                resource: [
                    {
                        required: true,
                        message: '请至少选择一个类型',
                        trigger: 'change'
                    }
                ],
                desc: [
                    {
                        required: false,
                        message: 'Please input activity form',
                        trigger: 'blur'
                    }
                ]
            }, //弹窗中-form表单-必填规则

            is_show_sub: 1,

            // 权益会员options
            is_vip_option: { 1: '是', '-1': '否' },
            // 列表title的宽度
            width_option: { vip_end_time: '180px', mobile: '160px' },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            mydisabled: false
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        showEvent(data) {
            data == 1 ? (this.is_show_sub = 2) : (this.is_show_sub = 1)
        },
        // 【筛选-用户标签】多选标签数据
        changedatasEvent(data) {
            this.change_tags = data
        },

        //冻结&解冻弹窗
        tanDongEvent(data) {
            ElMessageBox.confirm('确定要冻结余额吗？', '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'primary'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/Member/switchFrozen',
                            {
                                id: data.id,
                                thirdparty: data.thirdparty
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '已冻结余额'
                                })
                                data.frozen = 1
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消'
                    })
                })
        },
        //冻结&解冻弹窗
        tanJieEvent(data) {
            ElMessageBox.confirm('确定要解冻余额吗？', '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'primary'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/Member/switchFrozen',
                            {
                                id: data.id,
                                thirdparty: data.thirdparty
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '已解冻余额'
                                })
                                data.frozen = -1
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消'
                    })
                })
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            // console.log("this is:" + val);
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                is_vip: this.pagesdatas.is_vip,
                vip_name: this.pagesdatas.vip_name,
                vip_end_time: this.pagesdatas.vip_end_time,
                keywords: this.pagesdatas.keywords,
                is_plus: this.pagesdatas.is_plus,
                user_type: this.pagesdatas.user_type,
                subscribe: this.pagesdatas.subscribe,
                source: this.pagesdatas.source,
                is_real: this.pagesdatas.is_real,
                addtime: this.pagesdatas.addtime,
                last_login_time: this.pagesdatas.last_login_time,
                thirdparty: this.pagesdatas.thirdparty,
                tags: this.pagesdatas.tags,
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas, 2)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            this.pagesdatas = {
                is_vip: this.pagesdatas.is_vip,
                vip_name: this.pagesdatas.vip_name,
                vip_end_time: this.pagesdatas.vip_end_time,
                keywords: this.pagesdatas.keywords,
                is_plus: this.pagesdatas.is_plus,
                user_type: this.pagesdatas.user_type,
                subscribe: this.pagesdatas.subscribe,
                source: this.pagesdatas.source,
                is_real: this.pagesdatas.is_real,
                addtime: this.pagesdatas.addtime,
                last_login_time: this.pagesdatas.last_login_time,
                thirdparty: this.pagesdatas.thirdparty,
                tags: this.pagesdatas.tags,
                page: val,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas, 3)
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row'
            }
            return ''
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            // console.log("当前获取的taninfo is :" + JSON.stringify(taninfo));
            this.ruleForm.id = taninfo.id
            this.ruleForm.thirdparty = taninfo.thirdparty
            this.ruleForm.phone = taninfo.mobile
            this.ruleForm.nickname = taninfo.nickname
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata, rowtitle) {
            if (rowdata) {
                if (routeraddr == '/userinfolist12312') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                id: rowdata.id,
                                rowdata: JSON.stringify(rowdata),
                                rowtitle: JSON.stringify(rowtitle)
                            }
                        })
                        .catch((error) => error)
                } else {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                id: rowdata.id,
                                thirdparty: rowdata.thirdparty
                            }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas(tableData) {
            console.log(tableData)
        },
        //弹出框-提交表单
        submitForm(formName) {
            // 前端防抖
            this.mydisabled = true

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model
                    axios
                        .post(
                            '/admin/Member/balanceAction',
                            {
                                receive_uid: getdatas.id,
                                platform: getdatas.thirdparty,
                                type: getdatas.resource,
                                price: getdatas.money,
                                receive_mobile: getdatas.phone,
                                remark: getdatas.desc
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.reload() //刷新
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //弹出框-重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        //弹窗-关闭
        handleCloseSenior(done) {
            done()
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
        searchFormEvent(data) {
            // console.log('高级筛选-开始检索返回数据(页面):' + JSON.stringify(data))
            //判断-注册时间区间段状态
            var getaddtime = null
            if (data.addtime.start == '') {
                getaddtime = ''
            } else {
                if (data.addtime.end == '') {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.start + 86400 - 1)
                } else {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.end + 86400 - 1)
                }
            }
            //判断-注册时间区间段状态
            var getlastlogintime = null
            if (data.last_login_time.start == '') {
                getlastlogintime = ''
            } else {
                if (data.last_login_time.end == '') {
                    getlastlogintime = data.last_login_time.start + ' ~ ' + (data.last_login_time.start + 86400 - 1)
                } else {
                    getlastlogintime = data.last_login_time.start + ' ~ ' + (data.last_login_time.end + 86400 - 1)
                }
            }

            this.pagesdatas = {
                keywords: data.keywords,
                is_plus: data.is_plus,
                user_type: data.user_type,
                subscribe: data.subscribe,
                source: data.source,
                is_real: data.is_real,
                addtime: getaddtime,
                last_login_time: getlastlogintime,
                thirdparty: data.thirdparty,
                // tags: this.change_tags && this.change_tags != '' ? this.change_tags.join(',') : '',
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas, 1)
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log("点击查询获取的data数组：" + JSON.stringify(data));
            //判断-注册时间区间段状态
            var vip_end_time = null
            if (data.vip_end_time.start == '') {
                vip_end_time = ''
            } else {
                if (data.vip_end_time.end == '') {
                    vip_end_time = data.vip_end_time.start + ' ~ ' + (data.vip_end_time.start + 86400 - 1)
                } else {
                    vip_end_time = data.vip_end_time.start + ' ~ ' + (data.vip_end_time.end + 86400 - 1)
                }
            }

            this.pagesdatas = {
                is_vip: data.is_vip,
                vip_name: data.vip_name,
                vip_end_time: vip_end_time,
                keywords: data.keywords,
                is_plus: data.is_plus,
                thirdparty: data.thirdparty,
                tags: this.change_tags && this.change_tags != '' ? this.change_tags.join(',') : '',
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas, 1)
        },
        //数据渲染
        myaxiosfunc(successdata, flag) {
            // console.log(successdata);
            //渲染title-本页table表单title
            this.mainnewtititle = successdata.title
            //渲染数据
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //is_real该字段表示实名认证，1表示已认证，2表示未认证
                if (newdata[i].is_real) {
                    if (newdata[i].is_real === 1) {
                        newdata[i].is_real = '已认证'
                    } else {
                        newdata[i].is_real = '未认证'
                    }
                }

                //user_type该字段表示用户类型，1表示用户，2表示商户 ， 3表示合伙人
                if (newdata[i].user_type) {
                    var getusertype = []
                    for (let index = 0; index < newdata[i].user_type.length; index++) {
                        const element = newdata[i].user_type[index]
                        if (element == 1) {
                            getusertype.push('用户')
                        } else {
                            if (element == 2) {
                                getusertype.push('商户')
                            } else {
                                if (element == 3) {
                                    getusertype.push('合伙人')
                                }
                            }
                        }
                    }
                    newdata[i].user_type = getusertype.join(',')
                }

                //is_plus该字段表示是否是会员，1表示是,-1表示否，2表示已过期
                if (newdata[i].is_plus) {
                    if (newdata[i].is_plus === 1) {
                        newdata[i].is_plus = '是'
                    } else {
                        if (newdata[i].is_plus === -1) {
                            newdata[i].is_plus = '否'
                        } else {
                            newdata[i].is_plus = '已过期'
                        }
                    }
                }

                //subscribe该字段表示是否关注公众号 1未关注 -1关注
                if (newdata[i].subscribe) {
                    if (newdata[i].subscribe === 1) {
                        newdata[i].subscribe = '已关注'
                    } else {
                        newdata[i].subscribe = '未关注'
                    }
                }

                //转换plus到期时间
                if (newdata[i].end_time) {
                    if (newdata[i].end_time === 0 || newdata[i].end_time === null || newdata[i].end_time === '未开通') {
                        newdata[i].end_time = '未开通'
                    } else {
                        // console.log("plus开通时间：" + newdata[i].end_time);
                        newdata[i].end_time = this.$utils.formatDate(newdata[i].end_time)
                    }
                } else {
                    newdata[i].end_time = '未开通'
                }
                //转换注册时间
                if (newdata[i].addtime) {
                    if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                        newdata[i].addtime = '无注册时间'
                    } else {
                        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                    }
                }

                //转换最后登录时间
                if (newdata[i].last_login_time) {
                    if (newdata[i].last_login_time === 0 || newdata[i].last_login_time === null) {
                        newdata[i].last_login_time = '无最后登录时间'
                    } else {
                        newdata[i].last_login_time = this.$utils.formatDate(newdata[i].last_login_time)
                    }
                }

                //打印折扣
                if (newdata[i].discount == '') {
                    newdata[i].discount = '暂无折扣'
                } else {
                    newdata[i].discount = newdata[i].discount + '折'
                }
            }
            this.mainthreetableData = newdata
            // this.totalCount = successdata.total;
            // this.currentpage = successdata.current_page; //当前页-页面值
            // this.lastpage = successdata.last_page;
            this.loading = false

            // 数据小于当前页数时判断
            if (newdata.length < this.PageSize) {
                if (newdata.length != 0 && this.currentPage == 1) {
                    this.totalCount = this.PageSize - 1
                } else {
                    if (newdata.length == 0) {
                        // 按照常理：为0会减1页，由于双向绑定问题一直请求服务器，因此先注释，待后续优化
                        // this.totalCount = this.PageSize * (this.currentPage - 1)
                        this.totalCount = this.PageSize * this.currentPage
                    } else {
                        this.totalCount = this.PageSize * this.currentPage
                        this.lastpage = this.currentPage
                    }
                }
            } else {
                if (this.totalCount < this.PageSize) {
                    // 重设置-初始值
                    this.totalCount = 1000
                    this.Layout = 'sizes, prev, pager, next, jumper'
                } else {
                    if (flag == 1) {
                        this.currentPage = 1
                    }
                }
            }
        },
        // 获取数据Axios
        postAxiosEvent(postdata, flag) {
            //加载loading
            this.loading = true
            axios
                .post('/admin/Member/lists', postdata, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (flag) {
                        if (flag == 1) {
                            // 筛选查询
                            this.myaxiosfunc(response.data.result, flag)
                        } else if (flag == 2) {
                            // 每页 ${val} 条
                            let getdata = response.data.result.data
                            if (getdata.length > 0) {
                                if (getdata.length == this.PageSize) {
                                    this.lastpage = this.totalCount / this.PageSize
                                    if (this.totalCount / this.PageSize == this.lastpage) {
                                        this.totalCount += this.PageSize * 5
                                        this.lastpage = this.totalCount / this.PageSize
                                    }
                                    this.myaxiosfunc(response.data.result)
                                } else {
                                    this.totalCount += getdata.length
                                    this.lastpage += 1
                                    this.myaxiosfunc(response.data.result)
                                }
                            } else {
                                this.myaxiosfunc(response.data.result)
                            }
                        } else if (flag == 3) {
                            // 当前页: 第${val}页
                            let getdata = response.data.result.data
                            if (getdata.length > 0) {
                                if (getdata.length == this.PageSize) {
                                    this.lastpage = this.totalCount / this.PageSize
                                    if (this.totalCount / this.currentPage == this.PageSize) {
                                        this.totalCount += this.PageSize * 5
                                        this.lastpage = this.totalCount / this.PageSize
                                    }
                                    this.myaxiosfunc(response.data.result)
                                } else {
                                    this.totalCount += getdata.length
                                    this.lastpage = 1
                                    this.myaxiosfunc(response.data.result)
                                }
                            } else {
                                this.myaxiosfunc(response.data.result)
                            }
                        }
                    } else {
                        this.myaxiosfunc(response.data.result)
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },

        // get获取下拉数据
        getAxiosEvent() {
            axios
                .get('/admin/Member/lists', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    let getdatas = response.data.result
                    // 获取-标签相关下拉
                    this.pid_options = getdatas.tags
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            // 获取下拉
            this.getAxiosEvent()

            // 定义
            this.pagesdatas = {
                thirdparty: '1',
                page: 1,
                page_size: this.PageSize
            }
            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query
            if (routerinfo.userid) {
                this.pagesdatas = {
                    keywords: routerinfo.userid,
                    thirdparty: routerinfo.thirdparty,
                    page: 1,
                    page_size: this.PageSize
                }
                this.maintwoforminfo.myinputone.input[0].content = routerinfo.userid
            }

            if (routerinfo.thirdparty) {
                this.pagesdatas.thirdparty = routerinfo.thirdparty
                this.maintwoforminfo.selecttop.select[0].value = routerinfo.thirdparty
                this.maintwoforminfo.selecttop.select[0].valuedefault = routerinfo.thirdparty
            }

            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.tan-box-list {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}

.no-warp-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.no-warp-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.show-box {
    font-size: 12px;
    color: var(--el-color-primary);
}
.show-box:hover {
    cursor: pointer;
    color: var(--el-color-warning);
}
</style>
