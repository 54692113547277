<template>
    <div>
        <div><span>注：摇篮期、成长期、稳定期月平均 = 日平均 * 30天</span></div>
        <div>
            <el-descriptions direction="vertical" :column="6" :size="size" border>
                <el-descriptions-item label="设备编号">{{ clientinfo.client }}</el-descriptions-item>
                <el-descriptions-item label="投放时间">{{ clientinfo.addtime ? this.$utils.formatDate(clientinfo.addtime) : '' }}</el-descriptions-item>
                <el-descriptions-item label="收入合计">{{ clientinfo.acount }}</el-descriptions-item>
                <el-descriptions-item label="摇篮期(月平均)">{{ clientinfo.yaolan }}</el-descriptions-item>
                <el-descriptions-item label="成长期(月平均)">{{ clientinfo.chengzhang }}</el-descriptions-item>
                <el-descriptions-item label="稳定期(月平均)">{{ clientinfo.wending }}</el-descriptions-item>
            </el-descriptions>
        </div>
        <div style="margin-top: 20px;">
            <echartsbglinechat :List="clientinfo.list" ref="Refechartsbglinechat"/>
        </div>
    </div>
</template>

<script>
import echartsbglinechat from './echartsbglinechat.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'yeardvccountinfo',
    props: ['ClientInfo'],
    watch: {
        ClientInfo: {
            handler(newVal) {
                this.clientinfo = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            clientinfo: { client: '', addtime: '', acount: '', yaolan: '', chengzhang: '', wending: '' },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem('userinfo')) //获取存储的用户信息
        }
    },
    methods: {
        refEcharts(data){
            this.$refs.Refechartsbglinechat.echartCustom(data)
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        }
    },
    components: {
        echartsbglinechat
    }
})
</script>

<style scoped></style>
