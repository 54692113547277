<template>
    <div id="myimges">
        <!--图片显示：第一种样式类型-->
        <div v-if="imgesdata.showstatus === 1">
            <div style="border: 1px dashed #94a3d2;border-radius: 8px; ">
                <div style="padding: 10px 0 0 10px;width: 100%;">
                    <el-image
                        v-for="(imgesdatainfo, i) in imgesdata.content"
                        :key="i"
                        style="width: 120px; height: 140px;border-radius: 4px;margin:0 10px 5px 0"
                        :src="imgesdatainfo.url"
                        :preview-src-list="imgesdatainfo.preview"
                        hide-on-click-modal
                        fit="cover"
                    ></el-image>
                </div>
            </div>
        </div>
        <!--图片显示：第二种样式类型-->
        <div v-if="imgesdata.showstatus === 2">
            <div v-if="imgesdata.content.length > 0" style="border: 1px dashed #465EFF;display: table;padding: 10px 0 0 10px;border-radius: 8px">
                <el-image
                    v-for="(imgesdatainfo, i) in imgesdata.content"
                    :key="i"
                    style="width: 120px; height: 140px;border-radius: 4px;margin:0 10px 5px 0;"
                    :src="imgesdatainfo.url"
                    :preview-src-list="imgesdatainfo.preview"
                    hide-on-click-modal
                    :fit="imgesdata.fit ? imgesdata.fit : 'cover'"
                ></el-image>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'Mainimgesshow',
    props: ['imgesdata'],
    setup(props) {
        const imgesData = reactive(props['imgesdata'])
        // console.log(imgesData)

        return {
            imgesData
        }
    }
}
</script>

<style scoped>
#myimges :deep(.el-image-viewer__close) {
    top: 80px;
    right: 40px;
}
</style>
