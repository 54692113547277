<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'uid'">
                <div v-if="mytabletitle.prop === 'uid'">
                  <div :class="this.mypowerlimits.yhlb == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.yhlb == true ? routeraddrClick('/userlist', scope.row) : ''">
                    <span>{{ scope.row.uid }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-财务-申请退保证金-->
                <div v-if="mainthreetableope === 33">
                  <el-button type="primary" size="mini" v-if="this.mypowerlimits.sqtbzj_ck"
                    @click="routeraddrClick('/bondapplybackinfo', scope.row)">查看详情</el-button>
                  <el-button type="primary" size="mini" v-if="this.mypowerlimits.sqtbzj_cl && scope.row.status == -1"
                    @click="doApplyBack(scope.row.id, (dialogVisible1 = true))">处理</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog ref="dealDialog" v-model="dialogVisible1" title="处理" :before-close="handleCloseSenior"
        :width="this.searchwidth">
        <el-form ref="ruleForm" :model="dealForm" :rules="rules" label-width="120px" class="demo-ruleForm">
          <div style="
              max-height: 450px;
              overflow: hidden;
              overflow-y: visible;
              margin: 20px 5% 0 0;
            ">
            <el-form-item label="选择处理方式:" prop="status">
              <el-radio-group v-model="dealForm.status">
                <el-radio :label="2">驳回</el-radio>
                <el-radio :label="1">同意退保证金</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="选择退款明细:" prop="tableArr" v-if="dealForm.status == 1">
              <el-checkbox-group v-model="dealForm.tableArr">
                <el-row v-for="item in dealArr" :key="item.id">
                  <el-checkbox size="large" :label="item.id">
                    <div class="checkbox-box">
                      <div>
                        <span style="margin-right: 10px">支付宝/微信：￥{{ item.amount }}</span>
                      </div>
                      <div>
                        <span style="margin-left: 10px">{{ item.stand_num }}台</span>
                      </div>
                      <div>
                        <span style="margin-left: 10px">型号：{{ JSON.parse(item.system_price).title }}</span>
                      </div>
                    </div>
                  </el-checkbox>
                </el-row>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="处理备注:" prop="remark">
              <el-input type="textarea" v-model="dealForm.remark"></el-input>
            </el-form-item>
            <el-form-item style="text-align: right">
              <el-button @click="dialogVisible1 = false">取消</el-button>
              <el-button type="primary" @click="dealFormSubmmit('ruleForm')" :disabled="mydisabled">确定</el-button>
            </el-form-item>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vbondapplyback",
  inject: ["reload"],
  data() {
    return {
      mainonebread: [
        {
          name: "财务",
        },
        {
          name: "保证金",
        },
        {
          name: "申请退保证金",
          path: "/bondapplyback",
        },
      ], // 当前页-【面包屑导航】
      dialogVisible1: false,
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "用户ID/联系方式",
              name: "uid",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "处理状态：",
              placeholder: "全部",
              name: "status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "待处理",
                  value: "-1",
                },
                {
                  label: "已退款",
                  value: "1",
                },
                {
                  label: "已拒绝",
                  value: "2",
                },
              ],
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "thirdparty",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      }, // 筛选-【筛选查询】-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 33, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 140, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "bond_no",
          label: "申请编号",
          width: "240",
        },
        {
          fixedstatu: false,
          prop: "thirdpartyName",
          label: "平台来源",
          width: "130",
        },
        {
          fixedstatu: false,
          prop: "uid",
          label: "用户ID",
          width: "110",
        },
        {
          fixedstatu: false,
          prop: "bond",
          label: "保证金",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "number",
          label: "签约台数",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "contact",
          label: "联系人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "mobile",
          label: "联系方式",
          width: "130",
        },
        {
          fixedstatu: false,
          prop: "content",
          label: "申请原因",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "deal_status",
          label: "处理状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "deal_name",
          label: "处理人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "申请时间",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "dealtime",
          label: "处理时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      dealForm: {
        id: "",
        status: 2,
        tableArr: [],
        remark: "",
      },
      dealArr: [],
      rules: {
        status: [
          {
            required: true,
            message: "请选择选择处理方式",
            trigger: "blur",
          },
        ],
        tableArr: [
          {
            required: true,
            message: "请选择退款明细",
            trigger: "change",
          },
        ],
        remark: [
          {
            required: false,
            message: "请输入处理备注",
            trigger: "change",
          },
        ],
      }, //弹窗中-form表单-必填规则

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用

      mydisabled: false
    };
  },
  setup() {
    const dialogVisible = ref(false);
    const handleCloseSenior = (done) => {
      done();
    };

    return {
      dialogVisible,
      handleCloseSenior,
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/bond/refundList",
          {
            uid: this.pagesdatas.uid,
            status: this.pagesdatas.status,
            thirdparty: this.pagesdatas.thirdparty,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/bond/refundList",
          {
            uid: this.pagesdatas.uid,
            status: this.pagesdatas.status,
            thirdparty: this.pagesdatas.thirdparty,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 点击-处理按钮-获取处理相关信息
    doApplyBack(id) {
      axios
        .post(
          "/admin/bond/payList",
          {
            id: id,
          },
          {
            headers: { Authentication: this.mytoken },
          }
        )
        .then((response) => {
          console.log(response.data.result);
          this.dealArr = response.data.result;
          this.dealForm.id = id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 弹窗-处理-申请退保证金-确认按钮
    dealFormSubmmit(formName) {
      // 前端防抖
      this.mydisabled = true;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          var getdatas = this.$refs[formName].model;
          console.log(getdatas.tableArr.join(","));

          axios
            .post(
              "/admin/bond/dealWithRefund",
              {
                id: getdatas.id,
                status: getdatas.status,
                item: getdatas.tableArr.join(","),
                remark: getdatas.remark,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible1 = ref(false); //关闭弹窗
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    this.mydisabled = false;
                    this.reload(); //刷新
                  },
                });
              } else {
                this.mydisabled = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              this.mydisabled = false;
            });
        } else {
          console.log("error submit!!");
          this.mydisabled = false;
          return false;
        }
      });
    },
    resetDealDialog() {
      this.dealForm.id = "";
      this.dealForm.status = "";
      this.dealForm.tableArr = [];
      this.dealForm.remark = "";
    },
    //点击进行路由页面跳转以及携带参数
    //传uid
    routeraddrClick(routeraddr, rowdata) {
      // 携带参数跳转
      if (rowdata) {
        // 跳转-用户列表
        if (routeraddr == "/userlist") {
          this.$router
            .push({
              path: routeraddr,
              query: { userid: rowdata.uid, thirdparty: rowdata.thirdparty },
            })
            .catch((error) => error);
        }
        // 跳转-申请退保证金详情
        if (routeraddr == "/bondapplybackinfo") {
          this.$router
            .push({
              path: routeraddr,
              query: { id: rowdata.id },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/bond/refundList",
          {
            uid: this.pagesdatas.uid,
            status: this.pagesdatas.status,
            thirdparty: this.pagesdatas.thirdparty,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message)
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/bond/refundList",
          {
            uid: data.uid,
            status: data.status,
            thirdparty: data.thirdparty,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
        if (newdata[i].thirdparty) {
          newdata[i]["thirdpartyName"] = newdata[i].thirdparty
            .toString()
            .replace("1", "微信自助版")
            .replace("2", "支付宝自助版")
            .replace("3", "微信企业版")
            .replace("5", "微信家庭版")
            .replace("5", "支付宝企业版")
            .replace("6", "支付宝家庭版");
        }

        //转换申请时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无申请时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无申请时间";
        }
        //转换处理时间
        if (newdata[i].dealtime) {
          if (newdata[i].dealtime === 0 || newdata[i].dealtime === null) {
            newdata[i].dealtime = "无处理时间";
          } else {
            newdata[i].dealtime = this.$utils.formatDate(newdata[i].dealtime);
          }
        } else {
          newdata[i].dealtime = "无处理时间";
        }
        // 转换处理状态
        if (newdata[i].status) {
          if (newdata[i].status === -1) {
            newdata[i].deal_status = "待处理";
          } else if (newdata[i].status === 1) {
            newdata[i].deal_status = "已退款";
          } else {
            newdata[i].deal_status = "已拒绝";
          }
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/bond/refundList",
          {
            thirdparty: 1,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}

.checkbox-box {
  display: flex;
  flex-direction: row;
}

.checkbox-box div {
  margin-right: 10px;
}
</style>