<template>
	<div class="wrap">
		<el-drawer :title="ruleForm.id ? '编辑' : '添加' " v-model="dialogDisplay" @close="onCancel" direction="rtl" size="70%">
            <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="180px" style="padding: 0 20px 0 0">
				<el-form-item label="名称：" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="" maxlength="50"></el-input>
                </el-form-item>
				<el-form-item label="公众号：" prop="office_config_id">
					<el-select v-model="ruleForm.office_config_id" placeholder="请选择公众号" style="width: 100%;">
						<el-option v-for="item in officeOptions" :key="item.value" :label="item.label" :value="item.value" />
					</el-select>
                </el-form-item>
				<el-form-item label="状态：" prop="status">
                    <el-radio-group v-model="ruleForm.status">
                        <el-radio label="1">开启</el-radio>
                        <el-radio label="-1">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
				<div class="mimi-program">
					<div class="title">
						<span class="add" @click="addProgram">添加小程序</span>
						<span class="desc">（最多添加10个）</span>
					</div>
					<div class="program-box" v-for="(item, index) in ruleForm.content" :key="index">
						<div class="program-del" v-if="index != 0"  @click="deleteProgram(index)">X</div>
						<el-form-item label="小程序名称：" :prop="`content.${index}.appid`" :rules="rules.appid">
							<el-select v-model="item.appid" placeholder="请选择公众号" style="width: 100%;" @change="disabledProgram">
								<el-option v-for="item in programOptions" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled" />
							</el-select>
        		        </el-form-item>
						<el-form-item label="小程序页面路径：" :prop="`content.${index}.pagepath`" :rules="rules.pagepath">
		                    <el-input v-model="item.pagepath" placeholder="请输入小程序页面路径"></el-input>
        		        </el-form-item>
					</div>
				</div>
			</el-form>
			<div style="text-align: right">
				<el-button @click="onCancel">取消 </el-button>
				<el-button type="primary" @click="onSubmit">确定 </el-button>
			</div>
		</el-drawer>
	</div>
</template>

<script setup>
import { ref, reactive, watch, defineProps, defineEmits, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import http from '@/assets/js/http'

const dialogDisplay = ref(false)
const ruleFormRef = ref()
const ruleForm = ref({})
const emit = defineEmits(['success', 'cancel'])
const officeOptions = ref([])
const programOptions = ref([])

const props = defineProps({
	data: {
		type: Object,
		default: ()=> {
			return {
				data: {},
				api: '',
			}
		}
	},
	deviceDialog: {
		type: Boolean,
		default: ()=> {
			return false
		}
	}
})

const rules = reactive({
	name: [
		{
			required: true,
			message: '请输入名称',
			trigger: 'blur',
		},
	],
	office_config_id: [
		{
			required: true,
			message: '请选择公众号',
			trigger: 'change',
		},
	],
	status: [
		{
			required: true,
			message: '请输入名称',
			trigger: 'blur',
		},
	],
	appid: [
		{
			required: true,
			message: '请选择小程序',
			trigger: 'change',
		},
	],
	pagepath: [
		{
			required: true,
			message: '请输入小程序页面路径',
			trigger: 'blur',
		},
	],	
})

watch(props, async (newVal)=> {
	const { id } = newVal.data.data
	let res = {}

	if( id && newVal.deviceDialog ) res = await http.post('office/pushLinkView', { id })

	const { name, office_config_id, content, status } = res
	ruleForm.value = {
		id, 
		name,
		office_config_id,
		content: content ?  JSON.parse(content) : [ { appid: '', pagepath: '' } ],
		status: status ? String(status) : '1',
	}
	dialogDisplay.value = newVal.deviceDialog

	if(newVal.deviceDialog) {
		getOfficeConfig()
		getProgram()
	}
})

// 提交
const onSubmit = async ()=> {
	try {
		await ruleFormRef.value.validate()
	} catch (err) {
		return false
	}

	const { api } = props.data
	const params = ruleForm.value
	await http.post(api, params)
	
	emit('success')
}

// 取消
const onCancel = ()=> {
	emit('cancel')
}

// 添加小程序
const addProgram = ()=> {
	if(ruleForm.value.content.length >= 10) return ElMessage({ message: '最多添加10个', type: 'warning' })
	ruleForm.value.content.push({ appid: '', pagepath: '' })
}

// 删除小程序
const deleteProgram = async (index)=> {
	ruleForm.value.content.splice(index, 1)
	disabledProgram()
}

// 获取公众号
const getOfficeConfig = async ()=> {
	const data = await http.post('office/configIndex')
	officeOptions.value = data.map((item)=> {
		return { value: item.id, label: item.content.name }
	})
}

// 获取小程序
const getProgram = async ()=> {
	const data = await http.post('office/appIndex')
	programOptions.value = data.map((item)=> {
		return { value: String(item.id), label: item.name }
	})
	disabledProgram()
}

// 已经添加的小程序禁用
const disabledProgram = ()=> {
	let obj = {}
	ruleForm.value.content.forEach((item)=> obj[item.appid] = item)
	programOptions.value.forEach((item, index)=> {
		if(obj[item.value]) { 
			programOptions.value[index] = { ...item, disabled: true } 
		} else {
			programOptions.value[index] = { ...item, disabled: false } 	
		}
	})
}

</script>

<style lang="scss" scoped>

.wrap {

	:deep(.el-drawer) {
		overflow: auto;
	}
	.mimi-program {
		.title {
			margin-left: 60px;
			margin-bottom: 20px;
			.add {
				color: rgb(30, 111, 255);
				cursor: pointer;			
			}			
		}

		.program-box {
			position: relative;
			margin-bottom: 30px;
			padding: 30px 50px 0 0;
			border: 1px solid #ccc;
			border-radius: 10px;
			.program-del {
				position: absolute;
				top: -10px;
				right: -14px;
				padding: 2px 8px;
				border-radius: 50%;
				background: #ccc;
				color: #fff;
				cursor: pointer;
			} 
		}
	}
}

</style>