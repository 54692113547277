<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'before_discount_total_price' ||
                mytabletitle.prop === 'total_price' ||
                mytabletitle.prop === 'thirdparty'
              ">
                <!--平台来源-样式等渲染-->
                <div v-if="mytabletitle.prop === 'thirdparty'">
                  <div v-if="scope.row.trade_no.substr(0, 6) != 'ZSPLUS'">
                    <span>{{
                        scope.row.thirdparty == 1
                          ? "微信自助版"
                          : scope.row.thirdparty == 2
                            ? "支付宝自助版"
                            : ""
                    }}
                    </span>
                  </div>
                  <div v-else>
                    <span> 后台赠送 </span>
                  </div>
                </div>
                <!--实际金额-样式等渲染-->
                <div v-if="mytabletitle.prop === 'before_discount_total_price'">
                  <span>{{ scope.row.before_discount_total_price }}</span>
                </div>
                <!--实付金额-样式等渲染-->
                <div v-if="mytabletitle.prop === 'total_price'">
                  <span>{{ scope.row.total_price }}</span>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-退款-is_refund：1可以退款，-1表示不可退款-->
                <div v-if="scope.row.is_refund == 1">
                  <el-button type="primary" size="mini" @click="addTanClick(scope.row, (dialogVisible = true))">退款
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!--弹出框-退款-->
    <el-dialog v-model="dialogVisible" title="退款" :width="this.searchwidth" :before-close="handleCloseSenior">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px" class="demo-ruleForm">
        <div style="
              max-height: 450px;
              overflow: hidden;
              overflow-y: visible;
              margin: 20px 1% 0 0;
            ">
          <el-form-item label="退款金额:" prop="refundMoney">
            <el-input v-model="ruleForm.refundMoney" clearable min="0"></el-input>
            <div style="height: 20px;line-height:20px;">
              <span style="font-size: 12px; color: #999999; white-space: pre-wrap">(最多退款金额￥{{
                  this.ruleForm.maxrefundMoney
              }})</span>
            </div>
          </el-form-item>
          <el-form-item label="修改会员过期时间:" prop="end_time">
            <el-date-picker v-model="ruleForm.end_time" type="date" placeholder="请选择日期" style="width: 100%;" />
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="ruleForm.refundRemark" :rows="3" type="textarea" placeholder="请输入备注" />
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="resetForm('ruleForm')">取消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import axios from "axios";
import { defineComponent, ref } from "vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "vmemberplusbuy",
  inject: ["reload"], //刷新引用
  data() {
    var checkBigDay = (rule, value, callback) => {
      //获取key值为ide下的相关数据
      var getdatas = this.ruleForm;
      //校验判断
      if (!value) {
        return callback(new Error("退款金额不能为空！"));
      }
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("值中不能有空格"));
      }

      setTimeout(() => {
        // 判断字符串是否为文字
        if (isNaN(value)) {
          callback(new Error("请输入数字"));
        } else {
          let result = value.toString().indexOf(".");
          if (result != -1) {
            let getdata = value.toString().split(".");
            if (getdata[getdata.length - 1].length > 2) {
              callback(new Error("请保留小数点后两位"));
            }
          }

          if (value < 0) {
            callback(new Error("退款金额必须大于0"));
          } else {
            if (value > getdatas.maxrefundMoney) {
              callback(
                new Error(
                  "当前订单最多可退金额为：" + getdatas.maxrefundMoney + " 元"
                )
              );
            } else {
              callback();
            }
          }
        }
      }, 500);
    };

    return {
      mainonebread: [
        {
          name: "用户",
        },
        {
          name: "会员管理",
        },
        {
          name: "PLUS会员购买记录",
          path: "/memberplusbuy",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "订单编号/用户ID/手机号",
              name: "keywords",
              content: "",
            },
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "类型",
              name: "name",
              content: "",
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "thirdparty",
              value: "", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "", //设置默认值
              options: componentProperties.get('thirdpartyoption').optionsall,
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 60, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "trade_no",
          label: "订单编号",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "thirdparty",
          label: "平台来源",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "uid",
          label: "用户ID",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "mobile",
          label: "手机号",
          width: "130",
        },
        {
          fixedstatu: false,
          prop: "name",
          label: "类型",
          width: "70",
        },
        {
          fixedstatu: false,
          prop: "before_discount_total_price",
          label: "实际金额",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "couponid_name",
          label: "优惠",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "total_price",
          label: "实付金额",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "end_time",
          label: "VIP到期时间",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "discount",
          label: "打印折扣",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "购买时间",
          width: "180",
        },
        // {
        //   fixedstatu: false,
        //   prop: "total_price",
        //   label: "张数限制",
        //   width: "",
        // },
        // {
        //   fixedstatu: false,
        //   prop: "total_price",
        //   label: "已使用张数",
        //   width: "100",
        // },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "订单编号/用户ID/手机号",
            name: "keywords",
            content: "",
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "类型",
            name: "name",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "VIP到期时间区间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "end_time",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "购买时间区间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "addtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myselecttopisshow: true, //高级筛选-select单框-放input框之前
      myselecttop: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "平台来源：",
            placeholder: "全部",
            name: "thirdparty",
            value: "", //显示值，初始状况，显示值与默认值需相同
            valueisshow: true,
            valuedefault: "", //设置默认值
            options: componentProperties.get('thirdpartyoption').optionsall,
          },
        ],
      },

      searchwidth: "580px",
      //弹窗相关数据
      dialogVisible: ref(false), //弹窗显示&隐藏
      //弹窗中-form表单
      ruleForm: {
        id: "",
        maxrefundMoney: "",
        refundMoney: "",
        end_time: "",
        refundRemark: ""
      },
      //弹窗中-form表单-必填规则
      rules: {
        refundMoney: [
          {
            required: true,
            message: "退款金额为必填项",
            trigger: "blur",
          },
          {
            validator: checkBigDay,
            trigger: "blur",
          },
        ],
        end_time: [
          {
            required: true,
            message: "修改会员过期时间为必填项",
            trigger: "blur",
          },
        ],
      },

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/plusBuy", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: this.pagesdatas.keywords,
            name: this.pagesdatas.name,
            thirdparty: this.pagesdatas.thirdparty,
            end_time: this.pagesdatas.endtime,
            addtime: this.pagesdatas.addtime,
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/plusBuy", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: this.pagesdatas.keywords,
            name: this.pagesdatas.name,
            thirdparty: this.pagesdatas.thirdparty,
            end_time: this.pagesdatas.endtime,
            addtime: this.pagesdatas.addtime,
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //弹出框-提交表单[退款]
    submitForm(formName) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("提交：" + JSON.stringify(getdatas));
          axios
            .post(
              "admin/Member/plusRefund",
              {
                id: getdatas.id,
                refundMoney: getdatas.refundMoney,
                end_time: getdatas.end_time.valueOf() / 1000,
                refundRemark: getdatas.refundRemark,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = ref(false); //关闭弹窗
                ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-取消表单[退款]
    resetForm() {
      this.ruleForm = {
        id: "",
        maxrefundMoney: "",
        refundMoney: "",
        end_time: "",
        refundRemark: ""
      };
      this.dialogVisible = ref(false); //关闭弹窗
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置-[退款]
    addTanClick(taninfo) {
      this.ruleForm = {
        id: taninfo.id,
        maxrefundMoney: taninfo.total_price,
        refundMoney: "",
        end_time: "",
        refundRemark: ""
      }
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      this.$router.push(routeraddr).catch((error) => error);
    },
    //导出数据
    exportDatas() {
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/plusBuy", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: this.pagesdatas.keywords,
            name: this.pagesdatas.name,
            thirdparty: this.pagesdatas.thirdparty,
            end_time: this.pagesdatas.endtime,
            addtime: this.pagesdatas.addtime,
            export: 1,
          },
        })
        .then((response) => {
          console.log(response.data.message);
     
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      // 平台来源-自主维护字段，当为0是向后端传输空表示全部
      if (data.thirdparty == 0) {
        data.thirdparty = "";
      }
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-VIP到期时间-区间段状态
      var getendtime = null;
      if (data.end_time.start == "") {
        getendtime = "";
      } else {
        if (data.end_time.end == "") {
          getendtime =
            data.end_time.start + " ~ " + (data.end_time.start + 86400 - 1);
        } else {
          getendtime =
            data.end_time.start + " ~ " + (data.end_time.end + 86400 - 1);
        }
      }
      //判断-购买时间-区间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.end_time = getendtime;
      this.pagesdatas.addtime = getaddtime;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/plusBuy", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: data.keywords,
            name: data.name,
            thirdparty: data.thirdparty,
            end_time: getendtime,
            addtime: getaddtime,
            page: 1,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      // 平台来源-自主维护字段，当为0是向后端传输空表示全部
      if (data.thirdparty == 0) {
        data.thirdparty = "";
      }
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/plusBuy", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: data.keywords,
            name: data.name,
            thirdparty: data.thirdparty,
            page: 1,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      console.log(successdata);
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;

        //转换VIP到期时间
        if (newdata[i].end_time) {
          if (newdata[i].end_time === 0 || newdata[i].end_time === null) {
            newdata[i].end_time = "无VIP到期时间";
          } else {
            newdata[i].end_time = this.$utils.formatDate(newdata[i].end_time);
          }
        } else {
          newdata[i].end_time = "无VIP到期时间";
        }
        //转换购买时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无购买时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无购买时间";
        }
        // 折扣
        if (newdata[i].discount) {
          newdata[i].discount = newdata[i].discount + "折";
        }
        // 返利
        if (newdata[i].shop_gift) {
          newdata[i].shop_gift = newdata[i].shop_gift + "%";
        }
        if (newdata[i].user_gift) {
          newdata[i].user_gift = newdata[i].user_gift + "%";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      // 定义
      this.pagesdatas = {
        page: 1,
        page_size: this.PageSize,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      // 来源于路由的支付订单编号
      if (routerinfo.tradeno) {
        this.pagesdatas = {
          keywords: routerinfo.tradeno,
          page: 1,
          page_size: this.PageSize,
        };
        this.maintwoforminfo.myinputone.input[0].content = routerinfo.tradeno;
      }

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/plusBuy", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: this.pagesdatas,
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>
