<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <!-- tab-按钮 -->
    <div style="background-color: #ffffff; width:100%;margin-bottom: 10px;border-radius: 4px;min-height: 52px;">
      <div
        style="padding: 10px 20px; display: flex; flex-direction: row;flex-wrap: wrap;justify-content: left;align-items: center;">
        <div><span style="font-size: 14px;">表单名称：</span></div>
        <div>
          <el-button type="primary" size="small" v-for="(item, index) in getbuttondata" :key="index"
            @click="changeButtonEvent(item)">{{ item.name }}</el-button>
        </div>
      </div>
    </div>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表({{ this.changetablename }})</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'addtime'">
                <!--用户ID-样式等渲染-->
                <div v-if="mytabletitle.prop === 'addtime'">
                  <div>
                    <span>{{ scope.row.addtime != 0 ? this.$utils.formatDate(scope.row.addtime) : '' }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vformclue",
  data() {
    return {
      mainonebread: [
        {
          name: "运营",
        },
        {
          name: "营销玩法",
        },
        {
          name: "表单线索",
          path: "/formclue",
        },
      ], // 当前页-【面包屑导航】

      // 当前tab数组
      getbuttondata: [],

      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "姓名",
              name: "field1",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: "提交时间：",
              placeholder: {
                placeholderstart: "请选择开始时间",
                placeholderend: "请选择结束时间",
              },
              name: "addtime",
              value: {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
              },
              rangeseparator: "至", //分隔符
              selectmaxwidth: "100%", //时间段-最大宽度
              marginright: "0", //与右侧距离
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      // 选中的table-id
      nowtableid: '',

      // changetablename
      changetablename: '',

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 点击表单名称-触发事件
    changeButtonEvent(datas) {
      this.changetablename = datas.name
      this.nowtableid = datas.id;

      // 加载第一个input搜索框的提示名
      this.maintwoforminfo.myinputone.input[0].placeholder = JSON.parse(datas.fields)[0].name;
      // 加载table表单-title
      let gettitle = [];
      JSON.parse(datas.fields).forEach(element => {
        let data = {
          fixedstatu: false,
          prop: element.field,
          label: element.name,
          width: "",
        };
        gettitle.push(data)
      });
      // 重新赋值-table列表的title
      this.mainthreetabletitle = [
        {
          fixedstatu: false,
          prop: 'id',
          label: 'ID',
          width: "",
        },
        ...gettitle,
        {
          fixedstatu: false,
          prop: 'addtime',
          label: '提交时间',
          width: "",
        }]

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post("/admin/pages/formlist",
          {
            form_id: datas.id,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: { Authentication: this.mytoken },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post("/admin/pages/formlist",
          {
            form_id: this.nowtableid,
            field1: this.pagesdatas.field1,
            addtime: this.pagesdatas.addtime,
            page: 1,
            page_size: val,
          },
          {
            headers: { Authentication: this.mytoken },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post("/admin/pages/formlist",
          {
            form_id: this.nowtableid,
            field1: this.pagesdatas.field1,
            addtime: this.pagesdatas.addtime,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: { Authentication: this.mytoken },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //导出数据
    exportDatas() {
      //通过Ajax向后台获取数据
      axios
        .post("/admin/pages/formlist",
          {
            form_id: this.nowtableid,
            field1: this.pagesdatas.field1,
            addtime: this.pagesdatas.addtime,
            export: 1
          },
          {
            headers: { Authentication: this.mytoken },
          }
        )
        .then((response) => {
          console.log(response.data.message);
     
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-时间区间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime = data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime = data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.addtime = getaddtime;
      //通过Ajax向后台获取数据
      axios
        .post("/admin/pages/formlist",
          {
            form_id: this.nowtableid,
            field1: data.field1,
            addtime: getaddtime,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: { Authentication: this.mytoken },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;

      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      // 获取tab
      axios
        .post("/admin/pages/formTab", {},
          {
            headers: { Authentication: this.mytoken },
          }
        )
        .then((response) => {
          let getdatas = response.data.result;
          this.getbuttondata = getdatas;

          if (getdatas.length > 0) {
            this.changeButtonEvent(getdatas[0])
          }

        })
        .catch(function (error) {
          console.log(error);
        });

    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>
<style scoped>
.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>