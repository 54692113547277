<template>
    <div>
        <el-form ref="formRef" :model="form" :rules="rules" label-width="120px" :disabled="flag == 2 ? true : false">
            <el-form-item label="会员价格：">
                <span>{{ this.fenToYuanEvent(this.form.old_price) }}</span>
            </el-form-item>
            <el-form-item label="实付金额：">
                <span>{{ this.fenToYuanEvent(this.form.price) }}</span>
            </el-form-item>
            <el-form-item label="扣除明细：">
                <div class="refund-box">
                    <div>
                        <el-form-item label="打印扣除金额：" prop="print_out" label-width="160px">
                            <div class="refund-box-item">
                                <div class="refund-box-item">
                                    <div><el-input v-model="form.print_out" clearable maxlength="50" @input="inputEvent" /></div>
                                    <div><span>元</span></div>
                                </div>
                                <div>
                                    <span>已使用 {{ this.form.print_num }} 张（打印费优惠 {{ this.fenToYuanEvent(this.form.print_money) }} 元）</span>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="教育资源扣除金额：" prop="document_out" label-width="160px">
                            <div class="refund-box-item">
                                <div class="refund-box-item">
                                    <div><el-input v-model="form.document_out" clearable maxlength="50" @input="inputEvent" /></div>
                                    <div><span>元</span></div>
                                </div>
                                <div>
                                    <span>已使用文档{{ this.form.document_num }} 份</span>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="赠品扣除金额：" prop="gift_out" label-width="160px">
                            <div class="refund-box-item">
                                <div class="refund-box-item">
                                    <div><el-input v-model="form.gift_out" clearable maxlength="50" @input="inputEvent" /></div>
                                    <div><span>元</span></div>
                                </div>
                                <div>
                                    <el-form-item prop="gift_status" label-width="0px">
                                        <el-radio-group v-model="form.gift_status">
                                            <el-radio label="1">已退还</el-radio>
                                            <el-radio label="-1">未退还</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="商家券扣除金额：" prop="coupon_out" label-width="160px">
                            <div class="refund-box-item">
                                <div><el-input v-model="form.coupon_out" clearable maxlength="50" @input="inputEvent" /></div>
                                <div><span>元</span></div>
                            </div>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="其他扣除金额：" prop="other" label-width="160px">
                            <div class="refund-box-item">
                                <div><el-input v-model="form.other" clearable maxlength="50" @input="inputEvent" /></div>
                                <div><span>元</span></div>
                            </div>
                        </el-form-item>
                    </div>
                </div>
            </el-form-item>
            <el-divider />
            <el-form-item label="退款金额：">
                <div class="refund-box-item">
                    <div>{{ this.fenToYuanEvent(this.form.refund_money) }}</div>
                    <div><span>元</span></div>
                </div>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
                <el-input v-model="form.remark" type="textarea" clearable maxlength="500" show-word-limit />
            </el-form-item>
            <el-form-item>
                <el-button style="width: 200px" type="primary" @click="refundEvent('formRef')" :disabled="disabled">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'vmemberSet',
    inject: ['reload'], //刷新引用
    props: {
        drawerDatas: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        drawerDatas: {
            handler(newVal) {
                this.drawer_datas = newVal.value
                this.flag = this.drawer_datas.flag
                this.form.vipid = this.drawer_datas.data.id
                this.getRefundInfoEvent(this.form)
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            flag: '',
            form: {
                vipid: '',
                print_out: '',
                document_out: '',
                gift_out: '',
                gift_status: '',
                coupon_out: '',
                other: '',
                refund_money: '',
                remark: '',
                old_price: '',
                price: '',
                print_num: '',
                print_money: '',
                document_num: ''
            },
            rules: {
                print_out: [
                    {
                        required: true,
                        message: '打印扣除金额为必填项',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '大于等于0的正数或保留两位小数的小数',
                        trigger: ['blur', 'change']
                    }
                ],
                document_out: [
                    {
                        required: true,
                        message: '教育资源扣除金额必填项',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '大于等于0的正数或保留两位小数的小数',
                        trigger: ['blur', 'change']
                    }
                ],
                gift_out: [
                    {
                        required: true,
                        message: '赠品扣除金额必填项',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '大于等于0的正数或保留两位小数的小数',
                        trigger: ['blur', 'change']
                    }
                ],
                gift_status: [
                    {
                        required: true,
                        message: '必选项不能为空',
                        trigger: 'blur'
                    }
                ],
                coupon_out: [
                    {
                        required: true,
                        message: '商家券扣除金额必填项',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '大于等于0的正数或保留两位小数的小数',
                        trigger: ['blur', 'change']
                    }
                ],
                other: [
                    {
                        required: true,
                        message: '其他扣除金额必填项',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '大于等于0的正数或保留两位小数的小数',
                        trigger: ['blur', 'change']
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '备注必填项',
                        trigger: 'blur'
                    }
                ]
            },
            disabled: false, // 前端防抖
            drawer_datas: {},
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')) //获取权限
        }
    },
    methods: {
        // 填写扣除明细
        inputEvent() {
            // 当前value是元，需转换成分计算
            let print_out = this.yuanToFenEvent(this.form.print_out),
                document_out = this.yuanToFenEvent(this.form.document_out),
                gift_out = this.yuanToFenEvent(this.form.gift_out),
                coupon_out = this.yuanToFenEvent(this.form.coupon_out),
                other = this.yuanToFenEvent(this.form.other)

            // 计算退款金额-用分计算
            let refund_money = this.form.price - (print_out + document_out + gift_out + coupon_out + other)
            // 当退款金额小于0时，同一赋值为0
            this.form.refund_money = refund_money < 0 ? 0 : refund_money
        },
        // 分转元
        fenToYuanEvent(value) {
            return (value / 100).toFixed(2)
        },
        // 元转分
        yuanToFenEvent(value) {
            return value ? parseInt(value * 100) : 0
        },
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 退款
        refundEvent(formName) {
            var getdatas = this.deepcopy(this.$refs[formName].model)

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // input填写的数字 - 元转分
                    getdatas.print_out = this.yuanToFenEvent(this.form.print_out)
                    getdatas.document_out = this.yuanToFenEvent(this.form.document_out)
                    getdatas.gift_out = this.yuanToFenEvent(this.form.gift_out)
                    getdatas.coupon_out = this.yuanToFenEvent(this.form.coupon_out)
                    getdatas.other = this.yuanToFenEvent(this.form.other)

                    // 删除对象中多余的元素：
                    let del_model = ['old_price', 'price', 'print_num', 'print_money', 'document_num']
                    del_model.map((n) => {
                        delete getdatas[n]
                    })

                    this.disabled = true
                    axios
                        .post('/admin/vip/refund', getdatas, {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                // this.dialogVisible = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.disabled = false
                                        this.$emit('drawerStatus', false)
                                    }
                                })
                            } else {
                                this.disabled = false
                            }
                        })
                        .catch(function () {
                            this.disabled = false
                        })
                } else {
                    return false
                }
            })
        },
        // 获取详情信息
        getRefundInfoEvent(datas) {
            axios
                .get('/admin/vip/refundDetail', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        vipid: datas.vipid
                    }
                })
                .then((response) => {
                    if (response.data.code === 0) {
                        let getdatas = response.data.result
                        this.form = {
                            vipid: datas.vipid,
                            old_price: getdatas.old_price,
                            price: getdatas.price,
                            print_num: getdatas.print_num,
                            print_money: getdatas.print_money,
                            document_num: getdatas.document_num,
                            print_out: getdatas.print_out || getdatas.print_out == 0 ? this.fenToYuanEvent(getdatas.print_out) : this.fenToYuanEvent(getdatas.print_money),
                            document_out: getdatas.document_out || getdatas.document_out == 0 ? this.fenToYuanEvent(getdatas.document_out) : '',
                            gift_out: getdatas.gift_out || getdatas.gift_out == 0 ? this.fenToYuanEvent(getdatas.gift_out) : '',
                            gift_status: getdatas.gift_status ? getdatas.gift_status.toString() : '-1',
                            coupon_out: getdatas.coupon_out || getdatas.coupon_out == 0 ? this.fenToYuanEvent(getdatas.coupon_out) : '',
                            other: getdatas.other || getdatas.other == 0 ? this.fenToYuanEvent(getdatas.other) : '',
                            refund_money: getdatas.refund_money ? getdatas.refund_money : 0,
                            remark: getdatas.remark
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {},
    components: {}
})
</script>

<style scoped>
.refund-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.refund-box-item {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
    gap: 20px;
}
</style>
