<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box"  >
                <div class="box_title"  >
                    <div class="box_title_font">添加代理商</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :md="2" :lg="3" :xl="3">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :md="20" :lg="18" :xl="15">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
                                <!--姓名-->
                                <el-form-item label="姓名：" prop="name">
                                    <el-input v-model="ruleForm.name" clearable></el-input>
                                </el-form-item>
                                <!--手机号-->
                                <el-form-item label="手机号：" prop="username">
                                    <el-input v-model="ruleForm.username" placeholder="用于登录系统用户名" maxlength="13" clearable></el-input>
                                </el-form-item>
                                <!-- 身份证号码 -->
                                <el-form-item label="身份证号码：" prop="idcard_no">
                                    <el-input v-model="ruleForm.idcard_no" placeholder="请输入身份证号" maxlength="18" clearable></el-input>
                                </el-form-item>
                                <!-- 身份证正面照 -->
                                <el-form-item label="身份证正面照：" prop="idcard_pic_front">
                                    <mainimgesup
                                        ref="Refmyimges_on"
                                        v-model="ruleForm.idcard_pic_front"
                                        :myimgesup="myimgesup_on"
                                        :myimgesupisshow="myimgesupisshow_on"
                                        :filelist="ruleForm.idcard_pic_front_list"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data, 1)"
                                        v-on:deluploadfile="(data) => deluploadfileEvent(data, 1)"
                                        v-on:getfiletype="getfiletypeEvent"
                                    >
                                    </mainimgesup>
                                </el-form-item>
                                <!-- 身份证反面照 -->
                                <el-form-item label="身份证反面照：" prop="idcard_pic_back">
                                    <mainimgesup
                                        ref="Refmyimges_off"
                                        v-model="ruleForm.idcard_pic_back"
                                        :myimgesup="myimgesup_off"
                                        :myimgesupisshow="myimgesupisshow_off"
                                        :filelist="ruleForm.idcard_pic_back_list"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data, 2)"
                                        v-on:deluploadfile="(data) => deluploadfileEvent(data, 2)"
                                        v-on:getfiletype="getfiletypeEvent"
                                    >
                                    </mainimgesup>
                                </el-form-item>
                                <!--代理商是否分成-->
                                <el-form-item label="代理商是否分成：" prop="is_ratio">
                                    <el-radio-group v-model="ruleForm.is_ratio" @change="changeRadioEvent">
                                        <el-radio label="1">分成</el-radio>
                                        <!-- <el-radio label="-1">仅商户，代理商不分成</el-radio> -->
                                        <el-radio label="2">禁用(该等级分成归公司所有)</el-radio>
                                        <el-radio label="3">禁用(所有等级分成都归公司所有)</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!-- 类型 -->
                                <el-form-item label="类型：" prop="user_type">
                                    <el-radio-group v-model="ruleForm.user_type" @change="changeRadioTypeEvent">
                                        <el-radio label="1">代理</el-radio>
                                        <el-radio label="2">直营</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!-- 负责人 -->
                                <el-form-item label="负责人：" prop="win_user_id">
                                    <treeCascaderBox
                                        :ModelValue="ruleForm.win_user_id"
                                        :Placeholder="'请选择负责人'"
                                        :Options="pid_options"
                                        :Props="pid_options_props"
                                        @changedatas="changedatasEvent"
                                    />
                                </el-form-item>
                                <!--默认佣金比例-->
                                <el-form-item label="默认佣金比例：" required="true">
                                    <el-form-item prop="ratio">
                                        <el-input v-model="ruleForm.ratio" clearable step="0.1" min="0" max="1" maxlength="5"> </el-input>
                                        <el-form-item prop="ratio_remarks">
                                            <div style="margin-top: 10px">
                                                <el-input v-model="ruleForm.ratio_remarks" :rows="2" type="textarea" placeholder="佣金比例备注"> </el-input>
                                            </div>
                                        </el-form-item>
                                    </el-form-item>
                                </el-form-item>
                                <!-- 流量变现佣金比例 -->
                                <!-- <el-form-item label="流量变现佣金比例：" required="true">
                                        <el-form-item prop="flow_ratio">
                                            <el-input
                                            v-model="ruleForm.flow_ratio"
                                            clearable
                                            step="0.1"
                                            min="0"
                                            max="1"
                                            maxlength="5"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-form-item> -->
                                <!--销售人员/渠道经理 style="width: 47%; margin-right: 6%" prop="sales_people"-->
                                <el-form-item label="销售人员/渠道经理：" prop="sales_people">
                                    <el-select v-model="ruleForm.sales_people" placeholder="请选择" style="width: 100%" @visible-change="finduserSaleList('1')">
                                        <el-option :label="getsales.truename" :value="getsales.id" v-for="(getsales, i) in sales_people_datas" :key="i"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!--售后人员  -->
                                <el-form-item label="售后人员：" prop="after_people">
                                    <el-select v-model="ruleForm.after_people" placeholder="请选择" style="width: 100%" @visible-change="finduserSaleList('2')">
                                        <el-option :label="getafter.truename" :value="getafter.id" v-for="(getafter, j) in after_people_datas" :key="j"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!--选择地区-->
                                <el-form-item label="选择地区：" prop="area_code">
                                    <mainselect
                                        ref="citycode"
                                        v-model="ruleForm.area_code"
                                        :myselectcityleftisshow="myselectcityisshow"
                                        :myselectcity="myselectcity"
                                        v-on:getcitycode="getCityCodeEvent"
                                    ></mainselect>
                                </el-form-item>
                                <!--价格参数修改权限-->
                                <el-form-item label="价格参数修改权限：" prop="is_save_attr">
                                    <el-radio-group v-model="ruleForm.is_save_attr">
                                        <el-radio label="-1">无</el-radio>
                                        <el-radio label="1">有</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--是否自动提现-->
                                <el-form-item label="是否自动提现：" required="true">
                                    <div class="draw-box">
                                        <div class="draw-box-radio">
                                            <el-form-item prop="is_forthwith">
                                                <el-radio-group v-model="ruleForm.is_forthwith">
                                                    <el-radio label="1">是</el-radio>
                                                    <el-radio label="-1">否</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                        </div>
                                        <div class="draw-box-input" v-if="ruleForm.is_forthwith == -1">
                                            <el-form-item prop="month_draw_times">
                                                <div style="margin: 0 10px 10px 0">
                                                    <el-input v-model="ruleForm.month_draw_times" clearable placeholder="每月手动提现次数"></el-input>
                                                </div>
                                            </el-form-item>
                                            <el-form-item prop="month_draw_minmoney">
                                                <div>
                                                    <el-input v-model="ruleForm.month_draw_minmoney" clearable placeholder="每次最少提现金额限制"> </el-input>
                                                </div>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </el-form-item>
                                <!--机器广告图修改权限-->
                                <el-form-item label="机器广告图修改权限：" prop="is_save_adv">
                                    <el-radio-group v-model="ruleForm.is_save_adv">
                                        <el-radio label="-1">无</el-radio>
                                        <el-radio label="1">有</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--订单退款权限-->
                                <el-form-item label="订单退款权限：" prop="refund_audit">
                                    <el-radio-group v-model="ruleForm.refund_audit">
                                        <el-radio label="-1">无</el-radio>
                                        <el-radio label="1">有</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--提现微信-->
                                <el-form-item label="提现微信：" prop="wechat_user_id">
                                    <mainwechat
                                        ref="wechat"
                                        v-model="ruleForm.wechat_user_id"
                                        :mycashoutisshow="mycashoutisshow"
                                        :mycashout="mycashout"
                                        v-on:getpayaccount="(data) => getzhanghuCodeEvent(data, 'wechat')"
                                        v-on:getchangepaytype="getChangePaytypeEvent"
                                        v-on:getisshow="changeGetisshowEvent"
                                    ></mainwechat>
                                </el-form-item>
                                <!--提现支付宝-->
                                <el-form-item label="提现支付宝：" prop="alipay_user_id">
                                    <mainwechat
                                        ref="paychat"
                                        v-model="ruleForm.alipay_user_id"
                                        :mycashoutisshow="mycashoutisshow"
                                        :mycashout="mycashout1"
                                        v-on:getpayaccount="(data) => getzhanghuCodeEvent(data, 'paychat')"
                                        v-on:getchangepaytype="getChangePaytypeEvent"
                                        v-on:getisshow="changeGetisshowEvent"
                                    ></mainwechat>
                                </el-form-item>
                                <!--状态-->
                                <el-form-item label="状态：" prop="status">
                                    <el-radio-group v-model="ruleForm.status">
                                        <el-radio label="1">启用</el-radio>
                                        <el-radio label="-1">禁用</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--button按钮-->
                                <el-form-item style="margin-top: 50px">
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%" :disabled="mydisabled">提交 </el-button>
                                    <el-button @click="resetForm()">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :md="2" :lg="3" :xl="6">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import mainselect from '@/components/Mainselect.vue'
import mainwechat from '@/components/Mainwechat.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ElMessageBox } from 'element-plus'
import { ref } from 'vue'
import axios from 'axios'

export default {
    name: 'vagentlistadd',
    data() {
        // 文本input框-校验（文本-值中不能有空格）【手机号】
        var checkPhone = (rule, value, callback) => {
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('手机号中不能有空格'))
            }
            setTimeout(() => {
                callback()
            }, 500)
        }
        // 默认佣金比例-校验
        var checkRatio = (rule, value, callback) => {
            // console.log(rule, value);
            if (!value) {
                return callback(new Error('默认佣金比例不能为空'))
            }
            setTimeout(() => {
                // 当输入为浮点型的判断
                if (!Number.parseFloat(value)) {
                    callback(new Error('请输入小数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入数字'))
                    } else {
                        if (value > 1) {
                            callback(new Error('默认佣金比例值需小于等于1'))
                        } else {
                            if (value < 0) {
                                callback(new Error('默认佣金比例值需大于等于0'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }
        // 流量变现佣金比例-校验
        var checkFlowratio = (rule, value, callback) => {
            // console.log(rule, value);
            if (!value) {
                return callback(new Error('流量变现佣金比例不能为空'))
            }
            setTimeout(() => {
                // 当输入为浮点型的判断
                if (!Number.parseFloat(value)) {
                    callback(new Error('请输入小数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入数字'))
                    } else {
                        if (value > 1) {
                            callback(new Error('默认佣金比例值需小于等于1'))
                        } else {
                            if (value < 0) {
                                callback(new Error('默认佣金比例值需大于等于0'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '合作伙伴'
                },
                {
                    name: '代理商管理'
                },
                {
                    name: '代理商列表',
                    path: '/agentlist'
                },
                {
                    name: '添加代理商',
                    path: '/agentlistadd'
                }
            ], // 当前页-【面包屑导航】
            myselectcityisshow: true, //form表单-selectcity选框
            myselectcity: {
                selectwidth: '100%',
                maxwidth: '100%',
                marginright: '0',
                select: {
                    ismust: true,
                    name: 'area_code',
                    valuesheng: '',
                    valueshi: '',
                    valuequ: '',
                    disshi: true,
                    disqu: true
                }
            },
            mycashoutisshow: true, //form表单-提现微信/支付宝（单个组件）
            mycashout: {
                ide: 'wechat', // 必填标识：微信（'wechat'）,支付宝（'alipay'）
                titlewidth: '180px',
                cashout: [
                    {
                        title: '提现微信：',
                        ismust: true, //是否显示必填红标
                        isshowedit: false, //控制-首次进入页面时-编辑用户信息模块
                        isshowadd: true, //控制-首次进入页面时-添加提现信息的模块
                        avatarinfo: {
                            id: 1,
                            avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
                            nickname: '用户李四',
                            mobile: '18738573256'
                        }, //显示-首次进入页面时-提现用户信息
                        tantitle: '请选择提现微信', //弹窗-title名称
                        tantable: {
                            mainnick: '微信昵称',
                            searchpder: '请输入 微信昵称 / 手机号 查询'
                        }, //弹窗-table中的需改变的中文字段
                        addtitle: '添加提现微信', //添加-按钮的中文
                        addcolor: '#04BE02', //添加-按钮的颜色
                        paytype: 0 //默认提现方式 - 0表示无，1微信，2支付宝
                    }
                ]
            }, //提现微信
            mycashout1: {
                ide: 'alipay',
                titlewidth: '180px',
                cashout: [
                    {
                        title: '提现支付宝：',
                        ismust: true, //是否显示必填红标
                        isshowedit: false, //控制-首次进入页面时-编辑用户信息模块
                        isshowadd: true, //控制-首次进入页面时-添加提现信息的模块
                        avatarinfo: {
                            id: 1,
                            avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
                            nickname: '用户李四',
                            mobile: '18738573256'
                        }, //显示-首次进入页面时-提现用户信息
                        tantitle: '请选择提现支付宝', //弹窗-title名称
                        tantable: {
                            mainnick: '支付宝昵称',
                            searchpder: '请输入 支付宝昵称 / 手机号 查询'
                        }, //弹窗-table中的需改变的中文字段
                        addtitle: '添加提现支付宝', //添加-按钮的中文
                        addcolor: '#0571dc', //添加-按钮的颜色
                        paytype: 0 //默认提现方式 - 0表示无，1微信，2支付宝
                    }
                ]
            }, //提现支付宝

            //上传身份证-正面-imgesuploade上传图片
            myimgesupisshow_on: true,
            // 上传身份证-正面-上传图片
            myimgesup_on: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '上传身份证（正面）', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },

            //上传身份证-正面-imgesuploade上传图片
            myimgesupisshow_off: true,
            // 上传身份证-正面-上传图片
            myimgesup_off: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/api/admin/upload/openFile', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '上传身份证（反面）', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '', //提示&建议文字 建议尺寸46px*46px
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 10000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'] //文件格式
                    }
                }
            },

            // 销售&售后下拉框数据
            sales_people_datas: [],
            after_people_datas: [],

            //form表单数据
            ruleForm: {
                name: '',
                username: '',
                ratio: '',
                ratio_remarks: '',
                flow_ratio: '',
                area_code: '',
                sales_people: '',
                after_people: '',
                wechat_user_id: '',
                alipay_user_id: '',
                paytype: 0, //默认提现方式 1微信，2支付宝
                is_forthwith: ref('1'),
                month_draw_times: '',
                month_draw_minmoney: '',
                is_save_attr: ref('-1'),
                is_save_adv: ref('-1'),
                is_ratio: ref('1'),
                refund_audit: ref('-1'),
                status: ref('1'),
                user_type: '1',
                win_user_id: '',
                idcard_no: '',
                idcard_pic_front: '',
                idcard_pic_front_list: [],
                idcard_pic_back: '',
                idcard_pic_back_list: []
            },
            //form表单填写规则-前端判断是否必填
            rules: {
                name: [
                    {
                        required: true,
                        message: '代理商姓名为必填项',
                        trigger: 'blur'
                    }
                ],
                username: [
                    {
                        required: true,
                        message: '手机号/账号为必填项',
                        trigger: 'blur'
                    },
                    {
                        validator: checkPhone,
                        trigger: 'change'
                    }
                ],
                ratio: [
                    {
                        validator: checkRatio,
                        trigger: 'blur'
                    }
                ],
                flow_ratio: [
                    {
                        validator: checkFlowratio,
                        trigger: 'blur'
                    }
                ],
                area_code: [
                    {
                        required: true,
                        message: '地区为必填项',
                        trigger: 'blur'
                    }
                ],
                wechat_user_id: [
                    {
                        required: false,
                        message: '提现微信必填项',
                        trigger: 'blur'
                    }
                ],
                alipay_user_id: [
                    {
                        required: false,
                        message: '提现支付宝必填项',
                        trigger: 'blur'
                    }
                ],
                sales_people: [
                    {
                        required: true,
                        message: '请选择销售人员/渠道经理',
                        trigger: 'change'
                    }
                ],
                after_people: [
                    {
                        required: true,
                        message: '请选择售后人员',
                        trigger: 'change'
                    }
                ],
                is_forthwith: [
                    {
                        required: true,
                        message: '是否自动提现为必填',
                        trigger: 'change'
                    }
                ],
                month_draw_times: [
                    {
                        required: true,
                        message: '每月手动提现次数为必填',
                        trigger: 'change'
                    }
                ],
                month_draw_minmoney: [
                    {
                        required: true,
                        message: '每次最少提现金额限制为必填',
                        trigger: 'change'
                    }
                ],
                is_save_attr: [
                    {
                        required: true,
                        message: '价格参数修改权限为必填',
                        trigger: 'change'
                    }
                ],
                is_save_adv: [
                    {
                        required: true,
                        message: '机器广告图修改权限为必填',
                        trigger: 'change'
                    }
                ],
                is_ratio: [
                    {
                        required: true,
                        message: '代理商分成为必填',
                        trigger: 'change'
                    }
                ],
                refund_audit: [
                    {
                        required: true,
                        message: '订单退款权限为必填',
                        trigger: 'change'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态为必填',
                        trigger: 'change'
                    }
                ],
                user_type: [
                    {
                        required: true,
                        message: '类型为必填',
                        trigger: 'change'
                    }
                ],
                win_user_id: [
                    {
                        required: false,
                        message: '负责人为必填',
                        trigger: 'change'
                    }
                ],
                idcard_no: [
                    {
                        required: false,
                        message: '身份证为必填',
                        trigger: 'change'
                    }
                ],
                idcard_pic_front: [
                    {
                        required: false,
                        message: '身份证正面照为必填',
                        trigger: 'change'
                    }
                ],
                idcard_pic_back: [
                    {
                        required: false,
                        message: '身份证反面照为必填',
                        trigger: 'change'
                    }
                ]
            },
            // 代理商是否分成-维护字段
            isratio_history: ref('1'),

            // 负责人相关
            pid_options_props: { value: 'id', label: 'truename', children: 'child', checkStrictly: true },
            pid_options: [], // 负责人id下拉

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            mydisabled: false
        }
    },
    mounted() {},
    methods: {
        // 【身份证】-获取upload子组件回传的数据
        getUploadFileEvent(data, flag) {
            if (flag == 1) {
                this.ruleForm.idcard_pic_front = data.url
                this.ruleForm.idcard_pic_front_list = [
                    {
                        name: data.name,
                        url: data.url
                    }
                ]
            } else {
                this.ruleForm.idcard_pic_back = data.url
                this.ruleForm.idcard_pic_back_list = [
                    {
                        name: data.name,
                        url: data.url
                    }
                ]
            }
        },
        //【身份证】-删除上传的文件-事件
        deluploadfileEvent(data, flag) {
            if (flag == 1) {
                this.ruleForm.idcard_pic_front = ''
                this.ruleForm.idcard_pic_front_list = data
            } else {
                this.ruleForm.idcard_pic_back = ''
                this.ruleForm.idcard_pic_back_list = data
            }
        },
        // 【类型】单选
        changeRadioTypeEvent(data) {
            if (data == 1) {
                this.rules.win_user_id[0].required = false
                // this.rules.idcard_no[0].required = false
                // this.rules.idcard_pic_front[0].required = false
                // this.rules.idcard_pic_back[0].required = false

                this.rules.sales_people[0].required = true
                this.rules.after_people[0].required = true
            } else {
                this.rules.win_user_id[0].required = true
                // this.rules.idcard_no[0].required = true
                // this.rules.idcard_pic_front[0].required = true
                // this.rules.idcard_pic_back[0].required = true

                this.rules.sales_people[0].required = false
                this.rules.after_people[0].required = false
            }
        },
        //【负责人】选择
        changedatasEvent(data) {
            console.log(data)
            this.ruleForm.win_user_id = data
        },
        // 【批量修改负责人】获取下拉
        finduserPidOptions() {
            axios
                .post(
                    '/admin/winUser/list',
                    {},
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.pid_options = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        // 代理商是否分成-单选
        changeRadioEvent(val) {
            // 单选为[仅商户，代理商不分成]时
            // if (val == -1) {
            //   console.log(val);

            //   ElMessageBox.confirm(
            //     "设置成代理商不分成时，该代理商下所有设备，仅商户可分成，是否确定修改？",
            //     "提示：",
            //     {
            //       confirmButtonText: "确定",
            //       cancelButtonText: "取消",
            //       type: "primary",
            //     }
            //   )
            //     .then(() => {
            //       this.isratio_history = ref("-1");
            //       // this.ruleForm.ratio = 1;
            //     })
            //     .catch(() => {
            //       ElMessage({
            //         type: "info",
            //         message: "已取消",
            //       });
            //       this.ruleForm.is_ratio = this.isratio_history;
            //     });
            // }
            if (val == 1) {
                this.isratio_history = ref('1')
            }
            if (val == 2) {
                this.isratio_history = ref('2')
            }
            if (val == 3) {
                this.isratio_history = ref('3')
            }
        },

        //添加/删除后切换默认提现方式
        getChangePaytypeEvent(val) {
            // console.log("回传的值：" + val);
            if (this.mycashout.cashout[0].isshowadd == true && this.mycashout1.cashout[0].isshowadd == true) {
                this.ruleForm.paytype = 0
                this.mycashout.cashout[0].paytype = 0
                this.mycashout1.cashout[0].paytype = 0
            } else {
                this.ruleForm.paytype = val
                this.mycashout.cashout[0].paytype = val
                this.mycashout1.cashout[0].paytype = val
            }
            // console.log("之后：" + this.ruleForm.paytype);
        },
        //判断默认提现方式是否都为添加状态
        changeGetisshowEvent(val) {
            console.log('changeGetisshowEvent:' + val)
            if (val.ide == 'wechat') {
                this.mycashout.cashout[0].isshowedit = false
                this.mycashout.cashout[0].isshowadd = true
            } else {
                this.mycashout1.cashout[0].isshowedit = false
                this.mycashout1.cashout[0].isshowadd = true
            }
        },
        //获取公共组件回传的值-citycode
        getCityCodeEvent(data) {
            console.log('当前获取的citycode值为：' + data)
            this.ruleForm.area_code = data
        },
        //获取支付账号公共组件
        getzhanghuCodeEvent(data, payaccount) {
            if (payaccount == 'wechat') {
                console.log('获取返回的（微信）数据：' + JSON.stringify(data))
                if (data) {
                    this.ruleForm.wechat_user_id = data.id
                } else {
                    this.ruleForm.wechat_user_id = 0
                }
            } else {
                console.log('获取返回的（支付宝）数据：' + JSON.stringify(data))
                if (data) {
                    this.ruleForm.alipay_user_id = data.id
                } else {
                    this.ruleForm.alipay_user_id = 0
                }
            }
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            // 前端防抖
            this.mydisabled = true

            // console.log(
            //   "当前获取的form表单值：" + JSON.stringify(this.$refs[formName].model)
            // );
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model

                    axios
                        .post(
                            '/admin/shopUser/create',
                            {
                                name: getdatas.name,
                                username: getdatas.username,
                                ratio: getdatas.ratio,
                                ratio_remark: getdatas.ratio_remarks,
                                // flow_ratio: getdatas.flow_ratio,
                                area_code: getdatas.area_code,
                                sales_people: getdatas.sales_people,
                                after_people: getdatas.after_people,
                                wechat_user_id: getdatas.wechat_user_id,
                                alipay_user_id: getdatas.alipay_user_id,
                                is_forthwith: getdatas.is_forthwith,
                                month_draw_times: getdatas.month_draw_times,
                                month_draw_minmoney: getdatas.month_draw_minmoney,
                                is_save_attr: getdatas.is_save_attr,
                                is_save_adv: getdatas.is_save_adv,
                                status: getdatas.status,
                                is_ratio: getdatas.is_ratio,
                                refund_audit: getdatas.refund_audit,
                                default_pay: getdatas.paytype,
                                user_type: getdatas.user_type,
                                win_user_id: getdatas.win_user_id != null && getdatas.win_user_id != '' ? [...getdatas.win_user_id].pop() : '',
                                idcard_no: getdatas.idcard_no,
                                idcard_pic_front: getdatas.idcard_pic_front,
                                idcard_pic_back: getdatas.idcard_pic_back
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessageBox.alert(response.data.result, response.data.message, {
                                    confirmButtonText: '确定',
                                    callback: () => {
                                        // 成功之后跳转到代理商列表页面
                                        this.$router
                                            .push({
                                                path: '/agentlist',
                                                query: { agentname: getdatas.username }
                                            })
                                            .catch((error) => error)
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm() {
            this.$router.go(-1) // 返回上一页
            // this.$refs[formName].resetFields();
            // // 清空citycode
            // this.$refs.citycode.resetCitycode();
            // // 清空提现微信/支付宝[仅限于一个微信，一个支付宝（待优化）]
            // this.$refs.wechat.delWechatClient(0);
            // this.$refs.paychat.delWechatClient(0);
        },
        //通过API配置获取-销售/售后数据列表-用于下拉框
        finduserSaleList(depttype) {
            //通过API配置获取-销售/售后数据列表-用于下拉框
            axios
                .get('/admin/Admin/userSaleList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        dept_type: depttype
                    }
                })
                .then((response) => {
                    if (depttype === '1') {
                        // 由API判断，1销售部门， 2售后部门
                        this.sales_people_datas = response.data.result.data
                    } else {
                        this.after_people_datas = response.data.result.data
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // 获取负责人下拉
            this.finduserPidOptions()
        }
    },
    components: {
        mainone,
        mainselect,
        mainwechat,
        mainimgesup
    }
}
</script>

<style scoped>
/* 以下是：是否自动提现的css */
.draw-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.draw-box-radio {
    margin-right: 20px;
}

.draw-box-input {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* 以下是:当前页面整个box */
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    padding: 5px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}
</style>
