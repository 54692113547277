<template>
    <!-- 按钮-组件设置 -->
    <div>
        <!-- 标题 -->
        <div class="pages-box-small-title small-content-title-title-one" v-if="this.buttonformData.styletype.formdata">
            按钮设置
        </div>
        <div class="pages-box-small-content">
            <!-- 按钮样式 -->
            <div>
                <div class="small-content-title-title">按钮样式</div>
                <div class="small-content-title-content content-box-line">
                    <div class="content-box-type">
                        <el-radio-group v-model="buttonformData.styletype.btntype.changekey"
                            @change="changeButtonTypeEvent">
                            <el-radio style="margin:10px;" :label="item.key" size="large" border
                                v-for="(item, index) in this.buttontypechange" :key="index">
                                <div :style="item.styletype" class="content-box-type-div"></div>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <!-- 按钮文本 -->
            <div>
                <div class="small-content-title-title">按钮文本</div>
                <div class="small-content-title-content content-box-line">
                    <el-input v-model="buttonformData.value" show-word-limit maxlength="50"
                        @input="subformEvent(this.buttonformData)"></el-input>
                </div>
            </div>
            <!-- 文本字号 -->
            <div>
                <div class="small-content-title-title">文本字号</div>
                <div class="small-content-title-content content-box-line">
                    <el-select v-model="buttonformData.styletype.fontsize" placeholder="请选择字号" style="width:100%">
                        <el-option v-for="(item, index) in fontsizeoptions" :key="index" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </div>
            </div>
            <!-- 文字颜色 -->
            <div>
                <div class="small-content-title-title">
                    <div>文字颜色</div>
                    <div>
                        <el-radio-group v-model="buttonformData.styletype.fontcolor.type" class="ml-4">
                            <el-radio label="1">默认</el-radio>
                            <el-radio label="2">自定义</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="small-content-title-content content-box-line"
                    v-if="buttonformData.styletype.fontcolor.type == 2">
                    <div>
                        <span>{{ buttonformData.styletype.fontcolor.color }}</span>
                    </div>
                    <div style="display: flex; flex-direction: row">
                        <div>
                            <el-color-picker v-model="buttonformData.styletype.fontcolor.color"
                                style="vertical-align: middle"
                                @active-change="(data) => clickColorEvent(data, 'fontcolor')" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 按钮颜色 -->
            <div>
                <div class="small-content-title-title">
                    <div>按钮颜色</div>
                    <div>
                        <el-radio-group v-model="buttonformData.styletype.bgcolor.type" class="ml-4">
                            <el-radio label="1">默认</el-radio>
                            <el-radio label="2">自定义</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="small-content-title-content content-box-line"
                    v-if="buttonformData.styletype.bgcolor.type == 2">
                    <div>
                        <span>{{ buttonformData.styletype.bgcolor.color }}</span>
                    </div>
                    <div style="display: flex; flex-direction: row">
                        <div>
                            <el-color-picker v-model="buttonformData.styletype.bgcolor.color"
                                style="vertical-align: middle"
                                @active-change="(data) => clickColorEvent(data, 'backgroundcolor')" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 宽高 -->
            <div>
                <div class="small-content-title-title">按钮宽高</div>
                <div class="small-content-title-content content-box-line">
                    <div>
                        <el-input v-model="buttonformData.styletype.btnwidth" placeholder="宽(单位：% 或 px)" clearable>
                        </el-input>
                    </div>
                    <div style="padding: 10px;"><i class="el-icon-link" style="transform: rotate(45deg);"></i></div>
                    <div>
                        <el-input v-model="buttonformData.styletype.btnheight" placeholder="高(单位：% 或 px)" clearable>
                        </el-input>
                    </div>
                </div>
            </div>
            <!-- 链接 -->
            <div v-if="this.buttonformData.styletype.formdata">
                <div class="small-content-title-title">
                    <div>链接</div>
                    <div>
                        <el-button type="primary" size="small" plain
                            @click="seturlEvent(this.buttonformData.styletype, (dialogVisible1 = true))">设置链接
                        </el-button>
                    </div>
                </div>
                <div class="content-box-line">
                    <div>
                        <el-input v-model="buttonformData.styletype.formdata.jumpurl" placeholder="请设置链接" disabled
                            clearable>
                        </el-input>
                    </div>

                </div>
            </div>

        </div>

        <!-- 弹窗-设置链接 -->
        <el-dialog v-model="dialogVisible1" title="链接" :width="this.searchwidth" :before-close="handleCloseSenior">
            <div>
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                    <div style="
                        max-height: 450px;
                        overflow: hidden;
                        overflow-y: visible;
                        margin: 20px 5% 0 0;
                        ">
                        <el-form-item label="跳转方式：">
                            <el-radio-group v-model="this.ruleForm.jumptype" class="ml-4">
                                <!-- <el-radio label="1">站内链接</el-radio>
                                <el-radio label="2">站外小程序</el-radio> -->
                                <el-radio label="3">H5链接/站外网页</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <!-- <el-form-item label="站内链接：" v-if="this.ruleForm.jumptype == 1">
                            <el-select v-model="this.ruleForm.inurl" placeholder="请选择站内链接" style="width: 100%;">
                                <el-option v-for="(item, index) in this.inurloptions" :key="index" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="小程序APPID：" v-if="this.ruleForm.jumptype == 2">
                            <el-select v-model="this.ruleForm.miniappid" placeholder="请选择小程序APPID" style="width: 100%;">
                                <el-option v-for="(item, index) in this.miniappidoptions" :key="index"
                                    :label="item.label" :value="item.value" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="小程序路径：" v-if="this.ruleForm.jumptype == 2">
                            <div>
                                <el-input v-model="this.ruleForm.miniurl" placeholder="请填写小程序路径"> </el-input>
                            </div>
                            <div>
                                <span style="font-size: 12px;height: 20px;line-height: 20px;">小程序地址为空表示默认跳转小程序首页</span>
                            </div>
                        </el-form-item> -->
                        <el-form-item label="跳转路径：" v-if="this.ruleForm.jumptype == 3">
                            <el-input v-model="ruleForm.outurl" placeholder="请填写以http://或https://开头的跳转路径"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <div style="text-align: right;">
                                <el-button @click="resetForm((dialogVisible1 = false))">取消</el-button>
                                <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
                            </div>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { reactive, ref } from "vue";
import axios from "axios";

export default {
    name: "buttonset",
    props: ["buttonform"],
    setup(props) {
        const buttonformData = reactive(props["buttonform"]);

        return {
            buttonformData
        };
    },
    data() {
        return {
            // 按钮样式
            buttontypechange: [
                {
                    key: '1',
                    styletype: {
                        'border-radius': '4px',
                        'background-color': 'var(--el-color-primary)',
                    }
                },
                {
                    key: '2',
                    styletype: {
                        'border-radius': '4px',
                        'background-color': '#ffffff',
                        'border': '1px solid var(--el-color-primary)',
                    }
                },
                {
                    key: '3',
                    styletype: {
                        'border-radius': '4px',
                        'background-color': 'var(--el-color-primary)',
                        'box-shadow': '0px 5px 1px #465EFF7e'
                    }
                },
                {
                    key: '4',
                    styletype: {
                        'border-radius': '20px',
                        'background-color': 'var(--el-color-primary)',

                    }
                },
                {
                    key: '5',
                    styletype: {
                        'border-radius': '20px',
                        'background-color': '#ffffff',
                        'border': '1px solid var(--el-color-primary)',
                    }
                },
                {
                    key: '6',
                    styletype: {
                        'border-radius': '20px',
                        'background-color': 'var(--el-color-primary)',
                        'box-shadow': '0px 5px 1px #465EFF7e'
                    }
                },
            ],

            // 文本字号-下拉
            fontsizeoptions: [
                {
                    label: '12px',
                    value: '12px',
                },
                {
                    label: '13px',
                    value: '13px',
                },
                {
                    label: '14px',
                    value: '14px',
                },
                {
                    label: '15px',
                    value: '15px',
                },
                {
                    label: '16px',
                    value: '16px',
                },
                {
                    label: '17px',
                    value: '17px',
                },
                {
                    label: '18px',
                    value: '18px',
                },
                {
                    label: '19px',
                    value: '19px',
                },
                {
                    label: '20px',
                    value: '20px',
                }
            ],

            dialogVisible1: ref(false),
            searchwidth: "580px",
            // 弹窗相关数据
            ruleForm: {
                jumptype: "1",
                inurl: '',
                miniappid: '',
                miniurl: '',
                outurl: '',
            }, //弹窗中-form表单

            // 站内链接-下拉
            inurloptions: [
                {
                    value: '1',
                    label: '站内01'
                },
                {
                    value: '2',
                    label: '站内02'
                }
            ],
            miniappidoptions: [
                {
                    value: '1',
                    label: '小程序APPID01'
                },
                {
                    value: '2',
                    label: '小程序APPID02'
                }
            ],


            mytoken: localStorage.getItem("token"), //获取存储的token

        }
    },
    methods: {
        // 【按钮】-按钮样式选中
        changeButtonTypeEvent(data) {
            if (data == 1) {
                this.buttonformData.styletype.btntype = {
                    changekey: '1',
                    type: 'primary',
                    plain: false,
                    round: false,
                    boxshadow: '',
                }
            }
            if (data == 2) {
                this.buttonformData.styletype.btntype = {
                    changekey: '2',
                    type: 'primary',
                    plain: true,
                    round: false,
                    boxshadow: '',
                }
            }
            if (data == 3) {
                this.buttonformData.styletype.btntype = {
                    changekey: '3',
                    type: 'primary',
                    plain: false,
                    round: false,
                    boxshadow: '0px 5px 1px #465EFF',
                }
            }
            if (data == 4) {
                this.buttonformData.styletype.btntype = {
                    changekey: '4',
                    type: 'primary',
                    plain: false,
                    round: true,
                    boxshadow: '',
                }
            }
            if (data == 5) {
                this.buttonformData.styletype.btntype = {
                    changekey: '5',
                    type: 'primary',
                    plain: true,
                    round: true,
                    boxshadow: '',
                }
            }
            if (data == 6) {
                this.buttonformData.styletype.btntype = {
                    changekey: '6',
                    type: 'primary',
                    plain: false,
                    round: true,
                    boxshadow: '0px 5px 1px #465EFF',
                }
            }
            this.subformEvent(this.buttonformData)
        },
        // 【按钮】-文字颜色||背景颜色-修改
        clickColorEvent(data, flag) {
            if (flag == "fontcolor") {
                this.buttonformData.styletype.fontcolor.color = data;
            }
            if (flag == "backgroundcolor") {
                this.buttonformData.styletype.bgcolor.color = data;
            }
            this.subformEvent(this.buttonformData);
        },
        // 点击-设置链接-返显数据
        seturlEvent(data) {
            this.ruleForm.jumptype = data.formdata.jumptype;

            if (data.formdata.jumptype == 1) {
                this.ruleForm.inurl = data.formdata.jumpurl;
            }
            if (data.formdata.jumptype == 2) {
                this.ruleForm.miniappid = data.formdata.jumpappid;
                this.ruleForm.miniurl = data.formdata.jumpurl;
            }
            if (data.formdata.jumptype == 3) {
                this.ruleForm.outurl = data.formdata.jumpurl;
            }
        },
        // 点击-确定按钮-设置数据
        submitForm(formName) {
            var getdatas = this.$refs[formName].model;

            this.buttonformData.styletype.formdata.jumptype = getdatas.jumptype
            if (getdatas.jumptype == 1) {
                this.buttonformData.styletype.formdata.jumpappid = '';
                this.buttonformData.styletype.formdata.jumpurl = getdatas.inurl;
            }
            if (getdatas.jumptype == 2) {
                this.buttonformData.styletype.formdata.jumpappid = getdatas.miniappid;
                this.buttonformData.styletype.formdata.jumpurl = getdatas.miniurl;
            }
            if (getdatas.jumptype == 3) {
                this.buttonformData.styletype.formdata.jumpappid = '';
                this.buttonformData.styletype.formdata.jumpurl = getdatas.outurl;
            }
            this.subformEvent(this.buttonformData)

            this.dialogVisible1 = ref(false); //关闭弹窗
        },

        // 组件数据返回到父组件
        subformEvent(data) {
            this.$emit("getbuttonsetdata", data);
        },
    },
    watch: {
        // 监听-数据更新
        buttonform(val) {
            this.buttonformData = val;
        },
    },
    components: {

    },
    created() {
        // 获取相关配置配置-webview跳转链接-站内链接-下拉
        axios
            .post(
                "/admin/config/info",
                {
                    key_name: "mini_path",
                },
                {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                }
            )
            .then((response) => {
                if (response.data.result) {
                    var alldatas = Array();
                    var num = 0;
                    for (var key in response.data.result) {
                        alldatas[num] = {
                            value: response.data.result[key].path,
                            label: response.data.result[key].title,
                        };
                        num = num + 1;
                    }
                    this.inurloptions = alldatas
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        // 获取相关配置配置-小程序APPID-站外链接-下拉
        axios
            .post(
                "/admin/config/info",
                {
                    key_name: "outer_app",
                },
                {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                }
            )
            .then((response) => {
                if (response.data.result) {
                    var alldatas = Array();
                    var num = 0;
                    for (var key in response.data.result) {
                        alldatas[num] = {
                            value: response.data.result[key].appid,
                            label: response.data.result[key].title,
                        };
                        num = num + 1;
                    }
                    this.miniappidoptions = alldatas
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
</script>

<style scoped>
.small-content-title-title-one {
    border-bottom: 1px solid #e8eaec;
    padding-bottom: 10px;
}

.small-content-title-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    margin: 10px 0;
}

.small-content-title-title div {
    margin-right: 20px;
}

.small-content-title-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.content-box-type {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.content-box-type-div {
    width: 60px;
    height: 20px;
    background-color: var(--el-color-primary);
}

.el-radio.is-bordered {
    padding: 0 10px 0 10px;
}

.content-box-line {
    padding: 10px;
    border: 1px solid #e8eaec;
    border-radius: 4px;
}

.pages-box-small-content {
    height: 630px;
    overflow: hidden;
    overflow-y: visible;
}
</style>