<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div class="hand-box-list">
            <div class="hand-box-list-box">
                <el-tabs v-model="activeName" class="demo-tabs" @tab-click="
                        (tab, event) => {
                            handleTabsClick(tab, event)
                        }
                    ">
                    <el-tab-pane label="设备独立设置" name="1"> </el-tab-pane>
                    <el-tab-pane label="代理商独立设置" name="2"> </el-tab-pane>
                    <el-tab-pane label="地区设置" name="3"> </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!--table表单-->
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <div>
                <selectOne ref="RefselectOne" v-if="activeName == 1"
                    v-on:getsearchinfo="(data) => getSeachinfoEvent(data, activeName)"
                    v-on:searchForm="searchFormEvent" />
                <selectTwo ref="RefselectTwo" v-if="activeName == 2"
                    v-on:getsearchinfo="(data) => getSeachinfoEvent(data, activeName)"
                    v-on:searchForm="searchFormEvent" />
                <selectThree ref="RefselectThree" v-if="activeName == 3"
                    v-on:getsearchinfo="(data) => getSeachinfoEvent(data, activeName)"
                    v-on:searchForm="searchFormEvent" />
            </div>
            <!--主list列表-->
            <div
                style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px; color: red; line-height: 20px; padding-top: 10px">
                        <div><span>配置优先级：设备独立设备 > 代理商独立设置 > 地区设备。</span></div>
                        <div v-if="activeName == 3"><span>地区设置：针对的是【设备位置】定位！</span></div>
                    </div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="primary" plain
                            @click="batchOpenClose(this.multipleSelection, 1)">批量开启</el-button>
                        <el-button type="danger" plain
                            @click="batchOpenClose(this.multipleSelection, -1)">批量关闭</el-button>
                        <el-button type="primary" plain @click="routeraddrClick('/minipluswecomadd', null, activeName)"
                            v-if="this.mypowerlimits.xcxjqw_tj">添加</el-button>
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <commTable v-loading="loading" element-loading-text="正在飞速加载中..." ref="mymultipleTableRef"
                        :tabletitle="mainthreetabletitle" :tabledata="mainthreetableData" :totalcount="totalCount"
                        :currentpage="currentPage" :pagesize="PageSize" :Lastpage="lastpage" :Operation="{ width: 140 }"
                        @handleSelection="handleSelectionEvent" @handleSelectionOnline="handleSelectionChangeOnline">
                        <template #content="{scope,prop}">
                            <!-- 状态-渲染数据 -->
                            <div v-if="prop === 'status'">
                                <div id="switch" v-if="scope.row.status != 2">
                                    <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭"
                                        :active-value="1" :inactive-value="-1" active-color="#13ce66"
                                        inactive-color="#cbcbcb"
                                        @click="this.mypowerlimits.xcxjqw_qy ? changeStatus(scope.row) : ''"
                                        :disabled="!this.mypowerlimits.xcxjqw_qy">
                                    </el-switch>
                                </div>
                                <div v-if="scope.row.status == 2">
                                    <span style="color: red">禁用</span>
                                </div>
                            </div>
                            <!-- 企业名称 -->
                            <div v-else-if="prop === 'wecom_name'">
                                <div :class="scope.row.wecom_status == -2 ? 'wecom-class-box' : ''">
                                    <span>{{ scope.row.wecom_name }}</span>
                                </div>
                                <div v-if="scope.row.wecom_status == -2" style="color: red; font-size: 12px">
                                    <span>(已删除)</span>
                                </div>
                            </div>
                            <!-- 企微二维码链接 -->
                            <div v-else-if="prop === 'wecom_url'" style="cursor: pointer"
                                @click="clickErCodeEvent(scope.row)">
                                <span style="color: var(--el-color-primary); text-decoration: underline">{{
                                    scope.row.wecom_url }}</span>
                            </div>
                            <!-- 企微绑定设备 -->
                            <div v-else-if="prop === 'client_num'" style="cursor: pointer"
                                @click="clickBindDvcEvent(scope.row)">
                                <span style="color: var(--el-color-primary); text-decoration: underline">{{
                                    scope.row.client_num }}个</span>
                            </div>
                            <!-- 其他 -->
                            <div v-else>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                        <template #special="{scope}">
                            <div>
                                <el-button @click="routeraddrClick('/minipluswecomedit', scope.row, activeName)"
                                    type="primary" size="mini" v-if="this.mypowerlimits.xcxjqw_bj">编辑</el-button>
                                <el-button @click="deleteRow(scope.row, activeName)" type="danger" size="mini"
                                    v-if="this.mypowerlimits.xcxjqw_sc" icon="el-icon-delete"></el-button>
                            </div>
                        </template>
                    </commTable>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 弹窗:绑定设备 -->
        <div v-if="tandialogVisible">
            <modelDialog :isshow="tandialogVisible" :title="'绑定设备'" v-on:beforeclose="tanhandleClose">
                <template v-slot:search>
                    <div class="tan-search-class">
                        <div>
                            <modelSearch id="tan-button-enter" ref="RefmodelSearchTan" :gaoisshow="false"
                                :forminfo="tanforminfo" v-on:getsearchinfo="tangetSeachinfoEvent" />
                        </div>
                        <div style="margin-top: -5px;"><el-button type="warning" plain
                                @click="exportDatasTan()">导出</el-button></div>
                    </div>
                </template>
                <template v-slot:table>
                    <div v-loading="tanloading" element-loading-text="正在飞速加载中...">
                        <listTanTable ref="tanmMultipleTableRef" :tabletitle="tantabletitle" :tabledata="tantableData"
                            :totalcount="tantotalCount" :currentpage="tancurrentPage" :pagesize="tanPageSize"
                            :Lastpage="tanlastpage" />
                        <modelPages :totalCount="tantotalCount" :currentPage="tancurrentPage" :pageSizes="tanpageSizes"
                            :PageSize="tanPageSize" @size-change="tanhandleSizeChange"
                            @current-change="tanhandleCurrentChange" />
                    </div>
                </template>
            </modelDialog>
        </div>

        <!-- 弹窗:企业二维码链接 -->
        <div>
            <el-dialog v-model="this.erCodedialogVisible" title="查看-企业二维码链接" :width="400"
                :before-close="handleCloseSenior">
                <div class="dialog-box-flex">
                    <div style="min-height: 160px">
                        <img :src="this.isshow_erCode" alt="图片链接错误"
                            style="width: 100%; margin-top: 10px; border: 1px solid #ececec" />
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import mainone from '@/components/Mainone'
    import selectOne from './selectOne.vue'
    import selectTwo from './selectTwo.vue'
    import selectThree from './selectThree.vue'
    import modelDialog from './modelDialog.vue'
    import modelSearch from './modelSearch.vue'
    import listTanTable from './listTanTable.vue'
    import modelPages from './modelPages.vue'
    import commTable from './commTable.vue'
    import { ElMessageBox, ElMessage } from 'element-plus'
    import axios from 'axios'
    import { defineComponent, ref } from 'vue'

    export default defineComponent({
        name: 'vminipluswecom',
        inject: ['reload'],
        data() {
            return {
                mainonebread: [
                    {
                        name: '运营'
                    },
                    {
                        name: '企业微信'
                    },
                    {
                        name: '小程序加企业微信'
                    }
                ], // 当前页-【面包屑导航】
                activeName: '1',
                mainthreeisshowbut: false, // 数据列表中-【添加按钮】
                mainthreeisshowset: true, // 数据列表中-复选框
                mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
                mainthreetableope: 27, // 数据列表中-操作-根据页面显示控件（整页）
                mainthreehandle: 120, // 数据列表中-操作-宽度
                mainthreetabletitle: [], // 数据列表中-数据表头
                mainthreestatus: true, // 数据列表中-属于search按钮表示状态
                mainthreetableData: [], // 数据列表中-具体数据
                totalCount: 1, //数据列表中-数据总条数
                currentpage: 1, //数据列表中-数据当前页数
                lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
                loading: false, //初始化-loading加载动效，默认是false关闭

                //table表单相关数据
                seniorform: {},
                currentPage: 1,
                pageSizes: [20, 50, 100],
                PageSize: 20,
                multipleSelection: [],
                thispagename: '', //通过路由获取当前页面的path值
                searchwidth: '1200px',

                // 弹窗-绑定设备
                tandialogVisible: false,
                tanforminfo: {
                    myinputoneisshow: true, //筛选查询-左侧字+input
                    myinputone: {
                        divinterval: '0', //框右侧距离
                        titlewidth: '74px', //标题宽度
                        inputwidth: '140px', //input宽度
                        inputinterval: '20px', //input右侧距离
                        input: [
                            {
                                ismust: false, //是否必填，true表示必填
                                title: '设备编号：',
                                placeholder: '请输入',
                                name: 'name',
                                content: '',
                                class: 'input-mydemo-disabled'
                            }
                        ]
                    },
                    myselectcityleftisshow: true, //筛选查询-selectcity选框
                    myselectcityleft: {
                        selectwidth: '100%',
                        select: {
                            ismust: false,
                            title: '选择区域：',
                            name: 'area_code',
                            area_code: '',
                            valuesheng: '',
                            valueshi: '',
                            valuequ: '',
                            disshi: true,
                            disqu: true
                        }
                    },
                    myinputonefullisshow: true, //筛选查询-自动建议下拉框
                    myinputonefull: {
                        divinterval: '0', //框右侧距离
                        titlewidth: '74px', //标题宽度
                        inputwidth: '140px', //input宽度
                        inputinterval: '20px', //input右侧距离
                        input: [
                            {
                                ismust: false, //是否必填，true表示必填
                                title: '代理商名：',
                                placeholder: '请输入',
                                name: 'shop_id', //此name为键值
                                content: '',
                                id: '', //此id为给后台的id值
                                url: '/admin/shopUser/getShopList',
                                inputval: { name: '' } //这里记录需要传的参数
                            }
                        ]
                    }
                },
                tanloading: false,
                tantabletitle: [
                    {
                        fixedstatu: false,
                        prop: 'id',
                        label: 'ID',
                        width: '100'
                    },
                    {
                        fixedstatu: false,
                        prop: 'client',
                        label: '设备编号',
                        width: '130'
                    },
                    {
                        fixedstatu: false,
                        prop: 'shop_code_end',
                        label: '代理商名称',
                        width: '200'
                    },
                    {
                        fixedstatu: false,
                        prop: 'shop_user_end',
                        label: '代理商手机号',
                        width: '130'
                    },
                    {
                        fixedstatu: false,
                        prop: 'address',
                        label: '设备地址',
                        width: ''
                    }
                ],
                tantableData: [],
                tantotalCount: 1,
                tanlastpage: 1,
                tancurrentPage: 1,
                tanpageSizes: [20, 50, 100],
                tanPageSize: 20,
                tanpagesdatas: [],
                tanslientId: '',

                mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
                mytoken: localStorage.getItem('token'), //获取存储的token
                pagesdatas: [], //存储最近一次的筛选条件，供分页使用

                erCodedialogVisible: false,
                isshow_erCode: ''
            }
        },
        setup() {
            const dialogVisible = ref(false)
            const remarkId = ref('')
            const handleCloseSenior = (done) => {
                done()
            }

            return {
                dialogVisible,
                remarkId,
                handleCloseSenior
            }
        },
        mounted() {
            //获取当前页面路由path值
            this.thispagename = this.$route.path
        },
        methods: {
            // 批量开启/关闭
            batchOpenClose(datas, status) {
                if (datas && datas.length > 0) {
                    let ids = datas.map((n) => {
                        return n.id
                    })
                    this.axiosBatchEvent(ids, status)
                } else {
                    ElMessage({
                        type: 'error',
                        message: '请选中数据，再点击[批量' + (status == -1 ? '关闭' : '开启') + ']'
                    })
                }
            },
            axiosBatchEvent(ids, status) {
                axios
                    .post(
                        '/admin/Service/wecomSwitch',
                        {
                            id: ids,
                            status: status
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        if (response.data.code == 0) {
                            ElMessage({
                                type: 'success',
                                message: '批量' + (status == -1 ? '关闭' : '开启') + '成功',
                                duration: 1000,
                                onClose: () => {
                                    this.handleCurrentChange(this.currentPage)
                                    // 清空多选
                                    this.multipleSelection = []
                                }
                            })
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            // **************多选**************
            // 深拷贝
            deepcopy(obj, cache = []) {
                function find(list, f) {
                    return list.filter(f)[0]
                }

                // just return if obj is immutable value
                if (obj === null || typeof obj !== 'object') {
                    return obj
                }

                // if obj is hit, it is in circular structure
                const hit = find(cache, (c) => c.original === obj)
                if (hit) {
                    return hit.copy
                }

                const copy = Array.isArray(obj) ? [] : {}
                // put the copy into cache at first
                // because we want to refer it in recursive deepCopy
                cache.push({
                    original: obj,
                    copy
                })

                Object.keys(obj).forEach((key) => {
                    copy[key] = this.deepcopy(obj[key], cache)
                })

                return copy
            },
            // 去重
            reduce(datas) {
                let obj = {}
                let reduce = []
                reduce = datas.reduce(function (item, next) {
                    //item为没有重复id的数组，next为当前对象
                    obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
                    return item
                }, [])

                return reduce
            },
            // 多选数据-全选
            handleSelectionEvent(datas) {
                var all_datas = []
                let { data, rowdatas } = datas
                if (data.length > 0) {
                    // 全选
                    all_datas = [...this.multipleSelection, ...this.deepcopy(data)]
                } else {
                    // 取消全选
                    let ids = rowdatas.map((n) => {
                        return n.id
                    })
                    this.multipleSelection = this.multipleSelection.filter((n) => ids.indexOf(n.id) == -1)
                    all_datas = [...this.multipleSelection]
                }
                // 去重
                this.multipleSelection = this.reduce(all_datas)
            },
            // 多选数据-单选
            handleSelectionChangeOnline({ datas, row }) {
                this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id)
                this.handleSelectionEvent({ data: datas, rowdatas: this.mainthreetableData })
            },
            // 多选-检查反显
            moreDataBackShow(datas) {
                // console.log(this.$refs.mymultipleTableRef)
                if (this.$refs.mymultipleTableRef) {
                    console.log('fanxian:', datas)
                    this.$refs.mymultipleTableRef.backShow(datas, this.mainthreetableData)
                }
            },
            // **************************************************

            // 弹窗-企业二维码链接
            clickErCodeEvent(data) {
                this.erCodedialogVisible = true
                this.isshow_erCode = data.wecom_url
            },
            // 弹窗-绑定设备
            clickBindDvcEvent(data) {
                this.tandialogVisible = true
                this.tanloading = true

                this.tanpagesdatas = {
                    page: 1,
                    page_size: this.tanPageSize,
                    slientId: data.clientArr.length > 0 ? data.clientArr : [0]
                }
                this.tanslientId = data.clientArr.length > 0 ? data.clientArr : [0]
                this.tanpostAxios(this.tanpagesdatas)
            },
            //分页器中-当选择条数时触发该函数
            tanhandleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                this.tanPageSize = val
                this.tancurrentPage = 1

                //加载loading
                this.tanloading = true
                this.tanpagesdatas.page = 1
                this.tanpagesdatas.page_size = val

                this.tanpostAxios(this.tanpagesdatas)
            },
            //分页器中-当选择分页时触发该函数
            tanhandleCurrentChange(val) {
                // console.log(`当前页: 第${val}页`)
                this.tancurrentPage = val

                //加载loading
                this.tanloading = true
                this.tanpagesdatas.page = val
                this.tanpagesdatas.page_size = this.PageSize

                this.tanpostAxios(this.tanpagesdatas)
            },

            //获取Container-Pagedata-Maintwo-搜索-返回的数据
            tangetSeachinfoEvent(data) {
                console.log(data)
                this.tanpagesdatas = data
                this.tanloading = true
                this.tanpagesdatas.page = 1
                this.tanpagesdatas.page_size = this.tanPageSize
                this.tanpagesdatas.slientId = this.tanslientId
                this.tanpagesdatas.name = data.name
                this.tanpagesdatas.area_code = data.area_code

                this.tanpostAxios(this.tanpagesdatas)
            },
            // 弹窗-接口
            tanpostAxios(datas, index) {
                axios
                    .post('/admin/Equipment/clientList', datas, {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    })
                    .then((response) => {
                        if (!index) {
                            this.tanmyaxiosfunc(response.data.result)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            //数据渲染
            tanmyaxiosfunc(successdata) {
                var newdata = successdata.data
                for (var i = 0; i < newdata.length; i++) {
                    if (newdata[i].area_code) {
                        //将省市区分割
                        var arrcity = newdata[i].area_code.split(',')
                        newdata[i].province = arrcity[0]
                        newdata[i].city = arrcity[1]
                        newdata[i].county = arrcity[2]
                    }
                }
                this.tantableData = newdata
                this.tantotalCount = successdata.total
                this.tancurrentpage = successdata.current_page
                this.tanlastpage = successdata.last_page
                this.tanloading = false
            },
            tanhandleClose(done) {
                done()
                this.tandialogVisible = false
                this.tantableData = []
                this.$refs.RefmodelSearchTan.clear(this.tanforminfo.myinputone, this.tanforminfo.myselectcityleft, this.tanforminfo.myinputonefull)
            },
            // 弹窗-绑定设备-导出
            exportDatasTan() {
                axios
                    .post(
                        '/admin/Equipment/clientList',
                        {
                            name: this.tanpagesdatas.name,
                            area_code: this.tanpagesdatas.area_code,
                            shop_id: this.tanpagesdatas.shop_id,
                            type: this.activeName,
                            slientId: this.tanpagesdatas.slientId,
                            export: 1
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        console.log(response.data.message)
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            },

            // 切换tabs
            handleTabsClick(tab) {
                let all_mainthreedatas = [
                    {
                        fixedstatu: 'left',
                        prop: 'id',
                        label: 'id',
                        width: '80'
                    },
                    {
                        fixedstatu: false,
                        prop: 'name',
                        label: '名称',
                        width: '150'
                    },
                    {
                        fixedstatu: false,
                        prop: 'wecom_user',
                        label: '企微人员',
                        width: '140'
                    },
                    {
                        fixedstatu: false,
                        prop: 'wecom_name',
                        label: '企业名称',
                        width: '140'
                    },
                    {
                        fixedstatu: false,
                        prop: 'wecom_url',
                        label: '企微二维码链接',
                        width: '180'
                    },
                    {
                        fixedstatu: false,
                        prop: 'remark',
                        label: '描述',
                        width: ''
                    },
                    {
                        fixedstatu: false,
                        prop: 'area_code_name',
                        label: '绑定地址',
                        width: '150'
                    },
                    {
                        fixedstatu: false,
                        prop: 'shop_name',
                        label: '绑定代理商',
                        width: '150'
                    },
                    {
                        fixedstatu: false,
                        prop: 'client_num',
                        label: '绑定设备',
                        width: '100'
                    },
                    {
                        fixedstatu: false,
                        prop: 'jump_type_name',
                        label: '跳转类型',
                        width: '100'
                    },
                    {
                        fixedstatu: false,
                        prop: 'status',
                        label: '状态',
                        width: '100'
                    },
                    {
                        fixedstatu: false,
                        prop: 'admin_id_name',
                        label: '创建人',
                        width: '140'
                    },
                    {
                        fixedstatu: false,
                        prop: 'addtime',
                        label: '创建时间',
                        width: '180'
                    }
                ]
                let activename = tab.props.name

                // 匹配table标题
                if (activename == 1) {
                    this.mainthreetabletitle = all_mainthreedatas
                        .map((n) => {
                            if (n.label == '绑定地址' || n.label == '绑定代理商') {
                                return
                            } else {
                                return n
                            }
                        })
                        .filter((n) => n)
                }
                if (activename == 2) {
                    this.mainthreetabletitle = all_mainthreedatas
                        .map((n) => {
                            if (n.label == '绑定地址') {
                                return
                            } else {
                                return n
                            }
                        })
                        .filter((n) => n)
                }
                if (activename == 3) {
                    this.mainthreetabletitle = all_mainthreedatas
                        .map((n) => {
                            if (n.label == '绑定代理商') {
                                return
                            } else {
                                return n
                            }
                        })
                        .filter((n) => n)
                }

                // 获取数据
                this.getSeachinfoEvent({ page: 1, page_size: this.PageSize }, activename)

                // 清空多选
                this.multipleSelection = []
            },
            // 切换状态
            changeStatus(data) {
                axios
                    .post(
                        '/admin/Service/wecomSwitch',
                        {
                            id: data.id,
                            status: data.status
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        if (response.data.code == 0) {
                            ElMessage({
                                type: 'success',
                                message: response.data.message
                            })
                        } else {
                            // 切换状态失败-复原状态
                            if (data.status == 1) {
                                data.status = 1
                            } else {
                                data.status = -1
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            //table表-操作中-删除按钮（函数）
            deleteRow(data) {
                ElMessageBox.confirm("确定删除名称为[ '" + data.name + "' ]的数据吗?", '提示：', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axios
                        .post(
                            '/admin/Service/wecomSwitch',
                            {
                                id: data.id,
                                status: '-2'
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                })
            },
            //分页器中-当选择条数时触发该函数
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`)
                this.PageSize = val
                this.currentPage = 1

                //加载loading
                this.loading = true
                //通过Ajax向后台获取数据
                axios
                    .get('admin/Service/wecomList', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            name: this.pagesdatas.name,
                            wecom_user: this.pagesdatas.wecom_user,
                            wecom: this.pagesdatas.wecom,
                            status: this.pagesdatas.status,
                            bind_info: this.pagesdatas.bind_info,
                            type: this.activeName,
                            page: 1,
                            page_size: val
                        }
                    })
                    .then((response) => {
                        this.myaxiosfunc(response.data.result)
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            },
            //分页器中-当选择分页时触发该函数
            handleCurrentChange(val) {
                // console.log(`当前页: 第${val}页`)
                this.currentPage = val

                //加载loading
                this.loading = true
                //通过Ajax向后台获取数据
                axios
                    .get('admin/Service/wecomList', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            name: this.pagesdatas.name,
                            wecom_user: this.pagesdatas.wecom_user,
                            wecom: this.pagesdatas.wecom,
                            status: this.pagesdatas.status,
                            bind_info: this.pagesdatas.bind_info,
                            type: this.activeName,
                            page: val,
                            page_size: this.PageSize
                        }
                    })
                    .then((response) => {
                        this.myaxiosfunc(response.data.result)
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            },
            //点击按钮-弹出弹框并赋值弹窗基本配置
            addTanClick(taninfo) {
                console.log(taninfo)
                this.tancontent.content = taninfo.content
                this.tancontent.title = taninfo.title
            },
            //点击进行路由页面跳转以及携带参数
            routeraddrClick(routeraddr, rowdata, activename) {
                // 携带参数跳转
                if (rowdata) {
                    // 跳转-编辑
                    if (routeraddr === '/minipluswecomedit') {
                        this.$router
                            .push({
                                path: routeraddr,
                                query: { activename: activename, id: rowdata.id, back_data: JSON.stringify(rowdata) }
                            })
                            .catch((error) => error)
                    }
                } else {
                    if (activename) {
                        // 跳转-添加
                        if (routeraddr === '/minipluswecomadd') {
                            this.$router
                                .push({
                                    path: routeraddr,
                                    query: { activename: activename }
                                })
                                .catch((error) => error)
                        }
                    } else {
                        //仅跳转
                        this.$router.push(routeraddr).catch((error) => error)
                    }
                }
            },
            //获取当前windows页面信息-实时修改【弹窗】的宽度
            getwindowinfo() {
                this.sreenheight = document.documentElement.clientHeight
                this.sreenwidth = document.documentElement.clientWidth
                if (this.sreenwidth < 1550) {
                    this.searchwidth = this.sreenwidth * 0.8 + 'px'
                } else {
                    this.searchwidth = '1500px'
                }
            },
            //导出数据
            exportDatas() {
                axios
                    .get('admin/Service/wecomList', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            name: this.pagesdatas.name,
                            wecom_user: this.pagesdatas.wecom_user,
                            wecom: this.pagesdatas.wecom,
                            status: this.pagesdatas.status,
                            bind_info: this.pagesdatas.bind_info,
                            type: this.activeName,
                            export: 1
                        }
                    })
                    .then((response) => {
                        console.log(response.data.message)
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            },

            //提交等相关event事件函数
            //获取Container-Pagedata-Maintwo-搜索-返回的数据
            getSeachinfoEvent(data, activename) {
                // console.log('点击查询获取的data数组：' + JSON.stringify(data), activename)
                // 特殊判断-选中地区设置时，将数组转逗号隔开的字符串
                if (activename == 3 && data.bind_info) {
                    if (data.bind_info.length > 0) {
                        data.bind_info = data.bind_info
                            .map((n) => {
                                return n[n.length - 1]
                            })
                            .join(',')
                    }
                }

                this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
                //加载loading
                this.loading = true
                //通过Ajax向后台获取数据
                axios
                    .get('admin/Service/wecomList', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            name: data.name,
                            wecom_user: data.wecom_user,
                            wecom: data.wecom,
                            status: data.status,
                            bind_info: data.bind_info,
                            type: activename,
                            page: 1,
                            page_size: this.PageSize
                        }
                    })
                    .then((response) => {
                        this.myaxiosfunc(response.data.result)
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            },
            //数据渲染
            myaxiosfunc(successdata) {
                var newdata = successdata.data
                for (var i = 0; i < newdata.length; i++) {
                    //转换-创建时间
                    if (newdata[i].addtime) {
                        if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                            newdata[i].addtime = '--'
                        } else {
                            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                        }
                    } else {
                        newdata[i].addtime = '--'
                    }
                }

                this.mainthreetableData = newdata
                this.totalCount = successdata.total
                this.currentpage = successdata.current_page //当前页面页数
                this.lastpage = successdata.last_page
                this.loading = false

                this.moreDataBackShow(this.multipleSelection)
            }
        },
        created() {
            if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
                this.isshow = false
                this.$router.push('/').catch((error) => error)
            } else {
                window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
                this.getwindowinfo()

                // 切换默认tab
                let tab = { props: { name: this.activeName } }
                this.handleTabsClick(tab)
            }
        },
        components: {
            mainone,
            selectOne,
            selectTwo,
            selectThree,
            modelDialog,
            modelSearch,
            listTanTable,
            modelPages,
            commTable
        }
    })
</script>

<style scoped>
    /*操作中-按钮样式*/
    .el-button--mini {
        min-height: 28px;
        padding: 0 8px;
        font-size: 12px;
        border-radius: calc(var(--el-border-radius-base) - 1px);
    }

    .mytabletext {
        color: var(--el-color-primary);
        cursor: pointer;
    }

    /*局部控制-Switch按钮-按钮中显示状态文字*/
    /* switch按钮款式 */
    #switch :deep(.el-switch__label) {
        position: absolute;
        display: none;
        color: #fff !important;
    }

    /*关上时文字地位设置*/
    #switch :deep(.el-switch__label--right) {
        z-index: 1;
    }

    /* 调整关上时文字的显示位子 */
    #switch :deep(.el-switch__label--right) span {
        margin-left: 3px;
        font-size: 12px;
    }

    /*敞开时文字地位设置*/
    #switch :deep(.el-switch__label--left) {
        z-index: 1;
    }

    /* 调整敞开时文字的显示位子 */
    #switch :deep(.el-switch__label--left) span {
        margin-left: 15px;
        font-size: 12px;
    }

    /*显示文字*/
    #switch :deep(.el-switch__label.is-active) {
        display: block;
    }

    /* 调整按钮的宽度 */
    #switch :deep(.el-switch) .el-switch__core,
    #switch :deep(.el-switch) .el-switch__label {
        width: 55px !important;
        margin: 0;
        padding: 5px;
    }

    .tool-tip {
        max-width: 400px;
    }

    .hand-box-list {
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
    }

    .hand-box-list-box {
        height: 40px;
        padding: 10px 20px;
    }

    .wecom-class-box {
        text-decoration: line-through;
        text-decoration-color: red;
    }

    .tan-search-class {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
    }
</style>