<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--添加XX按钮-点击跳转添加类页面-->
            <el-button type="primary" plain class="max_search" @click="addTanClick(tancontent, (dialogVisible = true))"
              v-if="mypowerlimits.jyhysz_tj">添加</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'client'">
                <div v-if="mytabletitle.prop === 'client'">
                  <div :class="this.mypowerlimits.jqkz == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.jqkz == true ? routeraddrClick('/sharedvccontrol', scope.row) : ''">
                    <span>{{ scope.row.client }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-代理商列表-->
                <div v-if="mainthreetableope === 41">
                  <el-button @click.prevent="deleteRow(scope.$index, mainthreetableData)" v-if="mypowerlimits.jyhysz_sc"
                    type="danger" size="mini" icon="el-icon-delete"></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog v-model="dialogVisible" :title="tancontent.title" :width="this.searchwidth"
        :before-close="handleCloseSenior">
        <el-form ref="seniorform" :model="seniorform">
          <el-form-item>
            <el-row :gutter="10">
              <el-col :span="24" :xs="24">
                <maintwo :myinputonefullisshow="tancontent.search.myinputonefullisshow"
                  :myinputonefull="tancontent.search.myinputonefull" v-on:getsearchinfo="getTanSeachinfoEvent">
                </maintwo>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="margin: 0 20px">
            <el-table ref="getTanTabledata" :data="
              this.tancontent.getTanTabledata.slice(
                (tancontent.tanfenye.tancurrentPage -
                  tancontent.tanfenye.tanlastpage) *
                tancontent.tanfenye.tanPageSize,
                tancontent.tanfenye.tancurrentPage *
                tancontent.tanfenye.tanPageSize
              )
            " style="width: 100%" height="450" border v-loading="loading" element-loading-text="正在飞速加载中..."
              @select="selectThisTabledata" @select-all="selectAllThisTabledata">
              <el-table-column type="selection" width="55" />
              <el-table-column label="设备编号" prop="client" />
              <el-table-column label="投放商家" prop="name" />
              <el-table-column label="手机号码" prop="mobile" />
              <el-table-column label="设备定位地址" prop="address" />
            </el-table>
          </el-form-item>
          <el-form-item style="margin: 20px 20px 0 20px">
            <div style="text-align: right">
              <!--分页器-->
              <div class="block" style="text-align: center; float: left">
                <el-pagination background @size-change="tanhandleSizeChange" @current-change="tanhandleCurrentChange"
                  :current-page="tancontent.tanfenye.tancurrentPage" :page-sizes="tancontent.tanfenye.tanpageSizes"
                  :page-size="tancontent.tanfenye.tanPageSize" pager-count="3" layout="total, sizes, prev, next, jumper"
                  :total="tancontent.tanfenye.tantotalCount">
                </el-pagination>
              </div>
              <div style="float: right">
                <el-button @click="tanResetClickEvent('getTanTabledata')">
                  <i class="el-icon-refresh-left"></i> 重置
                </el-button>
                <el-button type="primary" @click="
                  tanSubClickEvent(this.tancontent.content.haschangedatas)
                ">确定
                </el-button>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
// import maininput from "@/components/Maininput";
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vplussetdisable",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "设置",
        },
        {
          name: "会员设置",
        },
        {
          name: "禁用PLUS会员设置",
          path: "/plussetdisable",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputonefullisshow: true, //筛选查询-自动建议下拉框
        myinputonefull: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "220px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "设备编号：",
              placeholder: "请输入设备编号", //此name为键值
              name: "dvcid",
              content: "",
              id: "", //此id为给后台的id值
              url: "/admin/getClientList",
              inputval: { client: "" }, //这里记录需要传的参数
            },
            {
              ismust: false, //是否必填，true表示必填
              title: "手机号码：",
              placeholder: "请输入手机号码",
              name: "agentid", //此name为键值
              content: "",
              id: "", //此id为给后台的id值
              url: "/admin/shopUser/getShopList",
              inputval: { username: "" }, //这里记录需要传的参数
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 41, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 60,
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "client",
          label: "设备编号",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "name",
          label: "代理商",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "mobile",
          label: "手机号",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "address",
          label: "设备定位地址",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "is_buy",
          label: "投放方式",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "putintime",
          label: "投放时间",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [10, 20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "1240px",
      tancontent: {
        title: "选择设备",
        content: {
          haschangedatas: "", //多选选择的数据
        },
        search: {
          myinputonefullisshow: true, //筛选查询-自动建议下拉框
          myinputonefull: {
            divinterval: "0", //框右侧距离
            titlewidth: "74px", //标题宽度
            inputwidth: "220px", //input宽度
            inputinterval: "20px", //input右侧距离
            input: [
              {
                ismust: false, //是否必填，true表示必填
                title: "设备编号：",
                placeholder: "请输入设备编号", //此name为键值
                name: "dvcid",
                content: "",
                id: "", //此id为给后台的id值
                url: "/admin/getClientList",
                inputval: { client: "" }, //这里记录需要传的参数
              },
              {
                ismust: false, //是否必填，true表示必填
                title: "手机号码：",
                placeholder: "请输入手机号码",
                name: "agentid", //此name为键值
                content: "",
                id: "", //此id为给后台的id值
                url: "/admin/shopUser/getShopList",
                inputval: { username: "" }, //这里记录需要传的参数
              },
            ],
          },
        }, //弹窗中-搜索框配置数据
        getTanTabledata: [], //弹窗中table数据
        tanfenye: {
          tancurrentPage: 1,
          tanpageSizes: [10, 20, 50, 100, 1000],
          tanPageSize: 20,
          tantotalCount: 1,
          tanlastpage: 1,
        },
      }, //弹框
      dialogVisible: ref(false), //弹窗控制状态

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
      tanpagesdatas: [], //存储最近一次的弹窗条件，供弹窗中分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //点击按钮-弹出弹框-并赋值弹窗基本配置
    addTanClick(taninfo) {
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;

      //加载loading
      this.loading = true;
      //点击弹窗后刷新弹窗table的信息
      axios
        .post(
          "/admin/config/clientList",
          {
            is_use_plus: "1",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.tancontent.getTanTabledata = response.data.result.data;
          this.tancontent.tanfenye.tantotalCount = response.data.result.total;
          this.tancontent.tanfenye.tancurrentPage =
            response.data.result.current_page; //当前页面页数
          this.tancontent.tanfenye.tanPageSize = response.data.result.per_page;
          this.tancontent.tanfenye.tanlastpage = response.data.result.last_page;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 弹窗-关闭
    handleCloseSenior(done) {
      done();
    },
    // 弹框-多选(selection,row)
    selectThisTabledata(selection) {
      this.tancontent.content.haschangedatas = selection;
    },
    // 弹框-全选
    selectAllThisTabledata(selection) {
      this.tancontent.content.haschangedatas = selection;
    },
    // 弹框-多选提交
    tanSubClickEvent(data) {
      if (data !== "") {
        var ids = [];
        data.forEach((element) => {
          ids.push(element.id);
        });
        console.log(ids);
        axios
          .post(
            "/admin/config/clientDisable",
            {
              client_ids: ids,
              status: "-1",
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.code === 0) {
              this.dialogVisible = false;
              ElMessage({
                type: "success",
                message: "禁用成功!",
                duration: 1000,
                onClose: () => {
                  this.reload(); //刷新
                },
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log("error submit!!");
        return false;
      }
    },
    // 弹框-多选重置
    tanResetClickEvent(formName) {
      this.$refs[formName].clearSelection();
    },
    //弹出框-条件-查询
    getTanSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.tanpagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/config/clientList",
          {
            shop_id: data.agentid,
            client: data.dvcid,
            is_use_plus: "1",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.tancontent.getTanTabledata = response.data.result.data;
          this.tancontent.tanfenye.tantotalCount = response.data.result.total;
          this.tancontent.tanfenye.tancurrentPage =
            response.data.result.current_page; //当前页面页数
          this.tancontent.tanfenye.tanPageSize = response.data.result.per_page;
          this.tancontent.tanfenye.tanlastpage = response.data.result.last_page;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //弹出框-分页器中-当选择条数时触发该函数
    tanhandleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.tancontent.tanfenye.tanPageSize = val;
      this.tancontent.tanfenye.tancurrentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/config/clientList",
          {
            shop_id: this.tanpagesdatas.agentid,
            client: this.tanpagesdatas.dvcid,
            is_use_plus: "1",
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.tancontent.getTanTabledata = response.data.result.data;
          this.tancontent.tanfenye.tantotalCount = response.data.result.total;
          this.tancontent.tanfenye.tancurrentPage =
            response.data.result.current_page; //当前页面页数
          this.tancontent.tanfenye.tanPageSize = response.data.result.per_page;
          this.tancontent.tanfenye.tanlastpage = response.data.result.last_page;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //弹出框-分页器中-当选择分页时触发该函数
    tanhandleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.tancontent.tanfenye.tancurrentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/config/clientList",
          {
            shop_id: this.tanpagesdatas.agentid,
            client: this.tanpagesdatas.dvcid,
            is_use_plus: "1",
            page: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.tancontent.getTanTabledata = response.data.result.data;
          this.tancontent.tanfenye.tantotalCount = response.data.result.total;
          this.tancontent.tanfenye.tancurrentPage =
            response.data.result.current_page; //当前页面页数
          this.tancontent.tanfenye.tanPageSize = response.data.result.per_page;
          this.tancontent.tanfenye.tanlastpage = response.data.result.last_page;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row);  //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      ElMessageBox.confirm("确定删除吗？", "提示：", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "primary",
      })
        .then(() => {
          var arrdata = [];
          arrdata.push(rows[index].id);
          axios
            .post(
              "/admin/config/clientDisable",
              {
                client_ids: arrdata,
                status: "1",
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    // this.reload(); //刷新
                    rows.splice(index, 1);
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //table表单-分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/config/clientList",
          {
            shop_id: this.pagesdatas.agentid,
            client: this.pagesdatas.dvcid,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表单-分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/config/clientList",
          {
            shop_id: this.pagesdatas.agentid,
            client: this.pagesdatas.dvcid,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },

    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 携带参数跳转
        this.$router
          .push({ path: routeraddr, query: { clientnumber: rowdata.client } })
          .catch((error) => error);
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },

    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 1250) {
        this.searchwidth = this.sreenwidth * 0.9 + "px";
      } else {
        this.searchwidth = "1240px";
      }
    },
    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/config/clientList",
          {
            shop_id: data.agentid,
            client: data.dvcid,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //判断投放方式
        if (newdata[i].is_buy) {
          if (newdata[i].is_buy === 1) {
            newdata[i].is_buy = "自投";
          }
          if (newdata[i].is_buy === 2) {
            newdata[i].is_buy = "购买";
          }
          if (newdata[i].is_buy === 3) {
            newdata[i].is_buy = "租赁";
          }
        }
        //转换投放时间
        if (newdata[i].putintime) {
          if (newdata[i].putintime === 0 || newdata[i].putintime === null) {
            newdata[i].putintime = "无添加时间";
          } else {
            newdata[i].putintime = this.$utils
              .formatDate(newdata[i].putintime)
              .substr(0, 10);
          }
        } else {
          newdata[i].putintime = "无添加时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      (this.PageSize = successdata.per_page), //当前页面显示条数
        (this.lastpage = successdata.last_page);
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/config/clientList",
          {
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

/*table表中-带状态*/
/*.el-table :deep(.warning-row) {*/
/*  background-color: #F4FAFF !important;*/
/*}*/
</style>
