<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div
                style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        "
            >
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <div class="mybutton_box">
                            <div class="mybutton_box_btn">
                                <el-button type="primary" @click="changeDomeEvent()" v-if="mypowerlimits.sblb_plxgyjmb">批量修改佣金模板 </el-button>
                            </div>
                            <!-- 批量修改佣金 -->
                            <div style="margin: 0 10px 0 10px">
                                <mainimgesup
                                    ref="uploadfile"
                                    :myimgesup="myimgesup"
                                    :myimgesupisshow="myimgesupisshow"
                                    v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                    v-on:deluploadfile="(data) => deluploadfileEvent(data)"
                                    v-if="mypowerlimits.sblb_plxgyj"
                                >
                                </mainimgesup>
                            </div>
                            <div class="mybutton_box_btn">
                                <!--导出按钮-点击导出数据-->
                                <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mytable">
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop === 'status' ||
                                        mytabletitle.prop === 'level' ||
                                        mytabletitle.prop === 'ratio_type' ||
                                        mytabletitle.prop === 'client' ||
                                        mytabletitle.prop === 'company_name' ||
                                        mytabletitle.prop === 'one_shop_user' ||
                                        mytabletitle.prop === 'two_shop_user' ||
                                        mytabletitle.prop === 'three_shop_user' ||
                                        mytabletitle.prop === 'user_type'
                                "
                            >
                                <!--设备编号-样式等渲染-->
                                <div v-if="mytabletitle.prop == 'client'">
                                    <div
                                        :class="this.mypowerlimits.jqkz == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.jqkz == true ? routeraddrClick('/sharedvccontrol', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.client }}</span>
                                    </div>
                                </div>
                                <!--代理等级-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'level'">
                                    <div v-if="scope.row.level == 1">
                                        <!-- <span style="color: #465eff">一级代理</span> -->
                                        <span>一级代理</span>
                                    </div>
                                    <div v-if="scope.row.level == 2">
                                        <!-- <span style="color: #f56c6c">二级代理</span> -->
                                        <span>二级代理</span>
                                    </div>
                                    <div v-if="scope.row.level == 3">
                                        <!-- <span style="color: #333333">三级代理</span> -->
                                        <span>三级代理</span>
                                    </div>
                                </div>
                                <!--公司&代理商-样式等渲染-->
                                <div
                                    v-if="
                                        mytabletitle.prop === 'company_name' ||
                                            mytabletitle.prop === 'one_shop_user' ||
                                            mytabletitle.prop === 'two_shop_user' ||
                                            mytabletitle.prop === 'three_shop_user'
                                    "
                                >
                                    <div v-if="mytabletitle.prop === 'company_name'">
                                        <div v-if="scope.row.company_name.length > 0">
                                            <div v-for="(one, index) in scope.row.company_name" :key="index">
                                                <div>{{ one }}</div>
                                            </div>
                                        </div>
                                        <div v-else>---</div>
                                    </div>
                                    <div v-if="mytabletitle.prop === 'one_shop_user'">
                                        <div v-if="scope.row.one_shop_user.length > 0">
                                            <div v-for="(one, index) in scope.row.one_shop_user" :key="index">
                                                <div>{{ one }}</div>
                                            </div>
                                        </div>
                                        <div v-else>---</div>
                                    </div>
                                    <div v-if="mytabletitle.prop === 'two_shop_user'">
                                        <div v-if="scope.row.two_shop_user.length > 0">
                                            <div v-for="(one, index) in scope.row.two_shop_user" :key="index">
                                                <div>{{ one }}</div>
                                            </div>
                                        </div>
                                        <div v-else>---</div>
                                    </div>
                                    <div v-if="mytabletitle.prop === 'three_shop_user'">
                                        <div v-if="scope.row.three_shop_user.length > 0">
                                            <div v-for="(one, index) in scope.row.three_shop_user" :key="index">
                                                <div>{{ one }}</div>
                                            </div>
                                        </div>
                                        <div v-else>---</div>
                                    </div>
                                </div>
                                <!--佣金设置-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'ratio_type'">
                                    <div v-if="scope.row.ratio_type == 0">
                                        <span>默认佣金比例</span>
                                    </div>
                                    <div v-if="scope.row.ratio_type == 1">
                                        <span>单独佣金比例</span>
                                    </div>
                                </div>
                                <!--状态-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <el-button :type="scope.row.printstatus == 0 ? 'primary' : scope.row.printstatus == 1 ? 'success' : 'danger'" size="mini"
                                        >{{ this.$utils.printStatusData(this.myprintstatusoption, scope.row.printstatus != 0 ? scope.row.printstatus : '0') }}
                                    </el-button>
                                    <el-button :type="scope.row.is_online == 1 ? 'success' : 'danger'" size="mini">{{ scope.row.is_online == 1 ? '在线' : '离线' }}</el-button>
                                </div>
                                <!-- 类型 -->
                                <div v-if="mytabletitle.prop === 'user_type'">
                                    <span>{{ scope.row.user_type ? this.CSP_type.filter((n) => n.value == scope.row.user_type)[0]['label'] : '' }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-代理商列表-->
                                <div v-if="mainthreetableope === 2">
                                    <el-button type="primary" size="mini" @click="addTanClick(scope.row, (dialogVisible = true))" v-if="mypowerlimits.sblb_xgyj"
                                        >修改佣金</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!--弹出框-修改佣金-->
            <el-dialog v-model="dialogVisible" title="修改佣金" :width="this.searchwidth" :before-close="handleCloseSenior">
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="110px" class="demo-ruleForm">
                    <div class="tan-box-list">
                        <el-form-item :label="ruleform.name + ':'" v-for="(ruleform, index) in ruleForm.ratiodata" :key="index">
                            <el-form-item>
                                <el-radio-group v-model="ruleform.type">
                                    <el-radio label="0">默认佣金比例</el-radio>
                                    <el-radio label="1">单独佣金比例</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item :prop="'ratiodata.' + index + '.ratio'" :rules="rules.ratio">
                                <div class="tan_box">
                                    <el-input v-model="ruleform.shop_ratio" disabled v-if="ruleform.type == 0" />
                                    <el-input
                                        v-model="ruleform.ratio"
                                        clearable
                                        step="0.1"
                                        min="0"
                                        max="1"
                                        maxlength="5"
                                        v-if="ruleform.type == 1"
                                        style="margin: 0 10px 10px 0; max-width: 200px"
                                    />
                                    <el-input
                                        v-model="ruleform.remark"
                                        clearable
                                        placeholder="佣金比例备注"
                                        v-if="ruleform.type == 1 && index == 0"
                                        style="margin: 0 10px 10px 0; max-width: 200px"
                                    />
                                </div>
                            </el-form-item>
                        </el-form-item>
                        <el-form-item style="text-align: right">
                            <el-button @click="resetForm()">取消</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import mainimgesup from '@/components/Mainimgesup.vue'
import CPS from '@/assets/js/componentProperties' // 公共数据
import { ElMessage, ElNotification } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vdvclist',
    inject: ['reload'], //刷新引用
    data() {
        // 修改佣金弹窗-佣金比例-number校验
        var checkBigDay = (rule, value, callback) => {
            //校验判断
            if (!value) {
                return callback(new Error('佣金比例不能为空！'))
            }
            setTimeout(() => {
                // 当输入为整数的判断
                if (!Number.parseFloat(value)) {
                    callback(new Error('请输入小数'))
                } else {
                    if (value > 1) {
                        callback(new Error('佣金比例值需小于等于1'))
                    } else {
                        callback()
                    }
                }
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '合作伙伴'
                },
                {
                    name: '代理商管理'
                },
                {
                    name: '设备列表',
                    path: '/dvclist'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: true, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '180px', //input宽度
                    inputinterval: '10px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号：',
                            placeholder: '请输入设备编号', //此name为键值
                            name: 'client',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/getClientList',
                            inputval: { client: '' } //这里记录需要传的参数
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '代理商名：',
                            placeholder: '请输入代理商名称',
                            name: 'agentid', //此name为键值
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/shopUser/getShopList',
                            inputval: { name: '' } //这里记录需要传的参数
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '手机号：',
                            placeholder: '请输入手机号',
                            name: 'mobile', //此name为键值
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/shopUser/getShopList',
                            inputval: { username: '' } //这里记录需要传的参数
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '负责人：',
                            placeholder: '请输入负责人', //此name为键值
                            name: 'win_user_id',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Statistics/userList',
                            posttype: 'get',
                            inputval: { truename: '' } //这里记录需要传的参数
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '100px',
                    selectinterval: '10px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '类型：',
                            placeholder: '全部',
                            name: 'user_type',
                            value: '',
                            options: [
                                ...[
                                    {
                                        value: '0',
                                        label: '全部'
                                    }
                                ],
                                ...CPS.get('winuserinfo').type
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 2, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 90,
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'client',
                    label: '设备编号',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '当前代理商名',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'mobile',
                    label: '当前代理商手机号',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'level',
                    label: '当前代理商等级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'ratio_remark',
                    label: '一级佣金备注',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'user_type',
                    label: '类型',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'win_user_id',
                    label: '负责人',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'company_name',
                    label: '公司',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'one_shop_user',
                    label: '代理商家(一代)',
                    width: '190'
                },
                {
                    fixedstatu: false,
                    prop: 'two_shop_user',
                    label: '代理商家(二代)',
                    width: '190'
                },
                {
                    fixedstatu: false,
                    prop: 'three_shop_user',
                    label: '代理商家(三代)',
                    width: '190'
                },
                {
                    fixedstatu: false,
                    prop: 'address',
                    label: '设备地址',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'is_buy',
                    label: '投放方式',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'putintime',
                    label: '投放时间',
                    width: '110'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'ver',
                    label: '版本号',
                    width: '100'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //高级筛选
            myinputishow: true, //高级筛选-input单框
            myinput: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%',
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '一级佣金比例备注：',
                        placeholder: '请输入一级佣金比例备注',
                        name: 'ratio_remark',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '版本号：',
                        placeholder: '请输入版本号',
                        name: 'ver',
                        content: ''
                    }
                ]
            },
            myselectisshow: true, //高级筛选-select单框
            myselect: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '状态:',
                        placeholder: '全部',
                        name: 'is_online',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '在线'
                            },
                            {
                                value: '-1',
                                label: '离线'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '当前代理等级:',
                        placeholder: '全部',
                        name: 'level',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '一级代理'
                            },
                            {
                                value: '2',
                                label: '二级代理'
                            },
                            {
                                value: '3',
                                label: '三级代理'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '一级佣金方式:',
                        placeholder: '全部',
                        name: 'ratio_way',
                        value: '',
                        options: [
                            {
                                value: ' ',
                                label: '全部'
                            },
                            {
                                value: '0',
                                label: '默认佣金比例'
                            },
                            {
                                value: '1',
                                label: '单独佣金比例'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '类型：',
                        placeholder: '全部',
                        name: 'user_type',
                        value: '',
                        options: [
                            ...[
                                {
                                    value: '0',
                                    label: '全部'
                                }
                            ],
                            ...CPS.get('winuserinfo').type
                        ]
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '投放时间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'putintime',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },
            myselectcityisshow: true, //高级筛选-selectcity选框
            myselectcity: {
                selectwidth: '100%',
                select: {
                    ismust: false,
                    name: 'area_code',
                    valuesheng: '',
                    valueshi: '',
                    valuequ: '',
                    disshi: true,
                    disqu: true
                }
            },
            myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
            myinputonefullgao: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%', //input宽度
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '设备编号：',
                        placeholder: '请输入设备编号', //此name为键值
                        name: 'client',
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/admin/getClientList',
                        inputval: { client: '' } //这里记录需要传的参数
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '代理商名：',
                        placeholder: '请输入代理商名称',
                        name: 'agentid', //此name为键值
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/admin/shopUser/getShopList',
                        inputval: { name: '' } //这里记录需要传的参数
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '手机号：',
                        placeholder: '请输入手机号',
                        name: 'mobile', //此name为键值
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/admin/shopUser/getShopList',
                        inputval: { username: '' } //这里记录需要传的参数
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '负责人：',
                        placeholder: '请输入负责人', //此name为键值
                        name: 'win_user_id',
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/admin/Statistics/userList',
                        posttype: 'get',
                        inputval: { truename: '' } //这里记录需要传的参数
                    }
                ]
            },
            // 负责人类型
            CSP_type: CPS.get('winuserinfo').type,

            // 设备打印状态数据
            myprintstatusoption: CPS.get('printstatusoption').options,

            //上传-【批量修改佣金】-imgesuploade上传图片
            myimgesupisshow: true,
            //上传-【批量修改佣金】-批量修改佣金-文件
            myimgesup: {
                uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: '/admin/shopUser/importRatio', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                multiple: false, //上传多个文件
                title: '批量修改佣金', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_size: 'medium', //button-按钮大小，medium / small / mini
                title_plain: false, //button-按钮是否为线性（plain）
                isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
                tips: '', //提示&建议文字
                limit: '', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    files: {
                        size: 2000, //文件大小（单位kb）
                        type: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], //文件格式
                        typechart: 'Excel' //文件格式提示规则
                    }
                }
            },

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100, 1000],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',

            //弹窗相关数据
            dialogVisible: ref(false), //弹窗显示&隐藏
            //弹窗中-form表单
            ruleForm: {
                clientid: '',
                ratiodata: [
                    {
                        id: '',
                        level: '',
                        name: '',
                        ratio: '',
                        remark: '',
                        shop_ratio: '',
                        shopid: '',
                        status: '',
                        type: ''
                    }
                ]
            },
            //弹窗中-form表单-必填规则
            rules: {
                ratio_type: [
                    {
                        required: true,
                        message: '设备佣金方式为必填项',
                        trigger: 'blur'
                    }
                ],
                ratio: [
                    {
                        validator: checkBigDay,
                        trigger: 'blur'
                    }
                ]
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 【批量修改佣金模板】
        changeDomeEvent() {
            axios
                .post(
                    '/admin/shopUser/getTemplatePath',
                    {},
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        let url = response.data.result
                        window.open(url, '_blank')
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        // 【批量修改佣金】-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            console.log('当前获取的回传数据：' + JSON.stringify(data))
            let messages = ''
            if (data.msg.length > 0) {
                messages =
                    '<div><div>导入条数：' +
                    data.count +
                    '</div><div>成功条数：' +
                    data.success_count +
                    '</div><div>失败条数：' +
                    data.error_count +
                    '</div><div>失败原因：' +
                    data.msg +
                    '</div></div>'
            } else {
                messages = '<div><div>导入条数：' + data.count + '</div><div>成功条数：' + data.success_count + '</div><div>失败条数：' + data.error_count + '</div></div>'
            }
            // 通知
            ElNotification({
                title: '批量修改佣金-反馈',
                dangerouslyUseHTMLString: true,
                duration: 8000,
                message: messages,
                type: 'success'
            })
            // 删除upload列队中的数据
            this.$refs.uploadfile.handleRemove(data)
        },
        //table表单-点击单位格-带参跳转等
        handleOneData(row, column, cell, event) {
            // console.log(row); //获取当前行数据
            // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
            // console.log(event); //获取当前指针事件
            console.log(event.path[1].id) //获取当前指针所指向的单元格id值，配合标签的id使用
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shopUser/client',
                    {
                        client: this.pagesdatas.client,
                        mobile: this.pagesdatas.mobile,
                        shop_id: this.pagesdatas.agentid,
                        is_online: this.pagesdatas.is_online,
                        area_code: this.pagesdatas.area_code,
                        level: this.pagesdatas.level,
                        ratio_way: this.pagesdatas.ratio_way,
                        putintime: this.pagesdatas.putintime,
                        ver: this.pagesdatas.ver, // 版本号
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        page: 1,
                        page_size: val,
                        export: 0
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`)
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shopUser/client',
                    {
                        client: this.pagesdatas.client,
                        mobile: this.pagesdatas.mobile,
                        shop_id: this.pagesdatas.agentid,
                        is_online: this.pagesdatas.is_online,
                        area_code: this.pagesdatas.area_code,
                        level: this.pagesdatas.level,
                        ratio_way: this.pagesdatas.ratio_way,
                        putintime: this.pagesdatas.putintime,
                        ver: this.pagesdatas.ver, // 版本号
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        page: val,
                        page_size: this.PageSize,
                        export: 0
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row'
            }
            return ''
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置-[修改佣金]
        addTanClick(taninfo) {
            axios
                .post(
                    '/admin/shopUser/getRatio',
                    {
                        client_id: taninfo.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        this.ruleForm.clientid = taninfo.id
                        this.ruleForm.ratiodata = response.data.result
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 跳转-用户列表
                if (routeraddr == '/sharedvccontrol') {
                    console.log(rowdata)
                    this.$router.push({ path: routeraddr, query: { clientnumber: rowdata.client } }).catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shopUser/client',
                    {
                        client: this.pagesdatas.client,
                        mobile: this.pagesdatas.mobile,
                        shop_id: this.pagesdatas.agentid,
                        is_online: this.pagesdatas.is_online,
                        area_code: this.pagesdatas.area_code,
                        level: this.pagesdatas.level,
                        ratio_way: this.pagesdatas.ratio_way,
                        putintime: this.pagesdatas.putintime,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        ver: this.pagesdatas.ver, // 版本号
                        export: 1
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //弹出框-提交表单
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model
                    console.log('提交：' + JSON.stringify(getdatas))
                    let ratiodata = []
                    getdatas.ratiodata.forEach((element) => {
                        let data = {
                            shopid: element.shopid,
                            type: element.type,
                            ratio: element.ratio,
                            remark: element.remark
                        }
                        ratiodata.push(data)
                    })
                    axios
                        .post(
                            '/admin/shopUser/setRatio',
                            {
                                client_id: getdatas.clientid,
                                ratio_data: ratiodata
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: '处理成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.reload() //刷新
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //弹出框-取消表单
        resetForm() {
            this.ruleForm = {
                clientid: '',
                ratiodata: [
                    {
                        id: '',
                        level: '',
                        name: '',
                        ratio: '',
                        remark: '',
                        shop_ratio: '',
                        shopid: '',
                        status: '',
                        type: ''
                    }
                ]
            }
            this.dialogVisible = ref(false) //关闭弹窗
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
        searchFormEvent(data) {
            // console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
            //加载loading
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //判断-申请时间段状态
            var getputintime = null
            if (data.putintime.start == '') {
                getputintime = ''
            } else {
                if (data.putintime.end == '') {
                    getputintime = data.putintime.start + ' ~ ' + (data.putintime.start + 86400 - 1)
                } else {
                    getputintime = data.putintime.start + ' ~ ' + (data.putintime.end + 86400 - 1)
                }
            }
            this.pagesdatas.putintime = getputintime
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shopUser/client',
                    {
                        client: data.client,
                        mobile: data.mobile,
                        shop_id: data.agentid,
                        is_online: data.is_online,
                        area_code: data.area_code,
                        level: data.level,
                        ratio_way: data.ratio_way,
                        putintime: getputintime,
                        ver: data.ver, // 版本号
                        user_type: data.user_type,
                        win_user_id: data.win_user_id,
                        page: 1,
                        page_size: this.PageSize,
                        export: 0
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shopUser/client',
                    {
                        client: data.client,
                        mobile: data.mobile,
                        shop_id: data.agentid,
                        user_type: data.user_type,
                        win_user_id: data.win_user_id,
                        page: 1,
                        page_size: this.PageSize,
                        export: 0
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //判断投放方式
                if (newdata[i].is_buy) {
                    if (newdata[i].is_buy === 1) {
                        newdata[i].is_buy = '自投'
                    }
                    if (newdata[i].is_buy === 2) {
                        newdata[i].is_buy = '购买'
                    }
                    if (newdata[i].is_buy === 3) {
                        newdata[i].is_buy = '租赁'
                    }
                }

                //当前页面中拥有换行的字符串，根据不同的字段进行replace('\\n', '\n')替换！
                if (newdata[i].one_shop_user) {
                    //one_shop_user	代理商家（一代）
                    newdata[i].one_shop_user = newdata[i].one_shop_user.split('\\n')
                } else {
                    newdata[i].one_shop_user = []
                }
                if (newdata[i].two_shop_user) {
                    //two_shop_user 代理商家（二代）
                    newdata[i].two_shop_user = newdata[i].two_shop_user.split('\\n')
                } else {
                    newdata[i].two_shop_user = []
                }
                if (newdata[i].three_shop_user) {
                    //three_shop_user 代理商家（三代）
                    newdata[i].three_shop_user = newdata[i].three_shop_user.split('\\n')
                } else {
                    newdata[i].three_shop_user = []
                }
                if (newdata[i].company_name) {
                    //company_name 公司
                    newdata[i].company_name = newdata[i].company_name.split('\\n')
                } else {
                    newdata[i].company_name = []
                }
                //转换投放时间
                if (newdata[i].putintime) {
                    if (newdata[i].putintime === 0 || newdata[i].putintime === null) {
                        newdata[i].putintime = '无添加时间'
                    } else {
                        newdata[i].putintime = this.$utils.formatDate(newdata[i].putintime).substr(0, 10)
                    }
                } else {
                    newdata[i].putintime = '无添加时间'
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            var getroutedata = this.$route.query
            console.log('getroutedata is : ' + JSON.stringify(getroutedata))

            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize,
                export: 0
            }

            let routerinfo = this.$route.query
            if (routerinfo.name) {
                // 回显查询框条件-这里写死（待优化）
                this.maintwoforminfo.myinputonefull.input[1].content = routerinfo.name
                this.maintwoforminfo.myinputonefull.input[1].id = routerinfo.id
                //将筛选条件赋值给本页的pagesdatas存储，供分页使用
                this.pagesdatas.agentid = routerinfo.id
                this.pagesdatas.shop_id = routerinfo.id
            }
            if(routerinfo.client_id && routerinfo.client_name){
                this.maintwoforminfo.myinputonefull.input[0].content = routerinfo.client_name
                this.maintwoforminfo.myinputonefull.input[0].id = routerinfo.client_id
                this.pagesdatas.client = routerinfo.client_id
            }

            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .post('/admin/shopUser/client', this.pagesdatas, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })

        }
    },
    components: {
        mainone,
        maintwo,
        mainimgesup
    }
})
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mybutton_box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mybutton_box_btn :deep(.el-button) {
    padding: 8px 20px;
    height: 36px;
    min-height: 36px;
}

.tan_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tan-box-list {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 1% 0 0;
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}
</style>
