<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            :myselectcityleftisshow="maintwoforminfo.myselectcityleftisshow"
            :myselectcityleft="maintwoforminfo.myselectcityleft"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :sortable="mytabletitle.sortable"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop === 'status' ||
                                        mytabletitle.prop === 'id' ||
                                        mytabletitle.prop === 'two_num' ||
                                        mytabletitle.prop === 'client_num' ||
                                        mytabletitle.prop === 'order_num' ||
                                        mytabletitle.prop === 'order_count' ||
                                        mytabletitle.prop === 'level' ||
                                        mytabletitle.prop === 'three_num' ||
                                        mytabletitle.prop === 'user_type'
                                "
                            >
                                <!-- 状态-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <span>{{ scope.row.status == 1 ? '启用' : '禁用' }}</span>
                                </div>
                                <!-- id-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'id'">
                                    <div
                                        :class="this.mypowerlimits.dlslb == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.dlslb == true ? routeraddrClick('/agentlist', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.id }}</span>
                                    </div>
                                </div>
                                <!-- 代理等级-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'level'">
                                    <span>{{ scope.row.level == 1 ? '一级代理' : scope.row.level == 2 ? '二级代理' : '三级代理' }}</span>
                                </div>
                                <!-- 二级-代理数量 -->
                                <div v-if="mytabletitle.prop === 'two_num'">
                                    <div @click="routeraddrClick('/agentcensus', scope.row, 2)" :class="scope.row.level == 1 ? 'mytabletext' : ''">
                                        <span>{{ scope.row.two_num }}</span>
                                    </div>
                                </div>

                                <!-- 三级-代理数量 :class="scope.row.level == 2 ? 'mytabletext' : ''"-->
                                <div v-if="mytabletitle.prop === 'three_num'">
                                    <div @click="routeraddrClick('/agentcensus', scope.row, 3)" :class="scope.row.level != 3 ? 'mytabletext' : ''">
                                        <span>{{ scope.row.three_num }}</span>
                                    </div>
                                </div>

                                <!-- 设备数量-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'client_num'">
                                    <div
                                        :class="this.mypowerlimits.sbddtj == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.sbddtj == true ? routeraddrClick('/dvcordercensus', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.client_num }}</span>
                                    </div>
                                </div>

                                <!-- 订单金额-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'order_num'">
                                    <div
                                        :class="this.mypowerlimits.sbddtj == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.sbddtj == true ? routeraddrClick('/dvcordercensus', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.order_num }}</span>
                                    </div>
                                </div>

                                <!-- 订单数量-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'order_count'">
                                    <div
                                        :class="this.mypowerlimits.sbddtj == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.sbddtj == true ? routeraddrClick('/dvcordercensus', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.order_count }}</span>
                                    </div>
                                </div>
                                <!-- 类型 -->
                                <div v-if="mytabletitle.prop === 'user_type'">
                                    <span>{{ scope.row.user_type ? this.CSP_type.filter((n) => n.value == scope.row.user_type)[0]['label'] : '' }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import CPS from '@/assets/js/componentProperties' // 公共数据

export default defineComponent({
    name: 'vagentcensus',
    data() {
        return {
            mainonebread: [
                {
                    name: '统计'
                },
                {
                    name: '打印统计'
                },
                {
                    name: '代理商统计',
                    path: '/agentcensus',
                    icon: true
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: true, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '170px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '代理商名称/手机号',
                            name: 'keywords',
                            content: ''
                        }
                    ]
                },
                myselectcityleftisshow: true, //筛选查询-selectcity选框
                myselectcityleft: {
                    selectwidth: '100%',
                    select: {
                        ismust: false,
                        title: '选择区域：',
                        name: 'area_code',
                        area_code: '',
                        valuesheng: '',
                        valueshi: '',
                        valuequ: '',
                        disshi: true,
                        disqu: true
                    }
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '100px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '类型：',
                            placeholder: '全部',
                            name: 'user_type',
                            value: '',
                            options: [
                                ...[
                                    {
                                        value: '0',
                                        label: '全部'
                                    }
                                ],
                                ...CPS.get('winuserinfo').type
                            ]
                        }
                    ]
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '64px', //标题宽度
                    inputwidth: '160px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '负责人：',
                            placeholder: '请输入负责人', //此name为键值
                            name: 'win_user_id',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Statistics/userList',
                            posttype: 'get',
                            inputval: { truename: '' } //这里记录需要传的参数
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 150, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '代理商名称',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'user_type',
                    label: '类型',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'win_user_id_name',
                    label: '负责人',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'level',
                    label: '代理等级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'two_num',
                    label: '二级代理',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'three_num',
                    label: '三级代理',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client_num',
                    label: '设备数量',
                    width: '110',
                    sortable: true
                },
                {
                    fixedstatu: false,
                    prop: 'order_count',
                    label: '订单金额',
                    width: '110',
                    sortable: true
                },
                {
                    fixedstatu: false,
                    prop: 'order_num',
                    label: '订单数量',
                    width: '110',
                    sortable: true
                },
                {
                    fixedstatu: false,
                    prop: 'area_code_name',
                    label: '所属区域',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '添加时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //高级筛选
            myinputishow: true, //高级筛选-input单框
            myinput: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%',
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '输入搜索：',
                        placeholder: '代理商名称/手机号',
                        name: 'keywords',
                        content: ''
                    }
                ]
            },
            myselectisshow: true, //高级筛选-select单框
            myselect: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '状态:',
                        placeholder: '全部',
                        name: 'status',
                        value: '',
                        options: [
                            {
                                value: '0',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '启用'
                            },
                            {
                                value: '-1',
                                label: '禁止'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '类型：',
                        placeholder: '全部',
                        name: 'user_type',
                        value: '',
                        options: [
                            ...[
                                {
                                    value: '0',
                                    label: '全部'
                                }
                            ],
                            ...CPS.get('winuserinfo').type
                        ]
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '添加时间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'addtime',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '支付时间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'paytime',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },
            myselectcityisshow: true, //高级筛选-selectcity选框
            myselectcity: {
                selectwidth: '100%',
                select: {
                    ismust: false,
                    name: 'area_code',
                    valuesheng: '',
                    valueshi: '',
                    valuequ: '',
                    disshi: true,
                    disqu: true
                }
            },
            myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
            myinputonefullgao: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%', //input宽度
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '负责人：',
                        placeholder: '请输入负责人', //此name为键值
                        name: 'win_user_id',
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/admin/Statistics/userList',
                        posttype: 'get',
                        inputval: { truename: '' } //这里记录需要传的参数
                    }
                ]
            },
            // 负责人类型
            CSP_type: CPS.get('winuserinfo').type,

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {}
            }, //弹框

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            // console.log("this is:" + val);
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/shopOrder', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id,
                        keywords: this.pagesdatas.keywords,
                        status: this.pagesdatas.status,
                        area_code: this.pagesdatas.area_code,
                        addtime: this.pagesdatas.addtime,
                        paytime: this.pagesdatas.paytime,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        page: 1,
                        page_size: val
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/shopOrder', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id,
                        keywords: this.pagesdatas.keywords,
                        status: this.pagesdatas.status,
                        area_code: this.pagesdatas.area_code,
                        addtime: this.pagesdatas.addtime,
                        paytime: this.pagesdatas.paytime,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        page: val,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata, flag) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转-代理商列表
                if (routeraddr == '/agentlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.id }
                        })
                        .catch((error) => error)
                }
                // 跳转-代理商统计(本页)
                if (routeraddr == '/agentcensus') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.id, flag: flag }
                        })
                        .catch((error) => error)
                }
                // 跳转-设备订单统计
                if (routeraddr == '/dvcordercensus') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { name: rowdata.name }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            axios
                .get('/admin/Statistics/shopOrder', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id,
                        keywords: this.pagesdatas.keywords,
                        status: this.pagesdatas.status,
                        area_code: this.pagesdatas.area_code,
                        addtime: this.pagesdatas.addtime,
                        paytime: this.pagesdatas.paytime,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        export: '1'
                    }
                })
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
        searchFormEvent(data) {
            // console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
            //加载loading
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //判断-添加时间段状态
            var getaddtime = null
            if (data.addtime.start == '') {
                getaddtime = ''
            } else {
                if (data.addtime.end == '') {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.start + 86400 - 1)
                } else {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.end + 86400 - 1)
                }
            }
            //判断-支付时间段状态
            var getpaytime = null
            if (data.paytime.start == '') {
                getpaytime = ''
            } else {
                if (data.paytime.end == '') {
                    getpaytime = data.paytime.start + ' ~ ' + (data.paytime.start + 86400 - 1)
                } else {
                    getpaytime = data.paytime.start + ' ~ ' + (data.paytime.end + 86400 - 1)
                }
            }
            this.pagesdatas.addtime = getaddtime
            this.pagesdatas.paytime = getpaytime

            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/shopOrder', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id,
                        keywords: data.keywords,
                        status: data.status,
                        area_code: data.area_code,
                        addtime: getaddtime,
                        paytime: getpaytime,
                        user_type: data.user_type,
                        win_user_id: data.win_user_id,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/shopOrder', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        keywords: data.keywords,
                        area_code: data.area_code,
                        user_type: data.user_type,
                        win_user_id: data.win_user_id,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //转换添加时间
                if (newdata[i].addtime) {
                    if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                        newdata[i].addtime = '无添加时间'
                    } else {
                        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                    }
                } else {
                    newdata[i].addtime = '无添加时间'
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            //加载loading
            this.loading = true //通过Ajax向后台获取数据

            // 定义
            this.pagesdatas = {
                page_size: this.PageSize,
                page: 1
            }
            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query
            if (routerinfo.id) {
                this.pagesdatas = {
                    id: routerinfo.id,
                    page_size: this.PageSize,
                    page: 1
                }
                if (routerinfo.flag == 2) {
                    let getcontentdata = this.mainthreetabletitle.filter((n) => n.prop != 'two_num')
                    this.mainthreetabletitle = getcontentdata

                    // mainonebread头部-导航
                    let data = {
                        name: '二级代理商统计',
                        parameter: { id: routerinfo.id, flag: routerinfo.flag }
                    }
                    this.mainonebread.push(data)
                }
                if (routerinfo.flag == 3) {
                    let getcontentdata1 = this.mainthreetabletitle.filter((n) => n.prop != 'two_num')
                    let getcontentdata2 = getcontentdata1.filter((n) => n.prop != 'three_num')
                    this.mainthreetabletitle = getcontentdata2

                    // mainonebread头部-导航
                    let data = {
                        name: '三级代理商统计',
                        parameter: { id: routerinfo.id, flag: routerinfo.flag }
                    }
                    this.mainonebread.push(data)
                }
            }
            if (routerinfo.win_user_id) {
                this.pagesdatas['win_user_id'] = routerinfo.win_user_id

                this.maintwoforminfo.myinputonefull.input[0].id = routerinfo.win_user_id
                this.maintwoforminfo.myinputonefull.input[0].content = routerinfo.win_user_name
            }

            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/shopOrder', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: this.pagesdatas
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
