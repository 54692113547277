<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div class="box-list">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <el-button type="primary" plain @click="routeraddrClick('/setderivativesadd')"
              v-if="this.mypowerlimits.jrcpsz_xz">
              添加</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width"
              :min-width="mytabletitle.minwidth">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'uptime' ||
                mytabletitle.prop === 'lease_day_unit' ||
                mytabletitle.prop === 'valid_area_name' ||
                mytabletitle.prop === 'lease_price_back_money' ||
                mytabletitle.prop === 'sign_client' ||
                mytabletitle.prop === 'sign_user'
              ">
                <!-- 租赁/返还-渲染数据 -->
                <div v-if="mytabletitle.prop === 'lease_price_back_money'">
                  <span> {{ scope.row.lease_price }} / </span><span style="color: var(--el-color-success);">{{
                      scope.row.back_money
                  }}</span>
                </div>
                <!-- 签约设备-渲染数据 -->
                <div v-if="mytabletitle.prop === 'sign_client'">
                  <span style="color: var(--el-color-primary);"> {{ scope.row.use_client }} </span><span> / {{
                      scope.row.all_client
                  }}</span>
                </div>
                <!-- 签约人-渲染数据 -->
                <div v-if="mytabletitle.prop === 'sign_user'">
                  <span style="color: var(--el-color-primary);"> {{ scope.row.use_lease }} </span><span> / {{
                      scope.row.all_lease
                  }}</span>
                </div>
                <!-- 状态-渲染数据 -->
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.switchstatus === 4" id="switch">
                    <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭" :active-value="1"
                      :inactive-value="-1" active-color="#13ce66" inactive-color="#cbcbcb"
                      @click="this.mypowerlimits.jrcpsz_qy == true ? changeStatus(scope.row) : ''"
                      :disabled="!this.mypowerlimits.jrcpsz_qy">
                    </el-switch>
                  </div>
                </div>
                <!-- 时间-渲染数据 -->
                <div v-if="mytabletitle.prop === 'uptime'">
                  {{ scope.row.uptime !== 0 && scope.row.uptime !== null
                      ? this.$utils.formatDate(scope.row.uptime)
                      : ""
                  }}
                </div>
                <!-- 返还周期-渲染 -->
                <div v-if="mytabletitle.prop === 'lease_day_unit'">
                  <span>{{ scope.row.lease_day }}</span>
                  <span>{{ scope.row.day_unit == 1 ? ' 天' : (scope.row.day_unit == 2 ? ' 周' : ' 月') }}</span>
                </div>
                <!-- 生效区域-渲染 -->
                <div v-if="mytabletitle.prop === 'valid_area_name'" class="div-overflow-title">
                  <div class="area-box">
                    <div class="box-table-addr">
                      <span :ref="'btnShowNum' + scope.$index">
                        <span>{{ scope.row.valid_area_name }}</span>
                      </span>
                    </div>
                    <div>
                      <el-popover placement="bottom-end" trigger="hover" title="生效地域" width="220px">
                        <template #reference>
                          <div class="my-icon-hover">
                            <i class="el-icon-info"></i>
                          </div>
                        </template>
                        <template #default>
                          <div style="margin-top: 20px">
                            <span>{{ scope.row.valid_area_name }}</span>
                          </div>
                        </template>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-运营-小程序广告位-->
                <div v-if="mainthreetableope === 22">
                  <el-button v-if="this.mypowerlimits.jrcpsz_sz == true" type="primary" size="mini"
                    @click="routeraddrClick('/setderivatives', scope.row)">设置
                  </el-button>
                  <el-button v-if="this.mypowerlimits.jrcpsz_czjl == true" type="primary" size="mini"
                    @click="routeraddrClick('/setderivativeslog', scope.row)">操作记录
                  </el-button>
                  <el-button v-if="scope.row.all_lease == 0" @click="delEvent(scope.row)" type="danger" size="mini"
                    icon="el-icon-delete"></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { ElMessage, ElMessageBox } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vsetderivativeslist",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "金融产品",
        },
        {
          name: "设置",
        },
        {
          name: "金融产品设置",
        },
      ], // 当前页-【面包屑导航】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 22, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 180, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "id",
          label: "产品ID",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "name",
          label: "产品名称",
          width: "",
          minwidth: '200',
        },
        {
          fixedstatu: false,
          prop: "printname",
          label: "设备类型",
          width: "",
          minwidth: '200',
        },
        {
          fixedstatu: false,
          prop: "lease_price_back_money",
          label: "租赁/返还",
          width: "",
          minwidth: '160'
        },
        {
          fixedstatu: false,
          prop: "lease_day_unit",
          label: "返还周期",
          width: "",
          minwidth: '100'
        },
        // {
        //   fixedstatu: false,
        //   prop: "valid_area_name",
        //   label: "生效地域",
        //   width: "260",
        //   minwidth: '180',
        // },
        {
          fixedstatu: false,
          prop: "sign_client",
          label: "签约设备",
          width: "",
          minwidth: '160'
        },
        {
          fixedstatu: false,
          prop: "sign_user",
          label: "签约人",
          width: "",
          minwidth: '160'
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "uptime",
          label: "更新时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      myuserinfo: JSON.parse(localStorage.getItem("userinfo")), //获取存储的用户信息
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单中-状态改变-开启/关闭
    changeStatus(data) {
      axios
        .post(
          "/admin/Lease/configSwitch",
          {
            id: data.id,
            printname: data.printname,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            ElMessage({
              type: "success",
              message: response.data.message ? response.data.message : '切换成功',
            });
          } else {
            // 切换状态失败-复原状态
            if (data.status == 1) {
              data.status = -1;
            } else {
              data.status = 1;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 删除
    delEvent(data) {
      console.log(data.id)
      ElMessageBox.confirm("确认删除【" + data.name + "】吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            .post(
              "/admin/Lease/configDelete",
              {
                id: data.id,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code == 0) {
                ElMessage({
                  message: response.data.message != '' ? response.data.message : '删除成功！',
                  type: "success",
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              ElMessage({
                message: error,
                type: "warning",
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消删除",
          });
        });
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      // console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/lease/configList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      // console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/lease/configList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, data) {
      if (data) {
        this.$router
          .push({ path: routeraddr, query: { id: data.id, printname: data.printname } })
          .catch((error) => error);
      } else {
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },

    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //渲染-状态-该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },

  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .get("/admin/lease/configList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            page: 1,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
});
</script>

<style scoped>
.box-list {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  line-height: 40px;
  padding: 0 20px;
}

/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-table-addr {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.box-table-addr:hover {
  color: var(--el-color-primary);
  cursor: pointer;
}

/* .div-overflow-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.area-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.my-icon-hover:hover {
  cursor: pointer;
  color: var(--el-color-primary);
}
</style>