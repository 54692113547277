<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'status'">
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.textstatus">
                    <span>{{ scope.row.textstatus }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-运营-幸运大抽奖-->
                <div>
                  <div v-if="scope.row.send_status === 1">
                    <el-button type="primary" size="mini">待完善</el-button>
                  </div>
                  <div v-if="scope.row.send_status === 2">
                    <el-button type="primary" size="mini" @click="fahuoEvent(scope.row)">待发货</el-button>
                  </div>
                  <div v-if="scope.row.send_status === 3">
                    <el-button type="primary" size="mini">已发货</el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" :layout="Layout"
              :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vluckydrawrecord",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "运营",
        },
        {
          name: "营销玩法",
        },
        {
          name: "幸运大抽奖",
          path: "/luckydraw",
        },
        {
          name: "抽奖记录",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "微信名/手机号/ID",
              name: "keywords",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "中奖状态：",
              placeholder: "全部",
              name: "status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "未中奖",
                  value: "-1",
                },
                {
                  label: "已中奖",
                  value: "1",
                },
              ],
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 42, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 100, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "uid",
          label: "用户ID",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "nickname",
          label: "微信名称",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "mobile",
          label: "手机号",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "抽奖时间",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "中奖状态",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "name",
          label: "奖品名称",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "uptime",
          label: "中奖时间",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "address",
          label: "收货信息",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      // rows.splice(index, 1)
      ElMessageBox.confirm(
        "确定删除该条 ' " + rows[index].title + " ' 活动吗?",
        "提示：",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          axios
            .post(
              "/admin/luckyDraw/delete",
              {
                id: rows[index].id,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "删除成功",
                });
                rows.splice(index, 1);
              }
            })
            .catch(function (error) {
              // 请求失败处理
              ElMessage({
                type: "error",
                message: "删除失败！" + error,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消删除",
          });
        });
    },
    //table表-操作中-终止按钮（函数）
    stopRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      // rows.splice(index, 1)
      ElMessageBox.confirm(
        "确定终止该条 ' " + rows[index].title + " ' 活动吗?",
        "提示：",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          axios
            .post(
              "/admin/luckyDraw/stop",
              {
                id: rows[index].id,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "删除成功",
                });
                rows.splice(index, 1);
              }
            })
            .catch(function (error) {
              // 请求失败处理
              ElMessage({
                type: "error",
                message: "删除失败！" + error,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消删除",
          });
        });
    },

    // 点击【代发货】按钮
    fahuoEvent(rowdata) {
      axios
        .post(
          "/admin/LuckyDraw/send",
          {
            id: rowdata.id,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          ElMessage({
            type: "success",
            message: response.data.message,
            duration: 1000,
            onClose: () => {
              this.reload(); //刷新
            },
          });
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/luckyDraw/log",
          {
            draw_id: this.pagesdatas.draw_id,
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          //   this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / this.PageSize == this.lastpage) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage += 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/luckyDraw/log",
          {
            draw_id: this.pagesdatas.draw_id,
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          //   this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / val == this.PageSize) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage = 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/admin/luckyDraw/log",
          {
            draw_id: this.pagesdatas.draw_id,
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          ElMessage({
            type: "success",
            message: response.data.message,
          });
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/luckyDraw/log",
          {
            draw_id: this.pagesdatas.draw_id,
            keywords: data.keywords,
            status: data.status,
            page_size: this.PageSize,
            page: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata, flag) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //渲染-状态
        if (newdata[i].status === 1) {
          newdata[i].textstatus = "已中奖";
        } else {
          newdata[i].textstatus = "未中奖";
        }

        //转换-抽奖时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无抽奖时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无抽奖时间";
        }

        //转换-中奖时间
        if (newdata[i].uptime) {
          if (newdata[i].uptime === 0 || newdata[i].uptime === null) {
            newdata[i].uptime = "";
          } else {
            newdata[i].uptime = this.$utils.formatDate(newdata[i].uptime);
          }
        } else {
          newdata[i].uptime = "";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      //   this.totalCount = successdata.total;
      //   this.currentpage = successdata.current_page; //当前页面页数
      //   this.lastpage = successdata.last_page;
      this.loading = false;

      // 数据小于当前页数时判断
      if (newdata.length < this.PageSize) {
        if (newdata.length != 0 && this.currentPage == 1) {
          this.totalCount = this.PageSize - 1;
        } else {
          if (newdata.length == 0) {
            // 按照常理：为0会减1页，由于双向绑定问题一直请求服务器，因此先注释，待后续优化
            // this.totalCount = this.PageSize * (this.currentPage - 1)
            this.totalCount = this.PageSize * this.currentPage;
          } else {
            this.totalCount = this.PageSize * this.currentPage;
            this.lastpage = this.currentPage;
          }
        }
      } else {
        if (this.totalCount < this.PageSize) {
          // 重设置-初始值
          this.totalCount = 1000;
          this.Layout = "sizes, prev, pager, next, jumper";
        } else {
          if (flag == 1) {
            this.currentPage = 1;
          }
        }
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 定义
      this.pagesdatas = {
        page: 1,
        page_size: this.PageSize,
      };

      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.id) {
        this.pagesdatas = {
          draw_id: routerinfo.id,
          page_size: this.PageSize,
          page: 1,
        };
      }

      //通过Ajax向后台获取数据
      axios
        .post("/admin/luckyDraw/log", this.pagesdatas, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>