<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo" :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone" :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft" :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow" :myinputonefull="maintwoforminfo.myinputonefull" v-on:getsearchinfo="getSeachinfoEvent"></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="default" plain @click="downloadTemplate" v-if="mypowerlimits.dlszdsblb_pldrzdsb">模板下载</el-button>
                        <el-button type="primary" @click="uploadTemplate" v-if="mypowerlimits.dlszdsblb_pldrzdsb">批量导入指定设备</el-button>
                        <el-button type="primary" @click="addAgentFun" v-if="mypowerlimits.dlszdsblb_tjyjdls">添加一级代理商</el-button>
                        <el-button type="warning" @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable" :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)" style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中...">
                        <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
                            <template #default="{ row }" v-if="mytabletitle.prop === 'status' || mytabletitle.prop === 'client_num' || mytabletitle.prop === 'user_type' ">
                                <!-- 类型 -->
                                <div v-if="mytabletitle.prop === 'user_type'">
                                    <div>
                                        {{ row.user_type == 1 ? '代理' : '直营' }}
                                    </div>
                                </div>
                                <!--状态-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <el-switch v-model="row.status" 
                                        :active-value="1" 
                                        :inactive-value="-1" 
                                        @click="mypowerlimits.dlszdsblb_ztkg == true ? recordStatus(row, value) : ''"
                                        :disabled="!mypowerlimits.dlszdsblb_ztkg"/>
                                </div>
                                <!-- 指定设备 -->
                                <div v-if="mytabletitle.prop === 'client_num'">
                                    <div v-if="row.client_num > 0">
                                        <el-button type="text" size="mini" @click="$router.push({path: '/deviceappointdvc', query: { active: 2, agent_id: row.shop_user_id, agent_name: row.name } })">{{ row.client_num }}</el-button>
                                    </div>
                                    <div v-else style="padding: 0 8px">
                                        {{ row.client_num }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column :fixed="'right'" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="{ row }">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-意向客户列表-->
                                <div>
                                    <el-button type="danger" size="mini" @click="recordRemove(row, index)" v-if="mypowerlimits.dlszdsblb_sc">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount"> </el-pagination>
                    </div>
                </div>
            </div>

            <!--弹出框-处理-->
            <el-dialog v-model="dialogVisible" title="更换设备" :width="this.searchwidth" :before-close="handleCloseSenior">
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                    <div class="tan-box">
                        <div style="margin-left: 24px; margin-bottom: 20px; color: red">
                            <span>注：必须选择该代理商下的直属设备</span>
                        </div>
                        <el-form-item label="分佣设备：" prop="cid">
                            <el-select v-model="ruleForm.cid" placeholder="请选择">
                                <el-option :label="item.client" :value="item.id" v-for="(item, index) in this.client_options" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item style="text-align: right">
                            <el-button @click="resetForm('ruleForm')">取消</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </el-dialog>
        </div>
        <addAgent ref="addAgent" :ModelValue="ruleForm.shopUserIds" PostUrl="/admin/clientWith/shopUserAddIndex" :SearchModel="agent_forminfo" :TableTitle="agent_tabletitle" @modelValue="modelValueEvent"></addAgent>
    </div>
</template>

<script>
import mainone from '@/components/Mainone';
import maintwo from '@/components/Maintwo';
import addAgent from './components/addAgent';
import { ElMessage, ElMessageBox } from 'element-plus';
import axios from 'axios';
import { defineComponent } from 'vue';

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authentication: localStorage.getItem('token'),
    },
});

export default defineComponent({
    name: 'vintention',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '合作伙伴',
                },
                {
                    name: '指定设备日志',
                },
                {
                    name: '介绍人列表',
                },
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '20px', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: '0',
                                },
                                {
                                    label: '开启',
                                    value: '1',
                                },
                                {
                                    label: '关闭',
                                    value: '-1',
                                },
                            ],
                        },
                    ],
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '120px', //标题宽度
                    inputwidth: '220px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '指定一级代理商：',
                            placeholder: '请输入代理商名称',
                            name: 'shop_user_id', //此name为键值
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/shopUser/getShopList',
                            inputval: { name: '' }, //这里记录需要传的参数
                        },
                    ],
                },
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 6, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 180,
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'shop_user_id',
                    label: 'ID',
                    width: '100',
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '指定一级代理商',
                    width: '240',
                },
                {
                    fixedstatu: false,
                    prop: 'win_user_id',
                    label: '负责人',
                    width: '',
                },
                {
                    fixedstatu: false,
                    prop: 'user_type',
                    label: '类型',
                    width: '',
                },
                {
                    fixedstatu: false,
                    prop: 'client_num',
                    label: '指定设备',
                    width: '100',
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号/账号',
                    width: '240',
                },

                {
                    fixedstatu: false,
                    prop: 'ratio',
                    label: '佣金比例',
                    width: '150',
                },
                {
                    fixedstatu: false,
                    prop: 'area_code',
                    label: '省，市，区/县',
                    width: '',
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: '120',
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            // 代理商-弹窗-搜索框
            agent_forminfo: {
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '100px', //标题宽度
                    inputwidth: '220px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '代理商名称：',
                            placeholder: '请输入代理商名称',
                            name: 'shop_user_id', //此name为键值
                            content: '',
                            id: 'shop_user_id', //此id为给后台的id值
                            url: '/admin/shopUser/getShopList',
                            inputval: { name: '' }, //这里记录需要传的参数
                        },
                    ],
                },
            },
            // 代理商-弹窗-table表头
            agent_tabletitle: [
                {
                    fixedstatu: false,
                    prop: 'id',
                    label: 'ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '代理商名称',
                    width: '130'
                },
                {
                    fixedstatu: false,
                    prop: 'user_type',
                    label: '类型',
                    width: '130'
                },
                {
                    fixedstatu: false,
                    prop: 'win_user_id',
                    label: '负责人',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号/账号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'level',
                    label: '代理等级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'area_code',
                    label: '省，市，区/县',
                    width: ''
                },
            ],

            //弹窗相关数据
            dialogVisible: false, //弹窗显示&隐藏
            ruleForm: {
                new_shop_id: '',
                cid: '',
                shopUserIds: {},
            }, //弹窗中-form表单
            rules: {
                cid: [
                    {
                        required: true,
                        message: '分佣设备为必填项',
                        trigger: 'blur',
                    },
                ],
            }, //弹窗中-form表单-必填规则
            client_options: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
        };
    },
    methods: {
        // 清除介绍人 确认清除[ ' + data.name + ']的介绍人[' + data.username + ']吗?', '清除介绍人
        delEvent(data) {
            ElMessageBox.confirm('清除介绍人后，不在产生分佣，且状态变成【已清除】!', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/shopUser/pullClear',
                            {
                                new_shop_id: data.new_shop_id,
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token'),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    message: response.data.message != '' ? response.data.message : '清除成功！',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage);
                                    },
                                });
                            }
                        })
                        .catch(function (error) {
                            ElMessage({
                                message: error,
                                type: 'warning',
                            });
                        });
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消',
                    });
                });
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            this.PageSize = val;
            this.currentPage = 1;

            this.pagesdatas = {
                ...this.pagesdatas,
                page: 1,
                page_size: val,
            };

            this.postAxiosEvent(this.pagesdatas);
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val;

            this.pagesdatas = {
                ...this.pagesdatas,
                page: val,
                page_size: this.PageSize,
            };

            this.postAxiosEvent(this.pagesdatas);
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    '/admin/clientWith/shopUserIndex',
                    {
                        ...this.pagesdatas,
                        page: undefined,
                        page_size: undefined,
                        export: 1,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token'),
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data.message);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // 修改状态
        async recordStatus(row, value) {
            try {
                const { data } = await http.post('admin/clientWith/shopUserStatusChange', { shop_user_id: row.shop_user_id });
                if(data.code !== 0) {
                    ElMessage({ type: 'error', message: data.message, duration: 3000 });
                } else {
                    ElMessage({ type: 'success', message: data.message, duration: 3000 });
                }
            } catch (e) {
                row.status = value === 1 ? -1 : 1;
            }
        },
        // 删除记录
        async recordRemove(row) {
            await ElMessageBox.confirm('确认删除该指定设备吗?', '删除指定设备', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' });
            const {data} = await http.post('admin/clientWith/shopUserDel', { shop_user_id: row.shop_user_id });
            if(data.code !== 0) {
                ElMessage({ type: 'error', message: data.message, duration: 3000 });
            } else {
                ElMessage({ type: 'success', message: data.message, duration: 3000 });
            }
            this.handleCurrentChange(this.currentPage);
        },

        handleCloseSenior() {
            this.resetForm('ruleForm');
        },
        //弹出框-取消表单
        resetForm(formName) {
            this.dialogVisible = false; //关闭弹窗
            this.$refs[formName].resetFields();
            this.ruleForm = {
                new_shop_id: '',
                cid: '',
            };
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = {
                ...data,
                page: 1,
                page_size: this.PageSize,
            };
            this.postAxiosEvent(this.pagesdatas);
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data;
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata;
            this.totalCount = successdata.total;
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;
        },

        // 获取接口
        async postAxiosEvent(params) {
            this.loading = true;
            try {
                const { data } = await http.post('admin/clientWith/shopUserIndex', params);
                this.myaxiosfunc(data.result);
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        },
        // 下载模板
        async downloadTemplate() {
            const { data } = await http.post('admin/clientWith/shopUserTemplate')
            const link = document.createElement('a')
            link.href = data.result
            link.click()
        },
        // 批量导入指定设备
        uploadTemplate() {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.xlsx,.xls';
            input.click();
            input.onchange = async (e) => {
                const file = e.target.files[0];
                if (!file) return
                if (file.size > 1024 * 1024 * 10) {
                    ElMessage({ type: 'warning', message: '文件大小不能超过10M', duration: 3000 })
                    return
                }
                const formData = new FormData();
                formData.append('file', file);
                const { data } = await http.post('admin/clientWith/shopUserImport', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                const { count, success_count, error_count, msg } = data.result;
                let message = `共${count}条记录`;
                if (success_count > 0) message += `<br />导入成功${success_count}条`;
                if (error_count > 0) message += `<br />导入失败${error_count}条`;
                if (msg.length > 0) message += `<br />失败原因：<br />${msg.join('<br />')}`;
                ElMessageBox.alert(message, '提示', { type: 'info', dangerouslyUseHTMLString: true });
                this.postAxiosEvent(this.pagesdatas);
            };
        },

        async modelValueEvent(data) {
            let shop_user_ids = Object.keys(data).join(',')
            // this.ruleForm.shopUserIds = data
            const { data: res } = await http.post('admin/clientWith/shopUserAdd', { shop_user_ids })
            if(res.code == 0) {
                this.ruleForm.shopUserIds = []
                ElMessage({ type: 'success', message: '操作成功', duration: 3000 })
                this.postAxiosEvent(this.pagesdatas)
            } else {
                ElMessage({ type: 'error', message: res.message, duration: 3000 })
            }
        },

        addAgentFun() {
            this.$refs.addAgent.clickAddEvent();
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false;
            this.$router.push('/').catch((error) => error);
        } else {
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize,
            };

            this.postAxiosEvent(this.pagesdatas);
        }
    },
    components: {
        mainone,
        maintwo,
        addAgent,
    },
});
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.tan-box {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}
</style>
