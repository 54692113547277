<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>

        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <el-button type="primary" plain @click="routeraddrClick('/goodslistadd')" v-if="this.mypowerlimits.gglb_tj">添加 </el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                    >
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'images' || mytabletitle.prop === 'status'">
                                <div v-if="mytabletitle.prop === 'images'">
                                    <el-image
                                        class="imagesshow"
                                        :src="scope.row.images"
                                        :preview-src-list="[scope.row.images]"
                                        fit="cover"
                                        @error="(data) => errorImagesEvent(data, scope.row)"
                                    >
                                    </el-image>
                                </div>
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div id="switch">
                                        <el-switch
                                            v-model="scope.row.status"
                                            active-text="上架"
                                            inactive-text="下架"
                                            :active-value="1"
                                            :inactive-value="2"
                                            active-color="#13ce66"
                                            @click="changeStatus(scope.row)"
                                            inactive-color="#cbcbcb"
                                        >
                                        </el-switch>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <div>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/goodslisteidt', scope.row)">编辑</el-button>
                                    <el-button @click.prevent="deleteRow(scope.$index, mainthreetableData)" type="danger" size="mini" icon="el-icon-delete"></el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vgoodslist',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '商城管理'
                },
                {
                    name: '商品管理'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '240px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '商品名称：',
                            placeholder: '请输入商品名称',
                            name: 'keywords',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '上下架：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: ''
                                },
                                {
                                    label: '上架',
                                    value: '1'
                                },
                                {
                                    label: '下架',
                                    value: '2'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 23, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'id',
                    label: 'ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'sort',
                    label: '排序',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '商品名称',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'images',
                    label: '图片',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status_name',
                    label: '总价格(元)',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'guest_name',
                    label: '送总打印金(元)',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'guest_mobile',
                    label: '运费(元)',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'final_price',
                    label: '总库存剩余',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'advert_time',
                    label: '总累计销量',
                    width: '250'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '上下架',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 0, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            Layout: 'sizes, prev, pager, next, jumper',

            //分页
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            mydisabled: false
        }
    },
    methods: {
        // 加载图片失败时，显示的默认图片
        errorImagesEvent(e, rowdata) {
            rowdata.images = require('@/assets/imgs/defaultimegs/delimage.jpg')
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            ElMessageBox.confirm("确定删除该条 ' " + rows[index].name + " ' 数据吗?", '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/ClientAdvert/del123',
                            {
                                id: rows[index].id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            // 请求失败处理
                            ElMessage({
                                type: 'error',
                                message: '删除失败！' + error
                            })
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除'
                    })
                })
        },
        // table表单-状态改变-status值通过后台返回
        changeStatus(data) {
            console.log('切换状态的data值：' + data.status)
            axios
                .post(
                    '/admin/shopUser/disable123',
                    {
                        id: data.id,
                        status: data.status
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = 2
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            let datas = {
                keywords: this.pagesdatas.keywords,
                status: this.pagesdatas.status,
                page: 1,
                page_size: val
            }
            this.postAxiosEvent(datas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`)
            this.currentPage = val

            let datas = {
                keywords: this.pagesdatas.keywords,
                status: this.pagesdatas.status,
                page: val,
                page_size: this.PageSize
            }
            this.postAxiosEvent(datas)
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 携带参数跳转
                this.$router.push({ path: routeraddr, query: { id: rowdata.id } }).catch((error) => error)
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas(tableData) {
            console.log(tableData)
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用

            let datas = {
                keywords: data.keywords,
                status: data.status,
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(datas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            console.log(successdata)
            // var newdata = successdata.data

            this.mainthreetableData = [
                {
                    id: '1',
                    name: '测试商品名称',
                    images: ''
                }
            ]
            this.totalCount = 1
            this.currentpage = 1
            this.lastpage = 1

            // this.mainthreetableData = newdata
            // this.totalCount = successdata.total
            // this.currentpage = successdata.current_page //当前页面页数
            // this.lastpage = successdata.last_page
            this.loading = false
        },
        // 请求接口
        postAxiosEvent(data) {
            //加载loading
            this.loading = true
            axios
                .post('/admin/ActivityGoods/index', data, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            //通过Ajax向后台获取数据
            let datas = {
                keywords: this.$route.query.adcode,
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(datas)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
