<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div class="hand-box-list">
            <div class="hand-box-list-box">
                <el-tabs
                    v-model="activeName"
                    class="demo-tabs"
                    @tab-click="
                        (tab, event) => {
                            handleTabsClick(tab, event)
                        }
                    "
                >
                    <el-tab-pane label="日统计" name="1"> </el-tab-pane>
                    <el-tab-pane label="月统计" name="2"> </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!--table表单-->
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <div>
                <selectOne
                    ref="RefselectOne"
                    v-if="activeName == 1"
                    :ShowBackDatas="show_back_datas"
                    v-on:getsearchinfo="(data) => getSeachinfoEvent(data, activeName)"
                    v-on:getdatasearchinfo="changeDataSearchEvent"
                />
                <selectTwo
                    ref="RefselectTwo"
                    v-if="activeName == 2"
                    :ShowBackDatas="show_back_datas"
                    v-on:getsearchinfo="(data) => getSeachinfoEvent(data, activeName)"
                    v-on:getdatasearchinfo="changeDataSearchEvent"
                />
            </div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px; line-height: 20px; padding-top: 10px">
                        <div><span>如果设备更换代理商，列表只统计更换后的数据。并且会保留之前代理商的下的设备数据，删粉只会在原来的代理商删减。</span></div>
                        <div><span>根据日期查询，有数据的设备，若查询范围没，没有数据则查询不到。</span></div>
                    </div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()" v-if="isshowExport">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <el-table-column :fixed="'left'" type="index" width="65" label="序号" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :min-width="mytabletitle.minwidth"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="main_titles.includes(mytabletitle.prop) || mytabletitle.prop === 'client' || mytabletitle.prop === 'putintime'">
                                <!-- 设备编号 -->
                                <div v-if="mytabletitle.prop === 'client'">
                                    <div :class="scope.row.is_now_agent == 2 ? 'client-class-box' : ''">
                                        <span>{{ scope.row.client }}</span>
                                    </div>
                                    <div v-if="scope.row.is_now_agent == 2" style="color: red; font-size: 12px"><span>(已删除)</span></div>
                                </div>
                                <!-- 投放时间 -->
                                <div v-if="mytabletitle.prop === 'putintime'">
                                    <span>{{ scope.row.putintime ? this.$utils.formatDate(scope.row.putintime) : '' }}</span>
                                </div>
                                <!-- 动态-时间 -->
                                <div v-if="main_titles.includes(mytabletitle.prop)">
                                    <!-- <span>{{ scope.row.sum_care }}</span> -->
                                    <div>
                                        <span>{{ scope.row[mytabletitle.prop].split('\r\n')[0] }}</span>
                                    </div>
                                    <div style="color: red;">
                                        <span>{{ scope.row[mytabletitle.prop].split('\r\n')[1] }}</span>
                                    </div>
                                    <div style="color: rgb(63, 63, 63);">
                                        <span>{{ scope.row[mytabletitle.prop].split('\r\n')[2] }}</span>
                                    </div>
                                    <div style="color: rgb(135, 60, 255);">
                                        <span>{{ scope.row[mytabletitle.prop].split('\r\n')[3] }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :fixed="'right'" label="合计" min-width="140" prop="sum_care" id="operation">
                            <template #default="scope">
                                <div>
                                    <!-- <span>{{ scope.row.sum_care }}</span> -->
                                    <div>
                                        <span>{{ scope.row['sum_care'].split('\r\n')[0] }}</span>
                                    </div>
                                    <div style="color: red;">
                                        <span>{{ scope.row['sum_care'].split('\r\n')[1] }}</span>
                                    </div>
                                    <div style="color: rgb(63, 63, 63);">
                                        <span>{{ scope.row['sum_care'].split('\r\n')[2] }}</span>
                                    </div>
                                    <div style="color: rgb(135, 60, 255);">
                                        <span>{{ scope.row['sum_care'].split('\r\n')[3] }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import selectOne from './selectOne.vue'
import selectTwo from './selectTwo.vue'
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vdvcfanscount',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '统计'
                },
                {
                    name: '企微统计'
                },
                {
                    name: '设备统计',
                    path: '/dvcfanscount',
                    icon: true
                }
            ], // 当前页-【面包屑导航】
            activeName: '1',
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 27, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            main_titles: [], //动态表头title

            searchwidth: '1100px',
            dialogVisible: false,
            ClientInfo: { client: '', addtime: '', acount: '', yaolan: '', chengzhang: '', wending: '', list: { time: [], money: [] } },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            // 是否显示导出按钮
            isshowExport: false,

            SelectTimesCustom: '',
            show_back_datas: {
                id: '',
                content: ''
            }
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 切换tabs
        handleTabsClick(tab) {
            let activename = tab.props.name

            let postdata = { page: 1, page_size: this.PageSize }

            let routerinfo = this.$route.query
            if (routerinfo && routerinfo.id && routerinfo.name) {
                this.show_back_datas.id = routerinfo.id
                this.show_back_datas.content = routerinfo.name
                postdata['agentid'] = routerinfo.id
            }

            // 兼容-切换-置空时间
            this.SelectTimesCustom = ''

            // 获取数据
            this.getSeachinfoEvent(postdata, activename)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            let postdata = {
                cid: this.pagesdatas.cid,
                agentid: this.pagesdatas.agentid,
                run_type: this.pagesdatas.run_type,
                addtime: this.pagesdatas.addtime && this.pagesdatas.addtime.length > 0 ? this.pagesdatas.addtime.join(' ~ ') : '',
                type: this.activeName,
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(postdata)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            let postdata = {
                cid: this.pagesdatas.cid,
                agentid: this.pagesdatas.agentid,
                run_type: this.pagesdatas.run_type,
                addtime: this.pagesdatas.addtime && this.pagesdatas.addtime.length > 0 ? this.pagesdatas.addtime.join(' ~ ') : '',
                type: this.activeName,
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(postdata)
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata, activename) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转-编辑
                if (routeraddr === '/minipluswecomedit') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { activename: activename, id: rowdata.id, back_data: JSON.stringify(rowdata) }
                        })
                        .catch((error) => error)
                }
            } else {
                if (activename) {
                    // 跳转-添加
                    if (routeraddr === '/minipluswecomadd') {
                        this.$router
                            .push({
                                path: routeraddr,
                                query: { activename: activename }
                            })
                            .catch((error) => error)
                    }
                } else {
                    //仅跳转
                    this.$router.push(routeraddr).catch((error) => error)
                }
            }
        },

        //导出数据
        exportDatas() {
            axios
                .post(
                    '/admin/cashflow/companyWechatClient',
                    {
                        cid: this.pagesdatas.cid,
                        agentid: this.pagesdatas.agentid,
                        run_type: this.pagesdatas.run_type,
                        addtime: this.pagesdatas.addtime && this.pagesdatas.addtime.length > 0 ? this.pagesdatas.addtime.join(' ~ ') : '',
                        type: this.activeName,
                        export: '1'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        //提交等相关event事件函数
        // Maintwo插槽时间范围数据
        changeDataSearchEvent(data) {
            this.SelectTimesCustom = data
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data, activeName) {
            // console.log('点击查询获取的data数组：', data)
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用

            this.pagesdatas.addtime = this.SelectTimesCustom.length > 0 ? this.SelectTimesCustom : []

            let postdata = {
                cid: data.cid,
                agentid: data.agentid,
                run_type: data.run_type,
                addtime: this.SelectTimesCustom && this.SelectTimesCustom.length > 0 ? this.SelectTimesCustom.join(' ~ ') : '',
                type: activeName,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(postdata)
        },
        // 获取数据Axios
        postAxiosEvent(postdata) {
            //加载loading
            this.loading = true
            axios
                .post('/admin/cashflow/companyWechatClient', postdata, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    let datas = response.data.result

                    // 判断是否显示导出
                    this.isshowExport = datas.tableexport

                    // 获取动态表头
                    if (datas.tabletitle) {
                        this.main_titles = Object.keys(datas.tabletitle)

                        let title_key = Object.keys(datas.tabletitle).sort()
                        let title_active = title_key.map((n) => {
                            return {
                                fixedstatu: false,
                                prop: n,
                                label: datas.tabletitle[n],
                                minwidth: '120'
                            }
                        })
                        let title_guding = ['client:设备编号', 'name:一级代理商', 'putintime:投放时间', 'address:设备地址', 'run_type:运营状态']
                        let title_guding_active = title_guding.map((n) => {
                            let [key, chart] = n.split(':')
                            return {
                                fixedstatu: key == 'client' ? 'left' : false,
                                prop: key,
                                label: chart,
                                minwidth: key == 'client' ? '100' : '150'
                            }
                        })
                        this.mainthreetabletitle = [...title_guding_active, ...title_active]
                    }

                    if (this.mainthreetabletitle.length > 0) {
                        // 获取数据
                        this.myaxiosfunc(datas)
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // 切换默认tab
            let tab = { props: { name: this.activeName } }
            this.handleTabsClick(tab)
        }
    },
    components: {
        mainone,
        selectOne,
        selectTwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}
.tool-tip {
    max-width: 400px;
}

.hand-box-list {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
}

.hand-box-list-box {
    height: 40px;
    padding: 10px 20px;
}

.client-class-box {
    text-decoration: line-through;
    text-decoration-color: red;
}

.tan-search-class {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}
</style>
