<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-意向客户列表-->
                <div v-if="mainthreetableope === 6">
                  <el-button type="primary" size="mini" @click="addTanClick(scope.row, (dialogVisible = true))"
                    v-if="mypowerlimits.yxkhlb_cl">处理</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-处理-->
      <el-dialog v-model="dialogVisible" title="处理" :width="this.searchwidth" :before-close="handleCloseSenior">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
          <div style="
              max-height: 450px;
              overflow: hidden;
              overflow-y: visible;
              margin: 20px 5% 0 0;
            ">
            <el-form-item label="请选择：" prop="status">
              <el-radio-group v-model="ruleForm.status">
                <el-radio :label="1">有意向</el-radio>
                <el-radio :label="2">已下单</el-radio>
                <el-radio :label="3">无意向</el-radio>
                <el-radio :label="4">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input v-model="ruleForm.remark" :autosize="{ minRows: 2 }" type="textarea" placeholder="请填写备注">
              </el-input>
            </el-form-item>
            <el-form-item style="text-align: right">
              <el-button @click="resetForm()">取消</el-button>
              <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
            </el-form-item>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vintention",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "合作伙伴",
        },
        {
          name: "代理商管理",
        },
        {
          name: "意向客户列表",
          path: "/intention",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "手机号",
              name: "mobile",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "20px", //框右侧距离
          selectwidth: "200px",
          selectinterval: "", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "状态：",
              placeholder: "全部",
              name: "status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "0",
                },
                {
                  label: "未处理",
                  value: "-1",
                },
                {
                  label: "有意向",
                  value: "1",
                },
                {
                  label: "已下单",
                  value: "2",
                },
                {
                  label: "无意向",
                  value: "3",
                },
                {
                  label: "其他",
                  value: "4",
                },
              ],
            },
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: "申请时间段：",
              // type: "date", //时间框显示类型-(无该字段，默认为datetime)
              placeholder: {
                placeholderstart: "请选择开始时间",
                placeholderend: "请选择结束时间",
              },
              name: "addtime",
              value: {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
              },
              rangeseparator: "至", //分隔符
              selectmaxwidth: "100%", //时间段-最大宽度
              marginright: "0", //与右侧距离
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 6, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 80,
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "id",
          label: "ID",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "name",
          label: "姓名",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "phone",
          label: "手机号/账号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "address",
          label: "地区",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "textstatus",
          label: "状态",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "申请时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "truename",
          label: "处理人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "uptime",
          label: "处理时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "remark",
          label: "备注",
          width: "200",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",

      //弹窗相关数据
      dialogVisible: ref(false), //弹窗显示&隐藏
      ruleForm: {
        id: "", //当前行意向客户
        status: 1, //当前行意向状态
        remark: "",
      }, //弹窗中-form表单
      rules: {
        status: [
          {
            required: true,
            message: "意向状态为必填项",
            trigger: "blur",
          },
        ],
      }, //弹窗中-form表单-必填规则

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  setup() { },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      // console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用

      //获取所点击按钮的id值-并根据不同的id值进行跳转&传值等
      if (event.path[1].id) {
        //由操作-合作-公司列表-向-公司关联设备页面传值
        if (event.path[1].id === "companydvcid") {
          this.$router
            .push({
              path: "/companydvclist",
              query: { id: row.id, name: row.name },
            })
            .catch((error) => error);
        }
        //由操作-合作-设备合伙人列表-向-合伙人关联设备页面传值
        if (event.path[1].id === "partnerdvcid") {
          this.$router
            .push({ path: "/partnerdvclist", query: { id: row.id } })
            .catch((error) => error);
        }
      }

      // console.log("当前的column.property 是：" + column.property);
      if (column.property === "secondagent") {
        //跳转二级代理
        this.$router.push("/agentcensustwo").catch((error) => error);
      }
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/shopUser/joinUs",
          {
            mobile: this.pagesdatas.mobile,
            status: this.pagesdatas.status,
            addtime: this.pagesdatas.addtime,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/shopUser/joinUs",
          {
            mobile: this.pagesdatas.mobile,
            status: this.pagesdatas.status,
            addtime: this.pagesdatas.addtime,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      this.ruleForm.id = taninfo.id;
      this.ruleForm.status = taninfo.status;
      this.ruleForm.remark = taninfo.remark;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      this.$router.push(routeraddr).catch((error) => error);
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/admin/shopUser/joinUs",
          {
            mobile: this.pagesdatas.mobile,
            status: this.pagesdatas.status,
            addtime: this.pagesdatas.addtime,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);
     
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //弹出框-提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var getdatas = this.$refs[formName].model;
          axios
            .post(
              "/admin/shopUser/dealWithJoin",
              {
                id: getdatas.id,
                status: getdatas.status,
                remark: getdatas.remark,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = ref(false); //关闭弹窗
                ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-取消表单
    resetForm() {
      this.dialogVisible = ref(false); //关闭弹窗
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-申请时间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime = data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime = data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.addtime = getaddtime; //将重组的time存储到pagesdatas中，供分页使用
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/shopUser/joinUs",
          {
            mobile: data.mobile,
            status: data.status,
            addtime: getaddtime,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //渲染-状态
        if (newdata[i].status === -1) {
          newdata[i].textstatus = "未处理";
        } else {
          if (newdata[i].status === 1) {
            newdata[i].textstatus = "有意向";
          } else {
            if (newdata[i].status === 2) {
              newdata[i].textstatus = "已下单";
            } else {
              if (newdata[i].status === 3) {
                newdata[i].textstatus = "无意向";
              } else {
                if (newdata[i].status === 4) {
                  newdata[i].textstatus = "其他";
                }
              }
            }
          }
        }

        //转换申请时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无申请时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无申请时间";
        }

        //转换处理时间
        if (newdata[i].uptime) {
          if (newdata[i].uptime === 0 || newdata[i].uptime === null) {
            newdata[i].uptime = "无处理时间";
          } else {
            newdata[i].uptime = this.$utils.formatDate(newdata[i].uptime);
          }
        } else {
          newdata[i].uptime = "无处理时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/shopUser/joinUs",
          {
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>