<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'images'">
                <div v-if="mytabletitle.prop === 'images'">
                  <div v-if="scope.row.images">
                    <el-image class="imagesshow" :src="scope.row.images" :preview-src-list="[scope.row.images]"
                      fit="cover"></el-image>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-售后-意见反馈-->
                <el-button type="primary" size="mini" @click="routeraddrClick('/feedbackopinioninfo', scope.row)">查看详情
                </el-button>
                <el-button type="primary" size="mini" v-if="scope.row.solve_status == 2 && this.mypowerlimits.yjfk_cl"
                  @click="
                    chuliTanClick(scope.row, (dialogVisible = true)),
                    resetFormSenior('seniorForm')
                  ">处理</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog v-model="dialogVisible" title="处理" :width="searchwidth" :before-close="handleCloseSenior"
        style="margin-top: 200px">
        <el-form ref="seniorForm" :model="seniorform" :rules="rules" label-width="130px" style="padding-right: 20px">
          <!--以下是左侧字-->
          <el-form-item label="选择处理方式：" prop="solve_status">
            <el-radio-group v-model="seniorform.solve_status">
              <el-radio label="1">已解决</el-radio>
              <el-radio label="-1">未解决</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="原因：" prop="solve_remark">
            <el-input v-model="seniorform.solve_remark" type="textarea"></el-input>
          </el-form-item>
          <div style="text-align: right">
            <el-button @click="resetFormSenior('seniorForm', (dialogVisible = false))">取消</el-button>
            <el-button type="primary" @click="onSubmitSenior('seniorForm')">确定</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vfeedbackopinion",
  inject: ["reload"],
  data() {
    return {
      mainonebread: [
        {
          name: "售后",
        },
        {
          name: "反馈信息",
        },
        {
          name: "意见反馈",
          path: "/feedbackopinion",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "处理状态：",
              placeholder: "全部",
              name: "solve_status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "待处理",
                  value: "2",
                },
                {
                  label: "已解决",
                  value: "1",
                },
                {
                  label: "未解决",
                  value: "-1",
                },
              ],
            },
            {
              // 与小程序的反馈类型对应
              selectisshow: true,
              ismust: false,
              title: "反馈类型：",
              placeholder: "全部",
              name: "type",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "文库相关",
                  value: "1",
                },
                {
                  label: "商品相关",
                  value: "2",
                },
                {
                  label: "物流状况",
                  value: "3",
                },
                {
                  label: "客户服务",
                  value: "4",
                },
                {
                  label: "优惠活动",
                  value: "5",
                },
                {
                  label: "产品体验",
                  value: "6",
                },
                {
                  label: "产品功能",
                  value: "7",
                },
                {
                  label: "其他",
                  value: "8",
                },
              ],
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "来源：",
              placeholder: "全部",
              name: "thirdparty",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 35, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "questionid",
          label: "ID",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "question",
          label: "问题描述",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "feedbacktype",
          label: "反馈类型",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "handlestatus",
          label: "处理状态",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "feedbacker",
          label: "反馈者",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "linkphone",
          label: "联系方式",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "createtime",
          label: "创建时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      getdatas: {
        status: null,
      }, //接口获取的总数据
      //弹框-form表单数据
      seniorform: {
        id: "",
        solve_status: "-1",
        solve_remark: "",
      },
      rules: {
        solve_status: [
          {
            required: true,
            message: "处理方式为必选项",
            trigger: "blur",
          },
        ],
        solve_remark: [
          {
            required: true,
            message: "原因为必填项",
            trigger: "blur",
          },
        ],
      },
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  setup() {
    const dialogVisible = ref(false);
    const handleCloseSenior = (done) => {
      done();
    };

    return {
      dialogVisible,
      handleCloseSenior,
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //点击按钮-弹出弹框
    chuliTanClick(taninfo) {
      this.seniorform.id = taninfo.id;
    },
    //弹出框-提交表单
    onSubmitSenior(formName) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/admin/Service/feedDeal",
              {
                id: getdatas.id,
                solve_status: getdatas.solve_status,
                solve_remark: getdatas.solve_remark,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = ref(false); //关闭弹窗
                ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-取消
    resetFormSenior(formName) {
      this.$refs[formName].resetFields();
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Service/feedList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            thirdparty: this.pagesdatas.thirdparty,
            solve_status: this.pagesdatas.solve_status,
            type: this.pagesdatas.type,
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      // console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Service/feedList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            thirdparty: this.pagesdatas.thirdparty,
            solve_status: this.pagesdatas.solve_status,
            type: this.pagesdatas.type,
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 跳转-反馈详情
        if (routeraddr == "/feedbackopinioninfo") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                id: rowdata.id,
              },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Service/feedList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            solve_status: data.solve_status,
            type: data.type,
            thirdparty: data.thirdparty,
            page: 1,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        newdata[i].questionid = newdata[i].id;
        newdata[i].question = newdata[i].content;
        // 反馈类型-渲染
        if (newdata[i].type) {
          this.maintwoforminfo.selectleft.select[1].options.forEach(
            (element) => {
              if (element.value == newdata[i].type) {
                newdata[i].feedbacktype = element.label;
              }
            }
          );
        }
        newdata[i].handlestatus = newdata[i].solve_status_name;
        newdata[i].feedbacker = newdata[i].nickname;
        newdata[i].linkphone = newdata[i].mobile;
        newdata[i].createtime = this.$utils.formatDate(newdata[i].addtime);
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 定义
      this.pagesdatas = {
        thirdparty: "1",
        page: 1,
        page_size: this.PageSize,
      };

      //通过Ajax向后台获取数据
      axios
        .get("/admin/Service/feedList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: this.pagesdatas,
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>