<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'extend_no' ||
                mytabletitle.prop === 'trade_no'
              ">
                <!-- 推广编号-渲染数据 -->
                <div v-if="mytabletitle.prop === 'extend_no'">
                  <div>
                    <span :class="this.mypowerlimits.ggtgfa == true ? 'mytabletext' : ''"
                      @click="this.mypowerlimits.ggtgfa == true ? routeraddrClick('/advertisersspread', scope.row) : ''">
                      {{ scope.row.extend_no }}
                    </span>
                  </div>
                </div>
                <!-- 订单编号-渲染数据 -->
                <div v-if="mytabletitle.prop === 'trade_no'">
                  <div>
                    <span :class="this.mypowerlimits.ddlb == true ? 'mytabletext' : ''"
                      @click="this.mypowerlimits.ddlb == true ? routeraddrClick('/orderlistinfo', scope.row) : ''">
                      {{ scope.row.trade_no }}
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vprintwriteoff",
  data() {
    return {
      mainonebread: [
        {
          name: "订单",
        },
        {
          name: "广告主管理",
        },
        {
          name: "打印核销记录",
          path: "/printwriteoff",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "推广方式：",
              placeholder: "全部",
              name: "type",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "优惠券",
                  value: "1",
                },
                {
                  label: "宣传页",
                  value: "2",
                },
              ],
            },
            {
              selectisshow: true,
              ismust: false,
              title: "状态：",
              placeholder: "全部",
              name: "is_check",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "已核销",
                  value: "1",
                },
                {
                  label: "未核销",
                  value: "2",
                },
                {
                  label: "已退款",
                  value: "3",
                },
              ],
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "platform",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 21, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "ad_no",
          label: "广告券码",
          width: "140",
        },
        {
          fixedstatu: false,
          prop: "extend_no",
          label: "推广编号",
          width: "250",
        },
        {
          fixedstatu: false,
          prop: "trade_no",
          label: "订单编号",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "uid",
          label: "用户ID",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "nickname",
          label: "用户昵称",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "type",
          label: "推广方式",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "is_check",
          label: "状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "print_money",
          label: "打印花费",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "check_money",
          label: "核销花费",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "print_time",
          label: "打印时间",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "check_time",
          label: "核销时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "订单编号：",
            placeholder: "请输入订单编号",
            name: "trade_no",
            content: "",
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "推广编号：",
            placeholder: "请输入推广编号",
            name: "extend_no",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "推广方式:",
            placeholder: "全部",
            name: "type",
            value: "",
            options: [
              {
                label: "全部",
                value: "",
              },
              {
                label: "优惠券",
                value: "1",
              },
              {
                label: "宣传页",
                value: "2",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "状态:",
            placeholder: "全部",
            name: "is_check",
            value: "",
            options: [
              {
                value: "",
                label: "全部",
              },
              {
                label: "已核销",
                value: "1",
              },
              {
                label: "未核销",
                value: "2",
              },
              {
                label: "已退款",
                value: "3",
              },
            ],
          },
        ],
      },
      myselecttopisshow: true, //高级筛选-select单框-放input框之前
      myselecttop: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "平台来源：",
            placeholder: "全部",
            name: "platform",
            value: "1", //显示值，初始状况，显示值与默认值需相同
            valueisshow: true,
            valuedefault: "1", //设置默认值
            options: componentProperties.get('thirdpartyoption').options,
          },
        ],
      },

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      // console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/checkIndex",
          {
            platform: this.pagesdatas.platform,
            trade_no: this.pagesdatas.trade_no,
            extend_no: this.pagesdatas.extend_no,
            type: this.pagesdatas.type,
            is_check: this.pagesdatas.is_check,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/checkIndex",
          {
            platform: this.pagesdatas.platform,
            trade_no: this.pagesdatas.trade_no,
            extend_no: this.pagesdatas.extend_no,
            type: this.pagesdatas.type,
            is_check: this.pagesdatas.is_check,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      // 携带参数跳转
      if (rowdata) {
        // 跳转-广告推广方案
        if (routeraddr == "/advertisersspread") {
          this.$router
            .push({ path: routeraddr, query: { extend_no: rowdata.extend_no } })
            .catch((error) => error);
        }
        // 跳转-订单详情
        if (routeraddr == "/orderlistinfo") {
          this.$router
            .push({ path: routeraddr, query: { orderno: rowdata.trade_no } })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/checkIndex",
          {
            platform: data.platform,
            trade_no: data.trade_no,
            extend_no: data.extend_no,
            type: data.type,
            is_check: data.is_check,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/checkIndex",
          {
            platform: data.platform,
            is_check: data.is_check,
            type: data.type,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
        //转换打印时间
        if (newdata[i].print_time) {
          if (newdata[i].print_time === 0 || newdata[i].print_time === null) {
            newdata[i].print_time = "无打印时间";
          } else {
            newdata[i].print_time = this.$utils.formatDate(
              newdata[i].print_time
            );
          }
        } else {
          newdata[i].print_time = "无打印时间";
        }
        //转换核销时间
        if (newdata[i].check_time) {
          if (newdata[i].check_time === 0 || newdata[i].check_time === null) {
            newdata[i].check_time = "";
          } else {
            newdata[i].check_time = this.$utils.formatDate(
              newdata[i].check_time
            );
          }
        } else {
          newdata[i].check_time = "";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 定义
      this.pagesdatas = {
        platform: 1,
        page_size: this.PageSize,
        page: 1,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.extend_no) {
        this.pagesdatas = {
          extend_no: routerinfo.extend_no,
          platform: routerinfo.thirdparty,
          page_size: this.PageSize,
          page: 1,
        };
        if (routerinfo.is_check) {
          this.pagesdatas.is_check = routerinfo.is_check;
          this.maintwoforminfo.selectleft.select[1].value = routerinfo.is_check;
        }
      }

      //通过Ajax向后台获取数据
      axios
        .post("/admin/Advertiser/checkIndex", this.pagesdatas, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>