<template>
  <!--本页流程使用说明：-->
  <!--1、在template中添加以子页面命名的过度组件，eg、usertest文件，引用组件的文件名也为usertest,便于后期维护！！！-->
  <!--2、在setup中，添加组件判断-->
  <!--3、对应的getData.myisshow为该组件的显示条件-->
  <!--4、getspecialinfo为子页面传回的数据，并通过getSpecialinfoEvent函数接收！-->
  <div>
    <vuserlist v-if="getData.myisshow === 1" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vuserlist>
    <vmemberplusbuy v-if="getData.myisshow === 100" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberplusbuy>
    <vmemberpluscash v-if="getData.myisshow === 101" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberpluscash>
    <vmemberdvcbuy v-if="getData.myisshow === 102" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberdvcbuy>
    <vyingbinew v-if="getData.myisshow === 103" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vyingbinew>
    <vyingbiconsume v-if="getData.myisshow === 104" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vyingbiconsume>
    <vdvclist v-if="getData.myisshow === 107" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvclist>
    <vintention v-if="getData.myisshow === 108" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vintention>
    <vpartnerlist v-if="getData.myisshow === 109" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpartnerlist>
    <vcontractlist v-if="getData.myisshow === 110" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcontractlist>
    <vdvcflowlist v-if="getData.myisshow === 111" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcflowlist>
    <vpartnercash v-if="getData.myisshow === 112" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpartnercash>
    <vsharedvcmap v-if="getData.myisshow === 113" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcmap>
    <vsharedvcexfactory v-if="getData.myisshow === 114" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcexfactory>
    <vsharedvcback v-if="getData.myisshow === 115" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcback>
    <vsharedvcinstall v-if="getData.myisshow === 116" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcinstall>
    <vsharedvccontrolfrp v-if="getData.myisshow === 211" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvccontrolfrp>
    <vprintresources v-if="getData.myisshow === 120" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vprintresources>
    <vfileclassify v-if="getData.myisshow === 121" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfileclassify>
    <vlabelmanage v-if="getData.myisshow === 122" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlabelmanage>
    <vcurriculumclassify v-if="getData.myisshow === 123" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcurriculumclassify>
    <vcoursewareclassify v-if="getData.myisshow === 124" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoursewareclassify>
    <vcoursewarelist v-if="getData.myisshow === 125" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoursewarelist>
    <vcurriculumlist v-if="getData.myisshow === 126" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcurriculumlist>
    <vorderlist v-if="getData.myisshow === 127" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vorderlist>
    <vrefundapplication v-if="getData.myisshow === 128" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrefundapplication>
    <vcurriculumorder v-if="getData.myisshow === 129" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcurriculumorder>
    <vlibraryorderlist v-if="getData.myisshow === 130" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlibraryorderlist>
    <vadvertiserslist v-if="getData.myisshow === 131" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vadvertiserslist>
    <vadvertisersspread v-if="getData.myisshow === 132" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vadvertisersspread>
    <vprintwriteoff v-if="getData.myisshow === 133" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vprintwriteoff>
    <vrecagent v-if="getData.myisshow === 134" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrecagent>
    <ventrynote v-if="getData.myisshow === 135" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></ventrynote>
    <vpostingdetails v-if="getData.myisshow === 136" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpostingdetails>
    <vstatementuser v-if="getData.myisshow === 137" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vstatementuser>
    <vstatementagent v-if="getData.myisshow === 138" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vstatementagent>
    <vstatementpartner v-if="getData.myisshow === 139" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vstatementpartner>
    <vbalancetransfer v-if="getData.myisshow === 140" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbalancetransfer>
    <vbalanceredpacket v-if="getData.myisshow === 141" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbalanceredpacket>
    <vredpacketdetailed v-if="getData.myisshow === 142" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vredpacketdetailed>
    <vbondapplyback v-if="getData.myisshow === 143" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbondapplyback>
    <vbonddetailed v-if="getData.myisshow === 144" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbonddetailed>
    <vwithdrawalrecord v-if="getData.myisshow === 145" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vwithdrawalrecord>
    <vwithdrawalremind v-if="getData.myisshow === 146" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vwithdrawalremind>
    <vfeedbackopinion v-if="getData.myisshow === 147" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfeedbackopinion>
    <vworkorderlist v-if="getData.myisshow === 148" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vworkorderlist>
    <vlibraryorder v-if="getData.myisshow === 149" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlibraryorder>
    <vinvitemember v-if="getData.myisshow === 150" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vinvitemember>
    <vdvcprint v-if="getData.myisshow === 151" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcprint>
    <vdepartment v-if="getData.myisshow === 152" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdepartment>
    <vpower v-if="getData.myisshow === 153" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpower>
    <vuser v-if="getData.myisshow === 154" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vuser>
    <vsaleuser v-if="getData.myisshow === 155" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsaleuser>
    <vloginlog v-if="getData.myisshow === 156" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vloginlog>
    <vappletadvert v-if="getData.myisshow === 157" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vappletadvert>
    <vadvertlist v-if="getData.myisshow === 158" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vadvertlist>
    <vadvertexamine v-if="getData.myisshow === 159" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vadvertexamine>
    <vadvertappeal v-if="getData.myisshow === 160" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vadvertappeal>
    <vcoupon v-if="getData.myisshow === 161" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoupon>
    <vluckydraw v-if="getData.myisshow === 162" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vluckydraw>
    <vphonecard v-if="getData.myisshow === 163" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vphonecard>
    <vordergoods v-if="getData.myisshow === 165" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vordergoods>
    <vbossintcus v-if="getData.myisshow === 164" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbossintcus>
    <vcommonproblem v-if="getData.myisshow === 166" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcommonproblem>
    <vdvcordercensus v-if="getData.myisshow === 167" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcordercensus>
    <vdvcorderranking v-if="getData.myisshow === 168" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcorderranking>
    <vaftersaleflow v-if="getData.myisshow === 169" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vaftersaleflow>
    <vsaleflow v-if="getData.myisshow === 170" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsaleflow>
    <vuserinfolist v-if="getData.myisshow === 171" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vuserinfolist>
    <vagentlistedit v-if="getData.myisshow === 175" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentlistedit>
    <vaccountset v-if="getData.myisshow === 176" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vaccountset>
    <vplusset v-if="getData.myisshow === 177" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vplusset>
    <vplussetdisable v-if="getData.myisshow === 178" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vplussetdisable>
    <vdvcplusset v-if="getData.myisshow === 179" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcplusset>
    <vresourcesexamine v-if="getData.myisshow === 181" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vresourcesexamine>
    <vresourcesedit v-if="getData.myisshow === 182" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vresourcesedit>
    <vprintresourcesaddmore v-if="getData.myisshow === 183" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vprintresourcesaddmore>
    <vfileclassifyaddlast v-if="getData.myisshow === 184" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfileclassifyaddlast>
    <vappletadvertadd v-if="getData.myisshow === 185" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vappletadvertadd>
    <vappletadvertedit v-if="getData.myisshow === 186" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vappletadvertedit>
    <vadvertlistadd v-if="getData.myisshow === 187" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vadvertlistadd>
    <vagentlistinfo v-if="getData.myisshow === 188" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentlistinfo>
    <vpartnerdvclist v-if="getData.myisshow === 189" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpartnerdvclist>
    <vdvcflowlistadd v-if="getData.myisshow === 190" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcflowlistadd>
    <vcontractlistadd v-if="getData.myisshow === 191" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcontractlistadd>
    <vplusinvite v-if="getData.myisshow === 192" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vplusinvite>
    <vaccount v-if="getData.myisshow === 201" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vaccount>
    <voverview v-if="getData.myisshow === 202" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></voverview>
    <vfooter v-if="getData.myisshow === 204" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfooter>
    <vfooteradd v-if="getData.myisshow === 205" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfooteradd>
    <vhome v-if="getData.myisshow === 206" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vhome>
    <vstyle v-if="getData.myisshow === 207" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vstyle>
    <vpowerset v-if="getData.myisshow === 208" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpowerset>
    <vdvcordercensusdetail v-if="getData.myisshow === 210" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcordercensusdetail>
    <vexportlist v-if="getData.myisshow === 213" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vexportlist>
    <vmemberinvitestate v-if="getData.myisshow === 214" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberinvitestate>
    <vmemberinviterecord v-if="getData.myisshow === 215" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberinviterecord>
    <vmemberinvitegift v-if="getData.myisshow === 216" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberinvitegift>
    <vsharedvccontrol v-if="getData.myisshow === 2" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvccontrol>
    <vsharedvccontroledit v-if="getData.myisshow === 203" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvccontroledit>
    <vsharedvccontrolseelog v-if="getData.myisshow === 217" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvccontrolseelog>
    <vdvcisonline v-if="getData.myisshow === 218" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcisonline>
    <refundinfomerge v-if="getData.myisshow === 219" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></refundinfomerge>
    <vweipages v-if="getData.myisshow === 226" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vweipages>
    <vweipagesadd v-if="getData.myisshow === 221" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vweipagesadd>
    <vstatementplatform v-if="getData.myisshow === 222" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vstatementplatform>
    <vsystemoutprint v-if="getData.myisshow === 223" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vsystemoutprint>
    <vweipagesedit v-if="getData.myisshow === 224" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vweipagesedit>
    <vformclue v-if="getData.myisshow === 225" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vformclue>
    <vpersonalinfo v-if="getData.myisshow === 230" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vpersonalinfo>
    <vtoolsedit v-if="getData.myisshow === 231" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vtoolsedit>
    <vminilayout v-if="getData.myisshow === 232" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vminilayout>
    <vmenuedit v-if="getData.myisshow === 234" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vmenuedit>
    <vuserset v-if="getData.myisshow === 235" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vuserset>
    <vsetderivatives v-if="getData.myisshow === 236" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsetderivatives>
    <vstatement v-if="getData.myisshow === 237" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vstatement>
    <vdercontractlist v-if="getData.myisshow === 238" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdercontractlist>
    <vderpartnerlist v-if="getData.myisshow === 239" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vderpartnerlist>
    <vderpartnerdvclist v-if="getData.myisshow === 240" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vderpartnerdvclist>
    <vderpartnercash v-if="getData.myisshow === 241" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vderpartnercash>
    <vderwithdrawalrecord v-if="getData.myisshow === 242" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vderwithdrawalrecord>
    <vorderbelong v-if="getData.myisshow === 243" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vorderbelong>
    <vsetderivativeslist v-if="getData.myisshow === 244" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsetderivativeslist>
    <vsetderivativesadd v-if="getData.myisshow === 245" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsetderivativesadd>
    <vsetderivativeslog v-if="getData.myisshow === 246" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsetderivativeslog>
    <vdercontractstop v-if="getData.myisshow === 247" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdercontractstop>
    <vdercontractstopinfo v-if="getData.myisshow === 248" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdercontractstopinfo>
    <vrechargememberset v-if="getData.myisshow === 249" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrechargememberset>
    <vmemberswitchset v-if="getData.myisshow === 250" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberswitchset>
    <vrechargememberaccount v-if="getData.myisshow === 251" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrechargememberaccount>
    <vrechargememberstatement v-if="getData.myisshow === 252" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrechargememberstatement>
    <vdercontractdvclist v-if="getData.myisshow === 253" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdercontractdvclist>
    <vdvcuseinfo v-if="getData.myisshow === 254" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcuseinfo>
    <vcorporatetransferadd v-if="getData.myisshow === 255" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcorporatetransferadd>
    <vcorporatetransferinfo v-if="getData.myisshow === 256" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcorporatetransferinfo>
    <vdercontractlistadd v-if="getData.myisshow === 257" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdercontractlistadd>
    <vcorporatetransferlist v-if="getData.myisshow === 259" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcorporatetransferlist>
    <vpaymoneypostgoods v-if="getData.myisshow === 260" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpaymoneypostgoods>
    <vsharedvccontrolfamilyedit v-if="getData.myisshow === 261" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvccontrolfamilyedit>
    <vpostgoodslist v-if="getData.myisshow === 272" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpostgoodslist>
    <vpostgoodslistadd v-if="getData.myisshow === 273" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpostgoodslistadd>
    <vpostgoodslistedit v-if="getData.myisshow === 274" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpostgoodslistedit>
    <vsurplusset v-if="getData.myisshow === 275" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsurplusset>
    <vcorporatetransferbankcardadd v-if="getData.myisshow === 276" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcorporatetransferbankcardadd>
    <vcorporatetransferbankcardinfo v-if="getData.myisshow === 277" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcorporatetransferbankcardinfo>
    <vcorporatetransferbankcardlist v-if="getData.myisshow === 278" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcorporatetransferbankcardlist>
    <vaccountfd v-if="getData.myisshow === 262" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vaccountfd>
    <voverviewfd v-if="getData.myisshow === 263" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></voverviewfd>
    <vdvcorderrankingfd v-if="getData.myisshow === 264" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcorderrankingfd>
    <vdvcordercensusfd v-if="getData.myisshow === 265" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcordercensusfd>
    <vagentcensusfd v-if="getData.myisshow === 266" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentcensusfd>
    <vserviceuser v-if="getData.myisshow === 267" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vserviceuser>
    <vserviceuseradd v-if="getData.myisshow === 268" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vserviceuseradd>
    <vserviceuseredit v-if="getData.myisshow === 269" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vserviceuseredit>
    <vgoodslist v-if="getData.myisshow === 300" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgoodslist>
    <vgoodslistadd v-if="getData.myisshow === 301" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgoodslistadd>
    <vgoodslistedit v-if="getData.myisshow === 302" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vgoodslistedit>
    <vordermanage v-if="getData.myisshow === 303" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vordermanage>
    <vordermanageinfo v-if="getData.myisshow === 304" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vordermanageinfo>
    <voperatuser v-if="getData.myisshow === 305" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></voperatuser>
    <vreportsingle v-if="getData.myisshow === 306" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vreportsingle>
    <vreportsingleinfo v-if="getData.myisshow === 307" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vreportsingleinfo>
    <vagentplusfans v-if="getData.myisshow === 308" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentplusfans>
    <vdvcfanscount v-if="getData.myisshow === 309" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdvcfanscount>
    <vserviceusercens v-if="getData.myisshow === 310" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vserviceusercens>
    <vreportsinglecens v-if="getData.myisshow === 311" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vreportsinglecens>
    <vreportsinglecensinfo v-if="getData.myisshow === 312" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vreportsinglecensinfo>
    <vintroducer v-if="getData.myisshow === 313" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vintroducer>
    <vnewfanscoupon v-if="getData.myisshow === 314" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vnewfanscoupon>
    <!-- 合作伙伴-指定设备报单 -->
    <vagentappointdvc v-if="getData.myisshow === 315" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentappointdvc>
    <vdeviceappointdvc v-if="getData.myisshow === 316" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdeviceappointdvc>
    <vheaderappointdvc v-if="getData.myisshow === 317" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vheaderappointdvc>

    <!--此处添加下级组件，添加样式如上一行 ...........-->
    <recposition v-if="getData.myisshow === 3" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></recposition>
    <feedinfo v-if="getData.myisshow === 4" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></feedinfo>
    <vcurriculumlistadd v-if="getData.myisshow === 5" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vcurriculumlistadd>
    <vcoursewarelistadd v-if="getData.myisshow === 6" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vcoursewarelistadd>
    <orderlistinfo v-if="getData.myisshow === 7" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></orderlistinfo>
    <refundinfo v-if="getData.myisshow === 8" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></refundinfo>
    <refwechatinfo v-if="getData.myisshow === 9" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></refwechatinfo>
    <advtiserinfo v-if="getData.myisshow === 10" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></advtiserinfo>
    <advspreadinfo v-if="getData.myisshow === 11" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></advspreadinfo>
    <adverinfo v-if="getData.myisshow === 12" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></adverinfo>
    <lookdeviceinfo v-if="getData.myisshow === 13" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></lookdeviceinfo>
    <playerinfo v-if="getData.myisshow === 14" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></playerinfo>
    <appealinfo v-if="getData.myisshow === 15" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></appealinfo>
    <bondbackinfo v-if="getData.myisshow === 16" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></bondbackinfo>
    <vagentlistadd v-if="getData.myisshow === 17" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vagentlistadd>
    <basic v-if="getData.myisshow === 18" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></basic>
    <vplatformprintsubcom v-if="getData.myisshow === 220" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vplatformprintsubcom>
    <!-- 临时上线-20230324 -->
    <vyeardvccount v-if="getData.myisshow === 510" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vyeardvccount>
    <vyeardvccountsum v-if="getData.myisshow === 511" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vyeardvccountsum>
    <!--此处添加下级组件，添加样式如上一行 ...........-->
    <vagentlist v-if="getData.myisshow === 19" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentlist>
    <vagentcensus v-if="getData.myisshow === 20" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentcensus>
    <vagentdivide v-if="getData.myisshow === 23" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentdivide>
    <vagentdividedetailed v-if="getData.myisshow === 24" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vagentdividedetailed>
    <vuseradd v-if="getData.myisshow === 29" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vuseradd>
    <vuseredit v-if="getData.myisshow === 30" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vuseredit>
    <vsaleuseradd v-if="getData.myisshow === 31" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsaleuseradd>
    <vsaleuseredit v-if="getData.myisshow === 32" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsaleuseredit>
    <vprintresourcesadd v-if="getData.myisshow === 33" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vprintresourcesadd>
    <vfileclassifyadd v-if="getData.myisshow === 34" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfileclassifyadd>
    <vfileclassifyedit v-if="getData.myisshow === 35" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vfileclassifyedit>
    <vlabelmanageadd v-if="getData.myisshow === 36" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlabelmanageadd>
    <vlabelmanageedit v-if="getData.myisshow === 37" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlabelmanageedit>
    <vcurriculumclassifyadd v-if="getData.myisshow === 38" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcurriculumclassifyadd>
    <vcurriculumclassifyedit v-if="getData.myisshow === 39" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcurriculumclassifyedit>
    <vcoursewareclassifyadd v-if="getData.myisshow === 40" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoursewareclassifyadd>
    <vcoursewareclassifyedit v-if="getData.myisshow === 41" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoursewareclassifyedit>
    <vcoursewarelistedit v-if="getData.myisshow === 42" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vcoursewarelistedit>
    <vcurriculumlistedit v-if="getData.myisshow === 43" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vcurriculumlistedit>
    <vworkorderlistinfo v-if="getData.myisshow === 44" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vworkorderlistinfo>
    <vcommonproblemadd v-if="getData.myisshow === 45" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vcommonproblemadd>
    <vcommonproblemedit v-if="getData.myisshow === 46" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vcommonproblemedit>
      <vjiaoyimingxi v-if="getData.myisshow === 47" :mainpagedata="mainpagedata" v-on:getsearchinfo="getSeachinfoEvent"></vjiaoyimingxi>
    <vluckydrawrecord v-if="getData.myisshow === 48" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vluckydrawrecord>
    <vluckydrawrecordadd v-if="getData.myisshow === 49" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vluckydrawrecordadd>
    <vluckydrawinfo v-if="getData.myisshow === 50" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vluckydrawinfo>
    <vluckydrawrecordedit v-if="getData.myisshow === 51" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vluckydrawrecordedit>
    <vcouponadd v-if="getData.myisshow === 52" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcouponadd>
    <vcouponinfo v-if="getData.myisshow === 53" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcouponinfo>
    <vcoupondata v-if="getData.myisshow === 54" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcoupondata>
    <vcouponedit v-if="getData.myisshow === 55" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vcouponedit>
    <vsharedvcmap2 v-if="getData.myisshow === 56" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcmap2>
    <vrefundapplicationmerge v-if="getData.myisshow === 57" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vrefundapplicationmerge>
    <vofflinestatistics v-if="getData.myisshow === 233" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vofflinestatistics>
    <vpublicnumset v-if="getData.myisshow === 280" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpublicnumset>
    <vpublicnumcapital v-if="getData.myisshow === 281" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpublicnumcapital>
    <!-- 20230322 -->
    <vlinktitle v-if="getData.myisshow === 282" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlinktitle>
    <vlinktitleadd v-if="getData.myisshow === 283" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlinktitleadd>
    <vlinktitleedit v-if="getData.myisshow === 284" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlinktitleedit>
    <vminipluswecom v-if="getData.myisshow === 285" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vminipluswecom>
    <vminipluswecomadd v-if="getData.myisshow === 286" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vminipluswecomadd>
    <vminipluswecomedit v-if="getData.myisshow === 287" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vminipluswecomedit>
    <vshoppluswecom v-if="getData.myisshow === 288" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vshoppluswecom>
    <vshoppluswecomadd v-if="getData.myisshow === 289" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vshoppluswecomadd>
    <vshoppluswecomedit v-if="getData.myisshow === 290" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vshoppluswecomedit>
    <!-- 临时：上线 -->
    <vsharedvcmapfd v-if="getData.myisshow === 500" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vsharedvcmapfd>
    <vyeardvccountfd v-if="getData.myisshow === 501" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vyeardvccountfd>

    <!-- 权益会员相关 -->
    <vmemberList  v-if="getData.myisshow === 550" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberList>
    <vmemberSet  v-if="getData.myisshow === 551" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberSet>
    <vdeviceSet  v-if="getData.myisshow === 552" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vdeviceSet>
    <vshopEquity v-if="getData.myisshow === 553" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vshopEquity>
    <vmemberDvcCount v-if="getData.myisshow === 554" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberDvcCount>
    <vmemberBuyRecord v-if="getData.myisshow === 555" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberBuyRecord>
    <vmemberWriteOff v-if="getData.myisshow === 556" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vmemberWriteOff>
    <!-- 全品相关 -->
    <vlibraryorderOther v-if="getData.myisshow === 557" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlibraryorderOther>
    <vlibraryorderSortOther v-if="getData.myisshow === 558" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vlibraryorderSortOther>
    <vorderOverdueRefund v-if="getData.myisshow === 559" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vorderOverdueRefund>
    <!-- 打印新粉统计 -->
    <vnewFansCount v-if="getData.myisshow === 560" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vnewFansCount>
    <vpubNumberSet v-if="getData.myisshow === 561" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpubNumberSet>
    <vpubNumberDisDvc v-if="getData.myisshow === 562" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vpubNumberDisDvc>
    <vchannelSpreadLink v-if="getData.myisshow === 563" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vchannelSpreadLink>
    <vbackNewsSet v-if="getData.myisshow === 564" :mainpagedata="mainpagedata" v-on:getspecialinfo="getSpecialinfoEvent"></vbackNewsSet>
   
    <vPrintEarnings
      v-if="getData.myisshow === 869"
      :mainpagedata="mainpagedata"
      v-on:getspecialinfo="getSpecialinfoEvent"
    />
  </div>
</template>

<script>
import {reactive} from "vue";
import vuserlist from "./user/userlist/vuserlist";  //引用组件文件名-命名 -用户-用户管理-用户列表
import vmemberplusbuy from './user/member/vmemberplusbuy' // 用户-会员管理-PLUS会员购买记录
import vmemberpluscash from './user/member/vmemberpluscash' // 用户-会员管理-PLUS会员返现记录
import vmemberdvcbuy from './user/member/vmemberdvcbuy' // 用户-会员管理-PLUS会员返现记录
import vmemberinvitestate from './user/member/vmemberinvitestate' // 用户-会员管理-PLUS会员邀请统计
import vmemberinviterecord from './user/member/vmemberinviterecord' // 用户-会员管理-PLUS会员邀请记录
import vmemberinvitegift from './user/member/vmemberinvitegift' // 用户-会员管理-PLUS会员礼品兑换
import vyingbinew from './user/yingbi/vyingbinew' // 用户-京币管理-新用户邀请记录
import vyingbiconsume from './user/yingbi/vyingbiconsume' // 用户-京币管理-京币发放/消费记录
import vexportlist from './user/exportlist/vexportlist' // 用户-导出管理-我的导出
import vdvclist from './collaborate/agent/dvclist/vdvclist' // 合作伙伴-代理商管理-设备列表
import vintention from './collaborate/agent/intention/vintention' // 合作伙伴-代理商管理-意向客户列表
import vpartnerlist from './collaborate/partner/partnerlist/vpartnerlist' // 合作伙伴-设备合伙人-设备合伙人列表
import vcontractlist from './collaborate/partner/contractlist/vcontractlist' // 合作伙伴-设备合伙人-合同列表
import vdvcflowlist from './collaborate/partner/dvcflowlist/vdvcflowlist' // 合作伙伴-设备合伙人-设备流水列表
import vpartnercash from './collaborate/partner/partnercash/vpartnercash' // 合作伙伴-设备合伙人-合伙人返现记录
import vsharedvcmap from './print/sharedvc/sharedvcmap/vsharedvcmap' // 打印-共享打印-设备地图
import vsharedvccontrolfrp from './print/sharedvc/sharedvccontrol/vsharedvccontrolfrp' // 打印-共享打印-编辑设备-远程控制
import vsharedvcexfactory from './print/sharedvc/sharedvcexfactory/vsharedvcexfactory' // 打印-共享打印-设备出厂记录
import vsharedvcback from './print/sharedvc/sharedvcback/vsharedvcback' // 打印-共享打印-设备退货记录
import vsharedvcinstall from './print/sharedvc/sharedvcinstall/vsharedvcinstall' // 打印-共享打印-安装记录
import vprintresources from './education/files/printresources/vprintresources' // 教育-文档管理-打印资源库
import vfileclassify from './education/files/fileclassify/vfileclassify' // 教育-文档管理-文档分类
import vlabelmanage from './education/files/labelmanage/vlabelmanage' // 教育-文档管理-标签管理
import vcurriculumclassify from './education/curriculum/curriculumclassify/vcurriculumclassify' // 教育-课程管理-课程分类
import vcoursewareclassify from './education/curriculum/coursewareclassify/vcoursewareclassify' // 教育-课程管理-课件分类
import vcoursewarelist from './education/curriculum/coursewarelist/vcoursewarelist' // 教育-课程管理-课件列表
import vcurriculumlist from './education/curriculum/curriculumlist/vcurriculumlist' // 教育-课程管理-课程列表
import vorderlist from './order/print/orderlist/vorderlist' // 订单-打印管理-订单列表
import vrefundapplication from './order/print/refundapplication/vrefundapplication' // 订单-打印管理-退款申请
import vrefundapplicationmerge from './order/print/refundapplication/vrefundapplicationmerge.vue' // 订单-打印管理-退款申请(商家)
import vorderbelong from '@/vspecial/order/print/orderbelong/vorderbelong' // 订单-打印管理-订单归属平台查询
import vcurriculumorder from './order/education/vcurriculumorder' // 订单-教育管理-课程订单列表
import vlibraryorderlist from './order/education/vlibraryorder' // 订单-教育管理-文库订单列表
import vadvertiserslist from './order/advertisers/advertiserslist/vadvertiserslist' // 订单-教育管理-文库订单列表
import vadvertisersspread from './order/advertisers/advertisersspread/vadvertisersspread' // 订单-广告主管理-广告推广方案
import vprintwriteoff from './order/advertisers/printwriteoff/vprintwriteoff' // 订单-广告主管理-打印核销记录
import vrecagent from './finance/capital/recagent/vrecagent' // 财务-资金对账-代理商对账
import ventrynote from './finance/capital/entrynote/ventrynote' // 财务-资金对账-代理商对账明细
import vpostingdetails from './finance/capital/postingdetails/vpostingdetails' // 财务-资金对账-第三方平台对账单
import vstatementuser from './finance/capital/statementuser/vstatementuser' // 财务-资金对账-用户对账单明细
import vstatementagent from './finance/capital/statementagent/vstatementagent' // 财务-资金对账-交易明细
import vstatementpartner from './finance/capital/statementpartner/vstatementpartner' // 财务-资金对账-合伙人对账单明细
import vbalancetransfer from './finance/capital/balancetransfer/vbalancetransfer' // 财务-资金对账-余额转账明细
import vbalanceredpacket from './finance/capital/balanceredpacket/vbalanceredpacket' // 财务-资金对账-余额红包券
import vredpacketdetailed from './finance/capital/balanceredpacket/vredpacketdetailed' // 财务-资金对账-余额红包券-红包领取明细
import vbondapplyback from './finance/bond/bondapplyback/vbondapplyback' // 财务-保证金-申请退保证金
import vbonddetailed from './finance/bond/bonddetailed/vbonddetailed' // 财务-保证金-保证金明细
import vwithdrawalrecord from './finance/withdrawal/withdrawalrecord/vwithdrawalrecord' // 财务-提现管理-提现记录
import vplatformprintsubcom from './finance/withdrawal/platformprintsubcom/vplatformprintsubcom'; // 财务-提现管理-平台打印分佣
import vwithdrawalremind from './finance/withdrawal/withdrawalremind/vwithdrawalremind' // 财务-提现管理-出入提现提醒
import vfeedbackopinion from './aftersale/feed/vfeedbackopinion' // 售后-意见反馈-小程序意见反馈
import vworkorderlist from './aftersale/workorderinfo/vworkorderlist' // 售后-工单信息-工单列表
import vlibraryorder from './census/education/libraryorder/vlibraryorder' // 统计-教育统计-文库订单排行
import vinvitemember from './census/member/invitemember/vinvitemember' // 统计-会员统计-邀请会员统计
import vdvcprint from './census/member/dvcprint/vdvcprint' // 统计-会员统计-设备打印统计
import vdepartment from './power/power/department/vdepartment' // 权限-权限管理-部门管理
import vpower from './power/power/power/vpower' // 权限-权限管理-权限管理
import vuser from './power/power/user/vuser' // 权限-权限管理-用户管理
import vsaleuser from './power/power/saleuser/vsaleuser' // 权限-权限管理-销售/售后用户管理
import vloginlog from './power/menu/loginlog/vloginlog' // 权限-权限管理-销售/售后用户管理
import vappletadvert from './operate/applet/appletadvert/vappletadvert' // 运营-小程序广告位管理-广告位列表
import vadvertlist from './operate/advertdvc/adverlist/advertlist/vadvertlist' // 运营-设备广告管理-广告列表
import vadvertexamine from './operate/advertdvc/adverlist/advertexamine/vadvertexamine' // 运营-设备广告管理-审核后台广告
import vadvertappeal from './operate/advertdvc/adverlist/advertappeal/vadvertappeal' // 运营-设备广告管理-广告申诉
import vcoupon from './operate/play/coupon/vcoupon' // 运营-营销玩法-优惠券
import vluckydraw from './operate/play/luckydraw/vluckydraw' // 运营-营销玩法-幸运大抽奖
import vphonecard from './operate/play/phonecard/vphonecard' // 运营-营销玩法-手机卡活动
import vbossintcus from './operate/play/bossintcus/vbossintcus' // 运营-营销玩法-手机卡活动
import vordergoods from './operate/play/vordergoods/vordergoods' // 运营-营销玩法-订购商品
import vformclue from './operate/play/formclue/vformclue' // 设置-DIY-微页面-表单线索
import vpersonalinfo from './set/diy/personalinfo/vpersonalinfo' // 设置-DIY-个人中心
import vtoolsedit from './set/diy/personalinfo/vtoolsedit' // 设置-DIY-个人中心-编辑工具栏
import vminilayout from './set/diy/minilayout/vminilayout' // 设置-DIY-首页布局
import vmenuedit from './set/diy/personalinfo/vmenuedit' // 设置-DIY-个人中心-编辑
import vuserset from './power/power/user/vuserset' // 权限-权限管理-用户管理-设置
// （先不做-20230407）
import vgoodslist from './operate/shopping/goods/vgoodslist' // 运营-商城管理-商品管理
import vgoodslistadd from './operate/shopping/goods/vgoodslistadd' // 运营-商城管理-商品管理-添加
import vgoodslistedit from './operate/shopping/goods/vgoodslistedit' // 运营-商城管理-商品管理-编辑
import vordermanage from './operate/shopping/ordermanage/vordermanage' // 运营-商城管理-订单管理
import vordermanageinfo from './operate/shopping/ordermanage/vordermanageinfo' // 运营-商城管理-订单管理-订单详情
import vlinktitle from './operate/wecom/linktitle/vlinktitle' // 运营-企业微信-链接标题语
import vlinktitleadd from './operate/wecom/linktitle/vlinktitleadd' // 运营-企业微信-链接标题语-添加
import vlinktitleedit from './operate/wecom/linktitle/vlinktitleedit' // 运营-企业微信-链接标题语-编辑
import vminipluswecom from './operate/wecom/minipluswecom/vminipluswecom' // 运营-企业微信-小程序加企微
import vminipluswecomadd from './operate/wecom/minipluswecom/vminipluswecomadd' // 运营-企业微信-小程序加企微-添加
import vminipluswecomedit from './operate/wecom/minipluswecom/vminipluswecomedit' // 运营-企业微信-小程序加企微-编辑
import vshoppluswecom from './operate/wecom/shoppluswecom/vshoppluswecom' // 运营-企业微信-商家加企微
import vshoppluswecomadd from './operate/wecom/shoppluswecom/vshoppluswecomadd' // 运营-企业微信-商家加企微-添加
import vshoppluswecomedit from './operate/wecom/shoppluswecom/vshoppluswecomedit' // 运营-企业微信-商家加企微-编辑
import vreportsingle from './collaborate/agent/reportsingle/vreportsingle' // 合作伙伴-代理商管理-报单列表
import vreportsingleinfo from './collaborate/agent/reportsingle/vreportsingleinfo' // 合作伙伴-代理商管理-报单列表-详情
import vcommonproblem from './operate/play/commonproblem/vcommonproblem' // 运营-服务中心-常见问题
import vdvcordercensus from './census/print/dvcordercensus/vdvcordercensus' // 统计-打印统计-设备订单统计
import vdvcorderranking from './census/print/dvcorderranking/vdvcorderranking' // 统计-打印统计-设备订单排行
import vdvcuseinfo from './census/print/dvcuseinfo/vdvcuseinfo' // 统计-打印统计-设备使用情况
import vaftersaleflow from './census/print/aftersaleflow/vaftersaleflow' // 统计-打印统计-售后人员流水统计
import vsaleflow from './census/print/saleflow/vsaleflow' // 统计-打印统计-销售人员流水统计
import vuserinfolist from './user/userlist/vuserinfolist' // 统计-打印统计-销售人员流水统计
import vagentlistedit from './collaborate/agent/agentlist/vagentlistedit' // 合作伙伴-代理商管理-代理商列表-编辑代理商
import vaccountset from './set/systemset/vaccountset' // 设置-系统设置-账户设置
import vplusset from './set/memberset/vplusset' // 设置-会员设置-PLUS会员设置
import vplussetdisable from './set/memberset/vplussetdisable' // 设置-会员设置-禁用PLUS会员设置
import vdvcplusset from './set/memberset/vdvcplusset' //设置-会员设置-设备会员系统默认值
import vresourcesexamine from './education/files/printresources/vresourcesexamine' //教育-文档管理-打印资源库-审核文档
import vresourcesedit from './education/files/printresources/vresourcesedit' //教育-文档管理-打印资源库-编辑文档
import vprintresourcesaddmore from './education/files/printresources/vprintresourcesaddmore' //教育-文档管理-打印资源库-编辑文档
import vfileclassifyaddlast from './education/files/fileclassify/vfileclassifyaddlast' //教育-文档管理-文档分类-新增下级文档
import vappletadvertadd from './operate/applet/appletadvert/vappletadvertadd' //运营-小程序广告位管理-广告位列表-添加广告位
import vappletadvertedit from './operate/applet/appletadvert/vappletadvertedit' //运营-小程序广告位管理-广告位列表-编辑广告位
import vadvertlistadd from './operate/advertdvc/adverlist/advertlist/vadvertlistadd' //运营-设备广告管理-广告列表-添加广告
import vagentlistinfo from './collaborate/agent/agentlist/vagentlistinfo' //合作-代理商管理-代理商列表-代理商详情
import vpartnerdvclist from './collaborate/partner/partnerlist/vpartnerdvclist' //合作-设备合伙人-设备合伙人列表-设备合伙人设备列表
import vdvcflowlistadd from './collaborate/partner/dvcflowlist/vdvcflowlistadd' //合作-设备合伙人-设备流水列表-添加流水
import vcontractlistadd from './collaborate/partner/contractlist/vcontractlistadd' //合作-设备合伙人-合同列表-添加合同
import vplusinvite from './set/memberset/vplusinvite' //设置-会员设置-会员邀请设置
import vaccount from "./finance/capital/account/vaccount"; //财务--平台总览
import vstatementplatform from "./finance/capital/statementplatform/vstatementplatform"; //财务--平台总览
import voverview from "./census/print/voverview"; //统计--打印统计--打印概览统计
import vsystemoutprint from "./census/print/systemoutprint/vsystemoutprint"; //统计--打印统计--系统外打印统计
import vfooter from "./set/diy/vfooter"; //设置-diy-底部导航
import vfooteradd from "./set/diy/vfooteradd"; //设置-diy-底部导航增加
import vhome from "./set/diy/vhome"; //设置-diy-首页导航
import vstyle from "./set/diy/vstyle"; //设置-diy-小程序风格
import vpowerset from "./power/power/power/vpowerset"; //设置-diy-小程序风格
import vdvcordercensusdetail from './census/print/dvcordercensus/vdvcordercensusdetail.vue';
import vsharedvccontrol from "./print/sharedvc/sharedvccontrol/vsharedvccontrol"; //打印-共享打印-机器控制
import vsharedvccontroledit from "./print/sharedvc/sharedvccontrol/vsharedvccontroledit"; //打印-共享打印-机器控制-编辑
import vsharedvccontrolseelog from "./print/sharedvc/sharedvccontrol/vsharedvccontrolseelog"; //打印-共享打印-机器控制-查看日志
import vdvcisonline from "./print/sharedvc/sharedvccontrol/vdvcisonline"; //打印-共享打印-机器控制-在线时长
import recposition from './education/files/recposition';
import feedinfo from './aftersale/feed/feedinfo';
import vcurriculumlistadd from './education/curriculum/curriculumlist/vcurriculumlistadd'; // 教育-课程管理-课程列表-添加课程
import vcoursewarelistadd from "./education/curriculum/coursewarelist/vcoursewarelistadd";// 教育-课程管理-课件列表-添加课件
import orderlistinfo from "./order/print/orderlist/orderinfo";
import refundinfo from "./order/print/refundapplication/refundinfo";
import refundinfomerge from "./order/print/refundapplication/refundinfomerge";
import refwechatinfo from "./order/print/refundapplication/refwechatinfo";
import advtiserinfo from './order/advertisers/advertiserslist/advtiserinfo'; //订单-广告主管理-广告主列表-广告主详情
import advspreadinfo from './order/advertisers/advertisersspread/advspreadinfo';//订单-广告主管理-广告推广方案-推广方案详情
import adverinfo from "./operate/advertdvc/adverlist/adverinfo"; //运营-设备广告管理-广告列表-设备广告信息
import lookdeviceinfo from "./operate/advertdvc/adverlist/lookdeviceinfo"; //运营-设备广告管理-广告列表-设备广告信息-查看设备详情
import playerinfo from "./operate/advertdvc/adverlist/playerinfo";
import vpublicnumset from "./operate/play/publicnumset/vpublicnumset"; //运营-营销玩法-公众号关注设置
import vpublicnumcapital from "./finance/capital/publicnumcapital/vpublicnumcapital"; //运营-营销玩法-公众号关注设置
import appealinfo from "./operate/advertdvc/advertappeal/appealinfo";
import bondbackinfo from "./finance/bond/bondapplyback/bondbackinfo";
import vagentlistadd from  "./collaborate/agent/agentlist/vagentlistadd"; //合作伙伴-代理商管理-代理商列表-添加代理商
import basic from "./set/systemset/vsystem" //设置-系统设置-基础设置
import vagentlist from  "./collaborate/agent/agentlist/vagentlist" //合作伙伴-代理商管理-代理商列表
import vagentcensus from "./census/print/agentcensus/vagentcensus" //统计--打印统计--代理商统计--一级代理商
import vagentdivide from "./census/print/agentdivide/vagentdivide" // 统计--打印统计--代理商分成预警
import vagentdividedetailed from './census/print/agentdivide/vagentdividedetailed' // 统计-打印统计-代理商分成预警-代理商分成明细
import vweipages from './set/diy/weipages/vweipages' // 设置-DIY-微页面
import vweipagesadd from './set/diy/weipages/vweipagesadd' // 设置-DIY-微页面-添加
import vweipagesedit from './set/diy/weipages/vweipagesedit' // 设置-DIY-微页面-编辑
import vuseradd from './power/power/user/vuseradd.vue' //权限-权限管理-用户管理-添加员工
import vuseredit from './power/power/user/vuseredit.vue' //权限-权限管理-用户管理-编辑员工
import vsaleuseradd from './power/power/saleuser/vsaleuseradd.vue' //权限-权限管理-销售/售后用户管理-添加员工
import vsaleuseredit from './power/power/saleuser/vsaleuseredit.vue' //权限-权限管理-销售/售后用户管理-编辑员工
import vprintresourcesadd from './education/files/printresources/vprintresourcesadd' //教育-文档管理-打印资源库-添加文档
import vfileclassifyadd from './education/files/fileclassify/vfileclassifyadd' //教育-文档管理-文档分类-添加分类
import vfileclassifyedit from './education/files/fileclassify/vfileclassifyedit' //教育-文档管理-文档分类-编辑分类
import vlabelmanageadd from './education/files/labelmanage/vlabelmanageadd.vue'; //教育-文档管理-标签管理-添加标签
import vlabelmanageedit from './education/files/labelmanage/vlabelmanageedit.vue'; //教育-文档管理-标签管理-编辑标签
import vcurriculumclassifyadd from './education/curriculum/curriculumclassify/vcurriculumclassifyadd' // 教育-课程管理-课程分类-添加分类
import vcurriculumclassifyedit from './education/curriculum/curriculumclassify/vcurriculumclassifyedit' // 教育-课程管理-课程分类-编辑分类
import vcoursewareclassifyadd from './education/curriculum/coursewareclassify/vcoursewareclassifyadd' // 教育-课程管理-课件分类-添加分类
import vcoursewareclassifyedit from './education/curriculum/coursewareclassify/vcoursewareclassifyedit' // 教育-课程管理-课件分类-编辑分类
import vcoursewarelistedit from "./education/curriculum/coursewarelist/vcoursewarelistedit";// 教育-课程管理-课件列表-编辑课件
import vcurriculumlistedit from "./education/curriculum/curriculumlist/vcurriculumlistedit"; //教育-课程管理-课程列表-编辑课程
import vworkorderlistinfo from "./aftersale/workorderinfo/vworkorderlistinfo" //售后-售后反馈-故障报修-故障详情
import vcommonproblemadd from "./operate/play/commonproblem/vcommonproblemadd" //运营-服务中心-常见问题-添加问题
import vcommonproblemedit from "./operate/play/commonproblem/vcommonproblemedit" //运营-服务中心-常见问题-编辑问题
import vjiaoyimingxi from "./finance/capital/jiaoyimingxi/vjiaoyimingxi"; //财务-资金对账-平台交易明细
import vluckydrawrecord from './operate/play/luckydraw/vluckydrawrecord' // 运营-营销玩法-幸运大抽奖-抽奖记录
import vluckydrawrecordadd from './operate/play/luckydraw/vluckydrawrecordadd' // 运营-营销玩法-幸运大抽奖-添加抽奖
import vluckydrawinfo from './operate/play/luckydraw/vluckydrawinfo' // 运营-营销玩法-幸运大抽奖-查看详情
import vluckydrawrecordedit from './operate/play/luckydraw/vluckydrawrecordedit' // 运营-营销玩法-幸运大抽奖-添加抽奖
import vcouponadd from './operate/play/coupon/vcouponadd' // 运营-营销玩法-优惠券-添加优惠券
import vcouponinfo from './operate/play/coupon/vcouponinfo' // 运营-营销玩法-优惠券-查看优惠券
import vcoupondata from './operate/play/coupon/vcoupondata' // 运营-营销玩法-优惠券-查看优惠券
import vcouponedit from './operate/play/coupon/vcouponedit' // 运营-营销玩法-优惠券-查看优惠券
import vsharedvcmap2 from './print/sharedvc/sharedvcmap/vsharedvcmap2' // 打印-共享打印-设备地图
import Vexportlist from './user/exportlist/vexportlist.vue';
import vofflinestatistics from './census/offlinestatistics/vofflinestatistics.vue';
import voperatuser from './power/power/operatuser/voperatuser.vue'
import vintroducer from './collaborate/agent/introducer/vintroducer.vue'
import vnewfanscoupon from './operate/wecom/newfanscoupon/vnewfanscoupon.vue'
import vdvcfanscount from './census/wecom/dvcfanscount/vdvcfanscount.vue'
import vagentplusfans from './census/wecom/agentplusfans/vagentplusfans.vue'
import vserviceusercens from './census/service/serviceusercens/vserviceusercens.vue'
import vreportsinglecens from './census/service/reportsinglecens/vreportsinglecens.vue'
import vreportsinglecensinfo from './census/service/reportsinglecens/vreportsinglecensinfo.vue'
// 合作伙伴-指定设备报单
import vagentappointdvc from './collaborate/appointdvc/agent/vagentappointdvc.vue' // 合作伙伴-指定设备报单-
import vdeviceappointdvc from './collaborate/appointdvc/device/vdeviceappointdvc.vue' // 合作伙伴-指定设备报单-
import vheaderappointdvc from './collaborate/appointdvc/header/vheaderappointdvc.vue' // 合作伙伴-指定设备报单-

// 临时：上线
import vsharedvcmapfd from './print/sharedvc/sharedvcmap/vsharedvcmapfd' // 打印-共享打印-设备地图
import vyeardvccount from './census/print/yeardvccount/vyeardvccount' // 统计-打印统计-设备收益统计
import vyeardvccountsum from './census/print/yeardvccount/vyeardvccountsum' // 统计-打印统计-年度设备统计
import vsetderivatives from '@/vspecial/derivatives/set/setderivatives/vsetderivatives';
import vstatement from '@/vspecial/derivatives/finance/statement/vstatement';
import vdercontractlist from '@/vspecial/derivatives/partner/dercontractlist/vdercontractlist';
import vdercontractdvclist from '@/vspecial/derivatives/partner/dercontractdvclist/vdercontractdvclist';
import vderpartnerlist from '@/vspecial/derivatives/partner/derpartnerlist/vderpartnerlist';
import vderpartnerdvclist from '@/vspecial/derivatives/partner/derpartnerlist/vderpartnerdvclist';
import vderpartnercash from '@/vspecial/derivatives/partner/derpartnercash/vderpartnercash';
import vderwithdrawalrecord from '@/vspecial/derivatives/finance/derwithdrawalrecord/vderwithdrawalrecord';
import vsetderivativeslist from '@/vspecial/derivatives/set/setderivatives/vsetderivativeslist';
import vsetderivativesadd from '@/vspecial/derivatives/set/setderivatives/vsetderivativesadd';
import vsetderivativeslog from '@/vspecial/derivatives/set/setderivatives/vsetderivativeslog';
import vdercontractstop from '@/vspecial/derivatives/partner/dercontractstop/vdercontractstop';
import vdercontractstopinfo from '@/vspecial/derivatives/partner/dercontractstop/vdercontractstopinfo';
import vdercontractlistadd from '@/vspecial/derivatives/partner/dercontractlist/vdercontractlistadd';
import vserviceuser from './power/power/service/vserviceuser'; // 权限-权限管理-业务用户管理
import vserviceuseredit from './power/power/service/vserviceuseredit'; // 权限-权限管理-业务用户管理-编辑
import vserviceuseradd from './power/power/service/vserviceuseradd'; // 权限-权限管理-业务用户管理-添加

import vrechargememberset from '@/vspecial/set/memberset/vrechargememberset';
import vmemberswitchset from '@/vspecial/set/memberset/vmemberswitchset';
import vrechargememberaccount from '@/vspecial/finance/capital/rechargememberaccount/vrechargememberaccount';
import vrechargememberstatement from '@/vspecial/finance/capital/rechargememberstatement/vrechargememberstatement';
import vcorporatetransferadd from '@/vspecial/finance/withdrawal/corporatetransfer/corporatetransferadd/vcorporatetransferadd';
import vcorporatetransferinfo from '@/vspecial/finance/withdrawal/corporatetransfer/corporatetransferinfo/vcorporatetransferinfo';
import vcorporatetransferlist from '@/vspecial/finance/withdrawal/corporatetransfer/corporatetransferlist/vcorporatetransferlist';
import vcorporatetransferbankcardadd from '@/vspecial/finance/withdrawal/corporatetransferbankcard/corporatetransferbankcardadd/vcorporatetransferbankcardadd';
import vcorporatetransferbankcardinfo from '@/vspecial/finance/withdrawal/corporatetransferbankcard/corporatetransferbankcardinfo/vcorporatetransferbankcardinfo';
import vcorporatetransferbankcardlist from '@/vspecial/finance/withdrawal/corporatetransferbankcard/corporatetransferbankcardlist/vcorporatetransferbankcardlist';
// 兼容（家庭版）
import vsharedvccontrolfamilyedit from './print/sharedvc/sharedvccontrol/vsharedvccontrolfamilyedit' // 打印-共享打印-家庭版

import vpostgoodslist from './operate/surplus/postgoods/vpostgoodslist';
import vpostgoodslistadd from './operate/surplus/postgoods/vpostgoodslistadd';
import vpostgoodslistedit from './operate/surplus/postgoods/vpostgoodslistedit';
import vsurplusset from './operate/surplus/surplusset/vsurplusset';
import vpaymoneypostgoods from './operate/surplus/paymoneypostgoods/vpaymoneypostgoods' // 运营-营销玩法-订购商品
// 临时任务20230227：给资金方展示
import vaccountfd from './finance/capitalFunder/accountfd/vaccountfd' // 财务
import voverviewfd from './census/printFunder/voverviewfd' // 统计
import vdvcorderrankingfd from './census/printFunder/dvcorderrankingfd/vdvcorderrankingfd' // 统计
import vdvcordercensusfd from './census/printFunder/dvcordercensusfd/vdvcordercensusfd' // 统计
import vagentcensusfd from './census/printFunder/agentcensusfd/vagentcensusfd' // 统计
// 临时任务 20230726：给资金方展示
import vyeardvccountfd from "./census/printFunder/yeardvccountfd/vyeardvccountfd";

// 权益会员相关
import vmemberList from "./operate/memberBenefits/member/vmemberList";
import vmemberSet from "./operate/memberBenefits/memberSet/vmemberSet";
import vdeviceSet from "./operate/memberBenefits/shopEquity/vdeviceSet"
import vshopEquity from "./operate/memberBenefits/shopEquity/vshopEquity"
import vmemberDvcCount from "./operate/memberBenefits/memberDvcCount/vmemberDvcCount"
import vmemberBuyRecord from "./operate/memberBenefits/memberBuyRecord/vmemberBuyRecord"
import vmemberWriteOff from "./operate/memberBenefits/memberWriteOff/vmemberWriteOff"

// 全品相关
import vlibraryorderOther from "./order/education/vlibraryorderOther"
import vlibraryorderSortOther from "./census/education/libraryorderSortOther/vlibraryorderSortOther"
import vorderOverdueRefund from "./order/print/orderOverdueRefund/vorderOverdueRefund"

// 打印新粉统计
import vnewFansCount from "./census/print/newFansCount/vnewFansCount";
import vpubNumberSet from "./operate/pubNumber/pubNumberSet/vpubNumberSet";
import vpubNumberDisDvc from "./operate/pubNumber/pubNumberDisDvc/vpubNumberDisDvc";
import vchannelSpreadLink from "./operate/pubNumber/channelSpreadLink/vchannelSpreadLink";
import vbackNewsSet from "./operate/pubNumber/backNewsSet/vbackNewsSet";

// 20240206新增
import vPrintEarnings from "./census/printFunder/printEarnings/vPrintEarnings";

export default {
  name: "special",
  props: ['mainpagedata'],
  setup(props) {
    const mainPagedata = reactive(props['mainpagedata']);

    //定义数据数组等
    let getData = reactive({
      myisshow: 0
    })
    //判断mycontrol是否定义
    if (mainPagedata.showpages.mycontrol) {
      //将mainPagedata.showpages.mycontrol的值赋予getData.myisshow控制页面走向
      getData.myisshow = mainPagedata.showpages.mycontrol
    }

    return {
      mainPagedata,
      getData
    }
  },
  methods: {
    getSpecialinfoEvent(data) { //获取XX特殊页面返回的数据
      console.log('获取XX特殊页面返回的数据:' + JSON.stringify(data))
      //将页数传向父组件
      this.$emit('getspecialinfo', data)
    },
    searchFormEvent(data) { //获取Maintwo-高级筛选-开始检索返回的数据
      console.log('高级筛选-开始检索返回数据(pagedata):' + JSON.stringify(data))
      //将数据传向父组件
      this.$emit('searchForm', data)
    },
    getSeachinfoEvent(data) { //获取Maintwo-搜索-返回的数据
      console.log('搜索-返回的数据:' + JSON.stringify(data))
      //将数据传向父组件
      this.$emit('getsearchinfo', data)
    },
    getPageinfoEvent(data) { //获取Mainthree-数据列表-分页选择的数据
      console.log('页面-分页选择的数据:' + JSON.stringify(data))
      //将页数传向父组件
      this.$emit('getpageinfo', data)
    },
    searchFormtanEvent(data) { //获取弹框-返回的数据
      console.log('special-获取单框提交的参数与数据等：' + JSON.stringify(data))
      //将数据传向父组件
      this.$emit('searchFormtan', data)
    }
  },
  components: {
    vrefundapplicationmerge,
    vuserlist,
    vmemberplusbuy,
    vmemberpluscash,
    vmemberdvcbuy,
    vmemberinvitestate,
    vmemberinviterecord,
    vmemberinvitegift,
    vyingbinew,
    vyingbiconsume,
    vdvclist,
    vdvcisonline,
    vintention,
    vpartnerlist,
    vcontractlist,
    vdvcflowlist,
    vpartnercash,
    vyeardvccount,
    vyeardvccountsum,
    vsharedvccontrol,
    vsharedvcmap,
    vsharedvcexfactory,
    vsharedvcback,
    vsharedvcinstall,
    vprintresources,
    vfileclassify,
    vlabelmanage,
    voperatuser,
    vcurriculumclassify,
    vcoursewareclassify,
    vcoursewarelist,
    vcurriculumlist,
    vorderlist,
    vorderbelong,
    vrefundapplication,
    vweipages,
    vweipagesadd,
    vweipagesedit,
    vformclue,
    vpersonalinfo,
    vtoolsedit,
    vuserset,
    vpublicnumset,
    vpublicnumcapital,
    vlinktitle,
    vlinktitleadd,
    vlinktitleedit,
    vminipluswecom,
    vminipluswecomadd,
    vminipluswecomedit,
    vshoppluswecom,
    vshoppluswecomadd,
    vshoppluswecomedit,
    vserviceuser,
    vserviceuseradd,
    vserviceuseredit,
    vcurriculumorder,
    vlibraryorderlist,
    vadvertiserslist,
    vadvertisersspread,
    vprintwriteoff,
    vrecagent,
    ventrynote,
    vpostingdetails,
    vsharedvccontrolfrp,
    vstatementuser,
    vstatementagent,
    vstatementpartner,
    vbalancetransfer,
    vbalanceredpacket,
    vredpacketdetailed,
    vbondapplyback,
    vbonddetailed,
    vwithdrawalrecord,
    vwithdrawalremind,
    vplatformprintsubcom,
    vfeedbackopinion,
    vworkorderlist,
    vlibraryorder,
    vinvitemember,
    vdvcprint,
    vdepartment,
    vpower,
    vuser,
    vsaleuser,
    vloginlog,
    vappletadvert,
    vadvertlist,
    vadvertexamine,
    vadvertappeal,
    vcoupon,
    vluckydraw,
    vphonecard,
    vbossintcus,
    vordergoods,
    vcommonproblem,
    vdvcordercensus,
    vdvcorderranking,
    vsystemoutprint,
    vaftersaleflow,
    vsaleflow,
    vuserinfolist,
    vagentlistedit,
    vaccountset,
    vplusset,
    vplussetdisable,
    vdvcplusset,
    vresourcesexamine,
    vresourcesedit,
    vprintresourcesaddmore,
    vfileclassifyaddlast,
    vappletadvertadd,
    vappletadvertedit,
    vadvertlistadd,
    vagentlistinfo,
    vpartnerdvclist,
    vdvcflowlistadd,
    vcontractlistadd,
    vplusinvite,
    vexportlist,
    vminilayout,
    vmenuedit,
    vdvcfanscount,
    vagentplusfans,
    vserviceusercens,
    vreportsinglecens,
    vreportsinglecensinfo,
    vsharedvcmapfd,
    vordermanageinfo,
    vordermanage,
    vgoodslistedit,
    vgoodslistadd,
    vgoodslist,
    vaccount,
    voverview,
    vsharedvccontroledit,
    vsharedvccontrolseelog,
    vfooter,
    vfooteradd,
    vhome,
    vstyle,
    vpowerset,
    vdvcordercensusdetail,
    vstatementplatform,
    recposition,
    feedinfo,
    vcurriculumlistadd,
    vcoursewarelistadd,
    orderlistinfo,
    refundinfo,
    refundinfomerge,
    refwechatinfo,
    advtiserinfo,
    advspreadinfo,
    adverinfo,
    lookdeviceinfo,
    playerinfo,
    appealinfo,
    bondbackinfo,
    vagentlistadd,
    basic,
    vagentlist,
    vagentcensus,
    vagentdivide,
    vagentdividedetailed,
    vuseradd,
    vuseredit,
    vsaleuseradd,
    vsaleuseredit,
    vprintresourcesadd,
    vfileclassifyadd,
    vfileclassifyedit,
    vlabelmanageadd,
    vlabelmanageedit,
    vreportsingle,
    vreportsingleinfo,
    vcurriculumclassifyadd,
    vcurriculumclassifyedit,
    vcoursewareclassifyadd,
    vcoursewareclassifyedit,
    vcoursewarelistedit,
    vcurriculumlistedit,
    vworkorderlistinfo,
    vcommonproblemadd,
    vcommonproblemedit,
    vjiaoyimingxi,
    vluckydrawrecord,
    vluckydrawrecordadd,
    vluckydrawinfo,
    vluckydrawrecordedit,
    vcouponadd,
    vcouponinfo,
    vcoupondata,
    vcouponedit,
    vsharedvcmap2,
    Vexportlist,
    vofflinestatistics,
    vsetderivatives,
    vstatement,
    vdercontractlist,
    vderpartnerlist,
    vderpartnerdvclist,
    vderpartnercash,
    vderwithdrawalrecord,
    vsetderivativeslist,
    vsetderivativesadd,
    vsetderivativeslog,
    vdercontractstop,
    vdercontractstopinfo,
    vrechargememberset,
    vmemberswitchset,
    vrechargememberaccount,
    vrechargememberstatement,
    vdercontractdvclist,
    vdvcuseinfo,
    vcorporatetransferadd,
    vcorporatetransferinfo,
    vdercontractlistadd,
    vcorporatetransferlist,
    vpaymoneypostgoods,
    vsharedvccontrolfamilyedit,
    vsurplusset,
    vpostgoodslistedit,
    vpostgoodslistadd,
    vpostgoodslist,
    vcorporatetransferbankcardlist,
    vcorporatetransferbankcardinfo,
    vcorporatetransferbankcardadd,
    vaccountfd,
    voverviewfd,
    vdvcorderrankingfd,
    vdvcordercensusfd,
    vagentcensusfd,
    vintroducer,
    vnewfanscoupon,
    vyeardvccountfd,
    vagentappointdvc,
    vdeviceappointdvc,
    vheaderappointdvc,
    vmemberList,
    vmemberSet,
    vdeviceSet,
    vshopEquity,
    vmemberDvcCount,
    vmemberBuyRecord,
    vmemberWriteOff,
    vlibraryorderOther,
    vlibraryorderSortOther,
    vorderOverdueRefund,
    vnewFansCount,
    vpubNumberSet,
    vpubNumberDisDvc,
    vchannelSpreadLink,
    vbackNewsSet,
    
    // 20240206新增
    vPrintEarnings,
  },
}
</script>

<style scoped>
</style>
