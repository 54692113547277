<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'images' || mytabletitle.prop === 'user_type'">
                                <div v-if="mytabletitle.prop === 'images'">
                                    <div v-if="scope.row.images">
                                        <el-image class="imagesshow" :src="scope.row.images" :preview-src-list="[scope.row.images]" fit="cover"></el-image>
                                    </div>
                                </div>
                                <!-- 类型 -->
                                <div v-if="mytabletitle.prop === 'user_type'">
                                    <span>{{ scope.row.user_type ? this.CSP_type.filter((n) => n.value == scope.row.user_type)[0]['label'] : '' }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-售后-工单列表-->
                                <div v-if="mainthreetableope === 34">
                                    <div>
                                        <el-button type="primary" size="mini" @click="routeraddrClick('/workorderinfo', scope.row)">查看详情 </el-button>
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            v-if="scope.row.solve_status == 2 && this.mypowerlimits.gzbx_cl"
                                            @click="chuliTanClick(scope.row, (dialogVisible = true)), resetFormSenior('seniorForm')"
                                            >处理
                                        </el-button>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!--弹出框-->
            <el-dialog v-model="dialogVisible" title="处理" :width="searchwidth" :before-close="handleCloseSenior" style="margin-top: 200px">
                <el-form ref="seniorForm" :model="seniorform" :rules="rules" label-width="130px" style="padding-right: 20px">
                    <!--以下是左侧字-->
                    <el-form-item label="选择处理方式：" prop="solve_status">
                        <el-radio-group v-model="seniorform.solve_status">
                            <el-radio label="1">已解决</el-radio>
                            <el-radio label="-1">未解决</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="原因：" prop="solve_remark">
                        <el-input v-model="seniorform.solve_remark" type="textarea"></el-input>
                    </el-form-item>
                    <div style="text-align: right">
                        <el-button @click="resetFormSenior('seniorForm', (dialogVisible = false))">取消</el-button>
                        <el-button type="primary" @click="onSubmitSenior('seniorForm')">确定</el-button>
                    </div>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessage } from 'element-plus'
import axios from 'axios'
import CPS from '@/assets/js/componentProperties' // 公共数据
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vworkorderlist',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '售后'
                },
                {
                    name: '反馈信息'
                },
                {
                    name: '故障报修',
                    path: '/workorderlist'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: false, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '180px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '工单编号/设备编号',
                            name: 'keywords',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '160px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '处理状态：',
                            placeholder: '全部',
                            name: 'solve_status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: ''
                                },
                                {
                                    label: '待处理',
                                    value: '2'
                                },
                                {
                                    label: '未解决',
                                    value: '-1'
                                },
                                {
                                    label: '已解决',
                                    value: '1'
                                }
                            ]
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '故障类型：',
                            placeholder: '全部',
                            name: 'type',
                            value: '',
                            options: []
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '类型：',
                            placeholder: '全部',
                            name: 'user_type',
                            value: '0',
                            options: [
                                ...[
                                    {
                                        value: '0',
                                        label: '全部'
                                    }
                                ],
                                ...CPS.get('winuserinfo').type
                            ]
                        }
                    ]
                },
                selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
                selecttop: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '160px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '来源：',
                            placeholder: '全部',
                            name: 'thirdparty',
                            value: '1', //显示值，初始状况，显示值与默认值需相同
                            valueisshow: true,
                            valuedefault: '1', //设置默认值
                            options: componentProperties.get('thirdpartyoption').options
                        }
                    ]
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '160px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '负责人：',
                            placeholder: '请输入负责人', //此name为键值
                            name: 'win_user_id',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Statistics/userList',
                            posttype: 'get',
                            inputval: { truename: '' } //这里记录需要传的参数
                        }
                    ]
                }
            }, // 筛选-【筛选查询】-控件信息
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 34, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 150, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'uid',
                    label: 'ID',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'content',
                    label: '故障描述',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client',
                    label: '故障设备',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'user_type',
                    label: '类型',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'win_user_id',
                    label: '负责人',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'thirdparty',
                    label: '来源',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'type_name',
                    label: '故障类型',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'solve_status_name',
                    label: '处理状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'nickname',
                    label: '反馈者',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'mobile',
                    label: '联系方式',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '创建时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            // 负责人类型
            CSP_type: CPS.get('winuserinfo').type,

            //table表单相关数据
            getdatas: {
                status: null
            }, //接口获取的总数据
            //弹框-form表单数据
            seniorform: {
                id: '',
                solve_status: '-1',
                solve_remark: ''
            },
            rules: {
                solve_status: [
                    {
                        required: true,
                        message: '状态为必选项',
                        trigger: 'blur'
                    }
                ],
                solve_remark: [
                    {
                        required: true,
                        message: '原因为必填项',
                        trigger: 'blur'
                    }
                ]
            },
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {}
            }, //弹框

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    setup() {
        const dialogVisible = ref(false)
        const handleCloseSenior = (done) => {
            done()
        }

        return {
            dialogVisible,
            handleCloseSenior
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //点击按钮-弹出弹框
        chuliTanClick(taninfo) {
            this.seniorform.id = taninfo.id
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Service/faultRepairList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        thirdparty: this.pagesdatas.thirdparty,
                        solve_status: this.pagesdatas.solve_status,
                        type: this.pagesdatas.type,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        page: 1,
                        page_size: val
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Service/faultRepairList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        thirdparty: this.pagesdatas.thirdparty,
                        solve_status: this.pagesdatas.solve_status,
                        type: this.pagesdatas.type,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        page: val,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            // 携带参数跳转
            if (rowdata) {
                if (routeraddr == '/workorderinfo') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.id, type_name: rowdata.type_name }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas(tableData) {
            console.log(tableData)
        },
        //弹出框-提交表单
        onSubmitSenior(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/Service/feedDeal',
                            {
                                id: getdatas.id,
                                solve_status: getdatas.solve_status,
                                solve_remark: getdatas.solve_remark
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = false //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.reload() //刷新
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //弹出框-取消
        resetFormSenior(formName) {
            this.$refs[formName].resetFields()
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Service/faultRepairList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        thirdparty: data.thirdparty,
                        solve_status: data.solve_status,
                        type: data.type,
                        user_type: data.user_type,
                        win_user_id: data.win_user_id,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //该字段表示search按钮，且status为1表示启用，2表示禁用
                newdata[i].switchstatus = 4
                //转换创建时间
                if (newdata[i].addtime) {
                    if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                        newdata[i].addtime = '无创建时间'
                    } else {
                        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                    }
                } else {
                    newdata[i].addtime = '无创建时间'
                }
                // 转换来源
                if (newdata[i].thirdparty) {
                    if (newdata[i].thirdparty === 1) {
                        newdata[i].thirdparty = '微信自助版'
                    } else {
                        newdata[i].thirdparty = '支付宝自助版'
                    }
                }
                // 转换-故障类型
                if (newdata[i].type) {
                    let opt = this.maintwoforminfo.selectleft.select[1].options[newdata[i].type]
                    if (opt) {
                        newdata[i].type_name = opt.label
                    } else {
                        newdata[i].type_name = '--'
                    }
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },

        // 故障类型-下拉信息获取
        getrepairtypeEvent() {
            axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'fault_repair_type'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    var alldatas = Array()
                    var num = 0
                    alldatas[num] = { value: '', label: '全部' }
                    for (var key in response.data.result) {
                        num = num + 1
                        alldatas[num] = { value: key, label: response.data.result[key] }
                    }
                    //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
                    this.maintwoforminfo.selectleft.select[1].options = alldatas
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            // 获取故障类型-下拉信息
            var p1 = axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'fault_repair_type'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    var alldatas = Array()
                    var num = 0
                    alldatas[num] = { value: '', label: '全部' }
                    for (var key in response.data.result) {
                        num = num + 1
                        alldatas[num] = { value: key, label: response.data.result[key] }
                    }
                    //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
                    this.maintwoforminfo.selectleft.select[1].options = alldatas
                })
                .catch(function(error) {
                    console.log(error)
                })

            //加载loading
            this.loading = true

            // 定义
            this.pagesdatas = {
                thirdparty: '1',
                page: 1,
                page_size: this.PageSize
            }

            //通过Ajax向后台获取数据
            var p2 = axios
                .get('/admin/Service/faultRepairList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: this.pagesdatas
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })

            Promise.all([p1, p2])
                .then(() => {
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
