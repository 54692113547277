<template>
    <div>
        <el-form ref="formRef" :model="form" :rules="rules" label-width="140px">
            <el-form-item label="公众号名称：" prop="name">
                <el-input v-model="form.name" clearable maxlength="200" />
            </el-form-item>
            <el-form-item label="描述：" prop="remark">
                <el-input v-model="form.remark" type="textarea" clearable maxlength="500" show-word-limit />
            </el-form-item>
            <el-form-item label="AppID" prop="appid">
                <el-input v-model="form.appid" clearable />
            </el-form-item>
            <el-form-item label="AppSecret" prop="appsecret">
                <el-input v-model="form.appsecret" clearable />
            </el-form-item>
            <el-form-item label="原始ID" prop="originalid">
                <el-input v-model="form.originalid" clearable />
            </el-form-item>
            <el-form-item label="卡片图资源ID" prop="thumb_media_id">
                <el-input v-model="form.thumb_media_id" clearable />
            </el-form-item>
            <el-form-item label="标签ID" prop="tagid">
                <el-input v-model="form.tagid" clearable />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" style="width: 200px" @click="submitEvent('formRef')" :disabled="button_disable">保存</el-button>
                <el-button
                    @click="
                        () => {
                            this.$emit('request', false)
                            this.$refs['formRef'].resetFields()
                        }
                    "
                    >取消</el-button
                >
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'pubNumberSetAddOrEdit',
    inject: ['reload'], //刷新引用
    props: {
        flag: {
            type: Number,
            default: 1
        },
        formData: {
            type: Object,
            default() {
                return {
                    id: '',
                    name: '',
                    remark: '',
                    appid: '',
                    appsecret: '',
                    originalid: '',
                    thumb_media_id: '',
                    tagid: ''
                }
            }
        }
    },
    watch: {
        formData: {
            handler(newVal) {
                this.form = this.deepcopy(newVal)
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            form: {
                id: '',
                name: '',
                remark: '',
                appid: '',
                appsecret: '',
                originalid: '',
                thumb_media_id: '',
                tagid: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '公众号名称不能为空',
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: true,
                        message: '描述不能为空',
                        trigger: 'blur'
                    }
                ],
                appid: [
                    {
                        required: true,
                        message: 'AppID不能为空',
                        trigger: 'blur'
                    }
                ],
                appsecret: [
                    {
                        required: true,
                        message: 'AppSecret不能为空',
                        trigger: 'blur'
                    }
                ],
                originalid: [
                    {
                        required: true,
                        message: '原始ID不能为空',
                        trigger: 'blur'
                    }
                ],
                thumb_media_id: [
                    {
                        required: true,
                        message: '卡片图资源ID不能为空',
                        trigger: 'blur'
                    }
                ],
                tagid: [
                    {
                        required: true,
                        message: '标签ID不能为空',
                        trigger: 'blur'
                    }
                ]
            },

            // 前端防抖
            button_disable: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')) //获取权限
        }
    },
    methods: {
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 提交-保存
        submitEvent(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.button_disable = true
                    axios
                        .post('/admin/office/configSave', getdatas, {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.button_disable = false
                                        this.reload()
                                    }
                                })
                            } else {
                                this.button_disable = false
                            }
                        })
                        .catch(function (error) {
                            this.button_disable = false
                        })
                } else {
                    return false
                }
            })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        }
    },
    components: {}
})
</script>
<style scoped></style>
