<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
    </div>

    <!-- 概览模块 -->
    <div class="mymodel">
        <div class="mymodel-box" v-for="(item, index) in this.modeldata" :key="index">
            <div class="mymodel-box-title">
                <div class="mymodel-box-title-name">{{ item.title }}</div>
                <div class="mymodel-box-title-month">本月</div>
            </div>
            <div class="mymodel-box-content">
                <div class="mymodel-box-content-num">{{ item.unit }} {{ item.number }}</div>
                <div class="mymodel-box-content-img">
                    <el-image :src="require('@/assets/imgs/printoverview/' + item.updownicon + '.png')"></el-image>
                </div>
            </div>
            <div class="mymodel-box-foot">
                <div class="mymodel-box-foot-num">{{ item.updownicon == 'icon_up' ? '+' : '-' }} {{ item.bit }}% 环比增长</div>
                <div class="mymodel-box-foot-img">
                    <el-image :src="require('@/assets/imgs/printoverview/' + item.footicon + '.png')"></el-image>
                </div>
            </div>
        </div>
    </div>
    <!-- 订单统计 -->
    <div class="dingmodel">
        <div class="dingmodel-padding">
            <div class="dingmodel-title-box">
                <div class="dingmodel-title-name">订单统计</div>
                <div class="dingmodel-title-tab">
                    <div class="dingmodel-title-tab-button">
                        <!-- <el-button type="primary" size="small" @click="orderline('本日', 1)">本日</el-button> -->
                        <el-button type="primary" size="small" @click="orderline(7, 1)">最近7日</el-button>
                        <el-button type="primary" size="small" @click="orderline(30, 1)">最近30日</el-button>
                        <el-button type="primary" size="small" @click="orderline(365, 1)">最近一年</el-button>
                    </div>
                    <div class="dingmodel-title-tab-select">
                        <mainselect :myselectleftisshow="myselectleftisshow" :myselectleft="myselectleft" v-on:gettimesrange="(data) => myorderline(data, 1)"></mainselect>
                    </div>
                </div>
            </div>
            <div class="dingmodel-content-box">
                <mainwechat ref="linechart" :linegradualisshow="linegradualisshow" :linegradualdata="linegradual"> </mainwechat>
            </div>
        </div>
    </div>
    <!-- 代理商排行&设备排行 -->
    <!-- <div class="paihangmodel" v-if="this.usertype != 1"> -->
    <!-- <div class="paihangmodel" v-if="ishsow_fd">
        <div class="paihangmodel-box paihangmodel-box-agent">
            <div class="paihangmodel-box-title">代理商排行</div>
            <div class="paihangmodel-box-content">
                <div class="paihangmodel-title-tab">
                    <div class="paihangmodel-title-tab-button">
                        <el-button type="primary" size="small" @click="orderline('今天', 2)">本日</el-button>
                        <el-button type="primary" size="small" @click="orderline('最近7天', 2)">最近7日</el-button>
                        <el-button type="primary" size="small" @click="orderline('最近30天', 2)">最近30日</el-button>
                    </div>
                    <div class="paihangmodel-title-tab-select">
                        <mainselect :myselectleftisshow="myselectleftisshow" :myselectleft="myselectleft1" v-on:gettimesrange="(data) => myorderline(data, 2)"></mainselect>
                    </div>
                </div>
                <div class="paihangmodel-content-box">
                    <div class="paihangmodel-content-box-table">
                        <div v-for="(item, index) in this.tableData" :key="index">
                            <div class="box-table" v-if="index < 10">
                                <div class="box-table-one">
                                    <div class="box-table-num">{{ index + 1 }}</div>
                                </div>
                                隐藏 <div class="box-table-addr">{{ item.address +" &nbsp;&nbsp; "+  item.name }}</div>
                                <div class="box-table-addr">{{ item.name }}</div>
                                <div class="box-table-money">{{ item.order_total }} 元</div>
                                <div class="box-table-number">{{ item.order_count }} 单</div>
                            </div>
                        </div>
                    </div>
                    <div class="paihangmodel-content-box-button">
                        <el-button type="primary" size="small" style="width: 150px" @click="routeraddrClick('/agentcensus')" v-if="this.mypowerlimits.dlstj">查看更多 </el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="paihangmodel-box">
            <div class="paihangmodel-box-title">设备排行</div>
            <div class="paihangmodel-box-content">
                <div class="paihangmodel-title-tab">
                    <div class="paihangmodel-title-tab-button">
                        <el-button type="primary" size="small" @click="orderline('今天', 3)">本日</el-button>
                        <el-button type="primary" size="small" @click="orderline('最近7天', 3)">最近7日</el-button>
                        <el-button type="primary" size="small" @click="orderline('最近30天', 3)">最近30日</el-button>
                    </div>
                    <div class="paihangmodel-title-tab-select">
                        <mainselect :myselectleftisshow="myselectleftisshow" :myselectleft="myselectleft2" v-on:gettimesrange="(data) => myorderline(data, 3)"></mainselect>
                    </div>
                </div>
                <div class="paihangmodel-content-box">
                    <div class="paihangmodel-content-box-table">
                        <div v-for="(item, index) in this.tableData2" :key="index">
                            <div class="box-table" v-if="index < 10">
                                <div class="box-table-one">
                                    <div class="box-table-num">{{ index + 1 }}</div>
                                </div>
                                <div class="box-table-addr">
                                    {{ item.client + ' &nbsp;&nbsp; ' + (item.push_type.sort_key ? item.push_type.sort_key : '') + ' ' + item.address }}
                                </div>
                                <div class="box-table-money">{{ item.order_total }} 元</div>
                                <div class="box-table-number">{{ item.order_count }} 单</div>
                            </div>
                        </div>
                    </div>
                    <div class="paihangmodel-content-box-button">
                        <el-button type="primary" size="small" style="width: 150px" @click="routeraddrClick('/dvcordercensus')" v-if="this.mypowerlimits.sbddtj"
                            >查看更多
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import mainone from '@/components/Mainone'
import mainwechat from '@/components/Mainecharts.vue'
import mainselect from '@/components/Mainselect'
import * as echarts from 'echarts'
import axios from 'axios'
import { defineComponent } from 'vue'
import { ref } from 'vue'

export default defineComponent({
    name: 'voverview',
    data() {
        return {
            mainonebread: [
                {
                    name: '统计'
                },
                {
                    name: '打印流水统计'
                },
                {
                    name: '打印概览统计'
                }
            ],

            // 概览模块
            modeldata: [
                {
                    title: '订单',
                    number: '0',
                    updownicon: 'icon_up',
                    bit: '0',
                    footicon: 'icon_1'
                },
                {
                    title: '收入',
                    unit: '¥',
                    number: '0',
                    updownicon: 'icon_up',
                    bit: '0',
                    footicon: 'icon_2'
                },
                {
                    title: '代理商',
                    number: '0',
                    updownicon: 'icon_up',
                    bit: '0',
                    footicon: 'icon_3'
                },
                {
                    title: '用户',
                    number: '0',
                    updownicon: 'icon_up',
                    bit: '0',
                    footicon: 'icon_4'
                }
            ],

            // 订单统计-自定义时间段
            myselectleftisshow: true, //筛选查询-左侧字+select单框||select时间
            myselectleft: {
                width: 'none',
                divinterval: '', //框右侧距离
                selectwidth: '150px',
                selectinterval: '', //select右侧距离
                select: [
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'ordertime',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },
            myselectleft1: {
                width: 'none',
                divinterval: '20px', //框右侧距离
                selectwidth: '200px',
                selectinterval: '', //select右侧距离
                select: [
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'topagent',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        selectmaxwidth: '100%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },
            myselectleft2: {
                width: 'none',
                divinterval: '20px', //框右侧距离
                selectwidth: '200px',
                selectinterval: '', //select右侧距离
                select: [
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'topclient',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        selectmaxwidth: '100%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },

            linegradualisshow: true, //折现图-渐变堆叠
            linegradual: [
                {
                    idname: 'linegradual',
                    width: '100%',
                    height: '400px',
                    text: '', //折线图-标题
                    maincolor: ['#80FFA5', '#00DDFF'], //定义主色调，且有几个模块，定义几个，[数组string类型]
                    legenddata: ['订单数量', '总金额', 'A6图片金额', 'A4文档金额'], //定义模块，[数组string类型]
                    subtextx: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], //图表X轴-标题
                    gridleft: '5%', //图表距离左侧距离
                    gridright: '5%', //图表距离右侧距离
                    gridbottom: '5%', //图表距离底部距离
                    data: [
                        {
                            name: '订单数量', //与上方模块定义的名对应
                            type: 'line',
                            stack: 'total',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(128, 255, 165)' //渐变的颜色1
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(1, 191, 236)' //渐变的颜色2
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: [] //对应图表X轴上的数据
                        },
                        {
                            name: '总金额',
                            type: 'line',
                            stack: 'count',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(0, 221, 255)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(77, 119, 255)'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                        {
                            name: 'A6图片金额',
                            type: 'line',
                            stack: 'a6photo',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },

                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(0, 255, 0)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(0, 0, 255)'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: []
                        },
                        {
                            name: 'A4文档金额',
                            type: 'line',
                            stack: 'a4word',
                            smooth: true,
                            lineStyle: {
                                width: 0
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(0, 255, 0)'
                                    },

                                    {
                                        offset: 1,
                                        color: 'rgba(0, 0, 255)'
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },

                            data: []
                        }
                    ]
                }
            ],

            loading1: false,
            loading2: false,

            tableData: [],
            tableData2: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            // 判断是否为资金方
            usertype: 1,

            ishsow_fd: false
        }
    },
    methods: {
        // 获取概览模块-数据
        getinfo() {
            axios
                .get('/admin/Statistics/linfo', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        let data = response.data.result
                        // 本月订单
                        this.modeldata[0].number = data.monthOrder
                        // 本月订单-较上月同期增长&下降||比例计算
                        if (parseFloat(data.monthOrder) >= parseFloat(data.lastMonthOrder)) {
                            this.modeldata[0].updownicon = 'icon_up'
                            if (data.lastMonthOrder != 0) {
                                this.modeldata[0].bit = ((parseFloat(data.monthOrder) - parseFloat(data.lastMonthOrder)) / parseFloat(data.lastMonthOrder)).toFixed(0)
                            } else {
                                if (data.lastMonthOrder == 0 && data.monthOrder != 0) {
                                    this.modeldata[0].bit = '100'
                                }
                            }
                        } else {
                            this.modeldata[0].updownicon = 'icon_down'
                            if (data.lastMonthOrder != 0) {
                                this.modeldata[0].bit = ((parseFloat(data.lastMonthOrder) - parseFloat(data.monthOrder)) / parseFloat(data.lastMonthOrder)).toFixed(0)
                            } else {
                                if (data.lastMonthOrder == 0) {
                                    this.modeldata[0].bit = '0'
                                }
                            }
                        }

                        // 本月收入
                        this.modeldata[1].number = data.monthInCome
                        // 本月收入-较上月同期增长&下降||比例计算
                        if (parseFloat(data.monthInCome) >= parseFloat(data.lastMonthInCome)) {
                            this.modeldata[1].updownicon = 'icon_up'
                            if (data.lastMonthInCome != 0) {
                                this.modeldata[1].bit = ((parseFloat(data.monthInCome) - parseFloat(data.lastMonthInCome)) / parseFloat(data.lastMonthInCome)).toFixed(0)
                            } else {
                                if (data.lastMonthInCome == 0 && data.monthInCome != 0) {
                                    this.modeldata[1].bit = '100'
                                }
                            }
                        } else {
                            this.modeldata[1].updownicon = 'icon_down'
                            if (data.lastMonthInCome != 0) {
                                this.modeldata[1].bit = ((parseFloat(data.lastMonthInCome) - parseFloat(data.monthInCome)) / parseFloat(data.lastMonthInCome)).toFixed(0)
                            } else {
                                if (data.lastMonthInCome == 0) {
                                    this.modeldata[1].bit = '0'
                                }
                            }
                        }

                        // 本月代理商
                        this.modeldata[2].number = data.monthShare
                        // 本月代理商-较上月同期增长&下降||比例计算
                        if (parseFloat(data.monthShare) >= parseFloat(data.lastMonthShare)) {
                            this.modeldata[2].updownicon = 'icon_up'
                            if (data.lastMonthShare != 0) {
                                this.modeldata[2].bit = ((parseFloat(data.monthShare) - parseFloat(data.lastMonthShare)) / parseFloat(data.lastMonthShare)).toFixed(0)
                            } else {
                                if (data.lastMonthShare == 0 && data.monthShare != 0) {
                                    this.modeldata[2].bit = '100'
                                }
                            }
                        } else {
                            this.modeldata[2].updownicon = 'icon_down'
                            if (data.lastMonthShare != 0) {
                                this.modeldata[2].bit = ((parseFloat(data.lastMonthShare) - parseFloat(data.monthShare)) / parseFloat(data.lastMonthShare)).toFixed(0)
                            } else {
                                if (data.lastMonthShare == 0) {
                                    this.modeldata[2].bit = '0'
                                }
                            }
                        }

                        // 本月用户数
                        this.modeldata[3].number = data.userData
                        // 本月代理商-较上月同期增长&下降||比例计算
                        if (parseFloat(data.userData) >= parseFloat(data.lastUserData)) {
                            this.modeldata[3].updownicon = 'icon_up'
                            if (data.lastUserData != 0) {
                                this.modeldata[3].bit = ((parseFloat(data.userData) - parseFloat(data.lastUserData)) / parseFloat(data.lastUserData)).toFixed(2)
                            } else {
                                if (data.lastUserData == 0 && data.userData != 0) {
                                    this.modeldata[3].bit = '100'
                                }
                            }
                        } else {
                            this.modeldata[3].updownicon = 'icon_down'
                            if (data.lastUserData != 0) {
                                this.modeldata[3].bit = ((parseFloat(data.lastUserData) - parseFloat(data.userData)) / parseFloat(data.lastUserData)).toFixed(2)
                            } else {
                                if (data.lastUserData == 0) {
                                    this.modeldata[3].bit = '0'
                                }
                            }
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        // 订单统计||代理商排行||设备排行-自定义时间筛选
        myorderline(data, flag) {
            let start = data.valuestart.valueOf() / 1000
            let end = data.valueend.valueOf() / 1000

            var getordertime = ''
            if (start == '') {
                getordertime = ''
            } else {
                if (end == '') {
                    getordertime = start + ' ~ ' + (start + 86400 - 1)
                } else {
                    getordertime = start + ' ~ ' + (end + 86400 - 1)
                }
            }
            this.orderline(getordertime, flag)
        },

        // 时间函数-事件
        getTimeEvent(flag) {
            // 获取今天的时间戳 10位
            let toData = new Date(new Date().toLocaleDateString()).getTime() / 1000

            // 获取-本日-时间段
            if (flag == 1) {
                let todayStart = toData //开始时间
                let todayEnd = todayStart + 86400 - 1 //结束时间
                console.log(todayStart, todayEnd)
                let timeduan = todayStart + ' ~ ' + todayEnd
                return timeduan
            }
            // 获取-最近7日-时间段
            if (flag == 7) {
                let past7daysStart = toData - 7 * 3600 * 24
                let past7daysEnd = toData - 1
                console.log(past7daysStart, past7daysEnd)
                let timeduan = past7daysStart + ' ~ ' + past7daysEnd
                return timeduan
            }
            // 获取-最近30日-时间段
            if (flag == 30) {
                let past30daysStart = toData - 30 * 3600 * 24
                let past30daysEnd = toData - 1
                console.log(past30daysStart, past30daysEnd)
                let timeduan = past30daysStart + ' ~ ' + past30daysEnd
                return timeduan
            }
            // 获取-最近1年-时间段
            if (flag == 365) {
                let past365daysStart = toData - 365 * 3600 * 24
                let past365daysEnd = toData - 1
                console.log(past365daysStart, past365daysEnd)
                let timeduan = past365daysStart + ' ~ ' + past365daysEnd
                return timeduan
            }
        },

        // 订单统计||代理商排行||设备排行-获取接口数据
        orderline(searchtime, flag) {
            let mysearchtime = searchtime
            // 本日
            if (searchtime == 1) {
                mysearchtime = this.getTimeEvent(1)
            }
            // 最近7日
            if (searchtime == 7) {
                mysearchtime = this.getTimeEvent(7)
            }
            // 最近30日
            if (searchtime == 30) {
                mysearchtime = this.getTimeEvent(30)
            }
            // 最近一年
            if (searchtime == 365) {
                mysearchtime = this.getTimeEvent(365)
            }

            let ajaxapi = ''
            // 订单统计
            if (flag == 1) {
                ajaxapi = '/admin/Statistics/lorderLine'
            }
            // // 代理商排行
            // if (flag == 2) {
            //     ajaxapi = '/admin/Statistics/ltopAgent'
            //     this.loading1 = true
            // }
            // // 设备排行
            // if (flag == 3) {
            //     ajaxapi = '/admin/Statistics/ltopClient'
            //     this.loading2 = true
            // }

            axios
                .get(ajaxapi, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        search_time: mysearchtime
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        // 订单统计
                        if (flag == 1) {
                            let orderdata = response.data.result
                            this.bindline(orderdata)
                        }
                        // 代理商排行
                        if (flag == 2) {
                            // 判断是否为资金方
                            this.usertype = response.data.result.userType

                            this.tableData = response.data.result.data
                            this.loading1 = false
                        }
                        // 设备排行
                        if (flag == 3) {
                            this.tableData2 = response.data.result.data
                            this.loading2 = false
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        // 订单统计-折线图
        bindline(orderdata) {
            this.linegradual[0].subtextx = orderdata.show
            this.linegradual[0].data[0].data = orderdata.count
            this.linegradual[0].data[1].data = orderdata.total
            this.linegradual[0].data[2].data = orderdata.a6photo
            this.linegradual[0].data[3].data = orderdata.a4word
            this.$refs.linechart.echartGradual(this.linegradual, 0)
        },

        // 路由跳转
        routeraddrClick(addr) {
            this.$router.push({ path: addr })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // 加载loading
            this.loading1 = true //通过Ajax向后台获取数据
            this.loading2 = true //通过Ajax向后台获取数据
            // 通过Ajax向后台获取数据
            // 概览统计-数据
            this.getinfo()
            // 订单统计-数据
            this.orderline(7, 1)
            // // 代理商排行-数据
            // this.orderline('今天', 2)
            // // 设备排行-数据
            // this.orderline('今天', 3)
        }
    },
    components: {
        mainone,
        mainwechat,
        mainselect
    }
})
</script>

<style scoped>
/* 概览模块 */
.mymodel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.mymodel-box {
    margin: 10px 15px 15px 10px;
    background-color: #002eb7;
    color: #ffffff;
    padding: 20px 5px 5px 20px;
    border-radius: 10px;
    min-width: 200px;
    flex-grow: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mymodel-box-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mymodel-box-title-name {
    font-size: 20px;
}

.mymodel-box-title-month {
    font-size: 14px;
    width: 70px;
    color: #ffffffd0;
}

.mymodel-box-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
}

.mymodel-box-content-num {
    font-size: 36px;
    font-weight: bold;
    margin-right: 20px;
}

.mymodel-box-foot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mymodel-box-foot-num {
    font-size: 14px;
}

.mymodel-box-foot-img .el-image {
    border-bottom-right-radius: 20px;
    width: 70px;
}

/* 订单统计 */
.dingmodel {
    background-color: #ffffff;
    width: 100%;
    min-height: 450px;
    border-radius: 4px;
    margin-top: 10px;
}

.dingmodel-padding {
    padding: 10px;
}

.dingmodel-title-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 40px;
    line-height: 40px;
}

.dingmodel-title-name {
    margin-left: 10px;
    font-size: 14px;
}

.dingmodel-title-tab {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dingmodel-title-tab-button {
    margin-right: 10px;
}

.dingmodel-content-box {
    margin: 20px 30px 0 0;
}

/* 代理商排行&设备排行 */
.paihangmodel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 10px 0;
}

.paihangmodel-box {
    flex-grow: 1;
    min-width: 400px;
}

.paihangmodel-box-agent {
    padding-right: 40px;
}

.paihangmodel-box-title {
    font-weight: 500;
    margin: 20px 0 10px 0;
}

.paihangmodel-box-content {
    min-height: 300px;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px 20px;
}

.paihangmodel-title-tab {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eaedf7;
}

.paihangmodel-title-tab-button {
    margin-right: 10px;
}

.paihangmodel-content-box {
    margin: 10px 0 0 0;
}

.paihangmodel-content-box-table {
    min-height: calc(250px - 70px);
}

.paihangmodel-content-box-button {
    margin-top: 20px;
    height: 50px;
    text-align: center;
}

/* 代理商排行&设备排行-table内容列表 */
.box-table {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    height: 50px;
    border-bottom: 1px solid #eaedf7;
    line-height: 50px;
    font-size: 14px;
    color: var(--el-text-color-regular);
}

.box-table-one {
    max-width: 50px;
    flex-grow: 1;
}

.box-table-num {
    margin-top: 10px;
    width: 30px;
    height: 30px;
    background-color: var(--el-color-primary);
    color: #ffffff;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    border-radius: 50px;
}

.box-table-addr {
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex-grow: 10;
}

.box-table-money {
    max-width: 200px;
    text-align: right;
    flex-grow: 1;
}

.box-table-number {
    max-width: 100px;
    text-align: right;
    flex-grow: 1;
}
</style>
