<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">{{ activename_option[this.activename] }}-编辑</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="5">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="14">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
                                <el-form-item label="名称：" prop="name">
                                    <el-input v-model.trim="ruleForm.name" placeholder="请输入名称" clearable maxlength="10" show-word-limit></el-input>
                                </el-form-item>
                                <el-form-item label="企业微信：" prop="wecom">
                                    <el-select v-model="ruleForm.wecom" placeholder="请选择企业微信" style="width: 100%">
                                        <el-option :label="item.corp_name" :value="item.id" v-for="(item, index) in wecom_options" :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="企微人员：" prop="wecom_user">
                                    <el-input v-model.trim="ruleForm.wecom_user" placeholder="请输入企微人员" clearable maxlength="10" show-word-limit></el-input>
                                </el-form-item>
                                <el-form-item label="企微二维码链接：" prop="wecom_url">
                                    <el-input v-model.trim="ruleForm.wecom_url" placeholder="请输入企微二维码链接" clearable maxlength="200" show-word-limit></el-input>
                                </el-form-item>
                                <el-form-item label="描述：" prop="remark">
                                    <el-input
                                        v-model="ruleForm.remark"
                                        type="textarea"
                                        maxlength="500"
                                        show-word-limit
                                        :autosize="{ minRows: 4 }"
                                        placeholder="请输入描述"
                                        clearable
                                    />
                                </el-form-item>
                                <el-form-item label="绑定地区：" prop="bind_info" v-if="this.activename == 3 || this.activename == 5">
                                    <modelCity
                                        :value="ruleForm.bind_info"
                                        :props="{ multiple: true, checkStrictly: true, emitPath: false }"
                                        :placeholder="'请绑定地区'"
                                        :collapsetags="false"
                                        :filterable="true"
                                        @changecity="changecityEvent"
                                    />
                                </el-form-item>
                                <el-form-item label="绑定代理商：" prop="bind_info" v-if="this.activename == 2 || this.activename == 4">
                                    <modelBind
                                        :ModelValue="ruleForm.bind_info"
                                        Flag="agent"
                                        :DialogTan="true"
                                        PostUrl="/admin/shopUser/index"
                                        :SearchModel="agent_forminfo"
                                        :TableTitle="agent_tabletitle"
                                        @modelValue="modelValueEvent"
                                    />
                                    <div style="margin-top: 5px">
                                        <modelUploadExcel
                                            :PostDatas="{ type: 2 }"
                                            :Download="UploadExcelInfo.download"
                                            :DownloadName="UploadExcelInfo.downloadName_agent"
                                            :Tips="UploadExcelInfo.tips_agent"
                                            @getUploadDatas="getUploadDatasEvent"
                                        />
                                    </div>
                                </el-form-item>
                                <el-form-item label="绑定设备：" prop="bind_info" v-if="this.activename == 1">
                                    <modelBind
                                        :ModelValue="ruleForm.bind_info"
                                        Flag="device"
                                        :DialogTan="true"
                                        PostUrl="/admin/Equipment/clientList"
                                        :SearchModel="device_forminfo"
                                        :TableTitle="device_tabletitle"
                                        @modelValue="modelValueEvent"
                                    />
                                    <div style="margin-top: 5px">
                                        <modelUploadExcel
                                            :PostDatas="{ type: 1 }"
                                            :Download="UploadExcelInfo.download"
                                            :DownloadName="UploadExcelInfo.downloadName_client"
                                            :Tips="UploadExcelInfo.tips_client"
                                            @getUploadDatas="getUploadDatasEvent"
                                        />
                                    </div>
                                </el-form-item>
                                <el-form-item label="跳转类型：" prop="jump_type">
                                    <el-radio-group v-model="ruleForm.jump_type">
                                        <el-radio label="1">强制加企微-送新粉优惠券</el-radio>
                                        <el-radio label="3">强制加企微-不送新粉优惠券</el-radio>
                                        <el-radio label="2">非强制加企微-送新粉优惠券</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="状态：" prop="status">
                                    <el-radio-group v-model="ruleForm.status">
                                        <el-radio label="1">开启</el-radio>
                                        <el-radio label="-1">关闭</el-radio>
                                        <el-radio label="2">禁用</el-radio>
                                    </el-radio-group>
                                    <div style="line-height: 24px; color: #666666">
                                        <div><span>开启：扫设备码，跳转加企微。</span></div>
                                        <div><span>关闭：关闭该设置的规则。</span></div>
                                        <div>
                                            <span>
                                                禁用：该规则地区/代理商/设备下，不跳转加企微。【区域设置】郑州是禁用，在【代理商单独设置】某个代理商有郑州的设备是开启。结果是该代理商下设备是启用，该郑州其他下设备是禁用。（满足：设备独立设备>代理商独立设置>地区设备）
                                            </span>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                    <el-button @click="this.$router.go(-1)">返回</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="5">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import modelCity from './modelCity.vue'
import modelBind from './modelBind.vue'
import modelUploadExcel from './modelUploadExcel.vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vminipluswecomedit',
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '企业微信'
                },
                {
                    name: '小程序加企业微信',
                    path: '/minipluswecom'
                },
                {
                    name: '编辑'
                }
            ], // 当前页-【面包屑导航】

            ruleForm: {
                name: '',
                wecom: '',
                wecom_user: '',
                wecom_url: '',
                remark: '',
                bind_info: '',
                jump_type: '1',
                status: '-1'
            }, //form表单

            rules: {
                name: [
                    {
                        required: true,
                        message: '名称为必填项',
                        trigger: 'change'
                    }
                ],
                wecom: [
                    {
                        required: true,
                        message: '企业微信为必选项',
                        trigger: 'change'
                    }
                ],
                wecom_user: [
                    {
                        required: true,
                        message: '企微人员为必填项',
                        trigger: 'change'
                    }
                ],
                wecom_url: [
                    {
                        required: true,
                        message: '企微二维码链接为必填项',
                        trigger: 'change'
                    }
                ],
                remark: [
                    {
                        required: true,
                        message: '描述为必填项',
                        trigger: 'change'
                    }
                ],
                bind_info: [
                    {
                        required: true,
                        message: '当前字段为必选项',
                        trigger: 'change'
                    }
                ],
                jump_type: [
                    {
                        required: true,
                        message: '跳转类型为必选项',
                        trigger: 'change'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态为必选项',
                        trigger: 'change'
                    }
                ]
            }, //form表单-必填规则

            wecom_options: [], // 企业微信下拉

            // 代理商-弹窗-搜索框
            agent_forminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '代理商名称/手机号',
                            name: 'keywords',
                            content: ''
                        }
                    ]
                }
            },
            // 代理商-弹窗-table表头
            agent_tabletitle: [
                {
                    fixedstatu: false,
                    prop: 'id',
                    label: 'ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '代理商名称',
                    width: '130'
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号/账号',
                    width: '130'
                },
                {
                    fixedstatu: false,
                    prop: 'level',
                    label: '代理等级',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'province',
                    label: '省级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'city',
                    label: '市级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'county',
                    label: '县/区级',
                    width: ''
                }
            ],

            // 设备-弹窗-搜索框
            device_forminfo: {
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号：',
                            placeholder: '请输入设备编号', //此name为键值
                            name: 'slientId',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/getClientList',
                            inputval: { client: '' } //这里记录需要传的参数
                        }
                    ]
                },
                myselectcityleftisshow: true, //筛选查询-selectcity选框
                myselectcityleft: {
                    selectwidth: '100%',
                    select: {
                        ismust: false,
                        title: '选择区域：',
                        name: 'area_code',
                        area_code: '',
                        valuesheng: '',
                        valueshi: '',
                        valuequ: '',
                        disshi: true,
                        disqu: true
                    }
                }
            },
            // 设备-弹窗-table表头
            device_tabletitle: [
                {
                    fixedstatu: false,
                    prop: 'id',
                    label: 'ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'client',
                    label: '设备编号',
                    width: '130'
                },
                {
                    fixedstatu: false,
                    prop: 'shop_code_end',
                    label: '代理商名称',
                    width: '130'
                },
                {
                    fixedstatu: false,
                    prop: 'address',
                    label: '设备地址',
                    width: ''
                }
            ],

            activename: '',
            activename_option: { 1: '设备独立设置', 2: '代理商独立设置', 3: '地区设置', 4: '(商家)代理商独立设置', 5: '(商家)地区设置' },

            // 绑定设备||代理商-批量导入按钮相关
            UploadExcelInfo: {
                download: {
                    is_show: true,
                    btn_title: '下载Excel模板'
                },
                downloadName_agent: 'admin_shop_id',
                tips_agent: '温馨提示：\n1、请根据模板批量导入，一次只能导入一个Excel文件 \n导入失败常见原因：\n1、列表存在该代理商，重复上传\n2、当前规则，存在该代理商，重复上传',

                downloadName_client: 'admin_client_id',
                tips_client: '温馨提示：\n1、请根据模板批量导入，一次只能导入一个Excel文件 \n导入失败常见原因：\n1、列表存在该设备，重复上传\n2、当前规则，存在该设备，重复上传'
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token
        }
    },

    methods: {
        // Excel批量导入
        getUploadDatasEvent(data) {
            this.ruleForm.bind_info = { ...this.ruleForm.bind_info, ...data }
        },
        // 选中地区
        changecityEvent(data) {
            this.ruleForm.bind_info = data
        },
        // 【代理商&设备】返回数据
        modelValueEvent(data) {
            this.ruleForm.bind_info = data
        },
        //from表单-提交表单
        submitForm(formName) {
            var getdata = this.$refs[formName].model
            // console.log(getdata)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let my_bind_info = ''
                    if (this.activename == 3 || this.activename == 5) {
                        my_bind_info = getdata.bind_info.join(',')
                    }

                    if (this.activename == 2 || this.activename == 1 || this.activename == 4) {
                        my_bind_info = Object.keys(getdata.bind_info).join(',')
                    }

                    axios
                        .post(
                            '/admin/Service/wecomEdit',
                            {
                                id: this.$route.query.id,
                                type: this.activename,
                                name: getdata.name,
                                wecom: getdata.wecom,
                                wecom_user: getdata.wecom_user,
                                wecom_url: getdata.wecom_url,
                                remark: getdata.remark,
                                bind_info: my_bind_info,
                                jump_type: getdata.jump_type,
                                status: getdata.status
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.go(-1) // 返回上一页
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //from表单-重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        // 获取企业微信-下拉
        getWecomOptions() {
            axios
                .get('/admin/Service/wecom', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.wecom_options = response.data.result.data
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.activename = this.$route.query.activename
            this.getWecomOptions()

            let back_data = JSON.parse(this.$route.query.back_data)
            let bind_info = null
            if (this.activename == 3 || this.activename == 5) {
                bind_info = back_data.bind_info.split(',')
            }
            if (this.activename == 2 || this.activename == 4) {
                bind_info = back_data.shop_names
            }
            if (this.activename == 1) {
                bind_info = back_data.clients
            }
            // 反显
            this.ruleForm = {
                name: back_data.name,
                wecom: back_data.wecom,
                wecom_user: back_data.wecom_user,
                wecom_url: back_data.wecom_url,
                remark: back_data.remark,
                bind_info: bind_info,
                jump_type: back_data.jump_type != null ? back_data.jump_type.toString() : '',
                status: back_data.status != null ? back_data.status.toString() : ''
            } //form表单
        }
    },
    components: {
        mainone,
        modelCity,
        modelBind,
        modelUploadExcel
    }
})
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
