<template>
    <div>
        <!-- 多选-显示&反显框 -->
        <div>
            <el-button type="primary" :plain="buttonSet.plain" :size="buttonSet.size" @click="clickAddEvent">{{ buttonSet.text }}</el-button>
            <slot name="show_content"></slot>
        </div>
        <!-- 弹窗 -->
        <div v-if="dialogtan">
            <modelDialog :isshow="dialogVisible" :title="tanTitle" v-on:beforeclose="handleClose">
                <template v-slot:search>
                    <modelSearch :gaoisshow="false" :forminfo="maintwoforminfo" v-on:getsearchinfo="getSeachinfoEvent" />
                </template>
                <template v-slot:table>
                    <dpwTable
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        ref="myTableDataRef"
                        :tabletitle="tabletitle"
                        :tabledata="tableData"
                        :totalcount="totalCount"
                        :currentpage="currentPage"
                        :pagesize="PageSize"
                        :Lastpage="lastpage"
                        :Operation="{ isshow: false }"
                        :selectionIsShow="true"
                        @handleSelection="handleSelectionEvent"
                        @handleSelectionOnline="handleSelectionChangeOnline"
                    >
                        <template #content="{ scope, prop }">
                            <div v-if="prop === 'img'">
                                <el-image :src="scope.row.img" style="width: 100px" />
                            </div>
                            <div v-else>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                    </dpwTable>
                </template>
                <template v-slot:pages>
                    <modelPages
                        :totalCount="totalCount"
                        :currentPage="currentPage"
                        :pageSizes="pageSizes"
                        :PageSize="PageSize"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </template>
                <template v-slot:footbtn>
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="submitEvent(this.multipleSelection, (dialogVisible = false))">确定</el-button>
                </template>
            </modelDialog>
        </div>
    </div>
</template>

<script>
import modelDialog from '@/vspecial/operate/wecom/minipluswecom/modelDialog.vue'
import modelSearch from '@/vspecial/operate/wecom/minipluswecom/modelSearch.vue'
import dpwTable from '../memberSet/dpw-table.vue'
import modelPages from '@/vspecial/operate/wecom/minipluswecom/modelPages.vue'
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
    name: 'modelBindCommon',
    inject: ['reload'],
    props: ['ModelValue', 'Flag', 'DialogTan', 'SearchModel', 'TableTitle', 'PostUrl', 'PostAxios', 'PostDatas', 'ButtonSet', 'TanTitle'],
    watch: {
        ModelValue: {
            handler(newVal) {
                this.modelvalue = newVal
            },
            deep: true,
            immediate: true
        },
        Flag: {
            handler(newVal) {
                this.flag = newVal
            },
            deep: true,
            immediate: true
        },
        DialogTan: {
            handler(newVal) {
                this.dialogtan = newVal
            },
            deep: true,
            immediate: true
        },
        PostUrl: {
            handler(newVal) {
                this.posturl = newVal
            },
            deep: true,
            immediate: true
        },
        PostAxios: {
            handler(newVal) {
                this.postaxios = newVal
            },
            deep: true,
            immediate: true
        },
        PostDatas: {
            handler(newVal) {
                this.postdatas = newVal ? newVal : {}
            },
            deep: true,
            immediate: true
        },
        SearchModel: {
            handler(newVal) {
                this.maintwoforminfo = newVal
            },
            deep: true,
            immediate: true
        },
        TableTitle: {
            handler(newVal) {
                this.tabletitle = newVal
            },
            deep: true,
            immediate: true
        },
        ButtonSet: {
            handler(newVal) {
                this.buttonSet = newVal ? newVal : { text: 'text', size: 'small', plain: true }
            },
            deep: true,
            immediate: true
        },
        TanTitle: {
            handler(newVal) {
                this.tanTitle = newVal ? newVal : '弹窗'
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            modelvalue: {},
            flag: '',

            dialogVisible: false,
            default_multipleSelection: [],
            tabletitle: [],
            tableData: [],
            has_tableData: [],
            Operation: { isshow: false, width: 120 },

            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            currentPage: 1,
            pageSizes: [10, 20, 50, 100],
            PageSize: 10,

            multipleSelection: [], //多选的数据

            maintwoforminfo: {},

            posturl: '',
            postaxios: 'post',
            pagesdatas: '',
            postdatas: {},

            buttonSet: {},
            tanTitle: ''
        }
    },
    methods: {
        // ********* 多选 *********
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 去重
        reduce(datas) {
            let obj = {}
            let reduce = []
            reduce = datas.reduce(function (item, next) {
                //item为没有重复id的数组，next为当前对象
                obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
                return item
            }, [])

            return reduce
        },
        // 弹窗-多选数据-选中
        handleSelectionEvent(datas) {
            let all_datas = [...this.multipleSelection, ...this.deepcopy(datas)]
            // 去重
            this.multipleSelection = this.reduce(all_datas)

            if (datas.length == 0) {
                this.multipleSelection = []
            }
        },
        // 弹窗-多选数据-取消
        handleSelectionChangeOnline({ datas, row }) {
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id)
            this.handleSelectionEvent(datas)
        },
        // 弹窗-多选-检查反显
        moreDataBackShow(datas) {
            if (this.$refs.myTableDataRef) {
                this.$refs.myTableDataRef.backShow(datas)
            }
        },
        removeDuplicateObjects(arr_1, arr_2) {
            // 使用 filter() 方法从 arr1 中过滤掉与 arr2 相同的对象
            let arr1 = arr_1.map((n) => {
                return n.id
            })
            let arr2 = arr_2.map((n) => {
                return n.id
            })

            const filteredArray = arr1.concat(arr2).filter((item) => !arr1.includes(item) || !arr2.includes(item))
            console.log('filteredArray:', filteredArray)
            let has_remove = arr_1
                .map((n) => {
                    if (filteredArray.includes(n.id)) {
                        return n
                    }
                })
                .filter((n) => n)

            return has_remove
        },
        // 点击-出现弹窗
        clickAddEvent() {
            this.postAxios({ ...this.postdatas, ...this.pagesdatas })
            // console.log('this.tableData:', this.tableData)
            // console.log('this.deepcopy(this.modelvalue):', this.deepcopy(this.modelvalue))
            // this.default_multipleSelection = this.removeDuplicateObjects(this.deepcopy(this.modelvalue), this.tableData)
            // console.log('this.default_multipleSelection:', this.default_multipleSelection)
            this.default_multipleSelection = this.deepcopy(this.modelvalue)
            this.dialogVisible = true

            this.multipleSelection = this.default_multipleSelection
            setTimeout(() => {
                this.moreDataBackShow(this.default_multipleSelection)
            }, 100)
        },
        // 弹窗-关闭弹窗
        handleClose(done) {
            done()
            this.dialogVisible = false
        },
        // ************ 多选（end） *********************
        // 弹窗-确定
        submitEvent(datas) {
            this.modelvalue = datas
            this.$emit('modelValue', this.modelvalue)
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas.page = 1
            this.pagesdatas.page_size = val

            this.postAxios(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas.page = val
            this.pagesdatas.page_size = this.PageSize

            this.postAxios(this.pagesdatas)
        },

        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('data:', data)
            this.pagesdatas = { ...this.postdatas, ...data }

            this.pagesdatas.page = 1
            this.pagesdatas.page_size = this.PageSize

            this.postAxios(this.pagesdatas)
        },
        // 接口
        postAxios(datas) {
            this.loading = true
            if (this.postaxios == 'get') {
                axios
                    .get(this.posturl, {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: datas
                    })
                    .then((response) => {
                        this.myaxiosfunc(response.data.result)
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            } else {
                axios
                    .post(this.posturl, datas, {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    })
                    .then((response) => {
                        this.myaxiosfunc(response.data.result)
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                if (newdata[i].area_code) {
                    //将省市区分割
                    var arrcity = newdata[i].area_code.split(',')
                    newdata[i].province = arrcity[0]
                    newdata[i].city = arrcity[1]
                    newdata[i].county = arrcity[2]
                }
            }
            this.tableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page
            this.lastpage = successdata.last_page
            this.loading = false

            this.moreDataBackShow(this.multipleSelection)
        }
    },
    created() {
        // this.loading = true
        // this.pagesdatas = {
        //     page: 1,
        //     page_size: this.PageSize
        // }
        // this.postAxios(this.pagesdatas)
    },
    components: {
        modelDialog,
        modelSearch,
        dpwTable,
        modelPages
    }
})
</script>

<style scoped>
.model-class {
    width: 100%;
    min-height: 40px;
    border: 1px solid var(--el-border-color-base);
    border-radius: 4px;
    background-color: #fff;
}

.model-class:hover {
    cursor: pointer;
    border-color: var(--el-text-color-placeholder);
}
.model-class-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;

    padding: 0px 5px;
}
.mx-1 {
    margin-right: 8px;
}
</style>
