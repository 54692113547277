<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">超期退款订单提交</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="5">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="12">
                            <div style="margin: 30px 0 60px 0; text-align: center">
                                <span style="font-size: 30px; color: #333333">超期退款订单提交</span>
                            </div>
                            <div>
                                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="0px" class="demo-ruleForm">
                                    <!--登录用户名-->
                                    <el-form-item prop="content">
                                        <div class="belong-input">
                                            <div class="belong-input-box">
                                                <el-input v-model="this.ruleForm.content" placeholder="请输入订单编号" maxlength="30" show-word-limit clearable> </el-input>
                                            </div>
                                            <div class="belong-input-button">
                                                <el-button type="primary" @click="submitForm('ruleForm')" style="width: 120px" :disabled="mydisabled">查询</el-button>
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item>
                                        <div style="color: #999999">
                                            <span class="belong-text">归属小程序：</span>
                                            <span v-if="loading">查询中<i class="el-icon-loading"></i></span>
                                            <span v-if="!loading" class="find-box" :style="{ color: this.show == 1 ? '#333333' : '#999999' }">
                                                {{ this.mininame }}
                                            </span>
                                        </div>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                    <div v-if="Object.keys(reuslt_data).length > 0">
                        <el-divider />
                        <!-- 查询详情 -->
                        <div>
                            <div style="display: flex; flex-direction: row; justify-content: left; align-items: center; gap: 10px">
                                <div style="width: 5px; height: 20px; background: #465eff"></div>
                                <div style="font-size: 16px"><span>查询详情</span></div>
                            </div>
                            <div style="padding: 10px 0">
                                <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px"></maindetails>
                                <div style="margin-bottom: 20px; margin-top: 10px" v-if="this.print_type == 2">
                                    <div style="min-width: 80px; float: left; margin-right: 20px">
                                        <span style="font-size: 14px; color: #333333; margin-left: 8px">证件照电子版:</span>
                                    </div>
                                    <div>
                                        <mainimgesshow :imgesdata="mainpagedata.imgesdata"></mainimgesshow>
                                    </div>
                                </div>
                                <el-divider></el-divider>
                                <maindetails :detailsdata="mainpagedata.details1" style="margin-top: 20px">
                                    <template #contentJumpbt="{ detailsdatainfo }">
                                        <div v-if="this.printstatus == 1" style="color: #465eff; cursor: pointer" @click="addTanClick(getrowdata, (dialogVisible = true))">
                                            {{ detailsdatainfo.jumpbt }}
                                        </div>
                                        <div v-if="this.printstatus == 2">
                                            {{ detailsdatainfo.jumpbt }}
                                        </div>
                                    </template>
                                </maindetails>
                                <el-divider></el-divider>
                                <maindetails :detailsdata="mainpagedata.details2"></maindetails>
                                <el-divider></el-divider>
                                <maindetails :detailsdata="mainpagedata.details3"></maindetails>
                            </div>
                        </div>
                        <!-- 添加退款申请-按钮 -->
                        <div style="margin: 60px 60px 60px 0; text-align: center">
                            <el-button type="primary" @click="submitFormAdd(reuslt_data)" style="width: 300px" :disabled="mydisabled_add">添加退款申请</el-button>
                        </div>
                        <!--弹出框-打印记录-->
                        <el-dialog v-model="dialogVisible" title="打印记录" :width="this.searchwidth" :before-close="handleCloseSenior">
                            <div>
                                <el-table :data="formdata" style="width: 100%" border>
                                    <el-table-column label="文档信息" prop="file">
                                        <template #default="scope">
                                            <div>
                                                <div style="font-size: 16px; color: #333333; margin: 10px 0">
                                                    {{ scope.row.file.filename }}
                                                </div>
                                                <div style="font-size: 13px; color: #999999">
                                                    打印范围 {{ scope.row.file.start }}~{{ scope.row.file.end }} 页 {{ scope.row.file.number }} 份
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="打印状态" prop="status" width="150">
                                        <template #default="scope">
                                            <div>
                                                <div
                                                    :style="{
                                                        color: scope.row.status == '待打印' ? '#f56c6c' : '#67c23a'
                                                    }"
                                                >
                                                    {{ scope.row.status }}
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import axios from 'axios'
import maindetails from '@/components/Maindetails'
import mainimgesshow from '@/components/Mainimgesshow'
import { ElMessage } from 'element-plus'

export default {
    name: 'vorderOverdueRefund',
    inject: ['reload'],
    data() {
        var checkInputEventInt = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf('.')
                if (result != -1) {
                    callback(new Error('请输入整数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入数字'))
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error('请输入数字'))
                        } else {
                            if (value < 0) {
                                callback(new Error('请输入非负数'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '订单'
                },
                {
                    name: '打印管理'
                },
                {
                    name: '超期退款订单提交'
                }
            ], // 当前页-【面包屑导航】

            ruleForm: {
                content: ''
            }, //form表单数据
            rules: {
                content: [
                    {
                        required: true,
                        message: '订单编号为必填项',
                        trigger: 'blur'
                    },
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ]
            }, //form表单填写规则-前端判断是否必填

            // 查询归属小程序名
            mininame: '',
            show: 0,
            // 查询详情
            reuslt_data: {},
            mainpagedata: {
                mainonebread: [
                    {
                        name: '订单'
                    },
                    {
                        name: '打印管理'
                    },
                    {
                        name: '订单列表',
                        path: '/orderlist'
                    },
                    {
                        name: '订单详情'
                    }
                ], // 当前页-【面包屑导航】

                // 打印类型-判断是否需要展示-证件照图片框
                print_type: 1,

                datas: {
                    pay_status_name: ''
                },
                details: {
                    showstatus: 1, //确定样式模块
                    title: '订单详情',
                    button: {
                        isshow: false,
                        name: '查看微信投诉详情',
                        jump: ''
                    },
                    content: []
                }, //关于详情信息的组件
                details1: {
                    showstatus: 1, //确定样式模块
                    title: '打印详情',
                    button: {
                        isshow: false,
                        name: '',
                        jump: ''
                    },
                    content: []
                }, //关于详情信息的组件
                details2: {
                    showstatus: 1, //确定样式模块
                    title: '支付详情',
                    button: {
                        isshow: false,
                        name: '',
                        jump: ''
                    },
                    content: []
                }, //关于详情信息的组件
                details3: {
                    showstatus: 1, //确定样式模块
                    title: '用户详情',
                    button: {
                        isshow: false,
                        name: '',
                        jump: ''
                    },
                    content: []
                }, //关于详情信息的组件
                imgesdata: {
                    showstatus: 2,
                    content: []
                } //关于图片展示的组件
            }, // 当前Pagedata所需的data数据与控制

            // 打印中-弹窗
            dialogVisible: false,
            searchwidth: '580px',
            formdata: [
                {
                    file: {
                        filename: ' ',
                        start: '',
                        end: '',
                        number: 1
                    },
                    status: ''
                }
            ],
            // 打印中-状态-维护字段-【当数据为空时，打印状态不可点击,1可以点击,2不可点击】
            printstatus: 1,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: localStorage.getItem('userinfo'), //获取存储的用户信息

            loading: false, //加载动效
            mydisabled: false, // 防抖-前端维护

            mydisabled_add: false // 防抖-前端维护（添加退款申请）按钮
        }
    },
    methods: {
        //提交form表单-并判断是否必填
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    // 前端防抖
                    this.mydisabled = true
                    axios
                        .post(
                            '/admin/shareorder/overdueReturnInfo',
                            {
                                content: this.ruleForm.content
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                if (response.data.result && Object.keys(response.data.result).length > 0) {
                                    this.mininame = '#' + response.data.result['mini_name']
                                    this.reuslt_data = response.data.result
                                    this.showDatasEvent(this.reuslt_data)
                                } else {
                                    this.mininame = response.data.message
                                    this.reuslt_data = {}
                                }
                            } else {
                                this.mininame = response.data.message
                                this.reuslt_data = {}
                            }
                            this.show = 1
                            this.loading = false
                            this.mydisabled = false
                        })
                        .catch(function () {
                            this.show = 0
                            this.loading = false
                            this.mydisabled = false
                            this.reuslt_data = {}
                        })
                } else {
                    this.reuslt_data = {}
                    this.mininame = '#查询后，此显示订单归属的小程序名称'
                    this.show = 0
                    return false
                }
            })
        },
        showDatasEvent(getdatas) {
            this.mainpagedata.datas = getdatas

            var content1 = [] //订单详情
            content1 = [
                {
                    name: '订单编号:',
                    value: this.mainpagedata.datas.trade_no
                },
                {
                    name: '订单状态:',
                    value: this.mainpagedata.datas.pay_status_name
                },
                {
                    name: '设备编号:',
                    value: this.mainpagedata.datas.ShareClient ? this.mainpagedata.datas.ShareClient.client : ''
                },
                {
                    name: '代理商:',
                    value: this.mainpagedata.datas.shop_user_name
                },
                {
                    name: '平台来源:',
                    value: this.mainpagedata.datas.thirdparty_plateform
                }
            ]
            this.mainpagedata.details.content = content1
            // 若ShareOrderFile为空数组、打印状态不可点
            this.shareOrderFileStatus = this.mainpagedata.datas.ShareOrderFile ? (this.mainpagedata.datas.ShareOrderFile.length > 0 ? 1 : 0) : 0
            var content2 = [] //打印详情
            content2 = [
                {
                    name: '打印状态:',
                    value: '',
                    jumpbt: getdatas.print_status_name + ' >>'
                },
                {
                    name: '打印分类:',
                    value: this.mainpagedata.datas.SharePrintCate.printtype_name
                },
                {
                    name: '打印类型:',
                    value: this.mainpagedata.datas.SharePrintCate.aliasname
                },
                {
                    name: '总份数:',
                    value: this.mainpagedata.datas.countnumber + '份'
                },
                {
                    name: '总页数:',
                    value: this.mainpagedata.datas.countpages + '页'
                },
                {
                    name: '打印费(含服务费):',
                    value: this.mainpagedata.datas.printtotal
                },
                {
                    name: '文档使用费:',
                    value: this.mainpagedata.datas.mobetotal
                },
                {
                    name: '三方服务费:',
                    value: this.mainpagedata.datas.mattingprice
                },
                {
                    name: '设备会员:',
                    value: this.mainpagedata.datas.monthlytotal
                },
                {
                    name: '文库类型:',
                    value: this.mainpagedata.datas.sourcetype_name
                }
            ]
            this.mainpagedata.details1.content = content2

            //支付详情
            var content3 = []
            content3 = [
                {
                    name: '优惠方式:',
                    value: this.mainpagedata.datas.discount_type_name
                },
                {
                    name: '优惠金额:',
                    value: this.mainpagedata.datas.discount_price.length > 0 ? this.mainpagedata.datas.discount_price.join('\n') : ''
                },
                {
                    name: '实付金额:',
                    value: this.mainpagedata.datas.sftotal
                },
                {
                    name: '支付方式:',
                    value: this.mainpagedata.datas.paytype_name
                },
                {
                    name: '支付时间:',
                    value: this.mainpagedata.datas.pay_time == null ? '' : this.$utils.formatDate(this.mainpagedata.datas.pay_time)
                }
            ]
            this.mainpagedata.details2.content = content3

            //用户详情
            var content4 = []
            content4 = [
                {
                    name: '用户手机号:',
                    value: this.mainpagedata.datas.mobile
                },
                {
                    name: '用户ID:',
                    value: this.mainpagedata.datas.uid
                },
                {
                    name: '创建时间:',
                    value: this.$utils.formatDate(this.mainpagedata.datas.addtime)
                }
            ]
            this.mainpagedata.details3.content = content4

            // 证件照-通过打印类型判断是否显示，1不显示，2显示
            this.print_type = getdatas.print_type
            //渲染-图片（证件照）
            let getimages = []
            if (getdatas.ShareOrderFile) {
                getdatas.ShareOrderFile.forEach((element) => {
                    let data = {
                        url: element.file_path,
                        preview: [element.file_path]
                    }
                    getimages.push(data)
                })
            }
            this.mainpagedata.imgesdata.content = getimages

            // 显示-弹窗-打印记录信息
            let printinfo = getdatas.ShareOrderFile
            let nowform = []
            if (printinfo.length > 0) {
                printinfo.forEach((element) => {
                    let datas = {
                        file: {
                            filename: element.file_name,
                            start: element.start,
                            end: element.end,
                            number: element.number
                        },
                        status: element.status_name
                    }
                    nowform.push(datas)
                })
            }
            this.formdata = nowform
            // 打印记录显示与否判断-维护点击状态字段
            if (nowform.length > 0) {
                this.printstatus = 1
            } else {
                this.printstatus = 2
            }

            return
        },
        // 添加退款申请-按钮-提交
        submitFormAdd(datas) {
            if (Object.keys(datas).length == 0) {
                ElMessage({
                    type: 'error',
                    message: '订单编号错误，无法提交！'
                })
            } else {
                this.mydisabled_add = true
                axios
                    .post(
                        '/admin/shareorder/overdueReturn',
                        {
                            content: datas.trade_no
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        if (response.data.code == 0) {
                            ElMessage({
                                type: 'success',
                                message: '提交成功',
                                duration: 1000,
                                onClose: () => {
                                    this.reload()
                                }
                            })
                        }
                        this.mydisabled_add = false
                    })
                    .catch(function () {
                        this.mydisabled_add = false
                    })
            }
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            this.mininame = '#查询后，此显示订单归属的小程序名称'
            this.show = 0
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333;
    padding: 5px 20px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}

.belong-input {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.belong-input-box {
    width: 100%;
    margin-right: 20px;
}

.belong-text {
    font-size: 16px;
    color: #333333;
    margin-right: 30px;
}

.find-box {
    font-size: 16px;
}

.flex-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.flex-box-item {
    font-size: 14px;
    color: #333333;
    line-height: 40px;
    min-width: 300px;
    max-width: 500px;
}
</style>
