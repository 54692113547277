<template>
    <div id="mymain">
        <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">
                        <span style="margin-right: 40px"> 查看代理商</span>
                    </div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <!--基本信息-->
                    <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px"></maindetails>
                    <!--身份证信息-->
                    <div class="idcard_no_box">
                        <div class="box-list-on">
                            <div class="box-list-on-title">
                                <span>身份证号:</span>
                            </div>
                            <div>
                                <span>{{ this.mainpagedata.details.idcard_no }}</span>
                            </div>
                        </div>
                        <div class="box-list-on">
                            <div class="box-list-on-title">
                                <span>身份证正面照:</span>
                            </div>
                            <div>
                                <mainimgesshow :imgesdata="mainpagedata.details.imgesdata_on"></mainimgesshow>
                            </div>
                        </div>
                        <div class="box-list-on">
                            <div class="box-list-on-title">
                                <span>身份证反面照:</span>
                            </div>
                            <div>
                                <mainimgesshow :imgesdata="mainpagedata.details.imgesdata_off"></mainimgesshow>
                            </div>
                        </div>
                    </div>
                    <el-divider />
                    <!-- 介绍人 -->
                    <div v-if="is_show_pull">
                        <maindetails :detailsdata="mainpagedata.details1" style="margin-top: 20px"></maindetails>
                        <el-divider />
                    </div>
                    <!--table表单-->
                    <div style="margin-top: 20px">
                        <i class="el-icon-s-data"></i>
                        <span class="table-box-list"> 操作记录</span>
                    </div>
                    <div class="mytable" style="margin-bottom: 20px">
                        <el-table :data="mainpagedata.tableData" :row-class-name="tableRowClassName" border
                            style="width: 100%; margin-top: 10px">
                            <el-table-column prop="source_type" label="平台" width="200" />
                            <el-table-column prop="truename" label="操作者" width="200" />
                            <el-table-column prop="addtime" label="操作时间" width="200" />
                            <el-table-column prop="remark" label="操作类型" />
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mainone from '@/components/Mainone'
    import maindetails from '@/components/Maindetails'
    import mainimgesshow from '@/components/Mainimgesshow'
    import axios from 'axios'

    export default {
        name: 'vagentlistinfo',
        data() {
            return {
                mainpagedata: {
                    mainonebread: [
                        {
                            name: '合作伙伴'
                        },
                        {
                            name: '代理商管理'
                        },
                        {
                            name: '代理商列表',
                            path: '/agentlist'
                        },
                        {
                            name: '代理商详情',
                            path: '/agentlistinfo'
                        }
                    ], // 当前页-【面包屑导航】
                    sales_people_datas: [],
                    after_people_datas: [],

                    details: {
                        showstatus: 1, //确定样式模块
                        title: '基本信息',
                        titlewidth: '150px', //新增title最小宽度
                        button: {
                            isshow: false,
                            name: '',
                            jump: '' //点击跳转
                        },
                        content: [
                            {
                                name: '姓名:',
                                value: ''
                            },
                            {
                                name: '手机号:',
                                value: ''
                            },
                            {
                                name: '代理商是否分成:',
                                value: ''
                            },
                            {
                                name: '默认佣金比例:',
                                value: ''
                            },
                            {
                                name: '销售人员/渠道经理:',
                                value: ''
                            },
                            {
                                name: '售后人员:',
                                value: ''
                            },
                            {
                                name: '选择地区:',
                                value: ''
                            },
                            {
                                name: '价格参数修改权限:',
                                value: ''
                            },
                            {
                                name: '是否自动提现:',
                                value: ''
                            },
                            {
                                name: '机器广告图修改权限:',
                                value: ''
                            },
                            {
                                name: '订单退款权限:',
                                value: ''
                            },
                            {
                                name: '提现微信:',
                                value: ''
                            },
                            {
                                name: '提现支付宝:',
                                value: ''
                            },
                            {
                                name: '状态:',
                                value: ''
                            }
                        ],
                        idcard_no: '', // 身份证号
                        imgesdata_on: {
                            showstatus: 2,
                            fit: 'contain',
                            content: [
                                // {
                                //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                                //   preview: [
                                //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                                //   ],
                                // },
                            ]
                        },
                        imgesdata_off: {
                            showstatus: 2,
                            fit: 'contain',
                            content: [
                                // {
                                //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                                //   preview: [
                                //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                                //   ],
                                // },
                            ]
                        }
                    }, //关于详情信息的组件

                    // 介绍人
                    is_show_pull: false,
                    details1: {
                        showstatus: 1, //确定样式模块
                        title: '介绍人',
                        titlewidth: '150px', //新增title最小宽度
                        button: {
                            isshow: false,
                            name: '',
                            jump: '' //点击跳转
                        },
                        content: [
                            {
                                name: '介绍人:',
                                value: ''
                            },
                            {
                                name: '分佣设备:',
                                value: ''
                            },
                            {
                                name: '介绍人佣金比例:',
                                value: ''
                            },
                            {
                                name: '分佣有效期:',
                                value: ''
                            },
                            {
                                name: '分佣状态:',
                                value: ''
                            }
                        ]
                    }, //关于详情信息的组件

                    tableData: [
                        // {
                        //   id: 665,
                        //   source_type: 1,
                        //   truename: "管理员",
                        //   remark: "佣金比例【0】修改为【0.002】",
                        //   addtime: 1638345438,
                        // },
                    ] //操作记录-table列表
                }, // 当前Pagedata所需的data数据与控制
                mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
                mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

                loading: false
            }
        },
        methods: {
            tableRowClassName({ rowIndex }) {
                if ((rowIndex + 1) % 2 === 0) {
                    return 'dabuleque'
                }
                return ''
            },
            routeraddrClick(routeraddr) {
                //点击进入路由页面
                this.$router.push(routeraddr).catch((error) => error)
            },
            getPersonName(id, type) {
                let truename = ''
                if (type == 1) {
                    for (let item of this.sales_people_datas) {
                        if (item.id == id) {
                            truename = item.truename
                            break
                        }
                    }
                }
                if (type == 2) {
                    for (let item of this.after_people_datas) {
                        if (item.id == id) {
                            truename = item.truename
                            break
                        }
                    }
                }
                return truename
            }
        },
        created() {
            if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
                this.isshow = false
                this.$router.push('/').catch((error) => error)
            } else {
                this.loading = true

                //通过API配置获取-销售/售后数据列表-用于下拉框
                var p1 = axios
                    .get('/admin/Admin/userSaleList', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            dept_type: 1
                        }
                    })
                    .then((response) => {
                        this.sales_people_datas = response.data.result.data
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
                var p2 = axios
                    .get('/admin/Admin/userSaleList', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            dept_type: 2
                        }
                    })
                    .then((response) => {
                        this.after_people_datas = response.data.result.data
                    })
                    .catch(function (error) {
                        console.log(error)
                    })

                //通过Ajax向后台获取数据
                var p3 = axios
                    .post(
                        '/admin/shopUser/info',
                        {
                            id: this.$route.query.id
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        var getdatas = response.data.result.info
                        // 渲染-广告信息
                        this.mainpagedata.details.content = [
                            {
                                name: '姓名:',
                                value: getdatas.name
                            },
                            {
                                name: '手机号:',
                                value: getdatas.username
                            },
                            {
                                name: '代理商是否分成:',
                                value: getdatas.is_ratio == 1 ? '是' : '否'
                            },
                            {
                                name: '默认佣金比例:',
                                value: getdatas.ratio
                            },
                            {
                                name: '销售人员/渠道经理:',
                                value: this.getPersonName(getdatas.sales_people, 1)
                            },
                            {
                                name: '售后人员:',
                                value: this.getPersonName(getdatas.after_people, 2)
                            },
                            {
                                name: '选择地区:',
                                value: getdatas.area_code_name
                            },
                            {
                                name: '价格参数修改权限:',
                                value: getdatas.is_save_attr == 1 ? '有' : '无'
                            },
                            {
                                name: '是否自动提现:',
                                value: getdatas.is_forthwith == 1 ? '是' : '否' + ' [每月提现：' + getdatas.month_draw_times + '次，限制金额：' + getdatas.month_draw_minmoney + '元]'
                            },
                            {
                                name: '机器广告图修改权限:',
                                value: getdatas.is_save_adv == 1 ? '有' : '无'
                            },
                            {
                                name: '订单退款权限:',
                                value: getdatas.refund_audit == 1 ? '有' : '无'
                            },
                            {
                                name: '提现微信:',
                                value: getdatas.wechat_user_id != 0 ? getdatas.wechat_user_id : '',
                                jumpbt: getdatas.wechat_user_id == 0 || getdatas.wechat_user_id == null ? '' : '[查看]',
                                jump: '/userlist',
                                jumpdatas: { userid: getdatas.wechat_user_id, thirdparty: 1 }
                            },
                            {
                                name: '提现支付宝:',
                                value: getdatas.alipay_user_id != 0 ? getdatas.alipay_user_id : '',
                                jumpbt: getdatas.alipay_user_id == 0 || getdatas.alipay_user_id == null ? '' : '[查看]',
                                jump: '/userlist',
                                jumpdatas: { userid: getdatas.alipay_user_id, thirdparty: 1 }
                            },
                            {
                                name: '状态:',
                                value: getdatas.status == 1 ? '启用' : getdatas.status == -1 ? '禁用' : '已删除'
                            }
                        ]

                        // 反显-身份证相关
                        this.mainpagedata.details.idcard_no = getdatas.idcard_no
                        this.mainpagedata.details.imgesdata_on.content =
                            getdatas.idcard_pic_front && getdatas.idcard_pic_front != null
                                ? [
                                    {
                                        url: getdatas.idcard_pic_front,
                                        preview: [getdatas.idcard_pic_front]
                                    }
                                ]
                                : []
                        this.mainpagedata.details.imgesdata_off.content =
                            getdatas.idcard_pic_back && getdatas.idcard_pic_back != null
                                ? [
                                    {
                                        url: getdatas.idcard_pic_back,
                                        preview: [getdatas.idcard_pic_back]
                                    }
                                ]
                                : []

                        // 介绍人
                        this.is_show_pull = getdatas.pull_user == null ? false : true
                        if (getdatas.pull_user != null) {
                            let pull_status = { '-1': '已清除', 1: '分佣中', 2: '已过期' }
                            this.mainpagedata.details1.content = [
                                {
                                    name: '介绍人:',
                                    value: getdatas.pull_user.name
                                },
                                {
                                    name: '分佣设备:',
                                    value: getdatas.pull_user.client
                                },
                                {
                                    name: '介绍人佣金比例:',
                                    value: getdatas.pull_user.ratio
                                },
                                {
                                    name: '分佣有效期:',
                                    value: getdatas.pull_user.valid_month == 0 ? '永久' : getdatas.pull_user.valid_month + '个月'
                                },
                                {
                                    name: '分佣状态:',
                                    value: pull_status[getdatas.pull_user.status]
                                }
                            ]
                        }

                        // 渲染-table表单数据
                        var newdata = response.data.result.log
                        for (var i = 0; i < newdata.length; i++) {
                            // 转换-平台来源- 1租户平台 2商户平台 3总平台 5业务平台
                            let my_source_type = { 1: '租户平台', 2: '商户平台', 3: '总平台', 5: '业务平台' }
                            newdata[i].source_type = my_source_type[newdata[i].source_type]

                            //转换添加时间
                            if (newdata[i].addtime) {
                                if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                                    newdata[i].addtime = '无添加时间'
                                } else {
                                    newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                                }
                            } else {
                                newdata[i].addtime = '无添加时间'
                            }
                        }
                        this.mainpagedata.tableData = newdata
                    })
                    .catch(function (error) {
                        console.log(error)
                    })

                Promise.all([p1, p2, p3])
                    .then(() => {
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log('error:失败:', error)
                        this.loading = false
                    })
            }
        },
        components: {
            mainone,
            maindetails,
            mainimgesshow
        }
    }
</script>

<style scoped>
    .box {
        background-color: #ffffff;
        border-radius: 4px;
        color: #333333;
        padding: 10px 20px;
    }

    .box_title {
        line-height: 40px;
        height: 40px;
    }

    .box_title_font {
        float: left;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
    }

    .box_title_button {
        float: right;
        font-weight: 400;
        font-size: 14px;
        color: #94a3d2;
    }

    .box_title_button:hover {
        cursor: pointer;
    }

    .box_content {
        border-top: 1px solid #eeeeee;
        margin-top: 10px;
    }

    .table-box-list {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
    }

    .idcard_no_box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        align-items: flex-start;
    }

    .box-list-on {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: left;
        align-items: flex-start;
        gap: 20px;
        width: 500px;
        margin: 10px 0;
    }

    .box-list-on-title {
        width: 150px;
        text-align: right;
        font-size: 14px;
    }
</style>