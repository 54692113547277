<template>
  <div>
    <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box"  >
        <div class="box_title"  >
          <div class="box_title_font"><i class="el-icon-s-operation"></i>添加课件</div>
          <div class="box_title_button">
            <!--可添加按钮-->
            <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-form ref="ruleForm"
                  :model="ruleForm"
                  :rules="rules"
                  label-width="120px"
                  class="demo-ruleForm" style="margin-top: 20px">
            <div>
              <el-row>
                <!--占位符-->
                <el-col :span="24" :xs="24" :sm="24" :lg="7">
                  <div style="width: 100%"></div>
                </el-col>
                <el-col :span="24" :xs="24" :sm="24" :lg="9">
                  <el-form-item label="课件名称：" prop="title">
                    <el-input v-model="ruleForm.title"></el-input>
                  </el-form-item>
                  <el-form-item label="课件分类：" prop="category">
                    <el-select
                      v-model="ruleForm.category"
                      placeholder="请选择"
                      style="width: 100%"
                      @visible-change="finduserSaleList()"
                    >
                      <el-option
                        :label="getsales.title"
                        :value="getsales.id"
                        v-for="(getsales, i) in ruleForm.category_data"
                        :key="i"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="是否试看：" prop="is_try_read">
                    <el-radio-group v-model="ruleForm.is_try_read">
                      <el-radio label="1">是</el-radio>
                      <el-radio label="2">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="课件格式：" prop="type">
                    <el-radio-group v-model="ruleForm.type">
                      <el-radio label="1">文档</el-radio>
                      <el-radio label="2">视频</el-radio>
                      <el-radio label="3">音频</el-radio>
                    </el-radio-group>
                    <div>
                      <span>支持格式：文档doc，docx，PPT，pptx，pdf，视频mp4、3gp、m3u8，音频mp3、acc、wav</span>
                    </div>
                  </el-form-item>
                  <el-form-item label="附件：" prop="file_url">
                    <mainimgesup v-model="ruleForm.file_url" :myimgesup="mainpagedata.myimgesup" :myimgesupisshow="mainpagedata.myimgesupisshow" v-on:getuploadfile="(data) => getUploadFileEvent(data)"></mainimgesup>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%;margin-right: 10px">确认
                    </el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                  </el-form-item>
                </el-col>
                <!--占位符-->
                <el-col :span="24" :xs="24" :sm="24" :lg="8">
                  <div style="width: 100%"></div>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import {ref} from "vue";
import axios from "axios";
import {ElMessage} from 'element-plus'

export default {
  name: "curadd",
  data() {
    return {
      mainpagedata: {
        mainonebread : [
          {
            name:'教育',

          },
          {
            name:'课程管理',

          },
          {
            name:'课件列表',
            path:'/coursewarelist'
          },
          {
            name:'添加课件',
            path:'/coursewarelistadd'
          }
        ], // 当前页-【面包屑导航】

        myinputishow: true, //form表单-input单框
        myinput: {
          inputwidth: '100%',
          inputmaxwidth: '400px',
          input: [
            {
              ismust: true, //是否必填，true表示必填
              title: '课件名称：',
              placeholder: '请输入课程名称',
              name: 'name',
              content: ''
            },
          ]
        },
        myselectisshow: true, //form表单-select单框
        myselect: {
          selectwidth: '100%',
          selectmaxwidth: '400px',
          select: [
            {
              selectisshow: true,
              ismust: true,
              title: '课件分类：',
              placeholder: '全部',
              name: 'fenlei',
              value: '',
              options: [
                {
                  value: '0',
                  label: '全部',
                },
                {
                  value: '1',
                  label: '一级代理',
                },
                {
                  value: '2',
                  label: '二级代理',
                },
                {
                  value: '3',
                  label: '三级代理',
                },
              ],
            },
          ]
        },
        myimgesupisshow: true, //form表单-imgesuploade上传图片
        myimgesup: {
          uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
          listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
          ismust: true, //是否必填，true表示必填
          action: "/api/admin/upload/file", //upload上传API地址
          headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
          title: "选择上传文件", //button-按钮文字
          tips: "", //提示&建议文字
          limit: "1", //上传数量限制
          target_id: 3, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
          rules: {
            files: {
              size: 200, //文件大小（单位kb）
              type: [
                "text/plain",
                "application/pdf",
                "application/msword",
              ], //文件格式
            },
          },
        },
        myradioisshow: true, //form表单-radio单选框
        myradio: {
          radio: [
            {
              titlewidth: '100px',
              ismust: true,
              title: '课件格式：',
              name: 'status',
              value: ref("1"),
              radio: [
                {
                  content: "文档",
                  label: "1"
                },
                {
                  content: "视频",
                  label: "2"
                },
                {
                  content: "音频",
                  label: "3"
                }
              ]
            }
          ]
        }, //form表单-radio选框
        myradio1: {
          radio: [
            {
              titlewidth: '100px',
              ismust: true,
              title: '是否试看：',
              name: 'status',
              value: ref("1"),
              radio: [
                {
                  content: "是",
                  label: "1"
                },
                {
                  content: "否",
                  label: "-1"
                }
              ]
            }
          ]
        }, //form表单-radio选框


      }, // 当前页面所需的data数据与控制
      mytoken: localStorage.getItem('token'),  //获取存储的token

      tableData: [
        {
          id: 1,
          showedit: true,
          showadd: true,
          showdel: true,
          name: '章节01',
          children: [
            {
              id: 11,
              showdel: true,
              name: '初级项目管理课程【视频】',
            },
            {
              id: 12,
              showdel: true,
              name: '初级项目管理课程【视频】',
            },
          ]
        },
        {
          id: 2,
          showedit: true,
          showadd: true,
          showdel: true,
          name: '章节02',
          children: [
            {
              id: 21,
              showdel: true,
              name: '初级项目管理课程【视频】',
            },
            {
              id: 22,
              showdel: true,
              name: '初级项目管理课程【视频】',
            },
          ],
        },
        {
          id: 3,
          showedit: true,
          showadd: true,
          showdel: true,
          name: '章节03',
          children: [
            {
              id: 31,
              showdel: true,
              name: '初级项目管理课程【视频】',
            },
            {
              id: 32,
              showdel: true,
              name: '初级项目管理课程【视频】',
            },
          ],
        },
      ],
      ruleForm: {
        title: '',
        category: '',
        category_data: [],
        is_try_read:ref('1'),
        type:ref('1'),
        file_url: '',
        time_length: 0,
        pages: 0
      },
      rules: {
        title: [
          {
            required: true,
            message: '分类名称为必填项',
            trigger: 'blur',
          },
        ],
        category: [
          {
            required: true,
            message: '排序为必填项',
            trigger: 'blur',
          },
        ],
        is_try_read: [
          {
            required: true,
            message: '请选择一个状态',
            trigger: 'change',
          },
        ],
        type: [
          {
            required: true,
            message: '请选择一个类型',
            trigger: 'change',
          },
        ],
        file_url: [
          {
            required: true,
            message: '请上传附件',
            trigger: 'blur',
          },
        ],
      },//form表单-必填规则
    }
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false
      this.$router.push('/').catch(error => error)
    } else {
      // //通过Ajax向后台获取数据
      // axios.post('/admin/shopUser/index',
      //     {
      //       addtime: '2020-10-01~2021-10-31' //该字段为必传项-当空值，表示查询当月数据
      //     },
      //     {
      //       headers: {
      //         'Authentication': this.mytoken
      //       }
      //     })
      //     .then(response => {
      //       this.myaxiosfunc(response.data.result)
      //     })
      //     .catch(function (error) {
      //       console.log(error)
      //     })
    }
  },
  mounted() {

  },
  methods: {
    // 获取下拉框数据
    finduserSaleList() {
      axios.post("/admin/Course/indexCourseware",
          {

          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
        })
        .then((response) => {
          console.log(response)
          this.ruleForm.category_data = response.data.result.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //提交form表单-并判断是否必填
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post('/admin/Course/addCourseCate',
          {
            title: this.$refs[formName].model.title,
            category: this.$refs[formName].model.category,
            type: this.$refs[formName].model.type,
            file_url: this.$refs[formName].model.file_url,
            is_try_read: this.$refs[formName].model.is_try_read,
            time_length: this.$refs[formName].model.time_length,
            pages: this.$refs[formName].model.pages,
          },
          {
            headers: {
              'Authentication': this.mytoken
            }
          })
          .then((response) => {
            if (response.data.code === 0) {
              ElMessage({
                type: "success",
                message: "提交成功！",
              });
            }
          })
          .catch(function (error) {
            console.log(error)
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置form表单中填写的数据
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //获取upload子组件回传的数据
    getUploadFileEvent(data) {
      console.log("当前获取的回传数据：" + JSON.stringify(data));
      this.ruleForm.file_url = data.url;
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    }
  },
  components: {
    mainone,
    mainimgesup,
  }
}
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #97A0C3;
  line-height: 60px;
  padding: 10px 30px;
  margin-top: 20px;
  margin-bottom: 80px
}

.box_title {
  line-height: 40px;
  height: 40px;
  margin-top: 10px
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #616e9d;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px
}

/*******以下是form表单中button的控件css **************************************/
.main_one_button {
  float: right;
}

.main_one_button .el-button:hover, .myaddbutton .el-button:hover {
  background-color: #ffffff;
  border: 1px solid #94a3d2;
  color: #94a3d2;
  font-size: 14px;
}

.main_one_button .el-button, .myaddbutton .el-button {
  border: 1px solid #002EB7;
  color: #002EB7;
  background-color: #ffffff;
}

.el-button {
  border-radius: 8px;
  letter-spacing: 1px !important;
}

.el-button i {
  font-size: 14px;
  font-weight: bold;
}

.el-button:active {
  color: #ffffff !important;
  background-color: #002EB7 !important;
}

.main_two_search {
  float: right;
  background-color: #002EB7 !important;
  color: #ffffff !important;
  border-radius: 8px;
}

.main_two_search:hover {
  background-color: #ffffff !important;
  border: 1px solid #94a3d2;
  color: #94a3d2 !important;
  font-size: 14px;
}

.main_two_search:active {
  color: #ffffff !important;
  background-color: #002EB7 !important;
}

/*******the end - 以上是form表单中button的控件css **************************************/

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

</style>