<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
            :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="boxlist">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
                        :row-class-name="tableRowClassName" :data="
                            mainthreetableData.slice(
                                (currentPage - lastpage) * PageSize,
                                currentPage * PageSize
                            )
                        " style="width: 100%" min-height="900" border v-loading="loading"
                        element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange"
                        @cell-click="handleOneData">
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i"
                            :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label"
                            :width="mytabletitle.width">
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="
                                mytabletitle.prop === 'client' ||
                                mytabletitle.prop === 'status' ||
                                mytabletitle.prop === 'income_money' ||
                                mytabletitle.prop === 'sign_end_time'
                            ">
                                <!--设备编号-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'client'">
                                    <span>{{ scope.row.client }}</span>
                                </div>
                                <!--合同状态-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <span
                                        :style="{ 'color': scope.row.status == 1 ? '#02CB48' : (scope.row.status == -1 ? '#FE8F47' : '#909399') }">
                                        {{
                                            scope.row.status ? this.maintwoforminfo.selectleft.select[0].options.filter(
                                                n => n.value == scope.row.status)[0]['label'] : ''
                                        }}</span>
                                </div>
                                <!--产生收益-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'income_money'">
                                    <span>
                                        {{
                                            scope.row.income_money || scope.row.income_money == 0 ?
                                                (parseInt(scope.row.income_money) / 100).toFixed(2) : ''
                                        }}
                                    </span>
                                </div>
                                <!-- 租赁时间-样式等渲染 -->
                                <div v-if="mytabletitle.prop === 'sign_end_time'">
                                    <!-- <div v-if="[-2,-1,1,null].indexOf(scope.row.status) != -1">
                                        <span>{{ scope.row.signtime ? this.$utils.formatDate(scope.row.signtime).substr(0, 10) : '' }}</span>
                                    </div>
                                    <div v-else>
                                        <span>{{ scope.row.signtime ? this.$utils.formatDate(scope.row.signtime).substr(0, 10) : '' }}</span>
                                        <span> 至 </span>
                                        <span>{{ scope.row.endtime ? this.$utils.formatDate(scope.row.endtime).substr(0, 10) : '' }}</span>
                                    </div> -->
                                    <div v-if="scope.row.status != null">
                                        <span>{{
                                            scope.row.signtime ?
                                                this.$utils.formatDate(scope.row.signtime).substr(0,
                                                    10) : ''
                                        }}</span>
                                        <span> 至 </span>
                                        <span>{{
                                            scope.row.endtime ? this.$utils.formatDate(scope.row.endtime).substr(0,
                                                10)
                                                : ''
                                        }}</span>
                                    </div>

                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "vdercontractdvclist",
    data() {
        return {
            mainonebread: [
                {
                    name: "金融产品",
                },
                {
                    name: "合伙人",
                },
                {
                    name: "合同设备列表",
                },
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: "0", //框右侧距离
                    titlewidth: "74px", //标题宽度
                    inputwidth: "180px", //input宽度
                    inputinterval: "20px", //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: "设备编号：",
                            placeholder: "请输入设备编号", //此name为键值
                            name: "client_id",
                            content: "",
                            id: "", //此id为给后台的id值
                            url: "/admin/getClientList",
                            inputval: { client: "",is_all:1 }, //这里记录需要传的参数
                        },
                    ],
                },
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: "0", //框右侧距离
                    titlewidth: "74px", //标题宽度
                    inputwidth: "180px", //input宽度
                    inputinterval: "20px", //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: "输入搜索：",
                            placeholder: "合同编号",
                            name: "trade_no",
                            content: "",
                        },
                    ],
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: "none",
                    divinterval: "10px", //框右侧距离
                    selectwidth: "160px",
                    selectinterval: "", //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: "合同状态：",
                            placeholder: "全部",
                            name: "status",
                            value: "",
                            options: [
                                {
                                    label: "全部",
                                    value: "0",
                                },
                                {
                                    label: "未开始",
                                    value: "-2",
                                },
                                {
                                    label: "审核中",
                                    value: "-1",
                                },
                                {
                                    label: "进行中",
                                    value: "1",
                                },
                                {
                                    label: "已完结",
                                    value: "2",
                                },
                                {
                                    label: "已终止",
                                    value: "3",
                                },
                            ],
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: "租赁时间：",
                            placeholder: {
                                placeholderstart: "开始时间",
                                placeholderend: "结束时间",
                            },
                            name: "signtime",
                            value: {
                                valuestart: ref(""),
                                valueend: ref(""),
                                endstatus: true,
                            },
                            rangeseparator: "至", //分隔符
                            selectmaxwidth: "400px", //时间段-最大宽度
                            marginright: "0", //与右侧距离
                        },
                    ],
                },
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 5, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 70,
            mainthreetabletitle: [
                {
                    fixedstatu: "left",
                    prop: "client",
                    label: "设备编号",
                    width: "150",
                },
                {
                    fixedstatu: false,
                    prop: "printname",
                    label: "设备型号",
                    width: "250",
                },
                {
                    fixedstatu: false,
                    prop: "trade_no",
                    label: "合同编号",
                    width: "270",
                },
                {
                    fixedstatu: false,
                    prop: "status",
                    label: "合同状态",
                    width: "120",
                },
                {
                    fixedstatu: false,
                    prop: "income_money",
                    label: "产生收益",
                    width: "120",
                },
                {
                    fixedstatu: false,
                    prop: "sign_end_time",
                    label: "租赁时间",
                    width: "240",
                },
                {
                    fixedstatu: false,
                    prop: "address",
                    label: "设备定位",
                    width: "",
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: "", //通过路由获取当前页面的path值

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
        };
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path;
    },
    methods: {
        //table表单-点击单位格-带参跳转等
        handleOneData(row, column, cell, event) {
            // console.log(row);  //获取当前行数据
            // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
            // console.log(event); //获取当前指针事件
            console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1);
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log("this is:" + val);
            this.multipleSelection = val;
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val;
            this.currentPage = 1;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/lease/deedclientlist",
                    {
                        client_id: this.pagesdatas.client_id,
                        trade_no: this.pagesdatas.trade_no,
                        status: this.pagesdatas.status,
                        signtime: this.pagesdatas.signtime,
                        page: 1,
                        page_size: val,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/lease/deedclientlist",
                    {
                        client_id: this.pagesdatas.client_id,
                        trade_no: this.pagesdatas.trade_no,
                        status: this.pagesdatas.status,
                        signtime: this.pagesdatas.signtime,
                        page: val,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return "warning-row";
            }
            return "";
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo);
            this.tancontent.content = taninfo.content;
            this.tancontent.title = taninfo.title;
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 跳转-合同列表
                if (routeraddr == "/dercontractlist") {
                    this.$router
                        .push({ path: routeraddr, query: { client_id: rowdata.client_id, client_name: rowdata.client } })
                        .catch((error) => error);
                }
            } else {
                this.$router.push(routeraddr).catch((error) => error);
            }
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    "/admin/lease/deedclientlist",
                    {
                        client_id: this.pagesdatas.client_id,
                        trade_no: this.pagesdatas.trade_no,
                        status: this.pagesdatas.status,
                        signtime: this.pagesdatas.signtime,
                        export: 1,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data.message);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true;
            //判断-签约时间段状态
            var getsigntime = null;
            if (data.signtime.start == "") {
                getsigntime = "";
            } else {
                if (data.signtime.end == "") {
                    getsigntime =
                        data.signtime.start + " ~ " + (data.signtime.start + 86400 - 1);
                } else {
                    getsigntime =
                        data.signtime.start + " ~ " + (data.signtime.end + 86400 - 1);
                }
            }
            this.pagesdatas.signtime = getsigntime;
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/lease/deedclientlist",
                    {
                        client_id: data.client_id,
                        trade_no: data.trade_no,
                        status: data.status,
                        signtime: getsigntime,
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data;
            this.mainthreetableData = newdata;
            this.totalCount = successdata.total;
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;
        },
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {

            //加载loading
            this.loading = true; //通过Ajax向后台获取数据

            // 定义
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize,
            };
            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query;
            if (routerinfo.trade_no) {
                this.pagesdatas['trade_no'] = routerinfo.trade_no;
                this.maintwoforminfo.myinputone.input[0].content = routerinfo.trade_no;
            }

            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/lease/deedclientlist", this.pagesdatas,
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    components: {
        mainone,
        maintwo,
    },
});
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.boxlist {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>