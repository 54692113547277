<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
            :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--添加XX按钮-点击跳转添加类页面-->
                        <!-- <el-button type="primary" plain class="max_search" v-show="mainthreeisshowbut"
                            @click="routeraddrClick('/contractlistadd')" v-if="mypowerlimits.htlb_tj">添加</el-button> -->
                        <!--导出按钮-点击导出数据-->
                        <!-- <el-button type="warning" plain @click="exportDatas()">导出</el-button> -->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable" :data="
                        mainthreetableData.slice(
                            (currentPage - lastpage) * PageSize,
                            currentPage * PageSize
                        )
                    " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange" @cell-click="handleOneData">
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i"
                            :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label"
                            :width="mytabletitle.width">
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="
                                mytabletitle.prop === 'userinfo' ||
                                mytabletitle.prop === 'signinfo' ||
                                mytabletitle.prop === 'addtime' ||
                                mytabletitle.prop === 'status' ||
                                mytabletitle.prop === 'return_money' ||
                                mytabletitle.prop === 'lytime' ||
                                mytabletitle.prop === 'symoney'
                            ">
                                <!--用户信息-样式等渲染 -->
                                <div v-if="mytabletitle.prop === 'userinfo'">
                                    <div class="userinfo-box">
                                        <div><span>用户ID：</span><span class="mytabletext"
                                                @click="routeraddrClick('/userlist', scope.row)">{{ scope.row.uid
                                                }}</span>
                                        </div>
                                        <div><span>用户姓名：</span><span>{{ scope.row.name }}</span></div>
                                        <div>
                                            <span>身份证号：</span><span>{{ this.$utils.hiddenCardId(scope.row.id_card)
                                            }}</span>
                                        </div>
                                        <div><span>联系手机：</span><span>{{ scope.row.mobile }}</span></div>
                                    </div>
                                </div>
                                <!--签约信息-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'signinfo'">
                                    <div class="userinfo-box">
                                        <div><span>签约金额：</span><span>{{ scope.row.money }}</span></div>
                                        <div><span>设备数量：</span><span>{{ scope.row.buy_num }}</span></div>
                                        <div><span>签约日期：</span><span>{{ this.$utils.formatDate(scope.row.signtime).substr(0, 10)}}</span></div>
                                    </div>
                                </div>
                                <!-- 履约时长 -->
                                <div v-if="mytabletitle.prop === 'lytime'">
                                    <span>{{ scope.row.pastline }} / {{ scope.row.deadline }} ({{ this.unit.filter(n => n.value == scope.row.unit)[0]['label'] }})</span>
                                </div>
                                <!-- 收益 -->
                                <div v-if="mytabletitle.prop === 'symoney'">
                                    <span>{{ scope.row.income_money }} / {{ scope.row.back_money }}</span>
                                </div>
                                <!-- 申请时间 -->
                                <div v-if="mytabletitle.prop === 'addtime'">
                                    <span>{{ this.$utils.formatDate(scope.row.addtime) }}</span>
                                </div>
                                <!--状态-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div>
                                        <span>{{ this.maintwoforminfo.selectleft.select[0].options.filter(n => n.value
                                                == scope.row.status)[0]['label']
                                        }}</span>
                                    </div>
                                </div>
                                <!--清算结果-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'return_money'">
                                    <div>
                                        <span>退款本金：{{ scope.row.return_money }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
                            prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-合同列表-->
                                <div>
                                    <el-button type="primary" size="mini"
                                        @click="routeraddrClick('/dercontractstopinfo', scope.row)">查看</el-button>
                                    <el-button type="primary" size="mini" v-if="scope.row.status == -1" @click="clickDealEvent(scope.row)">处理</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!-- 弹窗-处理 -->
            <derContractToExamine :Id="this.clickid" :ReturnMoney="this.return_money"
                :DialogVisible="this.dialogVisible" v-on:istruedialog="istruedialogEvent" />

        </div>
    </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "vdercontractstop",
    inject: ["reload"], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: "金融产品",
                },
                {
                    name: "合伙人",
                },
                {
                    name: "合同终止审批",
                },
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: "0", //框右侧距离
                    titlewidth: "74px", //标题宽度
                    inputwidth: "240px", //input宽度
                    inputinterval: "20px", //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: "输入搜索：",
                            placeholder: "合同编号/用户姓名/手机号",
                            name: "keywords",
                            content: "",
                        },
                    ],
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: "none",
                    divinterval: "20px", //框右侧距离
                    selectwidth: "200px",
                    selectinterval: "", //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: "状态：",
                            placeholder: "全部",
                            name: "status",
                            value: "",
                            options: [
                                {
                                    label: "全部",
                                    value: "0",
                                },
                                {
                                    label: "待审核",
                                    value: "-1",
                                },
                                {
                                    label: "已终止",
                                    value: "1",
                                },
                                {
                                    label: "已驳回",
                                    value: "2",
                                },
                                {
                                    label: "已撤销",
                                    value: "3",
                                },
                            ],
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: "申请时间：",
                            placeholder: {
                                placeholderstart: "请选择开始时间",
                                placeholderend: "请选择结束时间",
                            },
                            name: "addtime",
                            value: {
                                valuestart: ref(""),
                                valueend: ref(""),
                                endstatus: true,
                            },
                            rangeseparator: "至", //分隔符
                            selectmaxwidth: "100%", //时间段-最大宽度
                            marginright: "0", //与右侧距离
                        },
                    ],
                },
            }, // 筛选-控件信息
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreehandle: 130,
            mainthreetabletitle: [
                {
                    fixedstatu: "left",
                    prop: "trade_no",
                    label: "合同编号",
                    width: "180",
                },
                {
                    fixedstatu: false,
                    prop: "userinfo",
                    label: "用户信息",
                    width: "260",
                },
                {
                    fixedstatu: false,
                    prop: "signinfo",
                    label: "签约信息",
                    width: "200",
                },
                {
                    fixedstatu: false,
                    prop: "lytime",
                    label: "履约时长",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "symoney",
                    label: "收益(元)",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "addtime",
                    label: "申请时间",
                    width: "180",
                },
                {
                    fixedstatu: false,
                    prop: "status",
                    label: "状态",
                    width: "100",
                },
                {
                    fixedstatu: false,
                    prop: "return_money",
                    label: "清算结果",
                    width: "180",
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 0, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            // 单位
            unit: componentProperties.get('dercontractset').unit,

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100, 1000],
            PageSize: 20,
            multipleSelection: [],
            thispagename: "", //通过路由获取当前页面的path值

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用


            // 退款合同id
            clickid:'',
            // 退款金额
            return_money: '0.00',
            // 处理弹窗
            dialogVisible: ref(false), //弹窗显示&隐藏

        };
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path;
    },
    methods: {
         // 点击按钮-处理
         clickDealEvent(data) {
            this.clickid = data.id;
            this.return_money = data.return_money;
            this.dialogVisible = true;
        },
        // 弹窗-反显
        istruedialogEvent(data) {
            this.dialogVisible = data;
        },

        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            // console.log("this is:" + val);
            this.multipleSelection = val;
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val;
            this.currentPage = 1;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get("/admin/lease/auditList", {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                    params: {
                        keywords: this.pagesdatas.keywords,
                        status: this.pagesdatas.status,
                        addtime: this.pagesdatas.addtime,
                        page: 1,
                        page_size: val,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .get("/admin/lease/auditList", {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                    params: {
                        keywords: this.pagesdatas.keywords,
                        status: this.pagesdatas.status,
                        addtime: this.pagesdatas.addtime,
                        page: val,
                        page_size: this.PageSize,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 跳转-用户列表
                if (routeraddr == "/userlist") {
                    this.$router
                        .push({ path: routeraddr, query: { userid: rowdata.uid } })
                        .catch((error) => error);
                }
                // 跳转-合同信息
                if (routeraddr == "/dercontractstopinfo") {
                    this.$router
                        .push({ path: routeraddr, query: { id: rowdata.id } })
                        .catch((error) => error);
                }
            } else {
                this.$router.push(routeraddr).catch((error) => error);
            }
        },
        //导出数据
        exportDatas() {
            //    
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true;
            //判断-签约时间段状态
            var getaddtime = null;
            if (data.addtime.start == "") {
                getaddtime = "";
            } else {
                if (data.addtime.end == "") {
                    getaddtime =
                        data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
                } else {
                    getaddtime =
                        data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
                }
            }
            this.pagesdatas.addtime = getaddtime;
            //通过Ajax向后台获取数据
            axios
                .get("/admin/lease/auditList", {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                    params: {
                        keywords: data.keywords,
                        status: data.status,
                        addtime: getaddtime,
                        page: 1,
                        page_size: this.PageSize,
                    },
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data;
            this.mainthreetableData = newdata;
            this.totalCount = successdata.total;
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;
        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight;
            this.sreenwidth = document.documentElement.clientWidth;
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + "px";
            } else {
                this.searchwidth = "580px";
            }
        },
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo();

            // 定义
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize,
            };
            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query;
            if (routerinfo.signusername) {
                this.pagesdatas['keywords'] = routerinfo.signusername;
                this.maintwoforminfo.myinputone.input[0].content = routerinfo.signusername;
            }

            //加载loading
            this.loading = true; //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .get("/admin/lease/auditList", {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                    params: this.pagesdatas,
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    components: {
        mainone,
        maintwo,
    },
});
</script>
<style scoped>
/* 弹出框-搜索栏 */
.tan-search-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.tan-search-box-info {
    display: flex;
    flex-direction: row;
    margin: 0 10px 10px 0;
}

.tan-search-box-info-title {
    min-width: 80px;
}

.tan-table-status {
    display: flex;
    flex-direction: row;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.tan-box {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}

.userinfo-box {
    display: flex;
    flex-direction: column;
    justify-content: left;
}
</style>