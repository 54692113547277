<template>
    <div id="mymain">
        <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box"  >
                <div class="box_title"  >
                    <div class="box_title_font"><i class="el-icon-info"></i> 申请退保证金详情</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <div class="main_one_button">
                            <el-button type="primary" v-if="getdatas.status == -1" @click="doApplyBack(this.$route.query.id, (dialogVisible1 = true))">处理 </el-button>
                        </div>
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px"></maindetails>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details1"></maindetails>
                    <div style="margin-bottom: 20px; margin-top: 10px">
                        <div style="min-width: 80px; float: left; margin-right: 20px">
                            <span style="font-size: 14px; color: #333333; margin-left: 38px">凭证图片:</span>
                        </div>
                        <div>
                            <mainimgesshow :imgesdata="mainpagedata.imgesdata"></mainimgesshow>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details2"></maindetails>
                </div>
            </div>
        </div>

        <!--弹出框-->
        <el-dialog ref="dealDialog" v-model="dialogVisible1" title="处理" :before-close="handleCloseSenior" :width="this.searchwidth">
            <el-form ref="ruleForm" :model="dealForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                <div
                    style="
            max-height: 450px;
            overflow: hidden;
            overflow-y: visible;
            margin: 20px 5% 0 0;
          "
                >
                    <el-form-item label="选择处理方式:" prop="status">
                        <el-radio-group v-model="dealForm.status">
                            <el-radio :label="2">驳回</el-radio>
                            <el-radio :label="1">同意退保证金</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="选择退款明细:" prop="tableArr" v-if="dealForm.status == 1">
                        <el-checkbox-group v-model="dealForm.tableArr">
                            <el-row v-for="item in dealArr" :key="item.id">
                                <el-checkbox size="large" :label="item.id">
                                    <div class="checkbox-box">
                                        <div>
                                            <span style="margin-right: 10px">支付宝/微信：￥{{ item.amount }}</span>
                                        </div>
                                        <div>
                                            <span style="margin-left: 10px">{{ item.stand_num }}台</span>
                                        </div>
                                        <div>
                                            <span style="margin-left: 10px">型号：{{ JSON.parse(item.system_price).title }}</span>
                                        </div>
                                    </div>
                                </el-checkbox>
                            </el-row>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="处理备注:" prop="remark">
                        <el-input type="textarea" v-model="dealForm.remark"></el-input>
                    </el-form-item>
                    <el-form-item style="text-align: right">
                        <el-button @click="dialogVisible1 = false">取消</el-button>
                        <el-button type="primary" @click="dealFormSubmmit('ruleForm')">确定</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import maindetails from '@/components/Maindetails'
import mainimgesshow from '@/components/Mainimgesshow'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { ref } from 'vue'

export default {
    name: 'bondbackinfo',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainpagedata: {
                mainonebread: [
                    {
                        name: '财务'
                    },
                    {
                        name: '保证金'
                    },
                    {
                        name: '申请退保证金',
                        path: '/bondapplyback'
                    },
                    {
                        name: '申请退保证金详情',
                        path: ''
                    }
                ], // 当前页-【面包屑导航】
                mainoneiconshow1: false, // 当前页-【刷新按钮】
                mainoneiconshow2: true, // 当前页-【返回按钮】

                details: {
                    showstatus: 1, //确定样式模块
                    title: '保证金信息',
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '申请编号:',
                            value: ''
                        },
                        {
                            name: '用户ID:',
                            value: ''
                        },
                        {
                            name: '保证金:',
                            value: ''
                        },
                        {
                            name: '支付方式:',
                            value: ''
                        },
                        {
                            name: '签约台数:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                details1: {
                    showstatus: 1, //确定样式模块
                    title: '申请信息',
                    button: {
                        isshow: false,
                        name: ''
                    },
                    content: [
                        {
                            name: '申请时间:',
                            value: ''
                        },
                        {
                            name: '联系人:',
                            value: ''
                        },
                        {
                            name: '联系方式:',
                            value: ''
                        },
                        {
                            name: '申请原因:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                details2: {
                    showstatus: 1, //确定样式模块
                    title: '处理信息',
                    button: {
                        isshow: false,
                        name: ''
                    },
                    content: [
                        {
                            name: '处理状态:',
                            value: ''
                        },
                        {
                            name: '处理时间:',
                            value: ''
                        },
                        {
                            name: '处理备注:',
                            value: ''
                        },
                        {
                            name: '实际保证金退还金额:',
                            value: ''
                        },
                        {
                            name: '实际解约台数:',
                            value: ''
                        }
                    ]
                }, //关于详情信息的组件
                imgesdata: {
                    showstatus: 2,
                    content: [
                        // {
                        //   url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                        //   preview: ['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']
                        // },
                    ]
                } //关于图片展示的组件
            }, // 当前Pagedata所需的data数据与控制

            dialogVisible1: false,
            dealForm: {
                id: '',
                status: 2,
                tableArr: [],
                remark: ''
            },
            dealArr: [],
            rules: {
                status: [
                    {
                        required: true,
                        message: '请选择选择处理方式',
                        trigger: 'blur'
                    }
                ],
                tableArr: [
                    {
                        required: true,
                        message: '请选择退款明细',
                        trigger: 'change'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '请输入处理备注',
                        trigger: 'change'
                    }
                ]
            }, //弹窗中-form表单-必填规则

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            getdatas: {
                status: null
            }, //接口获取的总数据
            dialogVisible: false,
            searchwidth: '580px',

            thisradiochange: 1,

            loading: false
        }
    },
    methods: {
        // 点击-处理按钮-获取处理相关信息
        doApplyBack(id) {
            axios
                .post(
                    '/admin/bond/payList',
                    {
                        id: id
                    },
                    {
                        headers: { Authentication: this.mytoken }
                    }
                )
                .then((response) => {
                    console.log(response.data.result)
                    this.dealArr = response.data.result
                    this.dealForm.id = id
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // 弹窗-处理-申请退保证金-确认按钮
        dealFormSubmmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model
                    console.log(getdatas.tableArr.join(','))

                    axios
                        .post(
                            '/admin/bond/dealWithRefund',
                            {
                                id: getdatas.id,
                                status: getdatas.status,
                                item: getdatas.tableArr.join(','),
                                remark: getdatas.remark
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible1 = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.reload() //刷新
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //弹出框-取消
        resetDealDialog() {
            this.dealForm.id = ''
            this.dealForm.status = ''
            this.dealForm.tableArr = []
            this.dealForm.remark = ''
        },

        routeraddrClick(routeraddr) {
            //点击进入路由页面
            this.$router.push(routeraddr).catch((error) => error)
            console.log(routeraddr)
        },
        getwindowinfo() {
            //获取当前windows页面信息-实时修改【高级筛选】的宽度
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/bond/refundDetail',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.getdatas = response.data.result
                    var getdatas = response.data.result

                    // 渲染-保证金信息
                    this.mainpagedata.details.content = [
                        {
                            name: '申请编号:',
                            value: getdatas.bond_no
                        },
                        {
                            name: '用户ID:',
                            value: getdatas.uid
                        },
                        {
                            name: '平台来源:',
                            value: getdatas.thirdparty == 1 ? '微信自助版' : getdatas.thirdparty == 2 ? '支付宝自助版' : ''
                        },
                        {
                            name: '保证金:',
                            value: getdatas.bond != null ? (getdatas.bond / 100).toFixed(2) : ''
                        },
                        {
                            name: '支付方式:',
                            value: getdatas.payway == 1 ? '微信' : getdatas.payway == 2 ? '支付宝' : ''
                        },
                        {
                            name: '签约台数:',
                            value: getdatas.number
                        }
                    ]

                    //渲染-图片
                    let images = getdatas.image.split('#').filter((n) => n)
                    for (let image of images) {
                        this.mainpagedata.imgesdata.content.push({
                            url: image,
                            preview: [image]
                        })
                    }

                    // 渲染-申请信息
                    this.mainpagedata.details1.content = [
                        {
                            name: '申请时间:',
                            value: getdatas.addtime != 0 && getdatas.addtime != null ? this.$utils.formatDate(getdatas.addtime) : ''
                        },
                        {
                            name: '联系人:',
                            value: getdatas.contact
                        },
                        {
                            name: '联系方式:',
                            value: getdatas.mobile
                        },
                        {
                            name: '申请原因:',
                            value: getdatas.content
                        }
                    ]
                    // 渲染-处理信息
                    this.mainpagedata.details2.content = [
                        {
                            name: '处理状态:',
                            value: getdatas.status == -1 ? '待处理' : getdatas.status == 1 ? '已退款' : '已拒绝'
                        },
                        {
                            name: '处理时间:',
                            value: getdatas.dealtime != 0 && getdatas.dealtime != null ? this.$utils.formatDate(getdatas.dealtime) : ''
                        },
                        {
                            name: '处理备注:',
                            value: getdatas.remark
                        },
                        {
                            name: '实际保证金退还金额:',
                            value: getdatas.bond_refund != null ? (getdatas.bond_refund / 100).toFixed(2) : ''
                        },
                        {
                            name: '实际解约台数:',
                            value: getdatas.deal_number
                        }
                    ]

                    this.loading = false
                })
                .catch(function(error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.checkbox-box {
    display: flex;
    flex-direction: row;
}

.checkbox-box div {
    margin-right: 10px;
}
</style>
