<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box"  >
                <div class="box_title"  >
                    <div class="box_title_font">余额充值</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="4">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="16">
                            <div class="topup-amount">
                                <span>充值金额</span>
                                <span class="add-rule" @click="addRule">添加规则</span>
                            </div>

                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                                <div class="topup-section" v-for="(item, index) in ruleForm.topupList" :key="index">
                                    <div class="title">
                                        <div>充值{{ index + 1 }}：</div>
                                        <div class="del-font" @click="delRule(index)">删除</div>
                                    </div>
                                    <el-form-item label="充值金额：" :prop="`topupList.${index}.price`" :rules="rules.price">
                                        <el-input v-model="item.price" maxlength="100" placeholder="请输入金额"></el-input>
                                    </el-form-item>
                                    <el-form-item label="功能介绍：" :prop="`topupList.${index}.descr`">
                                        <el-input v-model="item.descr" placeholder="请输入功能介绍"></el-input>
                                    </el-form-item>
                                    <el-form-item label="送赠品：">
                                        <el-radio-group v-model="item.gift" :prop="`topupList.${index}.gift`">
                                            <el-radio label="1">有赠品</el-radio>
                                            <el-radio label="-1">没有赠品</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="选择赠品：" v-show="item.gift == 1">
                                        <el-button @click="selectGift(item, index)" type="primary">选择赠品</el-button>
                                    </el-form-item>
                                    <el-form-item label="赠品：" v-show="item.gift == 1">
                                        <div class="gift-wrapper">
                                            <div class="gift-item" v-for="(gitfItem, gitfItemIndex) in item.giftss" :key="gitfItemIndex">
                                                <div class="gift-box">
                                                    <div>
                                                        <el-image style="width: 100px; height: 80px" :src="gitfItem.img" :preview-src-list="[gitfItem.img]" fit="cover" @error="(data) => errorImagesEvent(data, scope.row)"> </el-image>
                                                        <div>
                                                            {{ formatLen(gitfItem.name, 6) }}
                                                        </div>
                                                        <div>库存：{{ gitfItem.stock_num }}</div>
                                                        <div class="gift-del" @click="delGift(index, gitfItemIndex)">X</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <el-input-number v-model="gitfItem.num" :min="1" size="small" />
                                                </div>
                                            </div>
                                        </div>
                                    </el-form-item>
                                </div>
                                <div>
                                    <div>其他</div>
                                    <el-form-item label="提示文案：">
                                        <el-input v-model="ruleForm.title" type="textarea" maxlength="200" :autosize="{ minRows: 5 }" show-word-limit></el-input>
                                    </el-form-item>
                                </div>

                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="4">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <el-dialog v-model="dialogVisible" title="赠品管理" width="70%" height="100px">
                <div class="dialog-wrapper">
                    <el-table el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable" :row-class-name="tableRowClassName" :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)" style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange" @cell-click="handleOneData">
                    <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <!-- <template #default="scope" v-if="mytabletitle.prop === 'uid'"> -->
                            <template #default="scope" v-if="mytabletitle.prop === 'img' || mytabletitle.prop === 'is_use' || mytabletitle.prop === 'remark'">
                                <div v-if="mytabletitle.prop === 'img'">
                                    <el-image class="imagesshow" style="width: 100px, height: 120px" :src="scope.row.img" :preview-src-list="[scope.row.img]" fit="cover" @error="(data) => errorImagesEvent(data, scope.row)"> </el-image>
                                </div>
                                <div v-if="mytabletitle.prop === 'remark'">
                                    <el-tooltip
                                        effect="dark"
                                        :content="scope.row.remark"
                                        placement="top-start"
                                        popper-class="tool-tip"
                                    >
                                        <div>{{ formatLen(scope.row.remark, 100) }}</div>
                                    </el-tooltip>
                                </div>

                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="dialog-bottom">
                        <el-button @click="dialogVisible = false">取消</el-button>
                        <el-button @click="confirmDialog" type="primary">确定</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { defineComponent } from 'vue';

// 金额效验（可小数）
const checkInputEvent = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('必填项不能为空'))
    }
    if (value.toString().indexOf(' ') != -1) {
        return callback(new Error('值中不能有空格'))
    }

    setTimeout(() => {
        // 判断字符串是否为文字
        if (isNaN(value)) {
            callback(new Error('请输入数字'))
        } else {
            let result = value.toString().indexOf('.')
            if (result != -1) {
                let getdata = value.toString().split('.')
                if (getdata[getdata.length - 1].length > 2) {
                    callback(new Error('请保留小数点后两位'))
                }
            }

            if (value <= 0) {
                callback(new Error('值需大于0'))
            } else {
                callback()
            }
        }
    }, 500)
}

export default defineComponent({
    name: 'vsurplusset',
    data() {
        return {
            mainonebread: [
                {
                    name: '运营',
                },
                {
                    name: '余额充值',
                },
                {
                    name: '余额充值设置',
                },
            ], // 当前页-【面包屑导航】

            topupListIndex: 0,

            dialogVisible: false,

            giftList: [],

            ruleForm: {
                title: '',
                topupList: [
                    {
                        price: '',
                        descr: '',
                        gift: '-1',
                        giftss: [],
                    },
                ],
            }, //form表单
            rules: {
                price: [
                    {
                        required: true,
                        message: '金额为必填项',
                        trigger: 'blur',
                    },
                    {
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ],
                title: [
                    {
                        required: true,
                        message: '月分红金额为必填项',
                        trigger: 'blur',
                    },
                ],
            }, //form表单-必填规则

            mainthreeisshowset: true, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 27, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'id',
                    width: '80',
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '名称',
                    width: '200',
                },
                {
                    fixedstatu: false,
                    prop: 'img',
                    label: '图片',
                    width: '100',
                },
                {
                    fixedstatu: false,
                    prop: 'stock_num',
                    label: '库存剩余',
                    width: '100',
                },
                {
                    fixedstatu: false,
                    prop: 'gift_num',
                    label: '累计销量',
                    width: '100',
                },
                {
                    fixedstatu: false,
                    prop: 'remark',
                    label: '详情说明',
                    width: '',
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            PageSize: 9999,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '450px',
            tancontent: {
                title: '备注',
                content: {},
            }, //弹框

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
        };
    },

    methods: {
        //获取配置详情
        getCashInfo() {
            axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'cash_recharge_set',
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token'),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        const res = response.data.result
                        const {contents, title} = res
                        this.ruleForm.topupList = contents
                        this.ruleForm.topupList.map((item)=> {
                            item.price = Number(item.price) / 100
                        })
                        this.ruleForm.title = title
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        //from表单-提交表单
        submitForm(formName) {
            try {
                let contents = [];
                this.ruleForm.topupList.forEach((element) => {
                    const { price, giftss, gift, descr } = element;
                    let giftContent = [];
                    if(gift === '1' && (!giftss || giftss.length <= 0)) {
                        throw new Error('请选择赠品')
                    }
                    if(gift === '1' && giftss) {
                        giftss.forEach((e) => {
                            let data = {};
                            data[e.id] = e.num;
                            giftContent = { ...giftContent, ...data };
                        });
                    }
                    contents = [
                        ...contents,
                        {
                            price: +price * 100,
                            gift,
                            descr,
                            gifts: giftContent,
                        },
                    ];
                });
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        axios
                            .post(
                                '/admin/config/update',
                                {
                                    contents: {
                                        contents,
                                        title: this.ruleForm.title,
                                    },
                                    key_name: 'cash_recharge_set',
                                },
                                {
                                    headers: {
                                        Authentication: localStorage.getItem('token'),
                                    },
                                }
                            )
                            .then((response) => {
                                if (response.data.code === 0) {
                                    ElMessage({
                                        type: 'success',
                                        message: '提交成功！',
                                        duration: 1000,
                                    });
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }catch(err) {
                console.log({err})
                const {message} = err
                ElMessage({
                    type: 'warning',
                    message,
                    duration: 1000,
                });

            }
        },

        addRule() {
            if (this.ruleForm.topupList.length >= 6) {
                ElMessage({
                    type: 'warning',
                    message: '规则不能大于6个',
                    duration: 1000,
                });
                return;
            }
            this.ruleForm.topupList = [
                ...this.ruleForm.topupList,
                {
                    price: '',
                    descr: '',
                    gift: '-1',
                    giftss: [],
                },
            ];
        },

        delRule(index) {
            if (this.ruleForm.topupList.length <= 1) {
                ElMessage({
                    type: 'warning',
                    message: '至少要有一条规则',
                    duration: 1000,
                });
                return;
            }
            this.ruleForm.topupList.splice(index, 1);
        },

        selectGift(item, index) {
            const { giftss } = item;
            this.getGiftList(giftss);
            this.topupListIndex = index;
            this.dialogVisible = true;
        },

        delGift(index, gitfItemIndex) {
            this.ruleForm.topupList[index].giftss.splice(gitfItemIndex, 1);
        },

        confirmDialog() {
            if (this.giftList.length > 5) {
                ElMessage({
                    type: 'warning',
                    message: '最多只能有5个赠品',
                    duration: 1000,
                });
                return;
            }

            const {giftss} = this.ruleForm.topupList[this.topupListIndex]
            this.giftList.forEach((row)=> {
                if(giftss) {
                    giftss.map((item)=> {
                        if(item.id === row.id) {
                            row.num = item.num;
                        }
                    })
                }
                if(!row.num) {
                    row.num = 1
                }
            })

            this.ruleForm.topupList[this.topupListIndex].giftss = [...this.giftList];
            this.dialogVisible = false;
        },

        getGiftList(giftss) {
            axios
                .post(
                    '/admin/member/cashGiftIndex',
                    {
                        page: 1,
                        is_use: "1",
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token'),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                    this.$nextTick(() => {
                        if(giftss) {
                            giftss.map((item)=> {
                                this.mainthreetableData.forEach((row)=> {
                                    if(item.id === row.id) {
                                        this.$refs.multipleTable.toggleRowSelection(row, true);
                                    }
                                })
                            })
                        }
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data;
            for (var i = 0; i < newdata.length; i++) {
                //转换-申请时间
                if (newdata[i].addtime) {
                    if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                        newdata[i].addtime = '--';
                    } else {
                        // console.log('添加时间：' + newdata[i].addtime)
                        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
                    }
                } else {
                    newdata[i].addtime = '--';
                }
                //转换-支付时间paytime
                if (newdata[i].paytime) {
                    if (newdata[i].paytime === 0 || newdata[i].paytime === null) {
                        newdata[i].paytime = '--';
                    } else {
                        // console.log('添时间：' + newdata[i].addtime)
                        newdata[i].paytime = this.$utils.formatDate(newdata[i].paytime);
                    }
                } else {
                    newdata[i].paytime = '--';
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata;
            this.totalCount = successdata.total;
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;
        },

        handleSelectionChange(data) {
            this.giftList = data;
        },

        //from表单-重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
            let data = [{ price: '', descr: '', gift: '-1', giftss: [] }];
            this.ruleForm.topupList = data;
        },

        formatLen(val, len) {
            return val.length > len ? `${val.slice(0, len)}...` : val
        },
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false;
            this.$router.push('/').catch((error) => error);
        }

        this.getCashInfo();
    },
    components: {
        mainone,
    },
});
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.topup-amount{
    margin-bottom: 20px;
}

.topup-amount .add-rule{
    margin-left: 20px;
    color: rgb(30, 111, 255);
    cursor: pointer;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.box_content .topup-section {
    border-bottom: 1px solid #dcdfe6;
    margin-bottom: 30px;
}

.box_content .topup-section .title {
    display: flex;
    margin-bottom: 20px;
}

.box_content .topup-section .title .del-font {
    margin-left: auto;
    color: #ff0000;
    cursor: pointer;
}

.dialog-bottom {
    margin-top: 30px;
    display: flex;
    justify-content: right;
}

.gift-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}

.gift-wrapper .gift-item {
    margin: 0 20px 20px 0;
}

.gift-wrapper .gift-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 0 10px 0;
    padding: 10px;
    border: 1px dashed #dcdfe6;
}

.gift-wrapper .gift-del {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    background: #ff0000;
    border-radius: 50%;
    line-height: 25px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.dialog-wrapper {
    height: 650px;
    overflow: auto;
}
</style>
