<template>
    <div class="page-container">
        <div class="container-filter">
            <el-form ref="filterRef" :model="params" inline>
                <div>
                    <el-form-item prop="cid" label="设备编号：">
                        <el-select
                            v-model="params.cid"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="输入设备编号"
                            remote-show-suffix
                            :remote-method="deviceRemote"
                            :loading="dloading"
                        >
                            <el-option v-for="item in devices" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="shop_user_id" label="一级代理商：">
                        <span v-if="agent_id">{{ agent_name }}</span>
                        <el-select
                            v-model="params.shop_user_id"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="输入代理商"
                            remote-show-suffix
                            :remote-method="shopRemote"
                            :loading="sloading"
                            v-else
                        >
                            <el-option v-for="item in shops" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                </div>
                <div>
                    <el-button @click="resetForm">重置</el-button>
                    <el-button type="primary" @click="getTableData()" :loading="loading">查询</el-button>
                </div>
            </el-form>
        </div>
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <template v-if="mypowerlimits.zdsblb_dlsplzysbdr">
                        <el-button type="default" @click="downloadTemplate">模板下载</el-button>
                        <el-button type="primary" @click="uploadTemplate">批量转移设备导入</el-button>
                    </template>
                    <el-button type="primary" @click="exportRecord">导出</el-button>
                </div>
            </div>
            <el-table v-loading="loading" :border="true" :data="table.data" @selection-change="selectionChange">
                <el-table-column type="selection" width="55" align="center" />
                <el-table-column prop="client" label="设备编号" />
                <el-table-column prop="main_id" label="指定一级代理商ID" />
                <el-table-column prop="main_name" label="指定一级代理商" />
                <el-table-column prop="shop_code" label="代理商" width="240" />
                <el-table-column prop="printname" label="打印机类型" />
                <el-table-column prop="run_type" label="运营状态" />
                <el-table-column prop="putintime" label="投放时间">
                    <template #default="{ row }">
                        <span>{{ row.putintime ? $utils.formatDate(row.putintime) : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="addtime" label="操作时间">
                    <template #default="{ row }">
                        <span>{{ row.addtime ? $utils.formatDate(row.addtime) : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="admin_id" label="操作人" />
                <el-table-column label="操作" width="100">
                    <template #default="{ row }">
                        <el-button type="danger" size="small" @click="handleDelete(row)" v-if="mypowerlimits.zdsblb_yc">移除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="tabler-pagination">
                <el-button type="primary" @click="recordsRemove" v-if="mypowerlimits.zdsblb_plyc">批量移除</el-button>
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[10, 20, 50, 100]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps } from 'vue'
import { ElMessageBox, ElNotification, ElMessage } from 'element-plus'
import axios from 'axios'

// 获取权限
const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authentication: localStorage.getItem('token')
    }
})
const props = defineProps({
    agent_id: [String, Number],
    agent_name: String
})

// 过滤

const filterRef = ref()
const shops = ref([])
const devices = ref([])
const params = reactive({
    cid: null,
    shop_user_id: props.agent_id,
    page: 1,
    page_size: 10
})
const sloading = ref(false)
const shopRemote = async (query) => {
    sloading.value = true
    const { data } = await http.post('/admin/shopUser/getShopList', { name: query })
    shops.value = data.result?.data?.map((item) => ({ label: item.name, value: item.id })) || []
    sloading.value = false
}
const dloading = ref(false)
const deviceRemote = async (query) => {
    dloading.value = true
    const { data } = await http.post('/admin/getClientList', { client: query })
    devices.value = data.result?.data?.map((item) => ({ label: item.client, value: item.id })) || []
    dloading.value = false
}

// 表格
const loading = ref(false)
const records = ref([])
const table = reactive({
    data: [],
    columns: [],
    total: 0
})
const selectionChange = (val) => {
    records.value = val
}
const getTableData = async (page = 1) => {
    loading.value = true
    try {
        params.page = page
        const { data } = await http.post('admin/clientWith/shopUserClientIndex', { ...params })
        Object.assign(table, data.result)
    } catch (e) {
        console.error(e)
    }
    loading.value = false
}

// 操作
// 重置查询表单
const resetForm = () => {
    filterRef.value?.resetFields()
    params.shop_user_id = props.agent_id
    params.cid = null
    getTableData()
}
// 移除
const handleDelete = async (row) => {
    await ElMessageBox.confirm(`是否移除设备${row.client}？`, '提示', { type: 'warning' })
    await http.post('admin/clientWith/shopUserClientDel', { cid: row.cid })
    ElNotification.success({ title: '提示', type: 'success', message: '移除成功' })
    getTableData()
}
// 批量移除
const recordsRemove = async () => {
    if (records.value.length === 0) {
        ElMessage({ type: 'warning', message: '请选择要移除的设备', duration: 3000 })
        return
    }
    await ElMessageBox.confirm(`是否移除设备${records.value.map((item) => item.client).join(',')}？`, '提示', { type: 'warning' })
    const { data } = await http.post('admin/clientWith/shopUserClientBatchDel', { cid: records.value.map((item) => item.cid).join(',') })
    ElMessage({ type: 'success', message: data.message, duration: 3000 })
    getTableData()
}

// 下载模板
const downloadTemplate = async () => {
    const { data } = await http.post('admin/clientWith/shopUserClientMoveTemplate')
    const link = document.createElement('a')
    link.href = data.result
    link.click()
}
// 批量导入指定设备
const uploadTemplate = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.xlsx,.xls'
    input.click()
    input.onchange = async (e) => {
        const file = e.target.files[0]
        if (!file) return
        if (file.size > 1024 * 1024 * 10) {
            ElMessage({ type: 'warning', message: '文件大小不能超过10M', duration: 3000 })
            return
        }
        const formData = new FormData()
        formData.append('file', file)
        const { data } = await http.post('admin/clientWith/shopUserClientMove', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        const { count, success_count, error_count, msg } = data.result
        let message = `共${count || 0}条记录`
        if (success_count > 0) message += `<br />导入成功${success_count}条`
        if (error_count > 0) message += `<br />导入失败${error_count}条`
        if (error_count > 0) message += `<br />失败原因：<br />${msg.join('<br />')}`
        ElMessageBox.alert(message, '提示', { type: 'info', dangerouslyUseHTMLString: true })
        getTableData()
    }
}
// 导出
const exportRecord = async () => {
    const { data } = await http.post('admin/clientWith/shopUserClientIndex', { ...params, export: 1 })
    ElNotification({ title: '提示', type: data.code === 0 ? 'success' : 'warning', message: data.message })
}

getTableData()
shopRemote()
deviceRemote()
</script>

<style lang="scss" scoped>
.page-container {
    background-color: #e9eef3;
    .container-bread {
        font-size: 14px;
        color: #666;
        display: flex;
        align-items: center;
    }
    .container-filter {
        padding: 0px 24px 12px;
        background-color: #fff;
        .el-form {
            display: flex;
            justify-content: space-between;
        }
        .el-form-item {
            margin-right: 20px;
            margin-bottom: 0px;
        }
    }

    .container-table {
        margin-top: 4px;
        padding: 24px;
        background-color: #fff;
        .table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }
        .tabler-pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
        }
    }
}

.container-drawer {
    .filter-tree {
        margin-top: 24px;
    }
    .drawer-footer {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
