<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="primary" plain @click="routeraddrClick('/luckydrawrecordadd')"
              v-if="this.mypowerlimits.xydcj_tj">添加
            </el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status'
              ">
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.textstatus">
                    <span>{{ scope.row.textstatus }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-运营-幸运大抽奖-->
                <div v-if="mainthreetableope === 27">
                  <div v-if="scope.row.drawhandle === 1">
                    <el-button type="primary" size="mini" @click="routeraddrClick('/luckydrawinfo', scope.row)">查看
                    </el-button>
                    <el-button type="primary" size="mini" @click="routeraddrClick('/luckydrawrecordedit', scope.row)"
                      v-if="this.mypowerlimits.xydcj_bj">编辑
                    </el-button>
                    <el-button @click.prevent="deleteRow(scope.$index, mainthreetableData)" type="danger" size="mini"
                      v-if="this.mypowerlimits.xydcj_sc" icon="el-icon-delete"></el-button>
                  </div>
                  <div v-if="scope.row.drawhandle === 2">
                    <el-button type="primary" size="mini" @click="routeraddrClick('/luckydrawinfo', scope.row)">查看
                    </el-button>
                    <el-button type="primary" size="mini" v-if="this.mypowerlimits.xydcj_cjjl"
                      @click="routeraddrClick('/luckydrawrecord', scope.row)">抽奖记录
                    </el-button>
                    <el-button type="primary" size="mini" v-if="this.mypowerlimits.xydcj_zz"
                      @click.prevent="stopRow(scope.$index, mainthreetableData)">终止
                    </el-button>
                  </div>
                  <div v-if="scope.row.drawhandle === 3">
                    <el-button type="primary" size="mini" @click="routeraddrClick('/luckydrawinfo', scope.row)">查看
                    </el-button>
                    <el-button type="primary" size="mini" v-if="this.mypowerlimits.xydcj_cjjl"
                      @click="routeraddrClick('/luckydrawrecord', scope.row)">抽奖记录
                    </el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog v-model="dialogVisible" :title="tancontent.title" :width="this.searchwidth"
        :before-close="handleCloseSenior">
        <el-form ref="seniorform" :model="seniorform">
          <div style="
              max-height: 450px;
              overflow: hidden;
              overflow-y: visible;
              padding-left: 5%;
              margin: 20px 0;
            ">
            <el-form-item>
              <!--以下是左侧字-->
              <maininput :myinputoneisshow="tancontent.content.myinputoneisshow"
                :myinputone="tancontent.content.myinputone"></maininput>
              <!--以下是上方字-->
              <maininput :myinputishow="tancontent.content.myinputishow" :myinput="tancontent.content.myinput">
              </maininput>
              <mainselect :myselectisshow="tancontent.content.myselectisshow" :myselect="tancontent.content.myselect">
              </mainselect>
              <mainselect :myselectcityisshow="tancontent.content.myselectcityisshow"
                :myselectcity="tancontent.content.myselectcity"></mainselect>
            </el-form-item>
          </div>
          <el-form-item>
            <div style="text-align: center" class="main_one_button">
              <el-button @click="
                resetFormSenior(
                  tancontent.content.myinputone,
                  tancontent.content.myinput,
                  tancontent.content.myselect,
                  tancontent.content.myselectcity
                )
              ">
                <i class="el-icon-refresh-left"></i> 重置
              </el-button>
              <el-button type="primary" @click="
                onSubmitSenior(
                  tancontent.content.myinputone,
                  tancontent.content.myinput,
                  tancontent.content.myselect,
                  tancontent.content.myselectcity
                )
              " class="main_two_search">确定
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import maininput from "@/components/Maininput";
import mainselect from "@/components/Mainselect";
// import {ElMessage} from "element-plus";
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vluckydraw",
  inject: ["reload"],
  data() {
    return {
      mainonebread: [
        {
          name: "运营",
        },
        {
          name: "营销玩法",
        },
        {
          name: "幸运大抽奖",
          path: "/luckydraw",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "活动名称",
              name: "title",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "活动位：",
              placeholder: "全部",
              name: "position",
              value: "",

              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "打印订单打印成功页",
                  value: "0",
                },
                {
                  label: "商城订单支付成功页",
                  value: "1",
                },
                {
                  label: "会员充值结果页",
                  value: "2",
                },
                {
                  label: "广告支付结果页",
                  value: "3",
                },
                {
                  label: "赚钱页",
                  value: "4",
                },
                {
                  label: "我的工具",
                  value: "5",
                },
              ],
            },
            {
              selectisshow: true,
              ismust: false,
              title: "状态：",
              placeholder: "全部",
              name: "status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "0",
                },
                {
                  label: "未开始",
                  value: "1",
                },
                {
                  label: "进行中",
                  value: "2",
                },
                {
                  label: "已过期",
                  value: "3",
                },
                {
                  label: "已终止",
                  value: "4",
                },
              ],
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 27, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 200, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "title",
          label: "活动名称",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "position",
          label: "活动位",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "begin_time",
          label: "活动时间",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "活动状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "times",
          label: "抽奖次数",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "hit_num",
          label: "已中奖",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  setup() {
    const dialogVisible = ref(false);
    const handleCloseSenior = (done) => {
      done();
    };

    return {
      dialogVisible,
      handleCloseSenior,
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      console.log(row); //获取当前行数据
      console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用

      //获取所点击按钮的id值-并根据不同的id值进行跳转&传值等
      if (event.path[1].id) {
        //由操作-合作-公司列表-向-公司关联设备页面传值
        if (event.path[1].id === "companydvcid") {
          this.$router
            .push({
              path: "/companydvclist",
              query: { id: row.id, name: row.name },
            })
            .catch((error) => error);
        }
        //由操作-合作-设备合伙人列表-向-合伙人关联设备页面传值
        if (event.path[1].id === "partnerdvcid") {
          this.$router
            .push({ path: "/partnerdvclist", query: { id: row.id } })
            .catch((error) => error);
        }
      }

      console.log("当前的column.property 是：" + column.property);
      if (column.property === "secondagent") {
        //跳转二级代理
        this.$router.push("/agentcensustwo").catch((error) => error);
      }
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      // rows.splice(index, 1)
      console.log("shanchu :" + rows[index].id);
      ElMessageBox.confirm(
        "确定删除该条 ' " + rows[index].title + " ' 活动吗?",
        "提示：",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          axios
            .post(
              "/admin/luckyDraw/delete",
              {
                id: rows[index].id,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "删除成功",
                });
                rows.splice(index, 1);
              }
            })
            .catch(function (error) {
              // 请求失败处理
              ElMessage({
                type: "error",
                message: "删除失败！" + error,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消删除",
          });
        });
    },
    //table表-操作中-终止按钮（函数）
    stopRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      // rows.splice(index, 1)
      console.log("shanchu :" + rows[index].id);
      ElMessageBox.confirm(
        "确定终止该条 ' " + rows[index].title + " ' 活动吗?",
        "提示：",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          axios
            .post(
              "/admin/luckyDraw/stop",
              {
                id: rows[index].id,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "终止成功",
                });
                this.reload(); //刷新
              }
            })
            .catch(function (error) {
              // 请求失败处理
              ElMessage({
                type: "error",
                message: "终止失败！" + error,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消删除",
          });
        });
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 2;
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/LuckyDraw/index",
          {
            title: this.pagesdatas.title,
            position: this.pagesdatas.position,
            status: this.pagesdatas.status,
            page: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, data) {
      if (data) {
        this.$router
          .push({ path: routeraddr, query: { id: data.id } })
          .catch((error) => error);
      } else {
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/LuckyDraw/index",
          {
            title: data.title,
            position: data.position,
            status: data.status,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //渲染-状态
        if (newdata[i].status === 1) {
          newdata[i].textstatus = "未开始";
          newdata[i].drawhandle = 1;
        } else {
          if (newdata[i].status === 2) {
            newdata[i].textstatus = "进行中";
            newdata[i].drawhandle = 2;
          } else {
            if (newdata[i].status === 3) {
              newdata[i].textstatus = "已过期";
              newdata[i].drawhandle = 3;
            } else {
              if (newdata[i].status === 4) {
                newdata[i].textstatus = "已终止";
                newdata[i].drawhandle = 3;
              }
            }
          }
        }

        //转换-活动时间
        if (newdata[i].begin_time) {
          if (newdata[i].begin_time === 0 || newdata[i].begin_time === null) {
            newdata[i].begin_time = "无活动时间";
          } else {
            newdata[i].begin_time = this.$utils.formatDate(
              newdata[i].begin_time
            );
          }
        } else {
          newdata[i].begin_time = "无活动时间";
        }
        //活动位配置
        if (newdata[i].position) {
          let opt =
            this.maintwoforminfo.selectleft.select[0].options[
            newdata[i].position
            ];
          if (opt) {
            newdata[i].position = opt.label;
          } else {
            newdata[i].position = "--";
          }
        } else {
          newdata[i].position = "---";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
    getconfiginfo(configinfo) {
      //获取api-加载的活动位位置信息
      var arraydata = Array();
      for (var key in configinfo) {
        arraydata[key - 1] = configinfo[key];
      }
      console.log(arraydata);
      var alldatas = Array();
      for (var i = 0; i < arraydata.length + 1; i++) {
        if (i === 0) {
          alldatas[i] = { value: 0, label: "全部" };
        } else {
          alldatas[i] = { value: i, label: configinfo[i] };
        }
      }
      console.log(alldatas);
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.maintwoforminfo.selectleft.select[0].options = alldatas;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //获取活动位置-配置
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "lucky_draw_position",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.getconfiginfo(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/LuckyDraw/index",
          {
            page: 1,
            page_size: 20,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
    maininput,
    mainselect,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>