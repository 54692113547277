<template>
    <div>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div style="border-top: 1px solid #eeeeee; margin-top: 10px">
            <!--主list列表-->
            <div style="background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 10px 20px">
                <div class="box-list-box" style="font-size: 14px; line-height: 60px; margin-bottom: 10px">
                    <!--左侧名称-->
                    <div><span>数据列表</span></div>
                    <!--右侧按钮-->
                    <div class="button-box">
                        <modelBindCommon
                            :ModelValue="multipleSelection_tanbox"
                            :DialogTan="true"
                            PostUrl="/admin/vipRule/shopList"
                            PostAxios="get"
                            :SearchModel="search_model"
                            :TableTitle="device_tabletitle"
                            :ButtonSet="{ text: '添加', size: 'small' }"
                            TanTitle="添加代理商"
                            @modelValue="(data) => modelValueEvent(data)"
                        />
                        <modelUploadExcelButton
                            :PostUrl="'/admin/vipRule/import'"
                            :PostDatas="{ type: 2, ruleId: this.$route.query.id }"
                            :Download="UploadExcelInfo.download"
                            :DownloadName="UploadExcelInfo.downloadName"
                            :Tips="UploadExcelInfo.tips"
                            :ActiveSchedule="false"
                            @getUploadDatas="getUploadDatasEvent"
                        />
                        <div>
                            <el-button type="danger" size="small" @click="delMoreEvent(multipleSelection)">批量移除</el-button>
                            <el-button type="warning" size="small" plain @click="exportDatas()">导出</el-button>
                        </div>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <dpwTable
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        ref="myTableDataRef"
                        :tabletitle="tabletitle"
                        :tabledata="tableData"
                        :totalcount="totalCount"
                        :currentpage="currentPage"
                        :pagesize="PageSize"
                        :Lastpage="lastpage"
                        :Operation="{ isshow: true }"
                        :selectionIsShow="true"
                        @handleSelection="handleSelectionEvent"
                        @handleSelectionOnline="handleSelectionChangeOnline"
                    >
                        <template #content="{ scope, prop }">
                            <div v-if="prop === 'img'">
                                <el-image :src="scope.row.img" style="width: 100px" />
                            </div>
                            <div v-else>
                                <span>{{ scope.row[prop] }}</span>
                            </div>
                        </template>
                        <template #special="{ scope }">
                            <div>
                                <el-button @click="delEvent(tableData, scope.$index)" type="danger" size="mini">移除</el-button>
                            </div>
                        </template>
                    </dpwTable>

                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import maintwo from '@/components/Maintwo'
import dpwTable from '../memberSet/dpw-table.vue'
import modelBindCommon from './modelBindCommon.vue'
import modelUploadExcelButton from './modelUploadExcelButton.vue'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'

export default defineComponent({
    name: 'setModelAgent',
    data() {
        return {
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '代理商名:',
                            placeholder: '输入搜索',
                            name: 'name',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '代理商等级：',
                            placeholder: '全部',
                            name: 'level',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: ''
                                },
                                {
                                    label: '一级代理商',
                                    value: '1'
                                },
                                {
                                    label: '二级代理商',
                                    value: '2'
                                },
                                {
                                    label: '三级代理商',
                                    value: '3'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 240, // 数据列表中-操作-宽度
            tabletitle: [
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '代理商名',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'level',
                    label: '代理商等级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client_num',
                    label: '设备数量',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'truename',
                    label: '添加人',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '添加时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            tableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,

            // 当前列表的多选
            multipleSelection: [],
            // 添加-弹窗-中的多选
            multipleSelection_tanbox: [],
            device_tabletitle: [
                {
                    fixedstatu: false,
                    prop: 'id',
                    label: 'ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '代理商名称',
                    width: '400'
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号/账号',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'level',
                    label: '代理等级',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'province',
                    label: '省级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'city',
                    label: '市级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'county',
                    label: '县/区级',
                    width: ''
                }
            ],
            search_model: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索：',
                            placeholder: '代理商名称/手机号',
                            name: 'keywords',
                            content: '',
                            class: 'input-mydemo'
                        }
                    ]
                }
            },
            // Excel下载上传button按钮
            UploadExcelInfo: {
                download: {
                    is_show: true,
                    btn_title: '下载Excel模板',
                    size: 'small',
                    type: 'primary'
                },
                downloadName: 'admin_shop_id',
                tips: ''
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        // 添加
        modelValueEvent(data) {
            this.multipleSelection_tanbox = data
            if (data.length > 0) {
                axios
                    .post(
                        '/admin/vipRule/ruleRelation',
                        {
                            ruleId: this.$route.query.id,
                            type: 2,
                            ids: data.map((n) => {
                                return n.id
                            })
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        if (response.data.code == 0) {
                            ElMessage({
                                type: 'success',
                                message: '添加成功',
                                duration: 1000,
                                onClose: () => {
                                    this.handleCurrentChange(1)
                                }
                            })
                        }
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            }
        },
        // ********* 多选 *********
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 去重
        reduce(datas) {
            let obj = {}
            let reduce = []
            reduce = datas.reduce(function (item, next) {
                //item为没有重复id的数组，next为当前对象
                obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
                return item
            }, [])

            return reduce
        },
        // 弹窗-多选数据-选中
        handleSelectionEvent(datas) {
            let all_datas = [...this.multipleSelection, ...this.deepcopy(datas)]
            // 去重
            this.multipleSelection = this.reduce(all_datas)

            if (datas.length == 0) {
                this.multipleSelection = []
            }
        },
        // 弹窗-多选数据-取消
        handleSelectionChangeOnline({ datas, row }) {
            console.log('datas, row :', datas, row)
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id)
            this.handleSelectionEvent(datas)
        },
        // 弹窗-多选-检查反显
        moreDataBackShow(datas) {
            if (this.$refs.myTableDataRef) {
                this.$refs.myTableDataRef.backShow(datas)
            }
        },
        // **********多选End*************
        // 批量移除
        delMoreEvent(rows) {
            if (rows.length == 0) {
                ElMessage({
                    type: 'error',
                    message: '至少选择一条数据,再批量移除'
                })
            } else {
                ElMessageBox.confirm('确定要移除所选内容吗?', '提示：', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        axios
                            .post(
                                '/admin/vipRule/ruleRelationDelete',
                                {
                                    ruleId: this.$route.query.id,
                                    type: 2,
                                    ids: rows.map((n) => {
                                        return n.relation_id
                                    })
                                },
                                {
                                    headers: {
                                        Authentication: localStorage.getItem('token')
                                    }
                                }
                            )
                            .then((response) => {
                                if (response.data.code === 0) {
                                    ElMessage({
                                        type: 'success',
                                        message: '移除成功',
                                        duration: 1000,
                                        onClose: () => {
                                            this.handleCurrentChange(1)
                                        }
                                    })
                                }
                            })
                            .catch(function (error) {
                                // 请求失败处理
                                ElMessage({
                                    type: 'error',
                                    message: '移除失败！' + error
                                })
                            })
                    })
                    .catch(() => {
                        ElMessage({
                            type: 'info',
                            message: '取消移除'
                        })
                    })
            }
        },
        // 单个移除
        delEvent(rows, index) {
            ElMessageBox.confirm("确定删除该条 ' " + rows[index].name + " ' 数据吗?", '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/vipRule/ruleRelationDelete',
                            {
                                ruleId: this.$route.query.id,
                                type: 2,
                                ids: [rows[index].relation_id]
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '移除成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            // 请求失败处理
                            ElMessage({
                                type: 'error',
                                message: '移除失败！' + error
                            })
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消移除'
                    })
                })
        },

        // 批量导入
        getUploadDatasEvent() {
            this.handleCurrentChange(1)
        },
        // 导出
        exportDatas() {
            axios
                .get('/admin/vipRule/shopIndex', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: { ...this.pagesdatas, ruleId: this.$route.query.id, export: '1' }
                })
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas.page = 1
            this.pagesdatas.page_size = val
            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas.page = val
            this.pagesdatas.page_size = this.PageSize
            this.postAxiosEvent(this.pagesdatas)
        },

        //提交等相关event事件函数
        // 获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：', data)
            this.pagesdatas = data
            this.pagesdatas.page = 1
            this.pagesdatas.page_size = this.PageSize

            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.tableData = newdata.map((n) => {
                n['id'] = n.relation_id
                return n
            })
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false

            this.moreDataBackShow(this.multipleSelection)
        },
        postAxiosEvent(data) {
            this.loading = true
            axios
                .get('/admin/vipRule/shopIndex', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: { ...data, ruleId: this.$route.query.id }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        maintwo,
        dpwTable,
        modelBindCommon,
        modelUploadExcelButton
    }
})
</script>
<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
}

.box-list-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.button-box {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
</style>
