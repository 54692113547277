<template>
    <div>
        <!-- 多选-显示&反显框 -->
        <div class="model-class">
            <div class="model-class-flex">
                <div class="mx-1" v-for="(item, index) in modelvalue" :key="index">
                    <el-tag type="info" effect="light" closable @close="closeEvent(item, index)">
                        {{ item }}
                    </el-tag>
                </div>
                <div class="mx-1">
                    <el-tag type="primary" effect="light" @click="clickAddEvent"> 添加更多... </el-tag>
                </div>
                <div class="mx-1" v-if="Object.values(modelvalue).length > 0">
                    <el-tag type="danger" effect="light" @click="delAllEvent"> 全部删除 </el-tag>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div v-if="dialogtan">
            <modelDialog :isshow="dialogVisible" :title="flag == 'agent' ? '选择代理商' : '选择设备'" v-on:beforeclose="handleClose">
                <template v-slot:search>
                    <modelSearch :gaoisshow="false" :forminfo="maintwoforminfo" v-on:getsearchinfo="getSeachinfoEvent" />
                </template>
                <template v-slot:table>
                    <modelTable
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        ref="mymultipleTableRef"
                        :tabletitle="mainthreetabletitle"
                        :tabledata="mainthreetableData"
                        :totalcount="totalCount"
                        :currentpage="currentPage"
                        :pagesize="PageSize"
                        :Lastpage="lastpage"
                        @handleSelection="handleSelectionEvent"
                        @handleSelectionOnline="handleSelectionChangeOnline"
                    />
                </template>
                <template v-slot:pages>
                    <modelPages
                        :totalCount="totalCount"
                        :currentPage="currentPage"
                        :pageSizes="pageSizes"
                        :PageSize="PageSize"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </template>
                <template v-slot:footbtn>
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="submitEvent(this.multipleSelection, (dialogVisible = false))">确定</el-button>
                </template>
            </modelDialog>
        </div>
    </div>
</template>

<script>
import modelDialog from './modelDialog.vue'
import modelSearch from './modelSearch.vue'
import modelTable from './modelTable.vue'
import modelPages from './modelPages.vue'
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
    name: 'modelBind',
    inject: ['reload'],
    props: ['ModelValue', 'Flag', 'DialogTan', 'SearchModel', 'TableTitle', 'PostUrl', 'PostAxios'],
    watch: {
        ModelValue: {
            handler(newVal) {
                this.modelvalue = newVal
            },
            deep: true,
            immediate: true
        },
        Flag: {
            handler(newVal) {
                this.flag = newVal
            },
            deep: true,
            immediate: true
        },
        DialogTan: {
            handler(newVal) {
                this.dialogtan = newVal
            },
            deep: true,
            immediate: true
        },
        PostUrl: {
            handler(newVal) {
                this.posturl = newVal
            },
            deep: true,
            immediate: true
        },
        PostAxios: {
            handler(newVal) {
                this.postaxios = newVal
            },
            deep: true,
            immediate: true
        },
        SearchModel: {
            handler(newVal) {
                this.maintwoforminfo = newVal
            },
            deep: true,
            immediate: true
        },
        TableTitle: {
            handler(newVal) {
                this.mainthreetabletitle = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            modelvalue: {},
            flag: '',

            dialogVisible: false,
            maintwoforminfo: {},
            mainthreetabletitle: [],
            mainthreetableData: [],

            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            currentPage: 1,
            pageSizes: [10, 20, 50, 100],
            PageSize: 10,

            multipleSelection: [], //多选的数据

            posturl: '',
            postaxios: 'post',
            pagesdatas: ''
        }
    },
    methods: {
        // 清除-单个tag
        closeEvent(data, index) {
            delete this.modelvalue[index]

            if (JSON.stringify(this.modelvalue) == '{}') {
                this.$emit('modelValue', '')
            } else {
                this.$emit('modelValue', this.modelvalue)
            }
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != index)
            this.moreDataBackShow(this.multipleSelection)
        },
        // 点击-全部删除-按钮
        delAllEvent() {
            this.modelvalue = ''
            this.$emit('modelValue', this.modelvalue)
            this.multipleSelection = []
            this.moreDataBackShow(this.multipleSelection)
        },
        // 点击-添加更多按钮-打开弹窗
        clickAddEvent() {
            this.dialogVisible = true

            // 异步-反显
            setTimeout(() => {
                this.multipleSelection = this.objectToArray(this.modelvalue)
                this.moreDataBackShow(this.multipleSelection)
            }, 100)
        },
        // 将已有数据转换成数组
        objectToArray(data) {
            // 代理商相关的数据转换
            if (this.flag == 'agent') {
                let key_array = Object.keys(data)
                if (key_array.length > 0) {
                    return key_array.map((n) => {
                        return { id: n, name: data[n] }
                    })
                } else {
                    return []
                }
            }
            // 设备相关数据转换
            if (this.flag == 'device') {
                let key_array = Object.keys(data)
                if (key_array.length > 0) {
                    return key_array.map((n) => {
                        return { id: n, client: data[n] }
                    })
                } else {
                    return []
                }
            }
        },
        // 弹窗-关闭弹窗
        handleClose(done) {
            done()
            this.dialogVisible = false
        },
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 去重
        reduce(datas) {
            let obj = {}
            let reduce = []
            reduce = datas.reduce(function (item, next) {
                //item为没有重复id的数组，next为当前对象
                obj[next.id] ? '' : (obj[next.id] = true && item.push(next))
                return item
            }, [])

            return reduce
        },
        // 弹窗-多选数据-选中
        handleSelectionEvent(datas) {
            let all_datas = [...this.multipleSelection, ...this.deepcopy(datas)]
            // 去重
            this.multipleSelection = this.reduce(all_datas)
        },
        // 弹窗-多选数据-取消
        handleSelectionChangeOnline({ datas, row }) {
            this.multipleSelection = this.multipleSelection.filter((n) => n.id != row.id)
            this.handleSelectionEvent(datas)
        },
        // 弹窗-确定
        submitEvent(datas) {
            if (this.flag == 'agent') {
                let data = {}
                datas.map((n) => {
                    data[n.id] = n.name
                    return
                })
                this.modelvalue = data
            }

            if (this.flag == 'device') {
                let data = {}
                datas.map((n) => {
                    data[n.id] = n.client
                    return
                })
                this.modelvalue = data
            }

            this.$emit('modelValue', this.modelvalue)
        },

        // 弹窗-多选-检查反显
        moreDataBackShow(datas) {
            console.log(this.$refs.mymultipleTableRef)
            if (this.$refs.mymultipleTableRef) {
                console.log('fanxian:', datas)
                this.$refs.mymultipleTableRef.backShow(datas, this.mainthreetableData)
            }
        },

        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            this.pagesdatas.page = 1
            this.pagesdatas.page_size = val

            this.postAxios(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            //加载loading
            this.loading = true
            this.pagesdatas.page = val
            this.pagesdatas.page_size = this.PageSize

            this.postAxios(this.pagesdatas)
        },

        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            this.pagesdatas = data
            this.loading = true
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }

            if (this.flag == 'agent') {
                this.pagesdatas.keywords = data.keywords
            }
            if (this.flag == 'device') {
                this.pagesdatas.slientId = data.slientId
                this.pagesdatas.area_code = data.area_code
            }

            this.postAxios(this.pagesdatas)
        },
        // 接口
        postAxios(datas, index) {
            if (this.postaxios) {
                axios
                    .get(this.posturl, {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: datas
                    })
                    .then((response) => {
                        if (!index) {
                            this.myaxiosfunc(response.data.result)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            } else {
                axios
                    .post(this.posturl, datas, {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    })
                    .then((response) => {
                        if (!index) {
                            this.myaxiosfunc(response.data.result)
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            }
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                if (newdata[i].area_code) {
                    //将省市区分割
                    var arrcity = newdata[i].area_code.split(',')
                    newdata[i].province = arrcity[0]
                    newdata[i].city = arrcity[1]
                    newdata[i].county = arrcity[2]
                }
            }
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page
            this.lastpage = successdata.last_page
            this.loading = false

            this.moreDataBackShow(this.multipleSelection)
        }
    },
    created() {
        this.loading = true
        this.pagesdatas = {
            page: 1,
            page_size: this.PageSize
        }

        this.postAxios(this.pagesdatas)
    },
    components: {
        modelDialog,
        modelSearch,
        modelTable,
        modelPages
    }
})
</script>

<style scoped>
.model-class {
    width: 100%;
    min-height: 40px;
    border: 1px solid var(--el-border-color-base);
    border-radius: 4px;
    background-color: #fff;
}

.model-class:hover {
    cursor: pointer;
    border-color: var(--el-text-color-placeholder);
}
.model-class-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;

    padding: 0px 5px;
}
.mx-1 {
    margin-right: 8px;
}
</style>
