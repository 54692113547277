<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box"  >
        <div class="box_title"  >
          <div class="box_title_font">会员邀请设置</div>
          <div class="box_title_button"></div>
        </div>
        <!--内容-->
        <div class="box_content" id="switch">
          <div style="width: 100%; margin-top: 10px">
            <el-form ref="tableData" :model="tableData" :rules="ruleData" class="demo-ruleForm" v-loading="loading"
              element-loading-text="正在飞速加载中...">
              <el-form-item>
                <el-table :header-cell-style="{ background: '#F5F7FA' }" :data="tableData" style="width: 100%" border>
                  <!-- 邀请人数 -->
                  <el-table-column prop="name" label="邀请人数" width="180">
                    <template #default="scope">
                      <el-form-item :prop="scope.$index + '.code'" :rules="ruleData.code">
                        <el-input type="number" step="1" :min="
                          parseInt(scope.$index) == 0
                            ? 1
                            : parseInt(
                              this.tableData[parseInt(scope.$index) - 1]
                                .code
                            ) + 1
                        " maxlength="3" v-model.number="scope.row.code" clearable />
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <!-- 礼品1名称 -->
                  <el-table-column prop="name1" label="礼品1名称" min-width="180">
                    <template #default="scope">
                      <el-input v-model="scope.row.name1" placeholder="最多6个中文" maxlength="6" show-word-limit
                        clearable />
                    </template>
                  </el-table-column>
                  <!-- 礼品1图片 -->
                  <el-table-column prop="img1" label="礼品1图片" :width="120">
                    <template #default="scope">
                      <el-upload class="upload-demo" :list-type="myimgesup.listtype" :action="
                        this.$baseurl +
                        myimgesup.action +
                        '?target_id=' +
                        myimgesup.target_id
                      " :headers="myimgesup.headers" :data="uploadParams" :file-list="scope.row.img1_file"
                        :limit="myimgesup.limit" :on-exceed="onExceedEvent" :on-preview="handlePreview" :on-remove="
                          (file, fileList) =>
                            handleRemove(file, fileList, scope.$index, 'img1')
                        " :before-remove="beforeRemove" :before-upload="
  (file) =>
    beforeUpload(
      file,
      myimgesup.target_id,
      myimgesup.rules
    )
" :on-progress="uploadChange" :on-success="
  (response, file, fileList) =>
    uploadSuccess(
      response,
      file,
      fileList,
      scope.$index,
      'img1'
    )
" :on-error="uploadError">
                        <el-button type="text" v-if="scope.row.img1 == '' || scope.row.img1 == null">添加</el-button>
                      </el-upload>
                    </template>
                  </el-table-column>
                  <!-- 礼品2名称 -->
                  <el-table-column prop="name2" label="礼品2名称" min-width="180">
                    <template #default="scope">
                      <el-input v-model="scope.row.name2" placeholder="最多6个中文" maxlength="6" show-word-limit
                        clearable />
                    </template>
                  </el-table-column>
                  <!-- 礼品2图片 -->
                  <el-table-column prop="img2" label="礼品2图片" :width="120">
                    <template #default="scope">
                      <el-upload class="upload-demo" :list-type="myimgesup.listtype" :action="
                        this.$baseurl +
                        myimgesup.action +
                        '?target_id=' +
                        myimgesup.target_id
                      " :headers="myimgesup.headers" :data="uploadParams" :file-list="scope.row.img2_file"
                        :limit="myimgesup.limit" :on-exceed="onExceedEvent" :on-preview="handlePreview" :on-remove="
                          (file, fileList) =>
                            handleRemove(file, fileList, scope.$index, 'img2')
                        " :before-remove="beforeRemove" :before-upload="
  (file) =>
    beforeUpload(
      file,
      myimgesup.target_id,
      myimgesup.rules
    )
" :on-progress="uploadChange" :on-success="
  (response, file, fileList) =>
    uploadSuccess(
      response,
      file,
      fileList,
      scope.$index,
      'img2'
    )
" :on-error="uploadError">
                        <el-button type="text" v-if="scope.row.img2 == '' || scope.row.img2 == null">添加</el-button>
                      </el-upload>
                    </template>
                  </el-table-column>
                  <!-- 礼品3名称 -->
                  <el-table-column prop="name3" label="礼品3名称" min-width="180">
                    <template #default="scope">
                      <el-input v-model="scope.row.name3" placeholder="最多6个中文" maxlength="6" show-word-limit
                        clearable />
                    </template>
                  </el-table-column>
                  <!-- 礼品3图片 -->
                  <el-table-column prop="img3" label="礼品3图片" :width="120">
                    <template #default="scope">
                      <el-upload class="upload-demo" :list-type="myimgesup.listtype" :action="
                        this.$baseurl +
                        myimgesup.action +
                        '?target_id=' +
                        myimgesup.target_id
                      " :headers="myimgesup.headers" :data="uploadParams" :file-list="scope.row.img3_file"
                        :limit="myimgesup.limit" :on-exceed="onExceedEvent" :on-preview="handlePreview" :on-remove="
                          (file, fileList) =>
                            handleRemove(file, fileList, scope.$index, 'img3')
                        " :before-remove="beforeRemove" :before-upload="
  (file) =>
    beforeUpload(
      file,
      myimgesup.target_id,
      myimgesup.rules
    )
" :on-progress="uploadChange" :on-success="
  (response, file, fileList) =>
    uploadSuccess(
      response,
      file,
      fileList,
      scope.$index,
      'img3'
    )
" :on-error="uploadError">
                        <el-button type="text" v-if="scope.row.img3 == '' || scope.row.img3 == null">添加</el-button>
                      </el-upload>
                    </template>
                  </el-table-column>
                  <!-- 操作 -->
                  <el-table-column prop="caozuo" label="操作" width="80">
                    <template #default="scope">
                      <el-button type="text" v-if="scope.row.addisshow" @click="addRechargeEvent(tableData)">增加
                      </el-button>
                      <el-button type="text" v-if="!scope.row.addisshow"
                        @click="delRechargeEvent(scope.$index, tableData)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
              <el-form-item style="text-align: center" v-if="this.mypowerlimits.hyyqsz_bc">
                <el-button type="primary" @click="submitForm('tableData', 'coin_set', tableData)" style="width: 150px">
                  保存</el-button>
                <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export default defineComponent({
  name: "vplusinvite",
  data() {
    // 会员邀请设置-邀请人数-校验
    var checkRebate = (rule, value, callback) => {
      console.log(rule, value);
      //获取当前数组中的ide
      var getide = parseInt(rule.field.split(".")[0]);
      console.log(getide);
      // //获取投放天数中key值为ide下的相关数据
      var getdatas = this.tableData;

      if (!value) {
        return callback(new Error("邀请人数不能为空"));
      }
      setTimeout(() => {
        // 当输入为整数的判断
        if (!Number.isInteger(value)) {
          callback(new Error("请输入整数"));
        } else {
          if (value < 1) {
            callback(new Error("邀请人数至少为1人"));
          } else {
            if (getide == 0) {
              if (getdatas.length > 1) {
                if (value >= getdatas[getide + 1].code) {
                  callback(new Error("当前值需小于下一条数据邀请人数值"));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            } else {
              if (value <= getdatas[getide - 1].code) {
                callback(new Error("当前值需大于" + getdatas[getide - 1].code));
              } else {
                if (getdatas[getide + 1] && getdatas[getide + 1].code != "") {
                  if (value >= getdatas[getide + 1].code) {
                    callback(new Error("当前值需小于下一条数据邀请人数值"));
                  } else {
                    callback();
                  }
                } else {
                  callback();
                }
              }
            }
          }
        }
      }, 1000);
    };
    return {
      mainonebread: [
        {
          name: "设置",
        },
        {
          name: "会员设置",
        },
        {
          name: "会员邀请设置",
          path: "/plusinvite",
        },
      ], // 当前页-【面包屑导航】
      activeName: "one", //tabs标签-默认显示页

      myimgesupisshow: true, //form表单-imgesuploade上传图片
      myimgesup: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/admin/upload/file", //upload上传API地址
        headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
        title: "添加", //button-按钮文字
        title_type: "text", //button-按钮显示样式
        isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
        // tips: "(1)建议尺寸：1070*1830像素; (2)视频仅持格式：*.mp4; (3)图片支持格式：*.jpg; (4)最大支持上传20M文件", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 101, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用 ; 101-plus设置图片；
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 2000, //文件大小（单位kb）
            type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
            typechart: "jpg/png", //文件格式提示规则
          },
        },
      },

      // PLUS设置
      tableData: [
        // {
        //   code: "1",
        //   name1: "文具套装",
        //   name2: "精美相册",
        //   name3: "好视力眼贴",
        //   img1: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100", //会员图标
        //   img1_file: [
        //     {
        //       name: "food.jpeg",
        //       url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        //     },
        //   ],
        //   img2: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100", //会员图标
        //   img2_file: [
        //     {
        //       name: "food.jpeg",
        //       url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        //     },
        //   ],
        //   img3: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100", //会员图标
        //   img3_file: [
        //     {
        //       name: "food.jpeg",
        //       url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        //     },
        //   ],
        //   addisshow: true, //操作按钮
        // },
      ],
      ruleData: {
        code: [
          {
            required: true,
            validator: checkRebate,
            trigger: "change",
          },
        ],
      },

      loading: false, //初始化-loading加载动效，默认是false关闭
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
    };
  },
  methods: {
    // 图片上传函数 -------------------（以下）-------------------
    //点击图片filelist时，触发函数，且返回file数据
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 3, you selected ${files.length
        } files this time, add up to ${files.length + fileList.length} totally`
      );
    },
    // 上传文件超出数量限制时的函数
    onExceedEvent(file, fileList) {
      ElMessage({
        type: "warning",
        message: "超出文件上传限制！最多为" + fileList.length + "个！",
      });
    },
    //上传图片-预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //上传图片||文件-上传前（准备）-传参
    beforeUpload(file, targetid, rules) {
      // console.log("当前上传的文件信息：" + file.type);
      if (rules) {
        if (file.type == "video/mp4") {
          if (rules.video) {
            // 判断文件 - 格式&大小等
            let types = rules.video.type;
            const isImage = types.includes(file.type);
            if (!isImage) {
              this.$message.error(
                "上传文件只能是 " + rules.video.typechart + " 文件格式!"
              );
              return false;
            }
            const isLtSize = file.size / 1024 < rules.video.size;
            if (!isLtSize) {
              this.$message.error(
                "上传文件大小不能超过 " + rules.video.size + " KB!"
              );
              return false;
            }
            if (targetid) {
              this.uploadParams = { file: file, target_id: targetid };
              return isImage && isLtSize;
            } else {
              this.uploadParams = { file: file, target_id: 7 };
              return isImage && isLtSize;
            }
          }
        } else {
          // rules.images图片-规则判断
          if (rules.images) {
            // 判断图片 - 格式&大小&图片宽高等
            let types = rules.images.type;
            const isImage = types.includes(file.type);
            if (!isImage) {
              if (rules.images.typechart) {
                this.$message.error(
                  "上传图片只能是 " + rules.images.typechart + " 图片格式!"
                );
              } else {
                this.$message.error(
                  "上传图片只能是 JPG/JPEG/gif/bmp/PNG  图片格式!"
                );
              }
              return false;
            }
            const isLtSize = file.size / 1024 < rules.images.size;
            if (!isLtSize) {
              this.$message.error(
                "上传图片大小不能超过 " + rules.images.size + " KB!"
              );
              return false;
            }
            if (
              rules.images.image_width != 0 &&
              rules.images.image_height != 0
            ) {
              const isSize = new Promise(function (resolve, reject) {
                let width = rules.images.image_width;
                let height = rules.images.image_height;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                  let valid = image.width == width && image.height == height;
                  valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
              }).then(
                () => {
                  return file;
                },
                () => {
                  this.$message.error(
                    "上传图片尺寸不符合,只能是" +
                    rules.images.image_width +
                    "*" +
                    rules.images.image_height +
                    "!"
                  );
                  return Promise.reject();
                }
              );
              if (targetid) {
                this.uploadParams = { file: file, target_id: targetid };
                return isImage && isLtSize && isSize;
              } else {
                this.uploadParams = { file: file, target_id: 7 };
                return isImage && isLtSize && isSize;
              }
            } else {
              if (targetid) {
                this.uploadParams = { file: file, target_id: targetid };
                return isImage && isLtSize;
              } else {
                this.uploadParams = { file: file, target_id: 7 };
                return isImage && isLtSize;
              }
            }
          } else {
            // rules.files文件-规则判断
            if (rules.files) {
              // 判断文件 - 格式&大小等
              let types = rules.files.type;
              const isImage = types.includes(file.type);
              if (!isImage) {
                this.$message.error(
                  "上传文件只能是 text、pdf、word、xls等文件格式!"
                );
                return false;
              }

              const isLtSize = file.size / 1024 < rules.files.size;
              if (!isLtSize) {
                this.$message.error(
                  "上传文件大小不能超过 " + rules.files.size + " KB!"
                );
                return false;
              }
              if (targetid) {
                this.uploadParams = { file: file, target_id: targetid };
                return isImage && isLtSize;
              } else {
                this.uploadParams = { file: file, target_id: 7 };
                return isImage && isLtSize;
              }
            }
          }
        }
      } else {
        if (targetid) {
          this.uploadParams = { file: file, target_id: targetid };
          return true;
        } else {
          this.uploadParams = { file: file, target_id: 7 };
          return true;
        }
      }
    },
    //移除文件前-触发的函数，可显示提示，待确定后移除文件
    beforeRemove(file) {
      console.log("get file status is:" + file.status);
      if (file.status === "success") {
        return this.$confirm(`确定移除该文件?`);
      } else {
        return;
      }
    },
    //上传图片-删除图片
    handleRemove(file, fileList, rowide, getide) {
      console.log("删除图片||文件：" + file, fileList, rowide);
      if (getide == "img1") {
        this.tableData[rowide].img1 = "";
        this.tableData[rowide].img1_file = [];
      } else {
        if (getide == "img2") {
          this.tableData[rowide].img2 = "";
          this.tableData[rowide].img2_file = [];
        } else {
          this.tableData[rowide].img3 = "";
          this.tableData[rowide].img3_file = [];
        }
      }
    },
    //上传图片||文件-成功
    uploadSuccess(response, file, fileList, rowide, getide) {
      if (response.code === 0) {
        //上传成功的文件后缀
        var successfile = file.name.split(".");
        // console.log(successfile);
        //上传成功的文件后缀名比较，且返回图片logo显示
        if (
          ["jpg", "jpeg", "png", "gif"].indexOf(
            successfile[successfile.length - 1]
          ) === -1
        ) {
          //当文件非以上4中格式时，回显logo图片为以下图片(文件样式)
          fileList[
            fileList.length - 1
          ].url = require("@/assets/imgs/upload/upload.png");
        }
        //提示上传成功！
        ElMessage({
          type: "success",
          message: "上传成功！",
        });
        //上传成功后，将图片||文件地址，返回父组件
        response.result.name = successfile[0];
        response.result.type = successfile[successfile.length - 1]; //返回文件后缀-判断类型
        console.log("上传成功之后的图片数据" + JSON.stringify(response.result));

        if (getide == "img1") {
          this.tableData[rowide].img1 = response.result.url;
          this.tableData[rowide].img1_file = [
            {
              url: response.result.url,
            },
          ];
        } else {
          if (getide == "img2") {
            this.tableData[rowide].img2 = response.result.url;
            this.tableData[rowide].img2_file = [
              {
                url: response.result.url,
              },
            ];
          } else {
            this.tableData[rowide].img3 = response.result.url;
            this.tableData[rowide].img3_file = [
              {
                url: response.result.url,
              },
            ];
          }
        }
      } else {
        //提示上传失败！
        ElMessage({
          type: "error",
          message: "上传失败！" + response.message,
        });
        //删除当前队列中的最后一个
        fileList.splice(fileList.length - 1, 1);
      }
      // console.log("上传成功返回数据！" + response, file, fileList);
    },
    //上传图片&文件-失败
    uploadError(err, file, fileList) {
      //提示上传失败！
      ElMessage({
        type: "error",
        message: "上传失败！",
      });
      console.log("上传失败！" + err, file, fileList);
    },
    // 图片上传函数 -------------------（以上）-------------------
    //操作-增加事件
    addRechargeEvent(fromdata, times) {
      if (times) {
        if (fromdata.length < times) {
          var oneAddChat = {
            code: "",
            name1: "",
            name2: "",
            name3: "",
            img1: "", //礼品图片1
            img1_file: [],
            img2: "", //礼品图片2
            img2_file: [],
            img3: "", //礼品图片3
            img3_file: [],
          };
          // js中通过push向数组最后新增指定的元素
          fromdata.push(oneAddChat);
        } else {
          ElMessage({
            type: "warning",
            message: "最多增加6条！",
          });
        }
      } else {
        var oneAddChats = {
          code: "",
          name1: "",
          name2: "",
          name3: "",
          img1: "", //礼品图片1
          img1_file: [],
          img2: "", //礼品图片2
          img2_file: [],
          img3: "", //礼品图片3
          img3_file: [],
        };
        // js中通过push向数组最后新增指定的元素
        fromdata.push(oneAddChats);
      }
    },
    //操作-删除事件
    delRechargeEvent(ide, formdata) {
      console.log("获取的删除id为：" + JSON.stringify(ide));
      // js中通过splice删除指定的元素
      formdata.splice(ide, 1);
    },
    //from表单-提交表单-会员邀请设置（保存）
    submitForm(formName) {
      // console.log(this.$refs[formName].model);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //保存-PLUS设置
          axios
            .post(
              "/admin/config/update",
              {
                contents: this.$refs[formName].model,
                key_name: "member_plus_invite_set",
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              console.log(response.data);
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "保存成功！",
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //from表单-重置表单-会员邀请设置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true;
      //首次加载-PLUS设置
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "member_plus_invite_set",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var newdata = response.data.result;
          newdata[0].addisshow = true;
          // 数据-渲染
          for (var i = 0; i < newdata.length; i++) {
            if (i == 0) {
              newdata[i].addisshow = true;
            } else {
              newdata[i].addisshow = false;
            }
            newdata[i].code = parseInt(newdata[i].code);
          }
          this.tableData = newdata;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
});
</script>
<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
}

/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

.box_content :deep(.el-form-item__error) {
  position: static !important;
}

.box_content :deep(.el-upload) {
  display: block !important;
}
</style>