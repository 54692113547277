<template>
    <!-- 图片-组件设置 -->
    <div>
        <!-- 标题 -->
        <div class="pages-box-small-title small-content-title-title-one">
            图片设置
        </div>
        <div class="pages-box-small-content">
            <!-- 图片 -->
            <div>
                <div class="small-content-title-title">
                    <div>图片</div>
                    <div>
                        <mainimgesup v-model="pictureformData.value" :myimgesup="myimgesup"
                            :myimgesupisshow="myimgesupisshow" v-on:getuploadfile="getUploadFileEvent"
                            v-on:deluploadfile="delUploadFileEvent">
                        </mainimgesup>
                    </div>
                </div>
                <div class="content-box-line" style="text-align: center;">
                    <el-image :src="pictureformData.value" style="width: 200px;"></el-image>
                </div>
            </div>
            <!-- 宽高 -->
            <div>
                <div class="small-content-title-title">宽高</div>
                <div class="small-content-title-content content-box-line">
                    <div>
                        <el-input v-model="pictureformData.styletype.width" placeholder="宽(单位：% 或 px)" clearable>
                        </el-input>
                    </div>
                    <div style="padding: 10px;"><i class="el-icon-link" style="transform: rotate(45deg);"></i></div>
                    <div>
                        <el-input v-model="pictureformData.styletype.height" placeholder="高(单位：% 或 px)" clearable>
                        </el-input>
                    </div>
                </div>
            </div>
            <!-- 链接 -->
            <div>
                <div class="small-content-title-title">
                    <div>链接</div>
                    <div>
                        <el-button type="primary" size="small" plain
                            @click="seturlEvent(this.pictureformData, (dialogVisible1 = true))">设置链接</el-button>
                    </div>
                </div>
                <div class="content-box-line">
                    <div>
                        <el-input v-model="pictureformData.formdata.jumpurl" placeholder="请设置链接" disabled clearable>
                        </el-input>
                    </div>

                </div>
            </div>

        </div>

        <!-- 弹窗-设置链接 -->
        <el-dialog v-model="dialogVisible1" title="链接" :width="this.searchwidth" :before-close="handleCloseSenior">
            <div>
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                    <div style="
                        max-height: 450px;
                        overflow: hidden;
                        overflow-y: visible;
                        margin: 20px 5% 0 0;
                        ">
                        <el-form-item label="跳转方式：">
                            <el-radio-group v-model="this.ruleForm.jumptype" class="ml-4">
                                <!-- <el-radio label="1">站内链接</el-radio>
                                <el-radio label="2">站外小程序</el-radio> -->
                                <el-radio label="3">H5链接/站外网页</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <!-- <el-form-item label="站内链接：" v-if="this.ruleForm.jumptype == 1">
                            <el-select v-model="this.ruleForm.inurl" placeholder="请选择站内链接" style="width: 100%;">
                                <el-option v-for="(item, index) in this.inurloptions" :key="index" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="小程序APPID：" v-if="this.ruleForm.jumptype == 2">
                            <el-select v-model="this.ruleForm.miniappid" placeholder="请选择小程序APPID" style="width: 100%;">
                                <el-option v-for="(item, index) in this.miniappidoptions" :key="index"
                                    :label="item.label" :value="item.value" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="小程序路径：" v-if="this.ruleForm.jumptype == 2">
                            <div>
                                <el-input v-model="this.ruleForm.miniurl" placeholder="请填写小程序路径"> </el-input>
                            </div>
                            <div>
                                <span style="font-size: 12px;height: 20px;line-height: 20px;">小程序地址为空表示默认跳转小程序首页</span>
                            </div>
                        </el-form-item> -->
                        <el-form-item label="跳转路径：" v-if="this.ruleForm.jumptype == 3">
                            <el-input v-model="ruleForm.outurl" placeholder="请填写以http://或https://开头的跳转路径"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <div style="text-align: right;">
                                <el-button @click="resetForm((dialogVisible1 = false))">取消</el-button>
                                <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
                            </div>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </el-dialog>


    </div>

</template>

<script>
import { reactive, ref } from "vue";
import mainimgesup from "@/components/Mainimgesup.vue";
import axios from "axios";

export default {
    name: "pictureset",
    props: ["pictureform"],
    setup(props) {
        const pictureformData = reactive(props["pictureform"]);

        const handleCloseSenior = (done) => {
            done();
        };

        return {
            pictureformData,
            handleCloseSenior
        };
    },
    data() {
        return {
            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: false, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                action: "/admin/upload/openFile", //将alioss上传成功的URI地址上传给后端API接口存储
                headers: { Authentication: localStorage.getItem("token") }, //后端API接口的headers
                method: "post", //后端API接口请求方式，get|post
                multiple: false, //上传多个文件，允许true，不允许false
                title: "上传图片", //button-按钮文字
                title_type: "primary", //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                isshow: false, //button-按钮是否显示，注意：false表示显示，true表示不显示
                tips: "", //提示&建议文字
                limit: "", //上传数量限制，为空表示不限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 50000, //文件大小（单位kb）
                        type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
                        typechart: "jpg/png", //文件格式提示规则
                    },
                },
            },

            dialogVisible1: ref(false),
            searchwidth: "580px",
            // 弹窗相关数据
            ruleForm: {
                jumptype: "1",
                inurl: '',
                miniappid: '',
                miniurl: '',
                outurl: '',
            }, //弹窗中-form表单


            // 站内链接-下拉
            inurloptions: [
                {
                    value: '1',
                    label: '站内01'
                },
                {
                    value: '2',
                    label: '站内02'
                }
            ],
            miniappidoptions: [
                {
                    value: '1',
                    label: '小程序APPID01'
                },
                {
                    value: '2',
                    label: '小程序APPID02'
                }
            ],

             mytoken: localStorage.getItem("token"), //获取存储的token
        }
    },
    methods: {
        //获取upload子组件回传的数据
        getUploadFileEvent(data) {
            console.log("当前获取的回传数据：", data);
            this.pictureformData.value = data.url;

            this.subformEvent(this.pictureformData)
        },
        //获取upload子组件回传-删除后的filelist数据
        delUploadFileEvent(filelist) {
            console.log("当前获取的回传的filelist：", filelist);

            this.pictureformData.value = '';
            this.subformEvent(this.pictureformData)
        },
        // 点击-设置链接-返显数据
        seturlEvent(data) {
            this.ruleForm.jumptype = data.formdata.jumptype;

            if (data.formdata.jumptype == 1) {
                this.ruleForm.inurl = data.formdata.jumpurl;
            }
            if (data.formdata.jumptype == 2) {
                this.ruleForm.miniappid = data.formdata.jumpappid;
                this.ruleForm.miniurl = data.formdata.jumpurl;
            }
            if (data.formdata.jumptype == 3) {
                this.ruleForm.outurl = data.formdata.jumpurl;
            }
        },
        // 点击-确定按钮-设置数据
        submitForm(formName) {
            var getdatas = this.$refs[formName].model;

            this.pictureformData.formdata.jumptype = getdatas.jumptype
            if (getdatas.jumptype == 1) {
                this.pictureformData.formdata.jumpappid = '';
                this.pictureformData.formdata.jumpurl = getdatas.inurl;
            }
            if (getdatas.jumptype == 2) {
                this.pictureformData.formdata.jumpappid = getdatas.miniappid;
                this.pictureformData.formdata.jumpurl = getdatas.miniurl;
            }
            if (getdatas.jumptype == 3) {
                this.pictureformData.formdata.jumpappid = '';
                this.pictureformData.formdata.jumpurl = getdatas.outurl;
            }
            this.subformEvent(this.pictureformData)

            this.dialogVisible1 = ref(false); //关闭弹窗
        },
        
        // 组件数据返回到父组件
        subformEvent(data) {
            this.$emit("getpicturesetdata", data);
        },

    },
    watch: {
        pictureform(val) {
            this.pictureformData = val
        }
    },
    components: {
        mainimgesup
    },
    created() {
        // 获取相关配置配置-webview跳转链接-站内链接-下拉
        axios
            .post(
                "/admin/config/info",
                {
                    key_name: "mini_path",
                },
                {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                }
            )
            .then((response) => {
                if (response.data.result) {
                    var alldatas = Array();
                    var num = 0;
                    for (var key in response.data.result) {
                        alldatas[num] = {
                            value: response.data.result[key].path,
                            label: response.data.result[key].title,
                        };
                        num = num + 1;
                    }
                    this.inurloptions = alldatas
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        // 获取相关配置配置-小程序APPID-站外链接-下拉
        axios
            .post(
                "/admin/config/info",
                {
                    key_name: "outer_app",
                },
                {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                }
            )
            .then((response) => {
                if (response.data.result) {
                    var alldatas = Array();
                    var num = 0;
                    for (var key in response.data.result) {
                        alldatas[num] = {
                            value: response.data.result[key].appid,
                            label: response.data.result[key].title,
                        };
                        num = num + 1;
                    }
                    this.miniappidoptions = alldatas
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
};
</script>

<style scoped>
.small-content-title-title-one {
    border-bottom: 1px solid #e8eaec;
    padding-bottom: 10px;
}

.small-content-title-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    margin: 10px 0;
}

.small-content-title-title div {
    margin-right: 20px;
}

.small-content-title-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.content-box-line {
    padding: 10px;
    border: 1px solid #e8eaec;
    border-radius: 4px;
}
</style>