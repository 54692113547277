<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>

    <div style="background-color: #ffffff; margin-bottom: 10px; border-radius: 4px;min-height: 80px;width: 100%;">
      <div
        style="display: flex; flex-direction: row; flex-wrap: wrap;justify-content: space-between;align-items: center;padding: 20px;">
        <div>
          <span style="font-size: 30px;font-weight: 500;">当前账户基准：{{this.currentfund}}</span>
        </div>
        <div>
          <el-button type="primary" size="small" @click="addTanClick((dialogVisible=true))">修改基准</el-button>
        </div>
      </div>
    </div>

    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
          </div>
        </div>
        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'status'">
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.switchstatus === 4" id="switch">
                    <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭" :active-value="1"
                      :inactive-value="-1" active-color="#13ce66"
                      @click="this.mypowerlimits.yhgl_qy ? changeStatus(scope.row) : ''"
                      :disabled="!this.mypowerlimits.yhgl_qy" inactive-color="#cbcbcb">
                    </el-switch>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗-修改基准 -->
    <el-dialog v-model="dialogVisible" title="修改基准" :width="this.searchwidth" :before-close="handleCloseSenior">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
        <div class="dialog-form">
          <el-form-item label="输入基准值：" prop="after">
            <el-input v-model="ruleForm.after" placeholder="请输入整数" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input v-model="ruleForm.remark" :autosize="{ minRows: 2 }" type="textarea" maxlength="100"
              show-word-limit placeholder="请填写备注" clearable>
            </el-input>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="resetForm((this.dialogVisible = false))">取消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vuserset",
  inject: ["reload"],
  data() {
    // 数字input框-校验-(仅整数)【赚钱页配置||】
    var checkInputEventInt = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("必填项不能为空"));
      }
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("值中不能有空格"));
      }
      if (value.toString().length > 1 && value.toString().slice(0, 1) == 0) {
        return callback(new Error("首位数字不能为0"));
      }
      setTimeout(() => {
        // 判断字符串是否存在小数点
        let result = value.toString().indexOf(".");
        if (result != -1) {
          callback(new Error("请输入整数"));
        } else {
          // 判断字符串是否为文字
          if (isNaN(value)) {
            callback(new Error("请输入整数"));
          } else {
            // 判断字符串是否为数字
            if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
              callback(new Error("请输入整数"));
            } else {
              if (value < 0) {
                callback(new Error("值需大于0"));
              } else {
                callback();
              }
            }
          }
        }
      }, 500);
    };
    return {
      mainonebread: [
        {
          name: "权限",
        },
        {
          name: "权限管理",
        },
        {
          name: "用户管理",
          path: "/user",
        },
        {
          name: "设置",
          // path: "/userset",
        },
      ], // 当前页-【面包屑导航】

      mainthreeisshowbut: true, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 45, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "before",
          label: "原基数",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "after",
          label: "调整后基准数",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "adminid_name",
          label: "操作人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "操作时间",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "remark",
          label: "备注",
          width: "400",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

      // 基准数
      currentfund: '',

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      dialogVisible: false,
      ruleForm: {
        after: '',
        remark: ''
      },
      rules: {
        after: [
          {
            required: true,
            validator: checkInputEventInt,
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: false,
            message: '备注不能为空',
            trigger: "blur",
          },
        ],
      },
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 弹窗-提交表单
    submitForm(formName) {
      var getdatas = this.$refs[formName].model
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post('/admin/Admin/setUserFund',
            {
              id: this.$route.query.id,
              after: getdatas.after,
              remark: getdatas.remark,
            },
            {
              headers: {
                'Authentication': this.mytoken
              }
            })
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = false;
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    this.reload();
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error)
            })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Admin/getUserFund", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            id: this.$route.query.id,
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Admin/getUserFund", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            id: this.$route.query.id,
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      this.ruleForm = {
        after: '',
        remark: ''
      };
      console.log(taninfo);
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, data) {
      if (data) {
        this.$router
          .push({ path: routeraddr, query: { id: data.id } })
          .catch((error) => error);
      } else {
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //数据渲染
    myaxiosfunc(successdata) {
      this.currentfund = successdata.currentFund;
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //转换操作时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无操作时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无操作时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },


  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 判断是否路由传参
      let paramsdata = {
        id: this.$route.query.id,
        page: 1,
        page_size: this.PageSize,
      };
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Admin/getUserFund", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: paramsdata,
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
});
</script>

<style scoped>
.dialog-form {
  max-height: 450px;
  overflow: hidden;
  overflow-y: visible;
  margin: 20px 5% 0 0;
}
</style>