<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <el-button type="primary" plain @click="
              addTanClick(tancontent, (data = 1), (dialogVisible = true))
            " v-if="mypowerlimits.bmgl_tj">添加</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' || mytabletitle.prop === 'num'
              ">
                <!-- 成员数量-渲染 -->
                <div v-if="mytabletitle.prop === 'num'">
                  <div :class="this.mypowerlimits.yhgl == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.yhgl == true ? routeraddrClick('/user', scope.row) : ''">
                    <span  >{{ scope.row.num }}</span>
                  </div>
                </div>
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.switchstatus === 4" id="switch">
                    <el-switch v-model="scope.row.status" active-text="开启" inactive-text="关闭" :active-value="1"
                      :inactive-value="-1" active-color="#13ce66"
                      @click="this.mypowerlimits.bmgl_qy ? changeStatus(scope.row) : ''"
                      :disabled="!this.mypowerlimits.bmgl_qy" inactive-color="#cbcbcb">
                    </el-switch>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-权限-部门管理-->
                <div v-if="mainthreetableope === 38">
                  <el-button type="primary" size="mini" @click="
                    addTanClick(
                      tancontent,
                      (data = 2),
                      scope.row,
                      (dialogVisible = true)
                    )
                  " v-if="mypowerlimits.bmgl_bj">编辑</el-button>
                  <el-button v-if="
                    scope.row.dept_type == 0 &&
                    scope.row.num == 0 &&
                    mypowerlimits.bmgl_sc == true
                  " @click="deleteRow(scope.$index, mainthreetableData)" type="danger" size="mini"
                    icon="el-icon-delete"></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog v-model="dialogVisible" :title="tancontent.title" :width="this.searchwidth"
        :before-close="handleCloseSenior">
        <el-form ref="seniorform" :model="seniorform" :rules="rules" label-width="100px" class="demo-ruleForm">
          <div style="
              max-height: 450px;
              overflow: hidden;
              overflow-y: visible;
              padding: 0 3%;
              margin: 20px 0;
            ">
            <el-form-item label="部门名称：" prop="name">
              <el-input v-model="seniorform.name"></el-input>
            </el-form-item>
            <el-form-item label="负责人：" prop="leader">
              <el-select v-model="seniorform.leader" placeholder="请选择负责人" style="width: 100%">
                <el-option :label="getsales.truename" :value="getsales.id" v-for="(getsales, i) in this.leaderdata"
                  :key="i"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职能描述：" prop="remark">
              <el-input v-model="seniorform.remark" type="textarea"></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <div style="text-align: right; margin-right: 15px">
              <el-button @click="resetFormSenior('seniorform')">
                <i class="el-icon-refresh-left"></i> 重置
              </el-button>
              <el-button type="primary" @click="onSubmitSenior('seniorform')" class="main_two_search">确定
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vdepartment",
  inject: ["reload"],
  data() {
    return {
      mainonebread: [
        {
          name: "权限",
        },
        {
          name: "权限管理",
        },
        {
          name: "部门管理",
          path: "/department",
        },
      ], // 当前页-【面包屑导航】
      mainthreeisshowbut: true, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 38, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 110, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "name",
          label: "部门名称",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "leader_name",
          label: "负责人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "remark",
          label: "职能描述",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "num",
          label: "成员数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "添加时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "是否启用",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

      //table表单相关数据
      seniorform: {
        name: "",
        leader: "",
        remark: "",
      },
      //form表单-必填规则
      rules: {
        name: [
          {
            required: true,
            message: "部门名称为必填项",
            trigger: "blur",
          },
        ],
        leader: [
          {
            required: true,
            message: "负责人为必填项",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "职能描述为必填项",
            trigger: "blur",
          },
        ],
      },
      //弹窗-下拉框-负责人数据
      leaderdata: [],

      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "添加部门",
        content: {},
      }, //弹框

      dialogVisible: ref(false), //弹窗控制状态
      change: 1, //判断添加与编辑
      editId: "", //获取编辑id

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //点击关闭-弹窗
    handleCloseSenior(done) {
      this.seniorform.name = "";
      this.seniorform.leader = "";
      this.seniorform.remark = "";
      done();
    },
    // table表单-状态改变
    changeStatus(data) {
      axios
        .post(
          "/admin/Admin/deptSwitch",
          {
            id: data.id,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code === 0) {
            ElMessage({
              type: "success",
              message: response.data.message,
            });
          } else {
            // 切换状态失败-复原状态
            if (data.status == 1) {
              data.status = -1;
            } else {
              data.status = 1;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      ElMessageBox.confirm("确定删除吗？", "提示：", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "primary",
      })
        .then(() => {
          axios
            .post(
              "/admin/Admin/deptDelete",
              {
                id: rows[index].id,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    // this.reload(); //刷新
                    rows.splice(index, 1);
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Admin/deptList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Admin/deptList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo, val, data) {
      this.change = val;
      this.editId = data.id;
      if (val == 2) {
        this.tancontent.title = "编辑部门";
        // 详情
        axios
          .get("/admin/Admin/deptDetail", {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
            params: {
              id: data.id,
            },
          })
          .then((response) => {
            var routedatas = response.data.result;
            var seniorform = this.seniorform;
            seniorform.name = routedatas.name; //通过路由获取的部门名称
            seniorform.leader = routedatas.leader; //负责人
            seniorform.remark = routedatas.remark; //职能描述
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        if (routeraddr == "/user") {
          console.log(rowdata);
          this.$router
            .push({ path: routeraddr, query: { dept_id: rowdata.id } })
            .catch((error) => error);
        } else {
          // 携带参数跳转
          this.$router
            .push({ path: routeraddr, query: { id: rowdata.id } })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //弹出框-提交表单
    onSubmitSenior(formName) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.change == 1) {
            axios
              .post(
                "/admin/Admin/deptInsert",
                {
                  name: getdatas.name,
                  leader: getdatas.leader,
                  remark: getdatas.remark,
                },
                {
                  headers: {
                    Authentication: localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                if (response.data.code === 0) {
                  this.dialogVisible = false;
                  ElMessage({
                    type: "success",
                    message: "提交成功",
                    duration: 1000,
                    onClose: () => {
                      this.reload(); //刷新
                    },
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            axios
              .post(
                "/admin/Admin/deptEdit",
                {
                  id: this.editId,
                  name: getdatas.name,
                  leader: getdatas.leader,
                  remark: getdatas.remark,
                },
                {
                  headers: {
                    Authentication: localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                if (response.data.code === 0) {
                  this.dialogVisible = false;
                  ElMessage({
                    type: "success",
                    message: "提交成功",
                    duration: 1000,
                    onClose: () => {
                      this.reload(); //刷新
                    },
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-重置
    resetFormSenior() {
      this.seniorform.name = "";
      this.seniorform.leader = "";
      this.seniorform.remark = "";
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
        //转换添加时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无添加时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无添加时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },

    // 弹窗-获取下拉框-负责人数据
    leaderSaleList() {
      axios
        .get("/admin/Admin/userSelect", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {},
        })
        .then((response) => {
          console.log(response.data.result);
          this.leaderdata = response.data.result;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      // 权限控制-启用禁用-[当其为false的时候不显示该列]
      if (this.mypowerlimits.bmgl_qy == false) {
        this.mainthreetabletitle.splice(this.mainthreetabletitle.length - 1, 1);
      }

      // 加载-弹窗-下拉框数据-负责人数据
      this.leaderSaleList();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Admin/deptList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>