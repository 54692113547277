<template>
  <!--单个radio单选框-->
  <div v-if="myradioisshow">
    <div v-for="(radiodata,y) in myradio.radio" :key="y">
      <el-row>
        <el-col :span="24">
          <div class="radio_float" :style="{'width':radiodata.titlewidth}">
            <span style="color: #616e9d;font-size: 14px;">
                <span style="color: red" v-if="radiodata.ismust">*</span>
                {{ radiodata.title }}
            </span>
          </div>
          <div class="radio_float">
            <el-radio-group v-model="radiodata.value">
              <el-radio v-for="(myradioinfo,i) in radiodata.radio" :key="i" :label="myradioinfo.label"
                        style="margin-right: 60px;width: 60px" @change="changeRadiomoreEvent">{{ myradioinfo.content }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <!--单个radio单选框+input框（left）-->
  <div v-if="myRadioinPutisshow">
    <!--radio单选框-->
    <div style="float: left">
      <div v-for="(radiodata,y) in myRadioinPut.radio" :key="y" style="margin-top: 6px">
        <div class="radio_float" :style="{'width':radiodata.titlewidth}">
            <span style="color: #616e9d;font-size: 14px;">
                <span style="color: red" v-if="radiodata.ismust">*</span>
                {{ radiodata.title }}
            </span>
        </div>
        <div class="radio_float">
          <el-radio-group v-model="radiodata.value" style="margin-bottom: 0">
            <el-radio v-for="(myradioinfo,i) in radiodata.radio" :key="i" :label="myradioinfo.label"
                      style="margin-right: 60px;width: 60px"
                      @change="changeRadioinputEvent">{{ myradioinfo.content }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <!--input框-->
    <div v-if="getData.thisinputisshow">
      <maininput :myinputoneisshow="myRadioinPut.input.myinputoneisshow"
                 :myinputone="myRadioinPut.input.myinputone"></maininput>
    </div>
  </div>
  <!--单个radio单选框+多个input框||多个select框（down）-->
  <div v-if="myRadioMoreisshow">
    <el-row>
      <el-col :span="24">
        <!--radio单选框-->
        <div>
          <div v-for="(radiodata,y) in myRadioMore.radio" :key="y" style="margin-top: 6px">
            <div class="radio_float" :style="{'width':radiodata.titlewidth}">
            <span style="color: #616e9d;font-size: 14px;">
                <span style="color: red" v-if="radiodata.ismust">*</span>
                {{ radiodata.title }}
            </span>
            </div>
            <div class="radio_float">
              <el-radio-group v-model="radiodata.value" style="margin-bottom: 0">
                <el-radio v-for="(myradioinfo,i) in radiodata.radio" :key="i" :label="myradioinfo.label"
                          style="margin-right: 60px;width: 60px"
                          @change="changeRadiomoreEvent">{{ myradioinfo.content }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </el-col>
      <el-col>
        <!--内容-->
        <div v-if="getData.changeoneisshow">
          <maininput :myinputoneisshow="myRadioMore.changeone.myinputoneisshow"
                     :myinputone="myRadioMore.changeone.myinputone"></maininput>
          <mainselect :myselectleftisshow="myRadioMore.changeone.selectleftisshow"
                      :myselectleft="myRadioMore.changeone.selectleft"></mainselect>
        </div>
        <div v-if="getData.changetwoisshow">
          <maininput :myinputoneisshow="myRadioMore.changetwo.myinputoneisshow"
                     :myinputone="myRadioMore.changetwo.myinputone"></maininput>
          <mainselect :myselectleftisshow="myRadioMore.changetwo.selectleftisshow"
                      :myselectleft="myRadioMore.changetwo.selectleft"></mainselect>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import {defineComponent, reactive} from 'vue'
import maininput from '@/components/Maininput.vue'
import mainselect from '@/components/Mainselect.vue'

export default defineComponent({
  name: "Mainradio",
  data() {
    return {
      myradios: this.myradio
    }
  },
  props: [
    'myradioisshow',
    'myradio',
    'myradioinputisshow',
    'myradioinput',
    'myradiomoreisshow',
    'myradiomore',
  ],
  setup(props,context) {
    const myRadioisshow = reactive(props['myradioisshow'])
    const myRadio = reactive(props['myradio'])
    const myRadioinPutisshow = reactive(props['myradioinputisshow'])
    const myRadioinPut = reactive(props['myradioinput'])
    const myRadioMoreisshow = reactive(props['myradiomoreisshow'])
    const myRadioMore = reactive(props['myradiomore'])

    //定义数据数组等
    let getData = reactive({
      thisinputisshow: false,   //默认隐藏input组件
      changeoneisshow: true, //默认隐藏content组件
      changetwoisshow: false, //默认隐藏content组件
    })

    //通过点击radio按钮-显示input框
    const changeRadioinputEvent = (data) => {
      if (data === '1') {
        getData.thisinputisshow = false  //隐藏input组件
      } else {
        getData.thisinputisshow = true  //显示input组件
      }
    }
    //通过点击radio按钮-切换content内容
    const changeRadiomoreEvent = (data) => {
      console.log(data)
      context.emit('getradioinfo', data)
      if (data === '1') {
        getData.changeoneisshow = true
        getData.changetwoisshow = false
      } else {
        getData.changeoneisshow = false
        getData.changetwoisshow = true
      }
    }

    return {
      myRadioisshow,
      myRadio,
      myRadioinPutisshow,
      myRadioinPut,
      myRadioMoreisshow,
      myRadioMore,
      getData,
      changeRadioinputEvent,
      changeRadiomoreEvent
    }
  },
  methods: {},
  components: {
    maininput,
    mainselect
  }
})
</script>

<style scoped>
.el-row {
  margin-bottom: 0 !important;
}

.radio_float {
  float: left;
  align-content: center;
}

.radio_float :deep(.el-radio__input.is-checked) .el-radio__inner {
  border-color: #1FC59B;
  background: #1FC59B;
}

.radio_float :deep(.el-radio__input.is-checked + .el-radio__label) {
  color: #1FC59B;
}

.radio_float :deep(.el-radio) {
  color: #616e9d;
}

.radio_float :deep(.el-radio__inner) {
  border-color: #94a3d2;
}
</style>
