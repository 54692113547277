<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            :myselectcityleftisshow="maintwoforminfo.myselectcityleftisshow"
            :myselectcityleft="maintwoforminfo.myselectcityleft"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        >
            <template v-slot:searchModel>
                <div style="display: flex;flex-direction: row;justify-content: center;align-items: center; flex-wrap: nowrap; color: #000;font-size: 14px;margin-right: 10px;">
                    <div><span>时间：</span></div>
                    <!-- type: monthrange -->
                    <datePicker
                        :SelectTimesCustom="back_SelectTimesCustom"
                        :Date_Disabled="true"
                        :Button_Change="{ chat: '请选择', type: 'monthrange', format: 'YYYY-MM' }"
                        :Out_Time="{ time_size: 63072000, erro_message: '选中的时间差不能超过2年' }"
                        @change-data="changeDataSearchEvent"
                    />
                </div>
            </template>
        </maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center">
                        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: left; align-items: center">
                            <!--左侧名称-->
                            <!-- <div style="float: left; font-size: 14px; line-height: 20px; padding-top: 10px">
                                <div><span>新粉：第一次添加成功的用户，二次加入不算</span></div>
                                <div><span>删粉：用户删除了企微联系人，二次删除不算</span></div>
                            </div> -->
                        </div>
                        <!--右侧按钮-->
                        <div>
                            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                            <!--<el-button ></el-button>-->
                            <!--导出按钮-点击导出数据  v-if="isshowExport == true" -->
                            <el-button type="warning" plain @click="exportDatas()">导出 </el-button>
                        </div>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :min-width="mytabletitle.minwidth"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="main_titles.includes(mytabletitle.prop) || mytabletitle.prop === 'user_type'">
                                <!-- 类型 -->
                                <div v-if="mytabletitle.prop === 'user_type'">
                                    <span>{{ scope.row.user_type ? this.CSP_type.filter((n) => n.value == scope.row.user_type)[0]['label'] : '' }}</span>
                                </div>
                                <!-- 动态-时间 -->
                                <div v-if="main_titles.includes(mytabletitle.prop)">
                                    <!-- <span>{{ scope.row.sum_care }}</span> -->
                                    <div>
                                        <span>{{ scope.row[mytabletitle.prop].split('\r\n')[0] }}</span>
                                    </div>
                                    <div style="color: red;">
                                        <span>{{ scope.row[mytabletitle.prop].split('\r\n')[1] }}</span>
                                    </div>
                                    <div style="color: rgb(63, 63, 63);">
                                        <span>{{ scope.row[mytabletitle.prop].split('\r\n')[2] }}</span>
                                    </div>
                                    <div style="color: rgb(135, 60, 255);">
                                        <span>{{ scope.row[mytabletitle.prop].split('\r\n')[3] }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :fixed="'right'" label="合计" min-width="140" prop="sum_care" id="operation">
                            <template #default="scope">
                                <div>
                                    <!-- <span>{{ scope.row.sum_care }}</span> -->
                                    <div>
                                        <span>{{ scope.row['sum_care'].split('\r\n')[0] }}</span>
                                    </div>
                                    <div style="color: red;">
                                        <span>{{ scope.row['sum_care'].split('\r\n')[1] }}</span>
                                    </div>
                                    <div style="color: rgb(63, 63, 63);">
                                        <span>{{ scope.row['sum_care'].split('\r\n')[2] }}</span>
                                    </div>
                                    <div style="color: rgb(135, 60, 255);">
                                        <span>{{ scope.row['sum_care'].split('\r\n')[3] }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column :fixed="'right'" label="操作" :width="mainthreehandle" prop="myoperation" id="operation" v-if="this.mypowerlimits.qwtjsbtj">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <div>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/dvcfanscount', scope.row)">设备详情</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import axios from 'axios'
import { defineComponent } from 'vue'
import CPS from '@/assets/js/componentProperties' // 公共数据

export default defineComponent({
    name: 'vagentplusfans',
    data() {
        return {
            mainonebread: [
                {
                    name: '统计'
                },
                {
                    name: '企微统计'
                },
                {
                    name: '代理商统计'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '100px',
                    selectinterval: '10px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '类型：',
                            placeholder: '全部',
                            name: 'user_type',
                            value: '',
                            options: [
                                ...[
                                    {
                                        value: '0',
                                        label: '全部'
                                    }
                                ],
                                ...CPS.get('winuserinfo').type
                            ]
                        }
                    ]
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '60px', //标题宽度
                    inputwidth: '110px', //input宽度
                    inputinterval: '10px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '代理商：',
                            placeholder: '请输入',
                            name: 'agentid', //此name为键值
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/shopUser/getShopList',
                            inputval: { name: '' } //这里记录需要传的参数
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '负责人：',
                            placeholder: '请输入', //此name为键值
                            name: 'win_user_id',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Statistics/userList',
                            posttype: 'get',
                            inputval: { truename: '' } //这里记录需要传的参数
                        }
                    ]
                },
                myselectcityleftisshow: true, //筛选查询-selectcity选框
                myselectcityleft: {
                    selectwidth: '440px',
                    select: {
                        ismust: false,
                        title: '选择地区：',
                        name: 'area_code',
                        area_code: '',
                        valuesheng: '',
                        valueshi: '',
                        valuequ: '',
                        disshi: true,
                        disqu: true
                    }
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 36, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 100, // 数据列表中-操作-宽度
            mainthreetabletitle: [], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            main_titles: [], //动态表头title

            // 负责人类型
            CSP_type: CPS.get('winuserinfo').type,

            searchwidth: '1100px',
            dialogVisible: false,
            ClientInfo: { client: '', addtime: '', acount: '', yaolan: '', chengzhang: '', wending: '', list: { time: [], money: [] } },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem('userinfo')), //获取存储的用户信息
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            // 是否显示导出按钮
            isshowExport: false,

            back_SelectTimesCustom: '',
            SelectTimesCustom: ''
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (routeraddr === '/dvcfanscount') {
                this.$router
                    .push({
                        path: routeraddr,
                        query: {
                            id: rowdata.id,
                            name: rowdata.name
                        }
                    })
                    .catch((error) => error)
            } else {
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            // console.log("this is:" + val);
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            let postdata = {
                agentid: this.pagesdatas.agentid,
                user_type: this.pagesdatas.user_type,
                win_user_id: this.pagesdatas.win_user_id,
                area_code: this.pagesdatas.area_code,
                addtime: this.pagesdatas.addtime && this.pagesdatas.addtime.length > 0 ? this.pagesdatas.addtime.join(' ~ ') : '',
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(postdata)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            let postdata = {
                agentid: this.pagesdatas.agentid,
                user_type: this.pagesdatas.user_type,
                win_user_id: this.pagesdatas.win_user_id,
                area_code: this.pagesdatas.area_code,
                addtime: this.pagesdatas.addtime && this.pagesdatas.addtime.length > 0 ? this.pagesdatas.addtime.join(' ~ ') : '',
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(postdata)
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo)
            this.tancontent.content = taninfo.content
            this.tancontent.title = taninfo.title
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    '/admin/cashflow/companyWechatAgent',
                    {
                        agentid: this.pagesdatas.agentid,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        area_code: this.pagesdatas.area_code,
                        addtime: this.pagesdatas.addtime && this.pagesdatas.addtime.length > 0 ? this.pagesdatas.addtime.join(' ~ ') : '',
                        export: '1'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        //提交等相关event事件函数
        // Maintwo插槽时间范围数据
        changeDataSearchEvent(data) {
            this.back_SelectTimesCustom = [data[0] * 1000, data[1] * 1000]
            this.SelectTimesCustom = data
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用

            this.pagesdatas.addtime = this.SelectTimesCustom.length > 0 ? this.SelectTimesCustom : []

            let postdata = {
                agentid: data.agentid,
                user_type: data.user_type,
                win_user_id: data.win_user_id,
                area_code: data.area_code,
                addtime: this.SelectTimesCustom && this.SelectTimesCustom.length > 0 ? this.SelectTimesCustom.join(' ~ ') : '',
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(postdata)
        },
        // 获取数据Axios
        postAxiosEvent(postdata) {
            //加载loading
            this.loading = true
            axios
                .post('/admin/cashflow/companyWechatAgent', postdata, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    let datas = response.data.result

                    // 判断是否显示导出
                    this.isshowExport = datas.tableexport

                    // 获取动态表头
                    if (datas.tabletitle) {
                        this.main_titles = Object.keys(datas.tabletitle)

                        let title_key = Object.keys(datas.tabletitle).sort()
                        let title_active = title_key.map((n) => {
                            return {
                                fixedstatu: false,
                                prop: n,
                                label: datas.tabletitle[n],
                                minwidth: '140'
                            }
                        })
                        let title_guding = ['id:ID', 'name:一级代理商', 'user_type:类型', 'win_user_id:负责人', 'area_code:地区']
                        let title_guding_active = title_guding.map((n) => {
                            let [key, chart] = n.split(':')
                            return {
                                fixedstatu: key == 'id' ? 'left' : false,
                                prop: key,
                                label: chart,
                                minwidth: key == 'id' ? '80' : '150'
                            }
                        })
                        this.mainthreetabletitle = [...title_guding_active, ...title_active]
                    }

                    if (this.mainthreetabletitle.length > 0) {
                        // 获取数据
                        this.myaxiosfunc(datas)
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 1150) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '1100px'
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            let postdata = {
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(postdata)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

/* 设备台数等信息 */
.dvcinfo-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    color: #333333;
    font-size: 14px;
    margin-left: 40px;
}

.dvcinfo-box-div {
    margin-right: 20px;
}

.dvcinfo-box-div-color {
    color: red;
    margin: 0 5px;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.cells-bg {
    background-color: #e7e7e7;
    color: #e7e7e7;
}
</style>
