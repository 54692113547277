<template>
  <!--上方字-单个select||单个时间选择||单个时间段  ****已做修改*****-->
  <div v-if="mySelectisshow">
    <div
      v-for="(myformitem, i) in mySelect.select"
      :key="i"
      class="select_dan"
      :style="{
        width: myformitem.selectwidth
          ? myformitem.selectwidth
          : mySelect.selectwidth,
        'margin-right': myformitem.divinterval
          ? myformitem.divinterval
          : mySelect.divinterval,
      }"
    >
      <span style="color: #333333; font-size: 14px">
        <span style="color: red" v-if="myformitem.ismust">*</span>
        {{ myformitem.title }}
      </span>
      <br />
      <!--显示普通select框-->
      <div
        v-if="myformitem.selectisshow"
        :style="{ 'max-width': mySelect.selectmaxwidth }"
      >
        <el-select
          v-model="myformitem.value"
          :placeholder="myformitem.placeholder"
          style="width: 100% !important"
           @change="mySelectleftGaoChangeEvent(myformitem.value,myformitem.options)"
        >
          <el-option
            v-for="myselect in myformitem.options"
            :key="myselect.value"
            :label="myselect.label"
            :value="myselect.value"
          >
          </el-option>
        </el-select>
      </div>
      <!--显示selectmoretag基础多选-->
      <div
        v-if="myformitem.selectmoretagisshow"
        :style="{ 'max-width': mySelect.selectmaxwidth }"
      >
        <el-select
          v-model="myformitem.value"
          multiple
          :placeholder="myformitem.placeholder"
          style="width: 100% !important"
        >
          <el-option
            v-for="item in myformitem.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!--显示selecttime时间框-->
      <div
        v-if="myformitem.selecttimeisshow"
        :style="{ 'max-width': mySelect.selectmaxwidth }"
      >
        <el-date-picker
          v-model="myformitem.value"
          :placeholder="myformitem.placeholder"
          :type="myformitem.type ? myformitem.type : 'date'"
          style="width: 100% !important"
          value-format="YYYY-MM-DD HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <!--显示selecttimeduan时间段-->
      <div
        v-if="myformitem.selecttimeduanisshow"
        :style="{
          'max-width': myformitem.selectmaxwidth,
          'margin-right': myformitem.marginright,
        }"
      >
        <el-row>
          <el-col :span="24" :xs="24" :sm="12">
            <div style="max-width: 253px">
              <el-date-picker
                v-model="myformitem.value.valuestart"
                :placeholder="myformitem.placeholder.placeholderstart"
                :type="myformitem.type ? myformitem.type : 'date'"
                style="width: 100% !important"
                @change="myChangestart(i, myformitem.value.valuestart)"
                :clearable="false"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="24" :xs="3" :sm="1">
            <div style="line-height: 42px; height: 50px; text-align: left">
              <span style="font-size: 14px; color: #333333">{{
                myformitem.rangeseparator
              }}</span>
            </div>
          </el-col>
          <el-col :span="24" :xs="24" :sm="11">
            <div style="max-width: 253px">
              <el-date-picker
                v-model="myformitem.value.valueend"
                :placeholder="myformitem.placeholder.placeholderend"
                :type="myformitem.type ? myformitem.type : 'date'"
                style="width: 100% !important"
                @change="myChangeend(i, myformitem.value.valueend)"
                :disabled="mySelect.select[i].value.endstatus"
                :clearable="false"
              >
              </el-date-picker>
            </div>
          </el-col>
        </el-row>
      </div>
      <!--显示Cascader多级联选-选择器-->
      <div
        v-if="myformitem.cascaderisshow"
        :style="{ 'max-width': mySelect.selectmaxwidth }"
      >
        <el-cascader
          style="width: 100% !important"
          v-model="myformitem.value"
          :placeholder="myformitem.placeholder"
          :options="myformitem.isaddress ? areacode_options : myformitem.options"
          :props="myformitem.props ? myformitem.props : {}"
          :collapse-tags="myformitem.collapsetags ? myformitem.collapsetags : false"
          :collapse-tags-tooltip="myformitem.collapsetagstooltip ? myformitem.collapsetagstooltip : false"
          @change="handleChange"
        ></el-cascader>
      </div>
    </div>
  </div>
  <!--上方字-单个select||单个时间选择||单个时间段||基础多选(float:left)-->
  <div v-if="mySelectLeftonisshow">
    <div
      v-for="(myformitem, i) in mySelectLefton.select"
      :key="i"
      class="select_dan"
      :style="{ width: mySelectLefton.selectwidth }"
      style="float: left; max-width: 420px"
    >
      <span style="color: #616e9d; font-size: 14px">
        <span style="color: red" v-if="myformitem.ismust">*</span>
        {{ myformitem.title }}
      </span>
      <!--显示普通select框-->
      <div v-if="myformitem.selectisshow">
        <el-select
          v-model="myformitem.value"
          :placeholder="myformitem.placeholder"
          style="width: 100% !important"
        >
          <el-option
            v-for="myselect in myformitem.options"
            :key="myselect.value"
            :label="myselect.label"
            :value="myselect.value"
          >
          </el-option>
        </el-select>
      </div>
      <!--显示selectmoretag基础多选-->
      <div v-if="myformitem.selectmoretagisshow">
        <el-select
          v-model="myformitem.value"
          multiple
          :placeholder="myformitem.placeholder"
          style="width: 100% !important"
        >
          <el-option
            v-for="item in myformitem.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!--显示selecttime时间框-->
      <div v-if="myformitem.selecttimeisshow">
        <el-date-picker
          v-model="myformitem.value"
          :placeholder="myformitem.placeholder"
          :type="myformitem.type ? myformitem.type : 'date'"
          style="width: 100% !important; margin: 5px 0"
          value-format="YYYY-MM-DD HH:mm:ss"
        >
        </el-date-picker>
      </div>
      <!--显示selecttimeduan时间段-->
      <div v-if="myformitem.selecttimeduanisshow">
        <el-row>
          <el-col :span="10" :xs="24" :sm="11">
            <el-date-picker
              v-model="myformitem.value.valuestart"
              :placeholder="myformitem.placeholder.placeholderstart"
              :type="myformitem.type ? myformitem.type : 'date'"
              style="width: 100% !important"
              value-format="YYYY-MM-DD HH:mm:ss"
              @change="myChangestart(i, myformitem.value.valuestart)"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="4" :xs="3" :sm="2">
            <div style="line-height: 40px; height: 40px; text-align: center">
              {{ myformitem.rangeseparator }}
            </div>
          </el-col>
          <el-col :span="10" :xs="24" :sm="11">
            <el-date-picker
              v-model="myformitem.value.valueend"
              :placeholder="myformitem.placeholder.placeholderend"
              :type="myformitem.type ? myformitem.type : 'date'"
              style="width: 100% !important"
              value-format="YYYY-MM-DD HH:mm:ss"
              @change="myChangeend(i, myformitem.value.valueend)"
              :disabled="mySelectLefton.select[i].value.endstatus"
            >
            </el-date-picker>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
  <!--上方字-单个select+单个input-->
  <div v-if="mySelectinputisshow">
    <div
      v-for="(myinputselect, j) in mySelectinput"
      :key="j"
      class="select_dan"
      :style="{ width: mySelectinput.selectwidth }"
    >
      <span style="color: #616e9d; font-size: 14px">
        <span style="color: red" v-if="myinputselect.ismust">*</span>
        {{ myinputselect.title }}
      </span>
      <br />
      <div style="float: left; width: 250px; padding-right: 40px">
        <el-select
          v-model="myinputselect.value"
          :placeholder="myinputselect.placeholder"
          style="width: 250px !important; padding: 5px 0"
        >
          <el-option
            v-for="myselectinput in myinputselect.options"
            :key="myselectinput.value"
            :label="myselectinput.label"
            :value="myselectinput.value"
          >
          </el-option>
        </el-select>
      </div>
      <div style="float: right; width: 250px">
        <el-input
          v-model="myinputselect.input.content"
          :placeholder="myinputselect.input.placeholder"
        ></el-input>
      </div>
    </div>
  </div>
  <!--上方字-单个select+单个input(float:left)-->
  <div v-if="mySelectinputLeftisshow">
    <div
      v-for="(myinputselect, q) in mySelectinputLeft.select"
      :key="q"
      class="select_dan"
      :style="{ width: mySelectinputLeft.selectwidth }"
      style="float: left; max-width: 550px"
    >
      <span style="color: #616e9d; font-size: 14px">
        <span style="color: red" v-if="myinputselect.ismust">*</span>
        {{ myinputselect.title }}
      </span>
      <br />
      <div style="float: left; width: 250px; padding-right: 40px">
        <el-select
          v-model="myinputselect.value"
          :placeholder="myinputselect.placeholder"
          style="width: 250px !important; padding: 5px 0"
        >
          <el-option
            v-for="myselectinput in myinputselect.options"
            :key="myselectinput.value"
            :label="myselectinput.label"
            :value="myselectinput.value"
          >
          </el-option>
        </el-select>
      </div>
      <div style="float: left; width: 250px; line-height: 50px">
        <el-input
          v-model="myinputselect.input.content"
          :placeholder="myinputselect.input.placeholder"
        ></el-input>
      </div>
    </div>
  </div>
  <!--上方字-多个select（省市县多级联动） ****已做修改*****-->
  <div v-if="mySelectcityisshow">
    <div class="select_dan" :style="{ width: mySelectcity.selectwidth }">
      <span style="color: #333333; font-size: 14px">
        <span style="color: red" v-if="mySelectcity.select.ismust">*</span>
        选择地区：
      </span>
      <br />
      <el-row>
        <el-col :span="8" :xs="24" :sm="8">
          <div style="margin: 5px 5px 5px 0; padding-right: 5%">
            <el-select
              v-model="mySelectcity.select.valuesheng"
              placeholder="请选择省"
              style="width: 100% !important"
              @click="Selectsheng"
            >
              <el-option
                v-for="(myselectsheng, shengnum) in getdisabled.mysheng"
                :key="shengnum"
                :label="myselectsheng.name"
                :value="myselectsheng.code"
                @click="Selectshengafter(myselectsheng.code)"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="8" :xs="24" :sm="8">
          <div style="margin: 5px 5px 5px 0; padding-right: 5%">
            <el-select
              v-model="mySelectcity.select.valueshi"
              placeholder="请选择市"
              style="width: 100% !important"
              :disabled="mySelectcity.select.disshi"
            >
              <el-option
                v-for="(myselectshi, shinum) in getdisabled.myshi"
                :key="shinum"
                :label="myselectshi.name"
                :value="myselectshi.code"
                @click="Selectshiafter(myselectshi.code)"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="8" :xs="24" :sm="8">
          <div style="margin: 5px 5px 5px 0; padding-right: 5%">
            <el-select
              v-model="mySelectcity.select.valuequ"
              placeholder="请选择县/区"
              style="width: 100% !important"
              :disabled="mySelectcity.select.disqu"
            >
              <el-option
                v-for="(myselectqu, qunum) in getdisabled.myqu"
                :key="qunum"
                :label="myselectqu.name"
                :value="myselectqu.code"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
  <!--左侧字-单个select||单个时间选择     ****已做修改*****-->
  <div v-if="mySelectleftisshow">
    <div
      v-for="(myformitem, l) in mySelectleft.select"
      :key="l"
      class="select_dan"
      :style="{
        width: mySelectleft.width,
        'margin-right': mySelectleft.divinterval,
      }"
    >
      <div style="float: left">
        <span style="color: #333333; font-size: 14px">
          <span style="color: red" v-if="myformitem.ismust">*</span>
          {{ myformitem.title }}
        </span>
      </div>
      <!--显示普通select框-->
      <div
        v-if="myformitem.selectisshow"
        style="float: left"
        :style="{
          width: mySelectleft.selectwidth,
          'margin-right': mySelectleft.selectinterval,
        }"
      >
      <!-- ref="ceshiRef" @visible-change="ceshiChange" -->
        <el-select
          v-model="myformitem.value"
          :placeholder="myformitem.placeholder"
          @change="mySelectleftChangeEvent(myformitem.value,myformitem.options)"
          
        >
          <el-option
            v-for="myselect in myformitem.options"
            :key="myselect.value"
            :label="myselect.label"
            :value="myselect.value"
            
          >
          </el-option>
        </el-select>
      </div>
      <!--显示selecttime时间框-单框时间-->
      <div
        v-if="myformitem.selecttimeisshow"
        style="float: left; margin-right: 20px"
        :style="{ 'margin-right': mySelectleft.selectinterval }"
      >
        <el-date-picker
          v-model="myformitem.value"
          :placeholder="myformitem.placeholder"
          :type="myformitem.type ? myformitem.type : 'date'"
          :style="{ width: mySelectleft.selectwidth }"
        >
        </el-date-picker>
      </div>
      <!--显示selecttime时间框-时间段 -->
      <div
        v-if="myformitem.selecttimeduanisshow"
        style="float: left"
        :style="{
          'max-width': mySelectleft.select[l].selectmaxwidth,
          'margin-right': mySelectleft.select[l].marginright,
        }"
      >
        <el-row :gutter="5">
          <el-col :span="24" :xs="24" :sm="11">
            <el-date-picker
              v-model="myformitem.value.valuestart"
              :placeholder="myformitem.placeholder.placeholderstart"
              :type="myformitem.type ? myformitem.type : 'date'"
              style="max-width: 100% !important"
              @change="myChangestart1(l, myformitem.value.valuestart)"
              :clearable="false"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="24" :xs="3" :sm="1">
            <div style="line-height: 60px; height: 50px; text-align: center">
              <span style="font-size: 14px; color: #333333">{{
                myformitem.rangeseparator
              }}</span>
            </div>
          </el-col>
          <el-col :span="24" :xs="24" :sm="11">
            <el-date-picker
              v-model="myformitem.value.valueend"
              :placeholder="myformitem.placeholder.placeholderend"
              :type="myformitem.type ? myformitem.type : 'date'"
              style="max-width: 100% !important"
              @change="myChangeend1(l, myformitem.value.valueend)"
              :disabled="mySelectleft.select[l].value.endstatus"
              :clearable="false"
            >
            </el-date-picker>
          </el-col>
        </el-row>
      </div>
      <!--显示Cascader多级联选-选择器-->
      <div
        v-if="myformitem.cascaderisshow"
        style="float: left"
        :style="{
          width: mySelectleft.selectwidth,
          'margin-right': mySelectleft.selectinterval,
        }"
      >
        <el-cascader
          v-model="myformitem.value"
          :placeholder="myformitem.placeholder"
          :options="myformitem.isaddress ? areacode_options : myformitem.options"
          :props="myformitem.props ? myformitem.props : {}"
          :collapse-tags="myformitem.collapsetags ? myformitem.collapsetags : false"
          :collapse-tags-tooltip="myformitem.collapsetagstooltip ? myformitem.collapsetagstooltip : false"
          @change="handleChange"
        ></el-cascader>
      </div>
    </div>
  </div>
  <!--左侧字-多个select(非联动)-->
  <div v-if="mySelectmoreisshow">
    <div
      v-for="(myselectmore, g) in mySelectmore"
      :key="g"
      class="select_dan"
      style="margin-right: 40px !important"
      :style="{ width: mySelectmore.selectwidth }"
    >
      <div style="float: left; width: 160px">
        <span style="color: #616e9d; font-size: 14px; line-height: 50px">
          <span style="color: red" v-if="myselectmore.ismust">*</span>
          {{ myselectmore.title }}
        </span>
      </div>
      <!--显示普通select框-->
      <div
        style="float: left; margin-right: 25px"
        v-for="(myformitem, d) in myselectmore.select"
        :key="d"
      >
        <el-select
          v-model="myformitem.value"
          :placeholder="myformitem.placeholder"
          style="width: 250px !important; margin: 5px 0"
        >
          <el-option
            v-for="myselect in myformitem.options"
            :key="myselect.value"
            :label="myselect.label"
            :value="myselect.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
  <!--左侧字-多个select（省市县多级联动）[有||无title] ****已做修改*****-->
  <div v-if="mySelectcityleftisshow">
    <div
      class="select_dan"
      :style="{ width: mySelectcity.selectwidth }"
       
    >
      <div style="display: flex; flex-direction: row">
        <div v-if="mySelectcity.select.title" style="min-width: 75px">
          <span style="color: #333333; font-size: 14px">
            <span style="color: red" v-if="mySelectcity.select.ismust">*</span>
            {{ mySelectcity.select.title }}
          </span>
        </div>
        <div
          :style="{
            'margin-right': mySelectcity.marginright
              ? mySelectcity.marginright
              : '20px',
            'max-width': mySelectcity.maxwidth
              ? mySelectcity.maxwidth
              : '450px',
          }"
        >
          <el-row :gutter="10">
            <el-col :span="8" :xs="24" :sm="8">
              <div style="margin: 0 5px 5px 0; width: 100%">
                <el-select
                  v-model="mySelectcity.select.valuesheng"
                  placeholder="请选择省"
                  style="width: 100% !important"
                  @click="Selectsheng"
                >
                  <el-option
                    v-for="(myselectsheng, shengnum) in getdisabled.mysheng"
                    :key="shengnum"
                    :label="myselectsheng.name"
                    :value="myselectsheng.code"
                    @click="Selectshengafter(myselectsheng.code)"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8" :xs="24" :sm="8">
              <div style="margin: 0 5px 5px 0; width: 100%">
                <el-select
                  v-model="mySelectcity.select.valueshi"
                  placeholder="请选择市"
                  style="width: 100% !important"
                  :disabled="mySelectcity.select.disshi"
                >
                  <el-option
                    v-for="(myselectshi, shinum) in getdisabled.myshi"
                    :key="shinum"
                    :label="myselectshi.name"
                    :value="myselectshi.code"
                    @click="Selectshiafter(myselectshi.code)"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8" :xs="24" :sm="8">
              <div style="margin: 0 5px 5px 0; width: 100%">
                <el-select
                  v-model="mySelectcity.select.valuequ"
                  placeholder="请选择县/区"
                  style="width: 100% !important"
                  :disabled="mySelectcity.select.disqu"
                >
                  <el-option
                    v-for="(myselectqu, qunum) in getdisabled.myqu"
                    :key="qunum"
                    :label="myselectqu.name"
                    :value="myselectqu.code"
                    @click="Selectquafter(myselectqu.code)"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import areacode from '@/assets/json/areacode.json'

export default defineComponent({
  name: "Mainselect",
  data() {
    return {
      // 地址options 
      areacode_options: areacode,
      //测试数据
      selectisshow: false,
      selectinputisshow: false,
      selectcityisshow: false,
      selectleftisshow: false,
      selectmoreisshow: false,
      select: [
        {
          selectisshow: true,
          ismust: true,
          title: "销售人员/渠道经理：",
          placeholder: "请选择",
          options: [
            {
              value: "销售人员value",
              label: "销售",
            },
            {
              value: "渠道经理value",
              label: "渠道",
            },
          ],
          value: "",
        },
        {
          selectisshow: true,
          ismust: true,
          title: "售后人员：",
          placeholder: "请选择",
          options: [
            {
              value: "售后人员value01",
              label: "售后人员01",
            },
            {
              value: "售后人员value02",
              label: "售后人员02",
            },
            {
              value: "售后人员value03",
              label: "售后人员03",
            },
            {
              value: "售后人员value04",
              label: "售后人员04",
            },
          ],
          value: "",
        },
        {
          selecttimeisshow: true,
          ismust: true,
          title: "筛选时间：",
          placeholder: "请选择时间",
          value: "",
        },
        {
          selecttimeduanisshow: true,
          ismust: true,
          title: "筛选时间段：",
          placeholderstart: "请选择开始时间",
          placeholderend: "请选择结束时间",
          defaulttime: [
            new Date(2021, 1, 1, 12, 0, 0),
            new Date(2021, 10, 1, 8, 0, 0),
          ], //默认时间范围
          value: "",
        },
      ],
      selectinput: [
        {
          ismust: true,
          title: "销售人员/渠道经理：",
          placeholder: "请选择",
          options: [
            {
              value: "销售人员value",
              label: "销售",
            },
            {
              value: "渠道经理value",
              label: "渠道",
            },
          ],
          value: "",
          input: {
            ismust: true,
            placeholder: "占公司分成的佣金比例",
            name: "commissionratio",
            content: "",
          },
        },
        {
          ismust: true,
          title: "售后人员：",
          placeholder: "请选择",
          options: [
            {
              value: "售后人员value01",
              label: "售后人员01",
            },
            {
              value: "售后人员value02",
              label: "售后人员02",
            },
            {
              value: "售后人员value03",
              label: "售后人员03",
            },
            {
              value: "售后人员value04",
              label: "售后人员04",
            },
          ],
          value: "",
          input: {
            ismust: true,
            placeholder: "占公司分成的佣金比例",
            name: "commissionratio",
            content: "",
          },
        },
      ],
      selectcity: [
        {
          ismust: true,
          title: "选择地区",
          placeholder: "请选择省",
          options: [
            {
              value: "省行政编码01",
              label: "河南省",
              placeholder: "请选择市",
              child: [
                {
                  value: "市行政编码01",
                  label: "郑州市",
                  placeholder: "请选择县/区",
                  child: [
                    {
                      value: "县/区行政编码01",
                      label: "中牟县",
                    },
                  ],
                },
                {
                  value: "市行政编码02",
                  label: "南阳市",
                  placeholder: "请选择县/区",
                  child: [
                    {
                      value: "县/区行政编码01",
                      label: "桐柏县",
                    },
                  ],
                },
              ],
            },
            {
              value: "省行政编码02",
              label: "四川省",
              placeholder: "请选择市",
              child: [
                {
                  value: "市行政编码01",
                  label: "资阳市",
                  placeholder: "请选择县/区",
                  child: [
                    {
                      value: "县/区行政编码01",
                      label: "雁江区",
                    },
                  ],
                },
              ],
            },
          ],
          shengvalue: "",
          shivalue: "",
          quvalue: "",
          shengnum: 0,
          shinum: 0,
          qunum: 0,
        },
      ],
      selectleft: [
        {
          selectisshow: true,
          ismust: true,
          title: "销售人员/渠道经理：",
          placeholder: "请选择",
          options: [
            {
              value: "销售人员value",
              label: "销售",
            },
            {
              value: "渠道经理value",
              label: "渠道",
            },
          ],
          value: "",
        },
        {
          selectisshow: true,
          ismust: true,
          title: "售后人员：",
          placeholder: "请选择",
          options: [
            {
              value: "售后人员value01",
              label: "售后人员01",
            },
            {
              value: "售后人员value02",
              label: "售后人员02",
            },
            {
              value: "售后人员value03",
              label: "售后人员03",
            },
            {
              value: "售后人员value04",
              label: "售后人员04",
            },
          ],
          value: "",
        },
        {
          selecttimeisshow: true,
          ismust: true,
          title: "筛选时间：",
          placeholder: "请选择时间",
          value: "",
        },
      ],
      selectmore: [
        {
          ismust: true,
          title: "A4纸打印设置：",
          select: [
            {
              placeholder: "请选择",
              options: [
                {
                  value: "销售人员value",
                  label: "销售",
                },
                {
                  value: "渠道经理value",
                  label: "渠道",
                },
              ],
              value: "",
            },
            {
              placeholder: "请选择",
              options: [
                {
                  value: "销售人员value",
                  label: "销售",
                },
                {
                  value: "渠道经理value",
                  label: "渠道",
                },
              ],
              value: "",
            },
            {
              placeholder: "请选择",
              options: [
                {
                  value: "销售人员value",
                  label: "销售",
                },
                {
                  value: "渠道经理value",
                  label: "渠道",
                },
              ],
              value: "",
            },
            {
              placeholder: "请选择",
              options: [
                {
                  value: "销售人员value",
                  label: "销售",
                },
                {
                  value: "渠道经理value",
                  label: "渠道",
                },
              ],
              value: "",
            },
          ],
        },
      ],
    };
  },
  props: [
    "myselectisshow",
    "myselect",
    "myselectleftonisshow",
    "myselectlefton",
    "myselectinputisshow",
    "myselectinput",
    "myselectinputleftisshow",
    "myselectinputleft",
    "myselectcityisshow",
    "myselectcity",
    "myselectleftisshow",
    "myselectleft",
    "myselectmoreisshow",
    "myselectmore",
    "myselectcityleftisshow",
  ],
  setup(props, context) {
    const mySelectisshow = reactive(props["myselectisshow"]);
    const mySelect = reactive(props["myselect"]);
    const mySelectLeftonisshow = reactive(props["myselectleftonisshow"]);
    const mySelectLefton = reactive(props["myselectlefton"]);
    const mySelectinputisshow = reactive(props["myselectinputisshow"]);
    const mySelectinput = reactive(props["myselectinput"]);
    const mySelectinputLeftisshow = reactive(props["myselectinputleftisshow"]);
    const mySelectinputLeft = reactive(props["myselectinputleft"]);
    const mySelectcityisshow = reactive(props["myselectcityisshow"]);
    const mySelectcity = reactive(props["myselectcity"]);
    const mySelectleftisshow = reactive(props["myselectleftisshow"]);
    const mySelectleft = reactive(props["myselectleft"]);
    const mySelectmoreisshow = reactive(props["myselectmoreisshow"]);
    const mySelectmore = reactive(props["myselectmore"]);
    const mySelectcityleftisshow = reactive(props["myselectcityleftisshow"]);

    //定义数据数组等
    let getdisabled = reactive({
      mysheng: "",
      myshi: "",
      myqu: "",
    });
    //setup中的方法-省市区联动选择
    const Selectsheng = () => {
      axios
        .get("/admin/getArea", {
          headers: {
            Authentication: "MTYzNzM5NDIwNpiqfGHGaoaqloeDzYGjd6qzemFz",
          },
          params: {},
        })
        .then((response) => {
          getdisabled.mysheng = response.data.result.list;
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    };
    //选择省之后-通过省的code筛选出市
    const Selectshengafter = (shengcode) => {
      axios
        .get("/admin/getArea", {
          headers: {
            Authentication: "MTYzNzM5NDIwNpiqfGHGaoaqloeDzYGjd6qzemFz",
          },
          params: {
            pcode: shengcode,
          },
        })
        .then((response) => {
          if (response.data.result.list.length === 0) {
            mySelectcity.select.valueshi = "暂无相关市";
          } else {
            var quanbu = [
              {
                code: "0",
                name: "全部",
                level: response.data.result.list[1].level,
              },
            ];
            getdisabled.myshi = quanbu.concat(response.data.result.list); //数据拼接
            mySelectcity.select.valueshi = "";
            mySelectcity.select.valuequ = "";

            //控制-控件状态
            mySelectcity.select.disshi = false;
            mySelectcity.select.disqu = true;
          }
          // console.log(getdisabled.myshi);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
      //向父组件传省code
      context.emit("getcitycode", shengcode);
    };
    //选择市之后-通过市code-数据库筛选出区
    const Selectshiafter = (shicode) => {
      if (shicode === "0") {
        mySelectcity.select.valuequ = "";
        //控制-控件状态
        mySelectcity.select.disqu = true;
      } else {
        axios
          .get("/admin/getArea", {
            headers: {
              Authentication: "MTYzNzM5NDIwNpiqfGHGaoaqloeDzYGjd6qzemFz",
            },
            params: {
              pcode: shicode,
            },
          })
          .then((response) => {
            if (response.data.result.list.length === 0) {
              mySelectcity.select.valuequ = "暂无相关县/区";
            } else {
              var quanbu = [
                {
                  code: "0",
                  name: "全部",
                  level: response.data.result.list[1].level,
                },
              ];
              getdisabled.myqu = quanbu.concat(response.data.result.list); //数据拼接
              mySelectcity.select.valuequ = "";

              //控制-控件状态
              mySelectcity.select.disqu = false;
              console.log(JSON.stringify(quanbu));
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error);
          });
      }

      //向父组件传市code
      context.emit("getcitycode", shicode);
    };
    //选择区之后-将区的code返回
    const Selectquafter = (qucode) => {
      //向父组件传区code
      context.emit("getcitycode", qucode);
    };

    //setup中的方法-两个时间选择框的判断
    const myChangestart = (ide, val) => {
      if (val === null) {
        if (mySelect) {
          mySelect.select[ide].value.endstatus = true;
          mySelect.select[ide].value.valueend = ref("");
        }
        if (mySelectLefton) {
          mySelectLefton.select[ide].value.endstatus = true;
          mySelectLefton.select[ide].value.valueend = ref("");
        }
      } else {
        if (mySelect) {
          mySelect.select[ide].value.endstatus = false;
        }
        if (mySelectLefton) {
          mySelectLefton.select[ide].value.endstatus = false;
        }
      }
    };
    const myChangeend = (ide, val) => {
      console.log(ide);
      if (
        mySelect.select[ide].selecttimeduanisshow === true &&
        mySelect.select[ide].value.valuestart !== ref("")
      ) {
        if (
          mySelect.select[ide].value.valuestart >=
          mySelect.select[ide].value.valueend
        ) {
          ElMessage({
            message: "错误：结束时间需大于开始时间！",
            type: "warning",
            offset: 70,
          });
          mySelect.select[ide].value.valueend = ref("");
        } else {
          mySelect.select[ide].value.valueend = val;
        }
      }
    };

    //setup中的方法-两个时间选择框的判断
    const myChangestart1 = (ide, val) => {
      if (val === null) {
        if (mySelectleft) {
          mySelectleft.select[ide].value.endstatus = true;
          mySelectleft.select[ide].value.valueend = ref("");
          context.emit("gettimesrange", mySelectleft.select[ide].value);
        }
      } else {
        if (mySelectleft) {
          mySelectleft.select[ide].value.endstatus = false;
          context.emit("gettimesrange", mySelectleft.select[ide].value);
        }
      }
    };
    const myChangeend1 = (ide, val) => {
      if (
        mySelectleft.select[ide].selecttimeduanisshow === true &&
        mySelectleft.select[ide].value.valuestart !== ref("")
      ) {
        if (
          mySelectleft.select[ide].value.valuestart >=
          mySelectleft.select[ide].value.valueend
        ) {
          ElMessage({
            message: "错误：结束时间需大于开始时间！",
            type: "warning",
            offset: 70,
          });
          mySelectleft.select[ide].value.valueend = ref("");
          context.emit("gettimesrange", mySelectleft.select[ide].value);
        } else {
          mySelectleft.select[ide].value.valueend = val;
          context.emit("gettimesrange", mySelectleft.select[ide].value);
        }
      }
    };

    //清空cityCode值-通过父组件ref控制
    const resetCitycode = () => {
      mySelectcity.select.valuesheng = "";
      mySelectcity.select.valueshi = "";
      mySelectcity.select.valuequ = "";
      mySelectcity.select.disshi = true;
      mySelectcity.select.disqu = true;
    };

    return {
      getdisabled,
      mySelectisshow,
      mySelect,
      mySelectLeftonisshow,
      mySelectLefton,
      mySelectinputisshow,
      mySelectinput,
      mySelectinputLeftisshow,
      mySelectinputLeft,
      mySelectcityisshow,
      mySelectcity,
      mySelectleftisshow,
      mySelectleft,
      mySelectmoreisshow,
      mySelectmore,
      mySelectcityleftisshow,
      Selectsheng,
      Selectshengafter,
      Selectshiafter,
      Selectquafter,
      myChangestart,
      myChangeend,
      myChangestart1,
      myChangeend1,
      resetCitycode,
    };
  },
  mounted() {},
  methods: {
    // ceshiChange(e){
    //   console.log('window.event.keyCode :>> ', window.event.keyCode);
    //   if(window.event.keyCode == 13 && !e){
    //     // 触发搜索
    //     console.log('123 :>> ', 123);
    //   }
    // },

    // 将【左侧字】所选择的value值与键与label值传向父组件
    mySelectleftChangeEvent(data,datalist){
      for(let i = 0;i<datalist.length;i++){
        if(data == datalist[i].value){
          let data = {
            key:i,
            value:datalist[i].value,
            label:datalist[i].label
          }
          this.$emit('selectData',data)
          return
        }
      }
    },
    //将【上方字】所选择的value值与键与label值传向父组件
    mySelectleftGaoChangeEvent(data,datalist){
      for(let i = 0;i<datalist.length;i++){
        if(data == datalist[i].value){
          let data = {
            key:i,
            value:datalist[i].value,
            label:datalist[i].label
          }
          this.$emit('selectDataGao',data)
          return
        }
      }
    }
  },
});
</script>

<style scoped>
/*.el-row {*/
/*  margin-bottom: 0 !important;*/
/*}*/

.select_dan {
  /*line-height: 40px;*/
  float: left;
  /*margin-right: 80px;*/
  /*margin-bottom: 0;*/
}

/*!*穿透：改变边框样式*!*/
/*.select_dan :deep(.el-input__inner) {*/
/*  border-radius: 8px;*/
/*  background-color: #FAFCFE;*/
/*  border: 1px solid #94a3d2;*/
/*  color: #616e9d;*/
/*  --el-input-placeholder-color: #94a3d2;*/
/*}*/
</style>