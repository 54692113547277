<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop === 'status' ||
                                        mytabletitle.prop === 'remark' ||
                                        mytabletitle.prop === 'addtime' ||
                                        mytabletitle.prop === 'timePayment' ||
                                        mytabletitle.prop === 'can_money' ||
                                        mytabletitle.prop === 'pay_money'
                                "
                            >
                                <!-- 状态-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <span>{{ formatStatus(scope.row.status) }}</span>
                                </div>
                                <!-- 日期 -->
                                <div v-if="mytabletitle.prop === 'addtime'">
                                    <span>{{ formatAddtime(scope.row.addtime) }}</span>
                                </div>
                                <!-- 打款时间 -->
                                <div v-if="mytabletitle.prop === 'timePayment'">
                                    <span>{{ formatTimePayment(scope.row.addtime) }}</span>
                                </div>
                                <!-- 每期需打款金额 -->
                                <div v-if="mytabletitle.prop === 'can_money'">
                                    <span>{{ formatPrice(scope.row.can_money) }}</span>
                                </div>
                                <!-- 实际打款金额 -->
                                <div v-if="mytabletitle.prop === 'pay_money'">
                                    <span>{{ formatPrice(scope.row.pay_money) }}</span>
                                </div>
                                <!-- 处理备注-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'remark'">
                                    <div>
                                        <el-popover placement="top" trigger="hover" title="处理备注">
                                            <template #reference>
                                                <div class="box-table-addr">
                                                    <span :ref="'btnShowNum' + scope.$index"
                                                        ><span>{{ scope.row.remark }}</span></span
                                                    >
                                                </div>
                                            </template>
                                            <template #default>
                                                <div style="margin-top: 10px">
                                                    <span>{{ scope.row.remark }}</span>
                                                </div>
                                            </template>
                                        </el-popover>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" prop="myoperation" id="operation" :width="mainthreehandle">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <div>
                                    <el-button v-if="scope.row.status == 2" type="primary" size="mini" @click="addTanClick(scope.row, (dialogVisible = true))">处理</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            :layout="Layout"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!--弹出框-处理-->
        <el-dialog v-model="dialogVisible" title="处理" :width="this.searchwidth" :before-close="handleCloseSenior">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                <div class="tan-box">
                    <el-form-item label="财务审核：" prop="status">
                        <el-radio-group v-model="ruleForm.status">
                            <el-radio :label="1">审核通过</el-radio>
                            <el-radio :label="3">审核不通过</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="备注：">
                        <el-input v-model="ruleForm.remark" :autosize="{ minRows: 2 }" type="textarea" placeholder="请填写备注" maxlength="100"> </el-input>
                    </el-form-item>
                    <el-form-item style="text-align: right">
                        <el-button @click="resetForm()">取消</el-button>
                        <el-button type="primary" @click="submitForm('ruleForm')" :disabled="this.mydisabled">确认</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vcorporatetransferbankcardinfo',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '财务'
                },
                {
                    name: '提现管理'
                },
                {
                    name: '银行打款'
                },
                {
                    name: '详情'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: []
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '日期：',
                            placeholder: {
                                placeholderstart: '请选择开始时间',
                                placeholderend: '请选择结束时间'
                            },
                            name: 'addtime',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            selectmaxwidth: '100%', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        }
                    ]
                },
                selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
                selecttop: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '', //显示值，初始状况，显示值与默认值需相同
                            options: [
                                {
                                    value: '',
                                    label: '全部'
                                },
                                {
                                    value: 1,
                                    label: '转账成功'
                                },
                                {
                                    value: 2,
                                    label: '转账异常'
                                },
                                {
                                    value: 3,
                                    label: '已拒绝'
                                }
                            ]
                        }
                    ]
                }
            }, // 筛选-【筛选查询】-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 33, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 150, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'id',
                    label: '序号',
                    width: '80'
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '日期',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'can_money',
                    label: '每期需打款金额',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'pay_money',
                    label: '实际打款金额',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'timePayment',
                    label: '打款时间',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'truename',
                    label: '处理人',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'remark',
                    label: '备注',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1000, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            Layout: ' sizes, prev, pager, next, jumper',

            //分页
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值

            //弹窗相关数据
            dialogVisible: ref(false), //弹窗显示&隐藏
            ruleForm: {
                id: '', //当前	提现记录id
                status: 1, //处理状态 1通过 3不通过
                remark: ''
            }, //弹窗中-form表单
            rules: {
                status: [
                    {
                        required: true,
                        message: '财务审核为必填项',
                        trigger: 'blur'
                    }
                ]
            }, //弹窗中-form表单-必填规则

            // 下载发票
            dialogVisible1: ref(false),

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: {}, //存储最近一次的筛选条件，供分页使用

            mydisabled: false
        }
    },
    setup() {},
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pagesdatas.page_size = val
            this.pagesdatas.totalCount = this.totalCount
            this.PageSize = val
            this.currentPage = 1

            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/fund/bankPaymentInfo',
                    {
                        status: this.pagesdatas.status,
                        addtime: this.pagesdatas.addtime,
                        bank_payment_id: this.$route.query.id,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.pagesdatas.page = val
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/fund/bankPaymentInfo',
                    {
                        status: this.pagesdatas.status,
                        addtime: this.pagesdatas.addtime,
                        bank_payment_id: this.$route.query.id,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    // this.myaxiosfunc(response.data.result);
                    let getdata = response.data.result.data

                    var simpledata = this.$utils.simplePagingJudge(this, getdata, val)
                    if (simpledata.flag == 1 || simpledata.flag == 3) {
                        this.totalCount = simpledata.totalCount
                        this.lastpage = simpledata.lastpage
                        this.myaxiosfunc(response.data.result)
                    }
                    if (simpledata.flag == 2 || simpledata.flag == 4) {
                        this.myaxiosfunc(response.data.result)
                    }
                    this.pagesdatas.totalCount = this.totalCount
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row'
            }
            return ''
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            this.ruleForm.id = taninfo.id
            this.ruleForm.status = 1
            this.ruleForm.remark = ''
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata, flag) {
            if (rowdata) {
                // 跳转-代理商列表
                if (routeraddr == '/agentlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                id: rowdata.relation_id,
                                thirdparty: rowdata.thirdparty
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-用户列表
                if (routeraddr == '/userlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                userid: flag == 1 ? rowdata.relation_id : rowdata.user_account,
                                thirdparty: rowdata.thirdparty
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-合伙人列表
                if (routeraddr == '/partnerlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                userid: rowdata.user_account
                            }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas(tableData) {
            console.log(tableData)
        },
        //弹出框-提交表单
        submitForm(formName) {
            // 前端防抖
            this.mydisabled = true

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model
                    axios
                        .post(
                            '/admin/fund/bankPaymentAudit',
                            {
                                id: getdatas.id,
                                status: getdatas.status,
                                remark: getdatas.remark
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.reload() //刷新
                                        this.mydisabled = false
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //弹出框-取消表单
        resetForm() {
            this.dialogVisible = ref(false) //关闭弹窗
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //判断-申请时间段状态
            var getaddtime = null
            if (data.addtime.start == '') {
                getaddtime = ''
            } else {
                if (data.addtime.end == '') {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.start + 86400 - 1)
                } else {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.end + 86400 - 1)
                }
            }
            this.pagesdatas.addtime = getaddtime
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/fund/bankPaymentInfo',
                    {
                        status: data.status,
                        addtime: getaddtime,
                        bank_payment_id: this.$route.query.id,
                        page_size: this.PageSize,
                        page: 1
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            // this.totalCount = 1000;
            this.currentPage = successdata.current_page //当前页面页数
            this.lastpage = this.totalCount / this.PageSize
            this.loading = false

            // 数据小于当前页数时判断
            if (newdata.length < this.PageSize) {
                this.totalCount = this.currentPage * this.PageSize
            }
        },

        formatStatus(val) {
            const arr = ['', '转账成功', '转账异常', '审核不通过', '处理中']
            return arr[val]
        },

        formatAddtime(val) {
            return this.$utils.formatDate(val).slice(0, 10)
        },

        formatTimePayment(val) {
            return this.$utils.formatDate(val)
        },

        formatPrice(val) {
            return (+val / 100).toFixed(2)
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            // 定义
            this.pagesdatas = {
                page_size: this.PageSize,
                page: 1
            }
            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query
            // 打款记录id
            if (routerinfo.id) {
                this.pagesdatas.bank_payment_id = routerinfo.id
            }

            //通过Ajax向后台获取数据
            axios
                .post('/admin/fund/bankPaymentInfo', this.pagesdatas, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-table-addr {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.mytabletext,
.box-table-addr:hover {
    color: var(--el-color-primary);
    cursor: pointer;
}

/* 下载发票 */
.downfapiao-box {
    background-color: rgba(236, 236, 236, 0.473);
    border-radius: 4px;
}

.downfapiao-box:hover {
    background-color: aliceblue;
    cursor: pointer;
}

.downfapiao-box:hover .downfapiao-box-flex-span {
    color: var(--el-color-primary);
}

.downfapiao-box-flex-image:hover {
    border: 1px solid var(--el-color-primary);
}

.downfapiao-box-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 10px 20px;
}

.downfapiao-box-flex-left {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.downfapiao-box-flex-image {
    width: 100px;
    height: 60px;
    vertical-align: middle;
    border: 1px solid #3333331c;
    border-radius: 2px;
    margin-right: 10px;
}

.downfapiao-box-flex-span {
    color: #333333;
}

.tan-box {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
