<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        >
            <template v-slot:searchModel>
                <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; color: #000; font-size: 14px; margin-right: 20px">
                    <div><span>时间：</span></div>
                    <!-- type: monthrange -->
                    <datePicker
                        :SelectTimesCustom="back_SelectTimesCustom"
                        :Date_Disabled="true"
                        :Button_Change="{ chat: '请选择', type: 'monthrange', format: 'YYYY-MM' }"
                        :Out_Time="{ time_size: 63072000, erro_message: '选中的时间差不能超过2年' }"
                        @change-data="changeDataSearchEvent"
                    />
                </div>
            </template>
        </maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center">
                        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: left; align-items: center">
                            <!--左侧名称-->
                            <div style="font-size: 14px">数据列表</div>
                        </div>
                        <!--右侧按钮-->
                        <div>
                            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                            <!--<el-button ></el-button>-->
                            <!--导出按钮-点击导出数据-->
                            <el-button type="warning" plain @click="exportDatas()" v-if="isshowExport == true">导出 </el-button>
                        </div>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :min-width="mytabletitle.minwidth"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'client' || mytabletitle.prop === 'putintime' || main_titles.includes(mytabletitle.prop)">
                                <div v-if="mytabletitle.prop === 'client'">
                                    <span>{{ scope.row.client }}</span>
                                </div>
                                <div v-if="mytabletitle.prop === 'putintime'">
                                    <span>{{ scope.row.putintime ? this.$utils.formatDate(scope.row.putintime) : '' }}</span>
                                </div>
                                <div
                                    v-if="main_titles.includes(mytabletitle.prop)"
                                    :class="scope.row[mytabletitle.prop] === false && scope.row[mytabletitle.prop] !== 0 ? 'cells-bg' : ''"
                                >
                                    <span>{{ scope.row[mytabletitle.prop] === false && scope.row[mytabletitle.prop] !== 0 ? '###' : scope.row[mytabletitle.prop] }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vyeardvccountfd',
    data() {
        return {
            mainonebread: [
                {
                    name: '统计'
                },
                {
                    name: '打印流水统计'
                },
                {
                    name: '设备收益统计'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '220px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号：',
                            placeholder: '请输入设备编号', //此name为键值
                            name: 'client',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Equipment/searchClient',
                            posttype: 'get',
                            inputval: { client: '' } //这里记录需要传的参数
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '代理商名：',
                            placeholder: '请输入代理商名称',
                            name: 'shop_id', //此name为键值
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/shopUser/getShopList',
                            inputval: { name: '' } //这里记录需要传的参数
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 36, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 100, // 数据列表中-操作-宽度
            mainthreetabletitle: [], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            main_titles: [], //动态表头title

            searchwidth: '1100px',
            dialogVisible: false,
            ClientInfo: { client: '', addtime: '', acount: '', yaolan: '', chengzhang: '', wending: '', list: { time: [], money: [] } },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem('userinfo')), //获取存储的用户信息
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            // 是否显示导出按钮
            isshowExport: false,

            back_SelectTimesCustom: '',
            SelectTimesCustom: ''
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            // console.log("this is:" + val);
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            let postdata = {
                client: this.pagesdatas.client,
                shop_id: this.pagesdatas.shop_id,
                putintime: this.pagesdatas.putintime && this.pagesdatas.putintime.length > 0 ? this.pagesdatas.putintime.join(' ~ ') : '',
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(postdata)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            let postdata = {
                client: this.pagesdatas.client,
                shop_id: this.pagesdatas.shop_id,
                putintime: this.pagesdatas.putintime && this.pagesdatas.putintime.length > 0 ? this.pagesdatas.putintime.join(' ~ ') : '',
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(postdata)
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    '/admin/cashflow/clientfIncome',
                    {
                        client: this.pagesdatas.client,
                        shop_id: this.pagesdatas.shop_id,
                        putintime: this.pagesdatas.putintime && this.pagesdatas.putintime.length > 0 ? this.pagesdatas.putintime.join(' ~ ') : '',
                        export: '1'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then(() => {})
                .catch(function () {})
        },

        //提交等相关event事件函数
        // Maintwo插槽时间范围数据
        changeDataSearchEvent(data) {
            this.back_SelectTimesCustom = [data[0] * 1000, data[1] * 1000]
            this.SelectTimesCustom = data
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：' , data)
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用

            this.pagesdatas.putintime = this.SelectTimesCustom.length > 0 ? this.SelectTimesCustom : []

            let postdata = {
                client: data.client,
                shop_id: data.shop_id,
                putintime: this.SelectTimesCustom && this.SelectTimesCustom.length > 0 ? this.SelectTimesCustom.join(' ~ ') : '',
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(postdata)
        },
        // 获取数据Axios
        postAxiosEvent(postdata) {
            //加载loading
            this.loading = true
            axios
                .post('/admin/cashflow/clientfIncome', postdata, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    let datas = response.data.result

                    // 判断是否显示导出
                    this.isshowExport = datas.tableexport

                    // 获取动态表头
                    if (datas.tabletitle) {
                        this.main_titles = Object.keys(datas.tabletitle)

                        let title_key = Object.keys(datas.tabletitle).sort()
                        let title_active = title_key.map((n) => {
                            return {
                                fixedstatu: false,
                                prop: n,
                                label: datas.tabletitle[n],
                                minwidth: '120'
                            }
                        })
                        let title_guding = ['client:设备编号', 'address:设备地址', 'shop_name:代理商', 'putintime:投放时间']
                        let title_guding_active = title_guding.map((n) => {
                            let [key, chart] = n.split(':')
                            return {
                                fixedstatu: key == 'client' ? 'left' : false,
                                prop: key,
                                label: chart,
                                minwidth: key == 'client' ? '100' : '150'
                            }
                        })
                        this.mainthreetabletitle = [...title_guding_active, ...title_active]
                    }

                    if (this.mainthreetabletitle.length > 0) {
                        // 获取数据
                        this.myaxiosfunc(datas)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            let postdata = {
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(postdata)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

/* 设备台数等信息 */
.dvcinfo-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    color: #333333;
    font-size: 14px;
    margin-left: 40px;
}

.dvcinfo-box-div {
    margin-right: 20px;
}

.dvcinfo-box-div-color {
    color: red;
    margin: 0 5px;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.cells-bg {
    background-color: #e7e7e7;
    color: #e7e7e7;
}
</style>
