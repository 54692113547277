<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div class="box-list-line">
                    <div id="switch">
                        <div class="box-list-line" style="justify-content: left">
                            <div><span>总开关</span></div>
                            <div>
                                <el-switch
                                    v-model="office_config_status"
                                    active-text="开启"
                                    inactive-text="关闭"
                                    active-value="1"
                                    inactive-value="-1"
                                    active-color="#13ce66"
                                    inactive-color="#cbcbcb"
                                    :disabled="!this.mypowerlimits.gzh_pz_zkg"
                                    @click="this.mypowerlimits.gzh_pz_zkg ? officeConfigStatus(office_config_status) : ''"
                                />
                            </div>
                        </div>
                        <div style="line-height: 28px">
                            <div><span>关闭后，系统内所有更新成设备二维码</span></div>
                            <div><span>开启后，根据优先级，优先生成对应公众号的渠道二维码</span></div>
                            <div><span>优先级，只能存在一个开启，并且不能全关闭</span></div>
                        </div>
                    </div>
                    <div>
                        <el-button v-if="this.mypowerlimits.gzh_pz_tj"  type="primary" plain @click="addOrEditEvent(1)">添加</el-button>
                    </div>
                </div>

                <div style="margin-top: 20px">
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <template #default="scope" v-if="mytabletitle.prop == 'status'">
                                <div v-if="mytabletitle.prop == 'status'">
                                    <div v-if="scope.row.status == -1 || scope.row.status == 2" id="switch">
                                        <el-switch
                                            v-model="scope.row.status"
                                            active-text="开启"
                                            inactive-text="关闭"
                                            :active-value="2"
                                            :inactive-value="-1"
                                            active-color="#13ce66"
                                            inactive-color="#cbcbcb"
                                            :disabled="!this.mypowerlimits.gzh_pz_sy"
                                            @click="this.mypowerlimits.gzh_pz_sy ? changeStatus(scope.row) : ''"
                                        >
                                        </el-switch>
                                    </div>
                                    <div v-else>
                                        <span v-if="scope.row.status == 1">开启</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" prop="myoperation" id="operation">
                            <template #default="scope">
                                <div>
                                    <el-button v-if="this.mypowerlimits.gzh_pz_bj" type="primary" size="mini" @click="addOrEditEvent(2, scope.row)">编辑</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <!-- 抽屉 -->
                <div id="mysections">
                    <el-drawer :title="tanFlagOption[tanFlag]" v-model="drawer" direction="rtl" size="70%">
                        <pubNumberSetAddOrEdit
                            :formData="formData"
                            :flag="tanFlag"
                            @request="
                                (data) => {
                                    this.drawer = data
                                }
                            "
                        />
                    </el-drawer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import pubNumberSetAddOrEdit from './pubNumberSetAddOrEdit.vue'

export default defineComponent({
    name: 'vmemberDvcCount',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '公众号'
                },
                {
                    name: '公众号配置'
                }
            ], // 当前页-【面包屑导航】
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 27, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'content.name',
                    label: '公众号名称',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'content.remark',
                    label: '描述',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'content.appid',
                    label: 'AppID',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'content.appsecret',
                    label: 'AppSecret',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'content.originalid',
                    label: '原始ID',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'content.thumb_media_id',
                    label: '卡片图资源ID',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '优先使用',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreetableData: [], // 数据列表中-具体数据

            // 总开关
            office_config_status: '-1',

            // 抽屉options
            tanFlagOption: { 1: '添加', 2: '编辑' },
            tanFlag: '',
            drawer: false,
            formData: {},

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        // 总开关-状态切换
        officeConfigStatus(data) {
            axios
                .post(
                    '/admin/config/update',
                    {
                        key_name: 'office_config_status',
                        contents: {
                            status: data
                        }
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        // 优先使用-状态切换
        changeStatus(data) {
            // 前端自定义的一个过渡字段-前端维护
            data.status = 2

            axios
                .post(
                    '/admin/office/configDisable',
                    {
                        id: data.id,
                        status: data.status
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message,
                            duration: 500,
                            onClose: () => {
                                this.postAxiosEvent()
                            }
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 2) {
                            data.status = -1
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 添加&编辑
        addOrEditEvent(flag, data) {
            this.drawer = true
            this.tanFlag = flag
            this.formData = data ? { ...data.content, id: data.id } : {}
        },
        // 接口
        postAxiosEvent(data, index) {
            if (!index) this.loading = true
            axios
                .get('/admin/office/configIndex', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.mainthreetableData = response.data.result
                    this.loading = false
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        // 获取总开关
        getOfficeConfigStatusInfo() {
            axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'office_config_status'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.office_config_status = response.data.result.status
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.getOfficeConfigStatusInfo()
            this.postAxiosEvent()
        }
    },
    components: {
        mainone,
        pubNumberSetAddOrEdit
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.tool-tip {
    max-width: 400px;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 20px;
    font-size: 14px;
}

.box-list-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
    gap: 20px;
}

/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
}
</style>
