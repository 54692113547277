<template>
    <div>
        <el-row>
            <!-- 占位符 -->
            <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                <div style="width: 100%"></div>
            </el-col>
            <!-- 主内容 -->
            <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                <div style="width: 100%; margin-top: 10px">
                    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
                        <div>
                            <div>
                                <span>赚京币</span>
                            </div>
                            <div class="fangfa-box">
                                <el-form-item label="观看视频（送京币）：" prop="watch_video">
                                    <el-input v-model="ruleForm.watch_video" clearable maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="邀请好友（送京币）：" prop="invite_user">
                                    <el-input v-model="ruleForm.invite_user" clearable maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="上传文档（送京币）：" prop="upload">
                                    <el-input v-model="ruleForm.upload" clearable maxlength="50"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fangfa-box-top">
                            <div>
                                <span>京币兑换余额比例</span>
                            </div>
                            <div class="fangfa-box">
                                <el-form-item label="兑换位置：" prop="display">
                                    <el-radio-group v-model="ruleForm.display">
                                        <el-radio label="1" size="large">显示</el-radio>
                                        <el-radio label="-1" size="large">隐藏</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <div v-if="ruleForm.display == 1">
                                    <el-form-item label="京币数量（币）：" prop="coin">
                                        <el-input v-model="ruleForm.coin" clearable maxlength="50"></el-input>
                                    </el-form-item>
                                    <el-form-item label="兑换余额（元）：" prop="cash">
                                        <el-input v-model="ruleForm.cash" clearable maxlength="50"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <div class="fangfa-box-top">
                            <div>
                                <span>京币有效期</span>
                            </div>
                            <div class="fangfa-box">
                                <el-form-item label="有效期：" prop="valid_time">
                                    <div class="date-time-box">
                                        <div><span>发放日起</span></div>
                                        <div><el-input v-model.number="ruleForm.valid_time" clearable maxlength="10" ></el-input></div>
                                        <div><span>月过期</span></div>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                        <el-form-item v-if="this.mypowerlimits.jcsz_bc" class="fangfa-box-top">
                            <el-button type="primary" @click="submitForm('ruleForm', 'coin_set', ruleForm)" style="width: 40%; margin-right: 10px" :disabled="buttondisable"
                                >保存
                            </el-button>
                            <el-button @click="resetForm('ruleForm')">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-col>
            <!-- 占位符 -->
            <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="8">
                <div style="width: 100%"></div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'MakeMoneySet',
    inject: ['reload'], //刷新引用
    props: ['ModelInfo'],
    watch: {
        ModelInfo: {
            handler(newVal) {
                this.ruleForm = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        // 数字input框-校验-(仅整数)【赚钱页配置||】
        var checkInputEventInt = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf('.')
                if (result != -1) {
                    callback(new Error('请输入整数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入整数'))
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error('请输入整数'))
                        } else {
                            if (value < 0) {
                                callback(new Error('值需大于0'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }

        return {
            //form表单-赚钱页配置
            ruleForm: {
                watch_video: '',
                invite_user: '',
                upload: '',
                display: '-1',
                coin: '',
                cash: '',
                valid_time: ''
            },
            //form表单-必填规则-赚钱页配置
            rules: {
                watch_video: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                invite_user: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                upload: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                display: [
                    {
                        required: true,
                        message: '兑换位置为必选项',
                        trigger: 'blur'
                    }
                ],
                coin: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                cash: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                valid_time: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    },
                    {
                        type: 'number',
                        min: 1,
                        message: '必须大于0的正整数',
                        trigger: 'change'
                    }
                ]
            },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token
        }
    },
    methods: {
        //from表单-提交表单
        submitForm(formName, keyname, formdata) {
            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
            this.buttondisable = true
            // console.log("当前获取的formdata：" + JSON.stringify(formdata));
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/config/update',
                            {
                                key_name: keyname,
                                contents: formdata
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '保存成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        // 刷新当前页面
                                        // if (formName == 'ruleForm') {
                                        //     let tab = {
                                        //         props: { name: 'one' }
                                        //     }
                                        //     this.handleClick(tab)
                                        // }

                                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                        this.buttondisable = false
                                    }
                                })
                            } else {
                                // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                this.buttondisable = false
                            }
                        })
                        .catch(function () {
                            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                            this.buttondisable = false
                        })
                } else {
                    // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                    this.buttondisable = false
                    return false
                }
            })
        },
        //from表单-重置表单-(常规表单)
        resetForm(formName) {
            this.$refs[formName].resetFields()
        }
    },
    created() {}
}
</script>

<style scoped>
.fangfa-box {
    border: 1px dashed var(--el-border-color-base);
    padding: 20px 20px 0 0;
    border-radius: 4px;
    margin-top: 20px;
}
.fangfa-box-top {
    margin-top: 20px;
}

.date-time-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    color: #606266;
    font-size: 14px;
    font-family: 'PingFang SC';
}
</style>
