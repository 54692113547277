export default {
    // 优惠券
    coupon: {
        use_type: { 1: '打印', 2: '文库', 3: 'PLUS会员', 4: '电子证件照', 5: '商家券' }
    },
    // 权益会员
    member_benefits: {
        member_type: { 1: '商家邀请', 2: '用户自购', 3: '全平台' },
        rule_type: { 1: '邀请送余额', 2: '邀请送商家券' },
        use_status: { '-1': '未使用', 1: '已使用', 2: '已过期', 3: '已作废' },
        document_is_free: { 1: '免文档使用费' } //, '-1': '不免文档使用费'
    },
    // 回复消息配置
    back_news_set: {
        jump_type: { 1: '网址', 2: '小程序' }
    }
}
