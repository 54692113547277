<template>
  <div v-loading="loading" element-loading-text="正在飞速加载中...">
    <div style="width: 99%;position: absolute; top: 20px;">
      <mainone :mainonebread="mainonebread"></mainone>
      <!-- 顶部-发布等按钮 -->
      <div class="pages-box-top">
        <div style="padding:10px">
          <!-- <el-button type="danger" plain @click="(this.reload())">重置</el-button> -->
          <el-button type="primary" plain @click="routeraddrClick('/vweipagespreview', this.phonecontent, 'preview')">
            预览效果</el-button>
          <el-button type="warning" @click="conserveEvent(this.phonecontent)">保存</el-button>
          <el-button type="primary" @click="releaseEvent()" :disabled="mydisabled">{{ this.releasestatus == '-1' ? '发布'
              : '重新发布'
          }} <i class="el-icon-s-promotion"></i></el-button>
        </div>
      </div>
    </div>

    <!-- 微页内容 -->
    <div class="pages-box">
      <!-- 左侧 -->
      <el-affix :offset="175">
        <!-- 左侧-功能组件 -->
        <div class="pages-box-left pages-box-all">
          <el-tabs tab-position="left" style="height: 100%">
            <el-tab-pane label="组件">
              <mysubbase v-on:getsubbasedata="getsubbasedataEvent"></mysubbase>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-affix>
      <!-- 中部-显示 -->
      <el-affix :offset="175">
        <div style="overflow:hidden;margin:0px;width:100%;height:100%;">
          <div class="pages-box-center"
            style="width:100%; height:100%; overflow-y:auto; overflow-x:auto;margin-right: -20px;">
            <div class="pages-box-center-padding">
              <div class="pages-box-center-padding-box">
                <div class="padding-box">
                  <div class="padding-box-center">
                    <!-- 固定手机顶头 -->
                    <div>
                      <img src="@/assets/imgs/weipages/phoneTop.png" alt="" class="statusBar" />
                    </div>
                    <!-- 内容-内容模块 -->
                    <div>
                      <div v-for="(item, index) in this.phonecontent" :key="index" class="changebox-hover"
                        :class="item.changebox ? 'change-box' : ''" @click="changePhonemodelEvent(item, index)">
                        <!-- 头部-手机标题 -->
                        <div v-if="item.flag == 'phonehead'">
                          <myphonehead :phoneheaddata="item"></myphonehead>
                        </div>
                        <!-- 文本 -->
                        <div v-if="item.flag == 'text'">
                          <el-popover placement="right-start" width="20px" trigger="click"
                            @show="changePhonemodelEvent(item, index)">
                            <div style="text-align: center;" class="padding-box-right-icon">
                              <div v-if="index != 1" @click="caretTopEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-caret-top"></i></div>
                              <div v-if="index != this.phonecontent.length - 1"
                                @click="caretBottomEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-caret-bottom"></i>
                              </div>
                              <div @click="delSolidEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-delete-solid"></i>
                              </div>
                            </div>
                            <template #reference>
                              <div>
                                <mytextshow :textdata="item"></mytextshow>
                              </div>
                            </template>
                          </el-popover>
                        </div>
                        <!-- 富文本 -->
                        <div v-if="item.flag == 'richtext'">
                          <el-popover placement="right-start" width="20px" trigger="click"
                            @show="changePhonemodelEvent(item, index)">
                            <div style="text-align: center;" class="padding-box-right-icon">
                              <div v-if="index != 1" @click="caretTopEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-caret-top"></i></div>
                              <div v-if="index != this.phonecontent.length - 1"
                                @click="caretBottomEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-caret-bottom"></i>
                              </div>
                              <div @click="delSolidEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-delete-solid"></i>
                              </div>
                            </div>
                            <template #reference>
                              <div>
                                <myrichtextshow :richtextdata="item"></myrichtextshow>
                              </div>
                            </template>
                          </el-popover>
                        </div>
                        <!-- 图片 -->
                        <div v-if="item.flag == 'picture'">
                          <el-popover placement="right-start" width="20px" trigger="click"
                            @show="changePhonemodelEvent(item, index)">
                            <div style="text-align: center;" class="padding-box-right-icon">
                              <div v-if="index != 1" @click="caretTopEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-caret-top"></i></div>
                              <div v-if="index != this.phonecontent.length - 1"
                                @click="caretBottomEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-caret-bottom"></i>
                              </div>
                              <div @click="delSolidEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-delete-solid"></i>
                              </div>
                            </div>
                            <template #reference>
                              <div>
                                <mypictureshow :picturedata="item" :pictureflages="'nosubmit'"></mypictureshow>
                              </div>
                            </template>
                          </el-popover>
                        </div>
                        <!-- 按钮 -->
                        <div v-if="item.flag == 'button'">
                          <el-popover placement="right-start" width="20px" trigger="click"
                            @show="changePhonemodelEvent(item, index)">
                            <div style="text-align: center;" class="padding-box-right-icon">
                              <div v-if="index != 1" @click="caretTopEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-caret-top"></i></div>
                              <div v-if="index != this.phonecontent.length - 1"
                                @click="caretBottomEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-caret-bottom"></i>
                              </div>
                              <div @click="delSolidEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-delete-solid"></i>
                              </div>
                            </div>
                            <template #reference>
                              <div>
                                <mybuttonshow :buttondata="item" :buttonflages="'nosubmit'"></mybuttonshow>
                              </div>
                            </template>
                          </el-popover>
                        </div>
                        <!-- 表单 -->
                        <div v-if="item.flag == 'form'">
                          <el-popover placement="right-start" width="20px" trigger="click"
                            @show="changePhonemodelEvent(item, index)">
                            <div style="text-align: center;" class="padding-box-right-icon">
                              <div v-if="index != 1" @click="caretTopEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-caret-top"></i></div>
                              <div v-if="index != this.phonecontent.length - 1"
                                @click="caretBottomEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-caret-bottom"></i>
                              </div>
                              <div @click="delSolidEvent(item, this.phonecontent, index)"><i
                                  class="el-icon-delete-solid"></i>
                              </div>
                            </div>
                            <template #reference>
                              <div>
                                <myformshow :formdata="item" :formflages="'nosubmit'"></myformshow>
                              </div>
                            </template>
                          </el-popover>
                        </div>
                        <!-- 其他 -->
                        <div v-else></div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-affix>
      <!-- 右侧 -->
      <el-affix :offset="175">
        <!-- 右侧-详细字段 -->
        <div class="pages-box-right pages-box-all">
          <div class="pages-box-small">
            <!-- 手机-页面-标题 -->
            <myphoneheadset v-if="this.rightflag == 'phonehead'" ref="refmyphoneheadset"
              :phoneheadform="this.phoneheadform" v-on:getphoneheaddata="getphoneheaddataEvent"></myphoneheadset>
            <!-- 文本 -->
            <mytextset v-if="this.rightflag == 'text'" ref="refmytextset" :textform="textsetdata"
              v-on:gettextsetdata="gettextsetdataEvent"></mytextset>
            <!-- 富文本 -->
            <myrichtextset v-if="this.rightflag == 'richtext'" :richtextform="richtextsetdata"
              v-on:getrichtextsetdata="getrichtextsetdataEvent" ref="refmyrichtextset"></myrichtextset>
            <!-- 图片 -->
            <mypictureset v-if="this.rightflag == 'picture'" :pictureform="picturesetdata"
              v-on:getpicturesetdata="getpicturesetdataEvent" ref="refmypictureset"></mypictureset>
            <!-- 按钮 -->
            <mybuttonset v-if="this.rightflag == 'button'" :buttonform="buttonsetdata"
              v-on:getbuttonsetdata="getbuttonsetdataEvent" ref="refmybuttonset"></mybuttonset>
            <!-- 表单 -->
            <myformset v-if="this.rightflag == 'form'" :formform="formsetdata" v-on:getformsetdata="getformsetdataEvent"
              ref="refmyformset"></myformset>
          </div>
        </div>
      </el-affix>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mysubbase from "@/components/weipages/subchange/subbase/subbase.vue";
import myphonehead from "@/components/weipages/subshow/phonehead/phonehead.vue";
import myphoneheadset from "@/components/weipages/subset/phoneheadset/phoneheadset.vue";
import mytextset from "@/components/weipages/subset/textset/textset.vue";
import mytextshow from "@/components/weipages/subshow/textshow/textshow.vue";
import myrichtextset from "@/components/weipages/subset/richtextset/richtextset.vue"
import myrichtextshow from "@/components/weipages/subshow/richtextshow/richtextshow.vue"
import mypictureset from "@/components/weipages/subset/pictureset/pictureset.vue"
import mypictureshow from "@/components/weipages/subshow/pictureshow/pictureshow.vue"
import mybuttonset from "@/components/weipages/subset/buttonset/buttonset.vue"
import mybuttonshow from "@/components/weipages/subshow/buttonshow/buttonshow.vue"
import myformset from "@/components/weipages/subset/formset/formset.vue"
import myformshow from "@/components/weipages/subshow/formshow/formshow.vue"

import getareajson from '@/assets/json/area.json'
import { ElLoading, ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vweipagesedit",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "店铺",
        },
        {
          name: "装修",
        },
        {
          name: "微页面",
          path: "/weipages",
        },
        {
          name: "编辑微页面",
        },
      ], // 当前页-【面包屑导航】

      margintop: "20px",

      // 发布状态
      releasestatus: '-1', // 默认为发布，-1发布，1重新发布

      phonecontent: [],

      // 中部-选中key-默认
      keyflag: 0,
      // 右侧-显示标识-默认
      rightflag: "phonehead",

      // 右侧-[手机标题页面]传输数据-默认
      phoneheadform: {},
      // 右侧-[文本]传输数据
      textsetdata: {},
      // 右侧-[富文本]传输数据
      richtextsetdata: {},
      // 右侧-[图片]传输数据
      picturesetdata: {},
      // 右侧-[按钮]传输数据
      buttonsetdata: {},
      // 右侧-[表单]传输数据
      formsetdata: {},

      // 保存之后的数据存储
      saveDataInfo: {},

      // 防抖-前端维护
      mydisabled: false,

      loading: false, //初始化-loading加载动效，默认是false关闭

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 发布按钮
    releaseEvent() {
      if (!this.saveDataInfo.id) {
        ElMessage({
          type: "warning",
          message: '请先编辑保存之后，在发布！！！',
        });
      } else {
        // 防抖-前端维护
        this.mydisabled = true;

        axios
          .post(
            "/admin/pages/disable",
            {
              id: this.saveDataInfo.id,
              status: 1,   //-1未发布1发布
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.code == 0) {
              ElMessage({
                type: "success",
                message: '发布成功',
                duration: 1000,
                onClose: () => {
                  this.$router.push('/weipages').catch((error) => error);
                  this.mydisabled = false;
                },
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            this.mydisabled = false;
          });
      }
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata, flag) {
      if (rowdata) {
        if (routeraddr == '/vweipagespreview') {
          if (flag == 'preview') {
            // 存储当前页面-并通过flage判别跳转路由
            this.conserveEvent(rowdata, flag);
          }
        }
      }
      else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    // 保存微页面
    conserveEvent(data, flag) {
      //加载loading
      var loading = ElLoading.service({
        lock: true,
        text: "正在保存.......",
        background: 'rgba(0, 0, 0, 0.7)',
      });

      // 去掉-省市区的options
      let phonecontentdata = this.$utils.inputdelCityCode(this.phonecontent, getareajson, 'del')

      console.log('phonecontentdata:',phonecontentdata)
      // 清空form表单中的value值
      for (let index = 0; index < phonecontentdata.length; index++) {
        const element = phonecontentdata[index];
        if(element.flag == 'form'){
          element.formdata.forminfo.forEach(element => {
             element.value = '';
          });
        }
      }

      axios
        .post(
          "/admin/pages/update",
          {
            id: this.$route.query.id,
            title: phonecontentdata[0].value,
            template: JSON.stringify(phonecontentdata),
            status: this.saveDataInfo.status,   //-1未发布1发布
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            this.saveDataInfo = response.data.result;
            this.phonecontent = JSON.parse(response.data.result.template);

            ElMessage({
              type: "success",
              message: '保存成功',
              duration: 1000,
              onClose: () => {
                // flag标识判断-跳转路由
                if (flag == 'preview') {
                  this.$router.push({
                    path: '/vweipagespreview',
                    query: {
                      id: this.saveDataInfo.id
                    }
                  }).catch((error) => error);
                }
                loading.close()
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //   左侧-拖拽-返回数据
    getsubbasedataEvent(data) {
      console.log(data)
      let ide = this.phonecontent.length;
      // 文本
      if (data.value == 1) {
        let datas = {
          flag: "text",
          key: ide,
          value: "默认文本",
          styletype: {
            textalign: "left", // left左，center中，right右
            fontsize: "14px",
            fontweight: "400", // 400常规，bold加粗，
            color: "#333333",
            bgcolor: "#FFFFFF",
          },
        };
        this.phonecontent.push(datas);
        this.changePhonemodelEvent(datas, ide);
      }
      // 富文本
      if (data.value == 2) {
        let datas = {
          flag: "richtext",
          key: ide,
          value: "<p>默认富文本</p>",
          styletype: {
            bgcolor: "#FFFFFF",
          },
        };
        this.phonecontent.push(datas);
        this.changePhonemodelEvent(datas, ide);
      }
      // 图片
      if (data.value == 3) {
        let datas = {
          flag: "picture",
          key: ide,
          value: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
          styletype: {
            width: '100%',
            height: '100%'
          },
          formdata: {
            jumptype: '2', // 1-站内链接；2-站外小程序；3-h5链接/站外网页
            jumpappid: '',
            jumpurl: ''
          }
        };
        this.phonecontent.push(datas);
        this.changePhonemodelEvent(datas, ide);
      }
      // 按钮
      if (data.value == 4) {
        let datas = {
          flag: "button",
          key: ide,
          value: "按钮",
          styletype: {
            btntype: {
              changekey: '1', // 1 
              type: 'primary', // 按钮主题色 primary || success || info || warning || danger
              plain: false, // 线型
              round: false, // 圆角
              boxshadow: '', // 0px 5px 1px #465EFF
            },
            fontsize: '14px',
            fontcolor: {
              type: '1',
              color: ''
            },
            bgcolor: {
              type: '1',
              color: ''
            },
            btnwidth: '100%',
            btnheight: '40px', // 按钮高度最小为40px
            formdata: {
              jumptype: '2', // 1-站内链接；2-站外小程序；3-h5链接/站外网页
              jumpappid: '',
              jumpurl: ''
            }
          }
        };
        this.phonecontent.push(datas);
        this.changePhonemodelEvent(datas, ide);
      }
      // 表单
      if (data.value == 5) {
        let datas = {
          flag: "form",
          key: ide,
          valueisshow: '1', // 是否显示表单名称，1显示，2隐藏
          value: "表单名称",
          form_id: '', // 表单的formid，唯一：前端维护
          formdata: {
            id: '90', // h5微页的id
            isshow: '1', // 是否显示字段名，1显示，2隐藏
            forminfo: [
              {
                paikey: 0,  // 前端维护的排序key
                key: 1,  // 后端固定的key
                name: '姓名',
                value: '',
                inputboxtype: {
                  type: 1, // 输入框类型：1输入框，2下拉框，3多级联选(省市区)
                },
                changebox: true,
              },
              {
                paikey: 1,
                key: 2,
                name: '手机号',
                value: '',
                inputboxtype: {
                  type: 1,
                }
              },
              {
                paikey: 2,
                key: 3,
                name: '省市区',
                value: '',
                inputboxtype: {
                  type: 3,
                  optionsvalue: '',
                  options: getareajson
                }
              },
              {
                paikey: 3,
                key: 4,
                name: '自定义下拉',
                value: '',
                inputboxtype: {
                  type: 2,
                  optionsvalue: '自定义下拉01#自定义下拉02',
                  options: ['自定义下拉01', '自定义下拉02']
                }
              },
            ],
            rules: { // 校验的字段必须与form表单提交的字段一直
              field1: [
                {
                  required: true,
                  message: "必填项",
                  trigger: "blur",
                },
              ],
              field2: [
                {
                  required: true,
                  message: "必填项",
                  trigger: "blur",
                },
              ],
              field3: [
                {
                  required: true,
                  message: "必填项",
                  trigger: "blur",
                },
              ],
              field4: [
                {
                  required: false,
                  message: "必填项",
                  trigger: "blur",
                },
              ]
            }
          },
          button: {
            value: "按钮",
            styletype: {
              btntype: {
                changekey: '1', // 1 
                type: 'primary', // 按钮主题色 primary || success || info || warning || danger
                plain: false, // 线型
                round: false, // 圆角
                boxshadow: '', // 0px 5px 1px #465EFF
              },
              fontsize: '14px',
              fontcolor: {
                type: '1',
                color: ''
              },
              bgcolor: {
                type: '1',
                color: ''
              },
              btnwidth: '100%',
              btnheight: '40px', // 按钮高度最小为40px
            }
          }
        };
        this.phonecontent.push(datas);
        this.changePhonemodelEvent(datas, ide);
      }
    },
    //  点击-【组件】模块
    changePhonemodelEvent(data, ide) {
      console.log(data, ide)
      this.rightflag = data.flag;
      this.keyflag = ide;
      // 移除带有changebox的对象值
      this.phonecontent.forEach((element) => {
        if (element.changebox) {
          Reflect.deleteProperty(element, "changebox");
        }
      });

      // 对选中的对象进行changebox赋值
      this.phonecontent[ide].changebox = true;

      // 点击-【手机页面标题】模块
      if (data.flag == "phonehead") {
        this.changePhoneheadModel(data);
      }
      // 点击-【文本】模块
      if (data.flag == "text") {
        this.changeTextModel(data);
      }

      // 点击-【富文本】模块
      if (data.flag == "richtext") {
        this.changeRichtextModel(data);
      }

      // 点击-【图片】模块
      if (data.flag == "picture") {
        this.changePictureModel(data);
      }

      // 点击-【按钮】模块
      if (data.flag == "button") {
        this.changeButtonModel(data);
      }

      // 点击-【表单】模块
      if (data.flag == "form") {
        this.changeFormModel(data);
      }
    },
    // 上移-【组件】模块
    caretTopEvent(data, datas, ide) {
      // 删除当前模块
      this.delSolidEvent(data, datas, ide, 1)
      // 向下插入-当前模块
      datas.splice(ide - 1, 0, data);
      // 重新排序数组key
      let newdatas = this.resultKeyEvent(datas)
      // 【由于刷新不及时-加入延时】
      this.phonecontent = [];
      setTimeout(() => {
        this.phonecontent = newdatas;
      }, 10);
      this.changePhonemodelEvent(newdatas[ide - 1], ide - 1);
    },
    // 下移-【组件】模块
    caretBottomEvent(data, datas, ide) {
      // 删除当前模块
      this.delSolidEvent(data, datas, ide, 1)
      // 向下插入-当前模块
      datas.splice(ide + 1, 0, data);
      // 重新排序数组key
      let newdatas = this.resultKeyEvent(datas)
      // 【由于刷新不及时-加入延时】
      this.phonecontent = [];
      setTimeout(() => {
        this.phonecontent = newdatas;
      }, 10);
      this.changePhonemodelEvent(newdatas[ide + 1], ide + 1);
    },
    // 删除-【组件】模块
    delSolidEvent(data, datas, ide, flag) {
      if (flag) {
        datas.splice(ide, 1)
        let newdatas = this.resultKeyEvent(datas)
        this.phonecontent = newdatas
      } else {
        ElMessageBox.prompt('删除组件将同时删除已收集的数据且不可恢复，是否确认删除?', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^永久删除$/,
          inputErrorMessage: '请输入"永久删除"4个字',
          inputPlaceholder: '请输入"永久删除"4个字'
        })
          .then(({ value }) => {
            console.log(value)
            datas.splice(ide, 1)
            let newdatas = this.resultKeyEvent(datas)
            this.phonecontent = newdatas
            // 点击删除之后-向上锁定模块
            this.changePhonemodelEvent(newdatas[ide - 1], ide - 1);
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消删除',
            })
          })
      }
    },
    // 【组件】模块-key重新排序-前端维护key值
    resultKeyEvent(datas) {
      // 重新排-key值
      for (let i = 0; i < datas.length; i++) {
        datas[i].key = i
      }
      return datas
    },
    // 点击-【手机页面标题】模块
    changePhoneheadModel(data) {
      this.phoneheadform = {
        text: data.value,
      };
    },
    //  获取-【手机页面标题】返显
    getphoneheaddataEvent(data) {
      if (this.phonecontent[this.keyflag]) {
        this.phonecontent[this.keyflag].value = data.text;
      }
    },
    // 点击-【文本】模块
    changeTextModel(data) {
      let textalign = "";
      // 判断-位置
      if (data.styletype.textalign == "left") {
        textalign = "居左显示";
      }
      if (data.styletype.textalign == "center") {
        textalign = "居中显示";
      }
      if (data.styletype.textalign == "right") {
        textalign = "居右显示";
      }

      let fontsize = "";
      // 判断-字体大小
      if (data.styletype.fontsize == "16px") {
        fontsize = "大（16号）";
      }
      if (data.styletype.fontsize == "14px") {
        fontsize = "中（14号）";
      }
      if (data.styletype.fontsize == "12px") {
        fontsize = "小（12号）";
      }

      let fontweight = "";
      // 判断-字体粗细
      if (data.styletype.fontweight == "400") {
        fontweight = "常规体";
      }
      if (data.styletype.fontweight == "bold") {
        fontweight = "加粗体";
      }

      this.textsetdata = {
        text: data.value,
        position: textalign,
        fontsize: fontsize,
        fontweight: fontweight,
        textcolor: data.styletype.color,
        backgroundcolor: data.styletype.bgcolor,
      };
    },
    //  获取-【文本】返显
    gettextsetdataEvent(data) {
      this.phonecontent[this.keyflag].value = data.text;
      this.phonecontent[this.keyflag].styletype.textalign = data.textalignset;
      this.phonecontent[this.keyflag].styletype.fontsize = data.fontsizeset;
      this.phonecontent[this.keyflag].styletype.fontweight = data.fontweightset;
      this.phonecontent[this.keyflag].styletype.color = data.textcolor;
      this.phonecontent[this.keyflag].styletype.bgcolor = data.backgroundcolor;
    },
    // 点击-【富文本】模块
    changeRichtextModel(data) {
      this.richtextsetdata = {
        value: data.value,
        backgroundcolor: data.styletype.bgcolor,
      };
      setTimeout(() => {
        this.$refs.refmyrichtextset.postValueEvent(data.value)
      }, 100)
    },
    // 获取-【富文本】返显
    getrichtextsetdataEvent(data) {
      this.phonecontent[this.keyflag].value = data.value;
      this.phonecontent[this.keyflag].styletype.bgcolor = data.backgroundcolor;
    },
    // 点击-【图片】模块
    changePictureModel(data) {
      this.picturesetdata = {
        value: data.value,
        styletype: data.styletype,
        formdata: data.formdata,
      }
    },
    // 获取-【图片】返显
    getpicturesetdataEvent(data) {
      this.phonecontent[this.keyflag].value = data.value;
      this.phonecontent[this.keyflag].styletype = data.styletype;
      this.phonecontent[this.keyflag].formdata = data.formdata;
    },
    // 点击-【按钮】模块
    changeButtonModel(data) {
      this.buttonsetdata = {
        value: data.value,
        styletype: data.styletype,
      }
    },
    // 获取-【按钮】返显
    getbuttonsetdataEvent(data) {
      this.phonecontent[this.keyflag].value = data.value;
      this.phonecontent[this.keyflag].styletype = data.styletype;
    },
    // 点击-【表单】模块
    changeFormModel(data) {
      this.formsetdata = {
        valueisshow: data.valueisshow,
        value: data.value,
        formdata: data.formdata,
        button: data.button,
      }
    },
    // 获取-【表单】返显
    getformsetdataEvent(data) {
      this.phonecontent[this.keyflag].valueisshow = data.valueisshow;
      this.phonecontent[this.keyflag].value = data.value;
      this.phonecontent[this.keyflag].formdata = data.formdata;
      this.phonecontent[this.keyflag].button = data.button;
    },

  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      var loading = ElLoading.service({
        lock: true,
        text: "正在加载......",
        background: 'rgba(0, 0, 0, 0.7)',
      });

      let routerinfo = this.$route.query;
      // 获取微页面详情
      axios.post(
        "/admin/pages/info",
        {
          id: routerinfo.id,
        },
        {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.code === 0) {
            let getdata = response.data.result.info
            this.saveDataInfo = getdata;
            let weipagesdata = JSON.parse(getdata.template);

            // 插入-省市区的options
            let phonecontentdata = this.$utils.inputdelCityCode(weipagesdata, getareajson, 'input')
            this.phonecontent = phonecontentdata

            // 判断发布状态-显示-1发布，1重新发布
            this.releasestatus = getdata.status;

            // 默认进入-定位到页面标题组件
            this.changePhonemodelEvent(this.phonecontent[0], 0);

            setTimeout(() => {
              loading.close();
            }, 500)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    mysubbase,
    myphonehead,
    myphoneheadset,
    mytextset,
    mytextshow,
    myrichtextset,
    myrichtextshow,
    mypictureset,
    mypictureshow,
    mybuttonset,
    mybuttonshow,
    myformset,
    myformshow,

  },
});
</script>
<style scoped>
.pages-box {
  min-height: 550px;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 1160px;
  /* background-color: rgba(240, 242, 245, 0.815); */
  background-color: #e9eef3;
}

.pages-box-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 10px;
  border-radius: 4px;
}

.pages-box-all {
  width: 100%;
}

.pages-box-center {
  min-width: 580px;
  /* background-color: rgba(240, 242, 245, 0.815); */
  background-color: #e9eef3;

}

.pages-box-left {
  width: 100%;
  min-width: 200px;
  max-width: 375px;
  background-color: #ffffff;
  height: 750px;
  border-radius: 4px;
}

.pages-box-right {
  width: 100%;
  min-width: 450px;
  max-width: 450px;
  background-color: #ffffff;
  height: 750px;
  border-radius: 4px;
}

/* 中部 */
.statusBar {
  width: 100%;
  height: 25px;
  display: block;
}

.pages-box-center-padding {
  padding: 30px 0;
}

.pages-box-center-padding-box {
  height: 100%;
  width: 100%;
}

.padding-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.padding-box-center {
  width: 440px;
  min-height: 680px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.24);
  background-color: #ffffff;
}

.padding-box-center-padding {
  padding: 10px 20px;
}

.padding-box-left {
  width: 20px;
  margin-right: 10px;
  background-color: bisque;
  text-align: center;
}

.padding-box-right {
  width: 20px;
  margin-left: 10px;
  background-color: bisque;
}


/* 中部-模块 */
.changebox-hover:hover {
  cursor: pointer;
  border: 2px dashed var(--el-color-primary);
}

.change-box {
  border: 2px solid var(--el-color-primary);
}

.padding-box-right-icon div {
  margin-bottom: 5px;
  text-align: center;
  color: #b8b8b8;
}

.padding-box-right-icon div:hover {
  color: var(--el-color-primary);
  cursor: pointer;
}


/* 右侧 */
.pages-box-small {
  padding: 10px;
}
</style>