<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
      :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"
      v-on:selectdatainfo="selectDatainfoEvent" v-on:selectdatainfogao="selectDatainfoGaoEvent"
      v-on:resetdatainfo="resetdatainfoEvent" v-on:resetdatainfogao="resetdatainfogaoEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div class="box-list">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--添加XX按钮-点击跳转添加类页面-->
            <!-- <el-button type="primary" plain @click="addTanClick((dialogVisible = true))" v-if="this.mypowerlimits.dlsdzdmx_ckk">充扣款</el-button> -->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'name' ||
                mytabletitle.prop === 'pay_uid' ||
                mytabletitle.prop === 'price' ||
                mytabletitle.prop === 'credit' ||
                mytabletitle.prop === 'thirdparty' ||
                mytabletitle.prop === 'paytype'
              ">
                <!-- 代理商-数据渲染 -->
                <div v-if="mytabletitle.prop === 'name'">
                  <div :class="this.mypowerlimits.dlslb == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.dlslb == true ? routeraddrClick('/agentlist', scope.row) : ''">
                    <span>{{ scope.row.name }}</span>
                  </div>
                </div>
                <!-- 支付用户ID-数据渲染 -->
                <div v-if="mytabletitle.prop === 'pay_uid'">
                  <div :class="this.mypowerlimits.yhlb == true && scope.row.pay_uid != '平台' ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.yhlb == true && scope.row.pay_uid != '平台' ? routeraddrClick('/userlist', scope.row) : ''">
                    <span>{{ scope.row.pay_uid != 0 ? scope.row.pay_uid : '' }}</span>
                  </div>
                </div>
                <!-- 账户余额-数据渲染 -->
                <div v-if="mytabletitle.prop === 'credit'">
                  <div v-if="scope.row.credit == 0">
                    <span>{{ 0 }}</span>
                  </div>
                  <div v-else>
                    <span>{{ scope.row.credit / 100 }}</span>
                  </div>
                </div>
                <!-- 金额-数据渲染 -->
                <div v-if="mytabletitle.prop === 'price'">
                  <div v-if="scope.row.price == 0">
                    <span>{{ 0 }}</span>
                  </div>
                  <div v-else>
                    <span>{{ scope.row.price / 100 }}</span>
                  </div>
                </div>
                <!-- 平台来源-数据渲染 -->
                <div v-if="mytabletitle.prop === 'thirdparty'">
                  <div><span>{{ this.platforms.filter(n => n.value == scope.row.thirdparty)[0].label }}</span></div>
                </div>
                <!-- 支付方式 -->
                <div v-if="mytabletitle.prop === 'paytype'">
                  <span>{{ scope.row.paytype ? this.getpaytype.filter(n => n.value == scope.row.paytype)[0]['label'] :
                      ''
                  }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" :layout="Layout"
              :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!--弹出框-充扣款-->
    <el-dialog v-model="dialogVisible" title="充扣款" :width="this.searchwidth" :before-close="handleCloseSenior">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
        <div class="tan-box">
          <el-form-item label="类型：" prop="resource">
            <el-radio-group v-model="ruleForm.resource">
              <el-radio label="1">充值</el-radio>
              <el-radio label="2">扣款</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="金额：" prop="money">
            <el-input v-model="ruleForm.money" placeholder="请输入金额" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input v-model="ruleForm.phone" placeholder="请输入手机号" @change="changePhonenumEvent" clearable
              maxlength="11" />
          </el-form-item>
          <el-form-item label="代理商名称：" prop="nickname">
            <div
              style="max-width: 96%;height: 40px;border-radius: 4px;border: 1px solid rgb(220, 223, 230);padding-left: 15px;color: rgb(118, 118, 118);">
              <span>{{ this.nickname }}</span>
            </div>
          </el-form-item>
          <el-form-item label="备注：" prop="desc">
            <el-input v-model="ruleForm.desc" type="textarea"></el-input>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="resetForm('ruleForm')">重置</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')" :disabled="mydisabled">确认</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vstatementagent",
  inject: ["reload"],
  data() {
    var checkPhone = (rule, value, callback) => {
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("手机号中不能有空格"));
      }
      setTimeout(() => {
        // 判断字符串是否存在小数点
        let result = value.toString().indexOf(".");
        if (result != -1) {
          callback(new Error("请正确输入手机号"));
        } else {
          // 判断字符串是否为文字
          if (isNaN(value)) {
            callback(new Error("请正确输入手机号"));
          } else {
            // 判断字符串是否为数字
            if (!Number.parseInt(value)) {
              callback(new Error("请正确输入手机号"));
            } else {
              if (value < 0) {
                callback(new Error("请正确输入手机号"));
              } else {
                callback();
              }
            }
          }
        }
      }, 500);
    };
    return {
      mainonebread: [
        {
          name: "财务",
        },
        {
          name: "资金对账",
        },
        {
          name: "代理商对账单明细",
          path: "/entrynote",
        },
      ], // 当前页-【面包屑导航】
      source_list: [
        { 0: [1, 2, 7, 11, 12, 14, 15, 16, 3, 13, 4, 5, 6, 8, 9, 10, 17, 18, 19, 20, 23, 24] },
        { 1: [1, 2, 7, 11, 12, 14, 15, 16, 17, 18, 19] },
        { 2: [3, 13] },
        { 3: [4, 5] },
        { 4: [6, 8, 9, 10] },
        { 5: [20] },
        { 6: [23, 24] }
      ],

      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "业务来源：",
              placeholder: "全部",
              name: "source",
              value: "",
              onchange: 0,
              options: [],
            },
            {
              selectisshow: true,
              ismust: false,
              title: "类型：",
              placeholder: "全部",
              name: "type",
              value: "",
              options: [],
            },
          ],
        },
        myinputonefullisshow: true, //筛选查询-自动建议下拉框
        myinputonefull: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "设备编号：",
              placeholder: "请输入设备编号", //此name为键值
              name: "client_id",
              content: "",
              id: "", //此id为给后台的id值
              url: "/admin/getClientList",
              inputval: { client: "" }, //这里记录需要传的参数
            },
            {
              ismust: false, //是否必填，true表示必填
              title: "代理商名：",
              placeholder: "请输入代理商名称",
              name: "shop_id", //此name为键值
              content: "",
              id: "", //此id为给后台的id值
              url: "/admin/shopUser/getShopList",
              inputval: { name: "" }, //这里记录需要传的参数
            },
          ],
        },
      }, // 筛选-【筛选查询】-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 9, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "name",
          label: "代理商",
          width: "",
        },
        {
          fixedstatu: "left",
          prop: "username",
          label: "手机号",
          width: "130",
        },
        {
          fixedstatu: false,
          prop: "client",
          label: "设备编号",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "trade_no",
          label: "订单编号",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "入账时间",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "source",
          label: "业务来源",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "type",
          label: "类型",
          width: "140",
        },
        {
          fixedstatu: false,
          prop: "pay_uid",
          label: "支付用户ID",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "nickname",
          label: "用户昵称",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "is_in",
          label: "收支类型",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "price",
          label: "金额",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "credit",
          label: "账户余额",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "paytype",
          label: "支付方式",
          width: "",
        },
        {
          fixedstatu: "right",
          prop: "thirdparty",
          label: "平台来源",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1000, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      Layout: " sizes, prev, pager, next, jumper",

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "订单编号：",
            placeholder: "请输入订单编号",
            name: "trade_no",
            content: "",
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "支付用户ID：",
            placeholder: "请输入支付用户ID",
            name: "pay_uid",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "收支类型：",
            placeholder: "全部",
            name: "is_in",
            value: "",
            options: [
              {
                value: "",
                label: "全部",
              },
              {
                value: "1",
                label: "支出",
              },
              {
                value: "2",
                label: "收入",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "业务来源:",
            onchange: 1,
            placeholder: "全部",
            name: "source",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "类型:",
            placeholder: "全部",
            name: "type",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "支付方式:",
            placeholder: "全部",
            name: "paytype",
            value: "",
            options: [
              {
                label: "全部",
                value: "",
              },
              {
                label: "余额",
                value: "1",
              },
              {
                label: "微信",
                value: "2",
              },
              {
                label: "支付宝",
                value: "3",
              },
              {
                label: "余额代付",
                value: "5",
              },
              {
                label: "现金",
                value: "6",
              },
              {
                label: "其他",
                value: "4",
              },
            ],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "入账时间范围区间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "addtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
      myinputonefullgao: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%", //input宽度
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "设备编号：",
            placeholder: "请输入设备编号", //此name为键值
            name: "client_id",
            content: "",
            id: "", //此id为给后台的id值
            url: "/admin/getClientList",
            inputval: { client: "" }, //这里记录需要传的参数
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "代理商名：",
            placeholder: "请输入代理商名称",
            name: "shop_id", //此name为键值
            content: "",
            id: "", //此id为给后台的id值
            url: "/admin/shopUser/getShopList",
            inputval: { name: "" }, //这里记录需要传的参数
          },
        ],
      },

      // 平台来源下拉
      platforms: componentProperties.get('thirdpartyoption').options,

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",

      // 【充扣款】弹窗相关数据
      dialogVisible: ref(false), //弹窗显示&隐藏
      ruleForm: {
        resource: ref("1"),
        money: "",
        shop_id: "", // 代理商id
        phone: "",
        nickname: "",
        desc: "",
      }, //弹窗中-form表单
      rules: {
        resource: [
          {
            required: true,
            message: "请至少选择一个类型",
            trigger: "blur",
          },
        ],
        money: [
          {
            required: true,
            message: "金额为必填项",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "手机号为必填项",
            trigger: "blur",
          },
          {
            validator: checkPhone,
            trigger: "change",
          }
        ],
        nickname: [
          {
            required: false,
            message: "代理商名称为必填项",
            trigger: "blur",
          },
        ],
        desc: [
          {
            required: false,
            message: "请填写备注",
            trigger: "blur",
          },
        ],
      }, //弹窗中-form表单-必填规则
      phonenumoptions: [], // 手机号下拉数据

      nickname: "输入手机号自动获取代理商名称",

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用

      getSource: [], //获取当前页面-来源数据（用于list表-渲染）
      getType: [], //获取当前页面-类型数据（用于list表-渲染）
      getpaytype: componentProperties.get('financeoption').paytype,

      mydisabled: false,
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 【充扣款】-输入手机号获取下拉
    changePhonenumEvent(data) {
      console.log(data)
      if (data.length == 11) {
        // 通过手机号-获取相关代理商信息
        axios
          .post(
            "/admin/shopUser/getShopList",
            {
              username: data,
              no_delete: 1, //有此参数名，表示只搜索状态不是删除的代理商
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            let getdatas = response.data.result.data
            if (getdatas.length > 0) {
              this.ruleForm.shop_id = getdatas[0].id;
              this.nickname = getdatas[0].name;
            } else {
              this.ruleForm.shop_id = '';
              this.nickname = "未获取到该手机号绑定的代理商";
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error);
          });
      } else {
        this.nickname = "请输入正确的手机号";
      }
    },
    //【充扣款】点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      // console.log("当前获取的taninfo is :" + JSON.stringify(taninfo));
      this.ruleForm.id = taninfo.id;
      this.ruleForm.thirdparty = taninfo.thirdparty;
      this.ruleForm.phone = taninfo.mobile;
      this.ruleForm.nickname = taninfo.nickname;
    },
    // 【充扣款】弹出框-提交表单
    submitForm(formName) {
      // 前端防抖
      this.mydisabled = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var getdatas = this.$refs[formName].model;
          axios
            .post(
              "/admin/shop/shopAction",
              {
                type: getdatas.resource,
                price: getdatas.money,
                shop_id: getdatas.shop_id,
                reamrk: getdatas.desc
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = ref(false); //关闭弹窗
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                    this.mydisabled = false;
                  },
                });
              } else {
                this.mydisabled = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              this.mydisabled = false;
            });
        } else {
          console.log("error submit!!");
          this.mydisabled = false;
          return false;
        }
      });
    },
    // 【充扣款】弹出框-重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();

      this.nickname = "输入手机号自动获取代理商名称";
    },
    // 【充扣款】弹窗-关闭
    handleCloseSenior(done) {
      done();
    },

    // 点击【业务来源】切换【类型】下拉框
    changeSourceEvent(datakey, flag) {
      if (datakey == 0) {
        this.getconfiginfotype(this.getType);
        // flag标识为1的自动填充搜索框中的值，2标识填充高级筛选中的搜索框值
        if (flag == 1) {
          this.maintwoforminfo.selectleft.select[1].value =
            this.maintwoforminfo.selectleft.select[1].options[0].value;
        } else {
          this.myselect.select[2].value =
            this.myselect.select[2].options[0].value;
        }
      } else {
        let sourcelist = this.source_list[datakey][datakey];
        let data = {};
        for (let ikey in sourcelist) {
          for (var jkey in this.getType) {
            if (jkey == sourcelist[ikey]) {
              data[jkey] = this.getType[jkey];
            }
          }
        }
        this.getconfiginfotype(data, 1);
        // flag标识为1的自动填充搜索框中的值，2标识填充高级筛选中的搜索框值
        if (flag == 1) {
          this.maintwoforminfo.selectleft.select[1].value =
            this.maintwoforminfo.selectleft.select[1].options[0].value;
        } else {
          this.myselect.select[2].value =
            this.myselect.select[2].options[0].value;
        }
      }
    },
    // 【搜索框】接收select选择的返回值-并进行函数操作
    selectDatainfoEvent(datas) {
      if (datas.name == "source") {
        this.changeSourceEvent(datas.changeinfo.value, 1);
      }
    },
    // 【高级筛选】接收select选择的返回值-并进行函数操作
    selectDatainfoGaoEvent(datas) {
      if (datas.name == "source") {
        this.changeSourceEvent(datas.changeinfo.value, 2);
      }
    },
    // 点击【搜索框】中【重置】按钮-触发事件
    resetdatainfoEvent(data) {
      console.log(data);
      this.getconfiginfotype(this.getType);
    },
    // 点击【高级搜索】中【重置】按钮-触发事件
    resetdatainfogaoEvent(data) {
      console.log(data);
      this.getconfiginfotype(this.getType);
    },

    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/shop/billInfo",
          {
            shop_id: this.pagesdatas.shop_id,
            client_id: this.pagesdatas.client_id,
            trade_no: this.pagesdatas.trade_no,
            pay_uid: this.pagesdatas.pay_uid,
            source: this.pagesdatas.source,
            type: this.pagesdatas.type,
            is_in: this.pagesdatas.is_in,
            paytype: this.pagesdatas.paytype,
            addtime: this.pagesdatas.addtime,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / this.PageSize == this.lastpage) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage += 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/shop/billInfo",
          {
            shop_id: this.pagesdatas.shop_id,
            client_id: this.pagesdatas.client_id,
            trade_no: this.pagesdatas.trade_no,
            pay_uid: this.pagesdatas.pay_uid,
            source: this.pagesdatas.source,
            type: this.pagesdatas.type,
            is_in: this.pagesdatas.is_in,
            paytype: this.pagesdatas.paytype,
            addtime: this.pagesdatas.addtime,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / val == this.PageSize) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage = 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 跳转-代理商列表
        if (routeraddr == "/agentlist") {
          this.$router
            .push({
              path: routeraddr,
              query: { agentname: rowdata.name },
            })
            .catch((error) => error);
        }
        // 跳转-用户列表
        if (routeraddr == "/userlist") {
          this.$router
            .push({
              path: routeraddr,
              query: { userid: rowdata.pay_uid, thirdparty: rowdata.thirdparty },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
      axios
        .post(
          "/admin/shop/billInfo",
          {
            shop_id: this.pagesdatas.shop_id,
            client_id: this.pagesdatas.client_id,
            trade_no: this.pagesdatas.trade_no,
            pay_uid: this.pagesdatas.pay_uid,
            source: this.pagesdatas.source,
            type: this.pagesdatas.type,
            is_in: this.pagesdatas.is_in,
            paytype: this.pagesdatas.paytype,
            addtime: this.pagesdatas.addtime,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message)
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      var getaddtime = "";
      if (data.addtime.start === "") {
        getaddtime = "";
      } else {
        if (data.addtime.end === "") {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.addtime = getaddtime;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/shop/billInfo",
          {
            shop_id: data.shop_id,
            client_id: data.client_id,
            trade_no: data.trade_no,
            pay_uid: data.pay_uid,
            source: data.source,
            type: data.type,
            is_in: data.is_in,
            paytype: data.paytype,
            addtime: getaddtime,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/shop/billInfo",
          {
            shop_id: data.shop_id,
            client_id: data.client_id,
            source: data.source,
            type: data.type,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata, flag) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        // 业务来源-匹配
        if (newdata[i].source) {
          newdata[i].source = this.getSource[newdata[i].source];
        } else {
          newdata[i].source = "";
        }
        // 类型-匹配
        if (newdata[i].type) {
          newdata[i].type = this.getType[newdata[i].type];
        } else {
          newdata[i].type = "";
        }
        //渲染-收支类型
        if (newdata[i].is_in) {
          if (newdata[i].is_in === 1) {
            newdata[i].is_in = "支出 -";
          }
          if (newdata[i].is_in === 2) {
            newdata[i].is_in = "收入 +";
          }
        }

        //转换-入账时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "--";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "--";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      // this.totalCount = successdata.total;
      // this.currentpage = successdata.current_page; //当前页面页数
      // this.lastpage = successdata.last_page;
      this.loading = false;

      // 数据小于当前页数时判断
      if (newdata.length < this.PageSize) {
        if (newdata.length != 0 && this.currentPage == 1) {
          this.totalCount = this.PageSize - 1;
        } else {
          if (newdata.length == 0) {
            // 按照常理：为0会减1页，由于双向绑定问题一直请求服务器，因此先注释，待后续优化
            // this.totalCount = this.PageSize * (this.currentPage - 1)
            this.totalCount = this.PageSize * this.currentPage;
          } else {
            this.totalCount = this.PageSize * this.currentPage;
            this.lastpage = this.currentPage;
          }
        }
      } else {
        if (this.totalCount < this.PageSize) {
          // 重设置-初始值
          this.totalCount = 1000;
          this.Layout = "sizes, prev, pager, next, jumper";
        } else {
          if (flag == 1) {
            this.currentPage = 1;
          }
        }
      }
    },
    //获取-配置-业务来源
    getconfiginfo(configinfo) {
      // console.log(configinfo);
      //将alldatas的数据存储在getSource数据中，用于渲染
      this.getSource = configinfo;
      //获取api-加载来源信息（根据API返回的json数据结构不同，函数方法不同！）
      var alldatas = Array();
      var num = 0;
      alldatas[num] = { value: num, label: "全部 " }; // 这里使用空格，区分字段名称
      for (var key in configinfo) {
        num = num + 1;
        alldatas[num] = { value: key, label: configinfo[key] };
      }
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.maintwoforminfo.selectleft.select[0].options = alldatas;
      this.myselect.select[1].options = alldatas;
    },
    //获取-配置-类型
    getconfiginfotype(configinfo, flag) {
      // flag标识不为1的改变this.getType的值
      if (flag != 1) {
        //将alldatas的数据存储在getType数据中，用于渲染
        this.getType = configinfo;
      }
      //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
      var alldatas = Array();
      var num = 0;
      alldatas[num] = { value: num, label: "全部" };
      for (var key in configinfo) {
        num = num + 1;
        alldatas[num] = { value: key, label: configinfo[key] };
      }
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.maintwoforminfo.selectleft.select[1].options = alldatas;
      this.myselect.select[2].options = alldatas;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //获取来源-配置
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "cashflow_shop_source",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.getconfiginfo(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      //获取类型 -配置
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "cashflow_shop_type",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.getconfiginfotype(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 定义
      this.pagesdatas = {
        page_size: this.PageSize,
        page: 1,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.shopid) {
        this.pagesdatas.shop_id = routerinfo.shopid;
        this.maintwoforminfo.myinputonefull.input[1].id = routerinfo.shopid;
        this.maintwoforminfo.myinputonefull.input[1].content = routerinfo.name;
      }
      if (routerinfo.source) {
        this.pagesdatas.source = routerinfo.source;
        this.maintwoforminfo.selectleft.select[0].value = routerinfo.source;
      }
      if (routerinfo.addtime) {
        this.pagesdatas.addtime = routerinfo.addtime;
      }

      //通过Ajax向后台获取数据
      axios
        .post("/admin/shop/billInfo", this.pagesdatas, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}

.tan-box {
  max-height: 450px;
  overflow: hidden;
  overflow-y: visible;
  margin: 20px 5% 0 0;
}

.box-list {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  line-height: 40px;
  padding: 0 20px;

}
</style>