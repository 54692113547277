<template>
    <!--上方字：单个input-->
    <div v-if="myinPutishow">
        <div v-for="(myformitem, i) in myinPut.input" :key="i" class="input_dan" :style="{ width: myinPut.inputwidth, 'margin-right': myinPut.divinterval }">
            <span style="color: #333333;font-size: 14px;">
                <span style="color: red;" v-if="myformitem.ismust">*</span>
                {{ myformitem.title }}
            </span>
            <div :style="{ 'max-width': myinPut.inputmaxwidth }">
                <el-input
                    v-model="myformitem.content"
                    :placeholder="myformitem.placeholder"
                    :type="myformitem.textarea"
                    :rows="myformitem.textareaheight"
                    clearable
                    :disabled="myformitem.disabled"
                ></el-input>
            </div>
        </div>
    </div>
    <!--上方字：单个input(float:left)-->
    <div v-if="myinPutLeftishow">
        <div v-for="(myformitem, i) in myinPutLeft.input" :key="i" class="input_dan" :style="{ width: myinPutLeft.inputwidth }" style="float: left;max-width: 420px">
            <span style="color: #616e9d;font-size: 14px;">
                <span style="color: red" v-if="myformitem.ismust">*</span>
                {{ myformitem.title }}
            </span>
            <el-input v-model="myformitem.content" :placeholder="myformitem.placeholder" :type="myformitem.textarea" :rows="myformitem.textareaheight" clearable></el-input>
            <div>
                <span style="font-size: 12px">{{ myformitem.tips }}</span>
            </div>
        </div>
    </div>
    <!--上方字：多个input+插入字-->
    <div v-if="myinPutsizeshow">
        <div class="input_dan">
            <span style="color: #616e9d;font-size: 14px;">
                <span style="color: red" v-if="myinPutsize.ismust">*</span>
                {{ myinPutsize.title }}
            </span>
            <div>
                <div v-for="(myformitem, l) in myinPutsize.input" :key="l" :style="{ 'padding-bottom': myinPutsize.paddingbottom, float: 'left', 'padding-right': '20px' }">
                    <div v-if="myinPutsize.textaddr === 'left'">
                        <div v-if="myformitem.textisshow" style="float: left;margin-right: 20px">
                            <span style="color: #616e9d;font-size: 14px;">{{ myformitem.text }}</span>
                        </div>
                        <div style="float: left;width: 400px">
                            <el-input v-model="myformitem.content" :placeholder="myformitem.placeholder" clearable></el-input>
                        </div>
                    </div>
                    <div v-if="myinPutsize.textaddr === 'right'">
                        <div style="float: left;margin-right: 20px;width: 400px">
                            <el-input v-model="myformitem.content" :placeholder="myformitem.placeholder" clearable></el-input>
                        </div>
                        <div v-if="myformitem.textisshow" style="float: left;">
                            <span style="color: #616e9d;font-size: 14px;">{{ myformitem.text }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--上方字：单个input+自动搜索建议-->
    <div v-if="myinPutoneFullgaoisshow">
        <div
            v-for="(myformitemgao, p) in myinPutoneFullgao.input"
            :key="p"
            class="input_dan"
            :style="{ width: myinPutoneFullgao.inputwidth, 'margin-right': myinPutoneFullgao.divinterval }"
        >
            <span style="color: #333333;font-size: 14px;">
                <span style="color: red" v-if="myformitemgao.ismust">*</span>
                {{ myformitemgao.title }}
            </span>
            <div :style="{ 'max-width': myinPutoneFullgao.inputmaxwidth }">
                <el-autocomplete
                    style="width: 100%;"
                    v-model="myformitemgao.content"
                    :fetch-suggestions="querySearch"
                    :trigger-on-focus="true"
                    class="inline-input"
                    debounce="300"
                    :placeholder="myformitemgao.placeholder"
                    @select="handleSelectgao"
                    @focus="getFocusgao(p)"
                    clearable
                />
            </div>
        </div>
    </div>
    <!--左侧字：单个input-->
    <div v-if="myinPutoneisshow">
        <div v-for="(myformitem, j) in myinPutone.input" :key="j" class="input_dan" :style="{ 'margin-right': myinPutone.divinterval, width: myinPutone.width }">
            <div class="input_float" :style="{ width: myinPutone.titlewidth }">
                <span style="color: #333333;font-size: 14px;">
                    <span style="color: red" v-if="myformitem.ismust">*</span>
                    {{ myformitem.title }}
                </span>
            </div>
            <div class="input_float" :style="{ width: myinPutone.inputwidth, 'margin-right': myinPutone.inputinterval }">
                <el-input
                    v-model="myformitem.content"
                    :placeholder="myformitem.placeholder"
                    clearable
                    :disabled="myformitem.disabled"
                    :type="myformitem.textarea"
                    :rows="myformitem.textareaheight"
                    :class="myformitem.class ? myformitem.class : 'input-post'"
                ></el-input>
                <span style="font-size: 12px;margin-right: 10px;color:#616e9d">{{ myformitem.tips }}</span>
                <span :style="{ color: myformitem.color }" style="font-size: 12px">{{ myformitem.colortips }}</span>
            </div>
        </div>
    </div>
    <!--左侧字：单个input+单个button-->
    <div v-if="myinPutbuttonisshow">
        <div v-for="(myformitem, j) in myinPutbutton.input" :key="j" class="input_dan" :style="{ 'margin-right': myinPutbutton.divinterval }">
            <div class="input_float" :style="{ width: myinPutbutton.titlewidth }">
                <span style="color: #616e9d;font-size: 14px;">
                    <span style="color: red" v-if="myformitem.ismust">*</span>
                    {{ myformitem.title }}
                </span>
            </div>
            <div class="input_float" :style="{ width: myinPutbutton.inputwidth, 'margin-right': myinPutbutton.inputinterval }">
                <el-input v-model="myformitem.content" :placeholder="myformitem.placeholder" clearable></el-input>
            </div>
            <div class="input_float" v-if="myformitem.buttonisshow">
                <el-button style="padding: 10px !important;border-radius: 8px">{{ myformitem.buttonname }}</el-button>
            </div>
        </div>
    </div>
    <!--左侧字：多个input-->
    <div v-if="myinPutmoreisshow">
        <div class="input_dan">
            <div class="input_float" :style="{ width: myinPutmore.titlewidth }">
                <span style="color: #616e9d;font-size: 14px;">
                    <span style="color: red" v-if="myinPutmore.ismust">*</span>
                    {{ myinPutmore.title }}
                </span>
            </div>
            <div class="input_float">
                <div v-for="(myformitem, k) in myinPutmore.input" :key="k" :style="{ width: myinPutmore.inputwidth, 'padding-bottom': myinPutmore.paddingbottom }">
                    <el-input v-model="myformitem.content" :placeholder="myformitem.placeholder" clearable></el-input>
                </div>
            </div>
        </div>
    </div>
    <!--左侧字：单个input+自动搜索建议-->
    <div v-if="myinPutoneFullisshow">
        <div v-for="(myformitem, j) in myinPutoneFull.input" :key="j" class="input_dan" :style="{ 'margin-right': myinPutoneFull.divinterval }">
            <div class="input_float" :style="{ width: myinPutoneFull.titlewidth }">
                <span style="color: #333333;font-size: 14px;">
                    <span style="color: red" v-if="myformitem.ismust">*</span>
                    {{ myformitem.title }}
                </span>
            </div>
            <div class="input_float" :style="{ width: myinPutoneFull.inputwidth, 'margin-right': myinPutoneFull.inputinterval }">
                <el-autocomplete
                    style="width: 100%;"
                    v-model="myformitem.content"
                    :fetch-suggestions="querySearch"
                    :trigger-on-focus="true"
                    class="inline-input"
                    debounce="300"
                    :placeholder="myformitem.placeholder"
                    @select="handleSelect"
                    @focus="getFocus(j)"
                    clearable
                />
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import axios from 'axios'

export default defineComponent({
    name: 'Maininput',
    data() {
        return {
            //测试数据
            inputishow: false,
            inputsizeshow: false,
            inputbuttonisshow: false,
            inputmoreisshow: false,
            input: {
                inputwidth: '400px',
                input: [
                    {
                        ismust: true, //是否必填，true表示必填
                        title: '手机号：',
                        placeholder: '用于登录系统用户名',
                        name: 'phonenum',
                        content: ''
                    },
                    {
                        ismust: true,
                        title: '姓名：',
                        name: 'agentname',
                        content: ''
                    },
                    {
                        ismust: true,
                        title: '默认佣金比例：',
                        name: 'commission',
                        content: ''
                    }
                ]
            },
            inputsize: {
                textaddr: 'right', //插入文字的位置
                ismust: true, //是否必填，true表示必填
                title: '手机号：',
                inputwidth: '400px',
                paddingbottom: '25px',
                input: [
                    {
                        placeholder: '用于登录系统用户名',
                        name: 'phonenum',
                        content: '',
                        textisshow: true, //true表示显示文字
                        text: '台/天'
                    },
                    {
                        placeholder: '用于登录系统用户名',
                        name: 'phonenum',
                        content: ''
                    }
                ]
            },
            inputbutton: {
                divinterval: '120px', //框右侧距离
                titlewidth: '160px', //标题宽度
                inputwidth: '400px', //input宽度
                inputinterval: '30px', //input右侧距离
                input: [
                    {
                        ismust: true, //是否必填，true表示必填
                        title: '手机号：',
                        placeholder: '用于登录系统用户名',
                        name: 'phonenum',
                        content: '',
                        buttonisshow: true,
                        buttonname: '手动修改'
                    },
                    {
                        ismust: true,
                        title: '姓名：',
                        name: 'agentname',
                        content: ''
                    },
                    {
                        ismust: true,
                        title: '默认佣金比例：',
                        name: 'commission',
                        content: ''
                    }
                ]
            },
            inputmore: {
                ismust: true, //是否必填，true表示必填
                title: '设备定位地址：',
                titlewidth: '160px',
                inputwidth: '400px',
                paddingbottom: '25px',
                input: [
                    {
                        placeholder: '用于登录系统用户名',
                        name: 'phonenum',
                        content: ''
                    },
                    {
                        placeholder: '用于登录系统用户名',
                        name: 'phonenum',
                        content: ''
                    }
                ]
            }
        }
    },
    props: [
        'myinputishow',
        'myinput',
        'myinputleftishow',
        'myinputleft',
        'myinputsizeshow',
        'myinputsize',
        'myinputoneisshow',
        'myinputone',
        'myinputbuttonisshow',
        'myinputbutton',
        'myinputmoreisshow',
        'myinputmore',
        'myinputonefullisshow',
        'myinputonefull',
        'myinputonefullgaoisshow',
        'myinputonefullgao'
    ],
    setup(props) {
        const myinPutishow = reactive(props['myinputishow'])
        const myinPut = reactive(props['myinput'])
        const myinPutLeftishow = reactive(props['myinputleftishow'])
        const myinPutLeft = reactive(props['myinputleft'])
        const myinPutsizeshow = reactive(props['myinputsizeshow'])
        const myinPutsize = reactive(props['myinputsize'])
        const myinPutoneisshow = reactive(props['myinputoneisshow'])
        const myinPutone = reactive(props['myinputone'])
        const myinPutbuttonisshow = reactive(props['myinputbuttonisshow'])
        const myinPutbutton = reactive(props['myinputbutton'])
        const myinPutmoreisshow = reactive(props['myinputmoreisshow'])
        const myinPutmore = reactive(props['myinputmore'])
        const myinPutoneFullisshow = reactive(props['myinputonefullisshow'])
        const myinPutoneFull = reactive(props['myinputonefull'])
        const myinPutoneFullgaoisshow = reactive(props['myinputonefullgaoisshow'])
        const myinPutoneFullgao = reactive(props['myinputonefullgao'])

        //单个input+自动搜索建议-（左侧字）
        const restaurants = ref([])
        const querySearch = (queryString, cb) => {
            let mykey = Object.keys(loadAll.inputdatas).splice(',')
            loadAll.inputdatas[mykey[0]] = queryString //Object.keys(loadAll.inputdatas) 获取字段名
            getAnswer(loadAll.posttype, loadAll.url, loadAll.token, cb)
        }
        const createFilter = (queryString) => {
            console.log(queryString)
        }
        const getFocus = (ide) => {
            //myinPutoneFull中url排序
            loadAll.url = myinPutoneFull.input[ide].url
            loadAll.inputdatas = myinPutoneFull.input[ide].inputval
            loadAll.posttype = myinPutoneFull.input[ide].posttype
        }
        const getFocusgao = (ide) => {
            //myinPutoneFull中url排序
            loadAll.url = myinPutoneFullgao.input[ide].url
            loadAll.inputdatas = myinPutoneFullgao.input[ide].inputval
            loadAll.posttype = myinPutoneFullgao.input[ide].posttype
        }
        let loadAll = reactive({
            //定义数据数组等
            myvalue: [],
            url: '',
            token: localStorage.getItem('token'),
            inputdatas: {},
            posttype: ''
        })
        const handleSelect = (item) => {
            //select选中之后运行
            for (var i = 0; i < myinPutoneFull.input.length; i++) {
                if (myinPutoneFull.input[i].content === item.value) {
                    myinPutoneFull.input[i].id = item.id
                }
            }
        }
        const handleSelectgao = (item) => {
            //select选中之后运行
            for (var i = 0; i < myinPutoneFullgao.input.length; i++) {
                if (myinPutoneFullgao.input[i].content === item.value) {
                    myinPutoneFullgao.input[i].id = item.id
                }
            }
        }
        const getAnswer = (posttype, url, token, cb) => {
            //通过axios获取筛选的数据
            if (url) {
                if (posttype == 'get') {
                    axios
                        .get(url, {
                            headers: { Authentication: token },
                            params: loadAll.inputdatas
                        })
                        .then(function(response) {
                            loadAll.myvalue = response.data.result.data
                            for (var i = 0; i < loadAll.myvalue.length; i++) {
                                let mykey = Object.keys(loadAll.inputdatas).splice(',')
                                loadAll.myvalue[i]['value'] = loadAll.myvalue[i][mykey[0]] //Object.keys(loadAll.inputdatas)表示获取该对象的键值
                                loadAll.myvalue[i]['link'] = loadAll.myvalue[i].id

                                // console.log(mykey)
                            }
                            restaurants.value = loadAll.myvalue //将查询的数据返回给restaurants.value
                            if (cb) {
                                //当传入cb为真时，运行下方代码
                                cb(restaurants.value) //通过输入搜索返回的数据
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    axios
                        .post(url, loadAll.inputdatas, {
                            headers: {
                                Authentication: token
                            }
                        })
                        .then(function(response) {
                            loadAll.myvalue = response.data.result.data
                            for (var i = 0; i < loadAll.myvalue.length; i++) {
                                let mykey = Object.keys(loadAll.inputdatas).splice(',')
                                loadAll.myvalue[i]['value'] = loadAll.myvalue[i][mykey[0]] //Object.keys(loadAll.inputdatas)表示获取该对象的键值
                                loadAll.myvalue[i]['link'] = loadAll.myvalue[i].id
                            }
                            restaurants.value = loadAll.myvalue //将查询的数据返回给restaurants.value
                            if (cb) {
                                //当传入cb为真时，运行下方代码
                                cb(restaurants.value) //通过输入搜索返回的数据
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                }
            }
        }
        onMounted(() => {
            getAnswer(loadAll.posttype, loadAll.url, loadAll.token)
        })

        return {
            myinPutishow,
            myinPut,
            myinPutLeftishow,
            myinPutLeft,
            myinPutsizeshow,
            myinPutsize,
            myinPutoneisshow,
            myinPutone,
            myinPutbuttonisshow,
            myinPutbutton,
            myinPutmoreisshow,
            myinPutmore,
            myinPutoneFullisshow,
            myinPutoneFull,
            myinPutoneFullgaoisshow,
            myinPutoneFullgao,

            restaurants,
            querySearch,
            createFilter,
            loadAll,
            handleSelect,
            getAnswer,
            getFocus,
            getFocusgao,
            handleSelectgao
        }
    },
    mounted() {},
    methods: {}
})
</script>

<style scoped>
.input_dan {
    float: left;
    /*margin-right: 80px;*/
    /*margin-bottom: 0px;*/
}

/*!*穿透：改变边框样式*!*/
/*.input_dan :deep(.el-input__inner), .input_dan :deep(.el-textarea__inner) {*/
/*  border-radius: 8px;*/
/*  background-color: #FAFCFE;*/
/*  border: 1px solid #94a3d2;*/
/*  color: #616e9d;*/
/*  --el-input-placeholder-color: #94a3d2;*/
/*}*/

.input_float {
    float: left;
    /*padding: 5px 0;*/
}
</style>
