<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div class="box-list-line">
                    <div><span>数据列表</span></div>
                </div>
                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <el-table-column type="index" width="65" label="序号" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <template #default="scope" v-if="mytabletitle.prop == 'status' || mytabletitle.prop == 'uptime' || mytabletitle.prop == 'jump_type'">
                                <div v-if="mytabletitle.prop == 'status'" id="switch">
                                    <el-switch
                                        v-model="scope.row.status"
                                        active-text="开启"
                                        inactive-text="关闭"
                                        :active-value="1"
                                        :inactive-value="-1"
                                        active-color="#13ce66"
                                        inactive-color="#cbcbcb"
                                        :disabled="!this.mypowerlimits.hfxxpz_qy"
                                        @click="this.mypowerlimits.hfxxpz_qy ? changeStatus(scope.row) : ''"
                                    >
                                    </el-switch>
                                </div>
                                <div v-if="mytabletitle.prop == 'jump_type'">
                                    <span>{{ this.jumpTypeOption[scope.row.jump_type] }}</span>
                                </div>
                                <div v-if="mytabletitle.prop == 'uptime'">
                                    <span> {{ scope.row.uptime !== 0 && scope.row.uptime !== null ? this.$utils.formatDate(scope.row.uptime) : '' }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" prop="myoperation" id="operation">
                            <template #default="scope">
                                <div>
                                    <el-button v-if="this.mypowerlimits.hfxxpz_bj" type="primary" size="mini" @click="addOrEditEvent(2, scope.row)">编辑</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <!-- 抽屉 -->
                <div id="mysections">
                    <el-drawer :title="tanFlagOption[tanFlag]" v-model="drawer" direction="rtl" size="80%">
                        <backNewsSetAddOrEdit
                            :activeInfoOption="activeInfoOption"
                            :formData="formData"
                            :officeConfigOption="officeConfigOption"
                            @request="
                                (data) => {
                                    this.drawer = data
                                }
                            "
                        />
                    </el-drawer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import backNewsSetAddOrEdit from './backNewsSetAddOrEdit.vue'
import dict from '@/assets/js/dict.js'

export default defineComponent({
    name: 'vbackNewsSet',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '公众号'
                },
                {
                    name: '回复消息配置'
                }
            ], // 当前页-【面包屑导航】
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 27, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'office_name',
                    label: '公众号名称',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'system_content.keyword1',
                    label: '标题',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'jump_type',
                    label: '模板跳转类型',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'truename',
                    label: '操作人',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'uptime',
                    label: '操作时间',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreetableData: [], // 数据列表中-具体数据

            // 跳转类型
            jumpTypeOption: dict.back_news_set.jump_type,

            // 抽屉options
            tanFlagOption: { 1: '添加', 2: '编辑' },
            tanFlag: '',
            drawer: false,
            activeInfoOption: {},
            formData: {},
            officeConfigOption: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        // 状态切换
        changeStatus(data) {
            axios
                .post(
                    '/admin/office/changeReplyMsgStatus',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message,
                            duration: 1000,
                            onClose: () => {
                                this.postAxiosEvent()
                            }
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 编辑-获取当前页面详情
        getAxiosInfoEvent(formID) {
            axios
                .post(
                    '/admin/office/replyMsgview',
                    {
                        id: formID
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result

                    this.activeInfoOption = getdatas.system_content.front_field
                    this.formData = {
                        id: getdatas.id,
                        office_config_id: getdatas.office_config_id,
                        template_id: getdatas.template_id,
                        keyword4: getdatas.keyword4,
                        jump_type: getdatas.jump_type.toString(),
                        jump_content: getdatas.jump_content,
                        status: getdatas.status.toString()
                    }
                })
                .catch((error) => error)
        },
        // 编辑-获取公众号名称下拉
        getPubNumerInfoEvent() {
            axios
                .get('/admin/office/configIndex', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.officeConfigOption = response.data.result
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 添加&编辑
        addOrEditEvent(flag, data) {
            this.drawer = true
            this.tanFlag = flag
            this.getPubNumerInfoEvent()
            if (flag == 2) this.getAxiosInfoEvent(data.id)
        },
        // 接口
        postAxiosEvent(data) {
            this.loading = true
            axios
                .post(
                    '/admin/office/replyMsgIndex',
                    { page: 1, page_size: 10000 },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.mainthreetableData = response.data.result.data
                    this.loading = false
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.postAxiosEvent()
        }
    },
    components: {
        mainone,
        backNewsSetAddOrEdit
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.tool-tip {
    max-width: 400px;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 20px;
    font-size: 14px;
}

.box-list-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
    gap: 20px;
}

/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
}
</style>
