<template>
    <div id="mymain">
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <!--          <div class="box_title_font" style="color: #00da82;"><i class="el-icon-success"></i> 当前订单状态 : 已完成</div>-->
                    <div class="box_title_font"><i class="el-icon-info"></i> 退款详情</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <div>
                            <el-button v-if="this.chuliinfo.status == -1" type="primary" @click="addTanClick(this.chuliinfo, (dialogVisible = true))">处理 </el-button>
                            <el-button type="primary" plain @click="nextClickEvent(this.$route.query.orderid)">下一条</el-button>
                        </div>
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <div style="margin-top: 20px">
                        <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px">
                            <template #contentJumpbt="{ detailsdatainfo }">
                                <div v-if="this.printstatus == 1" style="color: #465eff; cursor: pointer" @click="addTanClick(getrowdata, (dialogVisible1 = true))">
                                    {{ detailsdatainfo.jumpbt }}
                                </div>
                                <div v-if="this.printstatus == 2">
                                    {{ detailsdatainfo.jumpbt }}
                                </div>
                            </template>
                        </maindetails>
                    </div>
                    <div class="flex-box-item flex-box" v-if="this.print_type == 2">
                        <div style="min-width: 100px; text-align: right">
                            <span>证件照电子版:</span>
                        </div>
                        <div>
                            <mainimgesshow :imgesdata="mainpagedata.imgesdatacredit"></mainimgesshow>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details1"></maindetails>
                    <div class="flex-box-item flex-box">
                        <div style="min-width: 100px; text-align: right">
                            <span>反馈图片:</span>
                        </div>
                        <div>
                            <mainimgesshow :imgesdata="mainpagedata.imgesdata"></mainimgesshow>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <div>
                        <span style="font-size: 14px; font-weight: 600; margin-bottom: 20px">处理信息</span>
                        <div class="details-box" v-for="(item, index) in mainpagedata.details2" :key="index">
                            <maindetails :detailsdata="item"></maindetails>
                            <div class="flex-box-item flex-box">
                                <div style="min-width: 100px; text-align: right">
                                    <span>回复图片:</span>
                                </div>
                                <div>
                                    <mainimgesshow :imgesdata="item.imgesdatareply"></mainimgesshow>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--弹出框-处理-->
        <el-dialog v-model="dialogVisible" title="处理" :width="this.searchwidth" :before-close="handleCloseSenior">
            <div>
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
                    <div style="min-height: 400px; overflow: hidden; overflow-y: visible; margin: 20px 5% 0 0">
                        <el-form-item label="退款张数：" prop="number">
                            <el-input v-model.number="ruleForm.number" :max="ruleForm.refund_pages" step="1" clearable>
                                <template #append>张</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="申请退款金额：">
                            <div class="money_box">
                                <div class="money_box_content" v-if="ruleForm.number == ruleForm.refund_pages">
                                    ¥
                                    {{ ruleForm.lastmoney }}
                                </div>
                                <div class="money_box_content" v-else>
                                    ¥
                                    {{ ruleForm.number * ruleForm.price > ruleForm.lastmoney ? ruleForm.lastmoney : (ruleForm.number * ruleForm.price).toFixed(2) }}
                                </div>
                                <div class="money_box_content">(剩余最多退款金额 ¥ {{ ruleForm.lastmoney }})</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="选择处理方式：" prop="type">
                            <el-radio-group v-model="ruleForm.type">
                                <el-radio :label="2">驳回</el-radio>
                                <el-radio :label="1">同意退款</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="回复内容：" prop="remark">
                            <el-input v-model="ruleForm.remark" type="textarea" minlength="10" maxlength="50" show-word-limit :autosize="{ minRows: 2 }" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="留言图片(选填)：">
                            <mainimgesup
                                ref="myimges"
                                v-model="ruleForm.reply_img"
                                :myimgesup="myimgesup"
                                :myimgesupisshow="myimgesupisshow"
                                :filelist="ruleForm.reply_img_list"
                                v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                v-on:deluploadfile="(data) => deluploadfileEvent(data)"
                                v-on:getfiletype="getfiletypeEvent"
                                @click="changeMainimgesupEvent(this.changeMainimgesup)"
                            >
                            </mainimgesup>
                        </el-form-item>
                        <el-form-item label="处理备注(仅后台可见)：" prop="admin_remark">
                            <el-input v-model="ruleForm.admin_remark" type="textarea" maxlength="200" show-word-limit :autosize="{ minRows: 2 }" clearable></el-input>
                        </el-form-item>
                        <el-form-item style="text-align: right">
                            <el-button @click="resetForm('ruleForm')">重置</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm', ruleForm.type)" :disabled="mydisabled">确认 </el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </el-dialog>

        <!--弹出框-打印记录-->
        <el-dialog v-model="dialogVisible1" title="打印记录" :width="this.searchwidth" :before-close="handleCloseSenior">
            <div>
                <el-table :data="formdata" style="width: 100%" border>
                    <el-table-column label="文档信息" prop="file">
                        <template #default="scope">
                            <div>
                                <div style="font-size: 16px; color: #333333; margin: 10px 0">
                                    {{ scope.row.file.filename }}
                                </div>
                                <div style="font-size: 13px; color: #999999">打印范围 {{ scope.row.file.start }}~{{ scope.row.file.end }} 页 {{ scope.row.file.number }} 份</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="打印状态" prop="status" width="150">
                        <template #default="scope">
                            <div>
                                <div
                                    :style="{
                                        color: scope.row.status == '待打印' ? '#f56c6c' : '#67c23a'
                                    }"
                                >
                                    {{ scope.row.status }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import mainone from '@/components/Mainone.vue'
import maindetails from '@/components/Maindetails'
import mainimgesshow from '@/components/Mainimgesshow'
import mainimgesup from '@/components/Mainimgesup'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'refundinfo',
    inject: ['reload'],
    data() {
        // 处理-退款张数-张数校验
        var checkBig = (rule, value, callback) => {
            //获取key值为ide下的相关数据
            var getdatas = this.ruleForm
            setTimeout(() => {
                // 当输入为整数的判断
                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数字值'))
                } else {
                    if (getdatas.number > getdatas.refund_pages) {
                        callback(new Error('当前订单最多可退张数为：' + getdatas.refund_pages + ' 张'))
                    } else {
                        if (getdatas.number < 0) {
                            callback(new Error('张数最少为：0张'))
                        } else {
                            callback()
                        }
                    }
                }
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '订单'
                },
                {
                    name: '打印管理'
                },
                {
                    name: '退款申请(商户审批)',
                    path: '/refundapplicationmerge',
                    parameter: {},
                    color: 'var(--el-color-primary);'
                },
                {
                    name: '退款详情(商户审批)'
                }
            ], // 当前页-【面包屑导航】

            // 弹窗-处理
            dialogVisible: false,
            // 弹窗-处理-form表单
            ruleForm: {
                number: 0,
                type: 1,
                remark: '',
                reply_img: '',
                reply_img_list: [], //当前订单-展示image
                refund_pages: 0, //当前订单-最大可退张数
                price: 0, //当前订单-单价
                thirdparty: '', //当前订单-所属第三方平台
                trade_no: '', //当前订单-订单编号
                lastmoney: '', // 剩余最大可退金额
                admin_remark: '' //处理备注-仅后台可见
            },
            rules: {
                number: [
                    {
                        required: true,
                        validator: checkBig,
                        trigger: 'change'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '处理方式为必选项',
                        trigger: 'change'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '回复内容不能为空',
                        trigger: 'change'
                    },
                    {
                        required: false,
                        min: 10,
                        max: 50,
                        message: '回复内容在10-50字',
                        trigger: 'change'
                    }
                ],
                admin_remark: [
                    {
                        required: false,
                        message: '处理备注不能为空',
                        trigger: 'change'
                    }
                ]
            },
            //上传-【处理】-imgesuploade上传图片
            myimgesupisshow: true,
            // 上传-【处理】-上传留言图片
            myimgesup: {
                uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: true, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                data: { id: '', thirdparty: '' }, // upload上传时的携带参数
                action: '/admin/ShareQuestion/wechatImg', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                multiple: true, //上传多个文件
                title: '上传图片', //button-按钮文字
                title_type: 'primary', //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: '支持扩展名：*.jpg、*.jpeg、*.png ; 最多上传4张图片，每张图片大小不超过5M', //提示&建议文字
                limit: '4', //上传数量限制,0表示不限
                target_id: 10, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；10-退款审核
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 5000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/jpeg/png' //文件格式提示规则
                    }
                }
            },
            chuliinfo: '', //加载退款信息

            mainpagedata: {
                details: {
                    showstatus: 1, //确定样式模块
                    title: '订单详情',
                    button: {
                        isshow: false,
                        name: '查看微信投诉详情',
                        jump: '/refundwechatinfo',
                        jumpcontent: {
                            id: '',
                            thirdparty: ''
                        }
                    },
                    content: []
                }, //关于详情信息的组件
                details1: {
                    showstatus: 1, //确定样式模块
                    title: '反馈信息',
                    button: {
                        isshow: false,
                        name: ''
                    },
                    content: []
                }, //关于详情信息的组件
                details2: [
                    // {
                    //   showstatus: 1, //确定样式模块
                    //   title: "",
                    //   button: {
                    //     isshow: false,
                    //     name: "",
                    //   },
                    //   content: [],
                    //   // 处理-图片
                    //   imgesdatareply: {
                    //     showstatus: 2,
                    //     content: [],
                    //   },
                    // },
                ], //关于详情信息的组件
                imgesdata: {
                    showstatus: 2,
                    content: []
                }, //关于图片展示的组件

                // 证件照电子版
                imgesdatacredit: {
                    showstatus: 2,
                    content: []
                },
                searchwidth: '580px'
            }, // 当前Pagedata所需的data数据与控制

            // 打印中-弹窗
            dialogVisible1: false,
            searchwidth: '580px',
            formdata: [
                {
                    file: {
                        filename: 'filename123',
                        start: '1',
                        end: '3',
                        number: 1
                    },
                    status: '待打印'
                }
            ],
            // 打印中-状态-维护字段-【当数据为空时，打印状态不可点击,1可以点击,2不可点击】
            printstatus: 1,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            imges: [], //维护imges字段
            wechatimg: [], //维护wechatimg字段

            mydisabled: false,

            pagesdatas: {},

            loading: false
        }
    },
    methods: {
        // 点击下一条按钮
        nextClickEvent(currentId) {
            let mypagesdatas = JSON.parse(this.pagesdatas)
            axios
                .post(
                    '/admin/ShareQuestion/agentIndex',
                    {
                        thirdparty: mypagesdatas.thirdparty,
                        client: mypagesdatas.client,
                        status: mypagesdatas.status,
                        keywords: mypagesdatas.keywords,
                        pay_status: mypagesdatas.pay_status ? mypagesdatas.pay_status : '',
                        type: mypagesdatas.type ? mypagesdatas.type : '',
                        source: mypagesdatas.source ? mypagesdatas.source : '',
                        print_status: mypagesdatas.print_status ? mypagesdatas.print_status : '',
                        paytype: mypagesdatas.paytype ? mypagesdatas.paytype : '',
                        addtime: mypagesdatas.addtime ? mypagesdatas.addtime : '',
                        id: currentId
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        if (response.data.result.id > 0) {
                            this.routeraddrClick('/refundapplicationinfomerge', { id: response.data.result.id, thirdparty: mypagesdatas.thirdparty })
                        } else {
                            ElMessage({
                                type: 'warning',
                                message: '已经处理到最后一条了，即将返回退款申请（商户审批）列表！',
                                duration: 1000,
                                onClose: () => {
                                    // id返0表示下级没有了-需带筛选条件返回列表
                                    this.routeraddrClick('/refundapplicationmerge', this.pagesdatas)
                                }
                            })
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            // console.log("当前获取的taninfo is :" + JSON.stringify(taninfo));
            console.log(taninfo)
            let dataarray = []
            if (taninfo.reply_img != '0') {
                let imgearray = taninfo.reply_img.split(',')
                if (imgearray.length > 0) {
                    imgearray.forEach((element) => {
                        let onedata = {
                            name: '',
                            url: element
                        }
                        dataarray.push(onedata)
                    })
                }
            }

            // this.ruleForm.number = taninfo.pages - taninfo.cumu_pages; //当前订单-最大可退张数
            this.ruleForm.number = taninfo.number //当前订单-申请退款张数
            this.ruleForm.remark = ''
            this.ruleForm.admin_remark = ''
            this.ruleForm.reply_img = ''
            this.ruleForm.reply_img_list = []
            this.ruleForm.refund_pages = taninfo.pages - taninfo.cumu_pages //当前订单-最大可退张数
            this.ruleForm.price = taninfo.price / 100 //当前订单-单价（分转元）
            this.ruleForm.thirdparty = taninfo.thirdparty //当前订单-平台来源
            this.ruleForm.trade_no = taninfo.trade_no //当前订单-订单编号
            this.ruleForm.lastmoney = ((taninfo.refund_money - taninfo.cumu_refund) / 100).toFixed(2) // 剩余最大可退金额

            // 弹窗-上传图片需携带参数
            this.changeMainimgesup = {
                id: taninfo.id,
                thirdparty: taninfo.thirdparty
            }
        },
        // 点击上传图片按钮
        changeMainimgesupEvent(datas) {
            this.myimgesup.data = { id: datas.id, thirdparty: datas.thirdparty }
        },
        // 弹出框[处理-按钮]-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            // console.log("当前获取的回传数据：" + JSON.stringify(data));
            // console.log(data)
            let onedata = {
                id: data.file_info.id,
                name: data.name,
                url: data.file_info.url
            }
            this.imges.push(onedata)
            this.wechatimg.push(data.wechat_image)
        },

        //弹出框[处理-按钮]-删除上传的文件-事件（upload组件）
        deluploadfileEvent(data) {
            // console.log("当前删除事件-返回的数据：" + JSON.stringify(data));
            if (data.length > 0) {
                let dataarray = []
                data.forEach((element) => {
                    let onedata = {
                        id: element.response.result.file_info.id,
                        name: element.name,
                        url: element.response.result.file_info.url
                    }
                    dataarray.push(onedata)
                })
                this.ruleForm.reply_img_list = dataarray
                this.imges = dataarray //维护imges字段
            } else {
                this.ruleForm.reply_img_list = []
                this.imges = [] //维护imges字段
                this.wechatimg = [] //维护wechatimg字段
            }
        },

        //弹出框[处理-按钮]-提交表单
        submitForm(formName, typeide) {
            // 前端防抖
            this.mydisabled = true

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var getdatas = this.$refs[formName].model
                    let ajaxurl = ''
                    let ajaxdata = {}
                    // typeide：1同意2驳回
                    if (typeide == 1) {
                        let imgesarrary = []
                        let imagesid = []
                        // getdatas.reply_img_list = getdatas.reply_img_list.concat(this.imges);
                        getdatas.reply_img_list = this.imges
                        console.log(getdatas.reply_img_list)
                        getdatas.reply_img_list.forEach((element) => {
                            imgesarrary.push(element.url)
                            imagesid.push(element.id)
                        })
                        getdatas.reply_img = imgesarrary.join(',')
                        getdatas.reply_img_ids = imagesid.join(',')
                        ajaxurl = '/admin/ShareQuestion/returnMoney'
                        ajaxdata = {
                            thirdparty: getdatas.thirdparty,
                            trade_no: getdatas.trade_no,
                            status: getdatas.type,
                            remark: getdatas.remark,
                            admin_remark: getdatas.admin_remark,
                            money:
                                getdatas.number == getdatas.refund_pages
                                    ? getdatas.lastmoney
                                    : getdatas.number * getdatas.price > getdatas.lastmoney
                                    ? getdatas.lastmoney
                                    : getdatas.number * getdatas.price,
                            number: getdatas.number,
                            reply_img: getdatas.reply_img_ids,
                            wechat_img: this.wechatimg.length > 0 ? this.wechatimg.join(',') : []
                        }
                    } else {
                        let imgesarrary = []
                        let imagesid = []
                        // getdatas.reply_img_list = getdatas.reply_img_list.concat(this.imges);
                        getdatas.reply_img_list = this.imges
                        getdatas.reply_img_list.forEach((element) => {
                            imgesarrary.push(element.url)
                            imagesid.push(element.id)
                        })
                        getdatas.reply_img = imgesarrary.join(',')
                        getdatas.reply_img_ids = imagesid.join(',')
                        ajaxurl = '/admin/ShareQuestion/reject'
                        ajaxdata = {
                            thirdparty: getdatas.thirdparty,
                            trade_no: getdatas.trade_no,
                            status: getdatas.type,
                            remark: getdatas.remark,
                            admin_remark: getdatas.admin_remark,
                            money:
                                getdatas.number == getdatas.refund_pages
                                    ? getdatas.lastmoney
                                    : getdatas.number * getdatas.price > getdatas.lastmoney
                                    ? getdatas.lastmoney
                                    : getdatas.number * getdatas.price,
                            number: getdatas.number,
                            reply_img: getdatas.reply_img_ids,
                            wechat_img: this.wechatimg.length > 0 ? this.wechatimg.join(',') : []
                        }
                    }
                    axios
                        .post(ajaxurl, ajaxdata, {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = ref(false) //关闭弹窗
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！即将进入下一条！',
                                    duration: 1000,
                                    onClose: () => {
                                        // this.reload() //刷新
                                        this.mydisabled = false
                                        // 跳转下一条
                                        this.nextClickEvent(this.$route.query.orderid)
                                    }
                                })
                            } else {
                                this.mydisabled = false
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                            this.mydisabled = false
                        })
                } else {
                    console.log('error submit!!')
                    this.mydisabled = false
                    return false
                }
            })
        },
        //弹出框[处理-按钮]-重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            console.log(routeraddr, rowdata)
            // 携带参数跳转
            if (rowdata) {
                // 跳转-微信投诉详情
                if (routeraddr == '/refundwechatinfo') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                id: rowdata.id,
                                thirdparty: rowdata.thirdparty,
                                flag: 2, // 标识-表示来源于（退款申请(商户审批)中的退款详情(商户审批)）
                                pagesdatas: this.pagesdatas
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-退款申请详情-本页面（兼容下一条功能）
                if (routeraddr == '/refundapplicationinfomerge') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                orderid: rowdata.id,
                                thirdparty: rowdata.thirdparty,
                                pagesdatas: this.pagesdatas
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-退款申请页面-上一级页面（兼容下一条功能无数据-跳转list列表页）
                if (routeraddr == '/refundapplicationmerge') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                pagesdatas: rowdata
                            }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        getwindowinfo() {
            //获取当前windows页面信息-实时修改【高级筛选】的宽度
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            //加载loading
            this.loading = true

            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query
            if (routerinfo.pagesdatas) {
                this.pagesdatas = routerinfo.pagesdatas
                this.mainonebread[2].parameter.pagesdatas = routerinfo.pagesdatas
            }

            axios
                .post(
                    '/admin/ShareQuestion/info',
                    {
                        id: this.$route.query.orderid,
                        thirdparty: this.$route.query.thirdparty
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result

                    // 本id下的退款信息
                    this.chuliinfo = getdatas

                    //控制微信申诉记录详情按钮
                    this.mainpagedata.details.button.isshow = getdatas.complaint_id === '0' ? false : true
                    this.mainpagedata.details.button.jumpcontent.id = this.$route.query.orderid
                    this.mainpagedata.details.button.jumpcontent.thirdparty = this.$route.query.thirdparty

                    var content1 = [] //订单详情
                    content1 = [
                        {
                            name: '订单编号:',
                            value: response.data.result.trade_no
                        },
                        {
                            name: '平台来源:',
                            value: response.data.result.thirdparty_platform
                        },
                        {
                            name: '订单状态:',
                            value: response.data.result.ShareOrder ? response.data.result.ShareOrder.pay_status_name : ''
                        },
                        {
                            name: '打印状态:',
                            value: '',
                            jumpbt: (response.data.result.ShareOrder ? response.data.result.ShareOrder.print_status_name : '') + ' >>'
                        },
                        {
                            name: '打印张数:',
                            value: response.data.result.ShareOrder ? response.data.result.ShareOrder.countpages : ''
                        },
                        {
                            name: '打印费(含服务费):',
                            value: response.data.result.ShareOrder ? (response.data.result.ShareOrder.printtotal / 100).toFixed(2) : ''
                        },
                        {
                            name: '文档使用费:',
                            value: response.data.result.ShareOrder ? (response.data.result.ShareOrder.mobetotal / 100).toFixed(2) : ''
                        },
                        {
                            name: '三方服务费:',
                            value: response.data.result.ShareOrder ? (response.data.result.ShareOrder.mattingprice / 100).toFixed(2) : ''
                        },
                        {
                            name: '设备会员:',
                            value: response.data.result.ShareOrder ? (response.data.result.ShareOrder.monthlytotal / 100).toFixed(2) : ''
                        },
                        {
                            name: '优惠方式:',
                            value: response.data.result.ShareOrder ? response.data.result.ShareOrder.discount_type_name : ''
                        },
                        // {
                        //     name: '优惠金额:',
                        //     value: response.data.result.ShareOrder
                        //         ? response.data.result.ShareOrder.discount_type == 6
                        //             ? this.$utils.moneyAndDiscountToExchange(
                        //                   response.data.result.ShareOrder.discount_price,
                        //                   response.data.result.ShareOrder.mobetotal_discount,
                        //                   response.data.result.ShareOrder.printtotal
                        //               )
                        //             : (response.data.result.ShareOrder.discount_price / 100).toFixed(2)
                        //         : ''
                        // },
                        {
                            name: '优惠金额:',
                            value: response.data.result.ShareOrder
                                ? this.$utils.moneyAndDiscountToExchange(
                                      response.data.result.ShareOrder.discount_type,
                                      response.data.result.ShareOrder.discount_price,
                                      response.data.result.ShareOrder.mobetotal_discount,
                                      response.data.result.ShareOrder.printtotal
                                  )
                                : ''
                        },
                        {
                            name: '实付金额:',
                            value: response.data.result.ShareOrder ? (response.data.result.ShareOrder.sftotal / 100).toFixed(2) : ''
                        },
                        {
                            name: '实付打印费(含设备会员和服务费):',
                            value: (response.data.result.refund_money / 100).toFixed(2)
                        },
                        {
                            name: '支付方式:',
                            value: response.data.result.ShareOrder ? response.data.result.ShareOrder.paytype_name : ''
                        },
                        {
                            name: '用户手机号:',
                            value: response.data.result.user_mobile
                        },
                        {
                            name: '用户ID:',
                            value: response.data.result.uid
                        },
                        {
                            name: '支付时间:',
                            value:
                                response.data.result.ShareOrder && response.data.result.ShareOrder.pay_time ? this.$utils.formatDate(response.data.result.ShareOrder.pay_time) : ''
                        }
                    ]
                    this.mainpagedata.details.content = content1

                    var content2 = [] //反馈信息
                    content2 = [
                        {
                            name: '设备编号:',
                            value: response.data.result.ShareClient ? response.data.result.ShareClient.client : ''
                        },
                        {
                            name: '申请退款张数:',
                            value: response.data.result.number
                        },
                        {
                            name: '申请退款金额:',
                            value: (response.data.result.money / 100).toFixed(2)
                        },
                        {
                            name: '退款类型:',
                            value: response.data.result.type_name
                        },
                        {
                            name: '反馈详情:',
                            value: response.data.result.content
                        }
                    ]
                    this.mainpagedata.details1.content = content2

                    // 处理信息
                    let getchuli = response.data.result.auditsInfo
                    getchuli.forEach((element, key) => {
                        var content3 = []
                        content3 = [
                            {
                                name: '处理状态:',
                                value: element.content.status_name
                            },
                            {
                                name: '处理平台:',
                                value: element.length == 0 ? '' : element.process_platform
                            },
                            {
                                name: '处理人:',
                                value: element.length == 0 ? '' : element.process_name
                            },
                            {
                                name: '处理时间:',
                                value: element.length == 0 ? '' : this.$utils.formatDate(element.addtime)
                            },
                            {
                                name: '实际退款金额:',
                                value: element.content.status == -1 ? '' : (element.content.money / 100).toFixed(2)
                            },
                            {
                                name: '处理回复:',
                                value: typeof element === 'object' ? element.remark : ''
                            },
                            {
                                name: '处理备注:',
                                value: typeof element === 'object' ? element.content.admin_remark : ''
                            }
                        ]

                        let datas = {
                            showstatus: 1, //确定样式模块
                            title: '处理' + (parseInt(key) + 1),
                            button: {
                                isshow: false,
                                name: ''
                            },
                            content: content3,
                            // 处理-图片
                            imgesdatareply: {
                                showstatus: 2,
                                content: []
                            }
                        }

                        // 渲染-图片（回复图片）
                        let getimages2 = []
                        if (element['content'].reply_img_list) {
                            let preview = []
                            element['content'].reply_img_list.forEach((element1) => {
                                preview.push(element1.file_url)
                                let data = {
                                    url: element1.file_url,
                                    preview: preview
                                }
                                getimages2.push(data)
                            })
                        }
                        datas.imgesdatareply.content = getimages2

                        this.mainpagedata.details2.push(datas)
                    })

                    // var content3 = [];
                    // content3 = [
                    //   {
                    //     name: "处理状态:",
                    //     value: response.data.result.status_name,
                    //   },
                    //   {
                    //     name: "处理平台:",
                    //     value:
                    //       response.data.result.auditsInfo.length == 0
                    //         ? ""
                    //         : response.data.result.auditsInfo.process_platform,
                    //   },
                    //   {
                    //     name: "处理人:",
                    //     value:
                    //       response.data.result.auditsInfo.length == 0
                    //         ? ""
                    //         : response.data.result.auditsInfo.process_name,
                    //   },
                    //   {
                    //     name: "处理时间:",
                    //     value:
                    //       response.data.result.auditsInfo.length == 0
                    //         ? ""
                    //         : this.$utils.formatDate(
                    //           response.data.result.auditsInfo.addtime
                    //         ),
                    //   },
                    //   {
                    //     name: "实际退款金额:",
                    //     value: response.data.result.status == -1 ? '' : (response.data.result.cumu_refund / 100).toFixed(2),
                    //   },
                    //   {
                    //     name: "处理回复:",
                    //     value:
                    //       typeof response.data.result.auditsInfo === "object"
                    //         ? response.data.result.auditsInfo.remark
                    //         : "",
                    //   },
                    // ];
                    // this.mainpagedata.details2.content = content3;

                    // 显示-弹窗-打印记录信息
                    let printinfo = response.data.result.ShareOrder.ShareOrderFile
                    let nowform = []
                    if (printinfo.length > 0) {
                        printinfo.forEach((element) => {
                            let datas = {
                                file: {
                                    filename: element.file_name,
                                    start: element.start,
                                    end: element.end,
                                    number: element.number
                                },
                                status: element.status_name
                            }
                            nowform.push(datas)
                        })
                    }
                    this.formdata = nowform
                    // 打印记录显示与否判断-维护点击状态字段
                    if (nowform.length > 0) {
                        this.printstatus = 1
                    } else {
                        this.printstatus = 2
                    }

                    // // 渲染-图片（证件照电子版）
                    // let getimages = [];
                    // if (getdatas.ShareOrder.ShareOrderFile) {
                    //   getdatas.ShareOrder.ShareOrderFile.forEach((element) => {
                    //     let data = {
                    //       url: element.file_path,
                    //       preview: [element.file_path],
                    //     };
                    //     getimages.push(data);
                    //   });
                    // }
                    // this.mainpagedata.imgesdatacredit.content = getimages;
                    // console.log(this.mainpagedata.imgesdatacredit.content);

                    // 渲染-图片（反馈图片）
                    let getimages1 = []
                    if (getdatas.image_list) {
                        let preview = []
                        getdatas.image_list.forEach((element) => {
                            preview.push(element)
                            let data = {
                                url: element,
                                preview: preview
                            }
                            getimages1.push(data)
                        })
                    }
                    this.mainpagedata.imgesdata.content = getimages1
                    console.log(this.mainpagedata.imgesdata.content)

                    // // 渲染-图片（回复图片）
                    // let getimages2 = [];
                    // if (getdatas.reply_img_list) {
                    //   let preview = [];
                    //   getdatas.reply_img_list.forEach((element) => {
                    //     preview.push(element.file_url);
                    //     let data = {
                    //       url: element.file_url,
                    //       preview: preview,
                    //     };
                    //     getimages2.push(data);
                    //   });
                    // }
                    // this.mainpagedata.imgesdatareply.content = getimages2;
                    // console.log(this.mainpagedata.imgesdatareply.content);

                    this.loading = false
                })
                .catch((err) => {
                    console.log(err)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow,
        mainimgesup
    }
})
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px 30px;
    margin-top: 20px;
    margin-bottom: 80px;
}

.box_title {
    line-height: 40px;
    height: 40px;
    margin-top: 10px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.money_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.money_box_content {
    margin: 0 10px 10px 0;
}

/* 处理信息 */
.details-box {
    margin-top: 20px;
}

.flex-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.flex-box-item {
    font-size: 14px;
    color: #333333;
    line-height: 40px;
    min-width: 300px;
    max-width: 500px;
}
</style>
