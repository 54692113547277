<template>
    <div class="page-container">
        <div class="container-bread">
            合作伙伴 <el-icon><ArrowRight /></el-icon> 指定设备报单 <el-icon><ArrowRight /></el-icon> 负责人指定设备
        </div>
        <div class="container-filter">
            <el-form ref="filterRef" :model="params" inline>
                <div>
                    <el-form-item prop="main_id" label="负责人：">
                        <el-select v-model="params.main_id" placeholder="请选择负责人" clearable>
                            <el-option v-for="(item, i) in options" :key="i" :value="item.value" :label="item.label" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="status" label="状态：">
                        <el-select v-model="params.status" placeholder="全部" clearable>
                            <el-option label="全部" value="" />
                            <el-option label="开启" :value="1" />
                            <el-option label="关闭" :value="-1" />
                        </el-select>
                    </el-form-item>
                </div>
                <div>
                    <el-button @click="resetFilter">重置</el-button>
                    <el-button type="primary" @click="getTableData()" :loading="table.loading">查询</el-button>
                </div>
            </el-form>
        </div>
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <template v-if="mypowerlimits.fzrzdsb_pldrzdsb">
                        <el-button type="default" @click="downloadTemplate">模板下载</el-button>
                        <el-button type="primary" @click="uploadTemplate">批量导入指定设备</el-button>
                    </template>
                    <el-button type="primary" @click="showDrawer" v-if="mypowerlimits.fzrzdsb_tj">添加负责人</el-button>
                    <el-button type="primary" @click="exportRecord">导出</el-button>
                </div>
            </div>
            <el-table :border="true" :data="table.data" v-loading="table.loading">
                <el-table-column prop="main_id" label="负责人ID" />
                <el-table-column prop="truename" label="指定负责人" />
                <el-table-column prop="area" label="所属区域" />
                <el-table-column prop="pid_name" label="直属上级" />
                <el-table-column prop="client_num" label="指定设备">
                    <template #default="{ row }">
                        <template v-if="row.client_num > 0">
                            <el-button type="text" size="small" @click="deviceRoute(row)">{{ row.client_num }} </el-button>
                        </template>
                        <span v-else>{{ row.client_num }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template #default="{ row }">
                        <el-switch
                            v-model="row.status"
                            :active-value="1"
                            :inactive-value="-1"
                            @click="mypowerlimits.fzrzdsb_kg == true ? recordStatus(row, row.status) : ''"
                            :disabled="!mypowerlimits.fzrzdsb_kg"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                    <template #default="{ row }">
                        <el-button type="danger" size="small" @click="handleDelete(row)" v-if="mypowerlimits.fzrzdsb_sc">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="tabler-pagination">
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[10, 20, 50, 100]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>
        <el-drawer v-model="visible" title="添加负责人" show-close>
            <div class="drawer-body">
                <div class="drawer-content">
                    <div class="content-over">
                        <el-input v-model="treeFilter" @input="filterChange" placeholder="过滤" />
                        <el-tree
                            class="filter-tree"
                            ref="treeRef"
                            :data="tree"
                            show-checkbox
                            node-key="id"
                            default-expand-all
                            check-strictly
                            :props="{ label: 'truename', value: 'id', children: 'child', disabled: nodeDisabled }"
                            :filter-node-method="filterNode"
                        />
                    </div>
                </div>
                <div class="drawer-footer">
                    <el-button @click="visible = false">取 消</el-button>
                    <el-button type="primary" @click="submitAdd">确 定</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { ArrowRight } from '@element-plus/icons'
import axios from 'axios'
import { ElMessageBox, ElNotification, ElTree, ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'

// 获取权限
const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authentication: localStorage.getItem('token')
    }
})

// 负责人
const tree = ref([])
const getTreeData = async () => {
    const { data } = await http.get('/admin/Equipment/fzrTree')
    tree.value = data.result
}
const options = ref([])
const getOptions = async () => {
    const { data } = await http.get('/admin/Equipment/fzrTree', { params: { type: 'select' } })
    options.value = data.result.map((item) => ({ label: item.truename, value: item.id }))
}

// 过滤
const filterRef = ref()
const params = reactive({
    status: null,
    main_id: null,
    page: 1,
    page_size: 10
})

// 表格
const table = reactive({
    loading: false,
    data: [],
    columns: [],
    total: 0
})
const getTableData = async (page = 1) => {
    getOptions()
    params.page = page
    table.loading = true
    try {
        const { data } = await http.get('/admin/Equipment/fzrList', { params: { ...params, main_id: params.main_id } })
        Object.assign(table, data.result)
    } catch (e) {
        console.error(e)
    }
    table.loading = false
}

// 操作
const router = useRouter()
const deviceRoute = (row) => {
    router.push({ name: 'deviceappointdvc', query: { main_id: row.main_id, active: 1, main_name: row.truename } })
}
const handleDelete = async (row) => {
    await ElMessageBox.confirm('是否删除此记录？', '提示', { type: 'warning' })
    await http.post('admin/Equipment/fzrSwitch', { id: row.id, status: -2 })
    ElNotification.success({ title: '提示', type: 'success', message: '删除成功' })
    getTableData()
}
const nodeDisabled = (data) => {
    return options.value.some((item) => item.value === data.id)
}
const showDrawer = () => {
    visible.value = true
    treeFilter.value = ''
    getTreeData()
}
const filterNode = (value, data) => {
    if (!value) return true
    return data.truename.indexOf(value) !== -1
}
let t = 0
const filterChange = (value) => {
    if (t) clearTimeout(t)
    t = setTimeout(() => {
        treeRef.value.filter(value)
        t = 0
    }, 300)
}
// 重置查询
const resetFilter = () => {
    filterRef.value?.resetFields()
    params.status = null
    params.main_id = null
    getTableData()
}
// 修改状态
const recordStatus = async (row, value) => {
    try {
        const { data } = await http.post('admin/Equipment/fzrSwitch', { id: row.id, status: value })
        ElMessage({ type: 'success', message: data.message, duration: 3000 })
    } catch (e) {
        row.status = value === 1 ? -1 : 1
    }
}
// 下载模板
const downloadTemplate = async () => {
    const { data } = await http.get('admin/Equipment/downTemplate', { params: { templateName: 'fzr_client_template' } })
    const link = document.createElement('a')
    link.href = data.result.download
    link.click()
}
// 批量导入指定设备
const uploadTemplate = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.xlsx,.xls'
    input.click()
    input.onchange = async (e) => {
        const file = e.target.files[0]
        if(!file) return
        const formData = new FormData()
        formData.append('file', file)
        const { data } = await http.post('admin/Equipment/fzrImport', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        const { total, succ, fail, msg } = data.result
        let message = `共${total || 0}条记录`
        if (succ > 0) message += `<br />导入成功${succ}条`
        if (fail > 0) message += `<br />导入失败${fail}条`
        if (fail > 0) {
            message += `<br />失败原因：`
            for (let key in msg) {
                message += `<br />第${key}行：${msg[key]}`
            }
        }
        ElMessageBox.alert(message, '提示', { type: 'info', dangerouslyUseHTMLString: true })
        getTableData()
    }
}
// 导出
const exportRecord = async () => {
    const { data } = await http.get('/admin/Equipment/fzrList', { params: { ...params, main_id: params.main_id, export: 1 } })
    ElNotification({ title: '提示', type: data.code === 0 ? 'success' : 'warning', message: data.message })
}
const submitAdd = async () => {
    const ids = treeRef.value.getCheckedKeys()
    if (ids.length === 0) {
        ElMessage({ type: 'warning', message: '请选择负责人', duration: 3000 })
        return
    }
    const { data } = await http.post('admin/Equipment/fzrEdit', { id: ids })
    ElMessage({ type: 'success', message: data.message, duration: 3000 })
    visible.value = false
    getTableData()
}
// 弹窗
const visible = ref(false)
const treeRef = ref()
const treeFilter = ref('')

getTableData()
</script>

<style lang="scss" scoped>
.page-container {
    .container-bread {
        font-size: 14px;
        color: #666;
        display: flex;
        align-items: center;
    }
    .container-filter {
        margin-top: 8px;
        padding: 12px 24px;
        background-color: #fff;
        border-radius: 4px;
        .el-form {
            display: flex;
            justify-content: space-between;
        }
        .el-form-item {
            margin-right: 20px;
            margin-bottom: 0px;
        }
    }

    .container-table {
        margin-top: 12px;
        padding: 24px;
        background-color: #fff;
        border-radius: 4px;
        .table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }
        .tabler-pagination {
            display: flex;
            justify-content: flex-end;
            margin-top: 12px;
        }
    }
}

.drawer-body {
    position: absolute;
    top: 80px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    .drawer-content {
        flex: 1;
        overflow: hidden;
        .content-over {
            height: 100%;
            overflow: auto;
            .filter-tree {
                margin-top: 24px;
            }
        }
    }
    .drawer-footer {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
