<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <!--内容-->
                <div class="box_content" id="switch">
                    <el-tabs v-model="activeName" @tab-change="handleClick">
                        <!-- 权益会员设置 -->
                        <el-tab-pane label="权益会员设置" name="1" v-if="mypowerlimits.viphysz_set">
                            <benefitsSet />
                        </el-tab-pane>
                        <!-- 会员等级设置 -->
                        <el-tab-pane label="会员等级设置" name="2" v-if="mypowerlimits.viphysz_level_set">
                            <memberLevelSet />
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import benefitsSet from './benefitsSet.vue'
import memberLevelSet from './memberLevelSet.vue'
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent({
    name: 'vmemberSet',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '权益会员'
                },
                {
                    name: '会员设置'
                }
            ], // 当前页-【面包屑导航】
            activeName: '1', //tabs标签-默认显示页

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')) //获取权限
        }
    },
    methods: {
        handleClick(activename) {
            this.activeName = activename
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            if (!this.mypowerlimits.viphysz_set && this.mypowerlimits.viphysz_level_set) {
                this.activeName = '2'
            }
            this.handleClick(this.activeName)
        }
    },
    components: {
        mainone,
        benefitsSet,
        memberLevelSet
    }
})
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_content :deep(.el-form-item__error) {
    position: static !important;
}

.box_content :deep(.el-upload) {
    display: block !important;
}

.box_content {
    border-top: 0px solid #eeeeee;
}
</style>
