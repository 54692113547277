<template>
    <div>
        <div class="echars-box-list">
            <div class="echars-box-list-box">
                <div class="echars-box-list-box-m">
                    <span>摇篮期(0~90天)</span>
                </div>
                <div class="echars-box-list-box-bg" style="background-color: #BBC6E8;"></div>
            </div>
            <div class="echars-box-list-box">
                <div class="echars-box-list-box-m">
                    <span>成长期(91~365天)</span>
                </div>
                <div class="echars-box-list-box-bg" style="background-color: #D9F3CC;"></div>
            </div>
            <div class="echars-box-list-box">
                <div class="echars-box-list-box-m">
                    <span>稳定期(365天后)</span>
                </div>
                <div class="echars-box-list-box-bg" style="background-color: #FDE9BC;"></div>
            </div>
        </div>
        <div id="mymainbox" style="width: 100%;height: 400px;"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'echartsbglinechat',
    data() {
        return {
            list: { time: [], money: [] },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem('userinfo')) //获取存储的用户信息
        }
    },
    methods: {
        // // 求百分比
        // getPercentage(section, total, flag) {
        //     let num = null
        //     if (flag == 1) {
        //         if (section.length < 90) {
        //             num = section / total / 2
        //         } else {
        //             num = 90 / total / 1.5
        //         }
        //     }
        //     if (flag == 2) {
        //         if (section.length < 365) {
        //             num = 90 / total + section / total / 10
        //         } else {
        //             num = 90 / total + (365 - 90) / total / 2.5
        //         }
        //     }
        //     if (flag == 3) {
        //         console.log('section.length:', section)
        //         num = 365 / total + section / total / 40
        //     }
        //     console.log('num:', num)
        //     return (
        //         parseFloat(num * 100)
        //             .toFixed(2)
        //             .toString() + '%'
        //     )
        // },
        // 拥有摇篮期
        judgeBelong(data, flag, x_data) {
            let bg_list = { 1: '#78DC5D', 2: '#FFB85E', 3: '#81DCFF' }
            let xAxis_list = { 1: [x_data[0], x_data[89]], 2: [x_data[90], x_data[365]], 3: [x_data[366], x_data[x_data.length - 1]] }
            let title = []
            let series = []

            let title_list = {
                subtext: '',
                subtextStyle: { fontStyle: 'bold', fontSize: '16' },
                left: ''
            }

            let series_list = {
                type: 'line',
                label: { backgroundColor: '' },
                lineStyle: { color: '#3B9DFC' },
                smooth: true, //是否平滑处理值0-1,true相当于0.5
                data: [],
                markArea: { data: [[{ xAxis: '' }, { xAxis: '' }]] }
            }

            let t_info = title_list

            let s_info = series_list
            s_info.label.backgroundColor = bg_list[flag]
            s_info.markArea.data[0][0].xAxis = xAxis_list[flag][0]
            s_info.markArea.data[0][1].xAxis = xAxis_list[flag][1]

            if (flag == 1) {
                if (data.length > 90) {
                    s_info.data = [...data.slice(0, 90), ...this.moreDateExchange(data.length - 90)]
                } else {
                    s_info.data = data
                }
            }
            if (flag == 2) {
                if (data.length > 365) {
                    s_info.data = [...this.moreDateExchange(90), ...data.slice(90, 365), ...this.moreDateExchange(data.length - 365)]
                } else {
                    s_info.data = [...this.moreDateExchange(90), ...data.slice(90, data.length)]
                }
            }
            if (flag == 3) {
                s_info.data = [...this.moreDateExchange(365), ...data.slice(365, data.length)]
            }
            title = t_info
            series = s_info

            return { title, series }
        },
        // 批量替换
        moreDateExchange(num, defaultnum = null) {
            let data = []
            for (let index = 0; index < num; index++) {
                data.push(defaultnum)
            }
            return data
        },
        // 判断属于哪个时期
        judgePeriod(data, x_data) {
            let title_data = []
            let series_data = []

            if (data.length > 0 && x_data.length > 0) {
                if (data.length <= 90) {
                    let { title, series } = this.judgeBelong(data, 1, x_data)
                    title_data.push(title)
                    series_data.push(series)
                } else if (data.length > 90 && data.length <= 365) {
                    let data_1 = this.judgeBelong(data, 1, x_data)
                    title_data.push(data_1.title)
                    series_data.push(data_1.series)

                    let data_2 = this.judgeBelong(data, 2, x_data)
                    title_data.push(data_2.title)
                    series_data.push(data_2.series)
                } else {
                    let data_1 = this.judgeBelong(data, 1, x_data)
                    title_data.push(data_1.title)
                    series_data.push(data_1.series)

                    let data_2 = this.judgeBelong(data, 2, x_data)
                    title_data.push(data_2.title)
                    series_data.push(data_2.series)

                    let data_3 = this.judgeBelong(data, 3, x_data)
                    title_data.push(data_3.title)
                    series_data.push(data_3.series)
                }
            }

            return { title_data, series_data }
        },

        // echarts
        echartCustom(datas) {
            // console.log('获取的饼状图自定义', datas)
            var myChart = echarts.init(document.getElementById('mymainbox'))

            let { title_data, series_data } = this.judgePeriod(datas.money, datas.time)

            console.log(title_data, series_data)

            var option = {
                title: title_data,
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    left: '5%',
                    right: '3%',
                    top: '10%',
                    bottom: '10%'
                },
                calculable: true,
                xAxis: [
                    {
                        // name: '时间',
                        type: 'category',
                        boundaryGap: false,
                        data: datas.time
                    }
                ],
                yAxis: [
                    {
                        name: '(元)',
                        type: 'value',
                        max: function(value) {
                            //设置y轴最大值
                            return value.max + 10
                        }
                    }
                ],
                series: series_data
            }

            option && myChart.setOption(option, true)
            window.onresize = myChart.resize
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        }
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

/* 设备台数等信息 */
.dvcinfo-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    color: #333333;
    font-size: 14px;
    margin-left: 40px;
}

.dvcinfo-box-div {
    margin-right: 20px;
}

.dvcinfo-box-div-color {
    color: red;
    margin: 0 5px;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

/* echarts 顶部表示 */
.echars-box-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px 20px;
}
.echars-box-list-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}
.echars-box-list-box-m {
    margin-right: 10px;
    font-size: 18px;
    font-weight: bold;
}

.echars-box-list-box-bg {
    width: 40px;
    height: 20px;
    border-radius: 4px;
}
</style>
