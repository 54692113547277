<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop === 'status' ||
                                    mytabletitle.prop === 'level' ||
                                    mytabletitle.prop === 'user_type' ||
                                    mytabletitle.prop === 'valid_month' ||
                                    mytabletitle.prop === 'begin_end_time'
                                "
                            >
                                <!--代理等级-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'level'">
                                    <div v-if="scope.row.level == 1">
                                        <span>一级代理</span>
                                    </div>
                                    <div v-if="scope.row.level == 2">
                                        <span>二级代理</span>
                                    </div>
                                    <div v-if="scope.row.level == 3">
                                        <span>三级代理</span>
                                    </div>
                                </div>
                                <!--状态-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <span
                                        :style="{
                                            color: scope.row.status == 1 ? 'var(--el-color-success)' : scope.row.status == 2 ? 'var(--el-color-danger)' : '#333'
                                        }"
                                        >{{
                                            scope.row.status ? this.maintwoforminfo.selectleft.select[0].options.filter((n) => n.value == scope.row.status)[0]['label'] : ''
                                        }}</span
                                    >
                                </div>
                                <!-- 类型 -->
                                <div v-if="mytabletitle.prop === 'user_type'">
                                    <span>{{ scope.row.user_type == 1 ? '代理' : '直营' }}</span>
                                </div>
                                <!-- 分佣有效期 -->
                                <div v-if="mytabletitle.prop === 'valid_month'">
                                    <span>{{ scope.row.valid_month == 0 ? '永久' : scope.row.valid_month + '个月' }}</span>
                                </div>
                                <!-- 有效时间 -->
                                <div v-if="mytabletitle.prop === 'begin_end_time'">
                                    <span>{{
                                        scope.row.valid_month == 0
                                            ? this.$utils.formatDate(scope.row.begin_time).substr(0, 10) + '至 永久'
                                            : this.$utils.formatDate(scope.row.begin_time).substr(0, 10) + ' 至 ' + this.$utils.formatDate(scope.row.end_time).substr(0, 10)
                                    }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column :fixed="'right'" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-意向客户列表-->
                                <div>
                                    <el-button type="primary" size="mini" @click="addTanClick(scope.row)" v-if="scope.row.status != -1">更换设备</el-button>
                                    <el-button type="danger" size="mini" @click="delEvent(scope.row)" v-if="scope.row.status != -1">清除介绍人</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>

            <!--弹出框-处理-->
            <el-dialog v-model="dialogVisible" title="更换设备" :width="this.searchwidth" :before-close="handleCloseSenior">
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                    <div class="tan-box">
                        <div style="margin-left: 24px; margin-bottom: 20px; color: red">
                            <span>注：必须选择该代理商下的直属设备</span>
                        </div>
                        <el-form-item label="分佣设备：" prop="cid">
                            <el-select v-model="ruleForm.cid" placeholder="请选择">
                                <el-option :label="item.client" :value="item.id" v-for="(item, index) in this.client_options" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item style="text-align: right">
                            <el-button @click="resetForm('ruleForm')">取消</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vintention',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '合作伙伴'
                },
                {
                    name: '代理商管理'
                },
                {
                    name: '介绍人列表'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '20px', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '分佣状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: '0'
                                },
                                {
                                    label: '分佣中',
                                    value: '1'
                                },
                                {
                                    label: '已过期',
                                    value: '2'
                                },
                                {
                                    label: '已清除',
                                    value: '-1'
                                }
                            ]
                        }
                    ]
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '220px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '代理商名：',
                            placeholder: '请输入代理商名称',
                            name: 'new_shop_id', //此name为键值
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/shopUser/getShopList',
                            inputval: { name: '' } //这里记录需要传的参数
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号：',
                            placeholder: '请输入设备编号', //此name为键值
                            name: 'cid',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/getClientList',
                            inputval: { client: '' } //这里记录需要传的参数
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '介绍人：',
                            placeholder: '请输入介绍人',
                            name: 'shop_pull_id', //此name为键值
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/shopUser/getShopList',
                            inputval: { username: '' } //这里记录需要传的参数
                        }
                        // {
                        //   ismust: false, //是否必填，true表示必填
                        //   title: "负责人：",
                        //   placeholder: "请输入负责人", //此name为键值
                        //   name: "win_user_id",
                        //   content: "",
                        //   id: "", //此id为给后台的id值
                        //   url: "/admin/Statistics/userList",
                        //   posttype: "get",
                        //   inputval: { truename: "" }, //这里记录需要传的参数
                        // },
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 6, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 180,
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'new_shop_id',
                    label: 'ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '代理商名称',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'win_user_id',
                    label: '负责人',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'user_type',
                    label: '类型',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'username',
                    label: '手机号/账号',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'level',
                    label: '代理等级',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '分佣状态',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'pull_username',
                    label: '介绍人',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'client',
                    label: '分佣设备',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'ratio',
                    label: '介绍人佣金比例',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'valid_month',
                    label: '分佣有效期',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'begin_end_time',
                    label: '有效时间',
                    width: '240'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',

            //弹窗相关数据
            dialogVisible: false, //弹窗显示&隐藏
            ruleForm: {
                new_shop_id: '',
                cid: ''
            }, //弹窗中-form表单
            rules: {
                cid: [
                    {
                        required: true,
                        message: '分佣设备为必填项',
                        trigger: 'blur'
                    }
                ]
            }, //弹窗中-form表单-必填规则
            client_options: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        // 清除介绍人 确认清除[ ' + data.name + ']的介绍人[' + data.username + ']吗?', '清除介绍人
        delEvent(data) {
            ElMessageBox.confirm('清除介绍人后，不在产生分佣，且状态变成【已清除】!', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/shopUser/pullClear',
                            {
                                new_shop_id: data.new_shop_id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    message: response.data.message != '' ? response.data.message : '清除成功！',
                                    type: 'success',
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            ElMessage({
                                message: error,
                                type: 'warning'
                            })
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消'
                    })
                })
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                new_shop_id: this.pagesdatas.new_shop_id,
                shop_pull_id: this.pagesdatas.shop_pull_id,
                cid: this.pagesdatas.cid,
                status: this.pagesdatas.status,
                page: 1,
                page_size: val
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                new_shop_id: this.pagesdatas.new_shop_id,
                shop_pull_id: this.pagesdatas.shop_pull_id,
                cid: this.pagesdatas.cid,
                status: this.pagesdatas.status,
                page: val,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    '/admin/shopUser/pullIndex',
                    {
                        new_shop_id: this.pagesdatas.new_shop_id,
                        shop_pull_id: this.pagesdatas.shop_pull_id,
                        cid: this.pagesdatas.cid,
                        status: this.pagesdatas.status,
                        export: 1
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            this.dialogVisible = true
            this.getPullUserClient(taninfo)
            this.ruleForm.new_shop_id = taninfo.new_shop_id
            this.ruleForm.cid = taninfo.cid
        },
        // 获取直属设备
        getPullUserClient(data) {
            axios
                .post(
                    '/admin/shopUser/pullUseClient',
                    {
                        new_shop_id: data.new_shop_id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result
                    this.client_options = getdatas
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //弹出框-提交表单
        submitForm(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/shopUser/pullUpClient',
                            {
                                new_shop_id: getdatas.new_shop_id,
                                cid: getdatas.cid
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                this.dialogVisible = false
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.handleCurrentChange(this.currentPage)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    return false
                }
            })
        },
        handleCloseSenior() {
            this.resetForm('ruleForm')
        },
        //弹出框-取消表单
        resetForm(formName) {
            this.dialogVisible = false //关闭弹窗
            this.$refs[formName].resetFields()
            this.ruleForm = {
                new_shop_id: '',
                cid: ''
            }
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = {
                new_shop_id: data.new_shop_id,
                shop_pull_id: data.shop_pull_id,
                cid: data.cid,
                status: data.status,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },

        // 获取接口
        postAxiosEvent(data) {
            this.loading = true
            axios
                .post('/admin/shopUser/pullIndex', data, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}

.tan-box {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}
</style>
