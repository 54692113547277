<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'ad_code'">
                <!-- 广告编号-渲染数据 -->
                <div v-if="mytabletitle.prop === 'ad_code'">
                  <div :class="this.mypowerlimits.gglb == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.gglb == true ? routeruserlist('/advertlistinfo', scope.row.ad_id) : ''">
                    <span> {{ scope.row.ad_code }} </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-运营-广告申诉-->
                <div v-if="mainthreetableope === 25">
                  <el-button type="primary" size="mini" @click="routeraddrClick('/advertappealinfo', scope.row)">查看
                  </el-button>
                  <el-button type="primary" size="mini"
                    @click="chuliTanClick(tancontent, (dialogVisible = true)), resetFormSenior('seniorform')"
                    v-if="scope.row.status == -1 && this.mypowerlimits.ggss_sh">审核</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" :layout="Layout"
              :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog v-model="dialogVisible" :title="tancontent.title" :width="this.searchwidth"
        :before-close="handleCloseSenior">
        <el-form ref="seniorform" :model="seniorform" :rules="rules" label-width="100px">
          <el-form-item label="申诉原因：" prop="name">
            <span style="margin-right: 10px">{{ seniorform.name }}</span>
          </el-form-item>
          <el-form-item label="审核状态：" prop="status">
            <el-radio-group v-model="seniorform.status">
              <el-radio :label="1">审核通过</el-radio>
              <el-radio :label="2">审核不通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="seniorform.status == 1">
            <el-form-item label="是否退款：" prop="is_refund">
              <el-radio-group v-model="seniorform.is_refund" @change="changeRadioEvent">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="-1">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="退款金额：" prop="refund_money">
              <el-input :disabled="seniorform.is_refund == 0" type="number" step="0.01" min="0"
                :max="seniorform.max_refund_money" v-model="seniorform.refund_money" clearable placeholder="请输入退款金额"
                @change="changeInputEvent" @input="inputThisEvent"></el-input>
              <div>
                本单最大可退
                <span style="color: red">￥{{ seniorform.max_refund_money }}</span>
              </div>
            </el-form-item>
          </div>
          <el-form-item label="申述回复：" prop="remark">
            <el-input v-model="seniorform.remark" type="textarea"></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: right">
              <el-button @click="resetFormSenior('seniorform', (dialogVisible = false))">取消</el-button>
              <el-button type="primary" class="main_two_search" @click="onSubmitSenior('seniorform')"
                :disabled="mydisabled">确定</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vadvertappeal",
  inject: ["reload"], //刷新
  data() {
    return {
      mainonebread: [
        {
          name: "运营",
        },
        {
          name: "设备广告管理",
        },
        {
          name: "广告申诉",
          path: "/advertappeal",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "联系人/联系方式",
              name: "keywords",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "状态：",
              placeholder: "全部",
              name: "status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "待审核",
                  value: "-1",
                },
                {
                  label: "审核通过",
                  value: "1",
                },
                {
                  label: "审核驳回",
                  value: "2",
                },
              ],
            },
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: "提交时间：",
              placeholder: {
                placeholderstart: "请选择开始时间",
                placeholderend: "请选择结束时间",
              },
              name: "addtime",
              value: {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
              },
              rangeseparator: "至", //分隔符
              selectmaxwidth: "100%", //时间段-最大宽度
              marginright: "0", //与右侧距离
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 25, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 120, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: false,
          prop: "reason",
          label: "申诉原因",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status_name",
          label: "状态",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "ad_code",
          label: "广告编号",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "guest_name",
          label: "联系人",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "guest_mobile",
          label: "联系方式",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "提交时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1000, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      Layout: " sizes, prev, pager, next, jumper",

      //table表单相关数据
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      tancontent: {
        title: "申述审核", //弹框-标题
      },
      seniorform: {
        id: "",
        name: "",
        status: 1,
        is_refund: 1,
        refund_money: "",
        max_refund_money: 0,
        remark: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "申诉原因为必填项",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "状态为必选项",
            trigger: "blur",
          },
        ],
        is_refund: [
          {
            required: true,
            message: "退款为必选项",
            trigger: "blur",
          },
        ],
        refund_money: [
          {
            required: true,
            message: "退款金额为必填项",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "申述回复为必填项",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
      searchwidth: "580px",

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用

      mydisabled: false
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    routeruserlist(addr, data) {
      this.$router.push({ path: addr, query: { adid: data } });
    },
    //弹出框-审核-提交表单
    onSubmitSenior(formName) {
      // 前端防抖
      this.mydisabled = true;

      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var postdata = {};
          if (getdatas.status == 1) {
            postdata = {
              id: getdatas.id,
              status: getdatas.status,
              is_refund: getdatas.is_refund,
              refund_money: getdatas.refund_money,
              remark: getdatas.remark,
            };
          } else {
            postdata = {
              id: getdatas.id,
              status: getdatas.status,
              remark: getdatas.remark,
            };
          }

          axios
            .post("/admin/ClientAdvert/appealAudit", postdata, {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            })
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = false; //关闭弹窗
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    this.mydisabled = false;
                    this.reload(); //刷新
                  },
                });
              } else {
                this.mydisabled = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              this.mydisabled = false;
            });
        } else {
          console.log("error submit!!");
          this.mydisabled = false;
          return false;
        }
      });
    },
    //弹出框-审核-取消
    resetFormSenior(formName) {
      this.$refs[formName].resetFields();
    },
    //弹窗中-退款金额-是否退款-选择事件
    changeRadioEvent(val) {
      if (val == -1) {
        this.seniorform.refund_money = null;
      }
    },
    //弹窗中-退款金额-input输入事件
    inputThisEvent(val) {
      if (val > this.seniorform.max_refund_money) {
        this.seniorform.refund_money = this.seniorform.max_refund_money;
      } else {
        if (val < 0) {
          this.seniorform.refund_money = 0;
        }
      }
      if (val.split(".")[1]) {
        if (val.split(".")[1].length > 2) {
          this.seniorform.refund_money =
            val.split(".")[0] + "." + val.split(".")[1].substr(0, 2);
        }
      }
    },
    //table表单-点击单位格-获取当前行数据or带参跳转等
    handleOneData(row) {
      // console.log(row); //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(cell);
      // console.log(event); //获取当前指针事件
      // console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用

      this.seniorform.id = row.id;
      this.seniorform.name = row.reason;
      this.seniorform.max_refund_money = row.final_price / 100;
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ClientAdvert/appeal",
          {
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            addtime: this.pagesdatas.addtime,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ClientAdvert/appeal",
          {
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            addtime: this.pagesdatas.addtime,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 携带参数跳转
        this.$router
          .push({ path: routeraddr, query: { id: rowdata.id } })
          .catch((error) => error);
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-开始时间段状态
      var getstarttime = null;
      if (data.addtime.start == "") {
        getstarttime = "";
      } else {
        if (data.addtime.end == "") {
          getstarttime =
            data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getstarttime =
            data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.addtime = getstarttime;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ClientAdvert/appeal",
          {
            keywords: data.keywords,
            status: data.status,
            addtime: getstarttime,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata, flag) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
        //转换-提交时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无提交时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无提交时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      // this.totalCount = successdata.total;
      // this.currentpage = successdata.current_page; //当前页面页数
      // this.lastpage = successdata.last_page;
      this.loading = false;

      if (successdata.total == null) {
        // 数据小于当前页数时判断
        if (newdata.length < this.PageSize) {
          if (newdata.length != 0 && this.currentPage == 1) {
            this.totalCount = this.PageSize - 1;
          } else {
            if (newdata.length == 0) {
              // 按照常理：为0会减1页，由于双向绑定问题一直请求服务器，因此先注释，待后续优化
              // this.totalCount = this.PageSize * (this.currentPage - 1)
              this.totalCount = this.PageSize * this.currentPage;
            } else {
              this.totalCount = this.PageSize * this.currentPage;
              this.lastpage = this.currentPage;
            }
          }
        } else {
          if (this.totalCount < this.PageSize) {
            // 重设置-初始值
            this.totalCount = 1000;
            this.Layout = "sizes, prev, pager, next, jumper";
          } else {
            if (flag == 1) {
              this.currentPage = 1;
            }
          }
        }
      } else {
        this.totalCount = successdata.total;
        this.currentpage = successdata.current_page; //当前页面页数
        this.lastpage = successdata.last_page;
        this.Layout = "total, sizes, prev, pager, next, jumper";
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ClientAdvert/appeal",
          {
            page_size: 20,
            page: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>