<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"
      v-on:selectdatainfogao="selectDatainfoGaoEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'pay_sum'">
                <!-- 订单数量-渲染数据 -->
                <div v-if="mytabletitle.prop === 'pay_sum'">
                  <div :class="this.mypowerlimits.wkddlb == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.wkddlb == true ? routeraddrClick('/libraryorderlist', scope.row) : ''">
                    <span> {{ scope.row.pay_sum }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vlibraryorder",
  data() {
    return {
      mainonebread: [
        {
          name: "统计",
        },
        {
          name: "教育统计",
        },
        {
          name: "文库订单排行",
          path: "/libraryorder",
        },
      ], // 当前页-【面包屑导航】

      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "文档名称",
              name: "keywords",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "来源：",
              placeholder: "全部",
              name: "type",
              value: "",
              options: [
                {
                  value: "",
                  label: "全部",
                },
                {
                  value: "1",
                  label: "小程序",
                },
                {
                  value: "2",
                  label: "后台",
                },
              ],
            },
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: "支付时间：",
              placeholder: {
                placeholderstart: "请选择开始时间",
                placeholderend: "请选择结束时间",
              },
              name: "pay_time",
              value: {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
              },
              rangeseparator: "至", //分隔符
              selectmaxwidth: "100%", //时间段-最大宽度
              marginright: "0", //与右侧距离
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 37, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 200, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "id",
          label: "资源ID",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "sourcename",
          label: "文档名称",
          width: "400",
        },
        {
          fixedstatu: false,
          prop: "income_type",
          label: "收益类型",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "type",
          label: "来源",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "add_user",
          label: "上传人",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "pages",
          label: "页数",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "view_num",
          label: "真实浏览量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "print_num",
          label: "真实打印量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "plus_num",
          label: "会员打印量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "mark",
          label: "评分",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "pay_sum",
          label: "订单数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "pay_total",
          label: "付费金额",
          width: "",
        },
      ], // 数据列表中-数据表头
      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "文档名称",
            name: "keywords",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            onchange: 0,
            title: "平台：",
            placeholder: "全部",
            name: "platform",
            value: "",
            options: [
              {
                value: "1",
                label: "自助版",
              },
              {
                value: "2",
                label: "家庭版",
              },
              {
                value: "3",
                label: "企业版",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            onchange: 1,
            title: "一级分类：",
            placeholder: "全部",
            name: "cate_one",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            onchange: 2,
            title: "二级分类：",
            placeholder: "全部",
            name: "cate_two",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            onchange: 3,
            title: "三级分类：",
            placeholder: "全部",
            name: "cate_three",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            onchange: 4,
            title: "四级分类：",
            placeholder: "全部",
            name: "cate_four",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "来源：",
            placeholder: "全部",
            name: "type",
            value: "",
            options: [
              {
                value: "",
                label: "全部",
              },
              {
                value: "1",
                label: "小程序",
              },
              {
                value: "2",
                label: "后台",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "收益类型：",
            placeholder: "全部",
            name: "income_type",
            value: "",
            options: [
              {
                value: "",
                label: "全部",
              },
              {
                value: "1",
                label: "共享文档",
              },
              {
                value: "2",
                label: "收费文档",
              },
            ],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "支付时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "pay_time",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      gettypeall: [], //接收总分类
      getmytype: [
        {
          gettype: [], //分类-平台
          gettypechild: [], // 分类-子分类
        },
      ], //分类-配置
      resArr: [],

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 【高级筛选】接收select选择的返回值-并进行函数操作
    selectDatainfoGaoEvent(datas) {
      console.log(datas);
      if (datas.name == "platform") {
        var filterArr1 = this.resArr
          .filter((item) => item.pid == datas.changeinfo.value)
          .map((it) => {
            return { label: it.cate_name, value: it.id };
          });
        var filterArr = [{ label: "全部", value: 0 }, ...filterArr1];
        this.myselect.select[1].options = filterArr;
        this.myselect.select[1].value = filterArr[0].value;
        this.myselect.select[2].options = [];
        this.myselect.select[2].value = "";
        this.myselect.select[3].options = [];
        this.myselect.select[3].value = "";
        this.myselect.select[4].options = [];
        this.myselect.select[4].value = "";
      }
      if (datas.name == "cate_one") {
        const objArr1 = this.resArr
          .filter((item) => item.pid == datas.changeinfo.value)
          .map((it) => {
            return { label: it.cate_name, value: it.id };
          });
        const objArr = [{ label: "全部", value: 0 }, ...objArr1];
        this.myselect.select[2].value = objArr[0].value;
        this.myselect.select[2].options = objArr;
        this.myselect.select[3].options = [];
        this.myselect.select[3].value = "";
        this.myselect.select[4].options = [];
        this.myselect.select[4].value = "";
      }
      if (datas.name == "cate_two") {
        const catetwoArr1 = this.resArr
          .filter((item) => item.pid == datas.changeinfo.value)
          .map((it) => {
            return { label: it.cate_name, value: it.id };
          });
        const catetwoArr = [{ label: "全部", value: 0 }, ...catetwoArr1];
        this.myselect.select[3].options = catetwoArr;
        this.myselect.select[3].value = catetwoArr[0].value;
        this.myselect.select[4].options = [];
        this.myselect.select[4].value = "";
      }
      if (datas.name == "cate_three") {
        const catethreeArr1 = this.resArr
          .filter((item) => item.pid == datas.changeinfo.value)
          .map((it) => {
            return { label: it.cate_name, value: it.id };
          });
        const catethreeArr = [{ label: "全部", value: 0 }, ...catethreeArr1];
        this.myselect.select[4].options = catethreeArr;
        this.myselect.select[4].value = catethreeArr[0].value;
      }
    },
    toArr(tree) {
      let arrs = [];
      let result = [];
      arrs = arrs.concat(tree);
      // 把数组中每一项全部拉平
      while (arrs.length) {
        let first = arrs.shift(); // 弹出第一个元素
        // 直到每一项数据都没有children
        if (first.child) {
          //如果有children
          arrs = arrs.concat(first.child);
          delete first["child"];
        }
        result.push(first);
      }
      return result;
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/sharePrintSource/orderTop",
          {
            keywords: this.pagesdatas.keywords,
            platform: this.pagesdatas.platform,
            cate_one: this.pagesdatas.cate_one,
            cate_two: this.pagesdatas.cate_two,
            cate_three: this.pagesdatas.cate_three,
            cate_four: this.pagesdatas.cate_four,
            type: this.pagesdatas.type,
            income_type: this.pagesdatas.income_type,
            pay_time: this.pagesdatas.pay_time,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/sharePrintSource/orderTop",
          {
            keywords: this.pagesdatas.keywords,
            platform: this.pagesdatas.platform,
            cate_one: this.pagesdatas.cate_one,
            cate_two: this.pagesdatas.cate_two,
            cate_three: this.pagesdatas.cate_three,
            cate_four: this.pagesdatas.cate_four,
            type: this.pagesdatas.type,
            income_type: this.pagesdatas.income_type,
            pay_time: this.pagesdatas.pay_time,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      // 携带参数跳转
      if (rowdata) {
        // 跳转-文库订单列表
        if (routeraddr == "/libraryorderlist") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                filename: rowdata.sourcename,
              },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/sharePrintSource/orderTop",
          {
            keywords: this.pagesdatas.keywords,
            platform: this.pagesdatas.platform,
            cate_one: this.pagesdatas.cate_one,
            cate_two: this.pagesdatas.cate_two,
            cate_three: this.pagesdatas.cate_three,
            cate_four: this.pagesdatas.cate_four,
            type: this.pagesdatas.type,
            income_type: this.pagesdatas.income_type,
            pay_time: this.pagesdatas.pay_time,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message)
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    // 重置-下拉数据
    resetDownBoxEvent() {
      this.myselect.select[1].options = [];
      this.myselect.select[2].options = [];
      this.myselect.select[3].options = [];
      this.myselect.select[4].options = [];
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      this.pagesdatas = data;
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      var getpay_time = null;
      if (data.pay_time.start == "") {
        getpay_time = "";
      } else {
        if (data.pay_time.end == "") {
          getpay_time =
            data.pay_time.start + " ~ " + (data.pay_time.start + 86400 - 1);
        } else {
          getpay_time =
            data.pay_time.start + " ~ " + (data.pay_time.end + 86400 - 1);
        }
      }
      this.pagesdatas.pay_time = getpay_time;

      axios
        .post(
          "/admin/sharePrintSource/orderTop",
          {
            keywords: data.keywords,
            platform: data.platform,
            cate_one: data.cate_one,
            cate_two: data.cate_two,
            cate_three: data.cate_three,
            cate_four: data.cate_four,
            type: data.type,
            income_type: data.income_type,
            pay_time: getpay_time,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

      // 重置-下拉数据
      this.resetDownBoxEvent();
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用

      //加载loading
      this.loading = true;

      //判断-申请时间段状态
      var getaddtime = null;
      if (data.pay_time.start == "") {
        getaddtime = "";
      } else {
        if (data.pay_time.end == "") {
          getaddtime =
            data.pay_time.start + " ~ " + (data.pay_time.start + 86400 - 1);
        } else {
          getaddtime =
            data.pay_time.start + " ~ " + (data.pay_time.end + 86400 - 1);
        }
      }
      this.pagesdatas.pay_time = getaddtime;

      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/sharePrintSource/orderTop",
          {
            keywords: data.keywords,
            type: data.type,
            pay_time: getaddtime,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

      // 重置-下拉数据
      this.resetDownBoxEvent();
    },
    //数据渲染
    myaxiosfunc(successdata) {
      if (!successdata) {
        this.loading = false;
        return;
      }
      if (successdata.data) var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4;
        // 转换来源
        if (newdata[i].type) {
          if (newdata[i].type === 1) {
            newdata[i].type = "小程序";
          } else {
            newdata[i].type = "后台";
          }
        }
        // 转换收益类型
        if (newdata[i].income_type) {
          if (newdata[i].income_type === 1) {
            newdata[i].income_type = "共享文档";
          } else {
            newdata[i].income_type = "收费文档";
          }
        } else {
          newdata[i].income_type = "未审核";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/sharePrintSource/orderTop",
          {},
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

      //通过API获取配置数据-无限级分类列表
      axios
        .post(
          "/admin/SharePrintSource/sourceCateInfo",
          {},
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // 存储所有的数据
          this.gettypeall = response.data;
          this.getmytype[0].gettype = this.gettypeall;
          this.resArr = this.toArr(response.data);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>