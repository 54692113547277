<template>
  <mainone :mainonebread="mainonebread"></mainone>
  <div class="borde7 bg_glay1">
    <div class="title1">{{ this.title }}</div>
    <div style=" padding-top: 20px" class="bg_white">
      <el-form label-width="200px" style="width: 600px" ref="form1" :model="result" :rules="rules">
        <el-form-item label="导航图标名称:" prop="title">
          <el-input style="100px" v-model="this.result.title" maxlength="6" show-word-limit clearable></el-input>
        </el-form-item>
        <el-form-item label="未选中图片:" prop="icon">
          <mainimgesup ref="imagesupload" :myimgesup="myimgesup1" v-model="this.result.icon"
            :myimgesupisshow="myimgesupisshow" :filelist="filelist1"
            v-on:getuploadfile="(data) => getUpload1FileEvent(data)"></mainimgesup>
        </el-form-item>
        <el-form-item label="选中图片:" prop="click_icon">
          <mainimgesup ref="imagesupload2" :myimgesup="myimgesup2" prop="click_icon" :myimgesupisshow="myimgesupisshow"
            :filelist="filelist2" v-model="this.result.click_icon"
            v-on:getuploadfile="(data) => getUpload2FileEvent(data)"></mainimgesup>
        </el-form-item>
        <el-form-item label="跳转链接:" prop="url_link">
          <el-select v-model="this.result.url_link" placeholder="请选择" style="width: 100%">
            <el-option :label="getsales.title" :value="getsales.path" v-for="(getsales, i) in selecturllink" :key="i">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input style="300px" v-model="this.result.sort" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-radio-group v-model="this.result.status">
            <el-radio :label="1">显示</el-radio>&nbsp;
            <el-radio :label="2">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="显示平台:" prop="platform">
          <el-checkbox-group v-model="this.result.platform">
            <el-checkbox label="1">微信自助版</el-checkbox>
            <el-checkbox label="2">支付宝自助版</el-checkbox>
            <el-checkbox label="3">微信企业版</el-checkbox>
            <el-checkbox label="4">微信家庭版</el-checkbox>
            <el-checkbox label="5">支付宝企业版</el-checkbox>
            <el-checkbox label="6">支付宝家庭版</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <div>
            <el-button type="primary" style="width: 150px" @click="save()">提交</el-button>
            <el-button @click="routeraddrClick('/setfooter')">取消</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainimgesup from "@/components/Mainimgesup.vue";
import { defineComponent } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "vfooteradd",
  data() {
    return {
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      title: "",
      mainonebread: [
        {
          name: "店铺",
        },

        {
          name: "装修",
        },
        {
          name: "底部导航",
          path: "/setfooter",
        },
      ],
      rules: {
        title: [
          {
            required: true,
            message: "导航图标名称必须填写",
            trigger: "blur",
          },
        ],
        click_icon: [
          {
            required: true,
            message: "选中图片必须上传",
            trigger: "change",
          },
        ],
        icon: [
          {
            required: true,
            message: "未选中图片必须上传",
            trigger: "change",
          },
        ],
        url_link: [
          {
            required: true,
            message: "跳转链接必须填写",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "序号必须填写",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "状态为必选项",
            trigger: "blur",
          },
        ],
        platform: [
          {
            required: true,
            message: "平台为必选项",
            trigger: "blur",
          },
        ],
      },
      result: {
        id: 0,
        title: "",
        icon: "",
        click_icon: "",
        url_link: "",
        sort: 1,
        status: 1,
        platform: ['1'],
        addtime: 0,
      },
      // 跳转链接-加载-下拉数据
      selecturllink: [],

      id: "",
      myimgesupisshow: true,
      myimgesup1: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/admin/upload/file", //upload上传API地址
        headers: {
          Authentication: localStorage.getItem("token"),
        }, //upload上传headers
        title: "上传图片", //button-按钮文字
        tips: "建议尺寸：52*52PX，支持gif、png、jpg、jpeg格式，大小不超过1M", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 200, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；200-小程序底部导航图标
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 10000, //文件大小（单位kb）
            type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
            typechart: "jpg/png", //文件格式提示规则
          },
        },
      },
      filelist1: [],
      myimgesup2: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/admin/upload/file", //upload上传API地址
        headers: {
          Authentication: localStorage.getItem("token"),
        }, //upload上传headers
        title: "上传图片", //button-按钮文字
        tips: "建议尺寸：52*52PX，支持gif、png、jpg、jpeg格式，大小不超过1M", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 200, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；200-小程序底部导航图标
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 2000, //文件大小（单位kb）
            type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
            typechart: "jpg/png", //文件格式提示规则
          },
        },
      },
      filelist2: [],
    };
  },
  methods: {
    routeraddrClick(routeraddr) {
      //点击通过路由跳转
      this.$router.push(routeraddr).catch((error) => error);
    },
    save() {
      this.$refs["form1"].validate((valid) => {
        if (valid) {
          if (this.$route.query.id) {
            //修改
            axios
              .post(
                "/admin/Diy/editBottom",
                {
                  id: this.$route.query.id,
                  title: this.result.title,
                  icon: this.result.icon,
                  click_icon: this.result.click_icon,
                  url_link: this.result.url_link,
                  sort: this.result.sort,
                  status: this.result.status,
                  platform: this.result.platform,
                },
                {
                  headers: {
                    Authentication: localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                if (response.data.code == 0) {
                  ElMessage({
                    type: "success",
                    message: response.data.message,
                    duration: 1000,
                    onClose: () => {
                      this.$router.go(-1); // 返回上一页
                    },
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            //添加
            axios
              .post(
                "/admin/Diy/addBottom",
                {
                  title: this.result.title,
                  icon: this.result.icon,
                  click_icon: this.result.click_icon,
                  url_link: this.result.url_link,
                  sort: this.result.sort,
                  status: this.result.status,
                  platform: this.result.platform,
                },
                {
                  headers: {
                    Authentication: localStorage.getItem("token"),
                  },
                }
              )
              .then((response) => {
                if (response.data.code == 0) {
                  ElMessage({
                    type: "success",
                    message: response.data.message,
                    duration: 1000,
                    onClose: () => {
                      this.$router.go(-1); // 返回上一页
                    },
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getUpload1FileEvent(data) {
      this.result.icon = data.url;
      console.log(data.url);
    },
    getUpload2FileEvent(data) {
      this.result.click_icon = data.url;
      console.log(data.url);
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      // 跳转链接-加载-下拉数据
      axios
        .post(
          "/admin/config/info",
          { key_name: "mini_bottom_config" },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.selecturllink = response.data.result;
        })
        .catch(function (error) {
          console.log(error);
        });

      if (this.$route.query.flage == 1) {
        this.title = "添加底部导航";
        // mainone-导航
        let daohang = {
          name: "添加底部导航",
          path: "",
        };
        this.mainonebread.push(daohang)
      } else {
        this.title = "编辑底部导航";
        //  mainone-导航
        let daohang = {
          name: "编辑底部导航",
          path: "",
        };
        this.mainonebread.push(daohang)
        axios
          .post(
            "/admin/Diy/viewBottom",
            {
              id: this.$route.query.id,
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.code == 0) {
              this.result = response.data.result;
              this.filelist1.push({
                name: "未选中图片",
                url: this.result.icon,
              });
              this.filelist2.push({
                name: "选中图片",
                url: this.result.click_icon,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  },
  components: {
    mainone,
    mainimgesup,
  },
});
</script>

<style>
</style>
