<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                        :default-sort="{ prop: 'date', order: 'descending' }"
                        @sort-change="sortchangeEvent"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :sortable="mytabletitle.prop == 'days' ? 'custom' : false"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop === 'client' ||
                                        mytabletitle.prop === 'shop_code_name' ||
                                        mytabletitle.prop === 'putintime' ||
                                        mytabletitle.prop === 'shop_code_user_type_name'
                                "
                            >
                                <!-- 设备编号-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'client'">
                                    <div class="mytabletext">
                                        <span @click="routeraddrClick('/sharedvccontrol', scope.row)"> {{ scope.row.client }}</span>
                                    </div>
                                </div>
                                <!-- 设备编号-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'shop_code_name'">
                                    <div class="mytabletext">
                                        <span @click="routeraddrClick('/agentlist', scope.row)"> {{ scope.row.shop_code_name }}</span>
                                    </div>
                                </div>
                                <!-- 投放时间-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'putintime'">
                                    <div>
                                        <span> {{ scope.row.putintime == 0 ? '' : this.$utils.formatDate(scope.row.putintime).substr(0, 10) }}</span>
                                    </div>
                                </div>
                                <!-- 类型 -->
                                <div v-if="mytabletitle.prop === 'shop_code_user_type_name'">
                                    <span>{{ scope.row.shop_code_user_type_name  }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器 -->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
// import { ElMessage } from "element-plus";
import axios from 'axios'
import { defineComponent } from 'vue'
import CPS from '@/assets/js/componentProperties' // 公共数据

export default defineComponent({
    name: 'vsystemoutprint',
    data() {
        return {
            mainonebread: [
                {
                    name: '统计'
                },
                {
                    name: '告警统计'
                },
                {
                    name: '离线告警统计'
                    // path: "/offlinestatistics",
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: true, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '160px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号：',
                            placeholder: '请输入设备编号',
                            name: 'client',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '代理商：',
                            placeholder: '请输入代理商',
                            name: 'name',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '序列号：',
                            placeholder: '请输入序列号',
                            name: 'mac',
                            content: ''
                        }
                    ]
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '负责人：',
                            placeholder: '请输入负责人', //此name为键值
                            name: 'win_user_id',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Statistics/userList',
                            posttype: 'get',
                            inputval: { truename: '' } //这里记录需要传的参数
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '100px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '类型：',
                            placeholder: '全部',
                            name: 'user_type',
                            value: '',
                            options: [
                                ...[
                                    {
                                        value: '0',
                                        label: '全部'
                                    }
                                ],
                                ...CPS.get('winuserinfo').type
                            ]
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 36, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 50, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'client',
                    label: '设备编号',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'shop_code_name',
                    label: '代理商',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'shop_code_user_type_name',
                    label: '类型',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'shop_code_win_user_id',
                    label: '负责人',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'printname',
                    label: '打印机',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'mac',
                    label: '设备序列号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'ver',
                    label: '版本号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'days',
                    label: '持续离线（天）',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'last_online_timeStr',
                    label: '最近上线时间',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'address',
                    label: '投放地址',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'putintime',
                    label: '投放时间',
                    width: '120'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 0, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //高级筛选
            myinputishow: true, //高级筛选-input单框
            myinput: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%',
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '设备编号：',
                        placeholder: '请输入设备编号',
                        name: 'client',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '代理商：',
                        placeholder: '请输入代理商',
                        name: 'name',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '序列号：',
                        placeholder: '请输入序列号',
                        name: 'mac',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '版本号：',
                        placeholder: '请输入版本号',
                        name: 'ver',
                        content: ''
                    }
                ]
            },
            myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
            myinputonefullgao: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%', //input宽度
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '负责人：',
                        placeholder: '请输入负责人', //此name为键值
                        name: 'win_user_id',
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/admin/Statistics/userList',
                        posttype: 'get',
                        inputval: { truename: '' } //这里记录需要传的参数
                    }
                ]
            },
            myselectisshow: true, //高级筛选-select单框
            myselect: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '打印机类型：',
                        placeholder: '全部',
                        name: 'printname',
                        value: '',
                        options: []
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '类型：',
                        placeholder: '全部',
                        name: 'user_type',
                        value: '',
                        options: [
                            ...[
                                {
                                    value: '0',
                                    label: '全部'
                                }
                            ],
                            ...CPS.get('winuserinfo').type
                        ]
                    }
                ]
            },
            // 负责人类型
            CSP_type: CPS.get('winuserinfo').type,

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {}
            }, //弹框

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // ------------排序--------------------------
        sortchangeEvent(e) {
            console.log(e)

            let orderval = ''
            if (e.order == 'ascending') {
                orderval = 1
            } else {
                orderval = -1
            }

            this.pagesdatas.orderkey = e.prop
            this.pagesdatas.orderval = orderval

            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/offlineStat', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: this.pagesdatas.client,
                        name: this.pagesdatas.name,
                        mac: this.pagesdatas.mac,
                        ver: this.pagesdatas.ver,
                        printname: this.pagesdatas.printname,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        orderkey: e.prop,
                        orderval: orderval,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/offlineStat', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: this.pagesdatas.client,
                        name: this.pagesdatas.name,
                        mac: this.pagesdatas.mac,
                        ver: this.pagesdatas.ver,
                        printname: this.pagesdatas.printname,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        orderkey: this.pagesdatas.orderkey,
                        orderval: this.pagesdatas.orderval,
                        page: 1,
                        page_size: val
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/offlineStat', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: this.pagesdatas.client,
                        name: this.pagesdatas.name,
                        mac: this.pagesdatas.mac,
                        ver: this.pagesdatas.ver,
                        printname: this.pagesdatas.printname,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        orderkey: this.pagesdatas.orderkey,
                        orderval: this.pagesdatas.orderval,
                        page: val,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo)
            this.tancontent.content = taninfo.content
            this.tancontent.title = taninfo.title
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转-机器控制
                if (routeraddr == '/sharedvccontrol') {
                    this.$router.push({ path: routeraddr, query: { clientnumber: rowdata.client } }).catch((error) => error)
                }
                // 跳转-代理商
                if (routeraddr == '/agentlist') {
                    this.$router
                        .push({ path: routeraddr, query: { agentname: rowdata.shop_code_name.split('/')[rowdata.shop_code_name.split('/').length - 1] } })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },

        //导出数据
        exportDatas() {
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/offlineStat', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: this.pagesdatas.client,
                        name: this.pagesdatas.name,
                        mac: this.pagesdatas.mac,
                        ver: this.pagesdatas.ver,
                        printname: this.pagesdatas.printname,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id,
                        export: 1
                    }
                })
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
        searchFormEvent(data) {
            console.log('高级筛选-开始检索返回数据(页面):' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            // var getaddtime = null;
            // if (data.addtime.start == "") {
            //   getaddtime = "";
            // } else {
            //   if (data.addtime.end == "") {
            //     getaddtime =
            //       data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
            //   } else {
            //     getaddtime =
            //       data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
            //   }
            // }
            // this.pagesdatas.addtime = getaddtime;

            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/offlineStat', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: data.client,
                        name: data.name,
                        mac: data.mac,
                        ver: data.ver,
                        printname: data.printname,
                        user_type: data.user_type,
                        win_user_id: data.win_user_id,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true

            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/offlineStat', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: data.client,
                        name: data.name,
                        mac: data.mac,
                        ver: data.ver,
                        printname: data.printname,
                        user_type: data.user_type,
                        win_user_id: data.win_user_id,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            console.log(newdata)
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },

        // 获取-打印机类型-下拉信息
        getPrintName(configinfo) {
            //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
            var alldatas = Array()
            var num = 0
            alldatas[num] = { value: num, label: '全部' }
            for (var key in configinfo) {
                num = num + 1
                alldatas[num] = { value: configinfo[key], label: configinfo[key] }
            }
            //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
            this.myselect.select[0].options = alldatas
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // 打印机类型-下拉-数据
            axios
                .get('/admin/Equipment/getPrintName', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: this.queryParam
                })
                .then((response) => {
                    this.getPrintName(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })

            //加载loading
            this.loading = true

            // 定义
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/offlineStat', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: this.pagesdatas
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
