<template>
    <!-- 富文本-组件设置 -->
    <div>
        <!-- 标题 -->
        <div class="pages-box-small-title small-content-title-title-one">
            富文本设置
        </div>
        <div class="pages-box-small-content">
            <!-- 背景颜色 -->
            <div>
                <div class="small-content-title-title">背景颜色</div>
                <div class="small-content-title-content content-box-line">
                    <div>
                        <span>{{ this.richtextformData.backgroundcolor }}</span>
                    </div>
                    <div style="display: flex; flex-direction: row">
                        <div style="margin-right: 15px">
                            <el-button type="text" @click="resultColorEvent">重置
                            </el-button>
                        </div>
                        <div>
                            <el-color-picker v-model="this.richtextformData.backgroundcolor"
                                style="vertical-align: middle" @active-change="(data) => clickColorEvent(data)" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 富文本内容 -->
            <div>
                <div class="small-content-title-title">富文本内容</div>
                <div class="small-content-title-content content-box-line">
                    <TEditor ref="editorfile" v-model="this.richtextformData.value"
                        v-on:geteditordatas="(data) => { getEditorDatasEvent(data) }" />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { reactive } from "vue";
import TEditor from "@/components/Maineditor.vue";

export default {
    name: "richtextshow",
    props: ["richtextform"],
    setup(props) {
        const richtextformData = reactive(props["richtextform"]);

        return {
            richtextformData
        };
    },
    methods: {
        // 【富文本】-背景颜色-修改
        clickColorEvent(data) {
            this.richtextformData.backgroundcolor = data;

            this.subformEvent(this.richtextformData);
        },
        // 【富文本】-背景颜色-重置
        resultColorEvent() {
            this.richtextformData.backgroundcolor = "#ffffff";
            this.subformEvent(this.richtextformData);
        },
        // 【富文本】-文本数据
        getEditorDatasEvent(datas) {
            this.richtextformData.value = datas
            this.subformEvent(this.richtextformData)
        },
        // 组件数据返回到父组件
        subformEvent(data) {
            this.$emit("getrichtextsetdata", data);
        },
        
        // 富文本-数据返显
        postValueEvent(data) {
            this.$refs.editorfile.postValue(data);
        },

    },
    watch: {
        // 监听-数据更新
        richtextform(val) {
            this.richtextformData = val;
        },
    },
    components: {
        TEditor,
    },
};
</script>

<style scoped>
.small-content-title-content >>> .tox-fullscreen{
    padding: 80px 5px 0px 240px;
}
.small-content-title-title-one {
    border-bottom: 1px solid #e8eaec;
    padding-bottom: 10px;
}

.small-content-title-title {
    font-size: 14px;
    margin: 10px 0;
}

.small-content-title-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.content-box-line {
    padding: 10px;
    border: 1px solid #e8eaec;
    border-radius: 4px;
}

.pages-box-small-content{
    height: 700px;
    overflow: hidden;
    overflow-y: visible;
}
</style>