<template>
    <div>
        <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">
                        <i class="el-icon-info"></i> 当前订单状态 :
                        {{ mainpagedata.datas.pay_status_name }}
                    </div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px"></maindetails>
                    <div style="margin-bottom: 20px; margin-top: 10px" v-if="this.print_type == 2">
                        <div style="min-width: 80px; float: left; margin-right: 20px">
                            <span style="font-size: 14px; color: #333333; margin-left: 8px">证件照电子版:</span>
                        </div>
                        <div>
                            <mainimgesshow :imgesdata="mainpagedata.imgesdata"></mainimgesshow>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details1" style="margin-top: 20px">
                        <template #contentJumpbt="{ detailsdatainfo }">
                            <div v-if="this.printstatus == 1" style="color: #465eff; cursor: pointer" @click="addTanClick(getrowdata, (dialogVisible = true))">
                                {{ detailsdatainfo.jumpbt }}
                            </div>
                            <div v-if="this.printstatus == 2">
                                {{ detailsdatainfo.jumpbt }}
                            </div>
                        </template>
                    </maindetails>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details2"></maindetails>
                    <el-divider></el-divider>
                    <maindetails :detailsdata="mainpagedata.details3"></maindetails>
                </div>
            </div>
        </div>

        <!--弹出框-打印记录-->
        <el-dialog v-model="dialogVisible" title="打印记录" :width="this.searchwidth" :before-close="handleCloseSenior">
            <div>
                <el-table :data="formdata" style="width: 100%" border>
                    <el-table-column label="文档信息" prop="file">
                        <template #default="scope">
                            <div>
                                <div style="font-size: 16px; color: #333333; margin: 10px 0">
                                    {{ scope.row.file.filename }}
                                </div>
                                <div style="font-size: 13px; color: #999999">打印范围 {{ scope.row.file.start }}~{{ scope.row.file.end }} 页 {{ scope.row.file.number }} 份</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="打印状态" prop="status" width="150">
                        <template #default="scope">
                            <div>
                                <div
                                    :style="{
                                        color: scope.row.status == '待打印' ? '#f56c6c' : '#67c23a'
                                    }"
                                >
                                    {{ scope.row.status }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import maindetails from '@/components/Maindetails'
import mainimgesshow from '@/components/Mainimgesshow'
import axios from 'axios'
export default {
    name: 'orderinfo',
    data() {
        return {
            shareOrderFileStatus: 1,

            mainpagedata: {
                mainonebread: [
                    {
                        name: '订单'
                    },
                    {
                        name: '打印管理'
                    },
                    {
                        name: '订单列表',
                        path: '/orderlist'
                    },
                    {
                        name: '订单详情'
                    }
                ], // 当前页-【面包屑导航】

                // 打印类型-判断是否需要展示-证件照图片框
                print_type: 1,

                datas: {
                    pay_status_name: ''
                },
                details: {
                    showstatus: 1, //确定样式模块
                    title: '订单详情',
                    button: {
                        isshow: false,
                        name: '查看微信投诉详情',
                        jump: ''
                    },
                    content: []
                }, //关于详情信息的组件
                details1: {
                    showstatus: 1, //确定样式模块
                    title: '打印详情',
                    button: {
                        isshow: false,
                        name: '',
                        jump: ''
                    },
                    content: []
                }, //关于详情信息的组件
                details2: {
                    showstatus: 1, //确定样式模块
                    title: '支付详情',
                    button: {
                        isshow: false,
                        name: '',
                        jump: ''
                    },
                    content: []
                }, //关于详情信息的组件
                details3: {
                    showstatus: 1, //确定样式模块
                    title: '用户详情',
                    button: {
                        isshow: false,
                        name: '',
                        jump: ''
                    },
                    content: []
                }, //关于详情信息的组件
                imgesdata: {
                    showstatus: 2,
                    content: []
                } //关于图片展示的组件
            }, // 当前Pagedata所需的data数据与控制

            // 打印中-弹窗
            dialogVisible: false,
            searchwidth: '580px',
            formdata: [
                {
                    file: {
                        filename: 'filename123',
                        start: '1',
                        end: '3',
                        number: 1
                    },
                    status: '待打印'
                }
            ],
            // 打印中-状态-维护字段-【当数据为空时，打印状态不可点击,1可以点击,2不可点击】
            printstatus: 1,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            loading: false
        }
    },
    methods: {
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shareorder/info',
                    {
                        trade_no: this.$route.query.orderno || this.$route.query.extend_no,
                        platform: this.$route.query.platform ? this.$route.query.platform : '1-1'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.mainpagedata.datas = response.data.result
                    let getdatas = response.data.result

                    var content1 = [] //订单详情
                    content1 = [
                        {
                            name: '订单编号:',
                            value: this.mainpagedata.datas.trade_no
                        },
                        {
                            name: '订单状态:',
                            value: this.mainpagedata.datas.pay_status_name
                        },
                        {
                            name: '设备编号:',
                            value: this.mainpagedata.datas.ShareClient ? this.mainpagedata.datas.ShareClient.client : ''
                        },
                        {
                            name: '代理商:',
                            value: this.mainpagedata.datas.shop_user_name
                        },
                        {
                            name: '平台来源:',
                            value: this.mainpagedata.datas.thirdparty_plateform
                        }
                    ]
                    this.mainpagedata.details.content = content1
                    // 若ShareOrderFile为空数组、打印状态不可点
                    this.shareOrderFileStatus = this.mainpagedata.datas.ShareOrderFile ? (this.mainpagedata.datas.ShareOrderFile.length > 0 ? 1 : 0) : 0
                    var content2 = [] //打印详情
                    content2 = [
                        {
                            name: '打印状态:',
                            value: '',
                            jumpbt: getdatas.print_status_name + ' >>'
                        },
                        {
                            name: '打印分类:',
                            value: this.mainpagedata.datas.SharePrintCate.printtype_name
                        },
                        {
                            name: '打印类型:',
                            value: this.mainpagedata.datas.SharePrintCate.aliasname
                        },
                        {
                            name: '总份数:',
                            value: this.mainpagedata.datas.countnumber + '份'
                        },
                        {
                            name: '总页数:',
                            value: this.mainpagedata.datas.countpages + '页'
                        },
                        {
                            name: '打印费(含服务费):',
                            value: this.mainpagedata.datas.printtotal
                        },
                        {
                            name: '文档使用费:',
                            value: this.mainpagedata.datas.mobetotal
                        },
                        {
                            name: '三方服务费:',
                            value: this.mainpagedata.datas.mattingprice
                        },
                        {
                            name: '设备会员:',
                            value: this.mainpagedata.datas.monthlytotal
                        },
                        {
                            name: '文库类型:',
                            value: this.mainpagedata.datas.sourcetype_name

                        }
                    ]
                    this.mainpagedata.details1.content = content2

                    //支付详情
                    var content3 = []
                    content3 = [
                        {
                            name: '优惠方式:',
                            value: this.mainpagedata.datas.discount_type_name
                        },
                        {
                            name: '优惠金额:',
                            value: this.mainpagedata.datas.discount_price.length > 0 ? this.mainpagedata.datas.discount_price.join('\n') : ''
                        },
                        {
                            name: '实付金额:',
                            value: this.mainpagedata.datas.sftotal
                        },
                        // {
                        //     name: '分佣金额:',
                        //     value: this.mainpagedata.datas.companyprice
                        // },
                        {
                            name: '支付方式:',
                            value: this.mainpagedata.datas.paytype_name
                        },
                        {
                            name: '支付时间:',
                            value: this.mainpagedata.datas.pay_time == null ? '' : this.$utils.formatDate(this.mainpagedata.datas.pay_time)
                        }
                    ]
                    this.mainpagedata.details2.content = content3

                    //用户详情
                    var content4 = []
                    content4 = [
                        {
                            name: '用户手机号:',
                            value: this.mainpagedata.datas.mobile
                        },
                        {
                            name: '用户ID:',
                            value: this.mainpagedata.datas.uid
                        },
                        {
                            name: '创建时间:',
                            value: this.$utils.formatDate(this.mainpagedata.datas.addtime)
                        }
                    ]
                    this.mainpagedata.details3.content = content4

                    // 证件照-通过打印类型判断是否显示，1不显示，2显示
                    this.print_type = getdatas.print_type
                    //渲染-图片（证件照）
                    let getimages = []
                    if (getdatas.ShareOrderFile) {
                        getdatas.ShareOrderFile.forEach((element) => {
                            let data = {
                                url: element.file_path,
                                preview: [element.file_path]
                            }
                            getimages.push(data)
                        })
                    }
                    this.mainpagedata.imgesdata.content = getimages

                    // 显示-弹窗-打印记录信息
                    let printinfo = response.data.result.ShareOrderFile
                    let nowform = []
                    if (printinfo.length > 0) {
                        printinfo.forEach((element) => {
                            let datas = {
                                file: {
                                    filename: element.file_name,
                                    start: element.start,
                                    end: element.end,
                                    number: element.number
                                },
                                status: element.status_name
                            }
                            nowform.push(datas)
                        })
                    }
                    this.formdata = nowform
                    // 打印记录显示与否判断-维护点击状态字段
                    if (nowform.length > 0) {
                        this.printstatus = 1
                    } else {
                        this.printstatus = 2
                    }

                    this.loading = false
                })
                .catch(function (error) {
                    console.log(error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.flex-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.flex-box-item {
    font-size: 14px;
    color: #333333;
    line-height: 40px;
    min-width: 300px;
    max-width: 500px;
}
</style>
