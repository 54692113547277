<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">添加优惠券</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->

                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="4">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="12">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="160px" class="demo-ruleForm">
                                <el-form-item label="适用渠道：" prop="type">
                                    <el-radio-group v-model="ruleForm.type" @change="typechange">
                                        <el-radio-button :label="1">打印</el-radio-button>
                                        <el-radio-button :label="2">文库</el-radio-button>
                                        <el-radio-button :label="3">plus会员</el-radio-button>
                                        <el-radio-button :label="4">电子证件照</el-radio-button>
                                        <el-radio-button :label="5">商家券</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item :label="ruleForm.type == 5 ? '商家券名称：' : '优惠券名称：'" prop="title">
                                    <el-input v-model="ruleForm.title" placeholder="如:国庆节XX券，最多10个字" maxlength="10" show-word-limit clearable></el-input>
                                </el-form-item>
                                <el-form-item label="名称备注：" prop="name">
                                    <el-input v-model="ruleForm.name" placeholder="仅内部可见，最多10个字" maxlength="10" show-word-limit clearable> </el-input>
                                </el-form-item>
                                <el-form-item label="总发行量：" prop="number">
                                    <el-input v-model="ruleForm.number" placeholder="只能输入正整数，最大为10000" max="10000" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="面额：" prop="money">
                                    <el-input v-model="ruleForm.money" clearable>
                                        <template #append>元</template>
                                    </el-input>
                                    <div style="height: 20px; line-height: 20px">
                                        <span style="font-size: 12px; color: #999999">面值只能是数值，0.01-1000，限2位小数</span>
                                    </div>
                                </el-form-item>
                                <el-form-item label="使用门槛：">
                                    <el-radio-group v-model="ruleForm.full">
                                        <el-radio :label="0">不限制</el-radio>
                                        <el-radio :label="1"
                                            >满
                                            <el-input v-model="ruleForm.fullN" style="width: 150px" clearable></el-input>
                                            元可用
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="仅限新用户：">
                                    <el-radio-group v-model="ruleForm.is_new">
                                        <el-radio :label="-1">不限制</el-radio>
                                        <el-radio :label="1">限制</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="每人限领：">
                                    <el-radio-group v-model="ruleForm.limits">
                                        <el-radio :label="0">不限次数</el-radio>
                                        <el-radio :label="1">
                                            <el-input v-model="ruleForm.limitsN" style="width: 220px" placeholder="请输入每人限领次数" clearable>
                                                <template #append>次</template>
                                            </el-input>
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="用券时间：">
                                    <el-radio-group v-model="ruleForm.time_type">
                                        <el-radio :label="1">
                                            <div style="width: 96%">
                                                日期范围
                                                <el-date-picker
                                                    v-model="ruleForm.begin_time"
                                                    type="datetime"
                                                    placeholder="请选择开始时间"
                                                    style="width: 49%"
                                                    @change="(value) => changeTimes(value, 'begin_time')"
                                                >
                                                </el-date-picker>
                                                至
                                                <el-date-picker
                                                    v-model="ruleForm.end_time"
                                                    type="datetime"
                                                    placeholder="请选择到期时间"
                                                    style="width: 49%"
                                                    @change="(value) => changeTimes(value, 'end_time')"
                                                >
                                                </el-date-picker>
                                            </div>
                                        </el-radio>
                                        <el-radio :label="2">
                                            固定天数
                                            <el-input v-model="ruleForm.fixed_days" style="width: 260px" placeholder="领取后计算到期天数（次日）" clearable>
                                                <template #append>天</template>
                                            </el-input>
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="适用设备：" v-if="is_all_flag">
                                    <el-radio-group v-if="is_all_flag" v-model="ruleForm.is_all" @change="allChange">
                                        <el-radio :label="1">全部设备</el-radio>
                                        <el-radio :label="2"><span @click="allChange(2)">部分设备</span></el-radio>
                                    </el-radio-group>
                                    <el-form-item style="padding-right: 12px" prop="push_device" v-if="value2.length > 0">
                                        <el-select v-model="value2" multiple allow-create style="min-width: 200px; width: 100%" placeholder="请选择" @change="eleChange">
                                            <el-option v-for="item in push_alldvc" :key="item.id" :label="item.client" :value="item.id"> </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form-item>
                                <el-form-item label="领券中心：">
                                    <el-radio-group v-model="ruleForm.is_show">
                                        <el-radio :label="1">显示</el-radio>
                                        <el-radio :label="-1">不显示</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="显示平台：">
                                    <el-checkbox-group v-model="ruleForm.thirdParty">
                                        <el-checkbox :label="1">微信自助版</el-checkbox>
                                        <el-checkbox :label="2">支付宝自助版</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                                <el-form-item :label="ruleForm.type == 5 ? '使用规则：' : '备注：'">
                                    <el-input type="textarea" :rows="2" :placeholder="ruleForm.type == 5 ? '请输入内容' : '请填写备注'" v-model="ruleForm.remark"> </el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <!-- 弹出框 -->
        <el-dialog v-model="dialogVisible" title="选择设备" :width="searchwidth" :before-close="handleCloseSenior">
            <el-form ref="seniorForm" :model="seniorForm" :rules="rules">
                <!--查询条件-->
                <el-form-item>
                    <el-row :gutter="10" style="padding-right: 11px">
                        <el-col :span="24" :xs="24" :sm="8">
                            <el-row>
                                <el-col :span="24" :xs="24" :sm="6">
                                    <span>输入搜索：</span>
                                </el-col>
                                <el-col :span="24" :xs="24" :sm="18">
                                    <el-input v-model="seniorForm.keywords" placeholder="设备编号/代理商名称" clearable></el-input>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="24" :xs="24" :sm="8">
                            <el-row>
                                <el-col :span="24" :xs="24" :sm="6">
                                    <span>投放方式：</span>
                                </el-col>
                                <el-col :span="24" :xs="24" :sm="18">
                                    <el-select v-model="seniorForm.is_buy" placeholder="请选择">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="24" :xs="24" :sm="2">
                            <el-button type="primary" @click="onSubmitTan('seniorForm')">查询 </el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <!-- table表单 :data="tableData.slice((currentPage - lastpage) * PageSize,currentPage * PageSize)" -->
                <el-form-item>
                    <el-table
                        :data="tableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        ref="multipleTable"
                        style="width: 100%"
                        max-height="420"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column type="selection" width="55" />
                        <el-table-column label="id" prop="id" width="150" />
                        <el-table-column label="设备编号" prop="client" width="150" />
                        <el-table-column label="代理商" prop="shop_code_name"></el-table-column>
                        <el-table-column label="投放方式" prop="is_buy">
                            <template #default="scope">
                                <div v-if="scope.row.is_buy == ''"></div>
                                <div v-if="scope.row.is_buy == 1">自投</div>
                                <div v-if="scope.row.is_buy == 2">购买</div>
                                <div v-if="scope.row.is_buy == 3">租赁</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" prop="devstatus" width="150">
                            <template #default="scope">
                                <div v-if="scope.row.devstatus == 1">
                                    <el-button type="primary" size="mini" v-if="scope.row.printstatus_name">{{ scope.row.printstatus_name }} </el-button>
                                    <el-button type="success" size="mini" v-if="scope.row.online_name == '在线'">{{ scope.row.online_name }} </el-button>
                                    <el-button type="danger" size="mini" v-if="scope.row.online_name == '离线'">{{ scope.row.online_name }} </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-row>
                    <el-col :span="24" :xs="24" :sm="16">
                        <div style="margin-top: 5px">
                            <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                pager-count="2"
                                :current-page="currentPage"
                                :page-sizes="pageSizes"
                                :page-size="PageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="totalCount"
                            >
                            </el-pagination>
                        </div>
                    </el-col>
                    <el-col :span="24" :xs="24" :sm="8">
                        <div style="float: right">
                            <el-button @click="resetFormSenior(tableseniorForm, (dialogVisible = false))">取消 </el-button>
                            <el-button type="primary" @click="onSubmitSenior(tableseniorForm, (dialogVisible = false))">确定 </el-button>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vcouponadd',
    data() {
        // 数字input框-校验（可小数）【余额相关配置】
        var checkInputEvent = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }

            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error('请输入数字'))
                } else {
                    let result = value.toString().indexOf('.')
                    if (result != -1) {
                        let getdata = value.toString().split('.')
                        if (getdata[getdata.length - 1].length > 2) {
                            callback(new Error('请保留小数点后两位'))
                        }
                    }
                    if (value < 0) {
                        callback(new Error('值需大于0'))
                    } else {
                        if (value > 1000) {
                            callback(new Error('值需小于1000'))
                        } else {
                            callback()
                        }
                    }
                }
            }, 500)
        }
        // 数字input框-校验-(仅整数)【赚钱页配置||】
        var checkInputEventInt = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf('.')
                if (result != -1) {
                    callback(new Error('请输入整数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入整数'))
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error('请输入整数'))
                        } else {
                            if (value < 0) {
                                callback(new Error('值需大于0'))
                            } else {
                                // console.log('校验中的值：', this.ruleForm.type)
                                // 校验-适用渠道为5时候值必须大于100万
                                if (this.ruleForm.type == 5) {
                                    if (value < 1000000) {
                                        callback(new Error('值需大于1000000'))
                                    } else {
                                        callback()
                                    }
                                } else {
                                    if (value > 10000) {
                                        callback(new Error('值需小于10000'))
                                    } else {
                                        callback()
                                    }
                                }
                            }
                        }
                    }
                }
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '营销玩法'
                },
                {
                    name: '优惠券',
                    path: '/coupon'
                },
                {
                    name: '添加优惠券'
                    // path: '/luckydraw'
                }
            ], // 当前页-【面包屑导航】
            ruleForm: {
                type: 1,
                title: '',
                name: '',
                number: '',
                money: '',
                full: 0,
                fullN: '',
                is_new: -1,
                limits: 0,
                limitsN: '',
                time_type: 1,
                use_time: '',
                begin_time: '',
                end_time: '',
                fixed_days: '',
                is_all: 1,
                partake_list: '',
                is_show: -1,
                thirdParty: [1],
                remark: ''
            }, //form表单
            rules: {
                title: [
                    {
                        required: true,
                        message: '请填写优惠券名称',
                        trigger: 'blur'
                    }
                ],
                number: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                money: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ]
            }, //form表单-必填规则

            is_all_flag: true,
            options: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: '0',
                    label: '自投'
                },
                {
                    value: '1',
                    label: '购买'
                },
                {
                    value: '2',
                    label: '租赁'
                }
            ],

            value2: [], //多选设备存储id值

            tagshownum: 10, //tag显示数量，默认是10条
            push_alldvc: [], //获取的投放设备-与tableseniorForm中的alldvc同步，通过弹窗【确认】获得

            //弹框-查询设备-form表单数据
            seniorForm: {
                keywords: '',
                is_buy: ''
            },
            dialogVisible: false,
            searchwidth: '1024px',
            //弹窗中-selectcity选框
            myselectcityisshow: true,
            myselectcity: {
                selectwidth: '100%',
                select: {
                    ismust: false,
                    name: 'area_code',
                    valuesheng: '',
                    valueshi: '',
                    valuequ: '',
                    disshi: true,
                    disqu: true
                }
            },
            //弹窗中-table表单
            tableData: [],
            tableseniorForm: {
                dvcids: [],
                alldvc: [],
                is_all: 0
            },
            //弹窗中-table表单-分页器
            totalCount: 0, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            currentPage: 1,
            pageSizes: [10, 20, 50, 100, 1000],
            PageSize: 10,

            loading: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
            changeAllDvc: [], //等待全选的所有数据

            uploadParams: {}
        }
    },
    methods: {
        typechange(val) {
            if (val !== 1) {
                this.is_all_flag = false
                // this.value2 = [];
            } else {
                this.is_all_flag = true
            }
        },
        //点击关闭-弹窗
        handleCloseSenior(done) {
            done()
            if (this.value2.length == 0) {
                this.ruleForm.is_all = 1
            }
        },
        //弹窗中-分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.loading = true
            axios
                .post(
                    '/admin/Coupon/device',
                    {
                        keywords: this.pagesdatas.keywords,
                        is_buy: this.pagesdatas.is_buy,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    var successdata = response.data.result
                    var newdata = successdata.data
                    for (var i = 0; i < newdata.length; i++) {
                        //渲染-状态-该字段表示设备状态按钮
                        newdata[i].devstatus = 1
                    }
                    this.tableData = newdata
                    this.totalCount = successdata.total
                    this.currentpage = successdata.current_page //当前页面页数
                    this.lastpage = successdata.last_page
                    this.loading = false
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //弹窗中-分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.loading = true
            axios
                .post(
                    '/admin/Coupon/device',
                    {
                        keywords: this.pagesdatas.keywords,
                        is_buy: this.pagesdatas.is_buy,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    var successdata = response.data.result
                    var newdata = successdata.data
                    for (var i = 0; i < newdata.length; i++) {
                        //渲染-状态-该字段表示设备状态按钮
                        newdata[i].devstatus = 1
                    }
                    this.tableData = newdata
                    this.totalCount = successdata.total
                    this.currentpage = successdata.current_page //当前页面页数
                    this.lastpage = successdata.last_page
                    this.loading = false
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //弹窗中-table表单-多选按钮
        handleSelectionChange(val) {
            console.log('获取的 val is: ' + val)

            // 存储所选择的设备
            this.tableseniorForm.alldvc = val
        },
        //弹窗中-查询按钮-提交
        onSubmitTan(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.pagesdatas = getdatas //将本次的筛选条件存储在pagesdatas中，便于切换pages时携带参数！
                    this.loading = true
                    axios
                        .post(
                            '/admin/Coupon/device',
                            {
                                keywords: getdatas.keywords,
                                is_buy: getdatas.is_buy == '' ? 0 : getdatas.is_buy - 0,
                                page_size: 10,
                                page: 1
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            var successdata = response.data.result
                            var newdata = successdata.data
                            for (var i = 0; i < newdata.length; i++) {
                                //渲染-状态-该字段表示设备状态按钮
                                newdata[i].devstatus = 1
                            }
                            this.tableData = newdata
                            this.totalCount = successdata.total
                            this.currentpage = successdata.current_page //当前页面页数
                            this.lastpage = successdata.last_page
                            this.loading = false
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 1100) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '1024px'
            }
        },
        //去重方法
        unique(arr, val) {
            const res = new Map()
            return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1))
        },
        //弹出框-选择设备-确认
        onSubmitSenior(formData) {
            console.log('queren:' + JSON.stringify(formData.alldvc))
            let arrdata = formData.alldvc
            if (this.value2.length == 0) {
                this.push_alldvc = formData.alldvc
                let arrNew = []
                formData.alldvc.forEach((item) => {
                    arrNew.push(item.id)
                })
                this.value2 = arrNew
            } else {
                let newdata = [...this.push_alldvc, ...arrdata]
                let arr2 = []
                arr2 = this.unique(newdata, 'id')
                this.push_alldvc = arr2
                let arrNew = []
                arr2.forEach((item) => {
                    arrNew.push(item.id)
                })
                this.value2 = arrNew
            }
        },
        //弹出框-选择设备-取消
        resetFormSenior(datas) {
            // this.$refs["multipleTable"].clearSelection();
            console.log('this.tableseniorForm:' + JSON.stringify(datas))
            if (this.value2.length == 0) {
                this.ruleForm.is_all = 1
            }

            this.seniorForm = {
                keywords: '',
                is_buy: ''
            }
            this.myselectcity.select = {
                ismust: false,
                name: 'area_code',
                valuesheng: '',
                valueshi: '',
                valuequ: '',
                disshi: true,
                disqu: true
            }
        },
        allChange(e) {
            console.log(e)
            if (e == 2) {
                console.log(e)
                this.pagesdatas = this.seniorForm
                this.loading = true
                axios
                    .post(
                        '/admin/Coupon/device',
                        {
                            page_size: this.PageSize,
                            page: 1,
                            is_buy: 0
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        var successdata = response.data.result
                        var newdata = successdata.data
                        for (var i = 0; i < newdata.length; i++) {
                            //渲染-状态-该字段表示设备状态按钮
                            newdata[i].devstatus = 1
                        }
                        this.tableData = newdata
                        this.totalCount = successdata.total
                        this.currentpage = successdata.current_page //当前页面页数
                        this.lastpage = successdata.last_page
                        this.loading = false
                        this.changeAllDvc = newdata
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
                this.dialogVisible = true
            }
        },
        //多选下拉框监听
        eleChange(e) {
            console.log(e)
            if (e.length == 0) {
                this.ruleForm.is_all = 1
                this.value2 = []
                this.push_alldvc = []
            }
        },
        //选择时间并转换
        changeTimes(data, chart) {
            console.log('当前获取的时间：' + data)
            this.ruleForm[chart] = new Date(data).getTime()
            // console.log((new Date(data).getTime())/ 1000)
            //比较开始时间与结束时间
            if (this.ruleForm.begin_time == '') {
                ElMessage({
                    type: 'warning',
                    message: '请先选择开始时间！'
                })
                this.ruleForm.end_time = ''
            } else {
                if (this.ruleForm.end_time != '') {
                    if (this.ruleForm.begin_time > this.ruleForm.end_time) {
                        ElMessage({
                            type: 'warning',
                            message: '选择的结束时间必须大于开始时间！'
                        })
                        this.ruleForm.end_time = ''
                    }
                }
            }
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            var formdatas = this.$refs[formName].model
            var datas = ''
            // console.log(formdatas)
            // console.log(this.value2)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (formdatas.full == 1 && !formdatas.fullN) {
                        this.$message({
                            message: '请设置使用门槛',
                            type: 'warning'
                        })
                    } else if (formdatas.limits == 1 && !formdatas.limitsN) {
                        this.$message({
                            message: '请设置每人限领次数',
                            type: 'warning'
                        })
                    } else if (formdatas.time_type == 1 && !formdatas.begin_time) {
                        this.$message({
                            message: '请选择开始时间',
                            type: 'warning'
                        })
                    } else if (formdatas.time_type == 1 && !formdatas.end_time) {
                        this.$message({
                            message: '请选择结束时间',
                            type: 'warning'
                        })
                    } else if (formdatas.time_type == 2 && !formdatas.fixed_days) {
                        this.$message({
                            message: '请设置固定天数',
                            type: 'warning'
                        })
                    } else if (formdatas.type == 1 && formdatas.is_all == 2 && this.value2.length == 0) {
                        this.$message({
                            message: '请选择优惠券适用设备',
                            type: 'warning'
                        })
                    } else {
                        let times = formdatas.begin_time / 1000 + ' ~ ' + formdatas.end_time / 1000
                        if (formdatas.type != 1) {
                            datas = {
                                type: formdatas.type,
                                title: formdatas.title,
                                name: formdatas.name,
                                number: formdatas.number,
                                money: formdatas.money,
                                full: formdatas.full == 0 ? 0 : formdatas.fullN,
                                is_new: formdatas.is_new,
                                limits: formdatas.limits == 0 ? 0 : formdatas.limitsN,
                                time_type: formdatas.time_type,
                                use_time: times,
                                fixed_days: formdatas.fixed_days,
                                is_show: formdatas.is_show,
                                thirdparty: formdatas.thirdParty.join(),
                                remark: formdatas.remark
                            }
                        } else {
                            datas = {
                                type: formdatas.type,
                                title: formdatas.title,
                                name: formdatas.name,
                                number: formdatas.number,
                                money: formdatas.money,
                                full: formdatas.full == 0 ? 0 : formdatas.fullN,
                                is_new: formdatas.is_new,
                                limits: formdatas.limits == 0 ? 0 : formdatas.limitsN,
                                time_type: formdatas.time_type,
                                use_time: times,
                                fixed_days: formdatas.fixed_days,
                                is_all: formdatas.is_all,
                                partake_list: this.value2.join(),
                                is_show: formdatas.is_show,
                                thirdparty: formdatas.thirdParty.join(),
                                remark: formdatas.remark
                            }
                        }
                        this.subDatas(datas)
                    }
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        //提交API-函数
        subDatas(datas) {
            axios
                .post('/admin/Coupon/add', datas, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: '添加成功！',
                            duration: 1000,
                            onClose: () => {
                                this.$router.push('/coupon').catch((error) => error)
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()
        }
    },
    components: {
        mainone
        // mainimgesup,
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
