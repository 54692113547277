<template>
  <div>
    <!-- 文本 -->
    <div>
      <!-- 标题 -->
      <div class="pages-box-small-title small-content-title-title-one">
        文本设置
      </div>
      <div class="pages-box-small-content">
        <!-- 文本内容 -->
        <div>
          <div class="small-content-title-title">文本内容</div>
          <div class="small-content-title-content">
            <el-input
              v-model="this.textForm.text"
              placeholder="请输入文本内容"
              clearable
              :input="subformEvent(this.textForm)"
            ></el-input>
          </div>
        </div>
        <!-- 显示位置 -->
        <div>
          <div class="small-content-title-title">显示位置</div>
          <div class="small-content-title-content content-box-line">
            <div>
              <span>{{ this.textForm.position }}</span>
            </div>
            <div>
              <el-radio-group v-model="this.textForm.position" size="small">
                <el-radio-button
                  v-for="(item, index) in this.position"
                  :key="index"
                  :label="item.chartname"
                  @change="subformEvent(this.textForm)"
                >
                  {{ item.label }}
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <!-- 字体大小 -->
        <div>
          <div class="small-content-title-title">字体大小</div>
          <div class="small-content-title-content content-box-line">
            <div>
              <span>{{ this.textForm.fontsize }}</span>
            </div>
            <div>
              <el-radio-group v-model="this.textForm.fontsize" size="small">
                <el-radio-button
                  v-for="(item, index) in this.fontsize"
                  :key="index"
                  :label="item.chartname"
                  @change="subformEvent(this.textForm)"
                >
                  <div
                    style="height: 14px; line-height: 14px; font-weight: bolder"
                  >
                    <span :style="{ 'font-size': item.fontsize }">
                      {{ item.label }}
                    </span>
                  </div>
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <!-- 文本粗细 -->
        <div>
          <div class="small-content-title-title">文本粗细</div>
          <div class="small-content-title-content content-box-line">
            <div>
              <span>{{ this.textForm.fontweight }}</span>
            </div>
            <div>
              <el-radio-group v-model="this.textForm.fontweight" size="small">
                <el-radio-button
                  v-for="(item, index) in this.fontweight"
                  :key="index"
                  :label="item.chartname"
                  @change="subformEvent(this.textForm)"
                >
                  <div style="height: 14px; line-height: 14px; font-size: 16px">
                    <span :style="{ 'font-weight': item.fontweight }">
                      {{ item.label }}
                    </span>
                  </div>
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <!-- 文字颜色 -->
        <div>
          <div class="small-content-title-title">文字颜色</div>
          <div class="small-content-title-content content-box-line">
            <div>
              <span>{{ this.textForm.textcolor }}</span>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="margin-right: 15px">
                <el-button type="text" @click="resultColorEvent('textcolor')"
                  >重置</el-button
                >
              </div>
              <div>
                <el-color-picker
                  v-model="this.textForm.textcolor"
                  style="vertical-align: middle"
                  @active-change="(data) => clickColorEvent(data, 'textcolor')"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- 背景颜色 -->
        <div>
          <div class="small-content-title-title">背景颜色</div>
          <div class="small-content-title-content content-box-line">
            <div>
              <span>{{ this.textForm.backgroundcolor }}</span>
            </div>
            <div style="display: flex; flex-direction: row">
              <div style="margin-right: 15px">
                <el-button
                  type="text"
                  @click="resultColorEvent('backgroundcolor')"
                  >重置
                </el-button>
              </div>
              <div>
                <el-color-picker
                  v-model="this.textForm.backgroundcolor"
                  style="vertical-align: middle"
                  @active-change="(data) => clickColorEvent(data, 'backgroundcolor')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "textset",
  props: ["textform"],
  setup(props) {
    const textForm = reactive(props["textform"]);

    return {
      textForm,
    };
  },
  data() {
    return {
      // textForm: {
      //   text: "默认文本",
      //   position: "居左显示",
      //   fontsize: "中（14号）",
      //   fontweight: "常规体",
      //   textcolor: "#333333",
      //   backgroundcolor: "#ffffff",
      // },
      position: [
        {
          chartname: "居左显示",
          label: "居左",
          value: "1",
        },
        {
          chartname: "居中显示",
          label: "居中",
          value: "2",
        },
        {
          chartname: "居右显示",
          label: "居右",
          value: "3",
        },
      ],
      fontsize: [
        {
          chartname: "大（16号）",
          label: "A",
          value: "1",
          fontsize: "16px",
        },
        {
          chartname: "中（14号）",
          label: "A",
          value: "2",
          fontsize: "14px",
        },
        {
          chartname: "小（12号）",
          label: "A",
          value: "3",
          fontsize: "12px",
        },
      ],
      fontweight: [
        {
          chartname: "常规体",
          label: "T",
          value: "1",
          fontweight: "",
        },
        {
          chartname: "加粗体",
          label: "T",
          value: "2",
          fontweight: "bold",
        },
      ],
    };
  },
  methods: {
    // 【文本】-文字||背景颜色-修改
    clickColorEvent(data, flag) {
      if (flag == "textcolor") {
        this.textForm.textcolor = data;
      }
      if (flag == "backgroundcolor") {
        this.textForm.backgroundcolor = data;
      }
      this.subformEvent(this.textForm);
    },
    // 【文本】-文字||背景颜色-重置
    resultColorEvent(flag) {
      if (flag == "textcolor") {
        this.textForm.textcolor = "#333333";
      }
      if (flag == "backgroundcolor") {
        this.textForm.backgroundcolor = "#ffffff";
      }
      this.subformEvent(this.textForm);
    },

    // 组件数据返回到父组件
    subformEvent(data) {
      // 判断-位置
      if (data.position == "居左显示") {
        data.textalignset = "left";
      }
      if (data.position == "居中显示") {
        data.textalignset = "center";
      }
      if (data.position == "居右显示") {
        data.textalignset = "right";
      }

      // 判断-字体大小
      if (data.fontsize == "大（16号）") {
        data.fontsizeset = "16px";
      }
      if (data.fontsize == "中（14号）") {
        data.fontsizeset = "14px";
      }
      if (data.fontsize == "小（12号）") {
        data.fontsizeset = "12px";
      }

      // 判断-字体粗细
      if (data.fontweight == "常规体") {
        data.fontweightset = "400";
      }
      if (data.fontweight == "加粗体") {
        data.fontweightset = "bold";
      }

      this.$emit("gettextsetdata", data);
    },
  },
  watch: {
    // 监听-数据更新
    textform(val) {
      this.textForm = val;
    },
  },
};
</script>

<style scoped>
.small-content-title-title-one {
  border-bottom: 1px solid #e8eaec;
  padding-bottom: 10px;
}

.small-content-title-title {
  font-size: 14px;
  margin: 10px 0;
}

.small-content-title-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content-box-line {
  padding: 10px;
  border: 1px solid #e8eaec;
  border-radius: 4px;
}
</style>

