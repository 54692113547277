<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div class="box-list">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :min-width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'to_cash' ||
                mytabletitle.prop === 'cash'
              ">
                <!-- 待结算金额-->
                <div v-if="mytabletitle.prop === 'to_cash'">
                  <div><span>{{ scope.row.to_cash ? (scope.row.to_cash / 100).toFixed(2) : '0.00' }}</span></div>
                </div>
                <!-- 已结算金额-->
                <div v-if="mytabletitle.prop === 'cash'">
                  <div><span>{{ scope.row.cash ? (scope.row.cash / 100).toFixed(2) : '0.00' }}</span></div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" prop="myoperation" id="operation" :width="mainthreehandle">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-代理商列表-->
                <div>
                  <el-button type="primary" size="mini" v-if="scope.row.to_cash > 0" @click="dealEvent(scope.row, (dialogVisible = true))">处理
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 弹窗-处理 -->
      <el-dialog v-model="dialogVisible" title="处理" :width="this.searchwidth" :before-close="handleCloseSenior">
        <div style="margin-top: 20px;">
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" label-position="left">
            <el-form-item label="结算金额：" prop="money">
              <el-input v-model="ruleForm.money" clearable></el-input>
              <div class="end-box">
                <div><span>待结算金额：</span><span class="end-box-num">{{ this.tocash }}</span><span> 元</span></div>
                <div><span class="end-box-all" @click="clickAllMoneyEvent(this.tocash)">全部结算</span></div>
              </div>
            </el-form-item>
            <div style="text-align: right">
              <el-button @click="resetFormSenior('ruleForm', (dialogVisible = false))">取消</el-button>
              <el-button type="primary" @click="onSubmitSenior('ruleForm')">提交</el-button>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vrechargememberaccount",
  inject: ["reload"], //刷新引用
  data() {
    // 金额效验（可小数）
    var checkInputEvent = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error("必填项不能为空"));
      }
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("值中不能有空格"));
      }

      setTimeout(() => {
        // 判断字符串是否为文字
        if (isNaN(value)) {
          callback(new Error("请输入数字"));
        } else {
          let result = value.toString().indexOf(".");
          if (result != -1) {
            let getdata = value.toString().split(".");
            if (getdata[getdata.length - 1].length > 2) {
              callback(new Error("请保留小数点后两位"));
            }
          }

          if (value < 0) {
            callback(new Error("值需大于0"));
          } else {
            callback();
          }
        }
      }, 500);
    };
    return {
      mainonebread: [
        {
          name: "财务",
        },
        {
          name: "资金对账",
        },
        {
          name: "充值会员结算表",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "250px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "代理商名称/代理商ID/手机号",
              name: "content",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "代理商等级：",
              placeholder: "全部",
              name: "level",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "一级代理",
                  value: "1",
                },
                {
                  label: "二级代理",
                  value: "2",
                },
                {
                  label: "三级代理",
                  value: "3",
                },
              ],
            },

          ],
        },
      }, // 筛选-【筛选查询】-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 9, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 100, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "agent_id",
          label: "ID",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "name",
          label: "代理商名称",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "username",
          label: "手机号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "level",
          label: "代理等级",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "province",
          label: "省级",
          width: "130",
        },
        {
          fixedstatu: false,
          prop: "city",
          label: "市级",
          width: "130",
        },
        {
          fixedstatu: false,
          prop: "address",
          label: "县/区",
          width: "130",
        },
        {
          fixedstatu: false,
          prop: "to_cash",
          label: "待结算金额",
          width: "130",
        },
        {
          fixedstatu: false,
          prop: "cash",
          label: "已结算金额",
          width: "130",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      //弹窗相关数据
      dialogVisible: false, //弹窗显示&隐藏
      // 弹窗-form
      ruleForm: {
        agent_id: '',
        money: '',
      },
      rules: {
        money: [
          {
            required: true,
            message: "结算金额不能为空！",
            trigger: "blur",
          },
          {
            required: true,
            validator: checkInputEvent,
            trigger: "change",
          }
        ],
      },
      tocash: '',

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 点击处理
    dealEvent(data) {
      this.ruleForm.agent_id = data.agent_id;
      this.tocash = data.to_cash ? (data.to_cash / 100).toFixed(2) : 0;
    },
    // 弹窗-点击-全部结算
    clickAllMoneyEvent(data) {
      this.ruleForm.money = data;
    },
    // 弹窗-处理-提交
    onSubmitSenior(formName) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/admin/printWallet/shopToCash",
              {
                agent_id: getdatas.agent_id,
                money: getdatas.money * 100,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = false; //关闭弹窗
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-取消
    resetFormSenior(formName) {
      this.$refs[formName].resetFields();
    },
    // 关闭-弹窗
    handleCloseSenior() {
      this.$refs['ruleForm'].resetFields();
      this.dialogVisible = false;
    },

    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/printWallet/shopCash",
          {
            content: this.pagesdatas.content,
            level: this.pagesdatas.level,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/printWallet/shopCash",
          {
            content: this.pagesdatas.content,
            level: this.pagesdatas.level,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 跳转-用户列表
        if (routeraddr == "/userlist") {
          this.$router
            .push({ path: routeraddr, query: { userid: rowdata.uid } })
            .catch((error) => error);
        }
      } else {
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/admin/printWallet/shopCash",
          {
            content: this.pagesdatas.content,
            level: this.pagesdatas.level,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message)
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      // console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/printWallet/shopCash",
          {
            content: data.content,
            level: data.level,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },


    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/printWallet/shopCash",
          {
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}

.box-list {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  line-height: 40px;
  padding: 0 20px;
}

.end-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #888888;
  line-height: 30px;
}

.end-box-num {
  color: red;
}

.end-box-all {
  color: var(--el-color-primary);
}

.end-box-all:hover {
  cursor: pointer;
  color: blue;
}
</style>