<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
      :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <!-- <el-button
              type="warning"
              plain
              @click="exportDatas(mainthreetableData)"
              >导出</el-button
            > -->
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'images'">
                <div v-if="mytabletitle.prop === 'images'">
                  <!-- 图片显示 -->
                  <div v-if="scope.row.images">
                    <el-image class="imagesshow" :src="scope.row.images" :preview-src-list="[scope.row.images]"
                      fit="cover"  @error="(data)=>errorImagesEvent(data,scope.row)"></el-image>
                  </div>
                  <!-- 视频显示 -->
                  <div v-if="scope.row.video">
                    <div style="height: 140px">
                      <video :src="scope.row.video" controls width="80"
                        poster="https://www.jingshuoprint.com/Static/ShopMange/static/h-ui.admin/images/video.png"></video>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-运营-审核后台广告-->
                <div v-if="mainthreetableope === 24">
                  <el-button type="primary" size="mini" @click="routeraddrClick('/advertlistinfo', scope.row)">查看
                  </el-button>
                  <el-button type="primary" size="mini" v-if="scope.row.status == 2 && this.mypowerlimits.shhtgg_sh"
                    @click="
                      chuliTanClick(scope.row, (dialogVisible = true)),
                      resetFormSenior('seniorForm')
                    ">审核</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" :layout="Layout"
              :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-->
      <el-dialog v-model="dialogVisible" title="广告审核" :width="searchwidth" :before-close="handleCloseSenior">
        <el-form ref="seniorForm" :model="seniorform" :rules="rules" label-width="100px" style="padding-right: 20px">
          <!--以下是左侧字-->
          <el-form-item label="广告主题：">
            <span style="margin-right: 10px">{{ seniorform.name }}</span>
          </el-form-item>
          <el-form-item label="审核结果：" prop="status">
            <el-radio-group v-model="seniorform.status">
              <el-radio :label="3">审核通过 </el-radio>
              <el-radio :label="4">审核不通过 </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="审核备注：" prop="remark">
            <el-input v-model="seniorform.remark" type="textarea"></el-input>
          </el-form-item>
          <div style="text-align: right">
            <el-button @click="resetFormSenior('seniorForm', (dialogVisible = false))">取消</el-button>
            <el-button type="primary" @click="onSubmitSenior('seniorForm')" :disabled="mydisabled">确定</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vadvertexamine",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "运营",
        },
        {
          name: "设备广告管理",
        },
        {
          name: "审核后台广告",
          path: "/advertexamine",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "240px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "广告主题/联系人/联系方式",
              name: "keywords",
              content: "",
            },
          ],
        },
        myinputonefullisshow: true, //筛选查询-自动建议下拉框
        myinputonefull: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "150px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "设备编号：",
              placeholder: "请输入设备编号", //此name为键值
              name: "client",
              content: "",
              id: "", //此id为给后台的id值
              url: "/admin/Equipment/searchClient",
              posttype:'get', 
              inputval: { client: "" }, //这里记录需要传的参数
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "120px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "状态：",
              placeholder: "全部",
              name: "status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "待支付",
                  value: "1",
                },
                {
                  label: "待审核",
                  value: "2",
                },
                {
                  label: "审核通过",
                  value: "3",
                },
                {
                  label: "审核驳回",
                  value: "4",
                },
                {
                  label: "已过期",
                  value: "5",
                },
                {
                  label: "已退款",
                  value: "6",
                },
                {
                  label: "已下架",
                  value: "7",
                },
              ],
            },
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: "到期时间：",
              placeholder: {
                placeholderstart: "请选择开始时间",
                placeholderend: "请选择结束时间",
              },
              name: "end_time",
              value: {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
              },
              rangeseparator: "至", //分隔符
              selectmaxwidth: "100%", //时间段-最大宽度
              marginright: "0", //与右侧距离
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 24, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 120, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: false,
          prop: "name",
          label: "广告主题",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "images",
          label: "广告资源",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "thirdparty_name",
          label: "投放来源",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "push_num",
          label: "投放数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status_name",
          label: "状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "guest_name",
          label: "联系人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "guest_mobile",
          label: "联系方式",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "final_price",
          label: "实付金额",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "advert_time",
          label: "投放时间",
          width: "250",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "提交时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1000, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      Layout: " sizes, prev, pager, next, jumper",

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "广告主题/联系人/联系方式",
            name: "keywords",
            content: "",
          },
        ],
      },
      myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
      myinputonefullgao: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%", //input宽度
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "设备编号：",
            placeholder: "请输入设备编号", //此name为键值
            name: "client",
            content: "",
            id: "", //此id为给后台的id值
            url: "/admin/Equipment/searchClient",
            posttype:'get', 
            inputval: { client: "" }, //这里记录需要传的参数
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "状态:",
            placeholder: "全部",
            name: "status",
            value: "",
            options: [
              {
                label: "全部",
                value: "",
              },
              {
                label: "待支付",
                value: "1",
              },
              {
                label: "待审核",
                value: "2",
              },
              {
                label: "审核通过",
                value: "3",
              },
              {
                label: "审核驳回",
                value: "4",
              },
              {
                label: "已过期",
                value: "5",
              },
              {
                label: "已退款",
                value: "6",
              },
              {
                label: "已下架",
                value: "7",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "投放来源：",
            placeholder: "全部",
            name: "thirdparty",
            value: "",
            options: [
              {
                value: "",
                label: "全部",
              },
              {
                value: "-1",
                label: "租户后台",
              },
              {
                value: "1",
                label: "微信自助版",
              },
              {
                value: "2",
                label: "支付宝自助版",
              },
            ],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "开始时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "begin_time",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "到期时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "end_time",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },

      //分页
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      //弹框-form表单数据
      seniorform: {
        id: "",
        name: "",
        status: 4,
        remark: "",
      },
      rules: {
        status: [
          {
            required: false,
            message: "状态为必选项",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: false,
            message: "反馈详情为必填项",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
      searchwidth: "580px",

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用

      mydisabled: false
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 加载图片失败时，显示的默认图片
    errorImagesEvent(e,rowdata){
      rowdata.images = require('@/assets/imgs/defaultimegs/delimage.jpg')
    },
    
    //弹出框-审核-提交表单
    onSubmitSenior(formName) {
      // 前端防抖
      this.mydisabled = true;

      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/admin/ClientAdvert/audit",
              {
                id: getdatas.id,
                status: getdatas.status,
                remark: getdatas.remark,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = false; //关闭弹窗
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    this.mydisabled = false;
                    this.reload(); //刷新
                  },
                });
              } else {
                this.mydisabled = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              this.mydisabled = false;
            });
        } else {
          console.log("error submit!!");
          this.mydisabled = false;
          return false;
        }
      });
    },
    //弹出框-审核-取消
    resetFormSenior(formName) {
      this.$refs[formName].resetFields();
    },
    //点击按钮-弹出弹框
    chuliTanClick(taninfo) {
      this.seniorform.id = taninfo.id;
      this.seniorform.name = taninfo.name;
    },
    //点击关闭-弹窗
    handleCloseSenior(done) {
      done();
    },

    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ClientAdvert/adminIndex",
          {
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            thirdparty: this.pagesdatas.thirdparty,
            begin_time: this.pagesdatas.begin_time,
            end_time: this.pagesdatas.end_time,
            client: this.pagesdatas.client,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / this.PageSize == this.lastpage) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage += 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ClientAdvert/adminIndex",
          {
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            thirdparty: this.pagesdatas.thirdparty,
            begin_time: this.pagesdatas.begin_time,
            end_time: this.pagesdatas.end_time,
            client: this.pagesdatas.client,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / val == this.PageSize) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage = this.totalCount / this.PageSize;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 携带参数跳转
        this.$router
          .push({ path: routeraddr, query: { id: rowdata.id } })
          .catch((error) => error);
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas(tableData) {
      console.log(tableData);
    },

    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-开始时间段状态
      var getstarttime = null;
      if (data.begin_time.start == "") {
        getstarttime = "";
      } else {
        if (data.begin_time.end == "") {
          getstarttime =
            data.begin_time.start + " ~ " + (data.begin_time.start + 86400 - 1);
        } else {
          getstarttime =
            data.begin_time.start + " ~ " + (data.begin_time.end + 86400 - 1);
        }
      }
      //判断-到期时间段状态
      var getendtime = null;
      if (data.end_time.start == "") {
        getendtime = "";
      } else {
        if (data.end_time.end == "") {
          getendtime =
            data.end_time.start + " ~ " + (data.end_time.start + 86400 - 1);
        } else {
          getendtime =
            data.end_time.start + " ~ " + (data.end_time.end + 86400 - 1);
        }
      }
      this.pagesdatas.begin_time = getstarttime;
      this.pagesdatas.end_time = getendtime;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ClientAdvert/adminIndex",
          {
            keywords: data.keywords,
            status: data.status,
            thirdparty: data.thirdparty,
            begin_time: getstarttime,
            end_time: getendtime,
            client: data.client,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-到期时间段状态
      var getendtime = null;
      if (data.end_time.start == "") {
        getendtime = "";
      } else {
        if (data.end_time.end == "") {
          getendtime =
            data.end_time.start + " ~ " + (data.end_time.start + 86400 - 1);
        } else {
          getendtime =
            data.end_time.start + " ~ " + (data.end_time.end + 86400 - 1);
        }
      }
      this.pagesdatas.end_time = getendtime;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ClientAdvert/adminIndex",
          {
            keywords: data.keywords,
            status: data.status,
            end_time: getendtime,
            client: data.client,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata, flag) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //渲染-图片显示
        if (newdata[i].type == 2) {
          newdata[i].video = newdata[i].file_url;
        } else {
          newdata[i].images = newdata[i].file_url;
        }

        //转换-提交时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无提交时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无提交时间";
        }
        //转换-投放时间-（开始时间与到期时间-拼接）
        if (newdata[i].begin_time !== 0) {
          newdata[i].advert_time =
            "开始时间：" + this.$utils.formatDate(newdata[i].begin_time);
        } else {
          newdata[i].advert_time = "开始时间：";
        }
        if (newdata[i].end_time !== 0) {
          newdata[i].advert_time =
            newdata[i].advert_time +
            "\n 到期时间：" +
            this.$utils.formatDate(newdata[i].end_time);
        } else {
          newdata[i].advert_time = newdata[i].advert_time + "\n 到期时间：永久";
        }
        //   //渲染-删除按钮-仅是“待支付”显示
        //   if (newdata[i].status_name === "待支付") {
        //     newdata[i].delisshow = true;
        //   }
        //   //渲染-审核按钮(有争议-需要标识！)
        //   if (newdata[i].status_name === "待审核") {
        //     newdata[i].examinebtn = true;
        //   }
        //   //渲染-下架-按钮
        //   if (newdata[i].status_name === "审核通过") {
        //     newdata[i].underbtn = true;
        //   }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      // this.totalCount = successdata.total;
      // this.currentpage = successdata.current_page; //当前页面页数
      // this.lastpage = successdata.last_page;
      this.loading = false;

      if (successdata.total == null) {
        // 数据小于当前页数时判断
        if (newdata.length < this.PageSize) {
          if (newdata.length != 0 && this.currentPage == 1) {
            this.totalCount = this.PageSize - 1;
          } else {
            if (newdata.length == 0) {
              // 按照常理：为0会减1页，由于双向绑定问题一直请求服务器，因此先注释，待后续优化
              // this.totalCount = this.PageSize * (this.currentPage - 1)
              this.totalCount = this.PageSize * this.currentPage;
            } else {
              this.totalCount = this.PageSize * this.currentPage;
              this.lastpage = this.currentPage;
            }
          }
        } else {
          if (this.totalCount < this.PageSize) {
            // 重设置-初始值
            this.totalCount = 1000;
            this.Layout = "sizes, prev, pager, next, jumper";
          } else {
            if (flag == 1) {
              this.currentPage = 1;
            }
          }
        }
      } else {
        this.totalCount = successdata.total;
        this.currentpage = successdata.current_page; //当前页面页数
        this.lastpage = successdata.last_page;
        this.Layout = "total, sizes, prev, pager, next, jumper";
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ClientAdvert/adminIndex",
          {
            page_size: this.PageSize,
            page: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
  z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
  margin-left: 3px;
  font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
  z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
  margin-left: 15px;
  font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
  display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
  width: 55px !important;
  margin: 0;
  padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>