<template>
  <div>
    <div class="pages-box-small">
      <!-- 标题 -->
      <div class="pages-box-small-title">{{ this.leftcontent.title }}</div>
      <!-- 组件内容 -->
      <div
        class="pages-box-small-content"
        v-for="(item, index) in this.leftcontent.content"
        :key="index"
      >
        <!-- 内容标题 -->
        <div class="small-content-title">{{ item.title }}</div>
        <!-- 内容小框 -->
        <div class="small-content-title-box">
          <!-- 小框内容 -->
          <div
            class="small-content-title-box-content"
            v-for="(jtem, jndex) in item.content"
            :key="jndex"
            draggable="true"
            @dragstart="dragstart($event, jtem)"
            @click="dragstart($event, jtem)"
          >
            <div class="small-content-title-box-content-icon">
              <i :class="jtem.icon"></i>
            </div>
            <div class="small-content-title-box-content-text">
              {{ jtem.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "subbase",
  data() {
    return {
      leftcontent: {
        title: "组件",
        content: [
          {
            title: "基础组件",
            content: [
              {
                icon: "el-icon-tickets",
                label: "文本",
                value: "1",
              },
              {
                icon: "el-icon-date",
                label: "富文本",
                value: "2",
              },
              {
                icon: "el-icon-picture-outline",
                label: "图片",
                value: "3",
              },
              {
                icon: "el-icon-turn-off",
                label: "按钮",
                value: "4",
              },
              {
                icon: "el-icon-notebook-2",
                label: "联系人表单",
                value: "5",
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    // 开始拖拽
    dragstart(event, data) {
      // console.log(event, data);
      this.$emit('getsubbasedata',data)
    },
  },
};
</script>

<style scoped>
.pages-box-small {
  padding: 10px;
}

.pages-box-small-title {
  font-size: 18px;
}

.pages-box-small-content {
  margin-top: 20px;
}

.small-content-title {
  font-size: 14px;
}

.small-content-title-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.small-content-title-box-content {
  width: 64px;
  border-radius: 4px;
  margin: 15px 15px 0 0;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 1px solid #e8eaec;
  background-color: #f9fafb;
}

.small-content-title-box-content:hover {
  background-color: var(--el-color-primary);
  cursor: pointer;
  border-color: var(--el-color-primary);
}

.small-content-title-box-content:hover div {
  color: #ffffff;
}

.small-content-title-box-content-icon {
  margin: 10px;
  font-size: 30px;
  color: rgb(126, 126, 126);
}

.small-content-title-box-content-text {
  font-size: 12px;
  color: #7f8792;
}
</style>

