<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
            :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--添加XX按钮-点击跳转添加类页面-->
                        <el-button type="primary" class="max_search"
                            @click="routeraddrClick('/dercontractlistadd')">添加</el-button>
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
                        :row-class-name="tableRowClassName" :data="
                            mainthreetableData.slice(
                                (currentPage - lastpage) * PageSize,
                                currentPage * PageSize
                            )
                        " style="width: 100%" min-height="900" border v-loading="loading"
                        element-loading-text="正在飞速加载中..." @selection-change="handleSelectionChange"
                        @cell-click="handleOneData">
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i"
                            :fixed="mytabletitle.fixedstatu" :prop="mytabletitle.prop" :label="mytabletitle.label"
                            :width="mytabletitle.width" :min-width="mytabletitle.minwidth">
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="
                                mytabletitle.prop === 'source' ||
                                mytabletitle.prop === 'name' ||
                                mytabletitle.prop === 'sign_num' ||
                                mytabletitle.prop === 'status' ||
                                mytabletitle.prop === 'financial_name' ||
                                mytabletitle.prop === 'signtime' ||
                                mytabletitle.prop === 'stoptime'
                            ">
                                <!--签约人信息-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'name'">
                                    <div
                                        @click="this.mypowerlimits.jrcphhrlb == true ? routeraddrClick('/derpartnerlist', scope.row) : ''">
                                        <span>{{ scope.row.name }}</span><span
                                            :class="this.mypowerlimits.jrcphhrlb == true ? 'mytabletext' : ''">({{
                                                    scope.row.uid
                                            }})</span>
                                            
                                    </div>
                                </div>
                                <!--来源-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'source'">
                                    <div v-if="scope.row.source == 1">
                                        <span>小程序</span>
                                    </div>
                                    <div v-if="scope.row.source == 2">
                                        <span>后台</span>
                                    </div>
                                </div>
                                <!--签约台数-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'sign_num'">
                                    <div :class="this.mypowerlimits.htsblb == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.htsblb == true ? routeraddrClick('/dercontractdvclist', scope.row) : ''">
                                        <span>{{ scope.row.sign_num }}</span>
                                    </div>
                                </div>
                                <!--合同状态-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div
                                        :style="{ 'color': scope.row.status == 1 ? '#02CB48' : (scope.row.status == -1 ? '#FE8F47' : '#909399') }">
                                        <span>{{ scope.row.status_name }}</span>
                                    </div>
                                </div>
                                <!--产品名称-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'financial_name'">
                                    <span>{{ scope.row.status_name }}</span>
                                </div>
                                <!--合同有效期-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'signtime'">
                                    <span>{{ scope.row.signtime != 0 ?
                                            this.$utils.formatDate(scope.row.signtime).substr(0, 10) : ''
                                    }}</span>
                                    <span> 至 {{ scope.row.endtime != 0 ?
                                            this.$utils.formatDate(scope.row.endtime).substr(0, 10) : ''
                                    }}</span>
                                </div>
                                <!--实际结束时间-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'stoptime'">
                                    <span v-if="scope.row.status == 2 || scope.row.status == 3">{{ scope.row.stoptime !=
                                            0 ?
                                            this.$utils.formatDate(scope.row.stoptime).substr(0, 10) : ''
                                    }}</span>
                                    <span v-else>-</span>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
                            prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-合同列表-->
                                <div>
                                    <el-button type="primary" size="mini" plain
                                        @click="routeraddrClick('/dercontractlistadd', scope.row)"
                                        v-if="(scope.row.source == 2 && scope.row.sign_url && scope.row.sign_url.fileurl == '') || (scope.row.source == 2 && !scope.row.sign_url)">
                                        上传合同</el-button>
                                    <!-- 下载合同正确 -->
                                    <el-button type="primary" size="mini" @click="downloadClick(scope.row)"
                                        v-if="(scope.row.source == 1 && scope.row.sign_url != '') || (scope.row.source == 2 && scope.row.sign_url && scope.row.sign_url.fileurl != '')">
                                        下载合同</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "vdercontractlist",
    inject: ["reload"], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: "金融产品",
                },
                {
                    name: "合伙人",
                },
                {
                    name: "合同列表",
                },
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: "0", //框右侧距离
                    titlewidth: "74px", //标题宽度
                    inputwidth: "180px", //input宽度
                    inputinterval: "20px", //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: "输入搜索：",
                            placeholder: "合同编号",
                            name: "trade_no",
                            content: "",
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: "输入搜索：",
                            placeholder: "签约人姓名/签约人ID",
                            name: "keywords",
                            content: "",
                        },
                    ],
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: "none",
                    divinterval: "10px", //框右侧距离
                    selectwidth: "160px",
                    selectinterval: "", //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: "合同状态：",
                            placeholder: "全部",
                            name: "status",
                            value: "",
                            options: [
                                {
                                    label: "全部",
                                    value: "0",
                                },
                                {
                                    label: "未开始",
                                    value: "-2",
                                },
                                {
                                    label: "审核中",
                                    value: "-1",
                                },
                                {
                                    label: "进行中",
                                    value: "1",
                                },
                                {
                                    label: "已完结",
                                    value: "2",
                                },
                                {
                                    label: "已终止",
                                    value: "3",
                                },
                            ],
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: "签约时间：",
                            placeholder: {
                                placeholderstart: "开始时间",
                                placeholderend: "结束时间",
                            },
                            name: "signtime",
                            value: {
                                valuestart: ref(""),
                                valueend: ref(""),
                                endstatus: true,
                            },
                            rangeseparator: "至", //分隔符
                            selectmaxwidth: "400px", //时间段-最大宽度
                            marginright: "0", //与右侧距离
                        },
                    ],
                },
            }, // 筛选-控件信息
            mainthreeisshowbut: true, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 4, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 100,
            mainthreetabletitle: [
                {
                    fixedstatu: "left",
                    prop: "trade_no",
                    label: "合同编号设备序号",
                    width: "300",
                },
                {
                    fixedstatu: false,
                    prop: "name",
                    label: "签约人信息",
                    width: "160",
                },
                {
                    fixedstatu: false,
                    prop: "source",
                    label: "来源",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "set_name",
                    label: "产品名称",
                    width: "250",
                },
                {
                    fixedstatu: false,
                    prop: "status",
                    label: "合同状态",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "sign_num",
                    label: "签约台数",
                    width: "",
                },
                {
                    fixedstatu: false,
                    prop: "signtime",
                    label: "合同有效期",
                    width: "240",
                },
                {
                    fixedstatu: false,
                    prop: "money",
                    label: "合同金额",
                    width: "100",
                },
                {
                    fixedstatu: false,
                    prop: "stoptime",
                    label: "实际结束时间",
                    width: "120",
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 0, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: "", //通过路由获取当前页面的path值

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用
        };
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path;
    },
    methods: {
        // 下载合同
        downloadClick(data) {
            // 1小程序 2后台
            if (data.source == 1) {
                // 小程序
                window.open(data.sign_url, "_blank");
            } else {
                // 后台
                data.sign_url.fileurl.split(',').forEach(element => {
                    window.open(element, "_blank");
                });
            }
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            this.PageSize = val;
            this.currentPage = 1;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/Lease/deedList",
                    {
                        keywords: this.pagesdatas.keywords,
                        trade_no: this.pagesdatas.trade_no,
                        status: this.pagesdatas.status,
                        signtime: this.pagesdatas.signtime,
                        page: 1,
                        page_size: val,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val;

            //加载loading
            this.loading = true;
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/Lease/deedList",
                    {
                        keywords: this.pagesdatas.keywords,
                        trade_no: this.pagesdatas.trade_no,
                        status: this.pagesdatas.status,
                        signtime: this.pagesdatas.signtime,
                        page: val,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return "warning-row";
            }
            return "";
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, data) {
            if (data) {
                if (routeraddr == '/derpartnerlist') {
                    this.$router
                        .push({ path: routeraddr, query: { userid: data.uid } })
                        .catch((error) => error);
                }
                if (routeraddr == '/dercontractdvclist') {
                    this.$router
                        .push({ path: routeraddr, query: { trade_no: data.trade_no } })
                        .catch((error) => error);
                }
                if (routeraddr == '/dercontractlistadd') {
                    this.$router
                        .push({ path: routeraddr, query: { agreement_id: data.agreement_id } })
                        .catch((error) => error);
                }
            } else {
                this.$router.push(routeraddr).catch((error) => error);
            }
        },
        //导出数据
        exportDatas() {
            axios
                .post(
                    "/admin/Lease/deedList",
                    {
                        keywords: this.pagesdatas.keywords,
                        trade_no: this.pagesdatas.trade_no,
                        status: this.pagesdatas.status,
                        signtime: this.pagesdatas.signtime,
                        export: 1,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data.message);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true;
            //判断-签约时间段状态
            var getsigntime = null;
            if (data.signtime.start == "") {
                getsigntime = "";
            } else {
                if (data.signtime.end == "") {
                    getsigntime =
                        data.signtime.start + " ~ " + (data.signtime.start + 86400 - 1);
                } else {
                    getsigntime =
                        data.signtime.start + " ~ " + (data.signtime.end + 86400 - 1);
                }
            }
            this.pagesdatas.signtime = getsigntime;
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/Lease/deedList",
                    {
                        keywords: data.keywords,
                        trade_no: data.trade_no,
                        status: data.status,
                        signtime: getsigntime,
                        page: 1,
                        page_size: this.PageSize,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data;
            this.mainthreetableData = newdata;
            this.totalCount = successdata.total;
            this.currentpage = successdata.current_page; //当前页面页数
            this.lastpage = successdata.last_page;
            this.loading = false;
        },
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            // 定义
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize,
            };
            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query;
            if (routerinfo.signusername) {
                this.pagesdatas = {
                    keywords: routerinfo.signusername,
                    page_size: this.PageSize,
                };
                this.maintwoforminfo.myinputone.input[1].content = routerinfo.signusername;
            }

            //加载loading
            this.loading = true; //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .post(
                    "/admin/Lease/deedList",
                    this.pagesdatas,
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    components: {
        mainone,
        maintwo,
    },
});
</script>
<style scoped>
/* 弹出框-搜索栏 */
.tan-search-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.tan-search-box-info {
    display: flex;
    flex-direction: row;
    margin: 0 10px 10px 0;
}

.tan-search-box-info-title {
    min-width: 80px;
}

.tan-table-status {
    display: flex;
    flex-direction: row;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.tan-box {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>