<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop == 'level'">
                                <div v-if="mytabletitle.prop == 'level'">
                                    <span>{{ scope.row.level }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vmemberDvcCount',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '权益会员'
                },
                {
                    name: '设备统计'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '90px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '当前代理商:',
                            placeholder: '代理商名/手机号',
                            name: 'keywords',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '当前代理商等级：',
                            placeholder: '全部',
                            name: 'level',
                            value: '',
                            options: [
                                {
                                    value: '',
                                    label: '全部'
                                },
                                {
                                    value: '1',
                                    label: '一级代理商'
                                },
                                {
                                    value: '2',
                                    label: '二级代理商'
                                },
                                {
                                    value: '3',
                                    label: '三级代理商'
                                }
                            ]
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '余额发放：',
                            placeholder: '全部',
                            name: 'income_status',
                            value: '',
                            options: [
                                {
                                    value: '',
                                    label: '全部'
                                },
                                {
                                    value: '-1',
                                    label: '未发放'
                                }
                            ]
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '商家券核销：',
                            placeholder: '全部',
                            name: 'coupon_status',
                            value: '',
                            options: [
                                {
                                    value: '',
                                    label: '全部'
                                },
                                {
                                    value: '-1',
                                    label: '待核销'
                                }
                            ]
                        }
                    ]
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '84px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号:',
                            placeholder: '设备编号', //此name为键值
                            name: 'cid',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/getClientList',
                            inputval: { client: '' } //这里记录需要传的参数
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '一级代理商:',
                            placeholder: '代理商名称',
                            name: 'oneshopid', //此name为键值
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/shopUser/getShopList',
                            inputval: { name: '', level: 1 } //这里记录需要传的参数
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 27, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'client',
                    label: '设备编号',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'type',
                    label: '商家权益',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'shop_name',
                    label: '当前代理商名',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'mobile',
                    label: '当前代理商手机号',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'level',
                    label: '当前代理商等级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'one_shop_name',
                    label: '一级代理商',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'address',
                    label: '设备地址',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'shop_invite',
                    label: '商家邀请',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'user_buy',
                    label: '用户自购',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'income',
                    label: '余额收益',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'issued_income',
                    label: '已发放余额',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'not_issue_income',
                    label: '未发放余额',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'written_off_coupon_money',
                    label: '已核销商家券',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'coupon_money',
                    label: '待核销商家券',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'expired_coupon_money',
                    label: '已过期商家券',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                keywords: this.pagesdatas.keywords,
                level: this.pagesdatas.level,
                income_status: this.pagesdatas.income_status,
                coupon_status: this.pagesdatas.coupon_status,
                cid: this.pagesdatas.cid,
                oneshopid: this.pagesdatas.oneshopid,
                page: this.currentPage,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                keywords: this.pagesdatas.keywords,
                level: this.pagesdatas.level,
                income_status: this.pagesdatas.income_status,
                coupon_status: this.pagesdatas.coupon_status,
                cid: this.pagesdatas.cid,
                oneshopid: this.pagesdatas.oneshopid,
                page: this.currentPage,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //导出数据
        exportDatas() {
            //通过Ajax向后台获取数据/admin/config/info
            this.pagesdatas = {
                keywords: this.pagesdatas.keywords,
                level: this.pagesdatas.level,
                income_status: this.pagesdatas.income_status,
                coupon_status: this.pagesdatas.coupon_status,
                cid: this.pagesdatas.cid,
                oneshopid: this.pagesdatas.oneshopid,
                export: 1
            }

            this.postAxiosEvent(this.pagesdatas, 1)
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = {
                keywords: data.keywords,
                level: data.level,
                income_status: data.income_status,
                coupon_status: data.coupon_status,
                cid: data.cid,
                oneshopid: data.oneshopid,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        // 接口
        postAxiosEvent(data, index) {
            if (!index) this.loading = true
            axios
                .post('/admin/Statistics/vipClientShopCount', data, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (!index) {
                        this.myaxiosfunc(response.data.result)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.tool-tip {
    max-width: 400px;
}
</style>
