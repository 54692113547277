<template>
    <div>
        <el-form ref="myformRef" :model="form" :rules="rules" label-width="260px">
            <el-form-item label="会员等级名称：" prop="name">
                <el-input v-model="form.name" clearable maxlength="10" show-word-limit />
            </el-form-item>
            <el-form-item label="会员金额(元)：" :required="true">
                <div class="h-px-60">
                    <el-form-item label="现价：" prop="price" label-width="80px">
                        <div class="member-money-box">
                            <div><el-input v-model="form.price" clearable maxlength="50" /></div>
                            <div><span>元</span></div>
                        </div>
                    </el-form-item>
                </div>
                <div class="h-px-60">
                    <el-form-item label="原价：" prop="old_price" label-width="80px">
                        <div class="member-money-box">
                            <div><el-input v-model="form.old_price" clearable maxlength="50" /></div>
                            <div><span>元</span></div>
                        </div>
                    </el-form-item>
                </div>
            </el-form-item>
            <el-form-item label="会员有效期(月)：" prop="valid_time">
                <el-input v-model.number="form.valid_time" clearable maxlength="20" />
            </el-form-item>
            <el-form-item label="会员图标：" prop="icon">
                <mainimgesup
                    v-model="form.icon"
                    :myimgesup="myimgesup"
                    :myimgesupisshow="myimgesupisshow"
                    :filelist="form.icon_list"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 1)"
                    v-on:delfiledata="(data) => delfiledataEvent(data, 1)"
                >
                </mainimgesup>
            </el-form-item>
            <el-form-item label="绑定设备打印折扣(折)：" prop="bind_discount">
                <el-input v-model.number="form.bind_discount" clearable maxlength="20" />
            </el-form-item>
            <el-form-item label="学习电子赠品(n选一)：" prop="gift1">
                <modelBindGift
                    :ModelValue="form.gift1"
                    Flag="gift1"
                    :DialogTan="true"
                    PostUrl="/admin/member/cashGiftIndex"
                    PostAxios="post"
                    :TableTitle="tabletitle"
                    @modelValue="(data) => modelValueEvent(data, 'gift1')"
                >
                    <template v-slot:show_content>
                        <div class="gift-box-out">
                            <div class="gift-box-center" v-for="(item, index) in form.gift1" :key="index">
                                <div class="gift-box">
                                    <div>
                                        <el-image :src="item.img" style="width: 80px" />
                                    </div>
                                    <div>
                                        <div>
                                            <span>名称:</span><span>{{ item.name }}</span>
                                        </div>
                                        <!-- <div>
                                            <span>库存:</span><span>{{ item.stock_num }}</span>
                                        </div> -->
                                        <div>
                                            <el-form-item :prop="'gift1.' + index + '.give_num'" :rules="rules.give_num">
                                                <el-input v-model.number="item.give_num" placeholder="赠送数量" clearable maxlength="20" />
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                                <div @click="closeEvent('gift1', index)" class="close-box">
                                    <i class="el-icon-close"></i>
                                </div>
                            </div>
                        </div>
                    </template>
                </modelBindGift>
            </el-form-item>
            <el-form-item label="学习文具赠品(n选一)：" prop="gift2">
                <modelBindGift
                    :ModelValue="form.gift2"
                    Flag="gift2"
                    :DialogTan="true"
                    PostUrl="/admin/member/cashGiftIndex"
                    PostAxios="post"
                    :TableTitle="tabletitle"
                    @modelValue="(data) => modelValueEvent(data, 'gift2')"
                >
                    <template v-slot:show_content>
                        <div class="gift-box-out">
                            <div class="gift-box-center" v-for="(item, index) in form.gift2" :key="index">
                                <div class="gift-box">
                                    <div>
                                        <el-image :src="item.img" style="width: 80px" />
                                    </div>
                                    <div>
                                        <div>
                                            <span>名称:</span><span>{{ item.name }}</span>
                                        </div>
                                        <!-- <div>
                                            <span>库存:</span><span>{{ item.stock_num }}</span>
                                        </div> -->
                                        <div>
                                            <el-form-item :prop="'gift2.' + index + '.give_num'" :rules="rules.give_num">
                                                <el-input v-model.number="item.give_num" placeholder="赠送数量" clearable maxlength="20" />
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                                <div @click="closeEvent('gift2', index)" class="close-box">
                                    <i class="el-icon-close"></i>
                                </div>
                            </div>
                        </div>
                    </template>
                </modelBindGift>
            </el-form-item>
            <el-form-item label="教育文库：" prop="document_is_free">
                <el-radio-group v-model="form.document_is_free">
                    <el-radio :label="Number(index)" v-for="(item, index) in document_is_free" :key="index">{{ item }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="用户送余额(元)：" prop="send_cash">
                <el-input v-model="form.send_cash" clearable maxlength="20" />
            </el-form-item>
            <el-form-item label="会员权益介绍：" prop="descr_img">
                <mainimgesup
                    v-model="form.descr_img"
                    :myimgesup="myimgesup1"
                    :myimgesupisshow="myimgesupisshow1"
                    :filelist="form.descr_img_list"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 2)"
                    v-on:delfiledata="(data) => delfiledataEvent(data, 2)"
                >
                </mainimgesup>
            </el-form-item>
            <el-form-item label="展示排序：" prop="show_sort">
                <el-input v-model.number="form.show_sort" clearable maxlength="10" show-word-limit />
            </el-form-item>
            <el-form-item label="状态：" prop="status">
                <el-radio-group v-model="form.status">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="-1">关闭</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item> <el-button type="primary" style="width: 200px" @click="submitEvent('myformRef')" :disabled="button_disable">保存</el-button></el-form-item>
        </el-form>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import modelBindGift from './modelBindGift.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ElMessage } from 'element-plus'
import dict from '@/assets/js/dict.js'

export default defineComponent({
    name: 'memberLevelSetAddEdit',
    inject: ['reload'], //刷新引用
    props: {
        flag: {
            type: Number,
            default: 1
        },
        formData: {
            type: Object,
            default() {
                return {
                    name: '',
                    price: '',
                    old_price: '',
                    valid_time: '',
                    icon: '',
                    bind_discount: '',
                    gift1: [],
                    gift2: [],
                    document_is_free: 1,
                    send_cash: '',
                    descr_img: '',
                    show_sort: '',
                    status: -1
                }
            }
        }
    },
    watch: {
        formData: {
            handler(newVal) {
                this.form = this.deepcopy(newVal)
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            form: {},
            rules: {
                name: [
                    {
                        required: true,
                        message: '会员等级名称为必填项',
                        trigger: 'blur'
                    }
                ],
                price: [
                    {
                        required: true,
                        message: '现价为必填项',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                        pattern: /^(?:1000|([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '大于等于0的正数或保留两位小数的小数',
                        trigger: ['blur', 'change']
                    }
                ],
                old_price: [
                    {
                        required: true,
                        message: '原价为必填项',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                        pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '大于等于0的正数或保留两位小数的小数',
                        trigger: ['blur', 'change']
                    }
                ],
                valid_time: [
                    {
                        required: true,
                        message: '会员有效期为必填项',
                        trigger: 'blur'
                    },
                    {
                        type: 'number',
                        min: 0,
                        max: 36,
                        message: '小于等于36',
                        trigger: 'change'
                    },
                    {
                        type: 'integer',
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    }
                ],
                icon: [
                    {
                        required: true,
                        message: '会员图标为必填项',
                        trigger: 'blur'
                    }
                ],
                bind_discount: [
                    {
                        required: true,
                        message: '绑定设备打印折扣为必填项',
                        trigger: 'blur'
                    },
                    {
                        type: 'number',
                        min: 0,
                        max: 10,
                        message: '必须为0～10的正整数',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    }
                ],
                gift1: [
                    {
                        required: false,
                        message: '学习电子赠品为必填项',
                        trigger: 'blur'
                    }
                ],
                gift2: [
                    {
                        required: false,
                        message: '学习文具赠品为必填项',
                        trigger: 'blur'
                    }
                ],
                give_num: [
                    {
                        required: true,
                        message: '赠送数量为必填项',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/ 或 /^(([1-9]{1}\d*)|(0{1}))?$/
                        pattern: /^(([1-9]{1}\d*)|(0{1}))?$/,
                        message: '必须为大于等于0的正整数',
                        trigger: 'change'
                    }
                ],
                document_is_free: [
                    {
                        required: true,
                        message: '教育文库为必填项',
                        trigger: 'blur'
                    }
                ],
                send_cash: [
                    {
                        required: true,
                        message: '用户送余额为必填项',
                        trigger: 'blur'
                    },
                    {
                        // 正则表达式(大于等于0的正数或保留两位小数的小数)：/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
                        pattern: /^(?:1000|([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                        message: '大于等于0的正数或保留两位小数的小数',
                        trigger: ['blur', 'change']
                    }
                ],
                descr_img: [
                    {
                        required: true,
                        message: '会员权益介绍为必填项',
                        trigger: 'blur'
                    }
                ],
                show_sort: [
                    {
                        required: true,
                        message: '展示排序为必填项',
                        trigger: 'blur'
                    },
                    {
                        type: 'number',
                        min: 0,
                        message: '必须为大于0的正整数',
                        trigger: 'change'
                    },
                    {
                        type: 'integer',
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '开启状态为必选项',
                        trigger: 'blur'
                    }
                ]
            },

            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1阿里oss直传，2PHP的接口传输
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/file', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '点击上传', //button-按钮文字
                tips: '建议上传：*.png、*.jpg、*.jpeg格式', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 50000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },
            myimgesupisshow1: true, //form表单-imgesuploade上传图片
            myimgesup1: {
                uploadsize: 1, //选择上传图片的展现框-1阿里oss直传，2PHP的接口传输
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/file', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '点击上传', //button-按钮文字
                tips: '建议上传：*.png、*.jpg、*.jpeg格式', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 50000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },

            dialogVisible: false,
            tabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '名称',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'img',
                    label: '图片',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'stock_num',
                    label: '库存剩余',
                    width: ''
                }
            ],
            tableData: [],
            totalCount: 1,
            currentPage: 1,
            pageSizes: [10, 20, 50, 100],
            PageSize: 20,
            lastpage: 1,

            document_is_free: dict.member_benefits.document_is_free,

            // 前端防抖
            button_disable: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')) //获取权限
        }
    },
    methods: {
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        //获取upload子组件回传的数据
        getUploadFileEvent(data, flag) {
            console.log('当前获取的回传数据：', data)
            if (flag == 1) {
                this.form.icon = data.url
                this.form.icon_list = [
                    {
                        name: data.url.split('/')[data.url.split('/').length - 1],
                        url: data.url
                    }
                ]
            } else if (flag == 2) {
                this.form.descr_img = data.url
                this.form.descr_img_list = [
                    {
                        name: data.url.split('/')[data.url.split('/').length - 1],
                        url: data.url
                    }
                ]
            }
        },
        //删除上传的文件-事件
        delfiledataEvent(data, flag) {
            // console.log('当前删除事件-返回的数据：', data)
            if (flag == 1) {
                this.form.icon = ''
                this.form.icon_list = []
            } else if (flag == 2) {
                this.form.descr_img = ''
                this.form.descr_img_list = []
            }
        },
        // 多选-赠品
        modelValueEvent(data, flag) {
            this.form[flag] = data.map((n) => {
                return {
                    id: n.id,
                    name: n.name,
                    img: n.img,
                    give_num: n.give_num ? n.give_num : 1
                    // stock_num: n.stock_num
                }
            })
        },
        // 删除-赠品
        closeEvent(flag, index) {
            this.form[flag].splice(index, 1)
        },
        // 保存
        submitEvent(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.button_disable = true
                    axios
                        .post('/admin/vipSet/save', getdatas, {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.button_disable = false
                                        this.reload()
                                    }
                                })
                            } else {
                                this.button_disable = false
                            }
                        })
                        .catch(function (error) {
                            this.button_disable = false
                        })
                } else {
                    return false
                }
            })
        }
    },
    components: {
        mainimgesup,
        modelBindGift
    }
})
</script>
<style scoped>
.member-money-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
}
.h-px-60 {
    height: 60px;
}

.gift-box-out {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}
.gift-box-center {
    border: 1px dashed var(--el-color-primary);
    padding: 10px 10px 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
}
.gift-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    gap: 10px;
    flex: 1;
    min-width: 240px;
    max-width: 280px;
}

.close-box:hover {
    cursor: pointer;
    color: red;
    font-weight: bold;
}
.close-box i {
    font-size: 24px;
}
</style>
