<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <!-- <el-button type="warning" plain @click="exportDatas()">导出</el-button> -->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'trade_no'">
                                <div v-if="mytabletitle.prop === 'trade_no'">
                                    <span
                                        :class="this.mypowerlimits.ddlb == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.ddlb == true ? routeraddrClick('/orderlistinfo', scope.row) : ''"
                                    >
                                        {{ scope.row.trade_no }}</span
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
// import { ElMessage } from "element-plus";
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vlibraryorderlist',
    data() {
        return {
            mainonebread: [
                {
                    name: '订单'
                },
                {
                    name: '教育管理'
                },
                {
                    name: '第三方文库订单列表'
                }
            ], // 当前页-【面包屑导航】

            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '文档名称:',
                            placeholder: '请输入文档名称',
                            name: 'paper_name',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '用户ID:',
                            placeholder: '请输入用户ID',
                            name: 'uid',
                            content: ''
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 19, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 150, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'trade_no',
                    label: '订单编号',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'extra',
                    label: '第三方公司',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'source_id',
                    label: '文档ID',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'paper_name',
                    label: '文档名称',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'price',
                    label: '实付金额',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'uid',
                    label: '用户ID',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'pay_type',
                    label: '支付方式',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'paytime',
                    label: '支付时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                paper_name: this.pagesdatas.paper_name,
                uid: this.pagesdatas.uid,
                page: this.currentPage,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            this.pagesdatas = {
                paper_name: this.pagesdatas.paper_name,
                uid: this.pagesdatas.uid,
                page: this.currentPage,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //点击进入路由页面&携带参数跳转
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 携带参数跳转
                this.$router.push({ path: routeraddr, query: { orderno: rowdata.trade_no } }).catch((error) => error)
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            this.pagesdatas = {
                paper_name: this.pagesdatas.paper_name,
                uid: this.pagesdatas.uid,
                export: 1
            }
            this.postAxiosEvent(this.pagesdatas, 1)
        },
        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = {
                paper_name: data.paper_name,
                uid: data.uid,
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                // 实付金额-前端-分转元，【当为0时,保留两位小数】
                if (newdata[i].price == 0) {
                    newdata[i].price = (newdata[i].price / 100).toFixed(2)
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        postAxiosEvent(data, index) {
            if (!index) this.loading = true
            axios
                .post('/admin/SharePrintSource/extraOrderIndex', data, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (!index) this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}
</style>
