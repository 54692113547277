<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'company' ||
                mytabletitle.prop === 'print' ||
                mytabletitle.prop === 'check'
              ">
                <!-- 状态-渲染数据 -->
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.textstatus">
                    <span>{{ scope.row.textstatus }}</span>
                  </div>
                </div>
                <!-- 商家名称-渲染数据 -->
                <div v-if="mytabletitle.prop === 'company'">
                  <div :class="this.mypowerlimits.ggzlb == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.ggzlb == true ? routeraddrClick('/advertiserslist', scope.row) : ''">
                    <span>{{ scope.row.company }}</span>
                  </div>
                </div>
                <!-- 已打印-渲染数据 -->
                <div v-if="mytabletitle.prop === 'print'">
                  <div :class="this.mypowerlimits.dyhxjl == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.dyhxjl == true ? routeraddrClick('/printwriteoff', scope.row, 1) : ''">
                    <span>{{ scope.row.print }}</span>
                  </div>
                </div>
                <!-- 已核销-渲染数据 -->
                <div v-if="mytabletitle.prop === 'check'">
                  <div :class="this.mypowerlimits.dyhxjl == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.dyhxjl == true ? routeraddrClick('/printwriteoff', scope.row, 2) : ''">
                    <span>{{ scope.row.check }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-订单-广告推广方案-->
                <div v-if="mainthreetableope === 21">
                  <el-button type="primary" size="mini" @click="routeraddrClick('/advertisersspreadinfo', scope.row)">查看
                  </el-button>
                  <el-button type="primary" size="mini" v-if="scope.row.status == '进行中' && this.mypowerlimits.ggtgfa_qy"
                    @click.prevent="stopRow(scope.$index, mainthreetableData)">暂停</el-button>
                  <el-button type="primary" size="mini" v-if="scope.row.status == '已暂停' && this.mypowerlimits.ggtgfa_qy"
                    @click.prevent="runRow(scope.$index, mainthreetableData)">启用</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
  name: "vadvertisersspread",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "订单",
        },
        {
          name: "广告主管理",
        },
        {
          name: "广告推广方案",
          path: "/advertisersspread",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "推广编号/商家名称",
              name: "keywords",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "推广方式：",
              placeholder: "全部",
              name: "type",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "0",
                },
                {
                  label: "优惠券",
                  value: "1",
                },
                {
                  label: "宣传页",
                  value: "2",
                },
              ],
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "platform",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 21, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 120, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "extend_no",
          label: "推广编号",
          width: "250",
        },
        {
          fixedstatu: false,
          prop: "company",
          label: "商家名称",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "title",
          label: "方案名称",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "rule",
          label: "推送规则",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "type",
          label: "推广方式",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "print",
          label: "已打印",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "print_money",
          label: "打印花费",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "check",
          label: "已核销",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "check_money",
          label: "核销花费",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "total_money",
          label: "总花费",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "创建时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框
      
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表-操作中-暂停按钮（函数）
    stopRow(index, rows) {
      ElMessageBox.confirm(
        "确定暂停该条 ' " + rows[index].title + " ' 方案吗?",
        "提示：",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          axios
            .post(
              "/admin/Advertiser/changePlanStatus",
              {
                id: rows[index].id,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "暂停成功！",
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              // 请求失败处理
              ElMessage({
                type: "error",
                message: "暂停失败！" + error,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消暂停",
          });
        });
    },
    //table表-操作中-启用按钮（函数）
    runRow(index, rows) {
      ElMessageBox.confirm(
        "确定启用该条 ' " + rows[index].title + " ' 方案吗?",
        "提示：",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          axios
            .post(
              "/admin/Advertiser/changePlanStatus",
              {
                id: rows[index].id,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "启用成功！",
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              // 请求失败处理
              ElMessage({
                type: "error",
                message: "启用失败！" + error,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "暂停启用",
          });
        });
    },

    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/planIndex",
          {
            keywords: this.pagesdatas.keywords,
            type: this.pagesdatas.type,
            platform: this.pagesdatas.platform,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/planIndex",
          {
            keywords: this.pagesdatas.keywords,
            type: this.pagesdatas.type,
            platform: this.pagesdatas.platform,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata, flag) {
      if (rowdata) {
        // 跳转-广告主列表
        if (routeraddr == "/advertiserslist") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                userid: rowdata.company,
                thirdparty: this.pagesdatas.platform,
              },
            })
            .catch((error) => error);
        }
        // 跳转-打印/核销记录
        if (routeraddr == "/printwriteoff") {
          // 已打印
          if (flag == 1) {
            this.$router
              .push({
                path: routeraddr,
                query: {
                  extend_no: rowdata.extend_no,
                  thirdparty: this.pagesdatas.platform,
                },
              })
              .catch((error) => error);
          }
          // 已核销
          if (flag == 2) {
            this.$router
              .push({
                path: routeraddr,
                query: {
                  extend_no: rowdata.extend_no,
                  thirdparty: this.pagesdatas.platform,
                  is_check: 1,
                },
              })
              .catch((error) => error);
          }
        }
        // 跳转-广告推广方案详情
        if (routeraddr == "/advertisersspreadinfo") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                id: rowdata.id,
                thirdparty: this.pagesdatas.platform,
              },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      // console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Advertiser/planIndex",
          {
            keywords: data.keywords,
            type: data.type,
            platform: data.platform,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        if (newdata[i].status) {
          newdata[i].textstatus = newdata[i].status;
        }
        //转换创建时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无创建时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无创建时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 定义
      this.pagesdatas = {
        platform: "1",
        page_size: this.PageSize,
        page: 1,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.extend_no) {
        this.pagesdatas = {
          keywords: routerinfo.extend_no,
          platform: routerinfo.thirdparty,
          page_size: this.PageSize,
          page: 1,
        };
        this.maintwoforminfo.myinputone.input[0].content = routerinfo.extend_no;
      }

      //通过Ajax向后台获取数据
      axios
        .post("/admin/Advertiser/planIndex", this.pagesdatas, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>