<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box"  >
                <div class="box_title"  >
                    <div class="box_title_font">编辑员工</div>
                    <div class="box_title_button">
                        <!--可添加按钮-->
                        <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
                    </div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                                <el-form-item label="登录用户名：" prop="username">
                                    <el-input v-model="ruleForm.username"></el-input>
                                </el-form-item>
                                <el-form-item label="姓名：" prop="truename">
                                    <el-input v-model="ruleForm.truename"></el-input>
                                </el-form-item>
                                <el-form-item label="所属部门：" prop="dept_id">
                                    <el-select v-model="ruleForm.dept_id" placeholder="请选择" style="width: 100%">
                                        <el-option :label="getsales.name" :value="getsales.id" v-for="(getsales, i) in ruleForm.dept_id_data" :key="i"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="所属权限组：" prop="group_id">
                                    <el-select v-model="ruleForm.group_id" placeholder="请选择" style="width: 100%">
                                        <el-option :label="getsales.name" :value="getsales.id" v-for="(getsales, i) in ruleForm.group_id_data" :key="i"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="登录密码：" prop="pass">
                                    <el-input v-model="ruleForm.pass" type="password" autocomplete="off" show-password clearable></el-input>
                                </el-form-item>
                                <el-form-item label="确认密码：" prop="checkPass">
                                    <el-input v-model="ruleForm.checkPass" type="password" autocomplete="off" clearable></el-input>
                                </el-form-item>
                                <el-form-item label="备注信息：" prop="remark">
                                    <el-input v-model="ruleForm.remark" type="textarea"></el-input>
                                </el-form-item>
                                <el-form-item label="手机号：" prop="mobile">
                                    <el-input v-model="ruleForm.mobile" maxlength="11"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">确认 </el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vuseredit',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '权限'
                },
                {
                    name: '权限管理'
                },
                {
                    name: '用户管理',
                    path: '/user'
                },
                {
                    name: '编辑员工',
                    path: '/useredit'
                }
            ], // 当前页-【面包屑导航】

            myselectcityisshow: true, //form表单-selectcity选框
            myselectcity: {
                selectwidth: '100%',
                select: {
                    ismust: true,
                    name: 'area_code',
                    valuesheng: '',
                    valueshi: '',
                    valuequ: '',
                    disshi: true,
                    disqu: true
                }
            },
            ruleForm: {
                username: '',
                truename: '',
                dept_id: '',
                dept_id_data: [],
                group_id: '',
                group_id_data: [],
                pass: '',
                checkPass: '',
                remark: '',
                mobile: ''
            }, //form表单
            rules: {
                username: [
                    {
                        required: true,
                        message: '登录用户名为必填项',
                        trigger: 'blur'
                    }
                ],
                truename: [
                    {
                        required: true,
                        message: '姓名为必填项',
                        trigger: 'blur'
                    }
                ],
                dept_id: [
                    {
                        required: true,
                        message: '所属部门为必选项',
                        trigger: 'change'
                    }
                ],
                group_id: [
                    {
                        required: true,
                        message: '所属权限组为必选项',
                        trigger: 'change'
                    }
                ],
                pass: [
                    {
                        required: true,
                        validator: this.validatePass,
                        trigger: 'blur'
                    }
                ],
                checkPass: [
                    {
                        required: true,
                        validator: this.validatePass2,
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '备注信息为必填项',
                        trigger: 'blur'
                    }
                ],
                mobile: [
                    {
                        required: false,
                        message: '手机号为必填项',
                        trigger: 'blur'
                    }
                ]
            }, //form表单-必填规则

            loading: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token') //获取存储的token
        }
    },
    methods: {
        //提交第一个密码
        validatePass(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入登录密码'))
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass')
                }
                callback()
            }
        },
        //校验-第二个密码
        validatePass2(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入确认密码'))
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('2次输入的密码不同！请重新输入'))
            } else {
                callback()
            }
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/Admin/userEdit',
                            {
                                id: this.$route.query.id,
                                username: getdatas.username,
                                truename: getdatas.truename,
                                password: getdatas.pass,
                                dept_id: getdatas.dept_id,
                                group_id: getdatas.group_id,
                                remark: getdatas.remark,
                                mobile: getdatas.mobile
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.go(-1) // 返回上一页
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // 所属部门
            axios
                .get('/admin/Admin/deptSelect', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.ruleForm.dept_id_data = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })
            // 所属权限组
            axios
                .get('/admin/Admin/groupSelect', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.ruleForm.group_id_data = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })
            // 详情
            axios
                .get('/admin/Admin/userDetail', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: this.$route.query.id
                    }
                })
                .then((response) => {
                    var routedatas = response.data.result
                    var ruleform = this.ruleForm
                    ruleform.username = routedatas.username //通过路由获取的登录用户名
                    ruleform.truename = routedatas.truename //通过路由获取姓名
                    ruleform.dept_id = routedatas.dept_id //所属部门
                    ruleform.group_id = routedatas.group_id //所属权限组
                    ruleform.pass = routedatas.password //登录密码
                    ruleform.checkPass = routedatas.password //确认密码
                    ruleform.remark = routedatas.remark //备注
                    ruleform.mobile = routedatas.mobile //手机号
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
