<template>
    <div class="page-container">
        <div class="container-filter">
            <el-form ref="filterRef" :model="params" inline>
                <div>
                    <el-form-item prop="thirdparty" label="平台来源：">
                        <el-select v-model="params.thirdparty" placeholder="请选择平台来源" clearable>
                            <el-option v-for="item in thirdpartyOptions" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="keywords" label="输入搜索：">
                        <el-input v-model="params.keywords" placeholder="用户ID/用户昵称/手机号" clearable />
                    </el-form-item>
                    <el-form-item prop="keywords2" label="当前代理商：">
                        <el-input v-model="params.keywords2" placeholder="请输入代理商名" clearable />
                    </el-form-item>
                    <el-form-item prop="agentid" label="一级代理商：">
                        <el-select v-model="params.agentid" filterable remote reserve-keyword placeholder="请选择一级代理商" :remote-method="getAgent">
                            <el-option v-for="item in agentList" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="level" label="代理商等级：">
                        <el-select v-model="params.level" placeholder="请选择代理商等级" clearable>
                            <el-option v-for="item in agentLevelOptions" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="set_name" label="会员等级：">
                        <el-select v-model="params.set_name" placeholder="请选择会员等级" clearable>
                            <el-option v-for="item in setNameList" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="type" label="会员类型：">
                        <el-select v-model="params.type" placeholder="请选择会员类型" clearable>
                            <el-option v-for="item in memberTypeOptions" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="clientid" label="绑定设备：">
                        <el-select v-model="params.clientid" filterable remote reserve-keyword placeholder="请选择设备" :remote-method="getClient">
                            <el-option v-for="item in clientList" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="coupon_status" label="商家券核销：">
                        <el-select v-model="params.coupon_status" placeholder="请选择商家券核销" clearable>
                            <el-option v-for="item in shopVerifyOptions" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="addtime" label="购买时间：">
                        <el-date-picker v-model="params.addtime" type="daterange" :clearable="false" placeholder="选择日期" />
                    </el-form-item>
                    <el-form-item prop="end_time" label="到期时间：">
                        <el-date-picker v-model="params.end_time" type="daterange" :clearable="false" placeholder="选择日期" />
                    </el-form-item>
                    <el-form-item prop="pay_status" label="会员状态：">
                        <el-select v-model="params.pay_status" placeholder="请选择会员状态" clearable>
                            <el-option v-for="item in memberStatusOptions" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                </div>
                <div class="filter-btn">
                    <el-button @click="resetFilter">重置</el-button>
                    <el-button type="primary" @click="getTableData()" :loading="loading">查询</el-button>
                </div>
            </el-form>
        </div>
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <el-button type="primary" @click="exportRecord">导出</el-button>
                </div>
            </div>
            <el-table :border="true" :data="table.data" v-loading="loading">
                <el-table-column prop="uid" label="用户ID"></el-table-column>
                <el-table-column prop="nickname" label="用户昵称" />
                <el-table-column prop="mobile" label="手机号" min-width="180" />
                <el-table-column prop="type" label="会员类型" />
                <el-table-column prop="set_name" label="会员等级" min-width="120" />
                <el-table-column prop="client" label="绑定设备" min-width="120" />
                <el-table-column prop="rule_name" label="商家权益" min-width="120" />
                <el-table-column prop="coupon1" label="已核销商家券" width="120">
                    <template #default="{ row }">
                        <span>{{ formatPrice(row.coupon1) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="coupon2" label="待核销商家券" width="120">
                    <template #default="{ row }">
                        <span>{{ formatPrice(row.coupon2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="coupon3" label="已过期商家券" width="120">
                    <template #default="{ row }">
                        <span>{{ formatPrice(row.coupon3) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="addtime" label="购买时间" width="180">
                    <template #default="{ row }">
                        <span>{{ row.addtime ? $utils.formatDate(row.addtime) : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="end_time" label="到期时间" width="180">
                    <template #default="{ row }">
                        <span>{{ row.end_time ? $utils.formatDate(row.end_time) : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="pay_status" label="会员状态" width="120">
                    <template #default="{ row }">
                        <span>{{ memberStatusOptions.filter((n) => n.value == row.pay_status)[0]['label'] }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="210" fixed="right">
                    <template #default="{ row }">
                        <el-button v-if="mypowerlimits.viphylb_xgbdsb && row.pay_status == 1" type="warning" size="small" @click="changeDvcEvent(row)">修改绑定设备</el-button>
                        <el-button v-if="mypowerlimits.viphylb_tk && row.pay_status == 1" type="primary" size="small" @click="drawerEvent(1, row)">退款</el-button>
                        <el-button v-if="mypowerlimits.viphylb_tkxq && row.pay_status == 3" type="primary" size="small" @click="drawerEvent(2, row)">退款详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="tabler-pagination">
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[20, 50, 100, 1000]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>
        <!-- 抽屉 -->
        <div id="mysections">
            <el-drawer :title="drawerDatas.flag == 1 ? '退款' : '退款详情'" v-model="drawer" direction="rtl" size="70%">
                <memberRefund :drawerDatas="drawerDatas" @drawerStatus="(data)=>{drawer = data; getTableData(params.page)}"/>
            </el-drawer>
        </div>
        <changeDevice :deviceDialog="deviceDialog" :data="deviceData" @success="successEvent" @cancel="deviceDialog = false"></changeDevice>
    </div>
</template>

<script setup>
import memberRefund from './memberRefund.vue'
import changeDevice from './changeDevice.vue'
import { ref, reactive } from 'vue'
import { ElMessageBox, ElNotification, ElMessage } from 'element-plus'
import http from '@/assets/js/http'
import router from '@/router'

// 获取权限
const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

const thirdpartyOptions = reactive([
    { value: '', label: '全部' },
    { value: '1', label: '微信自助' },
    { value: '2', label: '支付宝自助' }
])

const agentLevelOptions = reactive([
    { value: '', label: '全部' },
    { value: 1, label: '一级代理商' },
    { value: 2, label: '二级代理商' },
    { value: 3, label: '三级代理商' }
])

const memberTypeOptions = reactive([
    { value: '', label: '全部' },
    { value: '1', label: '商家邀请' },
    { value: '2', label: '用户自购' },
    { value: '3', label: '全平台' },
])

const shopVerifyOptions = reactive([
    { value: '0', label: '全部' },
    { value: '1', label: '未核销' }
])

const memberStatusOptions = reactive([
    { value: '', label: '全部' },
    { value: '1', label: '生效中' },
    { value: '2', label: '已到期' },
    { value: '3', label: '已退款' }
])

const agentList = ref([])

const clientList = ref([])

const setNameList = ref([])

const deviceData = ref({})

// 过滤
const filterRef = ref()
const params = reactive({
    thirdparty: '',
    keywords: '',
    keywords2: '',
    agentid: '',
    level: '',
    set_name: '',
    type: '',
    clientid: '',
    coupon_status: '0',
    addtime: [],
    end_time: [],
    pay_status: '',
    page: 1,
    page_size: 20
})

// 退款||退款详情
const drawer = ref(false)
const drawerDatas = reactive({
    flag: 1,
    data: ''
})

// 修改绑定设备
const deviceDialog = ref(false)

// 表格
const loading = ref(false)
const table = reactive({
    data: [],
    columns: [],
    total: 0
})

const getTableData = async (page = 1) => {
    params.page = page
    loading.value = true
    try {
        const res = await http.post('vip/vipIndex', getParams())
        Object.assign(table, res)
    } catch (e) {
        console.log(e)
    }
    loading.value = false
}

const formatPrice = (data) => {
    return data ? (+data / 100).toFixed(2) : data
}

// 操作
// 重置查询条件
const resetFilter = () => {
    filterRef.value?.resetFields()
    getTableData()
}

const goUrl = (path, query) => {
    router.push({ path, query })
}

// 退款||退款详情
const drawerEvent = (flag, row) => {
    drawer.value = true
    drawerDatas.value = {
        flag: flag,
        data: { id: row.id }
    }
}

// 导出
const exportRecord = async () => {
    await http.post('vip/vipIndex', getParams(true), { sMsg: true })
}

const getAgent = async (query) => {
    const { data } = await http.post('shopUser/getShopList', { name: query })
    agentList.value = data.map((item) => {
        return { value: item.id, label: item.name }
    })
}

const getClient = async (query) => {
    const { data } = await http.post('getClientList', { client: query })
    clientList.value = data.map((item) => {
        return { value: item.id, label: item.client }
    })
}

const getSetName = async () => {
    const res = await http.post('vip/vipLevelIndex')
    setNameList.value = res.map((item) => {
        return { value: item.name, label: item.name }
    })
}

const getParams = (exp = false) => {
    let { addtime, end_time } = params
    let [startAddtime, endAddtime] = addtime
    let [startEndtime, endEndtime] = end_time

    addtime = startAddtime ? `${startAddtime.valueOf() / 1000} ~ ${endAddtime.valueOf() / 1000}` : ''

    end_time = startEndtime ? `${startEndtime.valueOf() / 1000} ~ ${endEndtime.valueOf() / 1000}` : ''

    let _params = { ...params, addtime, end_time }

    if (exp) _params.export = 1

    return _params
}

const changeDvcEvent = (row)=> {
    deviceData.value = row
    deviceDialog.value = true
}

const successEvent = ()=> {
    deviceDialog.value = false
    getTableData()
}

getAgent()
getClient()
getSetName()
getTableData()
</script>

<style lang="scss" scoped>
.page-container {
    background-color: #e9eef3;
    .container-filter {
        padding: 12px 24px;
        background-color: #fff;
        .el-form {
            display: flex;
            justify-content: space-between;
        }
        .el-form-item {
            margin-right: 20px;
            margin-bottom: 10px;
        }

        .filter-btn {
            min-width: 180px;
        }
    }

    .container-table {
        margin-top: 4px;
        padding: 24px;
        background-color: #fff;
        .table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        .skip {
            cursor: pointer;
            color: var(--el-color-primary);
        }
        .tabler-pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
        }
    }
}

.container-drawer {
    .filter-tree {
        margin-top: 24px;
    }
    .drawer-footer {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
}
</style>
