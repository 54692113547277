<template>
  <div>
    <!-- 页面 -->
    <div>
      <!-- 标题 -->
      <div class="pages-box-small-title small-content-title-title-one">
        页面
      </div>
      <div class="pages-box-small-content">
        <!-- 页面标题 -->
        <div>
          <div class="small-content-title-title">H5标题名称</div>
          <div class="small-content-title-content">
            <el-input v-model="this.phoneheadForm.text" placeholder="请输入页面标题" clearable
              :input="subformEvent(this.phoneheadForm)"></el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "phoneheadset",
  props: ["phoneheadform"],
  setup(props) {
    const phoneheadForm = reactive(props["phoneheadform"]);

    return {
      phoneheadForm,
    };
  },
  methods: {
    // 组件数据返回到父组件
    subformEvent(data) {
      this.$emit("getphoneheaddata", data);
    },
  },
  watch: {
    phoneheadform: {
      handler(newVal) {
        //监听form的数据变化
        this.phoneheadForm = newVal;
      },
      deep: true,
    }
  },
};
</script>

<style scoped>
.small-content-title-title-one {
  border-bottom: 1px solid #e8eaec;
  padding-bottom: 10px;
}

.small-content-title-title {
  font-size: 14px;
  margin: 10px 0;
}

.small-content-title-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content-box-line {
  padding: 10px;
  border: 1px solid #e8eaec;
  border-radius: 4px;
}
</style>

