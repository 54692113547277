<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"
      v-on:selectdatainfo="selectDatainfoEvent" v-on:selectdatainfogao="selectDatainfoGaoEvent"
      v-on:resetdatainfo="resetdatainfoEvent" v-on:resetdatainfogao="resetdatainfogaoEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div class="box-list">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'uid' ||
                mytabletitle.prop === 'cash' ||
                mytabletitle.prop === 'price' ||
                mytabletitle.prop === 'paytype'
              ">
                <!-- 用户ID-数据渲染 -->
                <div v-if="mytabletitle.prop === 'uid'">
                  <div :class="this.mypowerlimits.yhlb == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.yhlb == true ? routeraddrClick('/userlist', scope.row) : ''">
                    <span>{{ scope.row.uid }}</span>
                  </div>
                </div>
                <!-- 金额-数据渲染 -->
                <div v-if="mytabletitle.prop === 'price'">
                  <div>
                    <span>{{ (scope.row.price / 100).toFixed(2) }}</span>
                  </div>
                </div>
                <!-- 当前余额-数据渲染 -->
                <div v-if="mytabletitle.prop === 'cash'">
                  <div>
                    <span>{{ (scope.row.cash / 100).toFixed(2) }}</span>
                  </div>
                </div>
                <!-- 支付方式 -->
                <div v-if="mytabletitle.prop === 'paytype'">
                  <span>{{ scope.row.paytype ? this.getpaytype.filter(n => n.value == scope.row.paytype)[0]['label'] : '' }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" :layout="Layout"
              :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vstatementuser",
  data() {
    return {
      mainonebread: [
        {
          name: "财务",
        },
        {
          name: "资金对账",
        },
        {
          name: "用户对账单明细",
          path: "/statementagent",
        },
      ], // 当前页-【面包屑导航】

      // 【业务来源】-绑定-【类型】字段的数据下拉
      source_list: [
        {
          0: [
            1, 26, 27, 23, 31, 47, 53, 18, 19, 37, 36, 40, 6, 10, 39, 46, 51, 28, 29, 30, 13,
            32, 33, 34, 35, 41, 38, 17, 12, 13, 20, 21, 42, 24, 25, 14, 15, 16,
            43, 44, 45, 49, 50, 54, 55, 57
          ],
        },
        { 1: [1, 26, 27, 23, 31, 47, 53] },
        { 2: [18, 19, 37, 36, 40] },
        { 3: [6, 10, 39, 46, 51] },
        { 4: [28, 29, 30] },
        { 5: [13] },
        { 6: [32, 33, 34, 35, 41] },
        { 7: [38, 17] },
        { 8: [12, 13, 20, 48] },
        { 9: [21, 42] },
        { 10: [24, 25] },
        { 11: [14, 15, 16] },
        { 12: [43, 44, 45] },
        { 13: [49, 50] },
        { 14: [54, 55, 57] },
      ],

      maintwoiconshow: true, // 筛选-【高级筛选按钮】显示与否
      maintwoforminfo: {
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "业务来源：",
              onchange: 0,
              placeholder: "全部",
              name: "source",
              value: "",
              options: [],
            },
            {
              selectisshow: true,
              ismust: false,
              title: "类型：",
              placeholder: "全部",
              name: "type",
              value: "",
              options: [],
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "thirdparty",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      },
      // 筛选-【筛选查询】-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 9, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "uid",
          label: "用户ID",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "nickname",
          label: "用户昵称",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "mobile",
          label: "用户手机号",
          width: "130",
        },

        {
          fixedstatu: false,
          prop: "trade_no",
          label: "订单编号",
          width: "280",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "入账时间",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "source",
          label: "业务来源",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "type",
          label: "类型",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "price",
          label: "金额",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "is_in",
          label: "收支类型",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "cash",
          label: "当前余额",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "draw_cash",
          label: "可提现佣金",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "paytype",
          label: "支付方式",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1000, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      Layout: " sizes, prev, pager, next, jumper",

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "用户ID：",
            placeholder: "请输入用户ID",
            name: "uid",
            content: "",
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "手机号：",
            placeholder: "请输入手机号",
            name: "mobile",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "收支类型：",
            placeholder: "全部",
            name: "is_in",
            value: "", //显示值，初始状况，显示值与默认值需相同
            valueisshow: true,
            valuedefault: "", //设置默认值
            options: [
              {
                value: "",
                label: "全部",
              },
              {
                value: "1",
                label: "支出",
              },
              {
                value: "2",
                label: "收入",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "业务来源：",
            placeholder: "全部",
            onchange: 1,
            name: "source",
            value: "", //显示值，初始状况，显示值与默认值需相同
            valueisshow: true,
            valuedefault: "", //设置默认值
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "类型：",
            placeholder: "全部",
            name: "type",
            value: "", //显示值，初始状况，显示值与默认值需相同
            valueisshow: true,
            valuedefault: "", //设置默认值
            options: [],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "入账时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "addtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myselecttopisshow: true, //高级筛选-select单框-放input框之前
      myselecttop: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "平台来源：",
            placeholder: "全部",
            name: "thirdparty",
            value: "1", //显示值，初始状况，显示值与默认值需相同
            valueisshow: true,
            valuedefault: "1", //设置默认值
            options: componentProperties.get('thirdpartyoption').options,
          },
        ],
      },

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用

      getSource: [], //获取当前页面-来源数据（用于list表-渲染）
      getType: [], //获取当前页面-类型数据（用于list表-渲染）
      getpaytype: componentProperties.get('financeoption').paytype,
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 点击【业务来源】切换【类型】下拉框
    changeSourceEvent(datakey, flag) {
      console.log(datakey, flag)
      if (datakey == 0) {
        this.getconfiginfotype(this.getType);
        // flag标识为1的自动填充搜索框中的值，2标识填充高级筛选中的搜索框值
        if (flag == 1) {
          this.maintwoforminfo.selectleft.select[1].value =
            this.maintwoforminfo.selectleft.select[1].options[0].value;
        } else {
          this.myselect.select[2].value =
            this.myselect.select[2].options[0].value;
        }
      } else {
        let sourcelist = this.source_list[datakey][datakey];
        let data = {};
        for (let ikey in sourcelist) {
          for (var jkey in this.getType) {
            if (jkey == sourcelist[ikey]) {
              data[jkey] = this.getType[jkey];
            }
          }
        }
        this.getconfiginfotype(data, 1);
        // flag标识为1的自动填充搜索框中的值，2标识填充高级筛选中的搜索框值
        if (flag == 1) {
          this.maintwoforminfo.selectleft.select[1].value =
            this.maintwoforminfo.selectleft.select[1].options[0].value;
        } else {
          this.myselect.select[2].value =
            this.myselect.select[2].options[0].value;
        }
      }
    },
    // 【搜索框】接收select选择的返回值-并进行函数操作
    selectDatainfoEvent(datas) {
      console.log(datas)
      if (datas.name == "source") {
        this.changeSourceEvent(datas.changeinfo.value, 1);
      }
    },
    // 【高级筛选】接收select选择的返回值-并进行函数操作
    selectDatainfoGaoEvent(datas) {
      console.log(datas)
      if (datas.name == "source") {
        this.changeSourceEvent(datas.changeinfo.value, 2);
      }
    },
    // 点击【搜索框】中【重置】按钮-触发事件
    resetdatainfoEvent(data) {
      console.log(data);
      this.getconfiginfotype(this.getType);
    },
    // 点击【高级搜索】中【重置】按钮-触发事件
    resetdatainfogaoEvent(data) {
      console.log(data);
      this.getconfiginfotype(this.getType);
    },

    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/user/bill",
          {
            thirdparty: this.pagesdatas.thirdparty,
            uid: this.pagesdatas.uid,
            mobile: this.pagesdatas.mobile,
            is_in: this.pagesdatas.is_in,
            source: this.pagesdatas.source,
            type: this.pagesdatas.type,
            paytime: this.pagesdatas.paytime,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / this.PageSize == this.lastpage) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage += 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      // console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/user/bill",
          {
            thirdparty: this.pagesdatas.thirdparty,
            uid: this.pagesdatas.uid,
            mobile: this.pagesdatas.mobile,
            is_in: this.pagesdatas.is_in,
            source: this.pagesdatas.source,
            type: this.pagesdatas.type,
            paytime: this.pagesdatas.paytime,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;
          if (getdata.length > 0) {
            if (getdata.length == this.PageSize) {
              this.lastpage = this.totalCount / this.PageSize;
              if (this.totalCount / val == this.PageSize) {
                this.totalCount += this.PageSize * 5;
                this.lastpage = this.totalCount / this.PageSize;
              }
              this.myaxiosfunc(response.data.result);
            } else {
              this.totalCount += getdata.length;
              this.lastpage = 1;
              this.myaxiosfunc(response.data.result);
            }
          } else {
            this.myaxiosfunc(response.data.result);
          }
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 跳转-用户列表
        if (routeraddr == "/userlist") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                userid: rowdata.uid,
                thirdparty: this.pagesdatas.thirdparty,
              },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/admin/user/bill",
          {
            thirdparty: this.pagesdatas.thirdparty,
            uid: this.pagesdatas.uid,
            mobile: this.pagesdatas.mobile,
            is_in: this.pagesdatas.is_in,
            source: this.pagesdatas.source,
            type: this.pagesdatas.type,
            paytime: this.pagesdatas.paytime,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message)
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      var getaddtime = "";
      if (data.addtime.start === "") {
        getaddtime = "";
      } else {
        if (data.addtime.end === "") {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.paytime = getaddtime;

      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/user/bill",
          {
            thirdparty: data.thirdparty,
            uid: data.uid,
            mobile: data.mobile,
            is_in: data.is_in,
            source: data.source,
            type: data.type,
            paytime: getaddtime,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/user/bill",
          {
            thirdparty: data.thirdparty,
            source: data.source,
            type: data.type,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result, 1);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata, flag) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        // 业务来源-匹配
        if (newdata[i].source) {
          newdata[i].source = this.getSource[newdata[i].source];
        } else {
          newdata[i].source = "";
        }
        // 类型-匹配
        if (newdata[i].type) {
          newdata[i].type = this.getType[newdata[i].type];
        } else {
          newdata[i].type = "";
        }

        //渲染-收支类型
        if (newdata[i].is_in) {
          if (newdata[i].is_in === 1) {
            newdata[i].is_in = "支出";
          }
          if (newdata[i].is_in === 2) {
            newdata[i].is_in = "收入";
          }
        }

        //转换-入账时间
        if (newdata[i].addtime) {
          if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
            newdata[i].addtime = "无入账时间";
          } else {
            newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
          }
        } else {
          newdata[i].addtime = "无入账时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      // this.totalCount = successdata.total;
      // this.currentpage = successdata.current_page; //当前页面页数
      // this.lastpage = successdata.last_page;
      this.loading = false;

      // 数据小于当前页数时判断
      if (newdata.length < this.PageSize) {
        if (newdata.length != 0 && this.currentPage == 1) {
          this.totalCount = this.PageSize - 1;
        } else {
          if (newdata.length == 0) {
            // 按照常理：为0会减1页，由于双向绑定问题一直请求服务器，因此先注释，待后续优化
            // this.totalCount = this.PageSize * (this.currentPage - 1)
            this.totalCount = this.PageSize * this.currentPage;
          } else {
            this.totalCount = this.PageSize * this.currentPage;
            this.lastpage = this.currentPage
          }
        }
      } else {
        if (this.totalCount < this.PageSize) {
          // 重设置-初始值
          this.totalCount = 1000;
          this.Layout = "sizes, prev, pager, next, jumper";
        } else {
          if (flag == 1) {
            this.currentPage = 1;
          }
        }
      }
    },
    //获取-配置-业务来源
    getconfiginfo(configinfo) {
      // console.log(configinfo);
      //将alldatas的数据存储在getSource数据中，用于渲染
      this.getSource = configinfo;

      //获取api-加载来源信息（根据API返回的json数据结构不同，函数方法不同！）
      var alldatas = Array();
      var num = 0;
      alldatas[num] = { value: num, label: "全部 " }; // 这里使用空格，区分字段名称
      for (var key in configinfo) {
        num = num + 1;
        alldatas[num] = { value: key, label: configinfo[key] };
      }
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.maintwoforminfo.selectleft.select[0].options = alldatas;
      this.myselect.select[1].options = alldatas;
    },
    //获取-配置-类型
    getconfiginfotype(configinfo, flag) {
      // flag标识不为1的改变this.getType的值
      if (flag != 1) {
        //将alldatas的数据存储在getType数据中，用于渲染
        this.getType = configinfo;
      }

      //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
      var alldatas = Array();
      var num = 0;
      alldatas[num] = { value: num, label: "全部" };
      for (var key in configinfo) {
        num = num + 1;
        if (configinfo[key] == '充值') {
          alldatas[num] = { value: key, label: '充值 ' }; // 这里使用空格，区分字段名称
        } else {
          alldatas[num] = { value: key, label: configinfo[key] };
        }

      }
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.maintwoforminfo.selectleft.select[1].options = alldatas;
      this.myselect.select[2].options = alldatas;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //获取来源-配置
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "cashflow_user_source",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.getconfiginfo(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
      //获取类型 -配置
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "cashflow_user_type",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.getconfiginfotype(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 定义
      this.pagesdatas = {
        thirdparty: "1",
        page_size: this.PageSize,
        page: 1,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.id) {
        this.pagesdatas = {
          uid: routerinfo.id,
          thirdparty: routerinfo.thirdparty,
          page_size: this.PageSize,
          page: 1,
        };
        this.myinput.input[0].content = routerinfo.id;
      }

      //通过Ajax向后台获取数据
      axios
        .post("/admin/user/bill", this.pagesdatas, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}

.box-list {
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  line-height: 40px;
  padding: 0 20px;

}
</style>