<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
      :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'money' ||
                mytabletitle.prop === 'trade_no' ||
                mytabletitle.prop === 'refund_money' ||
                mytabletitle.prop === 'client' ||
                mytabletitle.prop === 'print_status_name' ||
                mytabletitle.prop === 'pay_status_name' ||
                mytabletitle.prop === 'nickname' ||
                mytabletitle.prop === 'addtime'
              ">
                <!-- 订单编号-渲染数据 -->
                <div v-if="mytabletitle.prop === 'trade_no'">
                  <div>
                    <span :class="this.mypowerlimits.ddlb == true ? 'mytabletext' : ''"
                      @click="this.mypowerlimits.ddlb == true ? routeraddrClick('/orderlistinfo', scope.row) : ''">
                      {{ scope.row.trade_no }}</span>
                  </div>
                </div>
                <!-- 设备编号-渲染数据 -->
                <div v-if="mytabletitle.prop === 'client'">
                  <div v-if="scope.row.ShareClient">
                    <span>{{ scope.row.ShareClient.client }}</span>
                  </div>
                </div>
                <!-- 打印状态-渲染数据 -->
                <div v-if="mytabletitle.prop === 'print_status_name'">
                  <div v-if="scope.row.ShareOrder">
                    <span>{{ scope.row.ShareOrder.print_status_name }}</span>
                  </div>
                </div>
                <!-- 订单状态-渲染数据 -->
                <div v-if="mytabletitle.prop === 'pay_status_name'">
                  <div v-if="scope.row.ShareOrder">
                    <span>{{ scope.row.ShareOrder.pay_status_name }}</span>
                  </div>
                </div>
                <!-- 反馈者-渲染数据 -->
                <div v-if="mytabletitle.prop === 'nickname'">
                  <div v-if="scope.row.Member">
                    <span>{{ scope.row.Member.nickname }}</span>
                  </div>
                </div>
                <!-- 申请退款金额-渲染数据 -->
                <div v-if="mytabletitle.prop === 'money'">
                  <div v-if="scope.row.money || scope.row.money == 0">
                    <span>{{ (scope.row.money / 100).toFixed(2) }}</span>
                  </div>
                </div>
                <!-- 实付打印费-渲染数据 -->
                <div v-if="mytabletitle.prop === 'refund_money'">
                  <div v-if="scope.row.refund_money || scope.row.refund_money == 0">
                    <span>{{ (scope.row.refund_money / 100).toFixed(2) }}</span>
                  </div>
                </div>
                <!-- 反馈时间-渲染数据 -->
                <div v-if="mytabletitle.prop === 'addtime'">
                  <div>
                    <span>{{ scope.row.addtime != 0 && scope.row.addtime != null ?
                        this.$utils.formatDate(scope.row.addtime) : '无反馈时间'
                    }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column :fixed="false" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-订单-申请退款-->
                <div>
                  <el-button type="primary" size="mini" @click="routeraddrClick('/refundapplicationinfo', scope.row)">
                    查看详情</el-button>
                  <el-button type="primary" size="mini" @click="addTanClick(scope.row, (dialogVisible = true))"
                    v-if="scope.row.status == -1 && mypowerlimits.tksq_cl">处理</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize" :layout="Layout"
              :total="totalCount">
              <!-- <slot> Layout: " sizes, prev, pager, next, jumper, slot",
                <el-button style="margin-left: 10px;" type="primary" size="mini"
                  @current-change="handleCurrentChange">前往</el-button>
              </slot> -->
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-处理-->
      <el-dialog v-model="dialogVisible" title="处理" :width="this.searchwidth" :before-close="handleCloseSenior">
        <div>
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
            <div style="
                min-height: 400px;
                overflow: hidden;
                overflow-y: visible;
                margin: 20px 5% 0 0;
              ">
              <el-form-item label="退款张数：" prop="number">
                <el-input v-model.number="ruleForm.number" :max="ruleForm.refund_pages" step="1" clearable>
                  <template #append>张</template>
                </el-input>
              </el-form-item>
              <el-form-item label="申请退款金额：">
                <div class="money_box">
                  <div class="money_box_content" v-if="ruleForm.number == ruleForm.refund_pages">
                    ¥
                    {{ ruleForm.lastmoney }}
                  </div>
                  <div class="money_box_content" v-else>
                    ¥
                    {{
                        (ruleForm.number * ruleForm.price) >
                          ruleForm.lastmoney
                          ? ruleForm.lastmoney
                          : (ruleForm.number * ruleForm.price).toFixed(2)
                    }}
                  </div>
                  <div class="money_box_content">
                    (剩余最多退款金额 ¥ {{ ruleForm.lastmoney }})
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="选择处理方式：" prop="type">
                <el-radio-group v-model="ruleForm.type">
                  <el-radio :label="2">驳回</el-radio>
                  <el-radio :label="1">同意退款</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="回复内容：" prop="remark">
                <el-input v-model="ruleForm.remark" type="textarea" minlength="10" maxlength="50" show-word-limit
                  :autosize="{ minRows: 2 }" clearable></el-input>
              </el-form-item>
              <el-form-item label="留言图片(选填)：">
                <mainimgesup ref="myimges" v-model="ruleForm.reply_img" :myimgesup="myimgesup"
                  :myimgesupisshow="myimgesupisshow" :filelist="ruleForm.reply_img_list"
                  v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                  v-on:deluploadfile="(data) => deluploadfileEvent(data)" v-on:getfiletype="getfiletypeEvent"
                  @click="changeMainimgesupEvent(this.changeMainimgesup)">
                </mainimgesup>
              </el-form-item>
              <el-form-item label="处理备注(仅后台可见)：" prop="admin_remark">
                <el-input v-model="ruleForm.admin_remark" type="textarea" maxlength="200" show-word-limit
                  :autosize="{ minRows: 2 }" clearable></el-input>
              </el-form-item>
              <el-form-item style="text-align: right">
                <el-button @click="resetForm('ruleForm')">重置</el-button>
                <el-button id="btnok" type="primary" @click="submitForm('ruleForm', ruleForm.type)"
                  :disabled="mydisabled">确认</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import mainimgesup from "@/components/Mainimgesup.vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vrefundapplication",
  inject: ["reload"], //刷新引用
  data() {
    // 处理-退款张数-张数校验
    var checkBig = (rule, value, callback) => {
      //获取key值为ide下的相关数据
      var getdatas = this.ruleForm;
      setTimeout(() => {
        // 当输入为整数的判断
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (getdatas.number > getdatas.refund_pages) {
            callback(
              new Error(
                "当前订单最多可退张数为：" + getdatas.refund_pages + " 张"
              )
            );
          } else {
            if (getdatas.number < 0) {
              callback(new Error("张数最少为：0张"));
            } else {
              callback();
            }
          }
        }
      }, 500);
    };
    return {
      mainonebread: [
        {
          name: "订单",
        },
        {
          name: "打印管理",
        },
        {
          name: "退款申请",
        },
      ], // 当前页-【面包屑导航】

      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "订单编号/手机号",
              name: "keywords",
              content: "",
            },
          ],
        },
        myinputonefullisshow: true, //筛选查询-自动建议下拉框
        myinputonefull: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "设备编号：",
              placeholder: "请输入设备编号", //此name为键值
              name: "client",
              content: "",
              id: "", //此id为给后台的id值
              url: "/admin/Equipment/searchClient",
              posttype: 'get',
              inputval: { client: "" }, //这里记录需要传的参数
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间（放input框之后）
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "处理状态：",
              placeholder: "请选择处理状态",
              name: "status",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "",
                },
                {
                  label: "已处理",
                  value: "1",
                },
                {
                  label: "已驳回",
                  value: "2",
                },
              ],
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间（放input框之前）
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "thirdparty",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: [],
            },
          ],
        },
      },
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 19, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 140, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "trade_no",
          label: "订单编号",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "pay_status_name",
          label: "订单状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "print_status_name",
          label: "打印状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "client",
          label: "设备编号",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "money",
          label: "申请退款金额",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "refund_money",
          label: "实付打印费",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "paytype_name",
          label: "支付方式",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "uid",
          label: "用户ID",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "mobile",
          label: "手机号",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "nickname",
          label: "反馈者",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "type_name",
          label: "退款类型",
          width: "100",
        },
        // {
        //   fixedstatu: false,
        //   prop: "source_name",
        //   label: "来源",
        //   width: "",
        // },
        {
          fixedstatu: false,
          prop: "status_name",
          label: "处理状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "反馈时间",
          width: "170",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1000, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      Layout: " sizes, prev, pager, next, jumper",

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "订单编号/手机号",
            name: "keywords",
            content: "",
          },
        ],
      },
      myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
      myinputonefullgao: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%", //input宽度
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "设备编号：",
            placeholder: "请输入设备编号", //此name为键值
            name: "client",
            content: "",
            id: "", //此id为给后台的id值
            url: "/admin/Equipment/searchClient",
            posttype: 'get',
            inputval: { client: "" }, //这里记录需要传的参数
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "订单状态：",
            placeholder: "全部",
            name: "pay_status",
            value: "",
            options: [
              {
                value: "",
                label: "全部",
              },
              {
                value: "1",
                label: "待付款",
              },
              {
                value: "2",
                label: "打印中",
              },
              {
                value: "3",
                label: "已完成",
              },
              {
                value: "4",
                label: "已反馈",
              },
              {
                value: "5",
                label: "已退款",
              },
              {
                value: "6",
                label: "已取消",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "处理状态:",
            placeholder: "全部",
            name: "status",
            value: "",
            options: [
              {
                label: "全部",
                value: "",
              },
              {
                label: "已处理",
                value: "1",
              },
              {
                label: "已驳回",
                value: "2",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "打印状态:",
            placeholder: "全部",
            name: "print_status",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "退款类型:",
            placeholder: "全部",
            name: "type",
            value: "",
            options: [],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "支付方式:",
            placeholder: "全部",
            name: "paytype",
            value: "",
            options: [],
          },
          // {
          //   selectisshow: true,
          //   ismust: false,
          //   title: "来源:",
          //   placeholder: "全部",
          //   name: "source",
          //   value: "",
          //   options: [],
          // },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "创建时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "addtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myselecttopisshow: true, //高级筛选-select单框-放input框之前
      myselecttop: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "平台来源：",
            placeholder: "全部",
            name: "thirdparty",
            valuedefault: "1", //设置默认值
            value: "1",
            valueisshow: true,
            options: [],
          },
        ],
      },
      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",

      // 弹窗-处理
      dialogVisible: false,
      // 弹窗-上传图片需携带参数
      changeMainimgesup: {
        id: '',
        thirdparty: ''
      },
      // 弹窗-处理-form表单
      ruleForm: {
        number: 0,
        type: 1,
        remark: "",
        reply_img: "",
        reply_img_list: [], //当前订单-展示image
        refund_pages: 0, //当前订单-最大可退张数
        price: 0, //当前订单-单价
        thirdparty: "", //当前订单-所属第三方平台
        trade_no: "", //当前订单-订单编号
        lastmoney: "", // 剩余最大可退金额
        admin_remark: "", //处理备注-仅后台可见
      },
      rules: {
        number: [
          {
            required: true,
            validator: checkBig,
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "处理方式为必选项",
            trigger: "change",
          },
        ],
        remark: [
          {
            required: false,
            message: "回复内容不能为空",
            trigger: "change",
          },
          {
            required: false,
            min: 10,
            max: 50,
            message: "回复内容在10-50字",
            trigger: "change",
          },
        ],
        admin_remark: [
          {
            required: false,
            message: "处理备注不能为空",
            trigger: "change",
          },
        ],
      },
      //上传-【处理】-imgesuploade上传图片
      myimgesupisshow: true,
      // 上传-【处理】-上传留言图片
      myimgesup: {
        uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        showfilelist: true, //list展示列表是否显示，显示true，隐藏false
        ismust: true, //是否必填，true表示必填
        data: { id: '', thirdparty: '' }, // upload上传时的携带参数
        action: "/admin/ShareQuestion/wechatImg", //upload上传API地址
        headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
        multiple: true, //上传多个文件
        title: "上传图片", //button-按钮文字
        title_type: "primary", //button-按钮显示样式,primary&text
        title_plain: true, //button-按钮是否为线性（plain）
        tips: "支持扩展名：*.jpg、*.jpeg、*.png ; 最多上传4张图片，每张图片大小不超过5M", //提示&建议文字
        limit: "4", //上传数量限制,0表示不限
        target_id: 10, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；10-退款审核
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 5000, //文件大小（单位kb）
            type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
            typechart: "jpg/jpeg/png", //文件格式提示规则
          },
        },
      },

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用

      imges: [], //维护imges字段
      wechatimg: [], //维护wechatimg字段

      mydisabled: false
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesdatas.page_size = val;
      this.pagesdatas.totalCount = this.totalCount;
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      axios
        .post(
          "/admin/ShareQuestion/index",
          {
            paytype: this.pagesdatas.paytype,
            source: this.pagesdatas.source,
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            client: this.pagesdatas.client,
            thirdparty: this.pagesdatas.thirdparty,
            print_status: this.pagesdatas.print_status,
            type: this.pagesdatas.type,
            addtime: this.pagesdatas.addtime,
            pay_status: this.pagesdatas.pay_status,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.pagesdatas.page = val;
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ShareQuestion/index",
          {
            paytype: this.pagesdatas.paytype,
            source: this.pagesdatas.source,
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            client: this.pagesdatas.client,
            thirdparty: this.pagesdatas.thirdparty,
            print_status: this.pagesdatas.print_status,
            type: this.pagesdatas.type,
            addtime: this.pagesdatas.addtime,
            pay_status: this.pagesdatas.pay_status,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          // this.myaxiosfunc(response.data.result);
          let getdata = response.data.result.data;

          var simpledata = this.$utils.simplePagingJudge(this, getdata, val);
          if (simpledata.flag == 1 || simpledata.flag == 3) {
            this.totalCount = simpledata.totalCount;
            this.lastpage = simpledata.lastpage;
            this.myaxiosfunc(response.data.result);
          }
          if (simpledata.flag == 2 || simpledata.flag == 4) {
            this.myaxiosfunc(response.data.result);
          }
          this.pagesdatas.totalCount = this.totalCount;

        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      // console.log("当前获取的taninfo is :" + JSON.stringify(taninfo));
      console.log(taninfo);
      let dataarray = [];
      if (taninfo.reply_img != "0") {
        let imgearray = taninfo.reply_img.split(",");
        if (imgearray.length > 0) {
          imgearray.forEach((element) => {
            let onedata = {
              name: "",
              url: element,
            };
            dataarray.push(onedata);
          });
        }
      }

      // this.ruleForm.number = taninfo.pages - taninfo.cumu_pages; //当前订单-最大可退张数
      this.ruleForm.number = taninfo.number; //当前订单-申请退款张数
      this.ruleForm.remark = "";
      this.ruleForm.admin_remark = "";
      this.ruleForm.reply_img = "";
      this.ruleForm.reply_img_list = [];
      this.ruleForm.refund_pages = taninfo.pages - taninfo.cumu_pages; //当前订单-最大可退张数
      this.ruleForm.price = taninfo.price / 100; //当前订单-单价（分转元）
      this.ruleForm.thirdparty = taninfo.thirdparty; //当前订单-平台来源
      this.ruleForm.trade_no = taninfo.trade_no; //当前订单-订单编号
      this.ruleForm.lastmoney = (
        (taninfo.refund_money - taninfo.cumu_refund) /
        100
      ).toFixed(2); // 剩余最大可退金额

      // 弹窗-上传图片需携带参数
      this.changeMainimgesup = {
        id: taninfo.id,
        thirdparty: taninfo.thirdparty
      };
    },
    // 点击上传图片按钮
    changeMainimgesupEvent(datas) {
      this.myimgesup.data = { id: datas.id, thirdparty: datas.thirdparty }
    },
    // 弹出框[处理-按钮]-获取upload子组件回传的数据-file文件url等数据
    getUploadFileEvent(data) {
      // console.log("当前获取的回传数据：" + JSON.stringify(data));
      // console.log(data)
      let onedata = {
        id: data.file_info.id,
        name: data.name,
        url: data.file_info.url,
      };
      this.imges.push(onedata);
      this.wechatimg.push(data.wechat_image);
    },

    //弹出框[处理-按钮]-删除上传的文件-事件（upload组件）
    deluploadfileEvent(data) {
      // console.log("当前删除事件-返回的数据：" + JSON.stringify(data));
      if (data.length > 0) {
        let dataarray = [];
        data.forEach((element) => {
          let onedata = {
            id: element.response.result.file_info.id,
            name: element.name,
            url: element.response.result.file_info.url,
          };
          dataarray.push(onedata);
        });
        this.ruleForm.reply_img_list = dataarray;
        this.imges = dataarray; //维护imges字段
      } else {
        this.ruleForm.reply_img_list = [];
        this.imges = []; //维护imges字段
        this.wechatimg = []; //维护wechatimg字段
      }
    },

    //弹出框[处理-按钮]-提交表单
    submitForm(formName, typeide) {
      // 前端防抖
      this.mydisabled = true;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          var getdatas = this.$refs[formName].model;
          let ajaxurl = "";
          let ajaxdata = {};
          // typeide：1同意2驳回
          if (typeide == 1) {
            let imgesarrary = [];
            let imagesid = [];
            // getdatas.reply_img_list = getdatas.reply_img_list.concat(this.imges);
            getdatas.reply_img_list = this.imges;
            console.log(getdatas.reply_img_list);
            getdatas.reply_img_list.forEach((element) => {
              imgesarrary.push(element.url);
              imagesid.push(element.id);
            });
            getdatas.reply_img = imgesarrary.join(",");
            getdatas.reply_img_ids = imagesid.join(",");
            ajaxurl = "/admin/ShareQuestion/returnMoney";
            ajaxdata = {
              thirdparty: getdatas.thirdparty,
              trade_no: getdatas.trade_no,
              status: getdatas.type,
              remark: getdatas.remark,
              admin_remark: getdatas.admin_remark,
              money: getdatas.number == getdatas.refund_pages ? getdatas.lastmoney :
                (getdatas.number * getdatas.price > getdatas.lastmoney
                  ? getdatas.lastmoney
                  : getdatas.number * getdatas.price),
              number: getdatas.number,
              reply_img: getdatas.reply_img_ids,
              wechat_img:
                this.wechatimg.length > 0 ? this.wechatimg.join(",") : [],
            };
          } else {
            let imgesarrary = [];
            let imagesid = [];
            // getdatas.reply_img_list = getdatas.reply_img_list.concat(this.imges);
            getdatas.reply_img_list = this.imges;
            getdatas.reply_img_list.forEach((element) => {
              imgesarrary.push(element.url);
              imagesid.push(element.id);
            });
            getdatas.reply_img = imgesarrary.join(",");
            getdatas.reply_img_ids = imagesid.join(",");
            ajaxurl = "/admin/ShareQuestion/reject";
            ajaxdata = {
              thirdparty: getdatas.thirdparty,
              trade_no: getdatas.trade_no,
              status: getdatas.type,
              remark: getdatas.remark,
              admin_remark: getdatas.admin_remark,
              money: getdatas.number == getdatas.refund_pages ? getdatas.lastmoney :
                (getdatas.number * getdatas.price > getdatas.lastmoney
                  ? getdatas.lastmoney
                  : getdatas.number * getdatas.price),
              number: getdatas.number,
              reply_img: getdatas.reply_img_ids,
              wechat_img:
                this.wechatimg.length > 0 ? this.wechatimg.join(",") : [],
            };
          }
          axios
            .post(ajaxurl, ajaxdata, {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            })
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = ref(false); //关闭弹窗
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                  duration: 1000,
                  onClose: () => {
                    this.mydisabled = false;
                    // this.reload(); //刷新
                    this.handleCurrentChange(this.pagesdatas.page) // 保留当前筛选条件与页数-重新查询数据
                  },
                });
              } else {
                this.dialogVisible = ref(false); //关闭弹窗
                this.mydisabled = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              this.mydisabled = false;
            });
        } else {
          console.log("error submit!!");
          this.mydisabled = false;
          return false;
        }
      });
    },
    //弹出框[处理-按钮]-重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.imges = [];
      this.wechatimg = [];
      this.ruleForm.reply_img_list = [];
    },

    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      // 携带参数跳转
      if (rowdata) {
        // 跳转-订单详情
        if (routeraddr == "/orderlistinfo") {
          this.$router
            .push({
              path: routeraddr,
              query: { orderno: rowdata.trade_no },
            })
            .catch((error) => error);
        }
        // 跳转-退款申请详情
        if (routeraddr == "/refundapplicationinfo") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                orderid: rowdata.id,
                thirdparty: this.pagesdatas.thirdparty,
                pagesdatas: JSON.stringify(this.pagesdatas),
              },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/admin/ShareQuestion/index",
          {
            paytype: this.pagesdatas.paytype,
            source: this.pagesdatas.source,
            keywords: this.pagesdatas.keywords,
            status: this.pagesdatas.status,
            client: this.pagesdatas.client,
            thirdparty: this.pagesdatas.thirdparty,
            print_status: this.pagesdatas.print_status,
            type: this.pagesdatas.type,
            addtime: this.pagesdatas.addtime,
            pay_status: this.pagesdatas.pay_status,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message)
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      this.pagesdatas.page = 1;
      this.pagesdatas.page_size = this.PageSize;
      this.pagesdatas.totalCount = this.totalCount;

      // 兼容-设备编号-模糊查询-下拉-反显
      this.pagesdatas['client_name'] = this.myinputonefullgao.input[0].content

      //加载loading
      this.loading = true;
      //判断-申请时间段状态
      var getaddtime = null;
      if (data.addtime.start == "") {
        getaddtime = "";
      } else {
        if (data.addtime.end == "") {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.start + 86400 - 1);
        } else {
          getaddtime =
            data.addtime.start + " ~ " + (data.addtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.addtime = getaddtime;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ShareQuestion/index",
          {
            paytype: data.paytype,
            source: data.source,
            keywords: data.keywords,
            status: data.status,
            client: data.client,
            thirdparty: data.thirdparty,
            print_status: data.print_status,
            type: data.type,
            addtime: getaddtime,
            pay_status: data.pay_status,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      this.pagesdatas.page = 1;
      this.pagesdatas.page_size = this.PageSize;
      this.pagesdatas.totalCount = this.totalCount;

      // 兼容-设备编号-模糊查询-下拉-反显
      this.pagesdatas['client_name'] = this.maintwoforminfo.myinputonefull.input[0].content

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/ShareQuestion/index",
          {
            status: data.status,
            client: data.client,
            thirdparty: data.thirdparty,
            keywords: data.keywords,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //数据渲染
    myaxiosfunc(successdata) {
      let newdata = successdata.data;
      this.mainthreetableData = successdata.data;
      // this.totalCount = 1000;
      this.currentPage = successdata.current_page; //当前页面页数
      this.lastpage = this.totalCount / this.PageSize;
      this.loading = false;

      // 数据小于当前页数时判断
      if (newdata.length < this.PageSize) {
        this.totalCount = this.currentPage * this.PageSize;
      }

    },

    //平台来源-下拉获取
    getthirdpartydic(data) {
      var thirdpartyDic = [];
      for (let key in data) {
        thirdpartyDic.push({ value: key, label: data[key] });
      }
      this.maintwoforminfo.selecttop.select[0].options = thirdpartyDic;
      this.myselecttop.select[0].options = thirdpartyDic;
    },
    //订单状态-下拉获取
    getpay_statusdic(data) {
      var pay_statusDic = [{ value: "", label: "全部" }];
      for (let key in data) {
        pay_statusDic.push({ value: key, label: data[key] });
      }
      this.myselect.select[0].options = pay_statusDic;
    },
    //打印状态-下拉获取
    getprint_statusdic(data) {
      var print_statusdic = [{ value: "", label: "全部" }];
      for (let key in data) {
        print_statusdic.push({ value: key, label: data[key] });
      }
      this.myselect.select[2].options = print_statusdic;
    },
    //处理状态-下拉获取
    getstatusdic(data) {
      var statusdic = [];
      for (let key in data) {
        statusdic.push({ value: key, label: data[key] });
      }
      this.maintwoforminfo.selectleft.select[0].options = [
        { value: "", label: "全部" },
        ...statusdic,
      ];
      this.myselect.select[1].options = [
        { value: "", label: "全部" },
        ...statusdic,
      ];
    },
    //退款类型-下拉获取
    gettypedic(data) {
      var typedic = [{ value: "", label: "全部" }];
      for (let key in data) {
        typedic.push({ value: key, label: data[key] });
      }
      this.myselect.select[3].options = typedic;
    },
    //支付方式-下拉获取
    getpaytypedic(data) {
      var paytypedic = [{ value: "", label: "全部" }];
      for (let key in data) {
        paytypedic.push({ value: key, label: data[key] });
      }
      this.myselect.select[4].options = paytypedic;
    },
    //来源-下拉获取
    // getsourcedic(data) {
    //   var sourcedic = [{ value: "", label: "全部" }];
    //   for (let key in data) {
    //     sourcedic.push({ value: key, label: data[key] });
    //   }
    //   this.myselect.select[5].options = sourcedic;
    // },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //通过Ajax向后台-获取搜索框下拉
      axios
        .post(
          "/admin/ShareQuestion/getRander",
          {},
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var dicData = response.data.result;
          for (let key in dicData) {
            if (key === "thirdparty") {
              this.getthirdpartydic(dicData[key]);
            }
            if (key === "pay_status") {
              this.getpay_statusdic(dicData[key]);
            }
            if (key === "print_status") {
              this.getprint_statusdic(dicData[key]);
            }
            if (key === "status") {
              this.getstatusdic(dicData[key]);
            }
            if (key === "type") {
              this.gettypedic(dicData[key]);
            }
            if (key === "paytype") {
              this.getpaytypedic(dicData[key]);
            }
            // if (key === "source") {
            //   this.getsourcedic(dicData[key]);
            // }
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据

      // 定义
      this.pagesdatas = {
        thirdparty: 1,
        page_size: this.PageSize,
        page: 1,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.pagesdatas) {
        this.pagesdatas = JSON.parse(routerinfo.pagesdatas);
        this.PageSize = this.pagesdatas.page_size;
        this.totalCount = this.pagesdatas.totalCount;
        this.handleCurrentChange(this.pagesdatas.page);
        // 平台来源-判断-反显
        if (this.pagesdatas.thirdparty) {
          this.maintwoforminfo.selecttop.select[0].value = this.pagesdatas.thirdparty.toString();
          this.myselecttop.select[0].value = this.pagesdatas.thirdparty.toString();
        }
        // 订单编号||手机号-判断-反显
        if (this.pagesdatas.keywords) {
          this.maintwoforminfo.myinputone.input[0].content = this.pagesdatas.keywords;
          this.myinput.input[0].content = this.pagesdatas.keywords;
        }
        // 设备编号-判断-反显
        if (this.pagesdatas.client) {
          this.maintwoforminfo.myinputonefull.input[0].id = this.pagesdatas.client;
          this.maintwoforminfo.myinputonefull.input[0].content =  this.pagesdatas.client_name;
          this.myinputonefullgao.input[0].id = this.pagesdatas.client;
          this.myinputonefullgao.input[0].content = this.pagesdatas.client_name;
          
        }
        // 处理状态-判断-反显
        if (this.pagesdatas.status) {
          this.maintwoforminfo.selectleft.select[0].value = this.pagesdatas.status;
          this.myselect.select[1].value = this.pagesdatas.status;
        }
        // 订单状态-判断-反显
        if (this.pagesdatas.pay_status) {
          this.myselect.select[0].value = this.pagesdatas.pay_status;
        }
        // 打印状态-判断-反显
        if (this.pagesdatas.print_status) {
          this.myselect.select[2].value = this.pagesdatas.print_status;
        }
        // 退款类型-判断-反显
        if (this.pagesdatas.type) {
          this.myselect.select[3].value = this.pagesdatas.type;
        }
        // 支付方式-判断-反显
        if (this.pagesdatas.paytype) {
          this.myselect.select[4].value = this.pagesdatas.paytype;
        }
        // // 来源-判断-反显
        // if (this.pagesdatas.source) {
        //   this.myselect.select[5].value = this.pagesdatas.source;
        // }
        // 创建时间-判断-反显
        if (this.pagesdatas.addtime) {
          // 判断时间戳
          let timearray = this.pagesdatas.addtime.split(' ~ ')
          var start = this.$utils.formatDate(timearray[0]).substr(0, 10)
          var end = this.$utils.formatDate(timearray[1]).substr(0, 10)
          var placeholderstart = '';
          var placeholderend = '';
          if (start == end) {
            placeholderstart = start;
            placeholderend = '';
          } else {
            placeholderstart = start;
            placeholderend = end;
          }

          this.myselect.select[5].placeholder = {
            placeholderstart: placeholderstart,
            placeholderend: placeholderend,
          }
        }
      }

      this.pagesdatas.totalCount = this.totalCount;

      //通过Ajax向后台获取数据
      axios
        .post("/admin/ShareQuestion/index", this.pagesdatas, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
    mainimgesup,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}

.money_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.money_box_content {
  margin: 0 10px 10px 0;
}

/* 分页-[前往]按钮 */
.el-pagination button {
  background: var(--el-button-background-color);
}

.el-pagination button:hover {
  background: var(--el-button-hover-color);
  color: #ffffff;
}
</style>