<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box"  >
        <div class="box_title"  >
          <div class="box_title_font">账户设置</div>
          <div class="box_title_button"></div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="7">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="9">
              <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                <!--登录用户名-->
                <el-form-item label="登录用户名：" prop="username">
                  <el-input v-model="ruleForm.username" disabled></el-input>
                </el-form-item>
                <!--姓名-->
                <el-form-item label="姓名：" prop="name">
                  <el-input v-model="ruleForm.name" disabled></el-input>
                </el-form-item>
                <!--旧密码-->
                <el-form-item label="旧密码：" prop="oldpassword">
                  <el-input v-model="ruleForm.oldpassword" clearable></el-input>
                </el-form-item>
                <!--新密码-->
                <el-form-item label="新密码：" prop="pass">
                  <el-input v-model="ruleForm.pass" type="password" autocomplete="off" clearable></el-input>
                </el-form-item>
                <!--确认密码-->
                <el-form-item label="确认密码：" prop="checkPass">
                  <el-input v-model="ruleForm.checkPass" type="password" autocomplete="off" clearable></el-input>
                </el-form-item>

                <!--button按钮-->
                <el-form-item style="margin-top: 50px" v-if="this.mypowerlimits.zhsz_tj">
                  <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%">提交</el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone.vue";
// import { h } from 'vue'
// import { ElNotification } from 'element-plus'
import { ElMessage } from "element-plus";
// import { ref } from "vue";
import axios from "axios";

export default {
  name: "vaccountset",
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("新密码为必填项"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("确认密码为必填项"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("与新密码不同!"));
      } else {
        callback();
      }
    };
    return {
      mainonebread: [
        {
          name: "设置",
        },
        {
          name: "系统设置",
        },
        {
          name: "账户设置",
          path: "/account",
        },
      ], // 当前页-【面包屑导航】

      ruleForm: {
        username: "admin",
        name: "admin",
        oldpassword: "",
        pass: "",
        checkPass: "",
      }, //form表单数据
      rules: {
        username: [
          {
            required: true,
            message: "登录用户名为必填项",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "姓名为必填项",
            trigger: "blur",
          },
        ],
        oldpassword: [
          {
            required: true,
            message: "旧密码为必填项",
            trigger: "blur",
          },
        ],
        pass: [{ required: true, validator: validatePass, trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      }, //form表单填写规则-前端判断是否必填

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      myuserinfo: localStorage.getItem("userinfo"), //获取存储的用户信息
    };
  },
  mounted() { },
  methods: {
    //提交form表单-并判断是否必填
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(
          //   "当前提交成功-获取的form表单值：" +
          //     JSON.stringify(this.$refs[formName].model)
          // );

          axios
            .post(
              "/admin/Admin/userEditPassword",
              {
                oldPassword: this.$refs[formName].model.oldpassword,
                password: this.$refs[formName].model.pass,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置form表单中填写的数据
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.$refs[formName].model.oldpassword = "";
      this.$refs[formName].model.pass = "";
      this.$refs[formName].model.checkPass = "";
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    }
    else {
      //  console.log(JSON.parse(this.myuserinfo));
      var getuserinfo = JSON.parse(this.myuserinfo);
      this.ruleForm.username = getuserinfo.username;
      this.ruleForm.name = getuserinfo.truename;
    }
  },
  components: {
    mainone,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #97a0c3;
  padding: 5px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
}
</style>
