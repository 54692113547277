<template>
    <div id="mymain">
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">
                        <span style="margin-right: 40px">报单详情</span>
                    </div>
                    <!-- <div class="box_title_button">
                        <el-button type="primary" @click="addTanClick(this.ruleForm)" v-if="this.mainpagedata.dealstatus.status == '待处理' && mypowerlimits.bdsq_sh"
                            >处理</el-button
                        >
                        <el-button type="primary" plain @click="nextClickEvent(this.$route.query.id)" v-if="mypowerlimits.bdsq_xyt">下一条</el-button>
                    </div> -->
                </div>
                <!--内容-->
                <div class="box_content">
                    <!-- 处理状态 -->
                    <div style="font-size: 14px; margin: 20px; margin-left: 0px">
                        <div style="font-weight: 600">
                            <span>处理状态：</span><span>{{ mainpagedata.dealstatus.status }}</span>
                        </div>
                        <div class="idcard_no_box" v-if="this.mainpagedata.dealstatus.status != '待处理'">
                            <div class="box-list-on">
                                <div class="box-list-on-title">
                                    <span>处理时间:</span>
                                </div>
                                <div>
                                    <span>{{ mainpagedata.dealstatus.time }}</span>
                                </div>
                            </div>
                            <div class="box-list-on">
                                <div class="box-list-on-title">
                                    <span>回复内容:</span>
                                </div>
                                <div>
                                    <span>{{ mainpagedata.dealstatus.remark }}</span>
                                </div>
                            </div>
                            <!-- 占位 -->
                            <div class="box-list-on">
                                <div class="box-list-on-title"></div>
                            </div>
                            <div class="box-list-on-dange">
                                <div class="box-list-on-title">
                                    <span>留言图片:</span>
                                </div>
                                <div>
                                    <mainimgesshow :imgesdata="mainpagedata.dealstatus.images"></mainimgesshow>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <!--商户信息-->
                    <div>
                        <maindetails :detailsdata="mainpagedata.details" style="margin-top: 20px">
                            <template #contentImages>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>身份证正面照:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details.imgesdata_1"></mainimgesshow>
                                    </div>
                                </div>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>身份证反面照:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details.imgesdata_2"></mainimgesshow>
                                    </div>
                                </div>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>店铺门头照:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details.imgesdata_3"></mainimgesshow>
                                    </div>
                                </div>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>营业执照:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details.imgesdata_4"></mainimgesshow>
                                    </div>
                                </div>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>法人身份证正面照:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details.imgesdata_5"></mainimgesshow>
                                    </div>
                                </div>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>法人身份证反面照:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details.imgesdata_6"></mainimgesshow>
                                    </div>
                                </div>
                            </template>
                        </maindetails>
                    </div>
                    <el-divider></el-divider>
                    <!-- 设备投放 -->
                    <div>
                        <maindetails :detailsdata="mainpagedata.details_1" style="margin-top: 20px">
                            <template #contentImages>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>投放视频证明:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <div
                                            v-if="mainpagedata.details_1.videosdata_1 && mainpagedata.details_1.videosdata_1 != null"
                                            style="padding: 10px; border: 1px dashed #465eff; border-radius: 4px"
                                        >
                                            <video :src="mainpagedata.details_1.videosdata_1" controls width="120" height="130"></video>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>设备图片:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details_1.imgesdata_1"></mainimgesshow>
                                    </div>
                                </div>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>设备验收单:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details_1.imgesdata_2"></mainimgesshow>
                                    </div>
                                </div>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>设备摆放照:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details_1.imgesdata_3"></mainimgesshow>
                                    </div>
                                </div>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>设备合同截图:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details_1.imgesdata_4"></mainimgesshow>
                                    </div>
                                </div>
                            </template>
                        </maindetails>
                    </div>
                    <el-divider></el-divider>
                    <!-- 设备押金 -->
                    <div>
                        <maindetails :detailsdata="mainpagedata.details_2" style="margin-top: 20px">
                            <template #contentImages>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>商户押金截图:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details_2.imgesdata_1"></mainimgesshow>
                                    </div>
                                </div>
                            </template>
                        </maindetails>
                    </div>
                    <el-divider></el-divider>
                    <!-- 分佣相关 -->
                    <div>
                        <maindetails :detailsdata="mainpagedata.details_3" style="margin-top: 20px"></maindetails>
                    </div>
                    <!-- 介绍人 -->
                    <div>
                        <maindetails :detailsdata="mainpagedata.details_4" style="margin-top: 20px"></maindetails>
                    </div>
                    <el-divider></el-divider>
                    <!-- 打印机信息 -->
                    <div>
                        <maindetails :detailsdata="mainpagedata.details_5" style="margin-top: 20px">
                            <template #contentImages>
                                <div class="box-list-on">
                                    <div class="box-list-on-title">
                                        <span>打印机序列号照:</span>
                                    </div>
                                    <div class="box-list-on-value">
                                        <mainimgesshow :imgesdata="mainpagedata.details_5.imgesdata_1"></mainimgesshow>
                                    </div>
                                </div>
                            </template>
                        </maindetails>
                    </div>
                    <el-divider></el-divider>
                    <!-- 权益会员 -->
                    <div>
                        <maindetails :detailsdata="mainpagedata.details_6" style="margin-top: 20px"></maindetails>
                    </div>
                    <el-divider></el-divider>
                    <!--操作记录-table表单-->
                    <div style="margin-top: 20px">
                        <i class="el-icon-s-data"></i>
                        <span class="table-box-list"> 操作记录</span>
                    </div>
                    <div class="mytable" style="margin-bottom: 20px">
                        <el-table :data="mainpagedata.tableData" border style="width: 100%; margin-top: 10px">
                            <el-table-column prop="name" label="操作人" width="200" />
                            <el-table-column prop="addtime" label="操作时间" width="200" />
                            <el-table-column prop="remark" label="操作类型" width="160" />
                            <el-table-column prop="contents" label="操作内容">
                                <template #default="scope">
                                    <div v-if="scope.row.contents && (scope.row.status == 3 || scope.row.status == 2)">
                                        <div class="box-list-on">
                                            <div>
                                                <span>操作平台：</span>
                                            </div>
                                            <div>
                                                <span>{{ scope.row.source_name }}</span>
                                            </div>
                                        </div>
                                        <div class="box-list-on-dange">
                                            <div>
                                                <span>回复内容：</span>
                                            </div>
                                            <div>
                                                <span>{{ JSON.parse(scope.row.contents).remark }}</span>
                                            </div>
                                        </div>
                                        <div class="box-list-on-dange">
                                            <div>
                                                <span>留言图片：</span>
                                            </div>
                                            <div>
                                                <mainimgesshow
                                                    :imgesdata="{
                                                        showstatus: 2,
                                                        fit: 'contain',
                                                        content:
                                                            JSON.parse(scope.row.contents).images != ''
                                                                ? JSON.parse(scope.row.contents)
                                                                      .images.split(',')
                                                                      .map((n) => {
                                                                          return {
                                                                              url: n,
                                                                              preview: [n]
                                                                          }
                                                                      })
                                                                : []
                                                    }"
                                                ></mainimgesshow>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="scope.row.contents && scope.row.status == 4">
                                        <div class="box-list-on-dange">
                                            <div>
                                                <span>{{ JSON.parse(scope.row.contents).remark }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>

                <!-- 弹窗-处理 -->
                <!-- <el-dialog v-model="dialogVisible" title="处理" :width="this.searchwidth" :before-close="handleCloseSenior">
                    <div>
                        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="180px" class="demo-ruleForm">
                            <div class="tan-box-list">
                                <el-form-item label="选择处理方式：" prop="status">
                                    <el-radio-group v-model="ruleForm.status">
                                        <el-radio :label="3">审核通过</el-radio>
                                        <el-radio :label="2">驳回</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="回复内容：" prop="remark">
                                    <el-input
                                        v-model="ruleForm.remark"
                                        type="textarea"
                                        minlength="10"
                                        maxlength="50"
                                        show-word-limit
                                        :autosize="{ minRows: 2 }"
                                        clearable
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="留言图片(选填)：">
                                    <mainimgesup
                                        ref="myimges"
                                        v-model="ruleForm.reply_img"
                                        :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow"
                                        :filelist="ruleForm.reply_img"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                        v-on:deluploadfile="(data) => deluploadfileEvent(data)"
                                        v-on:getfiletype="getfiletypeEvent"
                                        @click="changeMainimgesupEvent(this.changeMainimgesup)"
                                    >
                                    </mainimgesup>
                                </el-form-item>
                                <el-form-item style="text-align: right">
                                    <el-button @click="resultEvent">取消</el-button>
                                    <el-button id="btnok" type="primary" @click="submitForm('ruleForm')" :disabled="mydisabled">确认</el-button>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                </el-dialog> -->
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maindetails from '@/components/Maindetails'
import mainimgesshow from '@/components/Mainimgesshow'
// import mainimgesup from '@/components/Mainimgesup.vue'
import axios from 'axios'
// import { ref } from 'vue'
// import { ElMessage } from 'element-plus'

export default {
    name: 'vreportsingleinfo',
    data() {
        return {
            mainonebread: [
                {
                    name: '统计'
                },
                {
                    name: '业务统计'
                },
                {
                    name: '报单统计',
                    path: '/reportsinglecens',
                    parameter: {},
                    color: 'var(--el-color-primary);'
                },
                {
                    name: '报单详情'
                }
            ], // 当前页-【面包屑导航】

            // 投放方式下拉
            is_buy_options: [
                {
                    label: '租赁',
                    value: '3'
                },
                {
                    label: '用户自购',
                    value: '2'
                },
                {
                    label: '公司自投',
                    value: '1'
                }
            ],

            // 投放场景下拉
            push_type_options: [
                {
                    value: '1',
                    label: '校园文具店'
                },
                {
                    value: '2',
                    label: '社区便利店'
                },
                {
                    value: '3',
                    label: '风景游览区'
                },
                {
                    value: '4',
                    label: '菜鸟驿站'
                },
                {
                    value: '5',
                    label: '照相馆'
                },
                {
                    value: '6',
                    label: '图文店'
                },
                {
                    value: '7',
                    label: '大中小校园'
                },
                {
                    value: '8',
                    label: '便民中心'
                },
                {
                    value: '9',
                    label: '办事大厅'
                },
                {
                    value: '10',
                    label: '培训机构'
                },
                {
                    value: '11',
                    label: '酒店'
                },
                {
                    value: '12',
                    label: '其他'
                }
            ],

            // 销售人员/渠道经理-下拉
            sales_people_datas: [],
            // 售后人员-下拉
            after_people_datas: [],
            // 处理状态下拉
            dealstatus_options: [
                {
                    label: '待处理',
                    value: '1'
                },
                {
                    label: '已驳回',
                    value: '2'
                },
                {
                    label: '已通过',
                    value: '3'
                }
            ],

            mainpagedata: {
                // 处理状态
                dealstatus: {
                    status: '',
                    time: '',
                    remark: '',
                    images: {
                        showstatus: 2,
                        fit: 'contain',
                        content: []
                    }
                },

                // 商户信息
                details: {
                    showstatus: 1, //确定样式模块
                    title: '商户信息',
                    titlewidth: '100px', //新增title最小宽度
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '店铺名称:',
                            value: ''
                        },
                        {
                            name: '商户姓名:',
                            value: ''
                        },
                        {
                            name: '手机号码:',
                            value: ''
                        },
                        {
                            name: '所在地区:',
                            value: ''
                        },
                        {
                            name: '详细地址:',
                            value: ''
                        },
                        {
                            name: '类型:',
                            value: ''
                        },
                        {
                            name: '负责人:',
                            value: ''
                        },
                        {
                            name: '数据来源:',
                            value: ''
                        },
                        {
                            name: '身份证号:',
                            value: ''
                        }
                    ],
                    idcard_no: '', // 身份证号
                    imgesdata_1: {
                        showstatus: 2,
                        fit: 'contain',
                        content: [
                            // {
                            //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                            //   preview: [
                            //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                            //   ],
                            // },
                        ]
                    },
                    imgesdata_2: {
                        showstatus: 2,
                        fit: 'contain',
                        content: []
                    },
                    imgesdata_3: {
                        showstatus: 2,
                        content: []
                    },
                    imgesdata_4: {
                        showstatus: 2,
                        content: []
                    },
                    imgesdata_5: {
                        showstatus: 2,
                        content: []
                    },
                    imgesdata_6: {
                        showstatus: 2,
                        content: []
                    }
                },
                // 设备投放
                details_1: {
                    showstatus: 1, //确定样式模块
                    title: '设备投放',
                    titlewidth: '100px', //新增title最小宽度
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '设备编号:',
                            value: ''
                        },
                        {
                            name: '投放方式:',
                            value: ''
                        },
                        {
                            name: '投放场景:',
                            value: ''
                        },
                        {
                            name: '合同签约来源:',
                            value: ''
                        },
                        {
                            name: '政策方案:',
                            value: ''
                        }
                        // {
                        //     name: '销售人员/渠道经理:',
                        //     value: ''
                        // },
                        // {
                        //     name: '售后人员:',
                        //     value: ''
                        // }
                    ],
                    imgesdata_1: {
                        showstatus: 2,
                        content: []
                    },
                    imgesdata_2: {
                        showstatus: 2,
                        content: []
                    },
                    imgesdata_3: {
                        showstatus: 2,
                        content: []
                    },
                    imgesdata_4: {
                        showstatus: 2,
                        content: []
                    },
                    videosdata_1: ''
                },
                // 设备押金
                details_2: {
                    showstatus: 1, //确定样式模块
                    title: '设备押金',
                    titlewidth: '100px', //新增title最小宽度
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '支付宝账号:',
                            value: ''
                        },
                        {
                            name: '支付宝平台订单编号:',
                            value: ''
                        }
                    ],
                    imgesdata_1: {
                        showstatus: 2,
                        content: []
                    }
                },
                // 分佣相关
                details_3: {
                    showstatus: 1, //确定样式模块
                    title: '分佣相关',
                    titlewidth: '100px', //新增title最小宽度
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '商户是否分成:',
                            value: ''
                        },
                        {
                            name: '默认佣金比例:',
                            value: ''
                        },
                        {
                            name: '是否自动提现:',
                            value: ''
                        },
                        {
                            name: '微信提现账户:',
                            value: ''
                        }
                    ]
                },

                // 介绍人
                details_4: {
                    showstatus: 1, //确定样式模块
                    title: '介绍人',
                    titlewidth: '100px', //新增title最小宽度
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '是否有介绍人:',
                            value: ''
                        },
                        {
                            name: '介绍人:',
                            value: ''
                        },
                        {
                            name: '介绍人分佣比例:',
                            value: ''
                        },
                        {
                            name: '分佣有效期:',
                            value: ''
                        }
                    ]
                },
                // 打印机信息
                details_5: {
                    showstatus: 1, //确定样式模块
                    title: '打印机信息',
                    titlewidth: '100px', //新增title最小宽度
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '品牌:',
                            value: ''
                        },
                        {
                            name: '型号:',
                            value: ''
                        },
                        {
                            name: '打印机序列号:',
                            value: ''
                        },
                        {
                            name: '设备Pwd(密码):',
                            value: ''
                        }
                    ],
                    imgesdata_1: {
                        showstatus: 2,
                        content: []
                    }
                },
                // 权益会员
                details_6: {
                    showstatus: 1, //确定样式模块
                    title: '权益会员',
                    titlewidth: '100px', //新增title最小宽度
                    button: {
                        isshow: false,
                        name: '',
                        jump: '' //点击跳转
                    },
                    content: [
                        {
                            name: '是否参与会员:',
                            value: ''
                        },
                        {
                            name: '权益模式:',
                            value: ''
                        }
                    ]
                },
                // 操作记录
                tableData: [
                    // {
                    //   id: 665,
                    //   source_type: 1,
                    //   truename: "管理员",
                    //   remark: "佣金比例【0】修改为【0.002】",
                    //   addtime: 1638345438,
                    // },
                ] //操作记录-table列表
            }, // 当前Pagedata所需的data数据与控制

            // // 弹窗-处理相关
            // searchwidth: '580px',
            // dialogVisible: ref(false),
            // ruleForm: {
            //     status: 3,
            //     remark: '',
            //     reply_img: []
            // },
            // rules: {
            //     status: [
            //         {
            //             required: true,
            //             message: '处理方式为必选项',
            //             trigger: 'change'
            //         }
            //     ],
            //     remark: [
            //         {
            //             required: false,
            //             message: '回复内容不能为空',
            //             trigger: 'change'
            //         },
            //         {
            //             required: false,
            //             min: 10,
            //             max: 50,
            //             message: '回复内容在10-50字',
            //             trigger: 'change'
            //         }
            //     ]
            // },
            // //上传-【处理】-imgesuploade上传图片
            // myimgesupisshow: true,
            // // 上传-【处理】-上传留言图片
            // myimgesup: {
            //     uploadsize: 1,
            //     listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
            //     showfilelist: true, //list展示列表是否显示，显示true，隐藏false
            //     ismust: true, //是否必填，true表示必填
            //     data: { id: '', thirdparty: '' }, // upload上传时的携带参数
            //     action: '/admin/ShareQuestion/wechatImg', //upload上传API地址
            //     headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
            //     multiple: true, //上传多个文件
            //     title: '上传图片', //button-按钮文字
            //     title_type: 'primary', //button-按钮显示样式,primary&text
            //     title_plain: true, //button-按钮是否为线性（plain）
            //     tips: '支持扩展名：*.jpg、*.jpeg、*.png ; 最多上传4张图片，每张图片大小不超过5M', //提示&建议文字
            //     limit: '4', //上传数量限制,0表示不限
            //     target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；10-退款审核
            //     rules: {
            //         images: {
            //             image_width: 0, //图片宽度
            //             image_height: 0, //图片高度
            //             size: 5000, //文件大小（单位kb）
            //             type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
            //             typechart: 'jpg/jpeg/png' //文件格式提示规则
            //         }
            //     }
            // },

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]

            loading: false,
            mydisabled: false,
            pagesdatas: []
        }
    },
    methods: {
        // // 点击下一条按钮
        // nextClickEvent(currentId) {
        //     axios
        //         .post(
        //             '/admin/business/getNextId',
        //             {
        //                 id: currentId
        //             },
        //             {
        //                 headers: {
        //                     Authentication: localStorage.getItem('token')
        //                 }
        //             }
        //         )
        //         .then((response) => {
        //             if (response.data.code === 0) {
        //                 if (response.data.result > 0) {
        //                     this.routeraddrClick('/reportsingleinfo', { id: response.data.result })
        //                 } else {
        //                     ElMessage({
        //                         type: 'warning',
        //                         message: '已经处理到最后一条了，即将返回报单列表！',
        //                         duration: 1000,
        //                         onClose: () => {
        //                             // id返0表示下级没有了-需带筛选条件返回列表
        //                             this.routeraddrClick('/reportsingle', this.pagesdatas)
        //                         }
        //                     })
        //                 }
        //             }
        //         })
        //         .catch(function(error) {
        //             console.log(error)
        //         })
        // },

        // 跳转路由
        routeraddrClick(routeraddr, rowdata) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转本详情页面
                if (routeraddr == '/reportsingleinfo') {
                    this.$router.push({ path: routeraddr, query: { id: rowdata.id, pagesdatas: this.pagesdatas } }).catch((error) => error)
                }
                // 跳转列表
                if (routeraddr == '/reportsingle') {
                    this.$router.push({ path: routeraddr, query: { id: rowdata.id, pagesdatas: this.pagesdatas } }).catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        }
        // // 弹出框[处理-按钮]-获取upload子组件回传的数据-file文件url等数据
        // getUploadFileEvent(data) {
        //     // console.log('当前获取的回传数据：' + JSON.stringify(data))
        //     let onedata = {
        //         name: data.name,
        //         url: data.url
        //     }
        //     this.ruleForm.reply_img.push(onedata)
        // },
        // //弹出框[处理-按钮]-删除上传的文件-事件（upload组件）
        // deluploadfileEvent(data) {
        //     // console.log("当前删除事件-返回的数据：" + JSON.stringify(data));
        //     if (data.length > 0) {
        //         let dataarray = []
        //         data.forEach((element) => {
        //             let onedata = {
        //                 name: element.name,
        //                 url: element.url
        //             }
        //             dataarray.push(onedata)
        //         })
        //         this.ruleForm.reply_img = dataarray
        //     } else {
        //         this.ruleForm.reply_img = []
        //     }
        // },
        // // 打开弹窗-处理
        // addTanClick(taninfo) {
        //     this.dialogVisible = true
        //     console.log(taninfo)
        // },
        // // 弹窗-提交处理-数据
        // submitForm(formName) {
        //     // 前端防抖
        //     this.mydisabled = true

        //     this.$refs[formName].validate((valid) => {
        //         if (valid) {
        //             var getdatas = this.$refs[formName].model
        //             axios
        //                 .post(
        //                     '/admin/business/audit',
        //                     {
        //                         id: this.$route.query.id,
        //                         status: getdatas.status,
        //                         images: getdatas.reply_img
        //                             .map((n) => {
        //                                 return n.url
        //                             })
        //                             .join(','),
        //                         remark: getdatas.remark
        //                     },
        //                     {
        //                         headers: {
        //                             Authentication: localStorage.getItem('token')
        //                         }
        //                     }
        //                 )
        //                 .then((response) => {
        //                     if (response.data.code === 0) {
        //                         this.dialogVisible = ref(false) //关闭弹窗
        //                         ElMessage({
        //                             type: 'success',
        //                             message: '处理成功！' + response.data.message,
        //                             duration: 1000,
        //                             onClose: () => {
        //                                 this.mydisabled = false
        //                                 // 跳转下一条
        //                                 this.nextClickEvent(this.$route.query.id)
        //                             }
        //                         })
        //                     } else {
        //                         this.mydisabled = false
        //                     }
        //                 })
        //                 .catch(function(error) {
        //                     console.log(error)
        //                     this.mydisabled = false
        //                 })
        //         } else {
        //             // console.log('error submit!!')
        //             this.mydisabled = false
        //             return false
        //         }
        //     })
        // },
        // // 关闭弹窗-处理
        // handleCloseSenior(done) {
        //     this.resultEvent()
        //     done()
        // },
        // // 点击取消-关闭弹窗
        // resultEvent() {
        //     this.dialogVisible = false
        //     this.ruleForm = {
        //         status: 3,
        //         remark: '',
        //         reply_img: []
        //     }
        // },
        // // 获取实时宽高
        // getwindowinfo() {
        //     //获取当前windows页面信息-实时修改【高级筛选】的宽度
        //     this.sreenheight = document.documentElement.clientHeight
        //     this.sreenwidth = document.documentElement.clientWidth
        //     if (this.sreenwidth < 600) {
        //         this.searchwidth = this.sreenwidth * 0.8 + 'px'
        //     } else {
        //         this.searchwidth = '580px'
        //     }
        // },
        // getPersonName(id, type) {
        //     let truename = ''
        //     if (type == 1) {
        //         for (let item of this.sales_people_datas) {
        //             if (item.id == id) {
        //                 truename = item.truename
        //                 break
        //             }
        //         }
        //     }
        //     if (type == 2) {
        //         for (let item of this.after_people_datas) {
        //             if (item.id == id) {
        //                 truename = item.truename
        //                 break
        //             }
        //         }
        //     }
        //     return truename
        // }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            // window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            // this.getwindowinfo()

            // 获取销售&售后的下拉
            var p1 = axios
                .get('/admin/Admin/userSaleList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        dept_type: 1
                    }
                })
                .then((response) => {
                    this.sales_people_datas = response.data.result.data
                })
                .catch(function (error) {
                    console.log(error)
                })
            var p2 = axios
                .get('/admin/Admin/userSaleList', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        dept_type: 2
                    }
                })
                .then((response) => {
                    this.after_people_datas = response.data.result.data
                })
                .catch(function (error) {
                    console.log(error)
                })

            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query
            if (routerinfo.pagesdatas) {
                this.pagesdatas = routerinfo.pagesdatas
                this.mainonebread[2].parameter.pagesdatas = routerinfo.pagesdatas
            }

            this.loading = true
            //通过Ajax向后台获取数据
            var p3 = axios
                .post(
                    '/admin/statistics/businessInfo',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    var getdatas = response.data.result.info

                    var dealdatas = response.data.result.log
                    // ******************处理状态**********************
                    this.mainpagedata.dealstatus.status = getdatas.status ? this.dealstatus_options.filter((n) => n.value == getdatas.status)[0]['label'] : ''
                    if (dealdatas.length > 0 && dealdatas[0].status && (dealdatas[0].status == 3 || dealdatas[0].status == 2)) {
                        this.mainpagedata.dealstatus.time = dealdatas[0].addtime ? this.$utils.formatDate(dealdatas[0].addtime) : ''
                        this.mainpagedata.dealstatus.remark = JSON.parse(dealdatas[0].contents).remark
                        this.mainpagedata.dealstatus.images.content =
                            JSON.parse(dealdatas[0].contents).images != ''
                                ? JSON.parse(dealdatas[0].contents)
                                      .images.split(',')
                                      .map((n) => {
                                          return {
                                              url: n,
                                              preview: [n]
                                          }
                                      })
                                : []
                    } else {
                        this.mainpagedata.dealstatus.time = ''
                        this.mainpagedata.dealstatus.remark = ''
                        this.mainpagedata.dealstatus.images.content = []
                    }

                    // ******************商户信息**********************
                    this.mainpagedata.details.content = [
                        {
                            name: '店铺名称:',
                            value: getdatas.storename
                        },
                        {
                            name: '商户姓名:',
                            value: getdatas.name
                        },
                        {
                            name: '手机号码:',
                            value: getdatas.username
                        },
                        {
                            name: '所在地区:',
                            value: getdatas.show_name
                        },
                        {
                            name: '详细地址:',
                            value: getdatas.address
                        },
                        {
                            name: '类型:',
                            value: getdatas.user_type_name
                        },
                        {
                            name: '负责人:',
                            value: getdatas.win_truename
                        },
                        {
                            name: '数据来源:',
                            value: getdatas.source_name
                        },
                        {
                            name: '身份证号:',
                            value: getdatas.idcard_no
                        }
                    ]
                    // 反显-身份证相关
                    // this.mainpagedata.details.idcard_no = getdatas.idcard_no
                    this.mainpagedata.details.imgesdata_1.content =
                        getdatas.idcard_pic_front && getdatas.idcard_pic_front != null
                            ? [
                                  {
                                      url: getdatas.idcard_pic_front,
                                      preview: [getdatas.idcard_pic_front]
                                  }
                              ]
                            : []
                    this.mainpagedata.details.imgesdata_2.content =
                        getdatas.idcard_pic_back && getdatas.idcard_pic_back != null
                            ? [
                                  {
                                      url: getdatas.idcard_pic_back,
                                      preview: [getdatas.idcard_pic_back]
                                  }
                              ]
                            : []

                    this.mainpagedata.details.imgesdata_3.content =
                        getdatas.storedoor && getdatas.storedoor != null
                            ? [
                                  {
                                      url: getdatas.storedoor,
                                      preview: [getdatas.storedoor]
                                  }
                              ]
                            : []

                    this.mainpagedata.details.imgesdata_4.content =
                        getdatas.license && getdatas.license != null
                            ? [
                                  {
                                      url: getdatas.license,
                                      preview: [getdatas.license]
                                  }
                              ]
                            : []

                    this.mainpagedata.details.imgesdata_5.content =
                        getdatas.legal_person_pic_font && getdatas.legal_person_pic_font != null
                            ? [
                                  {
                                      url: getdatas.legal_person_pic_font,
                                      preview: [getdatas.legal_person_pic_font]
                                  }
                              ]
                            : []

                    this.mainpagedata.details.imgesdata_6.content =
                        getdatas.legal_person_pic_back && getdatas.legal_person_pic_back != null
                            ? [
                                  {
                                      url: getdatas.legal_person_pic_back,
                                      preview: [getdatas.legal_person_pic_back]
                                  }
                              ]
                            : []

                    // ******************设备投放**********************
                    this.mainpagedata.details_1.content = [
                        {
                            name: '设备编号:',
                            value: getdatas.client_name
                        },
                        {
                            name: '投放方式:',
                            value: getdatas.is_buy ? this.is_buy_options.filter((n) => n.value == getdatas.is_buy)[0]['label'] : ''
                        },
                        {
                            name: '投放场景:',
                            value: getdatas.push_type ? this.push_type_options.filter((n) => n.value == getdatas.push_type)[0]['label'] : ''
                        },
                        {
                            name: '合同签约来源:',
                            value: getdatas.contract_source_name
                        },
                        {
                            name: '政策方案:',
                            value: getdatas.policy_program_name
                        }
                        // {
                        //     name: '销售人员/渠道经理:',
                        //     value: this.getPersonName(getdatas.sales_people, 1)
                        // },
                        // {
                        //     name: '售后人员:',
                        //     value: this.getPersonName(getdatas.after_people, 2)
                        // }
                    ]
                    this.mainpagedata.details_1.videosdata_1 = getdatas.push_video
                    this.mainpagedata.details_1.imgesdata_1.content =
                        getdatas.clientimg && getdatas.clientimg != null
                            ? [
                                  {
                                      url: getdatas.clientimg,
                                      preview: [getdatas.clientimg]
                                  }
                              ]
                            : []
                    this.mainpagedata.details_1.imgesdata_2.content =
                        getdatas.invoice && getdatas.invoice != null
                            ? [
                                  {
                                      url: getdatas.invoice,
                                      preview: [getdatas.invoice]
                                  }
                              ]
                            : []
                    this.mainpagedata.details_1.imgesdata_3.content =
                        getdatas.push_clientimg && getdatas.push_clientimg != null
                            ? [
                                  {
                                      url: getdatas.push_clientimg,
                                      preview: [getdatas.push_clientimg]
                                  }
                              ]
                            : []

                    //   设备合同截图
                    this.mainpagedata.details_1.imgesdata_4.content =
                        getdatas.client_contract && getdatas.client_contract != null
                            ? getdatas.client_contract.split(',').map((n) => {
                                  return {
                                      url: n,
                                      preview: [n]
                                  }
                              })
                            : []

                    // ******************设备押金**********************
                    this.mainpagedata.details_2.content = [
                        {
                            name: '支付宝账号:',
                            value: getdatas.ali_username
                        },
                        {
                            name: '支付宝平台订单编号:',
                            value: getdatas.ali_trade_no
                        }
                    ]
                    this.mainpagedata.details_2.imgesdata_1.content =
                        getdatas.deposit_pic && getdatas.deposit_pic != null
                            ? [
                                  {
                                      url: getdatas.deposit_pic,
                                      preview: [getdatas.deposit_pic]
                                  }
                              ]
                            : []

                    // ******************分佣相关**********************
                    this.mainpagedata.details_3.content = [
                        {
                            name: '商户是否分成:',
                            value: getdatas.is_ratio == 1 ? '是' : '否'
                        },
                        {
                            name: '默认佣金比例:',
                            value: getdatas.ratio
                        },
                        {
                            name: '是否自动提现:',
                            value: getdatas.is_forthwith == 1 ? '是' : '否' + ' [每月提现：' + getdatas.month_draw_times + '次，限制金额：' + getdatas.month_draw_minmoney + '元]'
                        },
                        {
                            name: '微信提现账户:',
                            value:
                                getdatas.wechat_user_id && getdatas.wechat_user_id != 0
                                    ? getdatas.wechat_user_id + ' [昵称：' + getdatas.wechat.nickname + ' ，手机号：' + getdatas.wechat.mobile + ']'
                                    : ''
                        }
                    ]

                    // ******************介绍人相关**********************
                    this.mainpagedata.details_4.content =
                        getdatas.is_referrer == 1
                            ? [
                                  {
                                      name: '是否有介绍人:',
                                      value: getdatas.is_referrer_name
                                  },
                                  {
                                      name: '介绍人:',
                                      value: getdatas.referrer_id_name
                                  },
                                  {
                                      name: '介绍人分佣比例:',
                                      value: getdatas.referrer_ratio
                                  },
                                  {
                                      name: '分佣有效期:',
                                      value: getdatas.referrer_time == 0 ? '永久' : getdatas.referrer_time + '个月'
                                  }
                              ]
                            : [
                                  {
                                      name: '是否有介绍人:',
                                      value: getdatas.is_referrer_name
                                  }
                              ]

                    // ******************打印机信息**********************
                    this.mainpagedata.details_5.content = [
                        {
                            name: '品牌:',
                            value: getdatas.brand_id_name
                        },
                        {
                            name: '型号:',
                            value: getdatas.print_type_name
                        },
                        {
                            name: '打印机序列号:',
                            value: getdatas.print_no
                        },
                        {
                            name: '设备Pwd(密码):',
                            value: getdatas.client_pwd
                        }
                    ]
                    this.mainpagedata.details_5.imgesdata_1.content =
                        getdatas.print_no_img && getdatas.print_no_img != null
                            ? [
                                  {
                                      url: getdatas.print_no_img,
                                      preview: [getdatas.print_no_img]
                                  }
                              ]
                            : []

                    // ******************权益会员**********************
                    this.mainpagedata.details_6.content =
                        getdatas.is_vip == 1
                            ? [
                                  {
                                      name: '是否参与会员:',
                                      value: getdatas.is_vip_name
                                  },
                                  {
                                      name: '权益模式:',
                                      value: getdatas.rule_id_name
                                  }
                              ]
                            : [
                                  {
                                      name: '是否参与会员:',
                                      value: getdatas.is_vip_name
                                  }
                              ]

                    // ******************操作记录**********************
                    // 渲染-table表单数据
                    var newdata = response.data.result.log
                    for (var i = 0; i < newdata.length; i++) {
                        // 转换-平台来源- 1租户平台 2商户平台 3总平台 5业务平台
                        let my_source_type = { 1: '租户平台', 2: '商户平台', 3: '总平台', 5: '业务平台' }
                        newdata[i].source_type = my_source_type[newdata[i].source_type]

                        //转换添加时间
                        if (newdata[i].addtime) {
                            if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                                newdata[i].addtime = '无操作时间'
                            } else {
                                newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                            }
                        } else {
                            newdata[i].addtime = '无操作时间'
                        }
                    }
                    this.mainpagedata.tableData = newdata
                })
                .catch(function (error) {
                    console.log(error)
                })

            Promise.all([p1, p2, p3])
                .then(() => {
                    this.loading = false
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                    this.loading = false
                })
        }
    },
    components: {
        mainone,
        maindetails,
        mainimgesshow
        // mainimgesup
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.table-box-list {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
}

.idcard_no_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: flex-start;
}

.box-list-on {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;

    font-size: 14px;
    color: #333333;
    line-height: 40px;
    margin-right: 20px;
}

.box-list-on-title {
    min-width: 100px;
    text-align: right;
    font-size: 14px;
}

.box-list-on-value {
    min-width: 260px;
    max-width: 300px;
}

.box-list-on-dange {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: flex-start;
    gap: 20px;
    margin: 10px 0;
}

.tan-box-list {
    min-height: 300px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}
</style>
