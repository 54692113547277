<template>
  <div>
    <div>
      <mainone :mainonebread="mainonebread"></mainone>
    </div>
    <div>
      <!-- 选择配色方案 -->
      <div class="mybox">
        <!-- 标题 -->
        <div class="mybox-title">
          <span>选择配色方案</span>
        </div>
        <!-- 内容 -->
        <div class="mybox-content">
          <div>
            <el-form label-width="120px" style="margin-top: 20px" ref="form1" :model="result" :rules="rule1">
              <el-form-item label="配色方案:" required="true">
                <div class="peise-change">
                  <div class="peise-change-box">
                    <div v-for="(data, index) in btnclass" :key="index" @click="clickEvent(data.myclass, index)" :style="{
                      'background-color': data.backgroundcolor,
                      border: '1px solid ' + data.backgroundcolor,
                    }" class="peise-change-box-info">
                      <div style="float: left" v-if="data.id == 'zidingyi'">
                        <div style="margin: 6px">
                          <el-color-picker v-model="data.backgroundcolor" size="mini"
                            @active-change="clickEvent(data.myclass, index)" />
                        </div>
                      </div>
                      <div class="peise-change-box-right">
                        <span style="margin-right: 10px">
                          {{ data.name }}
                        </span>
                        <div style="margin-top: 15px">
                          <div :class="data.myclass"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="顶部文字色:" required="true" prop="top_txt_color">
                <el-radio-group v-model="this.result.top_txt_color">
                  <el-radio label="#ffffff">白色</el-radio>&nbsp;<el-radio label="#000000">黑色</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="主配色:" required="true" prop="main_color">
                <div class="change-main-color-box" :style="{
                  'background-color': this.result.main_color,
                  border: '1px solid ' + this.result.main_color,
                }">
                  <!-- 左侧颜色 -->
                  <div class="change-main-color-box-left">
                    <div>
                      <el-color-picker v-model="this.result.main_color" size="mini" :disabled="this.colordisabled" />
                    </div>
                  </div>
                  <!-- 右侧input -->
                  <div class="change-main-color-box-right">
                    <el-input v-model="this.result.main_color" clearable :disabled="this.colordisabled"></el-input>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="辅助配色:" required="true" prop="sub_color">
                <div class="change-main-color-box" :style="{
                  'background-color': this.result.sub_color,
                  border: '1px solid ' + this.result.sub_color,
                }">
                  <!-- 左侧颜色 -->
                  <div class="change-main-color-box-left">
                    <div>
                      <el-color-picker v-model="this.result.sub_color" size="mini" :disabled="this.colordisabled" />
                    </div>
                  </div>
                  <!-- 右侧input -->
                  <div class="change-main-color-box-right">
                    <el-input v-model="this.result.sub_color" clearable :disabled="this.colordisabled"></el-input>
                  </div>
                </div>
              </el-form-item>
              <el-form-item style="margin-top: 50px" v-if="mypowerlimits.xcxfg_bc">
                <el-button type="primary" style="min-width: 120px; width: 10%" @click="saveinfo()">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- 当前风格示例 -->
      <!-- <div class="mybox">
        <div class="mybox-title">
          <span>当前风格示例</span>
        </div>
        <div class="mybox-content">123123</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { defineComponent } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "vstyle",
  //全局配置键mini_style
  data() {
    return {
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      mainonebread: [
        {
          name: "店铺",
        },
        {
          name: "装修",
        },
        {
          name: "小程序风格",
          path: "/setstyle",
        },
      ],
      result: {
        style_color: "#000000", // 配色方案
        top_txt_color: "#000000", // 顶部文字色
        main_color: "#ff0000", // 主配色
        sub_color: "#0000ff", // 辅配色
      },
      rule1: {
        top_txt_color: [
          {
            required: true,
            message: "顶部文字色必选",
            trigger: "change",
          },
        ],
        main_color: [
          {
            required: true,
            message: "主配色必填",
            trigger: "blur",
          },
        ],
        sub_color: [
          {
            required: true,
            message: "辅助配色必填",
            trigger: "blur",
          },
        ],
      },
      // 选择配色-配色配置
      btnclass: [
        {
          backgroundcolor: "#05bd05",
          name: "薄荷绿",
          id: "lvse",
          myclass: "mybtn",
        },
        {
          backgroundcolor: "#445EFF",
          name: "克莱因蓝",
          id: "lanse",
          myclass: "mybtnfalse",
        },
        {
          backgroundcolor: "#ee4738",
          name: "复古红",
          id: "zise",
          myclass: "mybtnfalse",
        },
        {
          backgroundcolor: "#FEB600",
          name: "柠檬黄",
          id: "jinse",
          myclass: "mybtnfalse",
        },
        {
          backgroundcolor: "#dcdfe6",
          name: "自定义",
          id: "zidingyi",
          myclass: "mybtnfalse",
        },
      ],

      // 控制-主配色&辅助配色-是否可编辑; true禁用; false可编辑
      colordisabled: true,

      // ide-维护字段
      doubleclick: 0,
    };
  },
  methods: {
    // 选择配色-配色配置
    clickEvent(classname, ide) {
      if (ide != 4) {
        this.colordisabled = true;
        // 薄荷绿
        if (ide == 0) {
          this.result.main_color = "#05bd05";
          this.result.sub_color = "#f8c058";
        }
        // 克莱因蓝
        if (ide == 1) {
          this.result.main_color = "#445EFF";
          this.result.sub_color = "#F9579D";
        }
        // 复古红
        if (ide == 2) {
          this.result.main_color = "#ee4738";
          this.result.sub_color = "#1971FF";
        }
        // 柠檬黄
        if (ide == 3) {
          this.result.main_color = "#FEB600";
          this.result.sub_color = "#ED5555";
        }
      } else {
        this.colordisabled = false;
        // 自定义颜色
        if (ide == 4) {
          this.result.main_color = "#DCDFE6";
          this.result.sub_color = "#DCDFE6";
        }
      }

      // 将该按钮的值赋予相对应的data
      this.result.style_color = this.btnclass[ide].backgroundcolor;

      // 判断当重复点击时,不切换状态
      if (ide != this.doubleclick) {
        //    将该按钮进行切换
        if (classname == "mybtnfalse") {
          for (let key in this.btnclass) {
            if (this.btnclass[key].myclass == "mybtn") {
              this.btnclass[key].myclass = "mybtnfalse";
              this.btnclass[ide].myclass = "mybtn";
              this.doubleclick = ide; // 维护ide-click字段
              return;
            }
            if (this.btnclass[key].id == "zidingyi") {
              this.btnclass[ide].myclass = "mybtn";
              this.doubleclick = ide; // 维护ide-click字段
              return;
            }
          }
        } else {
          this.btnclass[ide].myclass = "mybtnfalse";
          this.doubleclick = ide; // 维护ide-click字段
        }
      }
    },
    saveinfo() {
      this.$refs["form1"].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/admin/Diy/miniStyleUpdate",
              {
                key_name: "mini_style",
                contents: this.result,
                remark: "",
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code == 0) {
                ElMessage({
                  type: "success",
                  message: response.data.message,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      //this.getwindowinfo()

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/Diy/miniStyleInfo",
          { key_name: "mini_style" },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            this.result = response.data.result;
            // 对比-颜色-并反显配色方案
            let stylecolorarray = ["#05bd05", "#445EFF", "#ee4738", "#FEB600"];
            if (stylecolorarray.indexOf(response.data.result.style_color) != -1) {
              for (let i = 0; i < stylecolorarray.length; i++) {
                if (response.data.result.style_color == stylecolorarray[i]) {
                  this.btnclass[i].myclass = "mybtn";
                  this.doubleclick = i;
                  this.colordisabled = true;
                } else {
                  this.btnclass[i].myclass = "mybtnfalse";
                }
              }
              this.btnclass[4].myclass = "mybtnfalse";
            } else {
              let changarray = stylecolorarray;
              changarray.push(response.data.result.style_color);
              for (let i = 0; i < changarray.length; i++) {
                if (response.data.result.style_color == changarray[i]) {
                  this.btnclass[i].myclass = "mybtn";
                  this.doubleclick = i;
                  this.colordisabled = true;
                } else {
                  this.btnclass[i].myclass = "mybtnfalse";
                }
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
  },
});
</script>

<style scoped>
/* 总体box */
.mybox {
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 20px;
}

.mybox-title {
  width: 100%;
  border-bottom: 1px solid #f4f4f4;
  padding: 20px 0;
  font-size: 14px;
}

.mybox-title span {
  margin-left: 20px;
}

.mybox-content {
  padding: 20px 0;
  margin-left: 20px;
}

/* 选中css */
.check-box {
  margin: 0 10px;
  cursor: pointer;
}

.check-box:before {
  content: "";
  background: #ffffff;
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  border-radius: 100%;
  cursor: pointer;
  vertical-align: bottom;
}

.check-box.checked:before {
  background: #9ec707;
}

/* 选中button */
.mybtn {
  width: 6px;
  height: 6px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 4px solid #465eff;
}

.mybtn:hover,
.mybtnfalse:hover {
  cursor: pointer;
}

.mybtnfalse {
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #dcdfe6;
}

/* 配色选择 */
.peise-change {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
}

.peise-change-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.peise-change-box-info {
  width: 160px;
  height: 42px;
  border-radius: 4px;
  margin-right: 20px;
  margin: 0 20px 10px 0;
}

.peise-change-box-right {
  background-color: #ffffff;
  height: 42px;
  line-height: 42px;
  float: right;
  width: 100px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
}

/* 主配色&辅助配色css */
.change-main-color-box {
  display: flex;
  flex-direction: row;
  min-width: 200px;
  width: 20%;
  height: 40px;
  border-radius: 4px;
}

.change-main-color-box-left {
  padding: 5px;
}

.change-main-color-box-right {
  width: 100%;
}

/* 去除input边框 */
.change-main-color-box-right :deep(.el-input__inner) {
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
