<template>
    <div>
        <el-button type="primary" plain style="width: 200px; margin-bottom: 20px" @click="drawerEvent(1)">添加</el-button>
        <commTable
            v-loading="loading"
            element-loading-text="正在飞速加载中..."
            ref="mymultipleTableRef"
            :tabletitle="tabletitle"
            :tabledata="tableData"
            :totalcount="10000"
            :currentpage="1"
            :pagesize="1000"
            :Lastpage="1"
            :Operation="{ width: 140 }"
            :selection_no_show="true"
            @handleSelection="handleSelectionEvent"
            @handleSelectionOnline="handleSelectionChangeOnline"
        >
            <template #content="{ scope, prop }">
                <!-- 会员金额 -->
                <div v-if="prop === 'money'">
                    <div>
                        <span>{{ scope.row.price }}</span>
                        <span>元</span>
                    </div>
                    <div>
                        <span>{{ scope.row.old_price }}</span>
                        <span>元</span>
                    </div>
                </div>
                <!-- 会员有效期 -->
                <div v-else-if="prop === 'valid_time'">
                    <span>{{ scope.row.valid_time }}</span>
                </div>
                <!-- 会员图标 -->
                <div v-else-if="prop === 'icon'">
                    <el-image :src="scope.row.icon" style="width: 80px" />
                </div>
                <!-- 学习电子赠品(n选一) -->
                <div v-else-if="prop === 'gift1'" class="gift-box-up">
                    <div class="gift-box" v-for="(item, index) in scope.row.gift1" :key="index">
                        <div>
                            <el-image :src="item.img" style="width: 80px" />
                        </div>
                        <div>
                            <div>
                                <span>名称:</span><span>{{ item.name }}</span>
                            </div>
                            <!-- <div>
                                <span>库存:</span><span>{{ item.stock_num }}</span>
                            </div> -->
                            <div>
                                <span>赠送数量:</span><span>{{ item.give_num }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 学习文具赠品(n选一) -->
                <div v-else-if="prop === 'gift2'" class="gift-box-up">
                    <div class="gift-box" v-for="(item, index) in scope.row.gift2" :key="index">
                        <div>
                            <el-image :src="item.img" style="width: 80px" />
                        </div>
                        <div>
                            <div>
                                <span>名称:</span><span>{{ item.name }}</span>
                            </div>
                            <!-- <div>
                                <span>库存:</span><span>{{ item.stock_num }}</span>
                            </div> -->
                            <div>
                                <span>赠送数量:</span><span>{{ item.give_num }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 会员权益介绍 -->
                <div v-else-if="prop === 'descr_img'">
                    <el-image :src="scope.row.descr_img" style="width: 80px" />
                </div>
                <!-- 教育文库 -->
                <div v-else-if="prop === 'document_is_free'">
                    <span>{{ this.document_is_free[scope.row.document_is_free] }}</span>
                </div>
                <!-- 状态 -->
                <div v-else-if="prop === 'status'">
                    <div id="switch">
                        <el-switch
                            v-model="scope.row.status"
                            active-text="开启"
                            inactive-text="关闭"
                            :active-value="1"
                            :inactive-value="-1"
                            active-color="#13ce66"
                            inactive-color="#cbcbcb"
                            @click="changeStatus(scope.row)"
                        >
                        </el-switch>
                    </div>
                </div>
                <!-- 其他 -->
                <div v-else>
                    <span>{{ scope.row[prop] }}</span>
                </div>
            </template>
            <template #special="{ scope }">
                <div>
                    <el-button @click="drawerEvent(2, scope.row)" type="primary" size="mini">编辑</el-button>
                    <!-- <el-button @click="delEvent(scope.row, tableData, scope.$index)" type="danger" size="mini">删除</el-button> -->
                </div>
            </template>
        </commTable>

        <!-- 抽屉 -->
        <div id="mysections">
            <el-drawer :title="tanFlag == 1 ? '添加-会员等级' : '编辑-会员等级'" v-model="drawer" direction="rtl" size="70%">
                <memberLevelSetAddEdit :flag="tanFlag" :formData="formData" />
            </el-drawer>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import commTable from '@/vspecial/operate/wecom/minipluswecom/commTable.vue'
import memberLevelSetAddEdit from './memberLevelSetAddEdit.vue'
import { ElMessage } from 'element-plus'
import dict from '@/assets/js/dict.js'

export default defineComponent({
    name: 'benefitsSet',
    inject: ['reload'], //刷新引用
    data() {
        return {
            loading: false,
            tableData: [
                {
                    name: '',
                    price: '',
                    old_price: '',
                    status: '1'
                },
                {}
            ],
            tabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'name',
                    label: '会员等级名称',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'money',
                    label: '会员金额(元)',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'valid_time',
                    label: '会员有效期(月)',
                    width: '80'
                },
                {
                    fixedstatu: false,
                    prop: 'icon',
                    label: '会员图标',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'bind_discount',
                    label: '绑定设备打印折扣(折)',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'gift1',
                    label: '学习电子赠品(n选一)',
                    width: '230'
                },
                {
                    fixedstatu: false,
                    prop: 'gift2',
                    label: '学习文具赠品(n选一)',
                    width: '230'
                },
                {
                    fixedstatu: false,
                    prop: 'document_is_free',
                    label: '教育文库',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'send_cash',
                    label: '用户送余额(元)',
                    width: '80'
                },
                {
                    fixedstatu: false,
                    prop: 'descr_img',
                    label: '会员权益介绍',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'show_sort',
                    label: '展示排序',
                    width: '80'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: '90'
                }
            ],

            rules: {
                name: [
                    {
                        required: true,
                        message: '会员等级名称为必填项',
                        trigger: 'change'
                    }
                ],
                price: [
                    {
                        required: true,
                        message: '现价为必填项',
                        trigger: 'change'
                    }
                ],
                old_price: [
                    {
                        required: true,
                        message: '原价为必填项',
                        trigger: 'change'
                    }
                ],
                valid_time: [
                    {
                        required: true,
                        message: '会员有效期为必填项',
                        trigger: 'change'
                    }
                ]
            },
            document_is_free: dict.member_benefits.document_is_free,

            drawer: false,
            tanFlag: null,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')) //获取权限
        }
    },
    methods: {
        // 抽屉-添加&编辑
        drawerEvent(flag, data) {
            this.drawer = true
            this.tanFlag = flag
            if (flag == 1) {
                this.formData = {
                    name: '',
                    price: '',
                    old_price: '',
                    valid_time: '',
                    icon: '',
                    bind_discount: '',
                    gift1: [],
                    gift2: [],
                    document_is_free: 1,
                    send_cash: '',
                    descr_img: '',
                    show_sort: '',
                    status: -1
                }
            } else {
                this.formData = data
                this.formData['bind_discount'] = Number(data['bind_discount'])
                if (data.icon && data.icon != '') {
                    this.formData['icon_list'] = [
                        {
                            name: data.icon.split('/')[data.icon.split('/').length - 1],
                            url: data.icon
                        }
                    ]
                }
                if (data.descr_img && data.descr_img != '') {
                    this.formData['descr_img_list'] = [
                        {
                            name: data.descr_img.split('/')[data.descr_img.split('/').length - 1],
                            url: data.descr_img
                        }
                    ]
                }
            }
        },
        // 删除
        delEvent(data, rows, index) {
            axios
                .get('/admin/vipSet/delete', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: data.id
                    }
                })
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: '删除成功',
                            duration: 1000,
                            onClose: () => {
                                rows.splice(index, 1)
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //table表单中-状态改变-开启/关闭
        changeStatus(data) {
            axios
                .get('/admin/vipSet/disable', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: data.id,
                        status: data.status
                    }
                })
                .then((response) => {
                    if (response.data.code == 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message ? response.data.message : '切换成功'
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        axios
            .get('/admin/vipSet/index', {
                headers: {
                    Authentication: localStorage.getItem('token')
                }
            })
            .then((response) => {
                if (response.data.result) {
                    let getdatas = response.data.result
                    this.tableData = getdatas.map((n) => {
                        n.gift1 = n.gift1 && n.gift1 != '' ? JSON.parse(n.gift1) : []
                        n.gift2 = n.gift2 && n.gift2 != '' ? JSON.parse(n.gift2) : []

                        return n
                    })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    },
    components: {
        commTable,
        memberLevelSetAddEdit
    }
})
</script>
<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
    width: 98%;
}

.gift-box {
    border: 1px dashed var(--el-color-primary);
    border-radius: 4px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    gap: 10px;
}

.gift-box-up {
    display: flex;
    flex-direction: column;
    justify-self: left;
    gap: 10px;
}
</style>
