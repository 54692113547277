<template>
  <div id="mymain">
    <mainone :mainonebread="mainpagedata.mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box"  >
        <div class="box_title"  >
          <div class="box_title_font">
            <i class="el-icon-info"></i> 反馈详情
          </div>
          <div class="box_title_button">
            <!--可添加按钮-->
            <div class="main_one_button">
              <el-button
                type="primary"
                v-if="this.getdatas.solve_status === 2"
                @click="dialogVisible = true"
                >处理
              </el-button>
            </div>
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <maindetails
            :detailsdata="mainpagedata.details"
            style="margin-top: 20px"
          ></maindetails>
          <div style="margin-bottom: 20px; margin-top: 10px">
            <div style="min-width: 80px; float: left; margin-right: 20px">
              <span style="font-size: 14px; color: #333333; margin-left: 38px"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;照片:</span
              >
            </div>
            <div>
              <mainimgesshow
                :imgesdata="mainpagedata.imgesdata"
              ></mainimgesshow>
            </div>
          </div>
          <el-divider></el-divider>
          <maindetails :detailsdata="mainpagedata.details1"></maindetails>
        </div>
      </div>
    </div>
    <!--弹出框-->
    <el-dialog
      v-model="dialogVisible"
      title="处理"
      :width="searchwidth"
      :before-close="handleCloseSenior"
      style="margin-top: 200px"
    >
      <el-form
        ref="seniorForm"
        :model="seniorform"
        :rules="rules"
        label-width="130px"
        style="padding-right: 20px"
      >
        <!--以下是左侧字-->
        <el-form-item label="选择处理方式：" prop="solve_status">
          <el-radio-group v-model="seniorform.solve_status">
            <el-radio label="1">已解决</el-radio>
            <el-radio label="-1">未解决</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="原因：" prop="solve_remark">
          <el-input
            v-model="seniorform.solve_remark"
            type="textarea"
          ></el-input>
        </el-form-item>
        <div style="text-align: right">
          <el-button
            @click="resetFormSenior('seniorForm', (dialogVisible = false))"
            >取消</el-button
          >
          <el-button type="primary" @click="onSubmitSenior('seniorForm')"
            >确定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maindetails from "@/components/Maindetails";
import mainimgesshow from "@/components/Mainimgesshow";
import { ElMessage } from "element-plus";
import axios from "axios";
import { ref } from "vue";

export default {
  name: "workorderinfo",
  inject: ["reload"],
  data() {
    return {
      mainpagedata: {
        mainonebread: [
          {
            name: "售后",
          },
          {
            name: "反馈信息",
          },
          {
            name: "意见反馈",
            path: "/feedbackopinion",
          },
          {
            name: "反馈详情",
          },
        ], // 当前页-【面包屑导航】
        mainoneiconshow1: true, // 当前页-【刷新按钮】
        mainoneiconshow2: false, // 当前页-【返回按钮】

        details: {
          showstatus: 1, //确定样式模块
          title: "基本信息",
          button: {
            isshow: false,
            name: "查看微信投诉详情",
          },
          content: [
            {
              name: "ID:",
              value: "",
            },
            {
              name: "来源:",
              value: "",
            },
            {
              name: "处理状态:",
              value: "",
            },
            {
              name: "反馈者:",
              value: "",
            },
            {
              name: "联系方式:",
              value: "",
            },
            {
              name: "反馈类型:",
              value: "",
            },
          ],
        },
        details1: {
          showstatus: 1, //确定样式模块
          title: "处理信息",
          button: {
            isshow: false,
            name: "",
          },
          content: [
            {
              name: "处理备注:",
              value: "",
            },
            {
              name: "创建时间:",
              value: "",
            },
            {
              name: "处理时间:",
              value: "",
            },
          ],
        }, //关于详情信息的组件
        imgesdata: {
          showstatus: 2,
          content: [
            // {
            //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
            //   preview: [
            //     "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
            //   ],
            // },
          ],
        }, //关于图片展示的组件
      }, // 当前Pagedata所需的data数据与控制

      getdatas: {
        status: null,
      }, //接口获取的总数据
      //弹框-form表单数据
      seniorform: {
        id: "",
        solve_status: "-1",
        solve_remark: "",
      },
      rules: {
        solve_status: [
          {
            required: true,
            message: "状态为必选项",
            trigger: "blur",
          },
        ],
        solve_remark: [
          {
            required: true,
            message: "原因为必填项",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
      searchwidth: "580px",

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
    };
  },
  methods: {
    //弹出框-提交表单
    onSubmitSenior(formName) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/admin/Service/feedDeal",
              {
                id: this.$route.query.id,
                solve_status: getdatas.solve_status,
                solve_remark: getdatas.solve_remark,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                this.dialogVisible = ref(false); //关闭弹窗
                ElMessage({
                  type: "success",
                  message: response.data.message,
                  duration: 1000,
                  onClose: () => {
                    this.reload(); //刷新
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-取消
    resetFormSenior(formName) {
      this.$refs[formName].resetFields();
    },
    chuliTanClick(taninfo) {
      //点击按钮-弹出弹框
      console.log(taninfo);
    },
    getwindowinfo() {
      //获取当前windows页面信息-实时修改【高级筛选】的宽度
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Service/feedDetial", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            id: this.$route.query.id,
          },
        })
        .then((response) => {
          this.getdatas = response.data.result;
          let getdatas = response.data.result;

          // 渲染-基本信息
          this.mainpagedata.details.content = [
            {
              name: "ID:",
              value: getdatas.uid,
            },
            {
              name: "平台来源:",
              value:
                getdatas.thirdparty == 1
                  ? "微信自助版"
                  : getdatas.thirdparty == 2
                  ? "支付宝自助版"
                  : "",
            },
            {
              name: "处理状态:",
              value: getdatas.solve_status_name,
            },
            {
              name: "反馈者:",
              value: getdatas.nickname,
            },
            {
              name: "联系方式:",
              value: getdatas.mobile,
            },
            {
              name: "反馈类型:",
              value: getdatas.type_name,
            },
            {
              name: "问题描述:",
              value: getdatas.content,
            },
          ];
          
          //渲染-图片
          let getimages = [];
          if (getdatas.images) {
            getdatas.images.forEach((element) => {
              let data = {
                url: element,
                preview: [element],
              };
              getimages.push(data);
            });
          }
          this.mainpagedata.imgesdata.content = getimages;

          // 渲染-处理信息
          this.mainpagedata.details1.content = [
            {
              name: "处理备注:",
              value: getdatas.solve_remark,
            },
            {
              name: "创建时间:",
              value: getdatas.addtime
                ? this.$utils.formatDate(getdatas.addtime)
                : null,
            },
            {
              name: "处理时间:",
              value: getdatas.solve_time
                ? this.$utils.formatDate(getdatas.solve_time)
                : null,
            },
          ];
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maindetails,
    mainimgesshow,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}
</style>