<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
            v-on:selectdatainfogao="selectDatainfoGaoEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop === 'business_num' ||
                                    mytabletitle.prop === 'shopwap_num' ||
                                    mytabletitle.prop === 'shop_num' ||
                                    mytabletitle.prop === 'client_order_count' ||
                                    mytabletitle.prop === 'client_order_num' ||
                                    mytabletitle.prop === 'client_num'
                                "
                            >
                                <!-- 负责人报单统计 -->
                                <div v-if="mytabletitle.prop === 'business_num'">
                                    <div
                                        :class="this.mypowerlimits.bdtj == true && scope.row.business_num > 0 ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.bdtj == true && scope.row.business_num > 0 ? routeraddrClick('/reportsinglecens', scope.row, 1) : ''"
                                    >
                                        <span> {{ scope.row.business_num }}</span>
                                    </div>
                                </div>
                                <!-- 代理商报单统计 -->
                                <div v-if="mytabletitle.prop === 'shopwap_num'">
                                    <div
                                        :class="this.mypowerlimits.bdtj == true && scope.row.shopwap_num > 0 ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.bdtj == true && scope.row.shopwap_num > 0 ? routeraddrClick('/reportsinglecens', scope.row, 2) : ''"
                                    >
                                        <span> {{ scope.row.shopwap_num }}</span>
                                    </div>
                                </div>
                                <!-- 代理商统计 -->
                                <div v-if="mytabletitle.prop === 'shop_num'">
                                    <div
                                        :class="this.mypowerlimits.dlstj == true && scope.row.shop_num > 0 ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.dlstj == true && scope.row.shop_num > 0 ? routeraddrClick('/agentcensus', scope.row) : ''"
                                    >
                                        <span> {{ scope.row.shop_num }}</span>
                                    </div>
                                </div>
                                <!-- 设备统计 -->
                                <div v-if="mytabletitle.prop === 'client_num'">
                                    <div
                                        :class="this.mypowerlimits.sbddtj == true && scope.row.client_num > 0 ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.sbddtj == true && scope.row.client_num > 0 ? routeraddrClick('/dvcordercensus', scope.row) : ''"
                                    >
                                        <span> {{ scope.row.client_num }}</span>
                                    </div>
                                </div>
                                <!-- 设备订单 -->
                                <div v-if="mytabletitle.prop === 'client_order_num'">
                                    <div
                                        :class="this.mypowerlimits.sbddtj == true && scope.row.client_order_num > 0 ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.sbddtj == true && scope.row.client_order_num > 0 ? routeraddrClick('/dvcordercensus', scope.row) : ''"
                                    >
                                        <span> {{ scope.row.client_order_num }}</span>
                                    </div>
                                </div>
                                <!-- 订单金额 -->
                                <div v-if="mytabletitle.prop === 'client_order_count'">
                                    <div
                                        :class="this.mypowerlimits.sbddtj == true && scope.row.client_order_count > 0 ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.sbddtj == true && scope.row.client_order_count > 0 ? routeraddrClick('/dvcordercensus', scope.row) : ''"
                                    >
                                        <span> {{ scope.row.client_order_count }}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
// import { ElMessage } from "element-plus";
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vserviceusercens',
    data() {
        return {
            mainonebread: [
                {
                    name: '统计'
                },
                {
                    name: '业务统计'
                },
                {
                    name: '业务人员统计'
                }
            ], // 当前页-【面包屑导航】

            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '160px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '业务人员：',
                            placeholder: '姓名/手机号',
                            name: 'truename',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '160px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '付款时间：',
                            placeholder: {
                                placeholderstart: '开始时间',
                                placeholderend: '结束时间'
                            },
                            name: 'paytime',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            selectmaxwidth: '320px', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '报单时间：',
                            placeholder: {
                                placeholderstart: '开始时间',
                                placeholderend: '结束时间'
                            },
                            name: 'applytime',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            selectmaxwidth: '320px', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 37, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 200, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'truename',
                    label: '姓名',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'area',
                    label: '所属区域',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'parent_truename',
                    label: '直属上级',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'business_num',
                    label: '负责人报单',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'shopwap_num',
                    label: '代理商报单',
                    width: ''
                },
                // {
                //     fixedstatu: false,
                //     prop: 'apply_num',
                //     label: '报单统计',
                //     width: ''
                // },
                {
                    fixedstatu: false,
                    prop: 'shop_num',
                    label: '代理商统计',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client_num',
                    label: '设备统计',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client_order_num',
                    label: '设备订单',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client_order_count',
                    label: '订单金额',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: ''
                }
            ], // 数据列表中-数据表头

            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/statistics/winUser',
                    {
                        truename: this.pagesdatas.truename,
                        paytime: this.pagesdatas.paytime,
                        applytime: this.pagesdatas.applytime,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`)
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/statistics/winUser',
                    {
                        truename: this.pagesdatas.truename,
                        paytime: this.pagesdatas.paytime,
                        applytime: this.pagesdatas.applytime,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata, flag) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转-报单统计
                if (routeraddr == '/reportsinglecens') {
                    console.log(this.pagesdatas);
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                win_user_id: rowdata.id,
                                win_user_name: rowdata.truename + '(' + rowdata.area + ')',
                                addtime: this.pagesdatas.applytime,
                                source: flag
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-代理商统计
                if (routeraddr == '/agentcensus') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                win_user_id: rowdata.id,
                                win_user_name: rowdata.truename + '(' + rowdata.area + ')',
                                paytime: this.pagesdatas.paytime
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-设备订单统计
                if (routeraddr == '/dvcordercensus') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                win_user_id: rowdata.id,
                                win_user_name: rowdata.truename + '(' + rowdata.area + ')',
                                paytime: this.pagesdatas.paytime
                            }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/statistics/winUser',
                    {
                        truename: this.pagesdatas.truename,
                        paytime: this.pagesdatas.paytime,
                        applytime: this.pagesdatas.applytime,
                        export: 1
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：',data)
            //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            this.pagesdatas = data
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            var paytime = null
            if (data.paytime.start == '') {
                paytime = ''
            } else {
                if (data.paytime.end == '') {
                    paytime = data.paytime.start + ' ~ ' + (data.paytime.start + 86400 - 1)
                } else {
                    paytime = data.paytime.start + ' ~ ' + (data.paytime.end + 86400 - 1)
                }
            }
            this.pagesdatas.paytime = paytime

            var applytime = null
            if (data.applytime.start == '') {
                applytime = ''
            } else {
                if (data.applytime.end == '') {
                    applytime = data.applytime.start + ' ~ ' + (data.applytime.start + 86400 - 1)
                } else {
                    applytime = data.applytime.start + ' ~ ' + (data.applytime.end + 86400 - 1)
                }
            }
            this.pagesdatas.applytime = applytime

            axios
                .post(
                    '/admin/statistics/winUser',
                    {
                        truename: data.truename,
                        paytime: this.pagesdatas.paytime,
                        applytime: this.pagesdatas.applytime,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            axios
                .post(
                    '/admin/statistics/winUser',
                    {
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
