<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">
            数据列表 &nbsp; &nbsp; &nbsp; &nbsp; 实付：<span style="color: red">¥ {{ this.count.sftotal ? this.count.sftotal :
                0
            }}</span>
            &nbsp; &nbsp; 公司：<span style="color: red">¥
              {{ this.count.companyprice ? this.count.companyprice : 0 }}</span>
            &nbsp; &nbsp; 一代：<span style="color: red">¥ {{ this.count.agentprice ? this.count.agentprice : 0 }}</span>
            &nbsp; &nbsp; 二代：<span style="color: red">¥ {{ this.count.shopprice ? this.count.shopprice : 0 }}</span>
            &nbsp; &nbsp; 三代：<span style="color: red">¥ {{ this.count.fshopprice ? this.count.fshopprice : 0 }}</span>
          </div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'trade_no' ||
                mytabletitle.prop === 'companyword' ||
                mytabletitle.prop === 'agent_one' ||
                mytabletitle.prop === 'agent_two' ||
                mytabletitle.prop === 'agent_three'
              ">
                <!--订单编号-样式等渲染-->
                <div v-if="mytabletitle.prop === 'trade_no'">
                  <div style="cursor: pointer" @click="routeraddrClick('/orderlistinfo', scope.row)">
                    <span style="color: #465eff">{{ scope.row.trade_no }}</span>
                  </div>
                </div>
                <!-- 公司-渲染数据 -->
                <div v-if="mytabletitle.prop === 'companyword'">
                  <div v-if="scope.row.companyid_name">
                    {{
                        scope.row.companyid_name +
                        "\n 分成:￥" +
                        (scope.row.companyprice ||
                          scope.row.companyprice == 0
                          ? scope.row.companyprice
                          : "")
                    }}
                  </div>
                </div>
                <!-- 代理商一代-渲染数据 -->
                <div v-if="mytabletitle.prop === 'agent_one'">
                  <div v-if="scope.row.agentid_name">
                    {{
                        scope.row.agentid_name +
                        "\n 分成:￥" +
                        (scope.row.agentprice || scope.row.agentprice == 0
                          ? scope.row.agentprice
                          : "")
                    }}
                  </div>
                  <div v-else>---</div>
                </div>
                <!-- 代理商二代-渲染数据 -->
                <div v-if="mytabletitle.prop === 'agent_two'">
                  <div v-if="scope.row.shopid_name">
                    {{
                        scope.row.shopid_name +
                        "\n 分成:￥" +
                        (scope.row.shopprice || scope.row.shopprice == 0
                          ? scope.row.shopprice
                          : "")
                    }}
                  </div>
                  <div v-else>---</div>
                </div>
                <!-- 代理商三代-渲染数据 -->
                <div v-if="mytabletitle.prop === 'agent_three'">
                  <div v-if="scope.row.fshopid_name">
                    {{
                        scope.row.fshopid_name +
                        "\n 分成:￥" +
                        (scope.row.fshopprice || scope.row.fshopprice == 0
                          ? scope.row.fshopprice
                          : "")
                    }}
                  </div>
                  <div v-else>---</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "dvcordercensusdetail",
  data() {
    return {
      mainonebread: [
        {
          name: "统计",
        },
        {
          name: "打印统计",
        },
        {
          name: "设备订单统计",
          path: "/dvcordercensus",
        },
        {
          name: "设备订单明细",
          path: "",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "订单编号：",
              placeholder: "请输入订单编号",
              name: "trade_no",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "订单状态：",
              placeholder: "全部",
              name: "pay_status",
              value: "",
              options: [
                {
                  value: "",
                  label: "全部",
                },
                {
                  value: "0",
                  label: "待付款",
                },
                {
                  value: "1",
                  label: "已支付",
                },
                {
                  value: "2",
                  label: "申请退款",
                },
                {
                  value: "3",
                  label: "已退款",
                },
                {
                  value: "4",
                  label: "部分退款",
                },
                {
                  value: "5",
                  label: "退款驳回",
                },
                {
                  value: "-2",
                  label: "已删除",
                },
              ],
            },
          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "thirdparty",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 36, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 50, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "trade_no",
          label: "订单编号",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "pay_time",
          label: "支付时间",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "pay_status_name",
          label: "订单状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "total",
          label: "订单金额",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "discount_price",
          label: "优惠",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "sftotal",
          label: "实付金额",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "type_name",
          label: "打印类型",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "companyword",
          label: "公司",
          width: "160",
        },
        {
          fixedstatu: false,
          prop: "agent_one",
          label: "代理商家（一代）",
          width: "160",
        },
        {
          fixedstatu: false,
          prop: "agent_two",
          label: "代理商家（二代）",
          width: "160",
        },
        {
          fixedstatu: false,
          prop: "agent_three",
          label: "代理商家（三代）",
          width: "160",
        },
      ],
      // 数据列表中-数据表头
      mainthreetableData: [],
      // 数据列表中-具体数据
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "订单编号：",
            placeholder: "请输入订单编号",
            name: "trade_no",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "订单状态:",
            placeholder: "订单状态:",
            name: "pay_status",
            value: "",
            options: [
              {
                value: "",
                label: "全部",
              },
              {
                value: "0",
                label: "待付款",
              },
              {
                value: "1",
                label: "已支付",
              },
              {
                value: "2",
                label: "申请退款",
              },
              {
                value: "3",
                label: "已退款",
              },
              {
                value: "4",
                label: "部分退款",
              },
              {
                value: "5",
                label: "退款驳回",
              },
              {
                value: "-2",
                label: "已删除",
              },
            ],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "支付时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "pay_time",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myselecttopisshow: true, //高级筛选-select单框-放input框之前
      myselecttop: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "平台来源：",
            placeholder: "全部",
            name: "thirdparty",
            value: "1", //显示值，初始状况，显示值与默认值需相同
            valueisshow: true,
            valuedefault: "1", //设置默认值
            options: componentProperties.get('thirdpartyoption').options,
          },
        ],
      },

      count: {}, // 合计-价格数据等

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  setup() {
    const dialogVisible = ref(false);
    const handleCloseSenior = (done) => {
      done();
    };

    return {
      dialogVisible,
      handleCloseSenior,
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Statistics/clientOrderList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            clientid: this.$route.query.id,
            thirdparty: this.pagesdatas.thirdparty,
            trade_no: this.pagesdatas.trade_no,
            pay_status: this.pagesdatas.pay_status,
            pay_time: this.pagesdatas.pay_time,
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      // console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Statistics/clientOrderList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            clientid: this.$route.query.id,
            thirdparty: this.pagesdatas.thirdparty,
            trade_no: this.pagesdatas.trade_no,
            pay_status: this.pagesdatas.pay_status,
            pay_time: this.pagesdatas.pay_time,
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 携带参数跳转
        if (routeraddr === "/orderlistinfo") {
          this.$router
            .push({
              path: routeraddr,
              query: { orderno: rowdata.trade_no },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      axios
        .get("/admin/Statistics/clientOrderList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            clientid: this.$route.query.id,
            thirdparty: this.pagesdatas.thirdparty,
            trade_no: this.pagesdatas.trade_no,
            pay_status: this.pagesdatas.pay_status,
            pay_time: this.pagesdatas.pay_time,
            export: 1,
          },
        })
        .then((response) => {
          console.log(response.data.message)
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用

      //加载loading
      this.loading = true;
      var getpaytime = "";
      if (data.pay_time.start === "") {
        getpaytime = "";
      } else {
        if (data.pay_time.end === "") {
          getpaytime =
            data.pay_time.start + " ~ " + (data.pay_time.start + 86400 - 1);
        } else {
          getpaytime =
            data.pay_time.start + " ~ " + (data.pay_time.end + 86400 - 1);
        }
      }
      this.pagesdatas.pay_time = getpaytime;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Statistics/clientOrderList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            clientid: this.$route.query.id,
            trade_no: data.trade_no,
            pay_status: data.pay_status,
            thirdparty: data.thirdparty,
            pay_time: getpaytime,
            page: 1,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;

      //通过Ajax向后台获取数据
      axios
        .get("/admin/Statistics/clientOrderList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            clientid: this.$route.query.id,
            thirdparty: data.thirdparty,
            trade_no: data.trade_no,
            pay_status: data.pay_status,
            page: 1,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      // 合计-价格数据等
      this.count = successdata.count;

      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //转换支付时间
        if (newdata[i].pay_time) {
          if (newdata[i].pay_time === 0 || newdata[i].pay_time === null) {
            newdata[i].pay_time = "无支付时间";
          } else {
            newdata[i].pay_time = this.$utils.formatDate(newdata[i].pay_time);
          }
        } else {
          newdata[i].pay_time = "无支付时间";
        }
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true;

      // 定义
      this.pagesdatas = {
        thirdparty: "1",
        page_size: this.PageSize,
        page: 1,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.id) {
        this.pagesdatas = {
          clientid: routerinfo.id,
          thirdparty: routerinfo.thirdparty,
          page_size: this.PageSize,
          page: 1,
        };
      }

      //通过Ajax向后台获取数据
      axios
        .get("/admin/Statistics/clientOrderList", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: this.pagesdatas,
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>