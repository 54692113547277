<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width"
              :min-width="mytabletitle.minwidth">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'order_num' ||
              mytabletitle.prop === 'status' ||
              mytabletitle.prop === 'opera_num' ||
              mytabletitle.prop === 'pay_money' ||
              mytabletitle.prop === 'operatime' ||
              mytabletitle.prop === 'success_money' ||
              mytabletitle.prop === 'refused_money'">
                <!-- 最终转账金额 -->
                <div v-if="mytabletitle.prop === 'pay_money'">
                  <div>
                    <span>{{ scope.row.pay_money > 0 ? '+' + scope.row.pay_money : scope.row.pay_money }}</span>
                  </div>
                </div>
                <!-- 提现笔数 -->
                <div v-if="mytabletitle.prop === 'order_num'">
                  <div :class="this.mypowerlimits.txjl == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.txjl == true ? routeraddrClick('/withdrawalrecord', scope.row) : ''">
                    <span>{{ scope.row.order_num == 0 ? '' : scope.row.order_num }}</span>
                  </div>
                </div>

                <!-- 提现成功 -->
                <div v-if="mytabletitle.prop === 'success_money'">
                  <div>
                    <span>{{ scope.row.success_money == 0 ? '' : scope.row.success_money }}</span>
                  </div>
                </div>
                <!-- 提现拒绝 -->
                <div v-if="mytabletitle.prop === 'refused_money'">
                  <div>
                    <span>{{ scope.row.refused_money == 0 ? '' : scope.row.refused_money }}</span>
                  </div>
                </div>

                <!-- 操作时间 -->
                <div v-if="mytabletitle.prop === 'operatime'">
                  <div>
                    <span>{{ scope.row.operatime == 0 ? '' : this.$utils.formatDate(scope.row.operatime) }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>

            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" prop="myoperation" id="operation" :width="mainthreehandle">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-代理商列表-->
                <div>
                  <el-button type="primary" plain size="mini"
                    @click="handlelogsEvent(scope.row, (dialogVisible1 = true))">操作记录</el-button>
                  <el-button type="primary" size="mini" @click="addTanClick(scope.row)" v-if="scope.$index == 0"> 确认转账
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出框-确认转账 -->
    <el-dialog v-model="dialogVisible" title="确认转账" :width="this.searchwidth" :before-close="handleCloseSenior">
      <el-form ref="ruleForm" :model="this.ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
        <div style="
            max-height: 450px;
            overflow: hidden;
            overflow-y: visible;
            margin: 20px 5% 0 0;
          ">
          <el-form-item label="选择资方：" prop="funder">
            <el-select v-model="ruleForm.funder" placeholder="请选择资方" style="width: 100%;">
              <el-option v-for="item in this.funderoptions" :key="item.value" :label="(item.label + ' - ' + item.type)"
                :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="转账金额：" prop="money">
            <el-input v-model="ruleForm.money" placeholder="请输入转账金额" clearable></el-input>
            <div style="font-size: 12px;color: #777;"><span>可转金额: {{ ruleForm.paymoney }}元</span>&nbsp;&nbsp;&nbsp;
              <el-button type="text" size="small" @click="transformMoneyEvent(ruleForm.paymoney)">全部转账</el-button>
            </div>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input v-model="ruleForm.remark" :autosize="{ minRows: 2 }" type="textarea" placeholder="请填写备注">
            </el-input>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button @click="resetForm()">取消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')" :disabled="mydisabled">确认</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>

    <!-- 弹出框-操作记录 -->
    <el-dialog v-model="dialogVisible1" title="操作记录" :width="this.searchwidth1" :before-close="handleCloseSenior">
      <div style="margin-top: 10px;">
        <el-table :header-cell-style="{ background: '#F5F7FA', height: '50px' }" :data="transformtableData" border
          style="width: 100%" v-loading="loading1" element-loading-text="正在飞速加载中...">
          <el-table-column prop="addtime" label="操作时间" width="180">
            <template #default="scope">
              <div>{{ this.$utils.formatDate(scope.row.addtime) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="truename" label="操作人" width="130" />
          <el-table-column prop="company_name" label="转账对象" width="350" />
          <el-table-column prop="money" label="转账金额" />
          <el-table-column prop="success_money" label="到账金额(包含手续费)" width="200">
            <template #default="scope">
              <div>{{ scope.row.success_money % 1 == 0 ? scope.row.success_money + '.00' : scope.row.success_money }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" />
        </el-table>
      </div>
      <div style="font-size: 12px; color: #777;margin: 10px 0;">
        <span>当日合计到账金额：{{ this.transformtableTotalmoney % 1 == 0 ? this.transformtableTotalmoney + '.00' :
            this.transformtableTotalmoney
        }} 元(包含手续费)</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vplatformprintsubcom",
  inject: ["reload"], //刷新引用
  data() {
    // 【加减款】金额-校验
    var checkInputEvent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("必填项不能为空"));
      }
      if (value.toString().indexOf(" ") != -1) {
        return callback(new Error("值中不能有空格"));
      }
      setTimeout(() => {
        // 判断字符串是否为文字
        if (isNaN(value)) {
          callback(new Error("请输入数字金额"));
        } else {
          let result = value.toString().indexOf('.');
          if (result != -1) {
            let getdata = value.toString().split(".");
            if (getdata[getdata.length - 1].length > 2) {
              callback(new Error("请保留小数点后两位"));
            }
          }
          if (value < 0) {
            callback(new Error("值需大于0"));
          } else {
            callback();
          }
        }
      }, 500);
    };
    return {
      mainonebread: [
        {
          name: "财务",
        },
        {
          name: "提现管理",
        },
        {
          name: "平台打印分佣",
          path: "/platformprintsubcom",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: "统计日期：",
              placeholder: {
                placeholderstart: "请选择开始时间",
                placeholderend: "请选择结束时间",
              },
              name: "time",
              value: {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
              },
              rangeseparator: "至", //分隔符
              selectmaxwidth: "100%", //时间段-最大宽度
              marginright: "0", //与右侧距离
            },

          ],
        },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "platform",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: [
                {
                  value: "1",
                  label: "微信自助版",
                },
                {
                  value: "2",
                  label: "支付宝自助版",
                },
                {
                  value: "3",
                  label: "微信企业版",
                },
                {
                  value: "4",
                  label: "微信家庭版",
                },
                {
                  value: "5",
                  label: "支付宝企业版",
                },
                {
                  value: "6",
                  label: "支付宝家庭版",
                },
              ],
            },
          ],
        },
      }, // 筛选-【筛选查询】-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 33, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 170, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: false,
          prop: "time",
          label: "日期",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "total_in",
          label: "佣金入账",
          minwidth: "150",
        },
        {
          fixedstatu: false,
          prop: "refund",
          label: "佣金退款",
          minwidth: "150",
        },
        {
          fixedstatu: false,
          prop: "pay_money",
          label: "可转金额",
          minwidth: "150",
        },
        {
          fixedstatu: false,
          prop: "success_money",
          label: "实际转账金额(包含手续费)",
          minwidth: "200",
        },
        {
          fixedstatu: false,
          prop: "order_num",
          label: "转账笔数",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "operatime",
          label: "最近一次转账时间",
          width: "180",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1000, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      Layout: " sizes, prev, pager, next, jumper",

      //分页
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      // 确认转账-弹窗
      // 确认转账-弹窗-弹窗相关数据
      dialogVisible: ref(false), //弹窗显示&隐藏
      // 确认转账-弹窗form表单
      ruleForm: {
        id: '',
        funder: '', //资金方
        money: '',
        remark: "",
      },
      // 确认转账-弹窗中-form表单-必填规则
      rules: {
        funder: [
          {
            required: true,
            message: "资金方为必选项",
            trigger: "blur",
          },
        ],
        money: [
          {
            required: true,
            message: "转账金额不能为空",
            trigger: "blur",
          },
          {
            required: true,
            validator: checkInputEvent,
            trigger: "change",
          }
        ],
        remark: [
          {
            required: false,
            message: "备注不能为空",
            trigger: "blur",
          },
        ],
      },
      // 确认转账-资金方相关下拉
      funderoptions: [],
      searchwidth: '560px',

      // 操作记录
      dialogVisible1: ref(false), //弹窗显示&隐藏
      transformtableData: [],
      transformtableTotalmoney: 0,
      searchwidth1: '1160px',
      loading1: false,

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用

      mydisabled: false
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 操作记录-点击按钮-弹窗
    handlelogsEvent(taninfo) {
      this.transformtableData = [];
      this.transformtableTotalmoney = 0;

      this.loading1 = true;
      setTimeout(() => {
        axios
          .post(
            "/admin/cashflow/commissionLog",
            {
              id: taninfo.id
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.loading1 = false;
            this.transformtableData = response.data.result.data;
            this.transformtableTotalmoney = response.data.result.total_money;
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error);
          });
      }, 100)

    },
    // 确认转账-点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      if (taninfo.order_num > 0) {
        ElMessageBox.confirm("今日已完成 " + taninfo.order_num + " 笔转账，您是否需要继续转账？", "转账确认：", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "primary",
        })
          .then(() => {
            this.ruleForm = {
              id: taninfo.id,
              funder: '',
              money: '',
              paymoney: taninfo.pay_money,
              remark: '',
            };

            this.dialogVisible = true;
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: "已取消",
            });
            this.mydisabled = false;
          });
      } else {
        this.ruleForm = {
          id: taninfo.id,
          funder: '',
          money: '',
          paymoney: taninfo.pay_money,
          remark: '',
        };
        this.dialogVisible = true;
      }

    },
    // 确认转账-点击全部转账-弹窗赋值
    transformMoneyEvent(data) {
      this.ruleForm.money = data
    },
    //弹出框-提交表单
    submitForm(formName) {
      // 前端防抖
      this.mydisabled = true;
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ElMessageBox.confirm("最终转账金额：￥" + getdatas.money, "确认转账：", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "primary",
          })
            .then(() => {
              axios
                .post(
                  "/admin/cashflow/printCommissionWithdraw",
                  {
                    id: getdatas.id,
                    value: getdatas.funder,
                    money: getdatas.money,
                    remark: getdatas.remark,
                  },
                  {
                    headers: {
                      Authentication: localStorage.getItem("token"),
                    },
                  }
                )
                .then((response) => {
                  if (response.data.code == 0) {
                    this.dialogVisible = ref(false); //关闭弹窗
                    ElMessage({
                      type: "success",
                      message: response.data.message,
                      duration: 1000,
                      onClose: () => {
                        this.reload(); //刷新
                        this.mydisabled = false;
                      },
                    });
                  } else {
                    this.mydisabled = false;
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  this.mydisabled = false;
                });
            })
            .catch(() => {
              ElMessage({
                type: "info",
                message: "已取消",
              });
              this.mydisabled = false;
            });
        } else {
          console.log("error submit!!");
          this.mydisabled = false;
          return false;
        }
      });
    },
    //弹出框-取消表单
    resetForm() {
      this.dialogVisible = ref(false); //关闭弹窗
    },

    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/cashflow/printCommission",
          {
            platform: this.pagesdatas.platform,
            status: this.pagesdatas.status,
            time: this.pagesdatas.time,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      // console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/cashflow/printCommission",
          {
            platform: this.pagesdatas.platform,
            status: this.pagesdatas.status,
            time: this.pagesdatas.time,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 跳转-提现记录
        if (routeraddr == "/withdrawalrecord") {
          this.$router
            .push({
              path: routeraddr,
              query: {
                thirdparty: rowdata.platform,
                relation_id: rowdata.id,
                user_type: 4,
              },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/cashflow/printCommission",
          {
            platform: this.pagesdatas.platform,
            status: this.pagesdatas.status,
            time: this.pagesdatas.time,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message)
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }

      if (this.sreenwidth < 1200) {
        this.searchwidth1 = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth1 = "1160px";
      }
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-申请时间段状态
      var gettime = null;
      if (data.time.start == "") {
        gettime = "";
      } else {
        if (data.time.end == "") {
          gettime =
            data.time.start + " ~ " + (data.time.start + 86400 - 1);
        } else {
          gettime =
            data.time.start + " ~ " + (data.time.end + 86400 - 1);
        }
      }
      this.pagesdatas.time = gettime;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/cashflow/printCommission",
          {
            platform: data.platform,
            status: data.status,
            time: gettime,
            page_size: this.PageSize,
            page: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      // 获取 资金方下拉信息
      axios
        .post("/admin/config/info",
          {
            key_name: 'withdraw_zjf',
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          })
        .then((response) => {
          var configinfo = response.data.result
          var alldatas = Array();
          var num = 0;
          for (var key in configinfo) {
            if (configinfo[key].status == true) {
              alldatas[num] = { value: configinfo[key].id, label: configinfo[key].name, type: configinfo[key].type == 'banks' ? '银行卡' : (configinfo[key].type == 'ali' ? '支付宝' : '') };
              num = num + 1;
            }
          }
          this.funderoptions = alldatas
        })
        .catch(function (error) {
          console.log(error);
        });


      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      // 定义
      this.pagesdatas = {
        thirdparty: "1",
        page_size: this.PageSize,
        page: 1,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.status) {
        this.pagesdatas.status = routerinfo.status;
      }
      if (routerinfo.thirdparty) {
        this.pagesdatas.platform = routerinfo.platform;
        this.maintwoforminfo.selecttop.select[0].value = routerinfo.platform;
      }

      //通过Ajax向后台获取数据
      axios
        .post("/admin/cashflow/printCommission", this.pagesdatas, {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-table-addr {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.mytabletext,
.box-table-addr:hover {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>