<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <!-- <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo> -->
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="primary" plain @click="drawerEvent(1)" v-if="mypowerlimits.vipsjqy_cash">添加邀请送余额</el-button>
                        <el-button type="primary" plain @click="drawerEvent(3)" v-if="mypowerlimits.vipsjqy_coupon">添加邀请送商家券</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="mytabletitle.prop === 'type' || mytabletitle.prop === 'client_num' || mytabletitle.prop === 'addtime' || mytabletitle.prop === 'status'"
                            >
                                <!-- 商家权益 -->
                                <div v-if="mytabletitle.prop === 'type'">
                                    <span>{{ type_options[scope.row.type] }}</span>
                                </div>
                                <!-- 设备数量 -->
                                <div v-if="mytabletitle.prop === 'client_num'">
                                    <span style="margin-right: 10px">{{ scope.row.client_num }}</span>
                                    <span>个</span>
                                </div>
                                <!-- 添加时间 -->
                                <div v-if="mytabletitle.prop === 'addtime'">
                                    <span> {{ scope.row.addtime !== 0 && scope.row.addtime !== null ? this.$utils.formatDate(scope.row.addtime) : '' }}</span>
                                </div>
                                <!-- 状态-渲染数据 -->
                                <!-- @click="this.mypowerlimits.vipsjqy_qy == true ? changeStatus(scope.row) : ''"
                                        :disabled="!this.mypowerlimits.vipsjqy_qy" -->
                                <div v-if="mytabletitle.prop === 'status'" id="switch">
                                    <el-switch
                                        v-model="scope.row.status"
                                        active-text="开启"
                                        inactive-text="关闭"
                                        :active-value="1"
                                        :inactive-value="-1"
                                        active-color="#13ce66"
                                        inactive-color="#cbcbcb"
                                        @click="changeStatus(scope.row)"
                                    >
                                    </el-switch>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" prop="myoperation" id="operation">
                            <template #default="scope">
                                <div>
                                    <el-button v-if="mypowerlimits.vipsjqy_sbsz" type="primary" size="mini" @click="routeraddrClick('/deviceSet', scope.row)">设备设置</el-button>
                                    <el-button
                                        v-if="mypowerlimits.vipsjqy_gzsz"
                                        type="primary"
                                        size="mini"
                                        @click="drawerEvent(scope.row.type == 1 ? 2 : 4, scope.row, scope.row.client_num > 0 ? true : false)"
                                    >
                                        规则设置
                                    </el-button>
                                    <el-button
                                        v-if="mypowerlimits.vipsjqy_sc"
                                        type="danger"
                                        size="mini"
                                        icon="el-icon-delete"
                                        @click="delEvent(mainthreetableData, scope.$index)"
                                    ></el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 抽屉 -->
        <div id="mysections">
            <el-drawer :title="tanFlagOption[tanFlag]" v-model="drawer" direction="rtl" size="70%">
                <inviteToBalanceOrCoupon :flag="tanFlag" :formData="formData" :isDisabled="is_disabled" />
            </el-drawer>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
// import maintwo from '@/components/Maintwo'
import inviteToBalanceOrCoupon from './inviteToBalanceOrCoupon.vue'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'

export default defineComponent({
    name: 'vshopEquity',
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '权益会员'
                },
                {
                    name: '商家权益'
                }
            ], // 当前页-【面包屑导航】
            // maintwoiconshow: false, // 筛选-【高级筛选按钮】
            // maintwoforminfo: {
            //     myinputoneisshow: true, //筛选查询-左侧字+input
            //     myinputone: {
            //         divinterval: '0', //框右侧距离
            //         titlewidth: '74px', //标题宽度
            //         inputwidth: '200px', //input宽度
            //         inputinterval: '20px', //input右侧距离
            //         input: [
            //             {
            //                 ismust: false, //是否必填，true表示必填
            //                 title: '设备编号：',
            //                 placeholder: '请输入设备编号',
            //                 name: 'client',
            //                 content: ''
            //             },
            //             {
            //                 ismust: false, //是否必填，true表示必填
            //                 title: '安装人员：',
            //                 placeholder: '输入搜索',
            //                 name: 'personnel',
            //                 content: ''
            //             }
            //         ]
            //     },
            //     selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
            //     selectleft: {
            //         width: 'none',
            //         divinterval: '', //框右侧距离
            //         selectwidth: '200px',
            //         selectinterval: '20px', //select右侧距离
            //         select: [
            //             {
            //                 selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            //                 ismust: false,
            //                 title: '投放时间：',
            //                 placeholder: {
            //                     placeholderstart: '请选择开始时间',
            //                     placeholderend: '请选择结束时间'
            //                 },
            //                 name: 'putintime',
            //                 value: {
            //                     valuestart: ref(''),
            //                     valueend: ref(''),
            //                     endstatus: true
            //                 },
            //                 rangeseparator: '至', //分隔符
            //                 divinterval: '3%', //上方字中-框右侧距离
            //                 selectwidth: '100%', //上方字中-总框长度
            //                 selectmaxwidth: '97%', //时间段-最大宽度
            //                 marginright: '0' //与右侧距离
            //             }
            //         ]
            //     }
            // }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 240, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'ID',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'rule_name',
                    label: '规则名称',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'type',
                    label: '商家权益',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'client_num',
                    label: '设备数量',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'truename',
                    label: '添加人',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '添加时间',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '状态',
                    width: '100'
                }
            ], // 数据列表中-数据表头
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            currentPage: 1,
            pageSizes: [20, 50, 100, 1000],
            PageSize: 20,

            type_options: { 1: '邀请送余额', 2: '邀请送商家券' },
            tanFlagOption: { 1: '邀请送余额（添加）', 2: '邀请送余额（规则设置）', 3: '邀请送商家券（添加）', 4: '邀请送商家券（规则设置）' },
            drawer: false,
            formData: {},
            is_disabled: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        drawerEvent(flag, data, is_disabled) {
            this.drawer = true
            this.tanFlag = flag

            if (flag == 1) {
                // 邀请送余额（添加）
                this.is_disabled = false
                axios
                    .get('/admin/vipRule/info', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            type: 1
                        }
                    })
                    .then((response) => {
                        let datas = response.data.result.content

                        if (datas.data.length > 0) {
                            datas.data.map((n, index) => {
                                if (n.shop_invite.length == 0) {
                                    n.shop_invite.push({
                                        invite_num: '',
                                        invite_income: ''
                                    })
                                }
                                if (n.user_buy.length == 0) {
                                    n.user_buy.push({
                                        invite_num: '',
                                        invite_income: ''
                                    })
                                }
                            })
                        }

                        this.formData = {
                            type: 1,
                            rule_name: '',
                            content: datas,
                            status: '-1'
                        }
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            } else if (flag == 2) {
                this.is_disabled = is_disabled
                // 邀请送余额（规则设置）
                axios
                    .get('/admin/vipRule/info', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            id: data.id,
                            type: 1
                        }
                    })
                    .then((response) => {
                        let getdatas = response.data.result

                        this.formData = {
                            id: getdatas.id,
                            type: getdatas.type,
                            rule_name: getdatas.rule_name,
                            content: getdatas.content,
                            status: data.status.toString()
                        }
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            } else if (flag == 3) {
                // 邀请送商家券（添加）
                this.is_disabled = false
                axios
                    .get('/admin/vipRule/info', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            type: 2
                        }
                    })
                    .then((response) => {
                        this.formData = {
                            type: 2,
                            rule_name: '',
                            content: response.data.result.content,
                            status: '-1'
                        }
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            } else if (flag == 4) {
                // 邀请送商家券（规则设置）
                this.is_disabled = is_disabled
                axios
                    .get('/admin/vipRule/info', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: {
                            id: data.id,
                            type: 2
                        }
                    })
                    .then((response) => {
                        let getdatas = response.data.result

                        this.formData = {
                            id: getdatas.id,
                            type: getdatas.type,
                            rule_name: getdatas.rule_name,
                            content: getdatas.content,
                            status: data.status.toString()
                        }
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            }
        },
        // 切换状态
        changeStatus(data) {
            axios
                .get('/admin/vipRule/disable', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        id: data.id,
                        status: data.status
                    }
                })
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = -1
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        //删除
        delEvent(rows, index) {
            ElMessageBox.confirm("确定删除该条 ' " + rows[index].rule_name + " ' 数据吗?", '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .get('/admin/vipRule/delete', {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            },
                            params: {
                                id: rows[index].id
                            }
                        })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功'
                                })
                                rows.splice(index, 1)
                            }
                        })
                        .catch(function (error) {
                            // 请求失败处理
                            ElMessage({
                                type: 'error',
                                message: '删除失败！' + error
                            })
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除'
                    })
                })
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.postAxiosEvent({
                page: 1,
                page_size: val
            })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.postAxiosEvent({
                page: val,
                page_size: this.PageSize
            })
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, row) {
            if (row) {
                this.$router.push({ path: routeraddr, query: { id: row.id } }).catch((error) => error)
            } else {
                this.$router.push({ path: routeraddr }).catch((error) => error)
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        // getSeachinfoEvent(data) {
        //     // console.log('点击查询获取的data数组：', data)
        //     this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用

        //     this.postAxiosEvent({
        //         page: 1,
        //         page_size: this.PageSize
        //     })
        // },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        postAxiosEvent(data) {
            this.loading = true
            axios
                .get('/admin/vipRule/index', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: data
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        } else {
            this.postAxiosEvent({
                page: 1,
                page_size: this.PageSize
            })
        }
    },
    components: {
        mainone,
        inviteToBalanceOrCoupon
        // maintwo
    }
})
</script>
<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
}
</style>
