<template>
    <div>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="padding-top: 10px">
                    <el-tabs v-model="activeName" @tab-click="handleClickTabs">
                        <el-tab-pane label="待发货" name="-1"></el-tab-pane>
                        <el-tab-pane label="已发货" name="1"></el-tab-pane>
                        <el-tab-pane label="已签收" name="2"></el-tab-pane>
                        <el-tab-pane label="无赠品" name="-2"></el-tab-pane>
                        <el-tab-pane label="全部" name="0"></el-tab-pane>
                    </el-tabs>
                </div>
                <div v-loading="loading" element-loading-text="正在飞速加载中...">
                    <div style="line-height: 60px">
                        <!--左侧名称-->
                        <div style="float: left; font-size: 14px">数据列表</div>
                        <!--右侧按钮-->
                        <div style="float: right">
                            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                            <el-button
                                type="primary"
                                plain
                                v-if="activeName == '-1' && mypowerlimits.czzppsxx_fh"
                                @click="
                                    this.changeSelection.length > 0
                                        ? hasGoEvent(this.changeSelection, 1)
                                        : this.$message({ message: '至少选择一个待发货，再点击批量发货', type: 'error' })
                                "
                                >批量发货</el-button
                            >
                            <el-button
                                type="primary"
                                plain
                                v-if="activeName == '1' && mypowerlimits.czzppsxx_qs"
                                @click="
                                    this.changeSelection.length > 0
                                        ? hasSigninEvent(this.changeSelection, 1)
                                        : this.$message({ message: '至少选择一个已发货，再点击批量签收', type: 'error' })
                                "
                                >批量签收</el-button
                            >
                            <!--导出按钮-点击导出数据-->
                            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
                        </div>
                    </div>

                    <div>
                        <!--table表单-->
                        <el-table
                            :header-cell-style="{ background: '#F5F7FA' }"
                            ref="multipleTable"
                            :row-class-name="tableRowClassName"
                            :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                            style="width: 100%"
                            min-height="900"
                            border
                            v-loading="loading"
                            element-loading-text="正在飞速加载中..."
                            @selection-change="handleSelectionChange"
                        >
                            <!--多选框-用于用户选择性导出数据-->
                            <el-table-column type="selection" width="55" v-if="activeName == '-1' || activeName == '1'" />
                            <el-table-column
                                v-for="(mytabletitle, i) in mainthreetabletitle"
                                :key="i"
                                :fixed="mytabletitle.fixedstatu"
                                :prop="mytabletitle.prop"
                                :label="mytabletitle.label"
                                :min-width="mytabletitle.width"
                            >
                                <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                                <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                                <template #default="scope" v-if="mytabletitle.prop === 'userinfo' || mytabletitle.prop === 'receiveinfo'">
                                    <!-- 充值用户信息 -->
                                    <div v-if="mytabletitle.prop === 'userinfo'">
                                        <div>
                                            <span>用户ID：</span>
                                            <span
                                                :class="this.mypowerlimits.yhlb == true ? 'mytabletext' : ''"
                                                @click="this.mypowerlimits.yhlb == true ? routeraddrClick('/userlist', scope.row) : ''"
                                            >
                                                {{ scope.row.uid }}
                                            </span>
                                        </div>
                                        <div>
                                            <span>用户昵称：</span>
                                            <span>{{ scope.row.nickname }}</span>
                                        </div>
                                        <div>
                                            <span>手机号：</span>
                                            <span>{{ scope.row.mobile }}</span>
                                        </div>
                                    </div>
                                    <!-- 收货人信息 -->
                                    <div v-if="mytabletitle.prop === 'receiveinfo'">
                                        <div>
                                            <span>收货人：</span>
                                            <span>
                                                {{ scope.row.receive_person }}
                                            </span>
                                        </div>
                                        <div>
                                            <span>手机号：</span>
                                            <span>{{ scope.row.receive_mobile }}</span>
                                        </div>
                                        <div>
                                            <span>所在区域：</span>
                                            <span>{{ scope.row.address_area }}</span>
                                        </div>
                                        <div>
                                            <span>详细地址：</span>
                                            <span>{{ scope.row.address_detail }}</span>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <!--操作&按钮-->
                            <el-table-column fixed="right" label="操作" :width="mainthreehandle" prop="myoperation" id="operation">
                                <template #default="scope">
                                    <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                    <div>
                                        <el-button @click="remarksEvent(scope.row)" type="primary" size="mini" v-if="mypowerlimits.czzppsxx_bz">备注 </el-button>
                                        <el-button @click="hasGoEvent(scope.row)" type="primary" size="mini" v-if="scope.row.goods_status == -1 && mypowerlimits.czzppsxx_fh"
                                            >已发货
                                        </el-button>
                                        <el-button @click="hasSigninEvent(scope.row)" type="primary" size="mini" v-if="scope.row.goods_status == 1 && mypowerlimits.czzppsxx_qs"
                                            >已签收
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!--分页器-->
                        <div class="block" style="text-align: center; margin: 30px 0">
                            <el-pagination
                                background
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                                :page-sizes="pageSizes"
                                :page-size="PageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="totalCount"
                            >
                            </el-pagination>
                        </div>

                        <!--弹出框-->
                        <el-dialog v-model="dialogVisible" title="操作" :width="this.searchwidth" :before-close="handleCloseSenior">
                            <el-form ref="seniorform" :model="seniorform">
                                <el-form-item label="备注:">
                                    <el-input
                                        v-model="seniorform.textarea"
                                        :rows="5"
                                        type="textarea"
                                        placeholder="请输入备注信息"
                                        style="margin-top: 10px"
                                        maxlength="200"
                                        show-word-limit
                                    />
                                </el-form-item>
                                <el-form-item>
                                    <div style="float: right">
                                        <el-button @click="resetFormSenior()"> 取消 </el-button>
                                        <el-button type="primary" @click="onSubmitSenior()">确定 </el-button>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </el-dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import maintwo from '@/components/Maintwo'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'balanceRecharge',
    inject: ['reload'],
    data() {
        return {
            dialogVisible1: false,
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '65px', //标题宽度
                    inputwidth: '160px', //input宽度
                    inputinterval: '10px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索:',
                            placeholder: '充值订单编号',
                            name: 'trade_no',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索:',
                            placeholder: '用户ID/手机号',
                            name: 'keywords',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入搜索:',
                            placeholder: '收货人姓名/手机号',
                            name: 'keywords2',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '120px',
                    selectinterval: '10px', //select右侧距离
                    select: [
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '充值时间：',
                            placeholder: {
                                placeholderstart: '开始时间',
                                placeholderend: '结束时间'
                            },
                            name: 'addtime',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            selectmaxwidth: '300px', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        }
                    ]
                },
                selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
                selecttop: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '140px',
                    selectinterval: '10px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '平台来源：',
                            placeholder: '全部',
                            name: 'thirdparty',
                            value: '1', //显示值，初始状况，显示值与默认值需相同
                            valueisshow: true,
                            valuedefault: '1', //设置默认值
                            options: componentProperties.get('thirdpartyoption').options
                        }
                    ]
                }
            }, // 筛选-【筛选查询】-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 33, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 140, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'trade_no',
                    label: '充值订单编号',
                    width: '240'
                },
                {
                    fixedstatu: false,
                    prop: 'price',
                    label: '充值金额',
                    width: '130'
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '充值时间',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'userinfo',
                    label: '充值用户信息',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'receiveinfo',
                    label: '收货人信息',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'gift',
                    label: '赠品',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'goods_status_name',
                    label: '状态',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'remark',
                    label: '备注',
                    width: '150'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            mydisabled: false,

            activeName: '-1',

            dialogVisible: false,
            remarkId: '',
            seniorform: {},

            // 多选数据
            changeSelection: []
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 多选数据
        handleSelectionChange(data) {
            console.log(data)
            this.changeSelection = data
        },
        // 切换tabs
        handleClickTabs(tab) {
            console.log('切换tabs：', tab.props.name)
            this.handleCurrentChange(1)
        },
        // 已发货&批量
        hasGoEvent(data, flag = 0) {
            let getdata = []
            if (flag == 1) {
                // 批量发货
                getdata = data.map((n) => {
                    return n.id
                })
            } else {
                // 单个发货
                getdata = [data.id]
            }

            axios
                .post(
                    '/admin/activityGoods/rechargeSend',
                    {
                        ids: getdata
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        this.dialogVisible = false
                        this.$message({
                            message: response.data.message,
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.handleCurrentChange(this.pagesdatas.page)
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 已签收&批量
        hasSigninEvent(data, flag = 0) {
            let getdata = []
            if (flag == 1) {
                // 批量签收
                getdata = data.map((n) => {
                    return n.id
                })
            } else {
                // 单个签收
                getdata = [data.id]
            }
            axios
                .post(
                    '/admin/activityGoods/rechargeReceive',
                    {
                        ids: getdata
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        this.dialogVisible = false
                        this.$message({
                            message: response.data.message,
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.handleCurrentChange(this.pagesdatas.page)
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        // 弹窗-备注
        remarksEvent(data) {
            this.dialogVisible = true
            this.remarkId = data.id
            this.seniorform.textarea = data.remark
        },
        //点击关闭-弹窗-备注
        handleCloseSenior(done) {
            done()
        },
        //弹出框-提交表单-备注
        onSubmitSenior() {
            axios
                .post(
                    '/admin/activityGoods/rechargeRemark',
                    {
                        id: this.remarkId,
                        remark: this.seniorform.textarea
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        this.dialogVisible = false
                        this.$message({
                            message: response.data.message,
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.handleCurrentChange(this.pagesdatas.page)
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //弹出框-重置
        resetFormSenior() {
            this.seniorform.textarea = ''
            this.dialogVisible = false
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1
            this.pagesdatas.page = this.currentPage
            this.pagesdatas.page_size = this.PageSize

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/activityGoods/recharge',
                    {
                        trade_no: this.pagesdatas.trade_no,
                        keywords: this.pagesdatas.keywords,
                        keywords2: this.pagesdatas.keywords2,
                        addtime: this.pagesdatas.addtime,
                        thirdparty: this.pagesdatas.thirdparty,
                        goods_status: this.activeName,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            this.pagesdatas.page = this.currentPage
            this.pagesdatas.page_size = this.PageSize

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/activityGoods/recharge',
                    {
                        trade_no: this.pagesdatas.trade_no,
                        keywords: this.pagesdatas.keywords,
                        keywords2: this.pagesdatas.keywords2,
                        addtime: this.pagesdatas.addtime,
                        thirdparty: this.pagesdatas.thirdparty,
                        goods_status: this.activeName,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //点击进行路由页面跳转以及携带参数
        //传uid
        routeraddrClick(routeraddr, rowdata) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转-用户列表
                if (routeraddr == '/userlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { userid: rowdata.uid, thirdparty: rowdata.thirdparty }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/activityGoods/recharge',
                    {
                        trade_no: this.pagesdatas.trade_no,
                        keywords: this.pagesdatas.keywords,
                        keywords2: this.pagesdatas.keywords2,
                        addtime: this.pagesdatas.addtime,
                        thirdparty: this.pagesdatas.thirdparty,
                        goods_status: this.activeName,
                        export: 1
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            var getaddtime = null
            if (data.addtime.start == '') {
                getaddtime = ''
            } else {
                if (data.addtime.end == '') {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.start + 86400 - 1)
                } else {
                    getaddtime = data.addtime.start + ' ~ ' + (data.addtime.end + 86400 - 1)
                }
            }
            this.pagesdatas.addtime = getaddtime

            this.pagesdatas.page = this.currentPage
            this.pagesdatas.page_size = this.PageSize

            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/activityGoods/recharge',
                    {
                        trade_no: data.trade_no,
                        keywords: data.keywords,
                        keywords2: data.keywords2,
                        addtime: getaddtime,
                        thirdparty: data.thirdparty,
                        goods_status: this.activeName,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //转换申请时间
                if (newdata[i].addtime) {
                    if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                        newdata[i].addtime = '无充值时间'
                    } else {
                        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                    }
                } else {
                    newdata[i].addtime = '无充值时间'
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            this.pagesdatas.page = this.currentPage
            this.pagesdatas.page_size = this.PageSize
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/activityGoods/recharge',
                    {
                        thirdparty: 1,
                        goods_status: -1,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    components: {
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.checkbox-box {
    display: flex;
    flex-direction: row;
}

.checkbox-box div {
    margin-right: 10px;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
