<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">{{ (this.flagType == 1 ? '添加合同' : '编辑合同') }}</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="5">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="11">
                            <el-form ref="ruleFormHhr" :model="ruleFormHhr" :rules="rulesHhr" label-width="200px"
                                class="demo-ruleForm">
                                <el-form-item label="选择金融产品：" prop="lease_set_id">
                                    <el-select v-model="ruleFormHhr.lease_set_id" placeholder="请选择金融产品" filterable
                                        :disabled="(this.flagType == 2)" style="width:100%" @change="changeSelectEvent">
                                        <el-option v-for="(item, index) in this.valid_area_options" :key="index"
                                            :label="item.name" :value="item.id" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="租赁设备数量：" prop="buy_num">
                                    <el-input v-model.number="ruleFormHhr.buy_num" clearable maxlength="10"
                                        :disabled="(this.flagType == 2)"></el-input>
                                </el-form-item>
                                <el-form-item label="初始签约日期：" prop="signtime">
                                    <el-date-picker v-model="ruleFormHhr.signtime" type="date" placeholder="选择初始签约日期"
                                        :disabled="(this.flagType == 2)" @change="changeSignTime"/>
                                </el-form-item>
                                <el-form-item label="开始返还日期：" prop="starttime">
                                    <el-date-picker v-model="ruleFormHhr.starttime" type="date" placeholder="选择始返还日期"
                                        @change="changeStartTime" :disabled-date="disabledDate"
                                        :disabled="(this.flagType == 2)" />
                                </el-form-item>
                                <!--提现微信-->
                                <el-form-item label="提现微信：" prop="wechat_user_id">
                                    <div v-if="(this.flagType == 1)">
                                        <mainwechat ref="wechat" v-model="ruleFormHhr.wechat_user_id"
                                            :mycashoutisshow="mycashoutisshow" :mycashout="mycashout"
                                            v-on:getpayaccount="(data) => getzhanghuCodeEvent(data, 'wechat')"
                                            v-on:getchangepaytype="getChangePaytypeEvent"
                                            v-on:getisshow="changeGetisshowEvent"></mainwechat>
                                    </div>
                                    <div v-else>
                                        <el-avatar :src="this.avatar" :size="40" />
                                    </div>
                                </el-form-item>
                                <el-form-item label="上级邀请人：" prop="sponsor_uid">
                                    <el-input v-model.number="ruleFormHhr.sponsor_uid" clearable maxlength="20"
                                        :disabled="(this.flagType == 2)">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="上传合同：" prop="sign_url">
                                    <el-radio-group v-model="ruleFormHhr.type" @change="changeRadioEvent">
                                        <el-radio label="images">图片格式</el-radio>
                                        <el-radio label="document">文档格式</el-radio>
                                    </el-radio-group>
                                    <mainimgesup ref="myimges" v-model="ruleFormHhr.fileurl" :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow" :filelist="ruleFormHhr.fileurl_list"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data)"
                                        v-on:deluploadfile="(data) => deluploadfileEvent(data)">
                                    </mainimgesup>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleFormHhr')"
                                        style="width: 40%; margin-right: 10px;min-width: 120px;"
                                        :disabled="buttondisable">保存
                                    </el-button>
                                    <el-button @click="resetForm">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import mainone from "@/components/Mainone.vue";
import mainwechat from "@/components/Mainwechat.vue";
import mainimgesup from "@/components/Mainimgesup.vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export default {
    name: "vdercontractlistadd",
    data() {
        // 设备数量
        var checkBaohuDays = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("必填项不能为空"));
            }
            if (value.toString().indexOf(" ") != -1) {
                return callback(new Error("值中不能有空格"));
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf(".");
                if (result != -1) {
                    callback(new Error("请输入整数"));
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error("请输入整数"));
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error("请输入整数"));
                        } else {
                            if (value < 0) {
                                callback(new Error("值需大于等于0"));
                            } else {
                                callback();
                            }
                        }
                    }
                }
            }, 500);
        };

        return {
            mainonebread: [
                {
                    name: "金融产品",
                },
                {
                    name: "合伙人",
                },
                {
                    name: "合同列表",
                    path: "/dercontractlist",
                },
            ], // 当前页-【面包屑导航】

            // 打印机类型-下拉选项
            dvctype_option: [],
            // form表单
            ruleFormHhr: {
                lease_set_id: '',
                buy_num: '',
                signtime: '',
                starttime: '',
                wechat_user_id: '',
                sponsor_uid: '',
                type: 'images',
                fileurl: '',
                fileurl_list: [],
            },
            // form表单-必填规则
            rulesHhr: {
                lease_set_id: [
                    {
                        required: true,
                        message: "金融产品为必选项",
                        trigger: "blur",
                    },
                ],
                buy_num: [
                    {
                        required: true,
                        message: "租赁设备数量不能为空",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        validator: checkBaohuDays,
                        trigger: "change",
                    },
                ],
                signtime: [
                    {
                        required: true,
                        message: "初始签约日期为必选项",
                        trigger: "change",
                    },
                ],
                starttime: [
                    {
                        required: true,
                        message: "开始返还日期为必选项",
                        trigger: "change",
                    },
                ],
                wechat_user_id: [
                    {
                        required: true,
                        message: "提现微信为必选项",
                        trigger: "blur",
                    },
                ],
                sponsor_uid: [
                    {
                        required: false,
                        message: "上级邀请人为必填项",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "合同格式为必选项",
                        trigger: "blur",
                    },
                ],
                fileurl: [
                    {
                        required: false,
                        message: "合同为必填项",
                        trigger: "blur",
                    },
                ],
            },
            // 合同url地址
            push_images: [],

            // 金融产品下拉
            valid_area_options: [],

            mycashoutisshow: true, //form表单-提现微信/支付宝（单个组件）
            mycashout: {
                ide: "wechat", // 必填标识：微信（'wechat'）,支付宝（'alipay'）
                titlewidth: "180px",
                cashout: [
                    {
                        title: "提现微信：",
                        ismust: true, //是否显示必填红标
                        isshowedit: false, //控制-首次进入页面时-编辑用户信息模块
                        isshowadd: true, //控制-首次进入页面时-添加提现信息的模块
                        avatarinfo: {
                            id: 1,
                            avatar:
                                "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                            nickname: "用户李四",
                            mobile: "18738573256",
                        }, //显示-首次进入页面时-提现用户信息
                        tantitle: "请选择提现微信", //弹窗-title名称
                        tantable: {
                            mainnick: "微信昵称",
                            searchpder: "请输入 微信昵称 / 手机号 查询",
                        }, //弹窗-table中的需改变的中文字段
                        addtitle: "添加提现微信", //添加-按钮的中文
                        addcolor: "#04BE02", //添加-按钮的颜色
                        paytype: 0, //默认提现方式 - 0表示无，1微信，2支付宝
                    },
                ],
            }, //提现微信

            //上传
            myimgesupisshow: true,
            // 上传
            myimgesup: {
                uploadsize: 1, //上传方式 1前端oss直传 2前端传PHP接口
                listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
                showfilelist: true, //list展示列表是否显示，显示true，隐藏false
                ismust: true, //是否必填，true表示必填
                data: { id: '', thirdparty: '' }, // upload上传时的携带参数
                action: "/api/admin/upload/openFile", //upload上传API地址
                headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
                multiple: true, //上传多个文件
                title: "上传合同", //button-按钮文字
                title_type: "primary", //button-按钮显示样式,primary&text
                title_plain: true, //button-按钮是否为线性（plain）
                tips: "", //提示&建议文字
                limit: "1", //上传数量限制,0表示不限
                target_id: 201, //上传文件target_id ：201上传金融产品合同
                rules: {
                    // images: {
                    //     image_width: 0, //图片宽度
                    //     image_height: 0, //图片高度
                    //     size: 100000, //文件大小（单位kb）
                    //     type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
                    //     typechart: "jpg/jpeg/png", //文件格式提示规则
                    // },
                    // files: {
                    //     size: 100000, //文件大小（单位kb）
                    //     type: ["application/pdf"], //文件格式
                    //     typechart: "pdf", //文件格式提示规则
                    // },
                },
            },

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
            myuserinfo: localStorage.getItem("userinfo"), //获取存储的用户信息

            buttondisable: false, // 前端防抖

            cyclenum: 0, //周期数（天）

            // 添加&编辑合同-标识
            flagType: null,
            // 编辑合同时：微信头像
            avatar: '',
        };
    },
    methods: {
        // 选中金融产品
        changeSelectEvent(data) {
            let getlessendata = this.valid_area_options.filter(n => n.id == data)[0];
            // 1天 2周 3月 (天)
            let unitnum = getlessendata.day_unit == 1 ? 1 : (getlessendata.day_unit == 2 ? 7 : 30)
            // 获取的周期数
            this.cyclenum = getlessendata.lease_day * unitnum;
        },
        // 开始返还时间-禁用区间
        disabledDate(time) {
            if (this.ruleFormHhr.signtime && this.ruleFormHhr.lease_set_id) {
                return time.getTime() > (this.ruleFormHhr.signtime.valueOf() / 1000 + (this.cyclenum - 1) * 86400) * 1000 || time.getTime() < this.ruleFormHhr.signtime.valueOf();
            } else {
                return time.getTime() < (Date.now() / 1000 - 0 * 86400) * 1000 || time.getTime() > (Date.now() / 1000 + 0 * 86400) * 1000;
            }
        },
        // 选中初始签约时间
        changeSignTime(){
            // 选择初始签约日期-置空开始返还日期
            this.ruleFormHhr.starttime = '';
        },
        // 点击选中格式
        changeRadioEvent(data) {
            // 重置显示列表
            this.ruleFormHhr.fileurl = '';
            this.ruleFormHhr.fileurl_list = [];
            this.myimgesup.rules = {};
            this.push_images = [];

            if (data == 'images') {
                let rule = {
                    image_width: 0, //图片宽度
                    image_height: 0, //图片高度
                    size: 100000, //文件大小（单位kb）
                    type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
                    typechart: "jpg/jpeg/png", //文件格式提示规则
                };
                this.myimgesup.rules['images'] = rule;
                this.myimgesup.tips = "支持扩展名：*.jpg、*.jpeg、*.png; 最多上传9个文件，每个文件大小不超过100M"
                this.myimgesup.limit = '9';
            } else {
                let rule = {
                    size: 100000, //文件大小（单位kb）
                    type: ["application/pdf"], //文件格式
                    typechart: "pdf", //文件格式提示规则
                };
                this.myimgesup.rules['files'] = rule;
                this.myimgesup.tips = "支持扩展名：*.pdf; 最多上传1个文件，每个文件大小不超过100M"
                this.myimgesup.limit = '1';
            }
        },
        // 弹出框[处理-按钮]-获取upload子组件回传的数据-file文件url等数据
        getUploadFileEvent(data) {
            // console.log("当前获取的回传数据：", data);
            let onedata = {
                name: data.name,
                url: this.ruleFormHhr.type == "images" ? data.url : require("@/assets/imgs/upload/upload.png"),
                urltrue: data.url,
            };
            this.ruleFormHhr.fileurl_list.push(onedata);
            this.push_images.push(data.url);
        },

        //弹出框[处理-按钮]-删除上传的文件-事件（upload组件）
        deluploadfileEvent(data) {
            // console.log("当前删除事件-返回的数据：", data);
            if (data.length > 0) {
                let dataarray = [];
                data.forEach((element) => {
                    let onedata = {
                        name: element.name,
                        url: this.ruleFormHhr.type == "images" ? element.urltrue : require("@/assets/imgs/upload/upload.png"),
                        urltrue: element.urltrue,
                    };
                    dataarray.push(onedata);
                });
                this.ruleFormHhr.fileurl_list = dataarray;
                this.push_images = dataarray.map(n => { return n.urltrue });
            } else {
                this.ruleFormHhr.fileurl_list = [];
                this.push_images = [];
            }
        },

        //获取支付账号公共组件
        getzhanghuCodeEvent(data, payaccount) {
            if (payaccount == "wechat") {
                // console.log("获取返回的（微信）数据：", JSON.stringify(data));
                if (data) {
                    this.ruleFormHhr.wechat_user_id = data.id;
                } else {
                    this.ruleFormHhr.wechat_user_id = '';
                }
            }
        },
        //添加/删除后切换默认提现方式
        getChangePaytypeEvent(val) {
            if (this.mycashout.cashout[0].isshowadd == true) {
                this.mycashout.cashout[0].paytype = 0;
            } else {
                this.mycashout.cashout[0].paytype = val;
            }
        },
        //判断默认提现方式是否都为添加状态
        changeGetisshowEvent(val) {
            // console.log("changeGetisshowEvent:" + val);
            if (val.ide == "wechat") {
                this.mycashout.cashout[0].isshowedit = false;
                this.mycashout.cashout[0].isshowadd = true;
            }
        },

        //from表单-提交表单
        submitForm(formName) {
            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
            this.buttondisable = true;
            var getdatas = this.$refs[formName].model;
            getdatas.fileurl = this.push_images.join(',');
            // console.log("当前获取的formdata：" + JSON.stringify(formdata));
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let postdata = {};
                    let sign_url = '';
                    if (getdatas.fileurl != "") {
                        sign_url = {
                            type: getdatas.type,
                            fileurl: getdatas.fileurl,
                        }
                    }
                    if (this.flagType == 1) {
                        postdata = {
                            lease_set_id: getdatas.lease_set_id,
                            buy_num: getdatas.buy_num,
                            signtime: getdatas.signtime.valueOf() / 1000,
                            starttime: getdatas.starttime.valueOf() / 1000,
                            wechat_user_id: getdatas.wechat_user_id,
                            sponsor_uid: getdatas.sponsor_uid,
                            sign_url: sign_url,
                        }
                    } else {
                        postdata = {
                            id: getdatas.id,
                            lease_set_id: getdatas.lease_set_id,
                            buy_num: getdatas.buy_num,
                            signtime: getdatas.signtime / 1000,
                            starttime: getdatas.starttime / 1000,
                            wechat_user_id: getdatas.wechat_user_id,
                            sponsor_uid: getdatas.sponsor_uid,
                            sign_url: sign_url
                        }
                    }

                    axios
                        .post(
                            "/admin/lease/deedAdd",
                            postdata,
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: "success",
                                    message: "保存成功！",
                                    duration: 1000,
                                    onClose: () => {
                                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                        this.buttondisable = false;
                                        this.$router.go(-1);
                                    },
                                });
                            } else {
                                // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                this.buttondisable = false;
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                            this.buttondisable = false;
                        });
                } else {
                    console.log("error submit!!");
                    // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                    this.buttondisable = false;
                    return false;
                }
            });
        },
        //from表单-重置表单
        resetForm() {
            // 重置表单
            this.ruleFormHhr = {
                lease_set_id: '',
                buy_num: '',
                signtime: '',
                starttime: '',
                wechat_user_id: '',
                sponsor_uid: '',
                type: 'images',
                fileurl: '',
                fileurl_list: [],
            }

            this.$router.go(-1); // 返回上一页
        },
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        }
        else {
            // 加载-金融产品下拉
            axios
                .get("/admin/Lease/configOptions", {
                    headers: {
                        Authentication: localStorage.getItem("token"),
                    },
                })
                .then((response) => {
                    this.valid_area_options = response.data.result.list;
                })
                .catch(function (error) {
                    console.log(error);
                });

            // 判断是否为编辑合同 flagType 1添加合同 2编辑合同
            this.flagType = this.$route.query.agreement_id ? 2 : 1;
            this.mainonebread.push({ name: this.flagType == 1 ? '添加合同' : '编辑合同' })
            if (this.flagType == 2) {
                // 获取详情
                axios
                    .post(
                        "/admin/lease/deedInfo",
                        {
                            id: this.$route.query.agreement_id,
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem("token"),
                            },
                        }
                    )
                    .then((response) => {
                        let getdatas = response.data.result;
                        this.ruleFormHhr = {
                            id: this.$route.query.agreement_id,
                            lease_set_id: getdatas.set_id,
                            buy_num: getdatas.buy_num,
                            signtime: getdatas.signtime * 1000,
                            starttime: getdatas.starttime * 1000,
                            wechat_user_id: getdatas.uid,
                            sponsor_uid: getdatas.sponsor_uid,
                            type: getdatas.sign_url.type ? getdatas.sign_url.type : 'images',
                            fileurl: '',
                            fileurl_list: [],
                        }
                        this.avatar = getdatas.avatar;
                        this.push_images = [];
                        this.changeRadioEvent(this.ruleFormHhr.type);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                this.changeRadioEvent(this.ruleFormHhr.type);
            }


        }
    },
    components: {
        mainone,
        mainwechat,
        mainimgesup,
    },
};
</script>
  
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    padding: 5px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}

.flag-class {
    line-height: 20px;
    margin-top: 2px;
}

.flag-class-div {
    font-size: 12px;
    color: #888888;
}

.flag-class-span {
    color: #e6b33c;
    font-size: 14px;
    font-weight: 600;
}

.lease-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.lease-box-day {
    width: 100%;
    margin-right: 20px;
}
</style>
  