<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
      :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'uid' ||
                mytabletitle.prop === 'day' ||
                mytabletitle.prop === 'endtime' ||
                mytabletitle.prop === 'addtime' ||
                mytabletitle.prop === 'print_num' ||
                mytabletitle.prop === 'print_num_used' ||
                mytabletitle.prop === 'thirdparty'
              ">
                <!--用户ID-样式等渲染-->
                <div v-if="mytabletitle.prop === 'uid'">
                  <div :class="this.mypowerlimits.yhlb == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.yhlb == true ? routeraddrClick('/userlist', scope.row) : ''">
                    <span style="color: #465eff">{{ scope.row.uid }}</span>
                  </div>
                </div>
                <!--平台来源-样式等渲染-->
                <div v-if="mytabletitle.prop === 'thirdparty'">
                  <span>{{ scope.row.thirdparty == 1 ? '微信自助版' : (scope.row.thirdparty == 2 ? '支付宝自助版' : '') }} </span>
                </div>
                <!--时间-样式等渲染-->
                <div v-if="mytabletitle.prop === 'day'">
                  <span>{{ scope.row.day }} 天</span>
                </div>
                <!--VIP到期时间-样式等渲染-->
                <div v-if="mytabletitle.prop === 'endtime'">
                  <span>{{ this.$utils.formatDate(scope.row.endtime) }}</span>
                </div>
                <!--购买时间-样式等渲染-->
                <div v-if="mytabletitle.prop === 'addtime'">
                  <span>{{ this.$utils.formatDate(scope.row.addtime) }}</span>
                </div>
                <!--会员使用数量-样式等渲染-->
                <div v-if="mytabletitle.prop === 'print_num'">
                  <div style="cursor: pointer">
                    <el-popover placement="right" title="会员使用量" trigger="click">
                      <template #reference>
                        <span style="color: var(--el-color-primary)" :ref="'btnShowNum' + scope.$index">查看</span>
                      </template>
                      <template #default>
                        <div style="color:var(--el-color-info)">
                          <div>A4: {{ scope.row.num1 }} 页</div>
                          <div>A3: {{ scope.row.num2 }} 页</div>
                          <div>A6: {{ scope.row.num3 }} 页</div>
                        </div>
                      </template>
                    </el-popover>
                  </div>
                </div>
                <!--用户ID-样式等渲染-->
                <div v-if="mytabletitle.prop === 'print_num_used'">
                  <div style="cursor: pointer">
                    <el-popover placement="right" title="已打印数量:" :width="150" trigger="click"
                      :content="scope.row.getused">
                      <template #reference>
                        <span style="color: var(--el-color-primary)" :ref="'btnShowNum' + scope.$index">查看</span>
                      </template>
                      <template #default>
                        <div style="color:var(--el-color-info)">
                          <div>A4: {{ scope.row.print_num1 }} 页</div>
                          <div>A3: {{ scope.row.print_num2 }} 页</div>
                          <div>A6: {{ scope.row.print_num3 }} 页</div>
                        </div>
                      </template>
                    </el-popover>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vmemberdvcbuy",
  data() {
    return {
      mainonebread: [
        {
          name: "用户",
        },
        {
          name: "会员管理",
        },
        {
          name: "设备会员购买记录",
          path: "/memberdvcbuy",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "设备编号：",
              placeholder: "请输入设备编号",
              name: "client",
              content: "",
            },
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "用户ID/手机号",
              name: "keywords",
              content: "",
              inputincon: "el-icon-search",
            },
          ],
        },
        // myinputonefullisshow: true, //筛选查询-自动建议下拉框
        // myinputonefull: {
        //   divinterval: "0", //框右侧距离
        //   titlewidth: "74px", //标题宽度
        //   inputwidth: "220px", //input宽度
        //   inputinterval: "20px", //input右侧距离
        //   input: [
        //     {
        //       ismust: false, //是否必填，true表示必填
        //       title: "设备编号：",
        //       placeholder: "请输入设备编号", //此name为键值
        //       name: "client",
        //       content: "",
        //       id: "", //此id为给后台的id值
        //       url: "/admin/Equipment/searchClient",
        //       posttype:'get', 
        //       inputval: { client: "" }, //这里记录需要传的参数
        //     },
        //   ],
        // },
        selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
        selecttop: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "平台来源：",
              placeholder: "全部",
              name: "thirdparty",
              value: "1", //显示值，初始状况，显示值与默认值需相同
              valueisshow: true,
              valuedefault: "1", //设置默认值
              options: componentProperties.get('thirdpartyoption').options,
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "uid",
          label: "用户ID",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "thirdparty",
          label: "平台来源",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "mobile",
          label: "手机号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "client",
          label: "设备编号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "day",
          label: "时间",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "price",
          label: "实付金额",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "endtime",
          label: "VIP到期时间",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "print_num",
          label: "会员使用数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "print_num_used",
          label: "已打印数量",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "addtime",
          label: "购买时间",
          width: "200",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "设备编号：",
            placeholder: "请输入设备编号",
            name: "client",
            content: "",
          },
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "用户ID/手机号",
            name: "keywords",
            content: "",
            inputincon: "el-icon-search",
          },
        ],
      },
      // myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
      // myinputonefullgao: {
      //   divinterval: "3%", //框右侧距离
      //   inputwidth: "47%", //input宽度
      //   inputmaxwidth: "100%",
      //   input: [
      //     {
      //       ismust: false, //是否必填，true表示必填
      //       title: "设备编号：",
      //       placeholder: "请输入设备编号", //此name为键值
      //       name: "client",
      //       content: "",
      //       id: "", //此id为给后台的id值
      //       url: "/admin/Equipment/searchClient",
      //       posttype: 'get',
      //       inputval: { client: "" }, //这里记录需要传的参数
      //     },
      //   ],
      // },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "到期时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "endtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myselecttopisshow: true, //高级筛选-select单框-放input框之前
      myselecttop: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "平台来源：",
            placeholder: "全部",
            name: "thirdparty",
            value: "1", //显示值，初始状况，显示值与默认值需相同
            valueisshow: true,
            valuedefault: "1", //设置默认值
            options: componentProperties.get('thirdpartyoption').options,
          },
        ],
      },

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      console.log(row); //获取当前行数据
      console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用

      //获取所点击按钮的id值-并根据不同的id值进行跳转&传值等
      if (event.path[1].id) {
        //由操作-合作-公司列表-向-公司关联设备页面传值
        if (event.path[1].id === "companydvcid") {
          this.$router
            .push({
              path: "/companydvclist",
              query: { id: row.id, name: row.name },
            })
            .catch((error) => error);
        }
        //由操作-合作-设备合伙人列表-向-合伙人关联设备页面传值
        if (event.path[1].id === "partnerdvcid") {
          this.$router
            .push({ path: "/partnerdvclist", query: { id: row.id } })
            .catch((error) => error);
        }
      }

      console.log("当前的column.property 是：" + column.property);
      if (column.property === "secondagent") {
        //跳转二级代理
        this.$router.push("/agentcensustwo").catch((error) => error);
      }
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/equipment", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: this.pagesdatas.keywords,
            client: this.pagesdatas.client,
            thirdparty: this.pagesdatas.thirdparty,
            endtime: this.pagesdatas.endtime,
            page: 1,
            page_size: val,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/equipment", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: this.pagesdatas.keywords,
            client: this.pagesdatas.client,
            thirdparty: this.pagesdatas.thirdparty,
            endtime: this.pagesdatas.endtime,
            page: val,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 携带参数跳转
        if (routeraddr == "/userlist") {
          this.$router
            .push({ path: routeraddr, query: { userid: rowdata.uid, thirdparty: rowdata.thirdparty } })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/equipment", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: this.pagesdatas.keywords,
            client: this.pagesdatas.client,
            thirdparty: this.pagesdatas.thirdparty,
            endtime: this.pagesdatas.endtime,
            export: 1,
          },
        })
        .then((response) => {
          console.log(response.data.message);
     
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      // console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-区间段状态
      var getendtime = null;
      if (data.endtime.start == "") {
        getendtime = "";
      } else {
        if (data.endtime.end == "") {
          getendtime = data.endtime.start + " ~ " + (data.endtime.start + 86400 - 1);
        } else {
          getendtime = data.endtime.start + " ~ " + (data.endtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.endtime = getendtime;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/equipment", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: data.keywords,
            client: data.client,
            thirdparty: data.thirdparty,
            endtime: getendtime,
            page: 1,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/equipment", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            keywords: data.keywords,
            client: data.client,
            thirdparty: data.thirdparty,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;

      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      // 默认首次进入平台来源选择1.微信自助版
      this.pagesdatas.thirdparty = "1"
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .get("/admin/Member/equipment", {
          headers: {
            Authentication: localStorage.getItem("token"),
          },
          params: {
            thirdparty: this.pagesdatas.thirdparty,
            page_size: this.PageSize,
          },
        })
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>
<style scoped>
.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>