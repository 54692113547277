<template>
    
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <!--内容-->
                <div class="box_content">
                    <el-tabs
                        v-model="activeName"
                        @tab-click="
                            (tab, event) => {
                                handleClick(tab, event)
                            }
                        "
                    >
                        <!-- 赚钱页配置 -->
                        <el-tab-pane label="赚钱页配置" name="one">
                            <MakeMoneySet :ModelInfo="this.ruleForm" />
                        </el-tab-pane>
                        <!-- 更新程序包-->
                        <el-tab-pane label="更新程序包" name="two">
                            <el-row>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                                    <div style="width: 100%"></div>
                                </el-col>
                                <!-- 主内容 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                                    <div style="width: 100%; margin-top: 20px">
                                        <el-form ref="ruleFormCxb" :model="ruleFormCxb" :rules="rulesCxb" label-width="180px" class="demo-ruleForm">
                                            <el-form-item label="版本号：" prop="version">
                                                <el-input v-model="ruleFormCxb.version" placeholder="请输入轮播链接" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="更新包地址：" prop="link">
                                                <el-input v-model="ruleFormCxb.link" placeholder="请输入更新包地址" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item v-if="this.mypowerlimits.jcsz_bc">
                                                <el-button
                                                    type="primary"
                                                    @click="submitForm('ruleFormCxb', 'update_package', ruleFormCxb)"
                                                    style="width: 40%; margin-right: 10px"
                                                    :disabled="buttondisable"
                                                    >保存
                                                </el-button>
                                                <el-button @click="resetForm('ruleFormCxb')">重置</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </el-col>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="8">
                                    <div style="width: 100%"></div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <!-- 余额相关配置 -->
                        <!-- <el-tab-pane label="余额相关配置" name="three">
              <el-row>
                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                  <div style="width: 100%"></div>
                </el-col>
                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                  <div style="width: 100%; margin-top: 20px">
                    <el-form ref="ruleFormYuE" :model="ruleFormYuE" :rules="rulesYuE" label-width="100px"
                      class="demo-ruleForm">
                      <el-form-item label="充值金额：">
                        <div v-for="(getmoney, i) in ruleFormYuE.recharge" :key="i" style="margin-bottom: 20px">
                          <el-row :gutter="10" :v-if="!getmoney.deleteRecharge">
                            <el-col :span="8" :xs="24">
                              <el-form-item :prop="'recharge.' + i + '.price'" :rules="rulesYuE.price">
                                <el-input v-model="getmoney.price" placeholder="请输入充值金额(元)" clearable></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8" :xs="24">
                              <el-form-item :prop="'recharge.' + i + '.descr'" :rules="rulesYuE.descr">
                                <el-input v-model="getmoney.descr" placeholder="请输入功能介绍" clearable></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8" :xs="24">
                              <el-button type="text" v-if="getmoney.addisshow" @click="
                                addRechargeEvent(
                                  ruleFormYuE,
                                  'cash_recharge_set',
                                  7
                                )
                              ">增加</el-button>
                              <el-button type="text" v-if="!getmoney.addisshow"
                                @click="delRechargeEvent(i, ruleFormYuE)">删除</el-button>
                            </el-col>
                          </el-row>
                        </div>
                      </el-form-item>
                      <el-form-item label="收货地址：" prop="address" :rules="rulesYuE.address">
                        <el-radio-group v-model="ruleFormYuE.address">
                          <el-radio label="-1">不展示</el-radio>
                          <el-radio label="1">展示</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item label="提示文案：" prop="tips" :rules="rulesYuE.tips">
                        <el-input v-model="ruleFormYuE.tips" placeholder="请输入提示文案" type="textarea" clearable maxlength="200" show-word-limit
                            :autosize="{ minRows: 4 }" style="width:67%"></el-input>
                      </el-form-item>
                      <el-form-item v-if="this.mypowerlimits.jcsz_bc">
                        <el-button type="primary" @click="
                          submitFormSpe(
                            ruleFormYuE,
                            'cash_recharge_set'
                          )
                        " style="width: 40%; margin-right: 10px" :disabled="buttondisable">保存
                        </el-button>
                        <el-button @click="resetFormSpe(ruleFormYuE, 'YuE')">重置</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-col>
                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="8">
                  <div style="width: 100%"></div>
                </el-col>
              </el-row>
            </el-tab-pane> -->
                        <!-- 合伙人配置 -->
                        <el-tab-pane label="合伙人配置" name="four">
                            <el-row>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                                    <div style="width: 100%"></div>
                                </el-col>
                                <!-- 主内容 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                                    <div style="width: 100%; margin-top: 20px">
                                        <el-form ref="ruleFormHhr" :model="ruleFormHhr" :rules="rulesHhr" label-width="260px" class="demo-ruleForm">
                                            <el-form-item label="投资设备价格：" prop="invest_price">
                                                <el-input v-model="ruleFormHhr.invest_price" clearable min="0" placeholder="请输入投资设备价格（元）"> </el-input>
                                            </el-form-item>
                                            <el-form-item label="投资邀请返佣比例一级（%）：" prop="invite_one_scale">
                                                <el-input v-model="ruleFormHhr.invite_one_scale" clearable step="1" min="0" max="100"> </el-input>
                                            </el-form-item>
                                            <el-form-item label=" 投资邀请返佣比例二级（%）：" prop="invite_two_scale">
                                                <el-input v-model="ruleFormHhr.invite_two_scale" clearable step="1" min="0" max="100"> </el-input>
                                            </el-form-item>
                                            <el-form-item label="分红比例：" prop="share_scale">
                                                <el-input v-model="ruleFormHhr.share_scale" clearable step="0.1" min="0" max="1" maxlength="5"> </el-input>
                                            </el-form-item>
                                            <el-form-item label="收回本金后分红比例：" prop="enough_share_scale">
                                                <el-input v-model="ruleFormHhr.enough_share_scale" clearable step="0.1" min="0" max="1" maxlength="5"></el-input>
                                            </el-form-item>
                                            <el-form-item label=" 购买设备价格：" prop="buy_price">
                                                <el-input v-model="ruleFormHhr.buy_price" placeholder="请输入购买设备价格（元）" clearable min="0"> </el-input>
                                            </el-form-item>
                                            <el-form-item label="购买邀请返佣比例一级（%）：" prop="buy_one_scale">
                                                <el-input v-model="ruleFormHhr.buy_one_scale" clearable step="1" min="0" max="100"></el-input>
                                            </el-form-item>
                                            <el-form-item label="购买邀请返佣比例二级（%）：" prop="buy_two_scale">
                                                <el-input v-model="ruleFormHhr.buy_two_scale" clearable step="1" min="0" max="100"></el-input>
                                            </el-form-item>
                                            <el-form-item label="邀请关系保护期（天）：" prop="invite_relation_time">
                                                <el-input v-model="ruleFormHhr.invite_relation_time" clearable step="1" min="0"></el-input>
                                            </el-form-item>
                                            <el-form-item v-if="this.mypowerlimits.jcsz_bc">
                                                <el-button
                                                    type="primary"
                                                    @click="submitForm('ruleFormHhr', 'partner_set', ruleFormHhr)"
                                                    style="width: 40%; margin-right: 10px"
                                                    :disabled="buttondisable"
                                                    >保存
                                                </el-button>
                                                <el-button @click="resetForm('ruleFormHhr')">重置</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </el-col>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="8">
                                    <div style="width: 100%"></div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <!-- 广告价格配置 -->
                        <el-tab-pane label="广告价格配置" name="five">
                            <el-row>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                                    <div style="width: 100%"></div>
                                </el-col>
                                <!-- 主内容 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="10">
                                    <div style="width: 100%; margin-top: 20px">
                                        <el-form ref="ruleFormAddrP" :model="ruleFormAddrP" :rules="rulesAddrP" label-width="140px" class="demo-ruleForm">
                                            <!-- 时长&价格 -->
                                            <el-form-item
                                                :label="getprice.title + '价格'"
                                                :required="true"
                                                v-for="(getprice, index) in ruleFormAddrP.client_advert_price"
                                                :key="index"
                                            >
                                                <el-row :gutter="10">
                                                    <el-col :span="10" :xs="24">
                                                        <el-form-item :prop="'client_advert_price.' + index + '.price'" :rules="rulesAddrP.price">
                                                            <el-input v-model="getprice.price" placeholder="请输入金额" clearable step="0.1" min="0">
                                                                <template #append>元</template>
                                                            </el-input>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="3" :xs="24">
                                                        <span style="margin: 0 10px">台/天</span>
                                                    </el-col>
                                                    <el-col :span="11" :xs="24">
                                                        <el-form-item :prop="'client_advert_price.' + index + '.num'" :rules="rulesAddrP.num">
                                                            <el-input v-model.number="getprice.num" placeholder="请输入展现次数" clearable step="1" min="0">
                                                                <template #append>展现次数</template>
                                                            </el-input>
                                                        </el-form-item>
                                                    </el-col>
                                                </el-row>
                                            </el-form-item>
                                            <!-- 投放天数折扣： -->
                                            <el-form-item label="投放天数折扣：" :required="true">
                                                <div v-for="(getdays, j) in ruleFormAddrP.client_advert_price_day" :key="j" style="margin-bottom: 20px">
                                                    <el-row :gutter="10" :v-if="!getdays.deleteRecharge">
                                                        <el-col :span="4" :xs="24">
                                                            <span style="margin: 0 10px" v-if="j == 0">{{ getdays.put_small }}天至</span>
                                                            <span style="margin: 0 10px" v-if="j > 0"
                                                                >{{ (getdays.put_small = ruleFormAddrP.client_advert_price_day[j - 1].put_big + 1) }}天至</span
                                                            >
                                                        </el-col>
                                                        <el-col :span="8" :xs="24">
                                                            <el-form-item :prop="'client_advert_price_day.' + j + '.put_big'" :rules="rulesAddrP.put_day_big">
                                                                <el-input
                                                                    step="1"
                                                                    min="1"
                                                                    max="60"
                                                                    maxlength="2"
                                                                    clearable
                                                                    v-model.number="getdays.put_big"
                                                                    placeholder="请输入天数"
                                                                    ><template #append>天</template>
                                                                </el-input>
                                                            </el-form-item>
                                                        </el-col>
                                                        <el-col :span="8" :xs="24">
                                                            <el-form-item :prop="'client_advert_price_day.' + j + '.put_rebate'" :rules="rulesAddrP.put_day_rebate">
                                                                <el-select v-model="getdays.put_rebate" placeholder="请选择折扣" size="large">
                                                                    <el-option
                                                                        v-for="item in this.ruleFormAddrP.rebatedata"
                                                                        :key="item.value"
                                                                        :label="item.label"
                                                                        :value="item.value"
                                                                    />
                                                                </el-select>
                                                            </el-form-item>
                                                        </el-col>
                                                        <el-col :span="4" :xs="24">
                                                            <el-button type="text" v-if="getdays.btnisshow == 2" @click="delRechargedaysEvent(j)">删除 </el-button>
                                                            <el-button type="text" v-if="getdays.btnisshow == 1" @click="addRechargedaysEvent(j, getdays)">增加</el-button>
                                                        </el-col>
                                                    </el-row>
                                                </div>
                                            </el-form-item>
                                            <!-- 投放台数折扣： -->
                                            <el-form-item label="投放台数折扣：" :required="true">
                                                <div v-for="(getnumber, kdex) in ruleFormAddrP.client_advert_price_num" :key="kdex" style="margin-bottom: 20px">
                                                    <el-row :gutter="10" :v-if="!getnumber.deleteRecharge">
                                                        <el-col :span="4" :xs="24">
                                                            <span style="margin: 0 10px" v-if="kdex == 0">{{ getnumber.put_small }}台至</span>
                                                            <span style="margin: 0 10px" v-if="kdex > 0"
                                                                >{{ (getnumber.put_small = ruleFormAddrP.client_advert_price_num[kdex - 1].put_big + 1) }}台至</span
                                                            >
                                                        </el-col>
                                                        <el-col :span="8" :xs="24">
                                                            <el-form-item :prop="'client_advert_price_num.' + kdex + '.put_big'" :rules="rulesAddrP.put_big">
                                                                <el-input
                                                                    step="1"
                                                                    min="1"
                                                                    max="20"
                                                                    maxlength="2"
                                                                    clearable
                                                                    v-model.number="getnumber.put_big"
                                                                    placeholder="请输入台数"
                                                                    ><template #append>台</template>
                                                                </el-input>
                                                            </el-form-item>
                                                        </el-col>
                                                        <el-col :span="8" :xs="24">
                                                            <el-form-item :prop="'client_advert_price_num.' + kdex + '.put_rebate'" :rules="rulesAddrP.put_rebate">
                                                                <el-select v-model="getnumber.put_rebate" placeholder="请选择折扣" size="large">
                                                                    <el-option
                                                                        v-for="item in this.ruleFormAddrP.rebatedata"
                                                                        :key="item.value"
                                                                        :label="item.label"
                                                                        :value="item.value"
                                                                    />
                                                                </el-select>
                                                            </el-form-item>
                                                        </el-col>
                                                        <el-col :span="4" :xs="24">
                                                            <el-button type="text" v-if="getnumber.btnisshow == 2" @click="delRechargenumberEvent(kdex)">删除</el-button>
                                                            <el-button type="text" v-if="getnumber.btnisshow == 1" @click="addRechargenumberEvent(kdex, getnumber)">增加</el-button>
                                                        </el-col>
                                                    </el-row>
                                                </div>
                                            </el-form-item>
                                            <el-form-item v-if="this.mypowerlimits.jcsz_bc">
                                                <el-button type="primary" @click="submitFormAdt('ruleFormAddrP')" style="width: 40%; margin-right: 10px" :disabled="buttondisable"
                                                    >保存
                                                </el-button>
                                                <el-button @click="resetFormAdt()">重置</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </el-col>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="8">
                                    <div style="width: 100%"></div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <!-- 打印相关配置 -->
                        <el-tab-pane label="打印相关配置" name="six">
                            <el-row>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                                    <div style="width: 100%"></div>
                                </el-col>
                                <!-- 主内容 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                                    <div style="width: 100%; margin-top: 20px">
                                        <el-form ref="ruleFormPrint" :model="ruleFormPrint" :rules="rulesPrint" label-width="260px" class="demo-ruleForm">
                                            <el-form-item label="每天退款次数限制：" prop="refund_day_num">
                                                <el-input v-model="ruleFormPrint.refund_day_num" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="每月退款次数限制：" prop="refund_month_num">
                                                <el-input v-model="ruleFormPrint.refund_month_num" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="申请退款有效天数：" prop="refund_effect_day">
                                                <el-input v-model="ruleFormPrint.refund_effect_day" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="余额支付每月最大限额：" prop="balance_month_max">
                                                <el-input v-model="ruleFormPrint.balance_month_max" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="购机拉新返现：" prop="cash_back">
                                                <el-input v-model="ruleFormPrint.cash_back" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="流量变现返现金额/用户：" prop="warn">
                                                <el-input v-model="ruleFormPrint.warn" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="电子版证件照价格：" prop="electron_price">
                                                <el-input v-model="ruleFormPrint.electron_price" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item v-if="this.mypowerlimits.jcsz_bc">
                                                <el-button
                                                    type="primary"
                                                    @click="submitForm('ruleFormPrint', 'print_set', ruleFormPrint)"
                                                    style="width: 40%; margin-right: 10px"
                                                    :disabled="buttondisable"
                                                    >保存
                                                </el-button>
                                                <el-button @click="resetForm('ruleFormPrint')">重置</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </el-col>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="8">
                                    <div style="width: 100%"></div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <!-- 广告主配置 -->
                        <el-tab-pane label="广告主配置" name="siven">
                            <el-row>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                                    <div style="width: 100%"></div>
                                </el-col>
                                <!-- 主内容 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                                    <div style="width: 100%; margin-top: 20px">
                                        <el-form ref="ruleFormAdt" :model="ruleFormAdt" :rules="rulesAdt" label-width="260px" class="demo-ruleForm">
                                            <el-form-item label="优惠券打印单价：" prop="coupon_price">
                                                <el-input v-model="ruleFormAdt.coupon_price" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="优惠券核销单价：" prop="coupon_check">
                                                <el-input v-model="ruleFormAdt.coupon_check" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="宣传页打印单价：" prop="extend_price">
                                                <el-input v-model="ruleFormAdt.extend_price" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="宣传页核销单价：" prop="extend_check">
                                                <el-input v-model="ruleFormAdt.extend_check" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item label="发布方案限制金额：" prop="run_money">
                                                <el-input v-model="ruleFormAdt.run_money" clearable></el-input>
                                            </el-form-item>
                                            <el-form-item v-if="this.mypowerlimits.jcsz_bc">
                                                <el-button
                                                    type="primary"
                                                    @click="submitForm('ruleFormAdt', 'advertiser_price', ruleFormAdt)"
                                                    style="width: 40%; margin-right: 10px"
                                                    :disabled="buttondisable"
                                                    >保存
                                                </el-button>
                                                <el-button @click="resetForm('ruleFormAdt')">重置</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </el-col>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="8">
                                    <div style="width: 100%"></div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <!-- 保证金配置 -->
                        <el-tab-pane label="保证金配置" name="eight">
                            <el-row>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                                    <div style="width: 100%"></div>
                                </el-col>
                                <!-- 主内容 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                                    <div style="width: 100%; margin-top: 20px">
                                        <el-form ref="ruleFormBond" :model="ruleFormBond" :rules="rulesBond" label-width="50px" class="demo-ruleForm">
                                            <el-form-item>
                                                <div style="margin-bottom: 20px">
                                                    <el-row :gutter="10">
                                                        <el-col :span="8" :xs="24">机器型号</el-col>
                                                        <el-col :span="8" :xs="24">保证金(元)/台</el-col>
                                                        <el-col :span="8" :xs="24">操作</el-col>
                                                        <el-col :span="24" :xs="24">
                                                            <div v-for="(getmoney, l) in ruleFormBond.recharge" :key="l" style="margin-bottom: 20px">
                                                                <div :v-if="!getmoney.deleteRecharge">
                                                                    <el-row :gutter="10">
                                                                        <el-col :span="8" :xs="24">
                                                                            <el-form-item :prop="'recharge.' + l + '.title'" :rules="rulesBond.title">
                                                                                <el-input v-model="getmoney.title" placeholder="请输入机器型号" clearable></el-input>
                                                                            </el-form-item>
                                                                        </el-col>
                                                                        <el-col :span="8" :xs="24">
                                                                            <el-form-item :prop="'recharge.' + l + '.value'" :rules="rulesBond.value">
                                                                                <el-input v-model="getmoney.value" placeholder="请输入保证金" clearable></el-input>
                                                                            </el-form-item>
                                                                        </el-col>
                                                                        <el-col :span="8" :xs="24">
                                                                            <el-button
                                                                                type="text"
                                                                                v-if="getmoney.addisshow"
                                                                                @click="addRechargeEvent(ruleFormBond, 'bond_device_set')"
                                                                                >增加</el-button
                                                                            >
                                                                            <el-button type="text" v-if="!getmoney.addisshow" @click="delRechargeEvent(l, ruleFormBond)"
                                                                                >删除</el-button
                                                                            >
                                                                        </el-col>
                                                                    </el-row>
                                                                </div>
                                                            </div>
                                                        </el-col>
                                                    </el-row>
                                                </div>
                                            </el-form-item>
                                            <el-form-item v-if="this.mypowerlimits.jcsz_bc">
                                                <el-button
                                                    type="primary"
                                                    @click="submitFormSpe(ruleFormBond.recharge, 'bond_device_set')"
                                                    style="width: 40%; margin-right: 10px"
                                                    :disabled="buttondisable"
                                                    >保存
                                                </el-button>
                                                <el-button @click="resetFormSpe(ruleFormBond.recharge, 'Bond')">重置</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </el-col>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="8">
                                    <div style="width: 100%"></div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <!-- 小程序审核线路配置 -->
                        <el-tab-pane label="小程序审核线路配置" name="nine">
                            <el-row>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                                    <div style="width: 100%"></div>
                                </el-col>
                                <!-- 主内容 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                                    <div style="width: 100%; margin-top: 20px">
                                        <el-form ref="ruleFormPhotoExamine" :model="ruleFormPhotoExamine" :rules="rulePhotoExamine" label-width="180px" class="demo-ruleForm">
                                            <!--文本审核线路-->
                                            <el-form-item label="文本审核线路：" prop="text">
                                                <el-radio-group v-model="this.ruleFormPhotoExamine.text">
                                                    <el-radio label="1">微信</el-radio>
                                                    <el-radio label="2">支付宝</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <!--图片审核线路-->
                                            <el-form-item label="图片审核线路：" prop="img">
                                                <el-radio-group v-model="this.ruleFormPhotoExamine.img">
                                                    <el-radio label="1">阿里云</el-radio>
                                                    <el-radio label="2">支付宝</el-radio>
                                                    <el-radio label="3">七牛云</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item v-if="this.mypowerlimits.jcsz_bc">
                                                <el-button
                                                    type="primary"
                                                    @click="submitFormPhotoExamine('ruleFormPhotoExamine')"
                                                    style="width: 40%; margin-right: 10px"
                                                    :disabled="buttondisable"
                                                    >保存
                                                </el-button>
                                                <el-button @click="resetFormPhotoExamine('ruleFormPhotoExamine')">重置</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </el-col>
                                <!-- 占位符 -->
                                <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="8">
                                    <div style="width: 100%"></div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import MakeMoneySet from './MakeMoneySet.vue'
import { defineComponent } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default defineComponent({
    name: 'orderlist',
    inject: ['reload'], //刷新引用
    data() {
        // 数字input框-校验（可小数）【余额相关配置】
        var checkInputEvent = (rule, value, callback) => {
            if (!value && value != 0) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }

            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error('请输入数字'))
                } else {
                    let result = value.toString().indexOf('.')
                    if (result != -1) {
                        let getdata = value.toString().split('.')
                        if (getdata[getdata.length - 1].length > 2) {
                            callback(new Error('请保留小数点后两位'))
                        }
                    }

                    if (value < 0) {
                        callback(new Error('值需大于0'))
                    } else {
                        callback()
                    }
                }
            }, 500)
        }
        // 数字input框-校验-(仅整数)【赚钱页配置||】
        var checkInputEventInt = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf('.')
                if (result != -1) {
                    callback(new Error('请输入整数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入整数'))
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error('请输入整数'))
                        } else {
                            if (value < 0) {
                                callback(new Error('值需大于0'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }
        // 文本input框-校验（文本-值中不能有空格）【更新程序包||】
        var checkInputEventWord = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                callback()
            }, 500)
        }
        // 文本input框-校验（文本-首位不能为空格）【余额相关配置||】
        var checkInputEventWordNull = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (
                value
                    .toString()
                    .substr(0, 1)
                    .indexOf(' ') != -1
            ) {
                return callback(new Error('首位不能为空格'))
            }
            setTimeout(() => {
                callback()
            }, 500)
        }

        // 特定校验
        // 更新程序包文本-版本号-校验
        var checkInputEventNumber = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            } else {
                setTimeout(() => {
                    // 判断字符串最后一位是否为数字
                    let result = value.toString()
                    let valuedata = result.substr(result.length - 1, 1)
                    if (!Number.parseInt(valuedata)) {
                        if (value.toString().indexOf(' ') != -1 || value.toString().indexOf('-') != -1) {
                            callback(new Error('数据格式有误'))
                        } else {
                            if (Number.parseInt(valuedata) == 0) {
                                callback()
                            } else {
                                callback(new Error('最后一位格式有误'))
                            }
                        }
                    }
                    if (value < 0) {
                        callback(new Error('值需大于0'))
                    } else {
                        callback()
                    }
                }, 500)
            }
        }

        // 合伙人配置-设备价格-校验-必须大于 0且保留两位小数
        var checkDevicePrice = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('设备价格不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error('请输入价格'))
                } else {
                    let result = value.toString().indexOf('.')
                    if (result != -1) {
                        let getdata = value.toString().split('.')
                        if (getdata[getdata.length - 1].length > 2) {
                            callback(new Error('请保留小数点后两位'))
                        }
                    }
                    if (value <= 0) {
                        callback(new Error('值需大于0'))
                    } else {
                        callback()
                    }
                }
            }, 500)
        }
        // 合伙人配置-邀请关系保护期-整数-校验
        var checkBaohuDays = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf('.')
                if (result != -1) {
                    callback(new Error('请输入整数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入整数'))
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error('请输入整数'))
                        } else {
                            if (value < 0) {
                                callback(new Error('值需大于0'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }
        // 合伙人配置-返佣比例-百分之整数-校验
        var checkBackMoney = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('比例值不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf('.')
                if (result != -1) {
                    callback(new Error('请输入整数'))
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error('请输入整数'))
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error('请输入整数'))
                        } else {
                            if (value > 100) {
                                callback(new Error('值需小于等于100'))
                            } else {
                                if (value < 0) {
                                    callback(new Error('值需大于0'))
                                } else {
                                    callback()
                                }
                            }
                        }
                    }
                }
            }, 500)
        }
        // 合伙人配置-分红比例值-0~1之间-校验
        var checkBackbili = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('比例值不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            if (!Number.parseInt(value.toString().substr(0, 1)) && value.toString().substr(0, 1) != 0) {
                return callback(new Error('首位必须为数字'))
            }
            setTimeout(() => {
                // 当输入为整数的判断
                if (!Number.parseFloat(value)) {
                    callback(new Error('请输入小数'))
                } else {
                    if (isNaN(value)) {
                        callback(new Error('请输入小数'))
                    } else {
                        if (value > 1) {
                            callback(new Error('值小于等于1'))
                        } else {
                            if (value < 0) {
                                callback(new Error('值大于等于0'))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }, 500)
        }

        // 数字input框-校验（可小数）【广告价格配置】-值必须大于0
        var checkInputEventaddr = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error('请输入数字'))
                } else {
                    let result = value.toString().indexOf('.')
                    if (result != -1) {
                        let getdata = value.toString().split('.')
                        if (getdata[getdata.length - 1].length > 2) {
                            callback(new Error('请保留小数点后两位'))
                        }
                    }
                    if (value <= 0) {
                        callback(new Error('值需大于0'))
                    } else {
                        callback()
                    }
                }
            }, 500)
        }

        // 广告价格配置-投放天数&台数折扣-折扣校验
        var checkRebate = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('折扣不能为空'))
            }
            setTimeout(() => {
                callback()
            }, 500)
        }
        // 广告价格配置-投放台数折扣-台数校验
        var checkBig = (rule, value, callback) => {
            //获取当前数组中的ide
            var getide = rule.field.split('.')[1]
            //获取投放天数中key值为ide下的相关数据
            var getdatas = this.ruleFormAddrP.client_advert_price_num[getide]
            if (!value) {
                return callback(new Error('台数不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 当输入为整数的判断
                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数字值'))
                } else {
                    if (value > 20) {
                        callback(new Error('值小于等于20'))
                    } else {
                        if (getdatas.put_big <= getdatas.put_small) {
                            callback(new Error('值需大于当前起始值'))
                        } else {
                            callback()
                        }
                    }
                }
            }, 500)
        }
        // 广告价格配置-投放天数折扣-天数校验
        var checkBigDay = (rule, value, callback) => {
            //获取当前数组中的ide
            var getide = rule.field.split('.')[1]
            //获取投放天数中key值为ide下的相关数据
            var getdatas = this.ruleFormAddrP.client_advert_price_day[getide]
            //校验判断
            if (!value) {
                return callback(new Error('天数不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }
            setTimeout(() => {
                // 当输入为整数的判断
                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数字值'))
                } else {
                    if (value > 60) {
                        callback(new Error('值需小于等于60'))
                    } else {
                        if (getdatas.put_big <= getdatas.put_small) {
                            callback(new Error('值需大于当前起始值'))
                        } else {
                            callback()
                        }
                    }
                }
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '设置'
                },
                {
                    name: '系统设置'
                },
                {
                    name: '基础设置',
                    path: '/system'
                }
            ], // 当前页-【面包屑导航】
            activeName: 'one', //tabs标签-默认显示页

            buttondisable: false, // 控制提交保存按钮-防止多次点击

            //form表单-赚钱页配置
            ruleForm: {},

            //form表单-更新程序包
            ruleFormCxb: {
                version: '',
                link: ''
            },
            //form表单-必填规则-更新程序包
            rulesCxb: {
                version: [
                    {
                        required: true,
                        validator: checkInputEventNumber,
                        trigger: 'blur'
                    }
                ],
                link: [
                    {
                        required: true,
                        validator: checkInputEventWord,
                        trigger: 'blur'
                    }
                ]
            },
            //form表单-余额相关配置
            ruleFormYuE: {
                recharge: [
                    {
                        price: '',
                        descr: '',
                        addisshow: true
                    }
                ],
                address: '-1',
                tips: ''
            },
            //form表单-必填规则-余额相关配置
            rulesYuE: {
                price: [
                    {
                        required: true,
                        message: '必填项不能为空',
                        trigger: 'change'
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ],
                descr: [
                    {
                        required: true,
                        message: '必填项不能为空',
                        trigger: 'change'
                    },
                    {
                        required: true,
                        validator: checkInputEventWordNull,
                        trigger: 'blur'
                    }
                ],
                address: [
                    {
                        required: true,
                        message: '收货地址为必选项',
                        trigger: 'change'
                    }
                ]
            },
            //form表单-合伙人配置
            ruleFormHhr: {
                share_scale: '',
                enough_share_scale: '',
                invest_price: '',
                buy_price: '',
                buy_one_scale: '',
                buy_two_scale: '',
                invite_one_scale: '',
                invite_two_scale: '',
                invite_relation_time: ''
            },
            //form表单-必填规则-合伙人配置
            rulesHhr: {
                invest_price: [
                    {
                        required: true,
                        validator: checkDevicePrice,
                        trigger: 'blur'
                    }
                ],
                invite_one_scale: [
                    {
                        required: true,
                        validator: checkBackMoney,
                        trigger: 'blur'
                    }
                ],
                invite_two_scale: [
                    {
                        required: true,
                        validator: checkBackMoney,
                        trigger: 'blur'
                    }
                ],
                share_scale: [
                    {
                        required: true,
                        validator: checkBackbili,
                        trigger: 'blur'
                    }
                ],
                enough_share_scale: [
                    {
                        required: true,
                        validator: checkBackbili,
                        trigger: 'blur'
                    }
                ],
                buy_price: [
                    {
                        required: true,
                        validator: checkDevicePrice,
                        trigger: 'blur'
                    }
                ],
                buy_one_scale: [
                    {
                        required: true,
                        validator: checkBackMoney,
                        trigger: 'blur'
                    }
                ],
                buy_two_scale: [
                    {
                        required: true,
                        validator: checkBackMoney,
                        trigger: 'blur'
                    }
                ],
                invite_relation_time: [
                    {
                        required: true,
                        validator: checkBaohuDays,
                        trigger: 'blur'
                    }
                ]
            },
            //form表单-广告价格配置
            ruleFormAddrP: {
                client_advert_price: [
                    {
                        num: 0,
                        type: 1,
                        price: 0,
                        title: '15s内'
                    },
                    {
                        num: 0,
                        type: 1,
                        price: 0,
                        title: '16s-30s'
                    },
                    {
                        num: 0,
                        type: 1,
                        price: 0,
                        title: '31s-60s'
                    },
                    {
                        num: 0,
                        type: 1,
                        price: 0,
                        title: '61s+'
                    }
                ], //价格
                client_advert_price_day: [
                    {
                        put_big: 2,
                        put_small: 1,
                        put_rebate: 10,
                        btnisshow: 1
                    }
                ], //投放天数折扣
                client_advert_price_num: [
                    {
                        put_big: 2,
                        put_small: 1,
                        put_rebate: 10,
                        btnisshow: 1
                    }
                ], //投放台数折扣

                // 折扣下拉数据
                rebatedata: [
                    {
                        value: 10,
                        label: '不享受优惠'
                    },
                    {
                        value: 9.5,
                        label: '9.5 折'
                    },
                    {
                        value: 9,
                        label: '9 折'
                    },
                    {
                        value: 8.5,
                        label: '8.5 折'
                    },
                    {
                        value: 8,
                        label: '8 折'
                    },
                    {
                        value: 7.5,
                        label: '7.5 折'
                    },
                    {
                        value: 7,
                        label: '7 折'
                    },
                    {
                        value: 6.5,
                        label: '6.5 折'
                    },
                    {
                        value: 6,
                        label: '6 折'
                    },
                    {
                        value: 5.5,
                        label: '5.5 折'
                    },
                    {
                        value: 5,
                        label: '5 折'
                    }
                ]
            },
            //form表单-必填规则-广告价格配置
            rulesAddrP: {
                price: [
                    {
                        required: true,
                        validator: checkInputEventaddr,
                        trigger: 'blur'
                    }
                ],
                num: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                put_day_big: [
                    {
                        required: true,
                        validator: checkBigDay,
                        trigger: 'blur'
                    }
                ],
                put_day_rebate: [
                    {
                        required: true,
                        validator: checkRebate,
                        trigger: 'change'
                    }
                ],
                put_big: [
                    {
                        required: true,
                        validator: checkBig,
                        trigger: 'blur'
                    }
                ],
                put_rebate: [
                    {
                        required: true,
                        validator: checkRebate,
                        trigger: 'change'
                    }
                ]
            },
            //form表单-打印相关配置
            ruleFormPrint: {
                refund_day_num: '',
                refund_month_num: '',
                refund_effect_day: '',
                balance_month_max: '',
                cash_back: '',
                warn: '',
                electron_price: ''
            },
            //form表单-必填规则-打印相关配置
            rulesPrint: {
                refund_day_num: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                refund_month_num: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                refund_effect_day: [
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: 'blur'
                    }
                ],
                balance_month_max: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ],
                cash_back: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ],
                warn: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ],
                electron_price: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ]
            },
            //form表单-广告主配置
            ruleFormAdt: {
                coupon_price: '',
                coupon_check: '',
                extend_price: '',
                extend_check: '',
                run_money: ''
            },
            //form表单-必填规则-广告主配置
            rulesAdt: {
                coupon_price: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ],
                coupon_check: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ],
                extend_price: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ],
                extend_check: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ],
                run_money: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ]
            },
            //form表单-保证金配置
            ruleFormBond: {
                recharge: [
                    {
                        title: '',
                        value: '',
                        addisshow: true
                    }
                ]
            },
            //form表单-必填规则-保证金配置
            rulesBond: {
                title: [
                    {
                        required: true,
                        validator: checkInputEventWordNull,
                        trigger: 'blur'
                    }
                ],
                value: [
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'blur'
                    }
                ]
            },

            //form表单-小程序审核线路配置
            ruleFormPhotoExamine: {
                text: '2',
                img: '2'
            },
            //form表单-必填规则-小程序审核线路配置
            rulePhotoExamine: {
                text: [
                    {
                        required: true,
                        message: '文本审核线路为必选项',
                        trigger: 'blur'
                    }
                ],
                img: [
                    {
                        required: true,
                        message: '图片审核线路为必选项',
                        trigger: 'blur'
                    }
                ]
            }, //form表单填写规则-前端判断是否必填

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token]
            isshow: true //用于判断页面模块是否存在
        }
    },
    methods: {
        getValidateEvent(data) {
            console.log('获取的data值：' + JSON.stringify(data))
        },
        //余额相关-获取imageupload组件回传的url值
        getImageUrlEvent(data) {
            console.log('获取的images组件回传的url值为：' + data)
        },
        //充值余额||保证金-增加事件
        addRechargeEvent(fromdata, flage, times) {
            // 充值余额
            if (flage == 'cash_recharge_set') {
                if (times) {
                    if (fromdata.recharge.length < times) {
                        let oneAddChat = {
                            price: '',
                            descr: ''
                        }
                        // js中通过push向数组最后新增指定的元素
                        fromdata.recharge.push(oneAddChat)
                    } else {
                        ElMessage({
                            type: 'warning',
                            message: '最多增加' + (times - 1) + '条！'
                        })
                    }
                } else {
                    let oneAddChats = {
                        price: '',
                        descr: ''
                    }
                    // js中通过push向数组最后新增指定的元素
                    this.ruleFormYuE.recharge.push(oneAddChats)
                }
            }
            // 保证金
            if (flage == 'bond_device_set') {
                if (times) {
                    if (fromdata.recharge.length < times) {
                        let oneAddChat = {
                            title: '',
                            value: ''
                        }
                        // js中通过push向数组最后新增指定的元素
                        fromdata.recharge.push(oneAddChat)
                    } else {
                        ElMessage({
                            type: 'warning',
                            message: '最多增加' + (times - 1) + '条！'
                        })
                    }
                } else {
                    let oneAddChats = {
                        title: '',
                        value: ''
                    }
                    // js中通过push向数组最后新增指定的元素
                    this.ruleFormBond.recharge.push(oneAddChats)
                }
            }
        },
        //充值余额||保证金-删除事件
        delRechargeEvent(ide, formdata) {
            console.log('获取的删除id为：' + ide)
            // js中通过splice删除指定的元素
            formdata.recharge.splice(ide, 1)
        },
        //广告价格-投放天数-增加事件
        addRechargedaysEvent(ide, getdaysinfo) {
            if (getdaysinfo.put_big !== '') {
                if (parseInt(getdaysinfo.put_big) < 60) {
                    if (parseInt(getdaysinfo.put_big) >= parseInt(getdaysinfo.put_small)) {
                        this.ruleFormAddrP.client_advert_price_day[ide].btnisshow = 1
                        var oneAddChat = {
                            put_small: parseInt(getdaysinfo.put_big) + 1,
                            put_big: '',
                            put_rebate: null,
                            btnisshow: 2
                        }
                        // js中通过push向数组最后新增指定的元素
                        this.ruleFormAddrP.client_advert_price_day.push(oneAddChat)
                    } else {
                        ElMessage({
                            type: 'warning',
                            message: '无法新增，所填天数需大于等于起始天数'
                        })
                        return
                    }
                } else {
                    ElMessage({
                        type: 'warning',
                        message: '无法新增，天数最多到60天'
                    })
                    return
                }
            } else {
                ElMessage({
                    type: 'warning',
                    message: '无法新增，天数不能为空'
                })
                return
            }
        },
        //广告价格-投放天数-删除事件
        delRechargedaysEvent(ide) {
            let clientadvertpriceday = this.ruleFormAddrP.client_advert_price_day
            if (ide > 0) {
                clientadvertpriceday.splice(ide, 1)
            }
        },
        //广告价格-投放台数-增加事件
        addRechargenumberEvent(ide, getdaysinfo) {
            if (getdaysinfo.put_big !== '') {
                if (parseInt(getdaysinfo.put_big) < 20) {
                    if (parseInt(getdaysinfo.put_big) >= parseInt(getdaysinfo.put_small)) {
                        this.ruleFormAddrP.client_advert_price_num[ide].btnisshow = 1
                        var oneAddChat = {
                            put_small: parseInt(getdaysinfo.put_big) + 1,
                            put_big: '',
                            put_rebate: null,
                            btnisshow: 2
                        }
                        // js中通过push向数组最后新增指定的元素
                        this.ruleFormAddrP.client_advert_price_num.push(oneAddChat)
                    } else {
                        ElMessage({
                            type: 'warning',
                            message: '无法新增，所填台数需大于等于起始台数'
                        })
                        return
                    }
                } else {
                    ElMessage({
                        type: 'warning',
                        message: '无法新增，台数最多到20台'
                    })
                    return
                }
            } else {
                ElMessage({
                    type: 'warning',
                    message: '无法新增，台数不能为空'
                })
                return
            }
        },
        //广告价格-投放台数-删除事件
        delRechargenumberEvent(ide) {
            let clientadvertpricenum = this.ruleFormAddrP.client_advert_price_num
            if (ide > 0) {
                clientadvertpricenum.splice(ide, 1)
            }
        },
        // 切换tabs标签页面，点击触发事件
        handleClick(tab) {
            var ajaxapi = '/admin/config/info'
            var key_name = ''
            // 赚钱页配置
            if (tab.props.name === 'one') {
                key_name = 'coin_set'
                //通过Ajax向后台获取数据
                axios
                    .post(
                        ajaxapi,
                        {
                            key_name: key_name
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        this.ruleForm = response.data.result
                        this.ruleForm.valid_time = parseInt(response.data.result.valid_time)
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
            //更新程序包
            if (tab.props.name === 'two') {
                key_name = 'update_package'
                //通过Ajax向后台获取数据
                axios
                    .post(
                        ajaxapi,
                        {
                            key_name: key_name
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        this.ruleFormCxb = response.data.result
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
            //余额相关配置
            if (tab.props.name === 'three') {
                key_name = 'cash_recharge_set'
                //通过Ajax向后台获取数据
                axios
                    .post(
                        ajaxapi,
                        {
                            key_name: key_name
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        response.data.result.contents[0].addisshow = true
                        let responsedata = response.data.result.contents
                        responsedata.forEach((element) => {
                            // (分)转(元)-充值金额
                            element.price = element.price / 100
                        })
                        this.ruleFormYuE.recharge = responsedata
                        this.ruleFormYuE.address = response.data.result.is_address
                        this.ruleFormYuE.tips = response.data.result.title
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
            //合伙人配置
            if (tab.props.name === 'four') {
                key_name = 'partner_set'
                //通过Ajax向后台获取数据
                axios
                    .post(
                        ajaxapi,
                        {
                            key_name: key_name
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        let responsedata = response.data.result
                        // (分)转(元)-投资设备价格
                        responsedata.invest_price = responsedata.invest_price / 100
                        // (分)转(元)-购买设备价格
                        responsedata.buy_price = responsedata.buy_price / 100
                        this.ruleFormHhr = responsedata
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
            //广告价格配置
            if (tab.props.name === 'five') {
                ajaxapi = '/admin/config/getClientAdvert'
                //通过Ajax向后台获取数据
                axios
                    .post(
                        ajaxapi,
                        {},
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        console.log(response.data)

                        // //渲染获取的数据：client_advert_price
                        let clientadvertprice = response.data.result.client_advert_price
                        clientadvertprice.forEach((element) => {
                            // (分)转（元）-价格
                            element.price = element.price / 100
                        })
                        this.ruleFormAddrP.client_advert_price = clientadvertprice

                        //渲染获取的数据：client_advert_price_day
                        // 将数据转换成int类型
                        let clientadvertpriceday = response.data.result.client_advert_price_day
                        let daydata = []
                        clientadvertpriceday.forEach(function(value, index) {
                            let daydataarry = {
                                put_rebate: parseFloat(value.put_rebate),
                                put_big: parseInt(value.put_big),
                                put_small: parseInt(value.put_small),
                                btnisshow: index == 0 ? 1 : 2
                            }
                            daydata.push(daydataarry)
                        })
                        this.ruleFormAddrP.client_advert_price_day = daydata

                        //渲染获取的数据：client_advert_price_num
                        // 将数据转换成int类型
                        let clientadvertpricenum = response.data.result.client_advert_price_num
                        let numdata = []
                        clientadvertpricenum.forEach(function(value, index) {
                            let numdataarry = {
                                put_rebate: parseFloat(value.put_rebate),
                                put_big: parseInt(value.put_big),
                                put_small: parseInt(value.put_small),
                                btnisshow: index == 0 ? 1 : 2
                            }
                            numdata.push(numdataarry)
                        })
                        this.ruleFormAddrP.client_advert_price_num = numdata
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
            //打印相关配置
            if (tab.props.name === 'six') {
                key_name = 'print_set'
                //通过Ajax向后台获取数据
                axios
                    .post(
                        ajaxapi,
                        {
                            key_name: key_name
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        // this.ruleFormPrint = response.data.result;
                        let responsedata = response.data.result
                        // (分)转(元)-余额支付每月最大限额
                        responsedata.balance_month_max = responsedata.balance_month_max / 100
                        // (分)转(元)-购机拉新返现
                        responsedata.cash_back = responsedata.cash_back / 100
                        // (分)转(元)-流量变现返现金额/用户
                        responsedata.warn = responsedata.warn / 100
                        // (分)转(元)-电子版证件照价格
                        responsedata.electron_price = responsedata.electron_price / 100
                        this.ruleFormPrint = responsedata
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
            //广告主配置
            if (tab.props.name === 'siven') {
                key_name = 'advertiser_price'
                //通过Ajax向后台获取数据
                axios
                    .post(
                        ajaxapi,
                        {
                            key_name: key_name
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        // this.ruleFormAdt = response.data.result;
                        let responsedata = response.data.result
                        // (分)转(元)-优惠券打印单价
                        responsedata.coupon_price = responsedata.coupon_price / 100
                        // (分)转(元)-优惠券核销单价
                        responsedata.coupon_check = responsedata.coupon_check / 100
                        // (分)转(元)-宣传页打印单价
                        responsedata.extend_price = responsedata.extend_price / 100
                        // (分)转(元)-宣传页核销单价
                        responsedata.extend_check = responsedata.extend_check / 100
                        // (分)转(元)-发布方案限制金额
                        responsedata.run_money = responsedata.run_money / 100
                        this.ruleFormAdt = responsedata
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
            //保证金配置
            if (tab.props.name === 'eight') {
                key_name = 'bond_device_set'
                //通过Ajax向后台获取数据
                axios
                    .post(
                        ajaxapi,
                        {
                            key_name: key_name
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        response.data.result[0].addisshow = true
                        let responsedata = response.data.result
                        responsedata.forEach((element) => {
                            // (分)转(元)-保证金
                            element.value = element.value / 100
                        })
                        this.ruleFormBond.recharge = responsedata
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }

            // 小程序审核线路配置
            if (tab.props.name === 'nine') {
                axios
                    .post(
                        '/admin/config/info',
                        {
                            key_name: 'check_content_line'
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) => {
                        this.ruleFormPhotoExamine = response.data.result
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
        },
        //from表单-提交表单-(常规表单)【赚钱页配置||更新程序包||合伙人配置||打印相关配置||广告主配置】
        submitForm(formName, keyname, formdata) {
            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
            this.buttondisable = true
            // console.log("当前获取的formdata：" + JSON.stringify(formdata));
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 合伙人配置-相关字段转换
                    if (formName == 'ruleFormHhr') {
                        formdata.invest_price = formdata.invest_price * 100
                        formdata.buy_price = formdata.buy_price * 100
                    }
                    // 打印相关配置-相关字段转换
                    if (formName == 'ruleFormPrint') {
                        formdata.balance_month_max = formdata.balance_month_max * 100
                        formdata.cash_back = formdata.cash_back * 100
                        formdata.warn = formdata.warn * 100
                        formdata.electron_price = formdata.electron_price * 100
                    }
                    // 广告主配置-相关字段转换
                    if (formName == 'ruleFormAdt') {
                        formdata.coupon_price = formdata.coupon_price * 100
                        formdata.coupon_check = formdata.coupon_check * 100
                        formdata.extend_price = formdata.extend_price * 100
                        formdata.extend_check = formdata.extend_check * 100
                        formdata.run_money = formdata.run_money * 100
                    }

                    axios
                        .post(
                            '/admin/config/update',
                            {
                                key_name: keyname,
                                contents: formdata
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '保存成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        if (formName == 'ruleFormHhr') {
                                            let tab = {
                                                props: { name: 'four' }
                                            }
                                            this.handleClick(tab)
                                        } else {
                                            if (formName == 'ruleFormPrint') {
                                                let tab = {
                                                    props: { name: 'six' }
                                                }
                                                this.handleClick(tab)
                                            } else {
                                                if (formName == 'ruleFormAdt') {
                                                    let tab = {
                                                        props: { name: 'siven' }
                                                    }
                                                    this.handleClick(tab)
                                                } else {
                                                    if (formName == 'ruleForm') {
                                                        let tab = {
                                                            props: { name: 'one' }
                                                        }
                                                        this.handleClick(tab)
                                                    } else {
                                                        if (formName == 'ruleFormCxb') {
                                                            let tab = {
                                                                props: { name: 'two' }
                                                            }
                                                            this.handleClick(tab)
                                                        } else {
                                                            this.reload() //刷新
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                        this.buttondisable = false
                                    }
                                })
                            } else {
                                setTimeout(() => {
                                    if (formName == 'ruleFormHhr') {
                                        let tab = {
                                            props: { name: 'four' }
                                        }
                                        this.handleClick(tab)
                                    } else {
                                        if (formName == 'ruleFormPrint') {
                                            let tab = {
                                                props: { name: 'six' }
                                            }
                                            this.handleClick(tab)
                                        } else {
                                            if (formName == 'ruleFormAdt') {
                                                let tab = {
                                                    props: { name: 'siven' }
                                                }
                                                this.handleClick(tab)
                                            } else {
                                                if (formName == 'ruleForm') {
                                                    let tab = {
                                                        props: { name: 'one' }
                                                    }
                                                    this.handleClick(tab)
                                                } else {
                                                    if (formName == 'ruleFormCxb') {
                                                        let tab = {
                                                            props: { name: 'two' }
                                                        }
                                                        this.handleClick(tab)
                                                    } else {
                                                        this.reload() //刷新
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                    this.buttondisable = false
                                }, 1000)
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            if (formName == 'ruleFormHhr') {
                                let tab = {
                                    props: { name: 'four' }
                                }
                                this.handleClick(tab)
                            } else {
                                if (formName == 'ruleFormPrint') {
                                    let tab = {
                                        props: { name: 'six' }
                                    }
                                    this.handleClick(tab)
                                } else {
                                    if (formName == 'ruleFormAdt') {
                                        let tab = {
                                            props: { name: 'siven' }
                                        }
                                        this.handleClick(tab)
                                    } else {
                                        if (formName == 'ruleForm') {
                                            let tab = {
                                                props: { name: 'one' }
                                            }
                                            this.handleClick(tab)
                                        } else {
                                            if (formName == 'ruleFormCxb') {
                                                let tab = {
                                                    props: { name: 'two' }
                                                }
                                                this.handleClick(tab)
                                            } else {
                                                this.reload() //刷新
                                            }
                                        }
                                    }
                                }
                            }
                            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                            this.buttondisable = false
                        })
                } else {
                    console.log('error submit!!')
                    if (formName == 'ruleFormHhr') {
                        let tab = {
                            props: { name: 'four' }
                        }
                        this.handleClick(tab)
                    } else {
                        if (formName == 'ruleFormPrint') {
                            let tab = {
                                props: { name: 'six' }
                            }
                            this.handleClick(tab)
                        } else {
                            if (formName == 'ruleFormAdt') {
                                let tab = {
                                    props: { name: 'siven' }
                                }
                                this.handleClick(tab)
                            } else {
                                if (formName == 'ruleForm') {
                                    let tab = {
                                        props: { name: 'one' }
                                    }
                                    this.handleClick(tab)
                                } else {
                                    if (formName == 'ruleFormCxb') {
                                        let tab = {
                                            props: { name: 'two' }
                                        }
                                        this.handleClick(tab)
                                    } else {
                                        this.reload() //刷新
                                    }
                                }
                            }
                        }
                    }
                    // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                    this.buttondisable = false
                    return false
                }
            })
        },
        //from表单-重置表单-(常规表单)
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        //form表单-提交表单-（非常规）
        submitFormSpe(formdata, keyname) {
            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
            this.buttondisable = true
            // console.log("非常规表单-保存-数据：" + JSON.stringify(formdata));
            // 提交-余额相关配置
            if (keyname == 'cash_recharge_set') {
                // form表单效验
                this.$refs['ruleFormYuE'].validate((valid) => {
                    if (valid) {
                        // （元）转（分）-价格-存储
                        formdata.recharge.forEach((element) => {
                            element.price = element.price * 100
                        })

                        axios
                            .post(
                                '/admin/config/update',
                                {
                                    key_name: keyname,
                                    contents: {
                                        contents: formdata.recharge,
                                        is_address: formdata.address,
                                        title: formdata.tips
                                    }
                                },
                                {
                                    headers: {
                                        Authentication: localStorage.getItem('token')
                                    }
                                }
                            )
                            .then((response) => {
                                if (response.data.code === 0) {
                                    ElMessage({
                                        type: 'success',
                                        message: '保存成功！',
                                        duration: 1000,
                                        onClose: () => {
                                            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                            this.buttondisable = false

                                            let tab = {
                                                props: { name: 'three' }
                                            }
                                            this.handleClick(tab)
                                        }
                                    })
                                } else {
                                    setTimeout(() => {
                                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                        this.buttondisable = false
                                        let tab = { props: { name: 'three' } }
                                        this.handleClick(tab)
                                    }, 1000)
                                }
                            })
                            .catch(function(error) {
                                // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                this.buttondisable = false
                                console.log(error)

                                let tab = {
                                    props: { name: 'three' }
                                }
                                this.handleClick(tab)
                            })
                    } else {
                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                        this.buttondisable = false
                        console.log('error submit!!')
                        let tab = {
                            props: { name: 'three' }
                        }
                        this.handleClick(tab)
                        return false
                    }
                })
            }
            // 提交-保证金配置
            if (keyname == 'bond_device_set') {
                // form表单效验
                this.$refs['ruleFormBond'].validate((valid) => {
                    if (valid) {
                        // （元）转（分）-价格-存储
                        formdata.forEach((element) => {
                            element.value = element.value * 100
                        })
                        axios
                            .post(
                                '/admin/config/update',
                                {
                                    key_name: keyname,
                                    contents: formdata
                                },
                                {
                                    headers: {
                                        Authentication: localStorage.getItem('token')
                                    }
                                }
                            )
                            .then((response) => {
                                if (response.data.code === 0) {
                                    ElMessage({
                                        type: 'success',
                                        message: '保存成功！',
                                        duration: 1000,
                                        onClose: () => {
                                            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                            this.buttondisable = false

                                            let tab = {
                                                props: { name: 'eight' }
                                            }
                                            this.handleClick(tab)
                                        }
                                    })
                                } else {
                                    setTimeout(() => {
                                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                        this.buttondisable = false
                                        let tab = { props: { name: 'eight' } }
                                        this.handleClick(tab)
                                    }, 1000)
                                }
                            })
                            .catch(function(error) {
                                console.log(error)
                                // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                this.buttondisable = false

                                let tab = {
                                    props: { name: 'eight' }
                                }
                                this.handleClick(tab)
                            })
                    } else {
                        console.log('error submit!!')
                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                        this.buttondisable = false

                        let tab = {
                            props: { name: 'eight' }
                        }
                        this.handleClick(tab)
                        return false
                    }
                })
            }
        },
        //form表单-重置表单-（非常规）
        resetFormSpe(formdata, formname) {
            // console.log("非常规表单-重置-数据：" + JSON.stringify(formdata));
            if (formname === 'YuE') {
                this.ruleFormYuE = {
                    recharge: [
                        {
                            price: '',
                            descr: '',
                            addisshow: true
                        }
                    ]
                }
            }
            if (formname === 'Bond') {
                this.ruleFormBond = {
                    recharge: [
                        {
                            title: '',
                            value: '',
                            addisshow: true
                        }
                    ]
                }
            }
        },
        //form表单-提交表单-（非常规-广告价格配置）
        submitFormAdt(formName) {
            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
            this.buttondisable = true
            // console.log( "广告价格配置-表单-提交-数据：" + JSON.stringify(this.$refs[formName].model));
            var formdata = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // (元)转（分）-价格
                    formdata.client_advert_price.forEach((element, index) => {
                        element.price = element.price * 100
                        element.type = (index + 1).toString()
                    })

                    axios
                        .post(
                            '/admin/config/setClientAdvert',
                            {
                                client_advert_price: formdata.client_advert_price,
                                client_advert_price_day: formdata.client_advert_price_day,
                                client_advert_price_num: formdata.client_advert_price_num
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '保存成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                        this.buttondisable = false
                                        let tab = {
                                            props: { name: 'five' }
                                        }
                                        this.handleClick(tab)
                                    }
                                })
                            } else {
                                ElMessage({
                                    type: 'warning',
                                    message: '保存失败！',
                                    duration: 1000,
                                    onClose: () => {
                                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                        this.buttondisable = false

                                        let tab = {
                                            props: { name: 'five' }
                                        }
                                        this.handleClick(tab)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                            this.buttondisable = false

                            let tab = {
                                props: { name: 'five' }
                            }
                            this.handleClick(tab)
                        })
                } else {
                    console.log('error submit!!')
                    // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                    this.buttondisable = false

                    let tab = {
                        props: { name: 'five' }
                    }
                    this.handleClick(tab)
                    return false
                }
            })
        },
        //form表单-重置表单-（非常规-广告价格配置）
        resetFormAdt() {
            var getdatas = this.ruleFormAddrP
            for (let i = 0; i < getdatas.client_advert_price.length; i++) {
                const element = getdatas.client_advert_price[i]
                element.num = null
                element.price = null
            }
            getdatas.client_advert_price_day = [
                {
                    put_big: 2,
                    put_small: 1,
                    put_rebate: 10,
                    btnisshow: 1
                }
            ]
            getdatas.client_advert_price_num = [
                {
                    put_big: 2,
                    put_small: 1,
                    put_rebate: 10,
                    btnisshow: 1
                }
            ]
        },

        // form表单-提交表单小程序审核线路配置
        submitFormPhotoExamine(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/config/update',
                            {
                                key_name: 'check_content_line',
                                contents: {
                                    text: this.ruleFormPhotoExamine.text,
                                    img: this.ruleFormPhotoExamine.img
                                }
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！'
                                })

                                let tab = {
                                    props: { name: 'nine' }
                                }
                                this.handleClick(tab)
                            } else {
                                let tab = {
                                    props: { name: 'nine' }
                                }
                                this.handleClick(tab)
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                            let tab = {
                                props: { name: 'nine' }
                            }
                            this.handleClick(tab)
                        })
                } else {
                    console.log('error submit!!')
                    let tab = {
                        props: { name: 'nine' }
                    }
                    this.handleClick(tab)
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetFormPhotoExamine(formName) {
            this.$refs[formName].resetFields()
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            //首次加载-赚钱页配置
            axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'coin_set'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.ruleForm = response.data.result
                    this.ruleForm.valid_time = parseInt(response.data.result.valid_time)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        MakeMoneySet
    }
})
</script>
<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 0px solid #eeeeee;
}
</style>
