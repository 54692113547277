<template>
    <!-- 动态进度条封装-dpw-20230510 -->
    <div style="margin: 5px 0;">
        <el-progress :text-inside="true" :stroke-width="16" :percentage="percentage" status="success" />
        <div style="line-height: 26px;color: rgb(255, 72, 72);font-size: 13px;">
            <span>{{ tips }}</span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'activeSchedule',
    inject: ['reload'],
    props: ['Status', 'Tips'],
    watch: {
        Status: {
            handler(newVal) {
                this.status = newVal
                if (newVal == 1) {
                    this.changePercentage(1)
                } else {
                    this.changePercentage(2)
                }
            },
            deep: true,
            immediate: true
        },
        Tips: {
            handler(newVal) {
                this.tips = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            status: 1,
            tips: '',
            percentage: 0
        }
    },
    methods: {
        changePercentage(flag) {
            if (flag == 1) {
                var myVar = setInterval(() => {
                    this.percentage += 1

                    if (this.percentage >= 99) {
                        this.percentage = 99
                        clearInterval(myVar)
                    }
                }, 300)
            }

            if (flag == 2) {
                this.percentage = 100
                clearInterval(myVar)
            }
        },

        changeEvent(data) {
            this.$emit('get-back-data', data)
        }
    },
    components: {}
})
</script>

<style scoped></style>
