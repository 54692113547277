<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">订单归属平台查询</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="5">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="12">
                            <div style="margin: 30px 0 60px 0;text-align: center;">
                                <span style="font-size:30px;color:#333333">订单归属平台查询</span>
                            </div>
                            <div>
                                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="0px"
                                    class="demo-ruleForm">
                                    <!--登录用户名-->
                                    <el-form-item prop="content">
                                        <div class="belong-input">
                                            <div class="belong-input-box">
                                                <el-input v-model="this.ruleForm.content" placeholder="请输入订单编号"
                                                    maxlength="30" show-word-limit clearable>
                                                </el-input>
                                            </div>
                                            <div class="belong-input-button">
                                                <el-button type="primary" @click="submitForm('ruleForm')"
                                                    style="width: 120px" :disabled="mydisabled">查询</el-button>
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item>
                                        <div style="color:#999999">
                                            <span class="belong-text">归属小程序：</span>
                                            <span v-if="loading">查询中<i class="el-icon-loading"></i></span>
                                            <span v-if="!loading" class="find-box"
                                                :style="{ 'color': this.show == 1 ? '#333333' : '#999999' }">
                                                {{ this.mininame }}
                                            </span>
                                        </div>
                                    </el-form-item>
                                </el-form>
                            </div>

                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from "@/components/Mainone.vue";
import axios from "axios";

export default {
    name: "vorderbelong",
    data() {
        var checkInputEventInt = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("必填项不能为空"));
            }
            if (value.toString().indexOf(" ") != -1) {
                return callback(new Error("值中不能有空格"));
            }
            setTimeout(() => {
                // 判断字符串是否存在小数点
                let result = value.toString().indexOf(".");
                if (result != -1) {
                    callback(new Error("请输入整数"));
                } else {
                    // 判断字符串是否为文字
                    if (isNaN(value)) {
                        callback(new Error("请输入数字"));
                    } else {
                        // 判断字符串是否为数字
                        if (!Number.parseInt(value) && Number.parseInt(value) != 0) {
                            callback(new Error("请输入数字"));
                        } else {
                            if (value < 0) {
                                callback(new Error("请输入非负数"));
                            } else {
                                callback();
                            }
                        }
                    }
                }
            }, 500);
        };
        return {
            mainonebread: [
                {
                    name: "订单",
                },
                {
                    name: "打印管理",
                },
                {
                    name: "订单归属平台查询",
                    path: "/orderbelong",
                },
            ], // 当前页-【面包屑导航】

            ruleForm: {
                content: "",
            }, //form表单数据
            rules: {
                content: [
                    {
                        required: true,
                        message: "订单编号为必填项",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        validator: checkInputEventInt,
                        trigger: "blur",
                    },
                ],

            }, //form表单填写规则-前端判断是否必填

            mininame: '',
            show: 0,

            mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
            mytoken: localStorage.getItem("token"), //获取存储的token
            myuserinfo: localStorage.getItem("userinfo"), //获取存储的用户信息

            loading: false, //加载动效
            mydisabled: false, // 防抖-前端维护
        };
    },
    methods: {
        //提交form表单-并判断是否必填
        submitForm(formName) {
            // 前端防抖
            this.mydisabled = true;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    axios
                        .post(
                            "/admin/shareorder/sourcePlat",
                            {
                                content: this.ruleForm.content
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                if (response.data.result) {
                                    this.mininame = '#'+ response.data.result['mini_name'];
                                } else {
                                    this.mininame = response.data.message;
                                }
                            }else{
                                this.mininame = response.data.message;
                            }
                            this.show = 1;
                            this.loading = false;
                            this.mydisabled = false;
                        })
                        .catch(function () {
                            this.show = 0;
                            this.loading = false;
                            this.mydisabled = false;
                        });
                } else {
                    this.mininame = '#查询后，此显示订单归属的小程序名称';
                    this.show = 0;
                    this.loading = false;
                    this.mydisabled = false;
                    return false;
                }
            });
        },
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        }
        else {
            this.mininame = '#查询后，此显示订单归属的小程序名称';
            this.show = 0;
        }
    },
    components: {
        mainone,
    },
};
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    padding: 5px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}

.belong-input {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.belong-input-box {
    width: 100%;
    margin-right: 20px;
}

.belong-text {
    font-size: 16px;
    color: #333333;
    margin-right: 30px;
}

.find-box{
    font-size: 16px;
}
</style>
