<template>
    <div v-loading="loading" element-loading-text="正在飞速加载中..." element-loading-background="#fff">
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="warning" plain @click="exportDatas()" v-if="this.daochudisabled == 0">导出</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                        :default-sort="{ prop: 'date', order: 'descending' }"
                        @sort-change="sortchangeEvent"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                            :sortable="mytabletitle.prop == 'order_total' || mytabletitle.prop == 'order_num' ? 'custom' : false"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop === 'client' ||
                                    mytabletitle.prop === 'order_total' ||
                                    mytabletitle.prop === 'order_num' ||
                                    mytabletitle.prop === 'address' ||
                                    mytabletitle.prop === 'addtimeStr'
                                "
                            >
                                <!-- 设备编号-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'client'">
                                    <!-- 暂时关闭 -->
                                    <!-- <div class="mytabletext">
                    <span @click="routeraddrClick('/vdcoverview', scope.row)">
                      {{ scope.row.client }}</span>
                  </div> -->
                                    <div>
                                        <span>{{ scope.row.client }}</span>
                                    </div>
                                </div>
                                <!-- 订单金额（扣除退款）-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'order_total'">
                                    <!-- <div :class="this.mypowerlimits.ddlb == true && this.usertype != 1 ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.ddlb == true && this.usertype != 1 ? routeraddrClick('/orderlist', scope.row) : ''">
                    <span>{{ scope.row.order_total }}</span>
                  </div> -->
                                    <div>
                                        <span>{{ scope.row.order_total }}</span>
                                    </div>
                                </div>
                                <!-- 订单数量-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'order_num'">
                                    <!-- <div :class="this.mypowerlimits.ddlb == true && this.usertype != 1 ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.ddlb == true && this.usertype != 1 ? routeraddrClick('/orderlist', scope.row) : ''">
                    <span>{{ scope.row.order_num }}</span>
                  </div> -->
                                    <div>
                                        <span>{{ scope.row.order_num }}</span>
                                    </div>
                                </div>
                                <!-- 投放位置-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'address'">
                                    <!-- <div :class="this.mypowerlimits.sbdt == true && this.usertype != 1 ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.sbdt == true && this.usertype != 1 ? routeraddrClick('/sharedvcmap', scope.row) : ''">
                    <span>{{ scope.row.address }}</span>
                  </div> -->
                                    <div>
                                        <span>{{ scope.row.address }}</span>
                                    </div>
                                </div>
                                <!--投放时间-样式等渲染-->
                                <div v-if="mytabletitle.prop === 'addtimeStr'">
                                    <div>
                                        {{ scope.row.addtimeStr == '' ? '' : scope.row.addtimeStr.substr(0, 10) }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器 layout="total, sizes, prev, pager, next, jumper" -->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            :layout="Layout"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
// import { ElMessage } from "element-plus";
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vdvcorderranking',
    data() {
        return {
            mainonebread: [
                {
                    name: '统计'
                },
                {
                    name: '打印流水统计'
                },
                {
                    name: '设备订单排行'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: true, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号：',
                            placeholder: '请输入设备编号',
                            name: 'client',
                            content: ''
                        }
                    ]
                },
                // myinputonefullisshow: true, //筛选查询-自动建议下拉框
                // myinputonefull: {
                //   divinterval: "0", //框右侧距离
                //   titlewidth: "74px", //标题宽度
                //   inputwidth: "200px", //input宽度
                //   inputinterval: "20px", //input右侧距离
                //   input: [
                //     {
                //       ismust: false, //是否必填，true表示必填
                //       title: "设备编号：",
                //       placeholder: "请输入设备编号", //此name为键值
                //       name: "client",
                //       content: "",
                //       id: "", //此id为给后台的id值
                //       url: "/admin/Equipment/searchClient",
                //       posttype:'get',
                //       inputval: { client: "" }, //这里记录需要传的参数
                //     },
                //   ],
                // },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '200px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '投放场景：',
                            placeholder: '全部',
                            name: 'push_type',
                            value: '',
                            options: []
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '支付时间：',
                            placeholder: {
                                placeholderstart: '请选择开始时间',
                                placeholderend: '请选择结束时间'
                            },
                            name: 'paytime',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            selectmaxwidth: '100%', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 36, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 50, // 数据列表中-操作-宽度
            mainthreetabletitle: [], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1000, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            Layout: ' sizes, prev, pager, next, jumper',

            //高级筛选
            myinputishow: true, //高级筛选-input单框
            myinput: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%',
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '代理商：',
                        placeholder: '请输入代理商',
                        name: 'name',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '设备编号：',
                        placeholder: '请输入设备编号',
                        name: 'client',
                        content: ''
                    }
                ]
            },
            // myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
            // myinputonefullgao: {
            //   divinterval: "3%", //框右侧距离
            //   inputwidth: "47%", //input宽度
            //   inputmaxwidth: "100%",
            //   input: [
            //     {
            //       ismust: false, //是否必填，true表示必填
            //       title: "设备编号：",
            //       placeholder: "请输入设备编号", //此name为键值
            //       name: "client",
            //       content: "",
            //       id: "", //此id为给后台的id值
            //       url: "/admin/Equipment/searchClient",
            //       posttype:'get',
            //       inputval: { client: "" }, //这里记录需要传的参数
            //     },
            //   ],
            // },
            myselectisshow: true, //高级筛选-select单框
            myselect: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '投放场景:',
                        placeholder: '全部',
                        name: 'push_type',
                        value: '',
                        options: []
                    },
                    // {
                    //   selectisshow: true,
                    //   ismust: false,
                    //   title: "投放方式:",
                    //   placeholder: "全部",
                    //   name: "is_buy",
                    //   value: "",
                    //   options: [
                    //     {
                    //       label: "全部",
                    //       value: "0",
                    //     },
                    //     {
                    //       label: "公司自投",
                    //       value: "1",
                    //     },
                    //     {
                    //       label: "购买",
                    //       value: "2",
                    //     },
                    //     {
                    //       label: "租赁",
                    //       value: "3",
                    //     },
                    //   ],
                    // },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '支付方式:',
                        placeholder: '全部',
                        name: 'paytype',
                        value: '',
                        options: [
                            {
                                value: '',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '余额'
                            },
                            {
                                value: '2',
                                label: '微信'
                            },
                            {
                                value: '3',
                                label: '支付宝'
                            },
                            {
                                value: '4',
                                label: '信用卡'
                            },
                            {
                                value: '5',
                                label: '余额代付'
                            }
                        ]
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '支付时间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'paytime',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '投放时间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'putintime',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {}
            }, //弹框

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            daochudisabled: 1,

            // 判断是否为资金方
            usertype: ''
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 排序
        sortchangeEvent(e) {
            console.log(e.prop)
            // if (e.prop == "order_total") {
            //   e.prop = "putintime";
            // }
            // if (e.prop == "uptimeStr") {
            //   e.prop = "uptime";
            // }

            let orderval = ''
            if (e.order == 'ascending') {
                orderval = 1
            } else {
                orderval = -1
            }

            this.pagesdatas.orderkey = e.prop
            this.pagesdatas.orderval = orderval
            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/lorderRanking', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: this.pagesdatas.client,
                        name: this.pagesdatas.name,
                        push_type: this.pagesdatas.push_type,
                        is_buy: this.pagesdatas.is_buy,
                        paytype: this.pagesdatas.paytype,
                        paytime: this.pagesdatas.paytime,
                        putintime: this.pagesdatas.putintime,
                        orderkey: e.prop,
                        orderval: orderval,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    let getdata = response.data.result.data
                    if (getdata.length > 0) {
                        if (getdata.length == this.PageSize) {
                            this.lastpage = this.totalCount / this.PageSize
                            if (this.totalCount / this.PageSize == this.lastpage) {
                                this.totalCount += this.PageSize * 5
                                this.lastpage = this.totalCount / this.PageSize
                            }
                            this.myaxiosfunc(response.data.result)
                        } else {
                            this.totalCount += getdata.length
                            this.lastpage += 1
                            this.myaxiosfunc(response.data.result)
                        }
                    } else {
                        this.myaxiosfunc(response.data.result)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/lorderRanking', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: this.pagesdatas.client,
                        name: this.pagesdatas.name,
                        push_type: this.pagesdatas.push_type,
                        is_buy: this.pagesdatas.is_buy,
                        paytype: this.pagesdatas.paytype,
                        paytime: this.pagesdatas.paytime,
                        putintime: this.pagesdatas.putintime,
                        orderkey: this.pagesdatas.orderkey,
                        orderval: this.pagesdatas.orderval,
                        page: 1,
                        page_size: val
                    }
                })
                .then((response) => {
                    // this.myaxiosfunc(response.data.result);
                    let getdata = response.data.result.data
                    if (getdata.length > 0) {
                        if (getdata.length == this.PageSize) {
                            this.lastpage = this.totalCount / this.PageSize
                            if (this.totalCount / this.PageSize == this.lastpage) {
                                this.totalCount += this.PageSize * 5
                                this.lastpage = this.totalCount / this.PageSize
                            }
                            this.myaxiosfunc(response.data.result)
                        } else {
                            this.totalCount += getdata.length
                            this.lastpage += 1
                            this.myaxiosfunc(response.data.result)
                        }
                    } else {
                        this.myaxiosfunc(response.data.result)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/lorderRanking', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: this.pagesdatas.client,
                        name: this.pagesdatas.name,
                        push_type: this.pagesdatas.push_type,
                        is_buy: this.pagesdatas.is_buy,
                        paytype: this.pagesdatas.paytype,
                        paytime: this.pagesdatas.paytime,
                        putintime: this.pagesdatas.putintime,
                        orderkey: this.pagesdatas.orderkey,
                        orderval: this.pagesdatas.orderval,
                        page: val,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                    let getdata = response.data.result.data
                    if (getdata.length > 0) {
                        if (getdata.length == this.PageSize) {
                            this.lastpage = this.totalCount / this.PageSize
                            if (this.totalCount / val == this.PageSize) {
                                this.totalCount += this.PageSize * 5
                                this.lastpage = this.totalCount / this.PageSize
                            }
                            this.myaxiosfunc(response.data.result)
                        } else {
                            this.totalCount += getdata.length
                            this.lastpage = 1
                            this.myaxiosfunc(response.data.result)
                        }
                    } else {
                        this.myaxiosfunc(response.data.result)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo)
            this.tancontent.content = taninfo.content
            this.tancontent.title = taninfo.title
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转-设备概览
                if (routeraddr == '/vdcoverview') {
                    this.$router.push({ path: routeraddr, query: { client: rowdata.id } }).catch((error) => error)
                }
                // 跳转-订单列表
                if (routeraddr == '/orderlist') {
                    this.$router.push({ path: routeraddr, query: { client: rowdata.client, thirdparty: '1', paytime: this.pagesdatas.paytime } }).catch((error) => error)
                }
                // 跳转-设备地图
                if (routeraddr === '/sharedvcmap') {
                    this.$router.push({ path: routeraddr, query: { area_code: rowdata.area_code, client: rowdata.client } }).catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },

        //导出数据
        exportDatas() {
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/lorderRanking', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: this.pagesdatas.client,
                        name: this.pagesdatas.name,
                        push_type: this.pagesdatas.push_type,
                        is_buy: this.pagesdatas.is_buy,
                        paytype: this.pagesdatas.paytype,
                        paytime: this.pagesdatas.paytime,
                        putintime: this.pagesdatas.putintime,
                        export: '1'
                    }
                })
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
        searchFormEvent(data) {
            console.log('高级筛选-开始检索返回数据(页面):' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            var getpaytime = null
            if (data.paytime.start == '') {
                getpaytime = ''
            } else {
                if (data.paytime.end == '') {
                    getpaytime = data.paytime.start + ' ~ ' + (data.paytime.start + 86400 - 1)
                } else {
                    getpaytime = data.paytime.start + ' ~ ' + (data.paytime.end + 86400 - 1)
                }
            }
            var getputintime = null
            if (data.putintime.start == '') {
                getputintime = ''
            } else {
                if (data.putintime.end == '') {
                    getputintime = data.putintime.start + ' ~ ' + (data.putintime.start + 86400 - 1)
                } else {
                    getputintime = data.putintime.start + ' ~ ' + (data.putintime.end + 86400 - 1)
                }
            }
            this.pagesdatas.paytime = getpaytime
            this.pagesdatas.putintime = getputintime

            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/lorderRanking', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: data.client,
                        name: data.name,
                        push_type: data.push_type,
                        is_buy: data.is_buy,
                        paytype: data.paytype,
                        paytime: getpaytime,
                        putintime: getputintime,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result, 1)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            var getaddtime = null
            if (data.paytime.start == '') {
                getaddtime = ''
            } else {
                if (data.paytime.end == '') {
                    getaddtime = data.paytime.start + ' ~ ' + (data.paytime.start + 86400 - 1)
                } else {
                    getaddtime = data.paytime.start + ' ~ ' + (data.paytime.end + 86400 - 1)
                }
            }
            this.pagesdatas.paytime = getaddtime
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/lorderRanking', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {
                        client: data.client,
                        push_type: data.push_type,
                        paytime: getaddtime,
                        page: 1,
                        page_size: this.PageSize
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result, 1)
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata, flag) {
            var newdata = successdata.data
            // console.log(newdata);
            for (var i = 0; i < newdata.length; i++) {
                //转换添加时间
                // if (newdata[i].addtime) {
                //   if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                //     newdata[i].addtime = "无添加时间";
                //   } else {
                //     newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime);
                //   }
                // } else {
                //   newdata[i].addtime = "无添加时间";
                // }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            // this.totalCount = successdata.total;
            // this.currentpage = successdata.current_page; //当前页面页数
            // this.lastpage = successdata.last_page;
            this.loading = false

            // 数据小于当前页数时判断
            if (newdata.length < this.PageSize) {
                if (newdata.length != 0 && this.currentPage == 1) {
                    this.totalCount = this.PageSize - 1
                } else {
                    if (newdata.length == 0) {
                        // 按照常理：为0会减1页，由于双向绑定问题一直请求服务器，因此先注释，待后续优化
                        // this.totalCount = this.PageSize * (this.currentPage - 1)
                        this.totalCount = this.PageSize * this.currentPage
                    } else {
                        this.totalCount = this.PageSize * this.currentPage
                        this.lastpage = this.currentPage
                    }
                }
            } else {
                if (this.totalCount < this.PageSize) {
                    // 重设置-初始值
                    this.totalCount = 1000
                    this.Layout = 'sizes, prev, pager, next, jumper'
                } else {
                    if (flag == 1) {
                        this.currentPage = 1
                    }
                }
            }
        },

        // 投放场景-下拉数据获取
        getPushTypeSelectEvent() {
            axios
                .get('/admin/Equipment/getPushTypeSelect', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    let configinfo = response.data.result
                    var alldatas = Array()
                    var num = 0
                    alldatas[num] = { value: num, label: '全部' }
                    for (var key in configinfo) {
                        num = num + 1
                        alldatas[num] = {
                            value: configinfo[key].id,
                            label: configinfo[key].sort_key
                        }
                    }
                    //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
                    this.maintwoforminfo.selectleft.select[0].options = alldatas
                    this.myselect.select[0].options = alldatas
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            //投放场景
            this.getPushTypeSelectEvent()

            //加载loading
            this.loading = true

             // 临时需求：20230315 - 默认今日时间段
             let toData = new Date(new Date().toLocaleDateString()).getTime()
            this.maintwoforminfo.selectleft.select[1].value = {
                valuestart: toData,
                valueend: ''
            }

            // 定义
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize,
                paytime: toData / 1000 + ' ~ ' + (toData / 1000 + 24 * 60 * 60 - 1)
            }
            
            //通过Ajax向后台获取数据
            axios
                .get('/admin/Statistics/lorderRanking', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: this.pagesdatas
                })
                .then((response) => {
                    // 修改-导出显示
                    if (response.data.result.isDisplayExport == 1) {
                        this.daochudisabled = 0 // 0显示导出，1隐藏导出
                    } else {
                        this.daochudisabled = 1 // 0显示导出，1隐藏导出
                    }

                    // 资金方标识
                    this.usertype = response.data.result.userType

                    // 判断table列表中的标题
                    if (response.data.result.userType == 1) {
                        // 隐藏搜索相关字段
                        this.maintwoforminfo.selectleft.select.splice(0, 1)
                        // 隐藏高级检索相关字段
                        this.maintwoiconshow = false

                        // 是资金方
                        this.mainthreetabletitle = [
                            {
                                fixedstatu: false,
                                prop: 'client',
                                label: '设备编号',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'shop_code_name',
                                label: '代理商',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'push_type_name',
                                label: '投放场景',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'order_total',
                                label: '订单金额(扣除退款)',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'order_num',
                                label: '订单数量',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'price',
                                label: '客单价',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'newuser',
                                label: '新用户增长数',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'fail_num',
                                label: '故障次数',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'refund',
                                label: '退款率',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'address',
                                label: '投放位置',
                                width: ''
                            }
                        ]
                    } else {
                        this.mainthreetabletitle = [
                            {
                                fixedstatu: false,
                                prop: 'client',
                                label: '设备编号',
                                width: '100'
                            },
                            {
                                fixedstatu: false,
                                prop: 'shop_code_name',
                                label: '代理商',
                                width: '200'
                            },
                            // {
                            //   fixedstatu: false,
                            //   prop: "is_buy_name",
                            //   label: "投放方式",
                            //   width: "",
                            // },
                            {
                                fixedstatu: false,
                                prop: 'push_type_name',
                                label: '投放场景',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'order_total',
                                label: '订单金额(扣除退款)',
                                width: '180'
                            },
                            {
                                fixedstatu: false,
                                prop: 'order_num',
                                label: '订单数量',
                                width: '120'
                            },
                            {
                                fixedstatu: false,
                                prop: 'price',
                                label: '客单价',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'newuser',
                                label: '新用户增长数',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'fail_num',
                                label: '故障次数',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'refund',
                                label: '退款率',
                                width: ''
                            },
                            {
                                fixedstatu: false,
                                prop: 'address',
                                label: '投放位置',
                                width: '250'
                            },
                            {
                                fixedstatu: false,
                                prop: 'addtimeStr',
                                label: '投放时间',
                                width: '120'
                            }
                        ]
                    }

                    // 获取数据
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}
</style>
