<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <!-- <el-button type="warning" plain @click="exportDatas()">导出</el-button> -->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'status' || mytabletitle.prop === 'receive_time' || mytabletitle.prop === 'pay_time'">
                                <div v-if="mytabletitle.prop === 'status'">
                                    <span>{{ dict_options.member_benefits.use_status[scope.row.status] }}</span>
                                </div>
                                <!-- 领取时间 -->
                                <div v-if="mytabletitle.prop === 'receive_time'">
                                    <span>{{ scope.row.receive_time != 0 ? this.$utils.formatDate(scope.row.receive_time) : '' }}</span>
                                </div>
                                <!-- 操作时间 -->
                                <div v-if="mytabletitle.prop === 'pay_time'">
                                    <span>{{ scope.row.pay_time != 0 ? this.$utils.formatDate(scope.row.pay_time) : '' }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import dict from '@/assets/js/dict.js'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vmemberDvcCount',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '权益会员'
                },
                {
                    name: '核销记录'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                selecttopisshow: true, //筛选查询-左侧字+select单框||select时间
                selecttop: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '180px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '平台来源：',
                            placeholder: '全部',
                            name: 'thirdparty',
                            value: '1', //显示值，初始状况，显示值与默认值需相同
                            valueisshow: true,
                            valuedefault: '1', //设置默认值
                            options: componentProperties.get('thirdpartyoption').options
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '140px',
                    selectinterval: '10px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '使用状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    value: '',
                                    label: '全部'
                                },
                                ...Object.keys(dict.member_benefits.use_status).map((n) => {
                                    return {
                                        label: dict.member_benefits.use_status[n],
                                        value: n
                                    }
                                })
                            ]
                        },
                        {
                            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                            ismust: false,
                            title: '操作时间：',
                            placeholder: {
                                placeholderstart: '开始时间',
                                placeholderend: '结束时间'
                            },
                            name: 'pay_time',
                            value: {
                                valuestart: ref(''),
                                valueend: ref(''),
                                endstatus: true
                            },
                            rangeseparator: '至', //分隔符
                            selectmaxwidth: '100%', //时间段-最大宽度
                            marginright: '0' //与右侧距离
                        }
                    ]
                }
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 27, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'code',
                    label: '商家券编码',
                    width: '200'
                },
                {
                    fixedstatu: false,
                    prop: 'client',
                    label: '绑定设备',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'uid',
                    label: '领取用户ID',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'receive_time',
                    label: '领取时间',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '使用状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'pay_time',
                    label: '操作时间',
                    width: ''
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,

            dict_options: dict,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    methods: {
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                keywords: this.pagesdatas.keywords,
                pay_time: this.pagesdatas.pay_time,
                status: this.pagesdatas.status,
                page: this.currentPage,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                keywords: this.pagesdatas.keywords,
                pay_time: this.pagesdatas.pay_time,
                status: this.pagesdatas.status,
                page: this.currentPage,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //导出数据
        exportDatas() {
            //通过Ajax向后台获取数据/admin/config/info
            this.pagesdatas = {
                keywords: this.pagesdatas.keywords,
                pay_time: this.pagesdatas.pay_time,
                status: this.pagesdatas.status,
                export: 1
            }

            this.postAxiosEvent(this.pagesdatas, 1)
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log('点击查询获取的data数组：' + JSON.stringify(data))

            var getpay_time = null
            if (data.pay_time.start == '') {
                getpay_time = ''
            } else {
                if (data.pay_time.end == '') {
                    getpay_time = data.pay_time.start + ' ~ ' + (data.pay_time.start + 86400 - 1)
                } else {
                    getpay_time = data.pay_time.start + ' ~ ' + (data.pay_time.end + 86400 - 1)
                }
            }

            this.pagesdatas = {
                keywords: data.keywords,
                pay_time: getpay_time,
                status: data.status,
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        // 接口
        postAxiosEvent(data, index) {
            if (!index) this.loading = true
            axios
                .post('/admin/vip/couponReadIndex', data, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    if (!index) {
                        this.myaxiosfunc(response.data.result)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize
            }

            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.tool-tip {
    max-width: 400px;
}
</style>
