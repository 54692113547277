<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop" :myselectcityisshow="myselectcityisshow"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"
      v-on:selectdatainfo="selectDatainfoEvent" v-on:selectdatainfogao="selectDatainfoGaoEvent"
      v-on:resetdatainfo="resetdatainfoEvent" v-on:resetdatainfogao="resetdatainfogaoEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px 20px 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">
            数据列表 &nbsp; &nbsp; &nbsp; &nbsp; 总计：<span style="color: red">¥ {{ total ? total : 0 }}</span>
            &nbsp; &nbsp; 收入：<span style="color: red">¥ {{ is_in ? is_in : 0 }}</span>
            &nbsp; &nbsp; 支出：<span style="color: red">¥ {{ is_out ? is_out : 0 }}</span>
          </div>

          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop == 'income' || mytabletitle.prop == 'outlay'
              ">
                <!-- 收入-渲染数据 -->
                <div v-if="mytabletitle.prop === 'income'">
                  <div class="data-box">
                    <div class="data-box-one">
                      + {{ (scope.row.income / 100).toFixed(2) }} (
                      {{ scope.row.income_num }} 笔 )
                    </div>
                    <div class="data-box-wx">
                      微&nbsp;&nbsp;&nbsp;信:&nbsp;&nbsp;￥{{
                          (scope.row.wx_income / 100).toFixed(2)
                      }}&nbsp;&nbsp; ( {{ scope.row.wx_income_num }} 笔 )
                    </div>
                    <div class="data-box-wx">
                      余&nbsp;&nbsp;&nbsp;额:&nbsp;&nbsp;￥{{
                          (scope.row.cash_income / 100).toFixed(2)
                      }}&nbsp;&nbsp; ( {{ scope.row.cash_income_num }} 笔 )
                    </div>
                    <div class="data-box-zfb">
                      支付宝:&nbsp;&nbsp;￥{{
                          (scope.row.ali_income / 100).toFixed(2)
                      }}&nbsp;&nbsp; ( {{ scope.row.ali_income_num }} 笔 )
                    </div>
                  </div>
                </div>
                <!-- 支出-渲染数据 -->
                <div v-if="mytabletitle.prop === 'outlay'">
                  <div class="data-box">
                    <div class="data-out-one">
                      - {{ (scope.row.outlay / 100).toFixed(2) }} (
                      {{ scope.row.outlay_num }} 笔 )
                    </div>
                    <div class="data-out-wx">
                      微&nbsp;&nbsp;&nbsp;信:&nbsp;&nbsp;￥{{
                          (scope.row.wx_outlay / 100).toFixed(2)
                      }}&nbsp;&nbsp; ( {{ scope.row.wx_outlay_num }} 笔 )
                    </div>
                    <div class="data-out-wx">
                      余&nbsp;&nbsp;&nbsp;额:&nbsp;&nbsp;￥{{
                          (scope.row.cash_outlay / 100).toFixed(2)
                      }}&nbsp;&nbsp; ( {{ scope.row.cash_outlay_num }} 笔 )
                    </div>
                    <div class="data-out-zfb">
                      支付宝:&nbsp;&nbsp;￥{{
                          (scope.row.ali_outlay / 100).toFixed(2)
                      }}&nbsp;&nbsp; ( {{ scope.row.ali_outlay_num }} 笔 )
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-财务-入账单-->
                <div v-if="mainthreetableope == 30">
                  <el-button type="primary" size="mini" @click="routeraddrClick('/jiaoyimingxi', scope.row)"
                    v-if="this.mypowerlimits.dsfptdzdmx">查看详情
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vstatementplatform",
  data() {
    return {
      mainonebread: [
        {
          name: "财务",
        },
        {
          name: "资金对账",
        },
        {
          name: "平台对账单",
          path: "/postingdetails",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: "时间范围：",
              placeholder: {
                placeholderstart: "请选择开始时间",
                placeholderend: "请选择结束时间",
              },
              name: "paytime",
              value: {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
              },
              rangeseparator: "至", //分隔符
              selectmaxwidth: "100%", //时间段-最大宽度
              marginright: "0", //与右侧距离
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 30, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 150, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "date",
          label: "日期",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "income",
          label: "收入",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "outlay",
          label: "支出",
          width: "",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭
      isshowpages: 1, //判断是否显示分页按钮-1表示隐藏，0表示显示

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 30,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, rowdata) {
      if (rowdata) {
        // 跳转-平台交易明细
        if (routeraddr == "/jiaoyimingxi") {
          // 获取当前行这一天的时间戳 13位
          let toData = new Date(new Date(rowdata.date).toLocaleDateString()).getTime() / 1000;
          let todayStart = toData; //开始时间
          let todayEnd = todayStart + 24 * 60 * 60 - 1; //结束时间

          this.$router
            .push({
              path: routeraddr,
              query: {
                addtime: todayStart + ' ~ ' + todayEnd,
                thirdparty: 1,
              },
            })
            .catch((error) => error);
        }
      } else {
        //仅跳转
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    // 导出数据
    exportDatas() {
      axios
        .post(
          "/admin/cashflow/platBill",
          {
            paytime: this.pagesdatas.paytime,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message)
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;

      //判断-时间范围-段状态
      var getpaytime = null;
      if (data.paytime.start == "") {
        getpaytime = "";
      } else {
        if (data.paytime.end == "") {
          getpaytime =
            data.paytime.start + " ~ " + (data.paytime.start + 86400 - 1);
        } else {
          getpaytime =
            data.paytime.start + " ~ " + (data.paytime.end + 86400 - 1);
        }
      }
      this.pagesdatas.paytime = getpaytime;

      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/cashflow/platBill",
          {
            paytime: getpaytime,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.list;
      console.log('当前页面的data:', newdata)
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;

      this.total = successdata.total / 100;
      this.is_in = successdata.is_in / 100;
      this.is_out = successdata.is_out / 100;
    },

    // 获取本月开始时间戳~今日结束时间戳
    getdefaultmonth() {
      let now = new Date();
      // 获取今天的时间戳 13位
      let toData = new Date(new Date().toLocaleDateString()).getTime() / 1000;
      //今天结束时间
      let todayEnd = toData + 24 * 60 * 60 - 1;
      //当前年
      let nowYear = now.getFullYear();
      //当前月
      let nowMonth = now.getMonth();
      //本月开始时间戳
      let firstDay = new Date(nowYear, nowMonth, 1).getTime() / 1000;
      return firstDay + " ~ " + todayEnd;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/cashflow/platBill",
          {
            paytime: this.getdefaultmonth(),
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.data-box {
  display: flex;
  flex-direction: column;
}

.data-box div {
  height: 30px;
  line-height: 30px;
}

.data-box-one {
  color: #66c139;
}

.data-out-one {
  color: #f56c6c;
}
</style>