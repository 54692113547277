<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">新粉优惠券</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row>
                        <!-- 占位符 -->
                        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!-- 主内容 -->
                        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="14" :xl="11">
                            <div style="width: 100%; margin-top: 10px">
                                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="200px" class="demo-ruleForm">
                                    <div>
                                        <div class="title-box" style="margin-top: 20px">
                                            <div><span>关联优惠券</span></div>
                                            <div><span style="font-size: 12px; color: #999999">最大添加3个，不允许券一、二、三是同一个</span></div>
                                        </div>
                                        <div class="fangfa-box">
                                            <el-form-item v-for="(item, index) in ruleForm.coupon" :key="index" :label="'关联优惠券' + exchange[index + 1] + '：'" :prop="'coupon.' + index + '.value'" :rules="rules.value">
                                                <div class="title-box">
                                                    <div>
                                                        <el-select v-model="item.value" placeholder="请选择优惠券" size="large" filterable @change="(data) => changeEvent(data, index)">
                                                            <el-option v-for="(jtem, jndex) in coupon_options" :key="jndex" :label="jtem.label" :value="jtem.value" :disabled="disabled_array.indexOf(jtem.value) > -1 ? true : false" />
                                                        </el-select>
                                                    </div>
                                                    <div v-if="index == 0"><el-button type="primary" @click="addEvent">添加</el-button></div>
                                                    <div v-else><el-button type="danger" @click="delEvent(index)">删除</el-button></div>
                                                </div>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div class="fangfa-box-top">
                                        <div>
                                            <span>新粉大礼包引导页</span>
                                        </div>
                                        <div class="fangfa-box">
                                            <el-form-item label='"去视频号"操作文案展示：' prop="go">
                                                <el-input v-model="ruleForm.go" clearable maxlength="10" show-word-limit></el-input>
                                            </el-form-item>
                                            <el-form-item label="背景图片：" prop="image">
                                                <mainimgesup v-model="ruleForm.image" :myimgesup="myimgesup" :myimgesupisshow="myimgesupisshow" :filelist="ruleForm.imageList" v-on:getuploadfile="(data) => getUploadFileEvent(data)" v-on:deluploadfile="(data) => deluploadfileEvent(data)"> </mainimgesup>
                                            </el-form-item>
                                            <el-form-item label="跳转位置：" prop="type">
                                                <el-radio-group v-model="ruleForm.type">
                                                    <el-radio label="1" size="large">视频号主页</el-radio>
                                                    <el-radio label="2" size="large">视频号直播间</el-radio>
                                                    <el-radio label="3" size="large">视频号视频</el-radio>
                                                    <el-radio label="4" size="large">视频号活动页</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="视频号ID：" prop="id">
                                                <el-input v-model="ruleForm.id" clearable maxlength="50" show-word-limit></el-input>
                                            </el-form-item>
                                            <el-form-item label="视频ID：" prop="id" v-if="ruleForm.type == 3">
                                                <el-input v-model="ruleForm.video_id" clearable maxlength="240" show-word-limit></el-input>
                                            </el-form-item>
                                            <el-form-item label="活动ID：" prop="id" v-if="ruleForm.type == 4">
                                                <el-input v-model="ruleForm.video_id" clearable maxlength="240" show-word-limit></el-input>
                                            </el-form-item>
                                            <div style="margin-left: 20px"><el-divider /></div>
                                            <el-form-item label='"跳过"操作文案展示：' prop="jump">
                                                <el-input v-model="ruleForm.jump" clearable maxlength="10" show-word-limit></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>

                                    <el-form-item class="fangfa-box-top">
                                        <el-button type="primary" @click="submitForm('ruleForm', 'new_fan_coupon', ruleForm)" style="width: 40%; margin-right: 10px" :disabled="buttondisable">保存 </el-button>
                                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-col>
                        <!-- 占位符 -->
                        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="6" :xl="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone';
import mainimgesup from '@/components/Mainimgesup.vue';
import { ElMessage } from 'element-plus';
import axios from 'axios';

export default {
    name: 'MakeMoneySet',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '运营',
                },
                {
                    name: '企业微信',
                },
                {
                    name: '新粉优惠券',
                },
            ],

            myimgesup: {
                uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/file', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '点击上传', //button-按钮文字
                tips: 'png、jpg、jpeg格式', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 101, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 2000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },

            //form表单-赚钱页配置
            ruleForm: {
                coupon: [
                    {
                        value: '',
                    },
                ],
                go: '',
                type: '2',
                id: '',
                video_id: '',
                jump: '',
                image: '',
                imageList: [],
            },
            //form表单-必填规则-赚钱页配置
            rules: {
                value: [
                    {
                        required: true,
                        message: '优惠券为必选项',
                        trigger: 'blur',
                    },
                ],
                go: [
                    {
                        required: true,
                        message: '去视频号操作文案不能为空',
                        trigger: 'blur',
                    },
                ],
                type: [
                    {
                        required: true,
                        message: '跳转位置为必选项',
                        trigger: 'blur',
                    },
                ],
                id: [
                    {
                        required: true,
                        message: '视频号ID为必填项',
                        trigger: 'blur',
                    },
                ],
                video_id: [
                    {
                        required: true,
                        message: '此ID为必填项',
                        trigger: 'blur',
                    },
                ],
                jump: [
                    {
                        required: true,
                        message: '跳过操作文案不能为空',
                        trigger: 'blur',
                    },
                ],
            },

            // 转换文字
            exchange: { 1: '一', 2: '二', 3: '三' },
            // 优惠券下拉
            coupon_options: [],
            // 禁选
            disabled_array: [],

            // 防抖
            buttondisable: false,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token[此页面中的该字段，可有可无，具体看该页面是否需要使用token
        };
    },
    methods: {
        // 添加
        addEvent() {
            if (this.ruleForm.coupon.length >= 3) {
                ElMessage({
                    type: 'warning',
                    message: '最多添加3条',
                });
            } else {
                this.ruleForm.coupon.push({ value: '' });
            }
        },
        // 删除
        delEvent(index) {
            this.disabled_array.splice(index, 1);
            this.ruleForm.coupon.splice(index, 1);
        },
        // 选中优惠券-下拉
        changeEvent(data, index) {
            if (this.disabled_array[index]) {
                this.disabled_array[index] = data;
            } else {
                this.disabled_array.push(data);
            }
        },

        //from表单-提交表单
        submitForm(formName, keyname, formdata) {
            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
            this.buttondisable = true;
            // console.log("当前获取的formdata：" + JSON.stringify(formdata));
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let getdatas = {
                        coupon: formdata.coupon.map((n) => {
                            return n.value;
                        }),
                        go: formdata.go,
                        type: formdata.type,
                        id: formdata.id,
                        video_id: formdata.video_id,
                        jump: formdata.jump,
                        image: formdata.image,  
                    };

                    axios
                        .post(
                            '/admin/config/update',
                            {
                                key_name: keyname,
                                contents: getdatas,
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token'),
                                },
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '保存成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                        this.buttondisable = false;
                                    },
                                });
                            } else {
                                // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                                this.buttondisable = false;
                            }
                        })
                        .catch(function () {
                            // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                            this.buttondisable = false;
                        });
                } else {
                    // 控制提交保存按钮-防止多次点击-true禁用；false-启用
                    this.buttondisable = false;
                    return false;
                }
            });
        },
        //from表单-重置表单-(常规表单)
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.ruleForm.coupon.map((n) => {
                return (n.value = '');
            });
        },

        // 获取优惠券下拉
        async getCouponOptions() {
            return await axios
                .post(
                    '/admin/Coupon/index',
                    {
                        status: ['-1', '1'],
                        page: 1,
                        page_size: 10000,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token'),
                        },
                    }
                )
                .then((response) => {
                    let getdatas = response.data.result.data;
                    this.coupon_options = getdatas.map((n) => {
                        return { label: n.title, value: n.id };
                    });

                    return true;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        getUploadFileEvent(data) {
            this.ruleForm.image = data.url
        },

        deluploadfileEvent(data) {
            this.ruleForm.image = ''
            this.ruleForm.imageList = []
        },

        // 获取当前页面info
        getCouponInfo() {
            axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'new_fan_coupon',
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token'),
                        },
                    }
                )
                .then((response) => {
                    let formdata = response.data.result;
                    this.ruleForm = {
                        coupon: formdata.coupon.map((n) => {
                            return { value: parseInt(n) };
                        }),
                        go: formdata.go,
                        type: formdata.type,
                        id: formdata.id,
                        video_id: formdata.video_id,
                        jump: formdata.jump,
                        image: formdata.image,
                        imageList: [
                            {
                                url: formdata.image,
                                name: '背景图片'
                            }
                        ]
                    };

                    this.disabled_array = formdata.coupon.map((n) => {
                        return parseInt(n);
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false;
            this.$router.push('/').catch((error) => error);
        } else {
            var p1 = this.getCouponOptions();

            Promise.all([p1])
                .then(() => {
                    this.getCouponInfo();
                })
                .catch((error) => {
                    console.log('error:失败:', error);
                });
        }
    },
    components: {
        mainone,
        mainimgesup
    },
};
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}

.title-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 20px;
}

.fangfa-box {
    border: 1px dashed var(--el-border-color-base);
    padding: 20px 20px 0 0;
    border-radius: 4px;
    margin-top: 20px;
}

.fangfa-box-top {
    margin-top: 20px;
}

.date-time-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    color: #606266;
    font-size: 14px;
    font-family: 'PingFang SC';
}
</style>
