<template>
  <mainone :mainonebread="mainonebread"></mainone>
  <!-- 内容 -->
  <div class="borde7 bg_glay1">
    <!-- 标题 -->
    <div class="mybox-title">
      <span>首页导航</span>
    </div>
    <div class="bg_white" style="padding-top: 30px; height: auto; overflow: hidden">
      <el-form ref="formdata" :model="formdata" :rules="rules" label-width="170px">
        <el-form-item label="选择样式:" required="true">
          <el-radio-group v-model="this.formdata.style">
            <el-radio label="1">图标+文字</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="每行数量:" required="true">
          <el-radio-group v-model="this.formdata.line_num">
            <el-radio label="4">4个</el-radio> &nbsp;
            <el-radio label="5">5个</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="小程序端是否显示:" required="true">
          <el-radio-group v-model="this.formdata.is_show">
            <el-radio label="1">是</el-radio> &nbsp;
            <el-radio label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="首页导航图标:" required="true">
          <div class="home-box">
            <div v-if="mypowerlimits.sydh_bc">
              <el-button type="primary" plain style="width: 200px" @click="addIcon()">添加导航图标</el-button>
            </div>
            <div class="home-box-info" v-for="(item, i) in this.formdata.navigation_list" :key="i">
              <div class="home-box-info-left">
                <!-- 左侧image图片-icon -->
                <div class="home-box-info-image">
                  <el-form-item label-width="0px" :prop="'navigation_list.' + i + '.icon'" :rules="rules.icon">
                    <mainimgesup :myimgesup="myimgesup" :myimgesupisshow="myimgesupisshow"
                      :filelist="item.icon != '' ? [{ url: item.icon, name: 'icon' }] : []"
                      v-on:getuploadfile="(data) => getUploadFileEvent(data, i)"
                      v-on:deluploadfile="(data) => deluploadfileEvent(data, i)">
                    </mainimgesup>
                  </el-form-item>
                </div>
                <!-- 右侧填写信息 -->
                <div class="home-box-info-input">
                  <!-- 跳转方式 -->
                  <div>
                    <el-form-item label="跳转方式：" label-width="140px" :prop="'navigation_list.' + i + '.jump_way'"
                      :rules="rules.jump_way">
                      <el-radio-group v-model="item.jump_way">
                        <el-radio :label="1">站内链接</el-radio>
                        <el-radio :label="2">站外小程序</el-radio>
                        <el-radio :label="3">h5链接/站外网页</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </div>
                  <div>
                    <!-- 站内链接 -->
                    <div v-if="item.jump_way == 1">
                      <el-form-item label="站内链接：" label-width="140px" :prop="'navigation_list.' + i + '.mini_path'"
                        :rules="rules.mini_path">
                        <el-select v-model="item.mini_path" placeholder="请选择" style="width: 100%"
                          @change="changeSelectEvent">
                          <el-option :label="gettypeid.title" :value="gettypeid.path"
                            v-for="(gettypeid, index) in mywebview" :key="index"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <!-- 站外小程序 -->
                    <div v-if="item.jump_way == 2">
                      <el-form-item label="小程序APPID：" label-width="140px" :prop="'navigation_list.' + i + '.outer_path'"
                        :rules="rules.outer_path">
                        <el-select v-model="item.outer_path" placeholder="请选择" style="width: 100%">
                          <el-option :label="gettypeid.title" :value="gettypeid.appid"
                            v-for="(gettypeid, index) in myouter" :key="index"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="小程序路径：" label-width="140px" style="margin-top: 20px;"
                        :prop="'navigation_list.' + i + '.outer_url'" :rules="rules.outer_url">
                        <el-input v-model="item.outer_url" clearable placeholder="请填写小程序路径"></el-input>
                        <div style="height: 20px; line-height: 20px">
                          <span style="font-size: 12px; color: #999999">小程序地址为空表示默认跳转小程序首页</span>
                        </div>
                      </el-form-item>
                    </div>
                    <!-- h5链接/站外网页 -->
                    <div v-if="item.jump_way == 3">
                      <el-form-item label="跳转路径：" label-width="140px" :prop="'navigation_list.' + i + '.wechat_path'"
                        :rules="rules.wechat_path">
                        <el-input v-model="item.wechat_path" clearable placeholder="请填写以http://或https://开头的跳转路径">
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- 导航标题 -->
                  <div style="margin-top: 20px;">
                    <el-form-item label="导航标题：" label-width="140px" :prop="'navigation_list.' + i + '.title'"
                      :rules="rules.title">
                      <el-input placeholder="请输入标题" v-model="item.title" clearable maxlength="5" show-word-limit>
                      </el-input>
                    </el-form-item>
                  </div>
                  <!-- 排序 -->
                  <div style="margin-top: 20px;">
                    <el-form-item label="导航排序：" label-width="140px" :prop="'navigation_list.' + i + '.sort'"
                      :rules="rules.sort">
                      <el-input placeholder="数字越大排序越高" v-model="item.sort" clearable></el-input>
                    </el-form-item>
                  </div>
                  <!-- 状态显示 -->
                  <div style="margin-top: 20px;">
                    <el-form-item label="状态显示：" label-width="140px" :prop="'navigation_list.' + i + '.status'"
                      :rules="rules.status">
                      <el-switch v-model="item.status" :inactive-value="-1" :active-value="1"></el-switch>
                    </el-form-item>
                  </div>
                  <!-- 平台显示 -->
                  <div style="margin-top: 20px;">
                    <el-form-item label="平台显示：" label-width="140px" :prop="'navigation_list.' + i + '.platform'"
                      :rules="rules.platform">
                      <el-checkbox-group v-model="item.platform">
                        <el-checkbox label="1">微信自助版</el-checkbox>
                        <el-checkbox label="2">支付宝自助版</el-checkbox>
                        <el-checkbox label="3">微信企业版</el-checkbox>
                        <el-checkbox label="4">微信家庭版</el-checkbox>
                        <el-checkbox label="5">支付宝企业版</el-checkbox>
                        <el-checkbox label="6">支付宝家庭版</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </div>

                </div>
              </div>
              <!-- <div v-if="mypowerlimits.sydh_bc">
                <el-button type="danger" @click="delIcdon(i)">删除</el-button>
              </div> -->
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="mypowerlimits.sydh_bc">
          <div style="margin-top: 30px">
            <el-button type="primary" @click="saveIcon('formdata')" style="width: 20%">保存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import { defineComponent } from "vue";
import mainimgesup from "@/components/Mainimgesup.vue";
import axios from "axios";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "vhome",
  data() {
    return {
      mainonebread: [
        {
          name: "店铺",
        },
        {
          name: "装修",
        },
        {
          name: "首页导航",
          path: "/sethome",
        },
      ],

      // form表单
      formdata: {
        style: "1",
        is_show: "1",
        line_num: "4",
        navigation_list: [],
      },
      // form表单-校验
      rules: {
        icon: [
          {
            required: true,
            message: "导航图标不能为空！",
            trigger: "blur",
          },
        ],
        jump_way: [
          {
            required: true,
            message: "跳转方式不能为空！",
            trigger: "blur",
          },
        ],
        mini_path: [
          {
            required: true,
            message: "站内链接不能为空！",
            trigger: "blur",
          },
        ],
        outer_path: [
          {
            required: true,
            message: "小程序APPID不能为空！",
            trigger: "blur",
          },
        ],
        outer_url: [
          {
            required: false,
            message: "小程序路径不能为空！",
            trigger: "blur",
          },
        ],
        wechat_path: [
          {
            required: true,
            message: "跳转路径不能为空！",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "导航标题不能为空！",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "导航排序不能为空！",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "状态显示不能为空！",
            trigger: "blur",
          },
        ],
        platform: [
          {
            required: true,
            message: "平台显示不能为空！",
            trigger: "blur",
          },
        ]
      },

      // 上传icon
      myimgesupisshow: true,
      myimgesup: {
        uploadsize: 2, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        listtype: "picture", //list展示类型，text/picture/picture-card；默认不填为text
        ismust: true, //是否必填，true表示必填
        action: "/admin/upload/file", //upload上传API地址
        headers: { Authentication: localStorage.getItem("token") }, //upload上传headers
        title: "上传图片", //button-按钮文字
        tips: "建议尺寸：84*84像素，png、jpg、jpeg格式", //提示&建议文字
        limit: "1", //上传数量限制
        target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
        rules: {
          images: {
            image_width: 0, //图片宽度
            image_height: 0, //图片高度
            size: 20000, //文件大小（单位kb）
            type: ["image/jpeg", "image/jpg", "image/png"], //文件格式
            typechart: "jpg/jpeg/png", //文件格式提示规则
          },
        },
      },

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  methods: {
    // 添加-导航图标
    addIcon() {
      this.formdata.navigation_list.unshift({
        icon: "",
        jump_way: 1,
        mini_path: '',
        outer_path: '',
        outer_url: '',
        wechat_path: '',
        title: "",
        sort: "0",
        status: 1,
        platform: ['1']
      });
    },
    // 删除-导航图标
    delIcdon(i) {
      this.formdata.navigation_list.splice(i, 1);
    },
    // 获取上传图片-icon
    getUploadFileEvent(data, i) {
      this.formdata.navigation_list[i].icon = data.url;
      this.$refs['formdata'].validate();
    },
    // 删除上传图片-icon
    deluploadfileEvent(data, i) {
      console.log("当前删除事件-返回的数据：" + JSON.stringify(data));
      this.formdata.navigation_list[i].icon = '';
    },
    // 选中-站内链接-触发事件
    changeSelectEvent(data) {
      console.log('data :>> ', data);
    },

    // 保存设置
    saveIcon(formName) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (getdatas.navigation_list.length > 0) {
            getdatas.navigation_list.forEach(element => {
              // 判断mini_path入库
              if (element.jump_way == 2) {
                element.mini_path = element.outer_url
              }
            });
          }

          axios
            .post(
              "/admin/nav/update",
              {
                style: getdatas.style,
                is_show: getdatas.is_show,
                line_num: getdatas.line_num,
                navigation_list: getdatas.navigation_list,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code == 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          ElMessage({
            type: "warning",
            message: "提交失败！请检查必填项字段",
          });
          return false;
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      // 获取相关配置配置-webview跳转链接
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "mini_path",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.mywebview = response.data.result;
        })
        .catch(function (error) {
          console.log(error);
        });
      // 获取相关配置配置-H5/公众号跳转链接
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "wechat_path",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.mywechat = response.data.result;
        })
        .catch(function (error) {
          console.log(error);
        });
      // 获取相关配置配置-小程序APPID
      axios
        .post(
          "/admin/config/info",
          {
            key_name: "outer_app",
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myouter = response.data.result;
        })
        .catch(function (error) {
          console.log(error);
        });

      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/nav/index",
          {},
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.code == 0) {
            this.formdata = response.data.result;

            if (this.formdata.navigation_list.length > 0) {
              this.formdata.navigation_list.forEach(element => {
                if (element.jump_way == 2) {
                  element.outer_url = element.mini_path
                }
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });


    }
  },
  components: {
    mainone,
    mainimgesup,
  },
});
</script>

<style>
.bg_white {
  background-color: #ffffff;
  width: 100%;
}

.bg_glay1 {
  background-color: #ffffff;
}

.borde7 {
  border: 1px solid;
  border-radius: 5px;
  border-color: #f5f6fb;
}

.title1 {
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
}

.borde2 {
  height: 150px;
  margin-top: 40px;
}

.borde2 {
  height: 150px;
  margin-top: 40px;
}

.left {
  float: left;
  vertical-align: middle;
}

/* 首页导航图标 */
.home-box {
  margin-bottom: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.home-box-info {
  margin: 10px 10px 10px 0;
  display: flex;
  flex-direction: row;
}

.home-box-info-left {
  border: 1px dashed #999999;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.home-box-info-image {
  width: 140px;
}

.home-box-info-input-titlelevel {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.home-box-info-input-titlelevel-title {
  min-width: 200px;
}

.home-box-info-input-titlelevel div {
  margin: 0 10px 10px 10px;
}

.home-box-info-input-info {
  margin: 0 0 20px 20px;
}

.mybox-title {
  width: 100%;
  border-bottom: 1px solid #f4f4f4;
  padding: 20px 0;
  font-size: 14px;
}

.mybox-title span {
  margin-left: 20px;
}
</style>
