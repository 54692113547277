<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div>
            <!--标题-->
            <div class="box"  >
                <div class="box_title"  >
                    <div class="box_title_font">添加</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
                                <!--收款方名称-->
                                <el-form-item label="收款方名称：" prop="enc_true_name">
                                    <el-input v-model="ruleForm.enc_true_name" maxlength="100" placeholder="请输入收款方名称"></el-input>
                                </el-form-item>
                                <!--收款方银行账号-->
                                <el-form-item label="收款方银行账号：" prop="enc_bank_no">
                                    <el-input v-model="ruleForm.enc_bank_no" maxlength="100" placeholder="请输入收款方银行账户"></el-input>
                                </el-form-item>
                                <!--开户银行-->
                                <el-form-item label="开户银行：" prop="bank_code">
                                    <el-input v-model="ruleForm.bank_code" maxlength="100" placeholder="请输入收款方开户行"></el-input>
                                </el-form-item>
                                <!--打款总金额-->
                                <el-form-item label="打款总金额：" prop="total_money">
                                    <el-input v-model="ruleForm.total_money" clearable maxlength="20" placeholder="请输入打款总金额"></el-input>
                                </el-form-item>
                                <!--打款周期-->
                                <el-form-item label="打款周期：" prop="total_num">
                                    <el-row>
                                        <el-col :span="15">
                                            <el-input v-model="ruleForm.total_num" clearable maxlength="12" placeholder="请输入周期数"></el-input>
                                        </el-col>
                                        <el-col :span="1"></el-col>
                                        <el-col :span="8">
                                            <el-select v-model="ruleForm.pay_type" placeholder="请选择">
                                                <el-option v-for="item in payTypeOptions" :key="item.value" :label="item.label" :value="item.value" style="width: 100%">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <!--每期浮动金额-->
                                <el-form-item label="每期浮动金额：" prop="range_money">
                                    <div style="display: flex;flex-direction: row;justify-content: left;align-items: center;flex-wrap: nowrap;">
                                        <div style="flex-grow: 1;">
                                            <el-input v-model="ruleForm.range_money" clearable maxlength="20" placeholder="请输入每期浮动金额"></el-input>
                                        </div>
                                        <div style="width: 20px;margin-left: 10px;">
                                            <el-tooltip effect="dark" placement="top-end">
                                                <i class="el-icon-info" style="cursor: pointer;"></i>
                                                <template #content>
                                                    <span>每期浮动金额的最大限制：打款总金额 / （期数 * 期数）,超出系统自动按每期浮动金额的最大限制计算。</span>
                                                </template>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </el-form-item>
                                <!--开始日期-->
                                <el-form-item label="开始日期：" :required="true">
                                    <el-row>
                                        <el-col :span="11">
                                            <el-form-item prop="startTime">
                                                <el-date-picker
                                                    v-model="ruleForm.startTime"
                                                    type="date"
                                                    placeholder="选择日期"
                                                    :disabled-date="disabledDate"
                                                    style="width: 100%"
                                                    value-format="YYYY-MM-DD"
                                                    @change="datePickerChange"
                                                />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2"></el-col>
                                        <el-col :span="11">
                                            <el-form-item prop="startHours">
                                                <el-time-select
                                                    v-model="ruleForm.startHours"
                                                    :start="timeSelectstart"
                                                    step="01:00"
                                                    end="23:00"
                                                    placeholder="选择时间"
                                                    style="width: 100%"
                                                />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="打款渠道：" prop="pay_line">
                                    <el-radio-group v-model="ruleForm.pay_line">
                                        <el-radio label="1">微信</el-radio>
                                        <el-radio label="2">支付宝</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--button按钮-->
                                <el-form-item style="margin-top: 50px" v-if="this.mypowerlimits.zhsz_tj">
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%">提交</el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone.vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vcorporatetransferbankcardadd',
    data() {
        // 金额效验（可小数）
        var checkInputEvent = (rule, value, callback) => {
            if (!value && value != 0) {
                return callback(new Error('必填项不能为空'))
            }
            if (value.toString().indexOf(' ') != -1) {
                return callback(new Error('值中不能有空格'))
            }

            setTimeout(() => {
                // 判断字符串是否为文字
                if (isNaN(value)) {
                    callback(new Error('请输入数字'))
                } else {
                    let result = value.toString().indexOf('.')
                    if (result != -1) {
                        let getdata = value.toString().split('.')
                        if (getdata[getdata.length - 1].length > 2) {
                            callback(new Error('请保留小数点后两位'))
                        }
                    }

                    if (value < 0) {
                        callback(new Error('值需大于0'))
                    } else {
                        callback()
                    }
                }
            }, 500)
        }
        return {
            mainonebread: [
                {
                    name: '财务'
                },
                {
                    name: '提现管理'
                },
                {
                    name: '银行打款',
                    path: '/corporatetransferbankcardlist'
                },
                {
                    name: '添加'
                }
            ], // 当前页-【面包屑导航】

            //form表单数据
            ruleForm: {
                enc_true_name: '',
                enc_bank_no: '',
                bank_code: '',
                total_money: '',
                total_num: '',
                pay_type: '1',
                range_money: '',
                startTime: '',
                startHours: '',
                pay_line: '1'
            },

            rules: {
                enc_true_name: [
                    {
                        required: true,
                        message: '收款方名称为必填项',
                        trigger: 'blur'
                    }
                ],
                enc_bank_no: [
                    {
                        required: true,
                        message: '收款方银行账号为必填项',
                        trigger: 'blur'
                    }
                ],
                bank_code: [
                    {
                        required: true,
                        message: '开户银行为必填项',
                        trigger: 'blur'
                    }
                ],
                total_money: [
                    {
                        required: true,
                        message: '打款总金额为必填项',
                        trigger: 'blur'
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'change'
                    }
                ],
                total_num: [
                    {
                        required: true,
                        message: '打款周期为必填项',
                        trigger: 'change'
                    }
                ],
                range_money: [
                    {
                        required: true,
                        message: '每期浮动金额为必填项',
                        trigger: 'change'
                    },
                    {
                        required: true,
                        validator: checkInputEvent,
                        trigger: 'change'
                    }
                ],
                startTime: [
                    {
                        required: true,
                        message: '开始日期为必选项',
                        trigger: 'change'
                    }
                ],
                startHours: [
                    {
                        required: true,
                        message: '开始时间为必选项',
                        trigger: 'change'
                    }
                ],
                pay_line: [
                    {
                        required: true,
                        message: '打款渠道为必选项',
                        trigger: 'change'
                    }
                ]
            }, //form表单填写规则-前端判断是否必填

            payTypeOptions: [
                {
                    value: '1',
                    label: '日'
                },
                {
                    value: '2',
                    label: '周'
                },
                {
                    value: '3',
                    label: '月'
                },
                {
                    value: '4',
                    label: '季'
                },
                {
                    value: '5',
                    label: '年'
                }
            ],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            timeSelectstart: '00:00' // 时间选择，起始时间
        }
    },
    methods: {
        //提交form表单-并判断是否必填
        submitForm(formName) {
            const time = `${this.ruleForm.startTime} ${this.ruleForm.startHours}`
            const begin_time = new Date(time) / 1000
            const date = new Date()
            // console.log({ time })
            if (begin_time < date / 1000) {
                ElMessage({
                    type: 'warning',
                    message: '当前时间已超过开始时间，请修改'
                })
                return
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(
                            '/admin/fund/bankPaymentAdd',
                            {
                                enc_true_name: this.ruleForm.enc_true_name,
                                enc_bank_no: this.ruleForm.enc_bank_no,
                                bank_code: this.ruleForm.bank_code,
                                total_money: this.ruleForm.total_money * 100,
                                total_num: this.ruleForm.total_num,
                                pay_type: this.ruleForm.pay_type,
                                range_money: this.ruleForm.range_money * 100,
                                begin_time: new Date(time) / 1000,
                                pay_line: this.ruleForm.pay_line
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.go(-1)
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },

        disabledDate(time) {
            return time.getTime() < Date.now() - 86000000
        },

        datePickerChange() {
            const begin_time = new Date(this.ruleForm.startTime) / 1000
            const date = new Date()
            const hour = date.getHours()
            if (begin_time > date / 1000) {
                this.timeSelectstart = '00:00'
            } else {
                this.timeSelectstart = `${String(hour + 1).padStart(2, '0')}:00`
                this.ruleForm.startHours = this.timeSelectstart
            }
        }
    },

    created() {},
    components: {
        mainone
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #97a0c3;
    padding: 5px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
}
</style>
