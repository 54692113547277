<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <el-button type="primary" @click="routeraddrClick('/corporatetransferadd')">添加</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :min-width="mytabletitle.width"
                        >
                            <!-- 20230203迎合业务需求：临时添加任务-区分状态字段与转账开关-新增关停操作 【本次新增未通过产品把关，全由项目经理LS操持】-->
                            <template #header v-if="mytabletitle.prop === 'status'">
                                {{ this.mainthreetabletitle.filter((n) => n.prop == 'status')[0]['label'] }}
                                <span style="cursor: pointer;"
                                    ><el-tooltip class="box-item" effect="dark" placement="top-end">
                                        <i class="el-icon-info"></i>
                                        <template #content>
                                            <div>
                                                <span>开启：正常转账；</span>
                                            </div>
                                            <div style="margin-top: 10px;">
                                                <span>关闭：（1）产生打款请求但实际不打款</span>
                                                <div style="margin-left: 36px;"><span>（2）如需打款请到【提现记录】或【对公打款详情】页面中进行手动审核操作</span></div>
                                            </div>
                                        </template>
                                    </el-tooltip></span
                                >
                            </template>
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop === 'total_money' ||
                                        mytabletitle.prop === 'pay_money' ||
                                        mytabletitle.prop === 'total_num' ||
                                        mytabletitle.prop === 'pay_num' ||
                                        mytabletitle.prop === 'every_pay_money' ||
                                        mytabletitle.prop === 'error_count' ||
                                        mytabletitle.prop === 'status' ||
                                        mytabletitle.prop === 'is_del'
                                "
                            >
                                <!-- 打款总金额-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'total_money'">
                                    <span>{{ scope.row.total_money != null ? (scope.row.total_money / 100).toFixed(2) : '' }}</span>
                                </div>
                                <!-- 累计已打款金额-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'pay_money'">
                                    <span style="color: var(--el-color-primary);">{{ scope.row.pay_money != null ? (scope.row.pay_money / 100).toFixed(2) : '' }}</span>
                                </div>
                                <!-- 打款周期-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'total_num'">
                                    <span>{{ scope.row.total_num + this.paytype_opt.filter((n) => n.value == scope.row.pay_type)[0]['label'] }}</span>
                                </div>
                                <!-- 已打款周期-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'pay_num'">
                                    <span style="color: var(--el-color-primary);">{{ scope.row.pay_num }}</span>
                                    <span>{{ this.paytype_opt.filter((n) => n.value == scope.row.pay_type)[0]['label'] }}</span>
                                </div>
                                <!-- 每期打款金额 -->
                                <div v-if="mytabletitle.prop === 'every_pay_money'">
                                    <span>{{ parseInt(scope.row.total_money / scope.row.total_num) / 100 }}</span>
                                </div>
                                <!-- 异常打款记录 -->
                                <div v-if="mytabletitle.prop === 'error_count'">
                                    <span :style="{ color: scope.row.error_count > 0 ? 'var(--el-color-danger)' : '' }">{{ scope.row.error_count }}</span>
                                </div>
                                <!-- 转账开关-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'status'">
                                    <div id="switch">
                                        <el-switch
                                            v-model="scope.row.status"
                                            active-text="开启"
                                            inactive-text="关闭"
                                            :active-value="1"
                                            :inactive-value="2"
                                            active-color="#13ce66"
                                            @click="mypowerlimits.dgdk_kg ? changeStatus(scope.row) : ''"
                                            inactive-color="#cbcbcb"
                                            :disabled="!mypowerlimits.dgdk_kg"
                                        >
                                        </el-switch>
                                    </div>
                                </div>
                                <!-- 状态-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'is_del'">
                                    <span :style="{ color: scope.row.is_del == 1 ? 'var(--el-color-success)' : 'var(--el-color-danger)' }">{{
                                        scope.row.is_del == 1 ? '正常' : '关停'
                                    }}</span>
                                </div>
                            </template>
                        </el-table-column>

                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" prop="myoperation" id="operation" :width="mainthreehandle">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-代理商列表-->
                                <!-- -->
                                <div>
                                    <el-button type="primary" size="mini" @click="routeraddrClick('/corporatetransferinfo', scope.row)" v-if="mypowerlimits.dgdk_xq"
                                        >详情</el-button
                                    >
                                    <el-button
                                        type="danger"
                                        size="mini"
                                        @click="isDelEvent(scope.row, this.mainthreetableData)"
                                        v-if="scope.row.is_del == 1 && mypowerlimits.dgdk_gt"
                                        >关停</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            :layout="Layout"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'vcorporatetransferlist',
    inject: ['reload'], //刷新引用
    data() {
        return {
            mainonebread: [
                {
                    name: '财务'
                },
                {
                    name: '提现管理'
                },
                {
                    name: '对公打款'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '90px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '收款方名称：',
                            placeholder: '请输入收款方名称',
                            name: 'content',
                            content: ''
                        }
                    ]
                }
            }, // 筛选-【筛选查询】-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 33, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 140, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: false,
                    prop: 'id',
                    label: '序号',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '收款方名称',
                    width: '160'
                },
                {
                    fixedstatu: false,
                    prop: 'user',
                    label: '收款账户',
                    width: '220'
                },
                {
                    fixedstatu: false,
                    prop: 'total_money',
                    label: '打款总金额',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'pay_money',
                    label: '累计已打款金额',
                    width: '140'
                },
                {
                    fixedstatu: false,
                    prop: 'total_num',
                    label: '打款周期',
                    width: '110'
                },
                {
                    fixedstatu: false,
                    prop: 'pay_num',
                    label: '已打款周期',
                    width: '110'
                },
                {
                    fixedstatu: false,
                    prop: 'every_pay_money',
                    label: '每期打款金额',
                    width: '140'
                },
                {
                    fixedstatu: false,
                    prop: 'error_count',
                    label: '异常打款记录',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'status',
                    label: '转账开关',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'is_del',
                    label: '状态',
                    width: '80'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1000, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            Layout: ' sizes, prev, pager, next, jumper',

            //分页
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值

            // 打款周期类型
            paytype_opt: [
                {
                    value: 1,
                    label: '天'
                },
                {
                    value: 2,
                    label: '周'
                },
                {
                    value: 3,
                    label: '月'
                },
                {
                    value: 4,
                    label: '季'
                },
                {
                    value: 5,
                    label: '年'
                }
            ],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            mydisabled: false
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 关停
        isDelEvent(data, tabledatas) {
            console.log(data, tabledatas)
            ElMessageBox.confirm('确定关停么？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    axios
                        .post(
                            '/admin/fund/companyPaymentChangeDel',
                            {
                                id: data.id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        // this.reload()
                                        this.mainthreetableData.map((n) => {
                                            if (n.id == data.id) {
                                                n.is_del = response.data.result.is_del
                                            }
                                            return n
                                        })
                                    }
                                })
                            }
                        })
                        .catch(function(error) {
                            console.log(error)
                        })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消关停'
                    })
                })
        },
        // table表单-状态改变-status值通过后台返回
        changeStatus(data) {
            // console.log("切换状态的data值：" + data.status);
            axios
                .post(
                    '/admin/fund/companyPaymentChangeStatus',
                    {
                        id: data.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: response.data.message
                        })
                    } else {
                        // 切换状态失败-复原状态
                        if (data.status == 1) {
                            data.status = 2
                        } else {
                            data.status = 1
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },

        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/fund/companyPaymentIndex',
                    {
                        content: this.pagesdatas.content,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.pagesdatas.page = val
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/fund/companyPaymentIndex',
                    {
                        content: this.pagesdatas.content,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 跳转-详情
                if (routeraddr == '/corporatetransferinfo') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                id: rowdata.id
                            }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas(tableData) {
            console.log(tableData)
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/fund/companyPaymentIndex',
                    {
                        content: data.content,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            // console.log(successdata);
            var newdata = successdata.data
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            // 定义
            this.pagesdatas = {
                content: '',
                page_size: this.PageSize,
                page: 1
            }

            //通过Ajax向后台获取数据
            axios
                .post('/admin/fund/companyPaymentIndex', this.pagesdatas, {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}

/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.box-table-addr {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.mytabletext,
.box-table-addr:hover {
    color: var(--el-color-primary);
    cursor: pointer;
}

/* 下载发票 */
.downfapiao-box {
    background-color: rgba(236, 236, 236, 0.473);
    border-radius: 4px;
}

.downfapiao-box:hover {
    background-color: aliceblue;
    cursor: pointer;
}

.downfapiao-box:hover .downfapiao-box-flex-span {
    color: var(--el-color-primary);
}

.downfapiao-box-flex-image:hover {
    border: 1px solid var(--el-color-primary);
}

.downfapiao-box-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 10px 20px;
}

.downfapiao-box-flex-left {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.downfapiao-box-flex-image {
    width: 100px;
    height: 60px;
    vertical-align: middle;
    border: 1px solid #3333331c;
    border-radius: 2px;
    margin-right: 10px;
}

.downfapiao-box-flex-span {
    color: #333333;
}

.tan-box {
    max-height: 450px;
    overflow: hidden;
    overflow-y: visible;
    margin: 20px 5% 0 0;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
