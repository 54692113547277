<template>
    <div class="page-container">
        <div class="container-table">
            <div class="table-header">
                <div>数据列表</div>
                <div>
                    <el-button v-if="mypowerlimits.qdtglj_tj" type="primary" @click="handleAdd">添加</el-button>
                </div>
            </div>
            <el-table :border="true" :data="table.data" v-loading="loading">
				<el-table-column prop="" label="序号" fixed="left">
                    <template #default="{ $index }">
                        <span>{{ $index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="名称" />
                <el-table-column prop="office_name" label="公众号" />
                <el-table-column prop="link" label="生成链接" />
                <el-table-column prop="status" label="状态" width="120">
					<template #default="{ row }">
                        <el-switch v-model="row.status" :active-value="1"  :inactive-value="2" @click="recordStatus(row, mypowerlimits.qdtglj_qy)" :disabled="!mypowerlimits.qdtglj_qy"/>
                    </template>
                </el-table-column>
                <el-table-column prop="truename" label="操作人" />
				<el-table-column prop="uptime" label="操作时间" width="180">
                    <template #default="{ row }">
                        <span>{{ row.uptime ? $utils.formatDate(row.uptime) : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="210" fixed="right">
                    <template #default="{ row }">
                        <el-button v-if="mypowerlimits.qdtglj_bj" type="primary" size="small" @click="handleEdit(row)">编辑</el-button>
						<el-button v-if="mypowerlimits.qdtglj_sc" type="danger" size="small" @click="handleDelete(row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="tabler-pagination">
                <el-pagination
                    @size-change="getTableData()"
                    @current-change="getTableData"
                    :current-page="params.page"
                    :page-sizes="[20, 50, 100, 1000]"
                    v-model:page-size="params.page_size"
                    :total="table.total"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>
        <FormDialog :deviceDialog="deviceDialog" :data="deviceData" @success="successEvent" @cancel="deviceDialog = false"></FormDialog>
    </div>
</template>

<script setup>
import FormDialog from './formDialog.vue'
import { ref, reactive } from 'vue'
import { ElMessageBox, ElNotification, ElMessage } from 'element-plus'
import http from '@/assets/js/http'
import router from '@/router'

// 获取权限
const mypowerlimits = reactive(JSON.parse(localStorage.getItem('powerlimits')))

const deviceData = ref({})

// 过滤
const params = reactive({
    page: 1,
    page_size: 20
})

// 修改绑定设备
const deviceDialog = ref(false)

// 表格
const loading = ref(false)
const table = reactive({
    data: [],
    columns: [],
    total: 0
})

const getTableData = async (page = 1) => {
    params.page = page
    loading.value = true
    try {
        const res = await http.post('office/pushLinkIndex', params)
        Object.assign(table, res)
    } catch (e) {
        console.log(e)
    }
    loading.value = false
}

const formatPrice = (data) => {
    return data ? (+data / 100).toFixed(2) : data
}

// 操作

// 跳转
const goUrl = (path, query) => {
    router.push({ path, query })
}

// 添加
const handleAdd = () => {
    deviceDialog.value = true
    deviceData.value = { data: {}, api: 'office/pushLinkAdd' }
}

// 编辑
const handleEdit = (row)=> {
    deviceData.value = { data: { id: row.id }, api: 'office/pushLinkEdit' }
    deviceDialog.value = true
}

// 删除
const handleDelete = async (row) => {
	const { name, id } = row
    await ElMessageBox.confirm(`是否删除${name}？`, '提示', { type: 'warning', confirmButtonText: '确定', cancelButtonText: '取消' })
    await http.post('office/pushLinkDel', { id })
    ElNotification.success({ title: '提示', type: 'success', message: '操作成功' })
    getTableData()
}

// 修改状态
const recordStatus = async (row, authority)=> {
	const { id } = row
    if (!authority) return
	try {
        await http.post('office/changePushLinkStatus', { id }, { sMsg: true });
    } catch (e) {
        row.status = row.status === 1 ? -1 : 1;
    }
}

const successEvent = ()=> {
    deviceDialog.value = false
    getTableData()
}

getTableData()
</script>

<style lang="scss" scoped>
.page-container {
    background-color: #e9eef3;
    .container-filter {
        padding: 12px 24px;
        background-color: #fff;
        .el-form {
            display: flex;
            justify-content: space-between;
        }
        .el-form-item {
            margin-right: 20px;
            margin-bottom: 10px;
        }

        .filter-btn {
            min-width: 180px;
        }
    }

    .container-table {
        margin-top: 4px;
        padding: 24px;
        background-color: #fff;
        .table-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        .skip {
            cursor: pointer;
            color: var(--el-color-primary);
        }
        .tabler-pagination {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
        }
    }
}

.container-drawer {
    .filter-tree {
        margin-top: 24px;
    }
    .drawer-footer {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }
}

#mysections :deep(.el-drawer__body) {
    overflow-y: auto;
}
</style>
