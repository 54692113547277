<template>
    <div>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import maintwo from '@/components/Maintwo'

export default defineComponent({
    name: 'selectThree',
    inject: ['reload'],
    data() {
        return {
            maintwoiconshow: false,
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '160px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '输入名称：',
                            placeholder: '请输入名称',
                            name: 'name',
                            content: ''
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '企微人员：',
                            placeholder: '请输入企微人员',
                            name: 'wecom_user',
                            content: ''
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '160px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '状态：',
                            placeholder: '全部',
                            name: 'status',
                            value: '',
                            options: [
                                {
                                    label: '全部',
                                    value: ''
                                },
                                {
                                    label: '开启',
                                    value: '1'
                                },
                                {
                                    label: '关闭',
                                    value: '-1'
                                },
                                {
                                    label: '禁用',
                                    value: '2'
                                }
                            ]
                        },
                        {
                            cascaderisshow: true,
                            ismust: false,
                            title: '地区：',
                            placeholder: '请选择地区',
                            name: 'bind_info',
                            value: '',
                            isaddress: true, // 判断是否为地址-地址可不传options
                            // options: [],
                            props: { multiple: true, checkStrictly: true, emitPath: true }, // tree层级props
                            collapsetags: true,
                            collapsetagstooltip: true
                        }
                    ]
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '160px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        // {
                        //     ismust: false, //是否必填，true表示必填
                        //     title: '设备编号：',
                        //     placeholder: '请输入设备编号', //此name为键值
                        //     name: 'client_id',
                        //     content: '',
                        //     id: '', //此id为给后台的id值
                        //     url: '/admin/getClientList',
                        //     inputval: { client: '', is_all: 1 } //这里记录需要传的参数
                        // },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '企业名称：',
                            placeholder: '请输入企业名称', //此name为键值
                            name: 'wecom',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Service/wecom',
                            posttype: 'get',
                            inputval: { corp_name: '' } //这里记录需要传的参数
                        }
                        // {
                        //     ismust: false, //是否必填，true表示必填
                        //     title: '代理商名：',
                        //     placeholder: '请输入代理商名称',
                        //     name: 'agentid', //此name为键值
                        //     content: '',
                        //     id: '', //此id为给后台的id值
                        //     url: '/admin/shopUser/getShopList',
                        //     inputval: { name: '' } //这里记录需要传的参数
                        // }
                    ]
                }
            }
        }
    },
    methods: {
        getSeachinfoEvent(data) {
            this.$emit('getsearchinfo', data)
        }
    },
    components: {
        maintwo
    }
})
</script>

<style scoped></style>
