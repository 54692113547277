<template>
    <div class="page-container">
        <div class="container-bread">
            合作伙伴 <el-icon><ArrowRight /></el-icon> 指定设备报单 <el-icon><ArrowRight /></el-icon> 负责人指定设备
        </div>
        <el-tabs class="container-tabs" v-model="active">
            <el-tab-pane label="负责人指定设备" name="1" lazy>
                <HeaderDevice :main_id="params.main_id" :main_name="params.main_name" />
            </el-tab-pane>
            <el-tab-pane label="代理商指定设备" name="2" lazy>
                <AgentDevice :agent_id="params.agent_id" :agent_name="params.agent_name" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script setup>
import HeaderDevice from './headerDevices.vue'
import AgentDevice from './agentDevices.vue'
import { ArrowRight } from '@element-plus/icons'
import { useRoute } from 'vue-router'
import { ref } from 'vue'

const params = useRoute().query
const active = ref(params.active || '1')
</script>

<style lang="scss" scoped>
.page-container {
    .container-bread {
        font-size: 14px;
        color: #666;
        display: flex;
        align-items: center;
    }

    .container-tabs {
        background-color: #fff;
        margin-top: 12px;
        padding: 8px 12px 0px;
    }
}
</style>
