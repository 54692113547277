<template>
    <div id="mymain" style="margin-bottom: 0 !important">
        <el-row v-for="(mycashoutdata, ide) in myCashout.cashout" :key="ide">
            <el-col :span="24">
                <!--提现：显示信息-->
                <!--        <div style="float: left;" :style="{'width':myCashout.titlewidth}">-->
                <!--            <span style="color: #616e9d;font-size: 14px;">-->
                <!--              <span style="color: red" v-if="mycashoutdata.ismust">*</span>-->
                <!--              {{ mycashoutdata.title }}-->
                <!--            </span>-->
                <!--        </div>-->
                <!--点击编辑按钮-->
                <div v-if="mycashoutdata.isshowedit">
                    <div class="edit-box">
                        <div class="edit-box-flex">
                            <div class="edit-box-flex-img">
                                <el-avatar :src="mycashoutdata.avatarinfo.avatar" style="border: 1px solid #dcdada"></el-avatar>
                            </div>
                            <div>
                                <i class="el-icon-error edit-box-flex-i" @click="delWechatClient(ide, mycashoutdata.paytype, myCashout.ide)"></i>
                            </div>
                        </div>
                        <div style="width: 120px">
                            <el-button type="primary" icon="el-icon-edit" size="mini" round @click="editWechatClient(ide, (dialogVisible = true))">修改 </el-button>
                        </div>
                        <div>
                            <div v-if="mycashoutdata.paytype == 1 && myCashout.ide == 'wechat'">
                                <span style="color: #f56c6c">默认提现方式</span>
                            </div>
                            <div v-if="mycashoutdata.paytype == 1 && myCashout.ide == 'alipay'" @click="changePaytypeEvent(ide, mycashoutdata.paytype)">
                                <span class="paytypeclass" style="color: #67c23a">设置默认提现方式</span>
                            </div>
                            <div v-if="mycashoutdata.paytype == 2 && myCashout.ide == 'wechat'" @click="changePaytypeEvent(ide, mycashoutdata.paytype)">
                                <span class="paytypeclass" style="color: #67c23a">设置默认提现方式</span>
                            </div>
                            <div v-if="mycashoutdata.paytype == 2 && myCashout.ide == 'alipay'">
                                <span style="color: #f56c6c">默认提现方式</span>
                            </div>
                        </div>
                    </div>
                    <div class="edit-box-user-info">
                        <div class="edit-box-user-content">
                            <div class="edit-box-user-title">用户ID：</div>
                            <div>{{ mycashoutdata.avatarinfo.id }}</div>
                        </div>
                        <div class="edit-box-user-content">
                            <div class="edit-box-user-title">用户昵称：</div>
                            <div>{{ mycashoutdata.avatarinfo.nickname }}</div>
                        </div>
                        <div class="edit-box-user-content">
                            <div class="edit-box-user-title">手机号：</div>
                            <div>{{ mycashoutdata.avatarinfo.mobile }}</div>
                        </div>
                    </div>
                </div>
                <!--点击添加按钮-->
                <div v-if="mycashoutdata.isshowadd">
                    <div style="float: left; width: 120px">
                        <el-button
                            type="success"
                            icon="el-icon-zoom-in"
                            size="mini"
                            round
                            @click="addWechatClient(ide, (dialogVisible = true))"
                            style="border: none !important"
                            :style="{ 'background-color': mycashoutdata.addcolor }"
                            >{{ mycashoutdata.addtitle }}
                        </el-button>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!--提现：弹窗-->
        <el-dialog v-model="dialogVisible" :title="getDatainfo.thistantitle" :width="800" :before-close="handleCloseSenior">
            <el-form ref="seniorform" @submit.prevent>
                <el-form-item>
                    <el-input v-model="getDatainfo.search" placeholder="请输入昵称/手机号" style="max-width: 200px; margin-right: 20px" clearable></el-input>
                    <el-button type="primary" @click="searchMytableData(getDatainfo.search, myCashout.ide)">查询</el-button>
                </el-form-item>
                <el-form-item style="margin-top: 10px">
                    <el-table :data="getDatainfo.tableData" style="width: 100%" height="450" border v-loading="getDatainfo.loading" element-loading-text="正在飞速加载中...">
                        <el-table-column label="ID" prop="id" width="100" />
                        <el-table-column label="头像" prop="avatar">
                            <template #header>
                                <div>头像</div>
                            </template>
                            <template #default="scope">
                                <el-avatar :src="scope.row.avatar" style="border: 1px solid #dcdada"></el-avatar>
                            </template>
                        </el-table-column>
                        <el-table-column :label="getDatainfo.mainnick" prop="nickname" />
                        <el-table-column label="手机号" prop="mobile" width="150" />
                        <el-table-column label="关注公众号" prop="subscribe" width="">
                            <template #default="scope">
                                <div>
                                    {{ scope.row.subscribe == -1 ? '否' : '是' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="实名认证" prop="is_real" width="">
                            <template #default="scope">
                                <div>
                                    {{ scope.row.is_real == -1 ? '否' : '是' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template #header>
                                <div>操作</div>
                            </template>
                            <template #default="scope">
                                <el-button
                                    size="mini"
                                    @click="handleTrue(scope.$index, scope.row, getDatainfo.thiside, myCashout.ide, (dialogVisible = false))"
                                    style="color: #97a0c3"
                                    >选择
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, ref } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
    name: 'Mainwechat',
    data() {
        return {
            thiside: null,
            thistantitle: '请选择'
        }
    },
    props: ['mycashout', 'mycashoutisshow'],
    setup(props, context) {
        const myCashout = reactive(props['mycashout'])
        const myCashoutisshow = reactive(props['mycashoutisshow'])

        const dialogVisible = ref(false)
        //定义数据数组等
        let getDatainfo = reactive({
            thiside: null,
            thistantitle: '请选择',
            mainnick: '昵称',
            searchpder: '昵称查询',
            isshoweditwx: false,
            isshowaddwx: false,
            tableData: [
                // {
                //   "id": 1,
                //   "avatar": "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                //   "nickname": "用户张三",
                //   "mobile": "18738573256"
                // },
                // {
                //   "id": 2,
                //   "avatar": "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
                //   "nickname": "小时光",
                //   "mobile": "18738573258"
                // },
                // {
                //   "id": 3,
                //   "avatar": "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                //   "nickname": "张三",
                //   "mobile": "18738573257"
                // },
                // {
                //   "id": 1,
                //   "avatar": "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                //   "nickname": "用户张三",
                //   "mobile": "18738573256"
                // },
                // {
                //   "id": 2,
                //   "avatar": "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
                //   "nickname": "小时光",
                //   "mobile": "18738573258"
                // },
                // {
                //   "id": 3,
                //   "avatar": "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
                //   "nickname": "张三",
                //   "mobile": "18738573257"
                // }
            ],
            search: '',

            loading: false //加载动效
        })
        const handleCloseSenior = (done) => {
            done()
            getDatainfo.tableData = []
            getDatainfo.search = ''
        }
        const handleTrue = (index, row, ide, getide) => {
            //弹窗-选择确认
            console.log(index, row)
            // console.log('this ide is:' + ide)
            myCashout.cashout[ide].isshowedit = true
            myCashout.cashout[ide].isshowadd = false
            myCashout.cashout[ide].avatarinfo = row //将弹窗的数据传入前端

            context.emit('getpayaccount', row) //将确认数据返回给父组件
            if (myCashout.cashout[ide].paytype == 0) {
                if (getide == 'wechat') {
                    myCashout.cashout[ide].paytype = 1
                    context.emit('getchangepaytype', 1) //将需切换的账户状态回传给父组件
                } else {
                    myCashout.cashout[ide].paytype = 2
                    context.emit('getchangepaytype', 2) //将需切换的账户状态回传给父组件
                }
            }

            getDatainfo.tableData = []
            getDatainfo.search = ''
        }
        const addWechatClient = (ide) => {
            //按钮-添加提现微信/支付宝
            getDatainfo.thiside = ide
            getDatainfo.thistantitle = myCashout.cashout[ide].tantitle
            getDatainfo.mainnick = myCashout.cashout[ide].tantable.mainnick
            getDatainfo.searchpder = myCashout.cashout[ide].tantable.searchpder
        }
        const editWechatClient = (ide) => {
            //按钮-编辑提现微信/支付宝信息&显示弹窗
            getDatainfo.thiside = ide
            getDatainfo.thistantitle = myCashout.cashout[ide].tantitle
            getDatainfo.mainnick = myCashout.cashout[ide].tantable.mainnick
            getDatainfo.searchpder = myCashout.cashout[ide].tantable.searchpder
        }
        const delWechatClient = (ide, val, getide) => {
            //按钮-删除提现微信/支付宝logo&显示添加提现微信/支付宝按钮
            myCashout.cashout[ide].isshowedit = false
            myCashout.cashout[ide].isshowadd = true

            context.emit('getpayaccount', '') //将确认数据返回给父组件
            if (getide == 'wechat') {
                myCashout.cashout[ide].paytype = 2
                context.emit('getchangepaytype', 2) //将需切换的账户状态回传给父组件
                context.emit('getisshow', {
                    ide: getide,
                    isshowedit: myCashout.cashout[ide].isshowedit,
                    isshowadd: myCashout.cashout[ide].isshowadd
                })
            } else {
                myCashout.cashout[ide].paytype = 1
                context.emit('getchangepaytype', 1) //将需切换的账户状态回传给父组件
                context.emit('getisshow', {
                    ide: getide,
                    isshowedit: myCashout.cashout[ide].isshowedit,
                    isshowadd: myCashout.cashout[ide].isshowadd
                })
            }
        }
        const searchMytableData = (data, ide) => {
            if (data != '') {
                //提交查询信息-走接口
                // console.log('获取提交查询的值:' + data)
                getDatainfo.loading = true //显示loading加载动效

                if (ide == 'wechat') {
                    //通过Ajax向后台获取数据
                    axios
                        .post(
                            '/admin/ajax/getWechatUser',
                            {
                                keywords: data
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            let datas = response.data
                            if (datas.code == 0) {
                                getDatainfo.tableData = response.data.result.list
                                getDatainfo.loading = false //关闭loading加载动效
                            } else {
                                setTimeout(() => {
                                    getDatainfo.loading = false //关闭loading加载动效
                                }, 5000)
                            }
                        })
                        .catch(function(error) {
                            ElMessage({
                                type: 'error',
                                message: error,
                                duration: 5000,
                                onClose: () => {
                                    getDatainfo.loading = false //关闭loading加载动效
                                }
                            })
                        })
                } else {
                    //通过Ajax向后台获取数据
                    axios
                        .post(
                            '/admin/ajax/getAliUser',
                            {
                                keywords: data
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            // getDatainfo.tableData = response.data.result.list;
                            // getDatainfo.loading = false; //关闭loading加载动效

                            let datas = response.data
                            if (datas.code == 0) {
                                getDatainfo.tableData = response.data.result.list
                                getDatainfo.loading = false //关闭loading加载动效
                            } else {
                                setTimeout(() => {
                                    getDatainfo.loading = false //关闭loading加载动效
                                }, 5000)
                            }
                        })
                        .catch(function(error) {
                            ElMessage({
                                type: 'error',
                                message: error,
                                duration: 5000,
                                onClose: () => {
                                    getDatainfo.loading = false //关闭loading加载动效
                                }
                            })
                        })
                }
            } else {
                ElMessage({
                    type: 'error',
                    message: '请输入查询条件'
                })
            }
        }
        //切换默认提现方式
        const changePaytypeEvent = (ide, val) => {
            if (val == 1) {
                myCashout.cashout[ide].paytype = 2
                context.emit('getchangepaytype', 2) //将需切换的账户状态回传给父组件
            } else {
                myCashout.cashout[ide].paytype = 1
                context.emit('getchangepaytype', 1) //将需切换的账户状态回传给父组件
            }
        }
        return {
            myCashout,
            myCashoutisshow,
            dialogVisible,
            getDatainfo,
            handleCloseSenior,
            handleTrue,
            addWechatClient,
            editWechatClient,
            delWechatClient,
            searchMytableData,
            changePaytypeEvent
        }
    },
    methods: {}
}
</script>

<style scoped>
/* 默认提现方式设置css */
.paytypeclass {
    cursor: pointer;
}

.paytypeclass:hover {
    color: #465eff !important;
}

.edit-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.edit-box-flex {
    width: 60px;
    margin-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
}

.edit-box-flex-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-box-flex-i {
    color: red;
    font-weight: 400;
    cursor: pointer;
    vertical-align: top;
}

.edit-box-user-info {
    width: 100%;
    /* text-align: left; */
    line-height: 26px;
    color: #666666;
    margin-top: 10px;
}

.edit-box-user-content {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}
.edit-box-user-title {
    width: 76px;
    /* text-align: right; */
}
</style>
