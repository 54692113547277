<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myselecttopisshow="maintwoforminfo.selecttopisshow"
            :myselecttop="maintwoforminfo.selecttop"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myinputonefullgaoisshow="myinputonefullgaoisshow"
            :myinputonefullgao="myinputonefullgao"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectuptopisshow="myselecttopisshow"
            :myselectuptop="myselecttop"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div class="box-list">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <!-- <el-button type="warning" plain @click="exportDatas()" v-if="myuserinfo.dept_id != 45">导出</el-button> -->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template
                                #default="scope"
                                v-if="
                                    mytabletitle.prop === 'trade_no' ||
                                        mytabletitle.prop === 'client' ||
                                        mytabletitle.prop === 'name' ||
                                        mytabletitle.prop === 'printtotal' ||
                                        mytabletitle.prop === 'mobetotal' ||
                                        mytabletitle.prop === 'sftotal' ||
                                        mytabletitle.prop === 'companyprice' ||
                                        mytabletitle.prop === 'uid' ||
                                        mytabletitle.prop === 'print_type_name' ||
                                        mytabletitle.prop === 'aliasname' ||
                                        mytabletitle.prop === 'ShopUser.user_type'
                                "
                            >
                                <!-- 订单编号-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'trade_no'">
                                    <div>
                                        <span class="mytabletext" @click="routeraddrClick('/orderlistinfo', scope.row)"> {{ scope.row.trade_no }}</span>
                                    </div>
                                </div>
                                <!-- 设备编号-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'client'">
                                    <span>{{ scope.row.ShareClient ? scope.row.ShareClient.client : '' }}</span>
                                </div>
                                <!-- 代理商-数据渲染 -->
                                <div v-if="mytabletitle.prop === 'name'">
                                    <span>{{ scope.row.ShopUser ? scope.row.ShopUser.name : '' }}</span>
                                </div>
                                <!-- 打印费-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'printtotal'">
                                    <span>{{ scope.row.printtotal === null ? '' : (scope.row.printtotal / 1).toFixed(2) }}</span>
                                </div>
                                <!-- 文档资源费-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'mobetotal'">
                                    <span>{{ scope.row.mobetotal === null ? '' : (scope.row.mobetotal / 100).toFixed(2) }}</span>
                                </div>
                                <!-- 实付金额-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'sftotal'">
                                    <span>{{ scope.row.sftotal === null ? '' : (scope.row.sftotal / 1).toFixed(2) }}</span>
                                </div>
                                <!-- 分佣金额-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'companyprice'">
                                    <span>{{ scope.row.companyprice === null ? '' : (scope.row.companyprice / 1).toFixed(2) }}</span>
                                </div>
                                <!-- 用户ID-渲染数据 -->
                                <div v-if="mytabletitle.prop === 'uid'">
                                    <div
                                        :class="this.mypowerlimits.yhlb == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.yhlb == true ? routeraddrClick('/userlist', scope.row) : ''"
                                    >
                                        <span> {{ scope.row.uid }}</span>
                                    </div>
                                </div>
                                <!-- 打印介质-数据渲染 -->
                                <div v-if="mytabletitle.prop === 'print_type_name'">
                                    <span>{{ scope.row.SharePrintCate ? scope.row.SharePrintCate.printtype_name : '' }}</span>
                                </div>
                                <!-- 打印类型-数据渲染 -->
                                <div v-if="mytabletitle.prop === 'aliasname'">
                                    <span>{{ scope.row.SharePrintCate ? scope.row.SharePrintCate.aliasname : '' }}</span>
                                </div>
                                <!-- 类型 -->
                                <div v-if="mytabletitle.prop === 'ShopUser.user_type'">
                                    <span>{{
                                        scope.row.ShopUser && scope.row.ShopUser.user_type ? this.CSP_type.filter((n) => n.value == scope.row.ShopUser.user_type)[0]['label'] : ''
                                    }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            :layout="Layout"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
// import { ElMessage } from "element-plus";
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import CPS from '@/assets/js/componentProperties' // 公共数据

export default defineComponent({
    name: 'vorderlist',
    data() {
        return {
            orderListDic: {},
            mainonebread: [
                {
                    name: '订单'
                },
                {
                    name: '打印管理'
                },
                {
                    name: '订单列表'
                    // path: "/orderlist",
                }
            ], // 当前页-【面包屑导航】

            maintwoiconshow: true, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '150px', //input宽度
                    inputinterval: '10px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '订单编号：',
                            placeholder: '请输入订单编号',
                            name: 'trade_no',
                            content: ''
                        }
                    ]
                },
                myinputonefullisshow: true, //筛选查询-自动建议下拉框
                myinputonefull: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '70px', //标题宽度
                    inputwidth: '130px', //input宽度
                    inputinterval: '10px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '设备编号:',
                            placeholder: '设备编号', //此name为键值
                            name: 'client',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Equipment/searchClient',
                            posttype: 'get',
                            inputval: { client: '' } //这里记录需要传的参数
                        },
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '负责人:',
                            placeholder: '负责人', //此name为键值
                            name: 'win_user_id',
                            content: '',
                            id: '', //此id为给后台的id值
                            url: '/admin/Statistics/userList',
                            posttype: 'get',
                            inputval: { truename: '' } //这里记录需要传的参数
                        }
                    ]
                },
                selectleftisshow: true, //筛选查询-左侧字+select单框||select时间（放input框之后）
                selectleft: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '140px',
                    selectinterval: '10px', //select右侧距离
                    select: [
                        {
                            cascaderisshow: true,
                            ismust: false,
                            title: '打印类型：',
                            placeholder: '打印类型',
                            name: 'printtype',
                            value: '',
                            options: []
                        },
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '类型：',
                            placeholder: '全部',
                            name: 'user_type',
                            value: '',
                            options: [
                                ...[
                                    {
                                        value: '0',
                                        label: '全部'
                                    }
                                ],
                                ...CPS.get('winuserinfo').type
                            ]
                        }
                    ]
                },
                selecttopisshow: true, //筛选查询-左侧字+select单框||select时间（放input框之前）
                selecttop: {
                    width: 'none',
                    divinterval: '', //框右侧距离
                    selectwidth: '140px',
                    selectinterval: '20px', //select右侧距离
                    select: [
                        {
                            selectisshow: true,
                            ismust: false,
                            title: '平台来源：',
                            placeholder: '全部',
                            name: 'thirdparty',
                            value: '1-1', //显示值，初始状况，显示值与默认值需相同
                            valueisshow: true,
                            valuedefault: '1-1', //设置默认值
                            options: []
                        }
                    ]
                }
            },
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 4, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 150, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'trade_no',
                    label: '订单编号',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'client',
                    label: '设备编号',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'name',
                    label: '代理商',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'ShopUser.user_type',
                    label: '类型',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'ShopUser.win_user_id_name',
                    label: '负责人',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'printtotal',
                    label: '打印费\n(含服务费)',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'mobetotal',
                    label: '文档资源费',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'mattingprice',
                    label: '三方服务费',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'sftotal',
                    label: '实付金额',
                    width: ''
                },
                // {
                //     fixedstatu: false,
                //     prop: 'companyprice',
                //     label: '分佣金额',
                //     width: ''
                // },
                {
                    fixedstatu: false,
                    prop: 'uid',
                    label: '用户ID',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'print_type_name',
                    label: '打印介质',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'aliasname',
                    label: '打印类型',
                    width: '120'
                },
                {
                    fixedstatu: false,
                    prop: 'paytype_name',
                    label: '支付方式',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'pay_status_name',
                    label: '订单状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'print_status_name',
                    label: '打印状态',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'pay_time',
                    label: '付款时间',
                    width: '180'
                }
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1000, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭
            Layout: ' sizes, prev, pager, next, jumper',

            //高级筛选
            myinputishow: true, //高级筛选-input单框
            myinput: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%',
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '订单编号：',
                        placeholder: '请输入订单编号',
                        name: 'trade_no',
                        content: ''
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '用户ID：',
                        placeholder: '请输入用户ID',
                        name: 'uid',
                        content: ''
                    }
                ]
            },
            myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
            myinputonefullgao: {
                divinterval: '3%', //框右侧距离
                inputwidth: '47%', //input宽度
                inputmaxwidth: '100%',
                input: [
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '设备编号：',
                        placeholder: '请输入设备编号', //此name为键值
                        name: 'client',
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/admin/Equipment/searchClient',
                        posttype: 'get',
                        inputval: { client: '' } //这里记录需要传的参数
                    },
                    {
                        ismust: false, //是否必填，true表示必填
                        title: '负责人：',
                        placeholder: '请输入负责人', //此name为键值
                        name: 'win_user_id',
                        content: '',
                        id: '', //此id为给后台的id值
                        url: '/admin/Statistics/userList',
                        posttype: 'get',
                        inputval: { truename: '' } //这里记录需要传的参数
                    }
                ]
            },
            myselectisshow: true, //高级筛选-select单框
            myselect: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '订单状态：',
                        placeholder: '全部',
                        name: 'pay_status_name',
                        value: '',
                        options: [
                            {
                                value: '',
                                label: '全部'
                            },
                            {
                                value: '1',
                                label: '待付款'
                            },
                            {
                                value: '2',
                                label: '打印中'
                            },
                            {
                                value: '3',
                                label: '已完成'
                            },
                            {
                                value: '4',
                                label: '已反馈'
                            },
                            {
                                value: '5',
                                label: '已退款'
                            },
                            {
                                value: '6',
                                label: '已取消'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '打印状态:',
                        placeholder: '全部',
                        name: 'print_status_name',
                        value: '',
                        options: [
                            {
                                label: '全部',
                                value: ''
                            },
                            {
                                label: '已上传',
                                value: '1'
                            },
                            {
                                label: '扫描仪启动失败',
                                value: '2'
                            },
                            {
                                label: '超时未支付',
                                value: '3'
                            },
                            {
                                label: '文件过期',
                                value: '4'
                            },
                            {
                                label: '发送至打印机超时',
                                value: '5'
                            },
                            {
                                label: '证件照合成失败',
                                value: '6'
                            },
                            {
                                label: '证件照尺寸有误',
                                value: '7'
                            },
                            {
                                label: '文件打开失败',
                                value: '8'
                            },
                            {
                                label: '文件下载失败',
                                value: '9'
                            },
                            {
                                label: '发送失败',
                                value: '10'
                            },
                            {
                                label: '打印失败',
                                value: '11'
                            },
                            {
                                label: '已打印',
                                value: '12'
                            },
                            {
                                label: '待打印',
                                value: '13'
                            }
                        ]
                    },
                    {
                        cascaderisshow: true,
                        ismust: false,
                        title: '打印类型：',
                        placeholder: '请选择打印类型',
                        name: 'printtype',
                        value: '',
                        options: []
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '支付方式:',
                        placeholder: '全部',
                        name: 'paytype',
                        value: '',
                        options: [
                            {
                                label: '全部',
                                value: ''
                            },
                            {
                                label: '微信支付',
                                value: '1'
                            },
                            {
                                label: '余额支付',
                                value: '2'
                            }
                        ]
                    },
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '类型：',
                        placeholder: '全部',
                        name: 'user_type',
                        value: '',
                        options: [
                            ...[
                                {
                                    value: '0',
                                    label: '全部'
                                }
                            ],
                            ...CPS.get('winuserinfo').type
                        ]
                    },
                    {
                        selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
                        ismust: false,
                        title: '付款时间：',
                        placeholder: {
                            placeholderstart: '请选择开始时间',
                            placeholderend: '请选择结束时间'
                        },
                        name: 'paytime',
                        value: {
                            valuestart: ref(''),
                            valueend: ref(''),
                            endstatus: true
                        },
                        rangeseparator: '至', //分隔符
                        divinterval: '3%', //上方字中-框右侧距离
                        selectwidth: '100%', //上方字中-总框长度
                        selectmaxwidth: '97%', //时间段-最大宽度
                        marginright: '0' //与右侧距离
                    }
                ]
            },
            myselecttopisshow: true, //高级筛选-select单框-放input框之前
            myselecttop: {
                divinterval: '3%', //框右侧距离
                selectwidth: '47%',
                selectmaxwidth: '100%',
                select: [
                    {
                        selectisshow: true,
                        ismust: false,
                        title: '平台来源：',
                        placeholder: '全部',
                        name: 'thirdparty',
                        value: '1-1', //显示值，初始状况，显示值与默认值需相同
                        valueisshow: true,
                        valuedefault: '1-1', //设置默认值
                        options: []
                    }
                ]
            },

            // 负责人类型
            CSP_type: CPS.get('winuserinfo').type,

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '580px',
            tancontent: {
                title: '弹框标题',
                content: {}
            }, //弹框

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem('userinfo')), //获取存储的用户信息
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            myprinttype: 1 //打印类型-标识-判断是否需要取最后一个值
        }
    },
    setup() {
        const dialogVisible = ref(false)
        const handleCloseSenior = (done) => {
            done()
        }

        return {
            dialogVisible,
            handleCloseSenior
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 处理状态-加载
        getstatus(configinfo) {
            //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
            var alldatas = Array()
            var num = 0
            alldatas[num] = { value: num, label: '全部' }
            for (var key in configinfo) {
                num = num + 1
                alldatas[num] = { value: key, label: configinfo[key] }
            }
            //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
            this.maintwoforminfo.selectleft.select[0].options = alldatas
            this.myselect.select[0].options = alldatas
        },
        // 打印类型-加载
        getprinttype(configinfo) {
            //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
            let alldatas = Array()
            let num = 0
            Object.keys(configinfo).forEach((element) => {
                if (configinfo[element].children) {
                    let child = this.getrecursion(configinfo[element].children)
                    alldatas[num] = {
                        value: configinfo[element].id,
                        label: configinfo[element].name,
                        children: child
                    }
                } else {
                    alldatas[num] = {
                        value: configinfo[element].id,
                        label: configinfo[element].name
                    }
                }
                num = num + 1
            })
            //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
            this.maintwoforminfo.selectleft.select[0].options = alldatas
            this.myselect.select[2].options = alldatas
        },
        // 打印类型-递归函数
        getrecursion(recursion) {
            let allchilddatas = Array()
            let num = 0
            Object.keys(recursion).forEach((element) => {
                if (recursion[element].children) {
                    let child = this.getrecursion(recursion[element].children)
                    allchilddatas[num] = {
                        value: recursion[element].id,
                        label: recursion[element].name,
                        children: child
                    }
                } else {
                    allchilddatas[num] = {
                        value: recursion[element].id,
                        label: recursion[element].name
                    }
                }
                num = num + 1
            })
            return allchilddatas
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            rows.splice(index, 1)
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            // console.log("this is:" + val);
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.PageSize = val
            this.currentPage = 1

            axios
                .post(
                    '/admin/shareorder/index',
                    {
                        page_size: val,
                        page: 1,
                        client: this.pagesdatas.client,
                        platform: this.pagesdatas.thirdparty,
                        type: this.pagesdatas.type,
                        pay_status: this.pagesdatas.pay_status_name,
                        print_status: this.pagesdatas.print_status_name,
                        paytype: this.pagesdatas.paytype,
                        trade_no: this.pagesdatas.trade_no,
                        uid: this.pagesdatas.uid,
                        timerange: this.pagesdatas.paytime,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    // this.myaxiosfunc(response.data.result);
                    let getdata = response.data.result.data
                    if (getdata.length > 0) {
                        if (getdata.length == this.PageSize) {
                            this.lastpage = this.totalCount / this.PageSize
                            if (this.totalCount / this.PageSize == this.lastpage) {
                                this.totalCount += this.PageSize * 5
                                this.lastpage = this.totalCount / this.PageSize
                            }
                            this.myaxiosfunc(response.data.result)
                        } else {
                            this.totalCount += getdata.length
                            this.lastpage += 1
                            this.myaxiosfunc(response.data.result)
                        }
                    } else {
                        this.myaxiosfunc(response.data.result)
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`);
            this.currentPage = val
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shareorder/index',
                    {
                        page_size: this.PageSize,
                        page: val,
                        platform: this.pagesdatas.thirdparty,
                        type: this.pagesdatas.type,
                        pay_status: this.pagesdatas.pay_status_name,
                        print_status: this.pagesdatas.print_status_name,
                        paytype: this.pagesdatas.paytype,
                        client: this.pagesdatas.client,
                        trade_no: this.pagesdatas.trade_no,
                        uid: this.pagesdatas.uid,
                        timerange: this.pagesdatas.paytime,
                        user_type: this.pagesdatas.user_type,
                        win_user_id: this.pagesdatas.win_user_id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    // this.myaxiosfunc(response.data.result);
                    let getdata = response.data.result.data
                    if (getdata.length > 0) {
                        if (getdata.length == this.PageSize) {
                            this.lastpage = this.totalCount / this.PageSize
                            if (this.totalCount / val == this.PageSize) {
                                this.totalCount += this.PageSize * 5
                                this.lastpage = this.totalCount / this.PageSize
                            }
                            this.myaxiosfunc(response.data.result)
                        } else {
                            this.totalCount += getdata.length
                            this.lastpage = 1
                            this.myaxiosfunc(response.data.result)
                        }
                    } else {
                        this.myaxiosfunc(response.data.result)
                    }
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row'
            }
            return ''
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo)
            this.tancontent.content = taninfo.content
            this.tancontent.title = taninfo.title
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            // 携带参数跳转
            if (rowdata) {
                // 跳转-订单详情
                if (routeraddr === '/orderlistinfo') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                orderno: rowdata.trade_no,
                                platform: this.pagesdatas.thirdparty
                            }
                        })
                        .catch((error) => error)
                }
                // 跳转-用户列表
                if (routeraddr === '/userlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: {
                                userid: rowdata.uid,
                                thirdparty: 1
                            }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shareorder/index',
                    {
                        platform: this.pagesdatas.thirdparty,
                        type: this.pagesdatas.type,
                        pay_status: this.pagesdatas.pay_status_name,
                        print_status: this.pagesdatas.print_status_name,
                        paytype: this.pagesdatas.paytype,
                        client: this.pagesdatas.client,
                        trade_no: this.pagesdatas.trade_no,
                        uid: this.pagesdatas.uid,
                        timerange: this.pagesdatas.paytime,
                        export: 1
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data.message)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },

        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
        searchFormEvent(data) {
            // console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            if (data.printtype.length > 0) {
                this.pagesdatas.type = data.printtype[data.printtype.length - 1] //将-打印类型-的最后一个元素取出，并赋值给type
            }
            var getpaytime = ''
            if (data.paytime.start == '') {
                getpaytime = ''
            } else if (data.paytime.end == '') {
                getpaytime = data.paytime.start + ' ~ ' + (data.paytime.start + 86400 - 1)
            } else {
                getpaytime = data.paytime.start + ' ~ ' + (data.paytime.end + 86400 - 1)
            }
            this.pagesdatas.paytime = getpaytime

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shareorder/index',
                    {
                        page_size: this.PageSize,
                        page: 1,
                        platform: data.thirdparty,
                        type: this.pagesdatas.type,
                        pay_status: data.pay_status_name,
                        print_status: data.print_status_name,
                        paytype: data.paytype,
                        client: data.client,
                        trade_no: data.trade_no,
                        uid: data.uid,
                        timerange: getpaytime,
                        user_type: data.user_type,
                        win_user_id: data.win_user_id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result, 1)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            // console.log("点击查询获取的data数组：" + JSON.stringify(data));
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            if (data.printtype.length > 0) {
                this.pagesdatas.type = data.printtype[data.printtype.length - 1] //将-打印类型-的最后一个元素取出，并赋值给type
            }

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/shareorder/index',
                    {
                        page_size: this.PageSize,
                        page: 1,
                        client: data.client,
                        platform: data.thirdparty,
                        type: this.pagesdatas.type,
                        trade_no: data.trade_no,
                        user_type: data.user_type,
                        win_user_id: data.win_user_id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result, 1)
                })
                .catch(function(error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata, flag) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //转换-付款时间
                if (newdata[i].pay_time) {
                    if (newdata[i].pay_time === 0 || newdata[i].pay_time === null) {
                        newdata[i].pay_time = '无付款时间'
                    } else {
                        newdata[i].pay_time = this.$utils.formatDate(newdata[i].pay_time)
                    }
                } else {
                    newdata[i].pay_time = '无付款时间'
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            // this.totalCount = successdata.total;
            // this.currentpage = successdata.current_page; //当前页面页数
            // this.lastpage = successdata.last_page;
            this.loading = false

            if (successdata.total == null) {
                // 数据小于当前页数时判断
                if (newdata.length < this.PageSize) {
                    if (newdata.length != 0 && this.currentPage == 1) {
                        this.totalCount = this.PageSize - 1
                    } else {
                        if (newdata.length == 0) {
                            // 按照常理：为0会减1页，由于双向绑定问题一直请求服务器，因此先注释，待后续优化
                            // this.totalCount = this.PageSize * (this.currentPage - 1)
                            this.totalCount = this.PageSize * this.currentPage
                        } else {
                            this.totalCount = this.PageSize * this.currentPage
                            this.lastpage = this.currentPage
                        }
                    }
                } else {
                    if (this.totalCount < this.PageSize) {
                        // 重设置-初始值
                        this.totalCount = 1000
                        this.Layout = 'sizes, prev, pager, next, jumper'
                    } else {
                        if (flag == 1) {
                            this.currentPage = 1
                        }
                    }
                }
            } else {
                this.totalCount = successdata.total
                this.currentpage = successdata.current_page //当前页面页数
                this.lastpage = successdata.last_page
                this.Layout = 'total, sizes, prev, pager, next, jumper'
            }
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()

            //加载loading
            this.loading = true
            // 定义
            this.pagesdatas = {
                page: 1,
                page_size: this.PageSize,
                platform: '1-1'
            }
            if (this.$route.query.client) {
                this.maintwoforminfo.myinputonefull.input[0].content = this.$route.query.client
                this.myinputonefullgao.input[0].content = this.$route.query.client
                axios
                    .get('/admin/Equipment/searchClient', {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        },
                        params: { client: this.$route.query.client }
                    })
                    .then((response) => {
                        if (response.data.code == 0) {
                            let getdata = response.data.result.data
                            this.pagesdatas['client'] = getdata[0].id
                            this.maintwoforminfo.myinputonefull.input[0].id = getdata[0].id
                            this.myinputonefullgao.input[0].id = getdata[0].id
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }
            if (this.$route.query.id) {
                this.pagesdatas['uid'] = this.$route.query.id
                this.myinput.input[1].content = this.$route.query.id
            }
            if (this.$route.query.trade_no) {
                this.pagesdatas['trade_no'] = this.$route.query.trade_no
                this.maintwoforminfo.myinputone.input[0].content = this.$route.query.trade_no
            }
            if (this.$route.query.thirdparty) {
                if (this.$route.query.thirdparty == 1) {
                    this.pagesdatas['platform'] = '1-1'
                    this.pagesdatas['thirdparty'] = '1-1'
                }
                if (this.$route.query.thirdparty == 2) {
                    this.pagesdatas['platform'] = '2-1'
                    this.pagesdatas['thirdparty'] = '2-1'
                }
                if (this.$route.query.thirdparty == 3) {
                    this.pagesdatas['platform'] = '1-3'
                    this.pagesdatas['thirdparty'] = '1-3'
                }
                if (this.$route.query.thirdparty == 4) {
                    this.pagesdatas['platform'] = '1-2'
                    this.pagesdatas['thirdparty'] = '1-2'
                }
                if (this.$route.query.thirdparty == 5) {
                    this.pagesdatas['platform'] = '2-3'
                    this.pagesdatas['thirdparty'] = '2-3'
                }
                if (this.$route.query.thirdparty == 6) {
                    this.pagesdatas['platform'] = '2-2'
                    this.pagesdatas['thirdparty'] = '2-2'
                }
            }
            if (this.$route.query.paytime) {
                this.pagesdatas['paytime'] = this.$route.query.paytime
                this.pagesdatas['timerange'] = this.$route.query.paytime
            }
            setTimeout(() => {
                //通过Ajax向后台获取数据
                axios
                    .post('/admin/shareorder/index', this.pagesdatas, {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    })
                    .then((response) => {
                        this.myaxiosfunc(response.data.result)
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            }, 500)

            // 获取订单列表字典项
            axios
                .get('/admin/shareorder/index', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                })
                .then((res) => {
                    this.orderListDic = res.data.result
                    // console.log(this.orderListDic);
                    this.getprinttype(this.orderListDic.printtype)

                    var orderArr = [{ label: '全部', value: '' }]
                    var print_status_arr = [{ label: '全部', value: '' }]
                    var paytype = [{ label: '全部', value: '' }]
                    var platformArr = []

                    // 订单状态
                    for (let key in this.orderListDic.pay_status) {
                        orderArr.push({
                            label: this.orderListDic.pay_status[key],
                            value: key
                        })
                    }
                    // 打印状态
                    for (let key in this.orderListDic.print_status) {
                        print_status_arr.push({
                            label: this.orderListDic.print_status[key],
                            value: key
                        })
                    }
                    // 支付方式
                    for (let key in this.orderListDic.paytype) {
                        paytype.push({ label: this.orderListDic.paytype[key], value: key })
                    }
                    // 平台来源
                    this.orderListDic.platform.forEach((item) => {
                        platformArr.push({ label: item.name, value: item.value })
                    })
                    this.maintwoforminfo.selecttop.select[0].options = platformArr
                    this.myselect.select[0].options = orderArr
                    this.myselect.select[1].options = print_status_arr
                    this.myselect.select[3].options = paytype
                    this.myselecttop.select[0].options = platformArr //平台来源-高级搜索
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

.box-list {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    line-height: 40px;
    padding: 0 20px;
}
</style>
