<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <maintwo
            :maintwoiconshow="maintwoiconshow"
            :maintwoforminfo="maintwoforminfo"
            :myinputoneisshow="maintwoforminfo.myinputoneisshow"
            :myinputone="maintwoforminfo.myinputone"
            :myselectleftisshow="maintwoforminfo.selectleftisshow"
            :myselectleft="maintwoforminfo.selectleft"
            :myinputishow="myinputishow"
            :myinput="myinput"
            :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
            :myinputonefull="maintwoforminfo.myinputonefull"
            :myselectisshow="myselectisshow"
            :myselect="myselect"
            :myselectcityisshow="myselectcityisshow"
            :myselectcity="myselectcity"
            v-on:getsearchinfo="getSeachinfoEvent"
            v-on:searchForm="searchFormEvent"
        ></maintwo>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <el-button type="primary" plain @click="routeraddrClick('/linktitleadd')" v-if="this.mypowerlimits.ljbty_tj">添加</el-button>
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        :header-cell-style="{ background: '#F5F7FA' }"
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <!-- <template #default="scope" v-if="mytabletitle.prop === 'uid'"> -->
                            <template #default="scope" v-if="mytabletitle.prop === 'file_url'">
                                <div v-if="mytabletitle.prop === 'file_url'">
                                    <el-image
                                        class="imagesshow"
                                        :src="scope.row.file_url"
                                        :preview-src-list="[scope.row.file_url]"
                                        fit="cover"
                                        @error="(data) => errorImagesEvent(data, scope.row)"
                                    >
                                    </el-image>
                                </div>
                            </template>
                        </el-table-column>
                        <!--操作&按钮-->
                        <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope" prop="myoperation" id="operation">
                            <template #default="scope">
                                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                                <!--操作-运营-幸运大抽奖-->
                                <div>
                                    <el-button @click="routeraddrClick('/linktitleedit', scope.row)" type="primary" size="mini" v-if="this.mypowerlimits.ljbty_bj"
                                        >编辑</el-button
                                    >
                                    <el-button @click="deleteRow(index, scope.row)" type="danger" size="mini" v-if="this.mypowerlimits.ljbty_sc">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import { ElMessageBox, ElMessage } from 'element-plus'
import axios from 'axios'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'vlinktitle',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '企业微信'
                },
                {
                    name: '链接标题语'
                }
            ], // 当前页-【面包屑导航】
            maintwoiconshow: false, // 筛选-【高级筛选按钮】
            maintwoforminfo: {
                myinputoneisshow: true, //筛选查询-左侧字+input
                myinputone: {
                    divinterval: '0', //框右侧距离
                    titlewidth: '74px', //标题宽度
                    inputwidth: '200px', //input宽度
                    inputinterval: '20px', //input右侧距离
                    input: [
                        {
                            ismust: false, //是否必填，true表示必填
                            title: '标题：',
                            placeholder: '请输入标题',
                            name: 'title',
                            content: ''
                        }
                    ]
                },
            }, // 筛选-控件信息
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 27, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 120, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'id',
                    label: 'id',
                    width: '80'
                },
                {
                    fixedstatu: false,
                    prop: 'title',
                    label: '标题<title>',
                    width: '180'
                },
                {
                    fixedstatu: false,
                    prop: 'description',
                    label: '描述<description>',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'file_url',
                    label: '图片<image>',
                    width: '140'
                },
                {
                    fixedstatu: false,
                    prop: 'create_url',
                    label: '生成链接',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'jump_url',
                    label: '要跳转链接',
                    width: ''
                },
                {
                    fixedstatu: false,
                    prop: 'truename',
                    label: '创建人',
                    width: '150'
                },
                {
                    fixedstatu: false,
                    prop: 'addtime',
                    label: '创建时间',
                    width: '200'
                },
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            currentpage: 1, //数据列表中-数据当前页数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
            loading: false, //初始化-loading加载动效，默认是false关闭

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100, 1000],
            PageSize: 20,
            multipleSelection: [],
            thispagename: '', //通过路由获取当前页面的path值
            searchwidth: '450px',
            tancontent: {
                title: '备注',
                content: {}
            }, //弹框

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [] //存储最近一次的筛选条件，供分页使用
        }
    },
    setup() {
        const dialogVisible = ref(false)
        const remarkId = ref('')
        const handleCloseSenior = (done) => {
            done()
        }

        return {
            dialogVisible,
            remarkId,
            handleCloseSenior
        }
    },
    mounted() {
        //获取当前页面路由path值
        this.thispagename = this.$route.path
    },
    methods: {
        // 加载图片失败时，显示的默认图片
        errorImagesEvent(e, rowdata) {
            rowdata.images = require('@/assets/imgs/defaultimegs/delimage.jpg')
        },
        remarks(data) {
            this.dialogVisible = true
            this.remarkId = data.id
            this.seniorform.textarea = data.remark
        },
        //table表单-点击单位格-带参跳转等
        handleOneData(row, column, cell, event) {
            console.log(row) //获取当前行数据
            console.log(column) //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
            console.log(event) //获取当前指针事件

            //获取所点击按钮的id值-并根据不同的id值进行跳转&传值等
            if (event.path && event.path[1].id) {
                //由操作-合作-公司列表-向-公司关联设备页面传值
                if (event.path[1].id === 'companydvcid') {
                    this.$router
                        .push({
                            path: '/companydvclist',
                            query: { id: row.id, name: row.name }
                        })
                        .catch((error) => error)
                }
                //由操作-合作-设备合伙人列表-向-合伙人关联设备页面传值
                if (event.path[1].id === 'partnerdvcid') {
                    this.$router.push({ path: '/partnerdvclist', query: { id: row.id } }).catch((error) => error)
                }
            }

            console.log('当前的column.property 是：' + column.property)
            if (column.property === 'secondagent') {
                //跳转二级代理
                this.$router.push('/agentcensustwo').catch((error) => error)
            }
        },
        //table表-操作中-删除按钮（函数）
        deleteRow(index, rows) {
            //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
            const { id } = rows

            ElMessageBox.confirm(`确定删除该条${rows.title}数据吗?`, '提示：', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                axios
                    .post(
                        '/admin/passiveReply/delete',
                        {
                            id
                        },
                        {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        }
                    )
                    .then(() => {
                        ElMessage({
                            type: 'success',
                            message: '操作成功！',
                            duration: 1000
                        })
                        this.reload() //刷新
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error)
                    })
            })
        },
        //table表中-当选项发生变化是触发该函数
        handleSelectionChange(val) {
            console.log('this is:' + val)
            this.multipleSelection = val
        },
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/passiveReply/index',
                    {
                        title: this.pagesdatas.title,
                        page: 1,
                        page_size: val
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            console.log(`当前页: 第${val}页`)
            this.currentPage = val

            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/passiveReply/index',
                    {
                        title: this.pagesdatas.title,
                        page: val,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //table表中-表格带状态-可扩展
        tableRowClassName({ rowIndex }) {
            //隔行显示
            if ((rowIndex + 1) % 2 === 0) {
                return 'warning-row'
            }
            return ''
        },
        //点击按钮-弹出弹框并赋值弹窗基本配置
        addTanClick(taninfo) {
            console.log(taninfo)
            this.tancontent.content = taninfo.content
            this.tancontent.title = taninfo.title
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            console.log({ rowdata })
            // 携带参数跳转
            if (rowdata) {
                // 跳转-用户列表
                if (routeraddr === '/userlist') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { userid: rowdata.uid, thirdparty: 1 }
                        })
                        .catch((error) => error)
                }

                if (routeraddr === '/linktitleedit') {
                    this.$router.push({ path: routeraddr, query: { id: rowdata.id } })
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //弹出框-提交表单
        onSubmitSenior() {
            //获取输入框字段与字段名并合成json数据
            console.log(this.$refs.seniorform)
            axios
                .post(
                    '/admin/ActivityGoods/remark',
                    {
                        id: this.remarkId,
                        remark: this.seniorform.textarea
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        this.dialogVisible = false //隐藏高级筛选模块
                        this.$message({
                            message: response.data.message,
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.reload() //刷新
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        },
        //弹出框-重置
        resetFormSenior() {
            this.seniorform.textarea = ''
            this.dialogVisible = false
        },
        //获取当前windows页面信息-实时修改【弹窗】的宽度
        getwindowinfo() {
            this.sreenheight = document.documentElement.clientHeight
            this.sreenwidth = document.documentElement.clientWidth
            if (this.sreenwidth < 600) {
                this.searchwidth = this.sreenwidth * 0.8 + 'px'
            } else {
                this.searchwidth = '580px'
            }
        },

        //提交等相关event事件函数
        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            console.log('点击查询获取的data数组：' + JSON.stringify(data))
            this.pagesdatas = data //将筛选条件赋值给本页的pagesdatas存储，供分页使用
            //加载loading
            this.loading = true
            //通过Ajax向后台获取数据
            axios
                .post(
                    '/admin/passiveReply/index',
                    {
                        title: data.title,
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error)
                })
        },
        //数据渲染
        myaxiosfunc(successdata) {
            var newdata = successdata.data
            for (var i = 0; i < newdata.length; i++) {
                //转换-申请时间
                if (newdata[i].addtime) {
                    if (newdata[i].addtime === 0 || newdata[i].addtime === null) {
                        newdata[i].addtime = '--'
                    } else {
                        // console.log('添加时间：' + newdata[i].addtime)
                        newdata[i].addtime = this.$utils.formatDate(newdata[i].addtime)
                    }
                } else {
                    newdata[i].addtime = '--'
                }
                //转换-支付时间paytime
                if (newdata[i].paytime) {
                    if (newdata[i].paytime === 0 || newdata[i].paytime === null) {
                        newdata[i].paytime = '--'
                    } else {
                        // console.log('添时间：' + newdata[i].addtime)
                        newdata[i].paytime = this.$utils.formatDate(newdata[i].paytime)
                    }
                } else {
                    newdata[i].paytime = '--'
                }
            }
            // console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },

        formatLen(val, len) {
            return val.length > len ? `${val.slice(0, len)}...` : val
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            window.addEventListener('resize', this.getwindowinfo) // 注册监听器-实时检测浏览器页面宽高
            this.getwindowinfo()
            console.log(this)
            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据/admin/config/info
            axios
                .post(
                    '/admin/passiveReply/index',
                    {
                        page: 1,
                        page_size: this.PageSize
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myaxiosfunc(response.data.result)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        maintwo
    }
})
</script>

<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
    min-height: 28px;
    padding: 0 8px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}

/*局部控制-Switch按钮-按钮中显示状态文字*/
/* switch按钮款式 */
#switch :deep(.el-switch__label) {
    position: absolute;
    display: none;
    color: #fff !important;
}

/*关上时文字地位设置*/
#switch :deep(.el-switch__label--right) {
    z-index: 1;
}

/* 调整关上时文字的显示位子 */
#switch :deep(.el-switch__label--right) span {
    margin-left: 3px;
    font-size: 12px;
}

/*敞开时文字地位设置*/
#switch :deep(.el-switch__label--left) {
    z-index: 1;
}

/* 调整敞开时文字的显示位子 */
#switch :deep(.el-switch__label--left) span {
    margin-left: 15px;
    font-size: 12px;
}

/*显示文字*/
#switch :deep(.el-switch__label.is-active) {
    display: block;
}

/* 调整按钮的宽度 */
#switch :deep(.el-switch) .el-switch__core,
#switch :deep(.el-switch) .el-switch__label {
    width: 55px !important;
    margin: 0;
    padding: 5px;
}
.tool-tip {
    max-width: 400px;
}
</style>
