<template>
    <div>
        <el-form ref="formRef" :model="form" :rules="rules" label-width="260px">
            <el-form-item label="会员名称：" prop="name">
                <el-input v-model="form.name" clearable maxlength="10" show-word-limit />
            </el-form-item>
            <el-form-item label="权益会员广告位（我的页）：" prop="person_img">
                <mainimgesup
                    v-model="form.person_img"
                    :myimgesup="myimgesup"
                    :myimgesupisshow="myimgesupisshow"
                    :filelist="form.person_img_list"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 1)"
                    v-on:delfiledata="(data) => delfiledataEvent(data, 1)"
                >
                </mainimgesup>
            </el-form-item>
            <el-form-item label="权益会员广告位（会员页）：" prop="buy_vip_img">
                <mainimgesup
                    v-model="form.buy_vip_img"
                    :myimgesup="myimgesup1"
                    :myimgesupisshow="myimgesupisshow1"
                    :filelist="form.buy_vip_img_list"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 2)"
                    v-on:delfiledata="(data) => delfiledataEvent(data, 2)"
                >
                </mainimgesup>
            </el-form-item>
            <el-form-item label="打印享折扣（会员页）：" prop="print_discount">
                <mainimgesup
                    v-model="form.print_discount"
                    :myimgesup="myimgesup2"
                    :myimgesupisshow="myimgesupisshow2"
                    :filelist="form.print_discount_list"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 3)"
                    v-on:delfiledata="(data) => delfiledataEvent(data, 3)"
                >
                </mainimgesup>
            </el-form-item>
            <el-form-item label="权益会员广告位（会员提交页）：" prop="buy_vip_submit_img">
                <mainimgesup
                    v-model="form.buy_vip_submit_img"
                    :myimgesup="myimgesup3"
                    :myimgesupisshow="myimgesupisshow3"
                    :filelist="form.buy_vip_submit_img_list"
                    v-on:getuploadfile="(data) => getUploadFileEvent(data, 4)"
                    v-on:delfiledata="(data) => delfiledataEvent(data, 4)"
                >
                </mainimgesup>
            </el-form-item>
            <el-form-item label="文库权益说明：" prop="library_use_descr">
                <el-input v-model="form.library_use_descr" type="textarea" clearable maxlength="500" show-word-limit />
            </el-form-item>
            <el-form-item label="打印折扣说明（所有门店）：" prop="print_discount_store_descr">
                <el-input v-model="form.print_discount_store_descr" type="textarea" clearable maxlength="500" show-word-limit />
            </el-form-item>
            <el-form-item label="打印折扣说明（门店活动）：" prop="print_discount_store_activity_descr">
                <el-input v-model="form.print_discount_store_activity_descr" type="textarea" clearable maxlength="500" show-word-limit />
            </el-form-item>
            <el-form-item label="送余额说明：" prop="send_cash_descr">
                <el-input v-model="form.send_cash_descr" type="textarea" clearable maxlength="500" show-word-limit />
            </el-form-item>
            <el-form-item label="会员规则：" prop="buy_vip_rule">
                <el-input v-model="form.buy_vip_rule" type="textarea" clearable maxlength="500" show-word-limit />
            </el-form-item>
            <el-form-item label="详细说明：" prop="buy_vip_descr"> <TEditor ref="editorfile" v-model="form.buy_vip_descr" @geteditordatas="getEditorDatasEvent" /> </el-form-item>
            <el-form-item label="适用地区：" required="true">
                <el-form-item label-width="0px" prop="address_type">
                    <el-radio-group v-model="form.address_type">
                        <el-radio label="1">全平台</el-radio>
                        <el-radio label="2">适用地区</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label-width="0px" prop="address_code" v-if="form.address_type == 2">
                    <modelCity
                        :value="form.address_code"
                        :props="{ multiple: true, checkStrictly: true, emitPath: false }"
                        :placeholder="'请绑定地区'"
                        :collapsetags="false"
                        :filterable="true"
                        @changecity="changecityEvent"
                    />
                </el-form-item>
            </el-form-item>
            <el-form-item label="开启状态：" prop="status">
                <el-radio-group v-model="form.status">
                    <el-radio label="1">开启</el-radio>
                    <el-radio label="-1">关闭</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item> <el-button type="primary" style="width: 200px" @click="submitEvent('formRef')" :disabled="button_disable">保存</el-button></el-form-item>
        </el-form>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import TEditor from '@/components/Maineditor.vue'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ElMessage } from 'element-plus'
import modelCity from '../../wecom/minipluswecom/modelCity.vue'

export default defineComponent({
    name: 'benefitsSet',
    inject: ['reload'], //刷新引用
    data() {
        return {
            form: {
                name: '',
                person_img: '',
                buy_vip_img: '',
                buy_vip_rule: '',
                buy_vip_descr: '',
                print_discount: '',
                buy_vip_submit_img: '',
                library_use_descr: '',
                print_discount_store_descr: '',
                print_discount_store_activity_descr: '',
                send_cash_descr: '',
                address_type: '1',
                address_code: '',
                status: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '会员名称为必填项',
                        trigger: 'blur'
                    }
                ],
                person_img: [
                    {
                        required: true,
                        message: '权益会员广告位（我的页）为必填项',
                        trigger: ['blur', 'change']
                    }
                ],
                buy_vip_img: [
                    {
                        required: true,
                        message: '权益会员广告位（会员页）为必填项',
                        trigger: ['blur', 'change']
                    }
                ],
                buy_vip_rule: [
                    {
                        required: true,
                        message: '会员规则为必填项',
                        trigger: 'blur'
                    }
                ],
                buy_vip_descr: [
                    {
                        required: true,
                        message: '详细说明为必填项',
                        trigger: 'blur'
                    }
                ],
                print_discount: [
                    {
                        required: false,
                        message: '打印享折扣（会员页）为必填项',
                        trigger: ['blur', 'change']
                    }
                ],
                buy_vip_submit_img: [
                    {
                        required: false,
                        message: '权益会员广告位（会员提交页）为必填项',
                        trigger: ['blur', 'change']
                    }
                ],
                library_use_descr: [
                    {
                        required: true,
                        message: '文库权益说明为必填项',
                        trigger: 'blur'
                    }
                ],
                print_discount_store_descr: [
                    {
                        required: true,
                        message: '打印折扣说明（所有门店）为必填项',
                        trigger: 'blur'
                    }
                ],
                print_discount_store_activity_descr: [
                    {
                        required: true,
                        message: '打印折扣说明（门店活动）为必填项',
                        trigger: 'blur'
                    }
                ],
                send_cash_descr: [
                    {
                        required: true,
                        message: '送余额说明为必填项',
                        trigger: 'blur'
                    }
                ],
                address_type: [
                    {
                        required: true,
                        message: '适用地区为必选项',
                        trigger: 'blur'
                    }
                ],
                address_code: [
                    {
                        required: true,
                        message: '适用地区为必填项',
                        trigger: 'blur'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '开启状态为必选项',
                        trigger: 'blur'
                    }
                ]
            },

            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 1, //选择上传图片的展现框-1阿里oss直传，2PHP的接口传输
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/file', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '点击上传', //button-按钮文字
                tips: '建议上传：*.png、*.jpg、*.jpeg格式', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 50000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },
            myimgesupisshow1: true, //form表单-imgesuploade上传图片
            myimgesup1: {
                uploadsize: 1, //选择上传图片的展现框-1阿里oss直传，2PHP的接口传输
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/file', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '点击上传', //button-按钮文字
                tips: '建议上传：*.png、*.jpg、*.jpeg格式', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 50000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },
            myimgesupisshow2: true, //form表单-imgesuploade上传图片
            myimgesup2: {
                uploadsize: 1, //选择上传图片的展现框-1阿里oss直传，2PHP的接口传输
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/file', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '点击上传', //button-按钮文字
                tips: '建议上传：*.png、*.jpg、*.jpeg格式', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 50000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },
            myimgesupisshow3: true, //form表单-imgesuploade上传图片
            myimgesup3: {
                uploadsize: 1, //选择上传图片的展现框-1阿里oss直传，2PHP的接口传输
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/file', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '点击上传', //button-按钮文字
                tips: '建议上传：*.png、*.jpg、*.jpeg格式', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 100, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 50000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },

            // 前端防抖
            button_disable: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')) //获取权限
        }
    },
    methods: {
        // 选中地区
        changecityEvent(data) {
            this.form.address_code = data
        },
        //获取upload子组件回传的数据
        getUploadFileEvent(data, flag) {
            // console.log('当前获取的回传数据：', data)
            if (flag == 1) {
                this.form.person_img = data.url
                this.form.person_img_list = [
                    {
                        name: data.url.split('/')[data.url.split('/').length - 1],
                        url: data.url
                    }
                ]
            } else if (flag == 2) {
                this.form.buy_vip_img = data.url
                this.form.buy_vip_img_list = [
                    {
                        name: data.url.split('/')[data.url.split('/').length - 1],
                        url: data.url
                    }
                ]
            } else if (flag == 3) {
                this.form.print_discount = data.url
                this.form.print_discount_list = [
                    {
                        name: data.url.split('/')[data.url.split('/').length - 1],
                        url: data.url
                    }
                ]
            } else if (flag == 4) {
                this.form.buy_vip_submit_img = data.url
                this.form.buy_vip_submit_img_list = [
                    {
                        name: data.url.split('/')[data.url.split('/').length - 1],
                        url: data.url
                    }
                ]
            }
        },
        //删除上传的文件-事件
        delfiledataEvent(data, flag) {
            // console.log('当前删除事件-返回的数据：', data)
            if (flag == 1) {
                this.form.person_img = ''
                this.form.person_img_list = []
            } else if (flag == 2) {
                this.form.buy_vip_img = ''
                this.form.buy_vip_img_list = []
            } else if (flag == 3) {
                this.form.print_discount = ''
                this.form.print_discount_list = []
            } else if (flag == 4) {
                this.form.buy_vip_submit_img = ''
                this.form.buy_vip_submit_img_list = []
            }
        },
        getEditorDatasEvent(datas) {
            this.form.buy_vip_descr = datas
        },
        submitEvent(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.button_disable = true

                    axios
                        .post(
                            '/admin/config/update',
                            {
                                key_name: 'vip_show_style',
                                contents: getdatas
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.button_disable = false
                                    }
                                })
                            } else {
                                this.button_disable = false
                            }
                        })
                        .catch(function (error) {
                            this.button_disable = false
                        })
                } else {
                    return false
                }
            })
        }
    },
    created() {
        axios
            .post(
                '/admin/config/info',
                {
                    key_name: 'vip_show_style'
                },
                {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    }
                }
            )
            .then((response) => {
                if (response.data.result) {
                    this.form = response.data.result
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        setTimeout(() => {
            this.$refs.editorfile.postValue(this.form.buy_vip_descr)
        }, 500)
    },
    components: {
        TEditor,
        mainimgesup,
        modelCity
    }
})
</script>
<style scoped></style>
