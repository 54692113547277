<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box">
                <div class="box_title">
                    <div class="box_title_font">编辑广告位</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="4">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="12">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="160px" class="demo-ruleForm">
                                <el-form-item label="广告名称：" prop="name">
                                    <el-input v-model="ruleForm.name"></el-input>
                                    <div style="height: 20px; line-height: 20px">
                                        <span style="font-size: 12px; color: #999999">广告名称只是作为辨别多个广告条目之用，并不显示在广告中</span>
                                    </div>
                                </el-form-item>
                                <el-form-item label="广告位置：" prop="type_id">
                                    <el-select v-model="ruleForm.type_id" placeholder="请选择" style="width: 100%">
                                        <el-option :label="gettypeid.name" :value="gettypeid.id" v-for="(gettypeid, index) in mytyppid" :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="排序：">
                                    <el-input v-model="ruleForm.sort" placeholder="数字越大排序越高"></el-input>
                                </el-form-item>
                                <el-form-item label="开始时间：">
                                    <el-date-picker v-model="ruleForm.begin_time" type="datetime" placeholder="不选择时间代表永久" style="width: 100%" @change="changeBeginTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="到期时间：">
                                    <el-date-picker v-model="ruleForm.end_time" type="datetime" placeholder="不选择时间代表永久" style="width: 100%" @change="changeEndTime">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="状态：">
                                    <el-radio-group v-model="ruleForm.status">
                                        <el-radio :label="1">上线</el-radio>
                                        <el-radio :label="-1">下线</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="广告类型：">
                                    <el-radio-group v-model="ruleForm.update_type">
                                        <el-radio label="1">图片</el-radio>
                                        <el-radio label="2">视频</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="广告图片：" prop="image" v-if="ruleForm.update_type == 1">
                                    <mainimgesup
                                        v-model="ruleForm.image"
                                        :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow"
                                        :filelist="ruleForm.image_list"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data, 1)"
                                        v-on:delfiledata="(data) => delfiledataEvent(data, 1)"
                                    ></mainimgesup>
                                </el-form-item>
                                <el-form-item label="视频背景图：" prop="image" v-if="ruleForm.update_type == 2">
                                    <mainimgesup
                                        v-model="ruleForm.image"
                                        :myimgesup="myimgesup"
                                        :myimgesupisshow="myimgesupisshow"
                                        :filelist="ruleForm.image_list"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data, 1)"
                                        v-on:delfiledata="(data) => delfiledataEvent(data, 1)"
                                    ></mainimgesup>
                                </el-form-item>
                                <el-form-item label="视频MP4：" prop="mp4" v-if="ruleForm.update_type == 2">
                                    <mainimgesup
                                        v-model="ruleForm.mp4"
                                        :myimgesup="myimgesup1"
                                        :myimgesupisshow="myimgesupisshow1"
                                        :filelist="ruleForm.mp4_list"
                                        v-on:getuploadfile="(data) => getUploadFileEvent(data, 2)"
                                        v-on:delfiledata="(data) => delfiledataEvent(data, 2)"
                                    >
                                    </mainimgesup>
                                </el-form-item>
                                <el-form-item label="跳转方式：">
                                    <el-radio-group v-model="ruleForm.jump_way" @change="changeJumpEvent">
                                        <el-radio :label="1" v-if="this.inids == -1">站内链接</el-radio>
                                        <el-radio :label="2">站外小程序</el-radio>
                                        <el-radio :label="3">h5链接/站外网页</el-radio>
                                        <el-radio :label="4">视频号</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!-- 站内链接 -->
                                <div v-if="ruleForm.jump_way == 1">
                                    <el-form-item label="站内链接：">
                                        <el-select v-model="ruleForm.mini_path" placeholder="请选择" style="width: 100%">
                                            <el-option :label="gettypeid.title" :value="gettypeid.path" v-for="(gettypeid, index) in mywebview" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <!-- 站外小程序 -->
                                <div v-if="ruleForm.jump_way == 2">
                                    <el-form-item label="小程序APPID：">
                                        <el-select v-model="ruleForm.outer_path" placeholder="请选择" style="width: 100%">
                                            <el-option :label="gettypeid.title" :value="gettypeid.appid" v-for="(gettypeid, index) in myouter" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="小程序路径：">
                                        <el-input v-model="ruleForm.outer_url" clearable placeholder="请填写小程序路径"></el-input>
                                        <div style="height: 20px; line-height: 20px">
                                            <span style="font-size: 12px; color: #999999">小程序地址为空表示默认跳转小程序首页</span>
                                        </div>
                                    </el-form-item>
                                </div>
                                <!-- h5链接/站外网页 -->
                                <div v-if="ruleForm.jump_way == 3">
                                    <el-form-item label="跳转路径：">
                                        <!-- <el-select v-model="ruleForm.wechat_path" placeholder="请选择" style="width: 100%">
                                                <el-option :label="gettypeid.title" :value="gettypeid.path" v-for="(gettypeid, index) in mywechat"
                                                :key="index"></el-option>
                                            </el-select> -->
                                        <el-input v-model="ruleForm.wechat_path" clearable placeholder="请填写以http://或https://开头的跳转路径"> </el-input>
                                    </el-form-item>
                                </div>
                                <!-- 视频号 -->
                                <div v-if="ruleForm.jump_way == 4">
                                    <el-form-item label="跳转位置：">
                                        <el-radio-group v-model="ruleForm.wechat_path">
                                            <el-radio label="1">视频号主页</el-radio>
                                            <el-radio label="2">视频号直播间</el-radio>
                                            <el-radio label="3">视频号视频</el-radio>
                                            <el-radio label="4">视频号活动页</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="视频号ID：">
                                        <el-input v-model="ruleForm.outer_path" clearable placeholder="请输入视频号ID" maxlength="50" show-word-limit> </el-input>
                                    </el-form-item>
                                    <el-form-item label="视频ID" v-if="ruleForm.wechat_path == 3">
                                        <el-input v-model="ruleForm.mini_path" clearable placeholder="请输入视频ID" maxlength="240" show-word-limit> </el-input>
                                    </el-form-item>
                                    <el-form-item label="活动ID" v-if="ruleForm.wechat_path == 4">
                                        <el-input v-model="ruleForm.mini_path" clearable placeholder="请输入活动ID" maxlength="240" show-word-limit> </el-input>
                                    </el-form-item>
                                </div>

                                <el-form-item label="显示平台：">
                                    <el-checkbox-group v-model="ruleForm.platform">
                                        <el-checkbox label="1">微信自助版</el-checkbox>
                                        <el-checkbox label="2">支付宝自助版</el-checkbox>
                                        <el-checkbox label="3" v-if="this.inids == -1">微信企业版</el-checkbox>
                                        <el-checkbox label="4" v-if="this.inids == -1">微信家庭版</el-checkbox>
                                        <el-checkbox label="5" v-if="this.inids == -1">支付宝企业版</el-checkbox>
                                        <el-checkbox label="6" v-if="this.inids == -1">支付宝家庭版</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>

                                <!-- 分割 -->
                                <el-divider content-position="left">投放规则</el-divider>
                                <el-form-item label="生效区域：" prop="valid_area">
                                    <cityCodeCascaderBox
                                        :ModelValue="ruleForm.valid_area"
                                        :Placeholder="'请选择生效区域'"
                                        :ShowAllLevels="false"
                                        :CollapseTags="true"
                                        :CollapseTagsTooltip="true"
                                        v-on:changedatas="changedatasEvent"
                                    />
                                    <div>
                                        <span style="color: #999; font-size: 12px"><i class="el-icon-info"></i> 生效区域：数据为空，表示全部区域生效！</span>
                                    </div>
                                </el-form-item>
                                <el-form-item label="投放时段：" required="true" prop="time_span">
                                    <div>
                                        <el-button type="primary" size="small" style="padding: 0px 20px; width: 80px" @click="addTimePickerEvent">添加</el-button
                                        ><span style="margin-left: 10px; color: #999; font-size: 12px"><i class="el-icon-info"></i> 最多添加5个时段</span>
                                    </div>
                                    <div style="margin: 10px 0 20px" v-for="(item, index) in ruleForm.time_span" :key="index">
                                        <el-form-item :prop="'time_span.' + index + '.times'" :rules="rules.times">
                                            <el-time-picker
                                                v-model="item.times"
                                                is-range
                                                range-separator="-"
                                                start-placeholder="开始时间"
                                                end-placeholder="结束时间"
                                                style="width: calc(100% - 40px)"
                                                @change="changeTimePickerEvent"
                                            />
                                            <el-button type="danger" style="margin-left: 10px; vertical-align: middle; padding: 0 5px" @click="delTimePickerEvent(index)"
                                                ><i class="el-icon-delete"></i
                                            ></el-button>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                                <el-form-item label="浏览次数：" prop="view_type">
                                    <el-radio-group v-model="ruleForm.view_type">
                                        <el-radio label="1">不限 </el-radio>
                                        <el-radio label="2">
                                            <div style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: left; align-items: center">
                                                <div style="margin-right: 10px">
                                                    <span>指定</span>
                                                </div>
                                                <div>
                                                    <el-form-item v-if="ruleForm.view_type == 2" prop="view_num" :rules="rules.view_num"
                                                        ><el-input v-model="ruleForm.view_num" maxlength="50" style="width: 120px" clearable /> 次</el-form-item
                                                    >
                                                    <el-form-item v-else><el-input v-model="ruleForm.view_num" maxlength="50" style="width: 120px" clearable /> 次</el-form-item>
                                                </div>
                                            </div>
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="新老用户：" prop="user_type">
                                    <el-checkbox-group v-model="ruleForm.user_type">
                                        <el-checkbox label="1">新用户</el-checkbox>
                                        <el-checkbox label="2">老用户</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                                <el-form-item label="机型：" prop="mobile_type">
                                    <el-checkbox-group v-model="ruleForm.mobile_type">
                                        <el-checkbox :label="items.id.toString()" v-for="(items, index) in this.mobile_type_options" :key="index">{{ items.name }}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>

                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%; margin-right: 10px">提交 </el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import mainimgesup from '@/components/Mainimgesup.vue'
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vappletadvertedit',
    data() {
        return {
            mainonebread: [
                {
                    name: '运营'
                },
                {
                    name: '小程序广告位管理'
                },
                {
                    name: '广告位列表',
                    path: '/appletadvert'
                },
                {
                    name: '编辑广告位'
                }
            ], // 当前页-【面包屑导航】

            myimgesupisshow: true, //form表单-imgesuploade上传图片
            myimgesup: {
                uploadsize: 2, //选择上传图片的展现框-1阿里oss直传，2PHP的接口传输
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/file', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '点击上传', //button-按钮文字
                tips: '只能上传jpg/png格式文件，文件不能超过2MB', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 5, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    images: {
                        image_width: 0, //图片宽度
                        image_height: 0, //图片高度
                        size: 2000, //文件大小（单位kb）
                        type: ['image/jpeg', 'image/jpg', 'image/png'], //文件格式
                        typechart: 'jpg/png' //文件格式提示规则
                    }
                }
            },
            myimgesupisshow1: true, //form表单-imgesuploade上传图片
            myimgesup1: {
                uploadsize: 1, //选择上传图片的展现框-1阿里oss直传，2PHP的接口传输
                listtype: 'picture', //list展示类型，text/picture/picture-card；默认不填为text
                ismust: true, //是否必填，true表示必填
                action: '/admin/upload/file', //upload上传API地址
                headers: { Authentication: localStorage.getItem('token') }, //upload上传headers
                title: '点击上传', //button-按钮文字
                tips: '只能上传MP4格式文件，文件不能超过10MB', //提示&建议文字
                limit: '1', //上传数量限制
                target_id: 5, //上传文件target_id ：图片分类，区分存储文件和存储时长，不传默认存储1天后过期；1-二维码； 2-打印文件； 3-文库文件； 4-课程文件； 5-广告主宣传页； 6-广告主产生的pdf文件； 7-证件照文件； 50-设备广告； 100-通用；
                rules: {
                    video: {
                        size: 10000, //文件大小（单位kb）
                        type: ['video/mp4'], //文件格式
                        typechart: 'mp4' //文件格式提示规则
                    }
                }
            },
            mytyppid: [], //广告位-配置
            mywebview: [], //webview跳转链接-配置
            mywechat: [], //H5/公众号跳转链接-配置
            myouter: [], //小程序APPID-配置
            ruleForm: {
                name: '',
                type_id: '',
                update_type: '1',
                image: '',
                image_list: [],
                mp4: '',
                mp4_list: [],
                status: ref('1'),
                jump_way: ref('1'),
                mini_path: '',
                wechat_path: '',
                outer_path: '',
                outer_url: '',
                begin_time: '',
                end_time: '',
                sort: '',
                platform: [],
                valid_area: [],
                time_span: [],
                view_type: '',
                view_num: '',
                user_type: [],
                mobile_type: []
            }, //form表单
            rules: {
                name: [
                    {
                        required: true,
                        message: '广告名称为必填项',
                        trigger: 'blur'
                    }
                ],
                type_id: [
                    {
                        required: true,
                        message: '请选择广告位置',
                        trigger: 'change'
                    }
                ],
                image: [
                    {
                        required: true,
                        message: '请上传图片',
                        trigger: 'change'
                    }
                ],
                mp4: [
                    {
                        required: true,
                        message: '请上传广告视频',
                        trigger: 'change'
                    }
                ],
                times: [
                    {
                        required: true,
                        message: '投放时段为必填项',
                        trigger: 'change'
                    }
                ],
                time_span: [
                    {
                        required: true,
                        message: '投放时段为必填项',
                        trigger: 'change'
                    }
                ],
                view_type: [
                    {
                        required: true,
                        message: '浏览次数为必选项',
                        trigger: 'change'
                    }
                ],
                view_num: [
                    {
                        required: true,
                        message: '指定次数为必填项',
                        trigger: 'change'
                    },
                    {
                        // 正则表达式(大于等于0的正整数)：/^\+?[0-9]\d*$/  或 /^\d\d*$/
                        pattern: /^\d\d*$/,
                        message: '必须为正整数',
                        trigger: 'change'
                    }
                ],
                user_type: [
                    {
                        required: true,
                        message: '新老用户为必选项',
                        trigger: 'change'
                    }
                ],
                mobile_type: [
                    {
                        required: true,
                        message: '机型为必选项',
                        trigger: 'change'
                    }
                ]
            }, //form表单-必填规则
            loading: false,

            // 用户id集
            userids: [],
            // 是否在id集中,默认不在id集中
            inids: 1,

            // 生效区域下拉
            valid_area_options: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            myuserinfo: JSON.parse(localStorage.getItem('userinfo')), //获取存储的用户信息

            // 记录点击次数
            number: 0
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            // 获取生效区域
            this.getAreaData()

            // 获取机型options
            var p0 = axios
                .post(
                    '/admin/advert/mobile_type',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.mobile_type_options = response.data.result
                })
                .catch(function (error) {
                    console.log(error)
                })

            // 获取相关配置配置-用户id集
            var p1 = axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'mini_ad_address_check'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code == 0) {
                        this.userids = Object.values(response.data.result)
                        if (this.userids.indexOf(this.myuserinfo.id.toString()) != -1) {
                            // id在用户id集中
                            this.inids = 1
                        } else {
                            // id不在用户id集中
                            this.inids = -1
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })

            // 获取相关配置配置-广告位
            var p2 = axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'advert_position',
                        id: this.myuserinfo.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.mytyppid = response.data.result
                })
                .catch(function (error) {
                    console.log(error)
                })
            // 获取相关配置配置-webview跳转链接
            var p3 = axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'mini_path'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.mywebview = response.data.result
                })
                .catch(function (error) {
                    console.log(error)
                })
            // 获取相关配置配置-H5/公众号跳转链接
            var p4 = axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'wechat_path'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.mywechat = response.data.result
                })
                .catch(function (error) {
                    console.log(error)
                })
            // 获取相关配置配置-小程序APPID
            var p5 = axios
                .post(
                    '/admin/config/info',
                    {
                        key_name: 'outer_app'
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.myouter = response.data.result
                })
                .catch(function (error) {
                    console.log(error)
                })

            Promise.all([p0, p1, p2, p3, p4, p5])
                .then(() => {
                    // 渲染-编辑中的数据
                    axios
                        .post(
                            '/admin/advert/info',
                            {
                                id: this.$route.query.id
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            this.ruleForm = response.data.result.info
                            // this.ruleForm.outer_url = response.data.result.info.out_url

                            this.ruleForm.begin_time = response.data.result.info.begin_time * 1000
                            this.ruleForm.end_time = response.data.result.info.end_time * 1000

                            // 反显-广告图片&视频
                            let getAplitInfo = response.data.result.info
                            this.ruleForm.update_type = getAplitInfo.update_type.toString()
                            this.ruleForm.image = getAplitInfo.image
                            this.ruleForm.image_list = getAplitInfo.image_url
                                ? [{ name: getAplitInfo.image_url.split('/')[getAplitInfo.image_url.split('/').length - 1], url: getAplitInfo.image_url }]
                                : []
                            this.ruleForm.mp4 = getAplitInfo.mp4
                            this.ruleForm.mp4_list = getAplitInfo.mp4
                                ? [{ name: getAplitInfo.mp4.split('/')[getAplitInfo.mp4.split('/').length - 1], url: require('@/assets/imgs/upload/upload.png') }]
                                : []

                            // 转化-将mini_path赋值给outer_url
                            var getdatas = response.data.result.info
                            if (getdatas.jump_way == 2) {
                                this.ruleForm.outer_url = getdatas.mini_path
                            }
                            if (getdatas.jump_way == 4) {
                                this.number = 1
                            }

                            // 转化生效区域
                            this.ruleForm.valid_area = getdatas.valid_area && getdatas.valid_area != '' ? getdatas.valid_area.split(',') : []

                            // 转化浏览次数
                            this.ruleForm.view_type = getdatas.view_type.toString()
                            this.ruleForm.view_num = getdatas.view_num

                            // 转化投放时间
                            this.ruleForm.time_span = getdatas.time_span.map((n) => {
                                let mytimes = []
                                mytimes[0] = n['show_begin_time'].valueOf() * 1000
                                mytimes[1] = n['show_end_time'].valueOf() * 1000

                                let times = { times: mytimes }
                                return times
                            })

                            // 转化新老用户
                            this.ruleForm.user_type = getdatas.user_type && getdatas.user_type != '' ? getdatas.user_type.split(',') : []
                            // 转化机型
                            this.ruleForm.mobile_type = getdatas.user_type && getdatas.mobile_type != '' && getdatas.mobile_type != null ? getdatas.mobile_type.split(',') : []
                        })
                        .catch(function (error) {
                            console.log(error)
                        })

                    this.loading = false
                })
                .catch((error) => {
                    console.log('error:失败:', error)
                    this.loading = false
                })
        }
    },
    methods: {
        // 点击跳转方式
        changeJumpEvent(data) {
            // 判断首次点击视频号的默认
            if (data == 4 && this.number == 0) {
                this.ruleForm.wechat_path = '1'
                this.number += 1
            }
        },

        // 添加-投放时间段
        addTimePickerEvent() {
            let times = { time: '' }
            if (this.ruleForm.time_span.length >= 5) {
                ElMessage({ type: 'warning', message: '最多添加5个时段！' })
            } else {
                this.ruleForm.time_span.push(times)
            }
        },
        // 删除-投放时间段
        delTimePickerEvent(index) {
            this.ruleForm.time_span.splice(index, 1)
        },
        // 选中-投放时间段
        changeTimePickerEvent(data) {
            console.log(data)
        },
        // 选中生效区域
        changedatasEvent(data) {
            this.ruleForm.valid_area = data
            console.log(data)
        },

        // 选择开始时间
        changeBeginTime(data) {
            if (!data) {
                data = 0
            }
            let time = data.valueOf()
            if (time >= this.ruleForm.end_time && this.shift10To13(this.ruleForm.end_time) != 0) {
                ElMessage({
                    type: 'warning',
                    message: '选择的结束时间必须大于开始时间！'
                })
                this.ruleForm.end_time = 0
            }
        },
        // 选择结束时间
        changeEndTime(data) {
            if (!data) {
                data = 0
            }
            let time = data.valueOf()
            if (time <= this.ruleForm.begin_time && this.shift10To13(this.ruleForm.begin_time) != 0) {
                ElMessage({
                    type: 'warning',
                    message: '选择的结束时间必须大于开始时间！'
                })
                this.ruleForm.end_time = 0
            }
        },
        // 判断时间戳是否为10位
        shift10To13(data) {
            if (!data) {
                data = 0
            }
            if (data.valueOf().length == 10) {
                return data.valueOf() * 1000
            } else {
                return data.valueOf()
            }
        },
        //获取upload子组件回传的数据
        getUploadFileEvent(data, flag) {
            // console.log('当前获取的回传数据：', data)
            if (flag == 1) {
                this.ruleForm.image = data.id
                this.ruleForm.image_list = [
                    {
                        name: data.url.split('/')[data.url.split('/').length - 1],
                        url: data.url
                    }
                ]
            } else if (flag == 2) {
                this.ruleForm.mp4 = data.res.requestUrls[0]
                this.ruleForm.mp4_list = [
                    {
                        name: data.name,
                        url: require('@/assets/imgs/upload/upload.png')
                    }
                ]
            }
        },
        //删除上传的文件-事件
        delfiledataEvent(data, flag) {
            // console.log('当前删除事件-返回的数据：', data)
            if (flag == 1) {
                this.ruleForm.image = ''
                this.ruleForm.image_list = []
            } else if (flag == 2) {
                this.ruleForm.mp4 = ''
                this.ruleForm.mp4_list = []
            }
        },
        //提交form表单-并判断是否必填
        submitForm(formName) {
            var formdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    formdatas.begin_time = this.ruleForm.begin_time / 1000
                    formdatas.end_time = this.ruleForm.end_time / 1000

                    var minipath = formdatas.mini_path
                    // 判断mini_path入库
                    if (formdatas.jump_way == 2) {
                        minipath = formdatas.outer_url
                    } else {
                        minipath = formdatas.mini_path
                    }

                    // 转换投放时间
                    let times = formdatas.time_span.map((n) => {
                        let mytimes = []
                        mytimes[0] = n.times[0].valueOf() / 1000
                        mytimes[1] = n.times[1].valueOf() / 1000
                        return mytimes
                    })

                    axios
                        .post(
                            '/admin/advert/update',
                            {
                                id: this.$route.query.id,
                                name: formdatas.name,
                                type_id: formdatas.type_id,
                                update_type: formdatas.update_type,
                                image: formdatas.image,
                                mp4: formdatas.mp4,
                                status: formdatas.status,
                                jump_way: formdatas.jump_way,
                                mini_path: minipath,
                                wechat_path: formdatas.wechat_path,
                                outer_path: formdatas.outer_path,
                                // outer_url: formdatas.outer_url,
                                begin_time: formdatas.begin_time,
                                end_time: formdatas.end_time,
                                sort: formdatas.sort,
                                platform: formdatas.platform,
                                valid_area: formdatas.valid_area.join(','),
                                time_span: times,
                                view_type: formdatas.view_type,
                                view_num: formdatas.view_num != '' ? formdatas.view_num : '0',
                                user_type: Array.from(formdatas.user_type),
                                mobile_type: Array.from(formdatas.mobile_type)
                            },
                            {
                                headers: {
                                    Authentication: localStorage.getItem('token')
                                }
                            }
                        )
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: 'success',
                                    message: '提交成功！',
                                    duration: 1000,
                                    onClose: () => {
                                        this.$router.push('/appletadvert').catch((error) => error)
                                    }
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },

        // 获取生效区域下拉
        getAreaData() {
            // 加载-生效区域-省-下拉数据
            axios
                .get('/admin/getArea', {
                    headers: {
                        Authentication: localStorage.getItem('token')
                    },
                    params: {}
                })
                .then((response) => {
                    this.valid_area_options = response.data.result.list
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone,
        mainimgesup
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
