<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
      :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectcityisshow="myselectcityisshow" :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent"
      v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="
          margin-top: 10px;
          background-color: #ffffff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0 20px;
        ">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--添加XX按钮-点击跳转添加类页面-->
            <el-button type="primary" plain class="max_search" v-show="mainthreeisshowbut"
              @click="routeraddrClick('/contractlistadd')" v-if="mypowerlimits.htlb_tj">添加</el-button>
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table :header-cell-style="{ background: '#F5F7FA' }" ref="multipleTable"
            :row-class-name="tableRowClassName" :data="
              mainthreetableData.slice(
                (currentPage - lastpage) * PageSize,
                currentPage * PageSize
              )
            " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="
                mytabletitle.prop === 'status' ||
                mytabletitle.prop === 'images' ||
                mytabletitle.prop === 'source' ||
                mytabletitle.prop === 'sign_type' ||
                mytabletitle.prop === 'name'
              ">
                <!--签约人-样式等渲染-->
                <div v-if="mytabletitle.prop === 'name'">
                  <div :class="this.mypowerlimits.sbhhrlb == true ? 'mytabletext' : ''"
                    @click="this.mypowerlimits.sbhhrlb == true ? routeraddrClick('/partnerlist', scope.row) : ''">
                    <span>{{ scope.row.name }}</span>
                  </div>
                </div>
                <!--来源-样式等渲染-->
                <div v-if="mytabletitle.prop === 'source'">
                  <div v-if="scope.row.source == 1">
                    <span>小程序</span>
                  </div>
                  <div v-if="scope.row.source == 2">
                    <span>后台</span>
                  </div>
                </div>
                <!--签约类型-样式等渲染-->
                <div v-if="mytabletitle.prop === 'sign_type'">
                  <div v-if="scope.row.sign_type == 1">
                    <span>投资（收益权）</span>
                  </div>
                  <div v-if="scope.row.sign_type == 2">
                    <span>设备（购买权）</span>
                  </div>
                  <div v-if="scope.row.sign_type == 3">
                    <span>设备（购买权分期）</span>
                  </div>
                </div>
                <!--图片展示-样式等渲染-->
                <div v-if="mytabletitle.prop === 'images'">
                  <div v-if="scope.row.images">
                    <el-image class="imagesshow" :src="scope.row.images" :preview-src-list="[scope.row.images]"
                      fit="cover"></el-image>
                  </div>
                </div>
                <!--状态-样式等渲染-->
                <div v-if="mytabletitle.prop === 'status'">
                  <div v-if="scope.row.textstatus">
                    <span>{{ scope.row.textstatus }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
                <!--操作：按照页面区分，且每个按钮对应相关函数-->
                <!--操作-合同列表-->
                <div v-if="mainthreetableope === 4">
                  <!-- 关联设备正确 -->
                  <el-button type="primary" size="mini" v-if="
                    scope.row.sign_type != 1 &&
                    scope.row.sign_num < scope.row.buy_num &&
                    scope.row.status != 2 &&
                    mypowerlimits.htlb_glsb
                  " @click="chuliTanClick(scope.row, (dialogVisible = true))">关联设备</el-button>
                  <!-- 下载合同正确 -->
                  <el-button type="primary" size="mini" v-if="
                    scope.row.source == 1 &&
                    scope.row.sign_url != null &&
                    scope.row.status != 2 &&
                    mypowerlimits.htlb_xzht
                  " @click="downloadClick(scope.row.sign_url)">下载合同
                  </el-button>

                  <!-- 提前终止（状态为进行中的显示提前终止按钮） -->
                  <el-button type="primary" size="mini" v-if="scope.row.status == 1 && mypowerlimits.htlb_tqzz"
                    @click="addTanClick2(scope.row, (dialogVisible2 = true))">提前终止</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>

      <!--弹出框-终止合同-->
      <el-dialog v-model="dialogVisible2" title="终止合同" :width="this.searchwidth1" :before-close="handleCloseSenior">
        <el-form ref="ruleForm2" :model="ruleForm2" :rules="rules2" label-width="120px" class="demo-ruleForm">
          <div style="
              max-height: 450px;
              overflow: hidden;
              overflow-y: visible;
              margin: 20px 5% 0 0;
            ">
            <el-form-item label="到期时间：" prop="end_time">
              <el-date-picker v-model="ruleForm2.end_time" type="datetime" placeholder="请选择日期" style="width: 100%">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注：" prop="desc">
              <el-input v-model="ruleForm2.desc" type="textarea"></el-input>
            </el-form-item>
            <el-form-item style="text-align: right">
              <el-button @click="resetForm2('ruleForm2')">重置</el-button>
              <el-button type="primary" @click="submitForm2('ruleForm2')">确认</el-button>
            </el-form-item>
          </div>
        </el-form>
      </el-dialog>

      <!--弹出框-关联设备-->
      <el-dialog v-model="dialogVisible" title="选择设备" :width="searchwidth" :before-close="handleCloseSenior">
        <el-form ref="seniorForm" :model="seniorForm" :rules="rules">
          <!--查询条件-->
          <el-form-item>
            <div class="tan-search-box">
              <div class="tan-search-box-info">
                <div class="tan-search-box-info-title">输入搜索：</div>
                <div>
                  <el-input v-model="seniorForm.keywords" placeholder="设备编号/代理商名称" clearable></el-input>
                </div>
              </div>
              <div class="tan-search-box-info">
                <div class="tan-search-box-info-title">选择区域：</div>
                <div>
                  <mainselect v-model="seniorForm.area_code" :myselectcityleftisshow="tanpages.myselectcityisshow"
                    :myselectcity="tanpages.myselectcity" v-on:getcitycode="getCityCodeEvent"></mainselect>
                </div>
              </div>
              <div class="tan-search-box-button">
                <el-button type="primary" @click="onSubmitTan('seniorForm', this.mypartnerid)">查询</el-button>
              </div>
            </div>
          </el-form-item>
          <!-- table表单 -->
          <el-form-item>
            <el-table :data="
              tableData.slice(
                (tanpages.currentPage - tanpages.lastpage) *
                tanpages.PageSize,
                tanpages.currentPage * tanpages.PageSize
              )
            " ref="multipleTable" style="width: 100%" max-height="420" border v-loading="tanpages.loading"
              element-loading-text="正在飞速加载中..." @selection-change="
                (val) =>
                  handleSelectionChangetan(val, this.setonehangdata.buy_num)
              ">
              <el-table-column type="selection" width="55" />
              <el-table-column label="设备编号" prop="client" width="150" />
              <el-table-column label="投放商家" prop="shop_name" />
              <el-table-column label="投放地址" prop="address" />
              <el-table-column label="投放场景" prop="push_type" width="150" />
              <el-table-column label="状态" prop="status" width="150">
                <template #default="scope">
                  <div class="tan-table-status">
                    <el-button
                    :type="scope.row.printstatus == 0 ? 'primary' : (scope.row.printstatus == 1 ? 'success' : 'danger')"
                    size="mini">{{ this.$utils.printStatusData( this.myprintstatusoption ,scope.row.printstatus != 0 ? scope.row.printstatus : '0') }}
                    </el-button>
                    <el-button :type="scope.row.is_online == 1 ? 'success' : 'danger'" size="mini">{{ scope.row.is_online
                        == 1 ? '在线' : '离线'
                    }}</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-row>
            <!-- 分页 -->
            <el-col :span="24" :xs="24" :sm="20">
              <div style="margin-top: 5px">
                <el-pagination background @size-change="handleSizeChangetan" @current-change="handleCurrentChangetan"
                  pager-count="5" :current-page="tanpages.currentPage" :page-sizes="tanpages.pageSizes"
                  :page-size="tanpages.PageSize" layout="total, sizes, prev, pager, next, jumper"
                  :total="tanpages.totalCount">
                </el-pagination>
              </div>
            </el-col>
            <!-- 确定&取消按钮 -->
            <el-col :span="24" :xs="24" :sm="4">
              <div style="text-align: right">
                <el-button @click="
                  resetFormSenior(tableseniorForm, (dialogVisible = false))
                ">取消</el-button>
                <el-button type="primary" @click="onSubmitSenior(tableseniorForm)" :disabled="tanpages.tandisabled">确定
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
import mainselect from "@/components/Mainselect";
import componentProperties from '@/assets/js/componentProperties' // 公共数据
import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vcontractlist",
  inject: ["reload"], //刷新引用
  data() {
    return {
      mainonebread: [
        {
          name: "合作伙伴",
        },
        {
          name: "设备合伙人",
        },
        {
          name: "合同列表",
          path: "/contractlist",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: true, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "输入搜索：",
              placeholder: "合同编号/签约人",
              name: "keywords",
              content: "",
            },
          ],
        },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "20px", //框右侧距离
          selectwidth: "200px",
          selectinterval: "", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "签约类型：",
              placeholder: "全部",
              name: "sign_type",
              value: "",
              options: [
                {
                  label: "全部",
                  value: "0",
                },
                {
                  label: "投资（收益权）",
                  value: "1",
                },
                {
                  label: "设备（购买权）",
                  value: "2",
                },
                {
                  label: "设备（购买权分期）",
                  value: "3",
                },
              ],
            },
          ],
        },
        myinputonefullisshow: true, //筛选查询-自动建议下拉框
        myinputonefull: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "220px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "设备编号：",
              placeholder: "请输入设备编号", //此name为键值
              name: "client_id",
              content: "",
              id: "", //此id为给后台的id值
              url: "/admin/getClientList",
              inputval: { client: "" }, //这里记录需要传的参数
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: true, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 4, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 250,
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "trade_no",
          label: "合同编号设备序号",
          width: "180",
        },
        {
          fixedstatu: false,
          prop: "name",
          label: "签约人",
          width: "90",
        },
        {
          fixedstatu: false,
          prop: "sign_type",
          label: "签约类型",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "source",
          label: "来源",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "status",
          label: "状态",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "alipay_no",
          label: "关联平台/订单编号",
          width: "200",
        },
        {
          fixedstatu: false,
          prop: "buy_num",
          label: "签约台数",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "client_no",
          label: "设备编号",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "signtime",
          label: "签约时间",
          width: "120",
        },

        {
          fixedstatu: false,
          prop: "money",
          label: "合同金额",
          width: "100",
        },
        {
          fixedstatu: false,
          prop: "type",
          label: "支付方式",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "truename",
          label: "添加人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "stoptime",
          label: "终止时间",
          width: "120",
        },
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 0, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //高级筛选
      myinputishow: true, //高级筛选-input单框
      myinput: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%",
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "输入搜索：",
            placeholder: "合同编号/签约人",
            name: "keywords",
            content: "",
          },
        ],
      },
      myselectisshow: true, //高级筛选-select单框
      myselect: {
        divinterval: "3%", //框右侧距离
        selectwidth: "47%",
        selectmaxwidth: "100%",
        select: [
          {
            selectisshow: true,
            ismust: false,
            title: "签约类型：",
            placeholder: "全部",
            name: "sign_type",
            value: "",
            options: [
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "投资（收益权）",
              },
              {
                value: "2",
                label: "设备（购买权）",
              },
              {
                value: "3",
                label: "设备（购买权分期）",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "来源:",
            placeholder: "全部",
            name: "source",
            value: "",
            options: [
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "小程序",
              },
              {
                value: "2",
                label: "后台",
              },
            ],
          },
          {
            selectisshow: true,
            ismust: false,
            title: "状态:",
            placeholder: "全部",
            name: "status",
            value: "",
            options: [
              {
                value: "0",
                label: "全部",
              },
              {
                value: "1",
                label: "进行中",
              },
              {
                value: "2",
                label: "已终止",
              },
            ],
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "签约时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "signtime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
          {
            selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
            ismust: false,
            title: "终止时间：",
            placeholder: {
              placeholderstart: "请选择开始时间",
              placeholderend: "请选择结束时间",
            },
            name: "stoptime",
            value: {
              valuestart: ref(""),
              valueend: ref(""),
              endstatus: true,
            },
            rangeseparator: "至", //分隔符
            divinterval: "3%", //上方字中-框右侧距离
            selectwidth: "100%", //上方字中-总框长度
            selectmaxwidth: "97%", //时间段-最大宽度
            marginright: "0", //与右侧距离
          },
        ],
      },
      myinputonefullgaoisshow: true, //高级筛选-自动建议下拉框
      myinputonefullgao: {
        divinterval: "3%", //框右侧距离
        inputwidth: "47%", //input宽度
        inputmaxwidth: "100%",
        input: [
          {
            ismust: false, //是否必填，true表示必填
            title: "设备编号：",
            placeholder: "请输入设备编号", //此name为键值
            name: "client_id",
            content: "",
            id: "", //此id为给后台的id值
            url: "/admin/getClientList",
            inputval: { client: "" }, //这里记录需要传的参数
          },
        ],
      },

      // 设备打印状态数据
      myprintstatusoption: componentProperties.get('printstatusoption').options,

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100, 1000],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值

      searchwidth1: "580px",
      //弹窗相关数据 - (终止合同)
      dialogVisible2: ref(false), //弹窗显示&隐藏
      //弹窗中-form表单 - (终止合同)
      ruleForm2: {
        id: "", //当前用户id
        thirdparty: "", //当前行用户-所属平台
        end_time: "",
        desc: "",
      },
      //弹窗中-form表单-必填规则 - (终止合同)
      rules2: {
        end_time: [
          {
            required: true,
            message: "终止时间为必填项",
            trigger: "blur",
          },
        ],
        desc: [
          {
            required: false,
            message: "备注为必填项",
            trigger: "blur",
          },
        ],
      },

      //存储点击关联设备按钮行的数据
      setonehangdata: null,
      //弹框-查询设备-form表单数据
      seniorForm: {
        keywords: "",
        area_code: "",
      },
      dialogVisible: false,
      searchwidth: "1024px",
      //弹窗中-table表单
      tableData: [],
      tableseniorForm: {
        agreement_id: "",
        dvcids: [],
        alldvc: [],
        is_all: 0,
      },
      tanpages: {
        //弹窗中-selectcity选框
        myselectcityisshow: true,
        myselectcity: {
          selectwidth: "100%",
          maxwidth: "500px",
          marginright: "0",
          select: {
            ismust: false,
            name: "area_code",
            valuesheng: "",
            valueshi: "",
            valuequ: "",
            disshi: true,
            disqu: true,
          },
        },
        //弹窗中-table表单-分页器
        totalCount: 0, //数据列表中-数据总条数
        currentpage: 1, //数据列表中-数据当前页数
        lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
        currentPage: 1,
        pageSizes: [10, 20, 50, 100, 1000],
        PageSize: 10,
        loading: false, //初始化-loading加载动效，默认是false关闭

        sign_type: "", //弹出框中签约类型
        pagesdatas: [], //存储(弹窗中)最近一次的筛选条件，供分页使用

        tandisabled: true, //控制弹窗中的确定按钮
      },

      // 所选行数据的 partner_id-维护字段
      mypartnerid: "",

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  setup() { },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    // 下载合同
    downloadClick(url) {
      window.open(url, "_blank");

      //   // url = 'https://www.runoob.com/images/logo.png'
      //   const el = document.createElement('a');
      //   // el.style.display = 'none';
      //   el.setAttribute('target', '_blank');
      //  /**
      //    * download的属性是HTML5新增的属性
      //    * href属性的地址必须是非跨域的地址，如果引用的是第三方的网站或者说是前后端分离的项目(调用后台的接口)，这时download就会不起作用。
      //    * 此时，如果是下载浏览器无法解析的文件，例如.exe,.xlsx..那么浏览器会自动下载，但是如果使用浏览器可以解析的文件，比如.txt,.png,.pdf....浏览器就会采取预览模式
      //    * 所以，对于.txt,.png,.pdf等的预览功能我们就可以直接不设置download属性(前提是后端响应头的Content-Type: application/octet-stream，如果为application/pdf浏览器则会判断文件为 pdf ，自动执行预览的策略)
      //    */
      //   // el.setAttribute('download', url);
      //   el.href = url;
      //   console.log(el);
      //   document.body.appendChild(el);
      //   el.click();
      //   // document.body.removeChild(el);
    },
    // 【关联设备】---------------------以下弹窗----------------------
    //点击按钮-弹出弹框
    chuliTanClick(data) {
      //存储当前行的数据
      this.setonehangdata = data;
      // console.log("获取当前行数据 :" + JSON.stringify(data));
      //存储当前行的合同id
      this.tableseniorForm.agreement_id = data.agreement_id;
      // 存储当前行的签约类型
      this.tanpages.sign_type = data.sign_type;
      this.tanpages.pagesdatas.partner_id = data.partner_id;
      this.tanpages.pagesdatas.sign_type = data.sign_type;
      this.tanpages.pagesdatas.keywords = "";
      this.tanpages.pagesdatas.area_code = "";
      this.tanpages.pagesdatas.page = 1;
      this.tanpages.pagesdatas.page_size = 10;
      this.tanpages.PageSize = 10;

      // 所选行数据的 partner_id-维护字段
      this.mypartnerid = data.partner_id;

      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    // 弹窗中-获取数据的函数
    tanGetInfoEvent(pagesdatas) {
      // console.log(pagesdatas.sign_type);
      if (pagesdatas.sign_type == 1) {
        //点击进入弹窗-加载tabledata
        this.tanpages.loading = true;
        axios
          .post(
            "/admin/partner/getInvestClient",
            {
              keywords: pagesdatas.keywords,
              area_code: pagesdatas.area_code,
              page: pagesdatas.page,
              page_size: pagesdatas.page_size,
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            var successdata = response.data.result;
            var newdata = successdata.data;
            for (var i = 0; i < newdata.length; i++) {
              //渲染-状态-该字段表示设备状态按钮
              newdata[i].devstatus = 1;
            }
            this.tableData = newdata;
            this.tanpages.totalCount = successdata.total;
            this.tanpages.currentpage = successdata.current_page; //当前页面页数
            this.tanpages.lastpage = successdata.last_page;
            this.tanpages.loading = false;
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        //点击进入弹窗-加载tabledata
        this.tanpages.loading = true;
        axios
          .post(
            "/admin/partner/getBuyClient",
            {
              partner_id: pagesdatas.partner_id,
              keywords: pagesdatas.keywords,
              area_code: pagesdatas.area_code,
              page: pagesdatas.page,
              page_size: pagesdatas.page_size,
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.code == 0) {
              var successdata = response.data.result;
              var newdata = successdata.data;
              for (var i = 0; i < newdata.length; i++) {
                //渲染-状态-该字段表示设备状态按钮
                newdata[i].devstatus = 1;
              }
              this.tableData = newdata;
              this.tanpages.totalCount = successdata.total;
              this.tanpages.currentpage = successdata.current_page; //当前页面页数
              this.tanpages.lastpage = successdata.last_page;
              this.tanpages.loading = false;
            } else {
              // code为非0时，数据显示情况！
              this.tableData = [];
              this.tanpages.totalCount = 0;
              this.tanpages.currentpage = 1;
              this.tanpages.lastpage = 1;
              this.tanpages.loading = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    //弹窗中-分页器中-当选择条数时触发该函数
    handleSizeChangetan(val) {
      console.log(`每页 ${val} 条`);
      this.tanpages.PageSize = val;
      this.tanpages.currentPage = 1;

      this.tanpages.pagesdatas.page = 1;
      this.tanpages.pagesdatas.page_size = val;
      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    //弹窗中-分页器中-当选择分页时触发该函数
    handleCurrentChangetan(val) {
      console.log(`当前页: 第${val}页`);
      this.tanpages.currentPage = val;

      this.tanpages.pagesdatas.page = val;
      this.tanpages.pagesdatas.page_size = this.tanpages.PageSize;
      this.tanGetInfoEvent(this.tanpages.pagesdatas);
    },
    //弹窗中-table表单-多选按钮
    handleSelectionChangetan(val, buydvcnum) {
      // console.log("获取的 val is: " + val);
      if (val.length > 0) {
        if (val.length > buydvcnum) {
          ElMessage({
            type: "warning",
            message: "别选啦，超出签约台数了！",
          });
          this.tanpages.tandisabled = true;
        } else {
          this.tanpages.tandisabled = false;
        }
      } else {
        // 当数组长度为0时，禁止确认按钮
        this.tanpages.tandisabled = true;
      }

      // 存储所选择的设备
      this.tableseniorForm.alldvc = val;
    },
    //获取公共组件回传的值-citycode
    getCityCodeEvent(data) {
      // console.log("当前获取的citycode值为：" + data);
      this.seniorForm.area_code = data;
    },
    //弹窗中-查询按钮-提交
    onSubmitTan(formName, partnerid) {
      var getdatas = this.$refs[formName].model;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tanpages.pagesdatas = getdatas;
          this.tanpages.pagesdatas.sign_type = this.tanpages.sign_type;
          console.log(this.tanpages.sign_type);
          this.tanpages.pagesdatas.partner_id = partnerid;
          this.tanpages.pagesdatas.page = 1;
          this.tanpages.pagesdatas.page_size = this.tanpages.PageSize;
          this.tanGetInfoEvent(this.tanpages.pagesdatas);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-选择设备-确认
    onSubmitSenior(formData) {
      // 存储所选择设备的id，并用逗号隔开
      var getdvcids = [];
      if (formData.alldvc.length > 0) {
        formData.alldvc.forEach((element) => {
          getdvcids.push(element.id);
        });
      }
      if (getdvcids.length > 0) {
        formData.dvcids = getdvcids.join(",");
        axios
          .post(
            "/admin/partner/relationClient",
            {
              agreement_id: formData.agreement_id,
              client_ids: formData.dvcids,
            },
            {
              headers: {
                Authentication: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.code == 0) {
              ElMessage({
                type: "success",
                message: "更换成功",
                duration: 1000,
                onClose: () => {
                  this.dialogVisible = false; //关闭弹窗
                  setTimeout(() => {
                    this.reload(); //刷新
                  }, 500);
                },
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        ElMessage({
          type: "warning",
          message: "请至少选择一个设备再点击确认！",
        });
      }
    },
    //弹出框-选择设备-取消
    resetFormSenior(datas) {
      console.log("this.tableseniorForm:" + JSON.stringify(datas));
      this.seniorForm = {
        keywords: "",
        area_code: "",
      };
      this.tanpages.myselectcity.select = {
        ismust: false,
        name: "area_code",
        valuesheng: "",
        valueshi: "",
        valuequ: "",
        disshi: true,
        disqu: true,
      };
    },
    //点击关闭-弹窗
    handleCloseSenior(done) {
      done();
    },
    // 【终止合同】---------------------以下弹窗----------------------
    //点击按钮-弹出弹框并赋值弹窗基本配置（提前终止）
    addTanClick2(taninfo) {
      console.log(taninfo);
      var getdata = taninfo;
      this.ruleForm2.id = getdata.agreement_id;
      // this.ruleForm2.thirdparty = getdata.thirdparty;
    },
    //弹出框-提交表单（提前终止）
    submitForm2(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var getdatas = this.$refs[formName].model;
          axios
            .post(
              "/admin/agreement/stop",
              {
                id: getdatas.id,
                stoptime: getdatas.end_time.valueOf() / 1000,
                remark: getdatas.desc,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "操作成功！",
                });
                this.dialogVisible2 = ref(false); //关闭弹窗
                setTimeout(() => {
                  this.reload(); //刷新
                }, 2000);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //弹出框-重置表单(提前终止)
    resetForm2(formName) {
      this.$refs[formName].resetFields();
    },
    // 以下是数据列表--------------------------------
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/agreement/index",
          {
            keywords: this.pagesdatas.keywords,
            sign_type: this.pagesdatas.sign_type,
            client_id: this.pagesdatas.client_id,
            source: this.pagesdatas.source,
            status: this.pagesdatas.status,
            signtime: this.pagesdatas.signtime,
            stoptime: this.pagesdatas.stoptime,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/agreement/index",
          {
            keywords: this.pagesdatas.keywords,
            sign_type: this.pagesdatas.sign_type,
            client_id: this.pagesdatas.client_id,
            source: this.pagesdatas.source,
            status: this.pagesdatas.status,
            signtime: this.pagesdatas.signtime,
            stoptime: this.pagesdatas.stoptime,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr, data) {
      console.log("当前data获取得到的值：", data);
      if (data) {
        this.$router
          .push({ path: routeraddr, query: { name: data.name } })
          .catch((error) => error);
      } else {
        this.$router.push(routeraddr).catch((error) => error);
      }
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/admin/agreement/index",
          {
            keywords: this.pagesdatas.keywords,
            sign_type: this.pagesdatas.sign_type,
            client_id: this.pagesdatas.client_id,
            source: this.pagesdatas.source,
            status: this.pagesdatas.status,
            signtime: this.pagesdatas.signtime,
            stoptime: this.pagesdatas.stoptime,
            export: 1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data.message);
     
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth1 = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth1 = "580px";
      }
      if (this.sreenwidth < 1100) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "1024px";
      }
    },
    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
    searchFormEvent(data) {
      console.log("高级筛选-开始检索返回数据(页面):" + JSON.stringify(data));
      //加载loading
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-签约时间段状态
      var getsigntime = null;
      if (data.signtime.start == "") {
        getsigntime = "";
      } else {
        if (data.signtime.end == "") {
          getsigntime =
            data.signtime.start + " ~ " + (data.signtime.start + 86400 - 1);
        } else {
          getsigntime =
            data.signtime.start + " ~ " + (data.signtime.end + 86400 - 1);
        }
      }
      this.pagesdatas.signtime = getsigntime;
      //判断-终止时间段状态
      var getstoptime = null;
      if (data.stoptime.start == "") {
        getstoptime = "";
      } else {
        if (data.stoptime.end == "") {
          getstoptime =
            data.stoptime.start + " ~ " + (data.stoptime.start + 86400 - 1);
        } else {
          getstoptime =
            data.stoptime.start + " ~ " + (data.stoptime.end + 86400 - 1);
        }
      }
      this.pagesdatas.stoptime = getstoptime;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/agreement/index",
          {
            keywords: data.keywords,
            sign_type: data.sign_type,
            client_id: data.client_id,
            source: data.source,
            status: data.status,
            signtime: getsigntime,
            stoptime: getstoptime,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
      console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/agreement/index",
          {
            keywords: data.keywords,
            sign_type: data.sign_type,
            client_id: data.client_id,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      for (var i = 0; i < newdata.length; i++) {
        //渲染-支付方式
        if (newdata[i].type === 1) {
          newdata[i].type = "微信";
        } else {
          if (newdata[i].type === 2) {
            newdata[i].type = "线下收款";
          } else {
            newdata[i].type = "支付宝";
          }
        }
        //转换-签约时间
        if (newdata[i].signtime) {
          if (newdata[i].signtime === 0 || newdata[i].signtime === null) {
            newdata[i].signtime = "无签约时间";
          } else {
            newdata[i].signtime = this.$utils
              .formatDate(newdata[i].signtime)
              .substr(0, 10);
          }
        } else {
          newdata[i].signtime = "无签约时间";
        }
        //转换-终止时间
        if (newdata[i].stoptime) {
          if (newdata[i].stoptime === 0 || newdata[i].stoptime === null) {
            newdata[i].stoptime = "--";
          } else {
            newdata[i].stoptime = this.$utils
              .formatDate(newdata[i].stoptime)
              .substr(0, 10);
          }
        } else {
          newdata[i].stoptime = "--";
        }
        //渲染-状态
        if (newdata[i].status === 1) {
          newdata[i].textstatus = "进行中";
        } else {
          if (newdata[i].status === 2) {
            newdata[i].textstatus = "终止";
          } else {
            newdata[i].textstatus = "--";
          }
        }
      }
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      // 定义
      this.pagesdatas = {
        page_size: this.PageSize,
      };
      // 判断-路由携带-跳转数据
      let routerinfo = this.$route.query;
      if (routerinfo.signusername) {
        this.pagesdatas = {
          keywords: routerinfo.signusername,
          page_size: this.PageSize,
        };
        this.maintwoforminfo.myinputone.input[0].content =
          routerinfo.signusername;
      }
      if (routerinfo.pid) {
        this.pagesdatas = {
          partner_id: routerinfo.pid,
          page_size: this.PageSize,
        };
      }

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/agreement/index",
          this.pagesdatas,
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
    mainselect,
  },
});
</script>
<style scoped>
/* 弹出框-搜索栏 */
.tan-search-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.tan-search-box-info {
  display: flex;
  flex-direction: row;
  margin: 0 10px 10px 0;
}

.tan-search-box-info-title {
  min-width: 80px;
}

.tan-table-status {
  display: flex;
  flex-direction: row;
}

/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}

.mytabletext {
  color: var(--el-color-primary);
  cursor: pointer;
}
</style>