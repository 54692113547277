<template>
    <div>
        <el-table :data="tableData" style="width: 100%">
            <el-table-column :label="item.label" :prop="item.prop" :width="item.width" v-for="(item, index) in tableTitle" :key="index">
                <template v-if="item.children" #default>
                    <el-table-column :label="jtem.label" :prop="item.prop + '.' + jndex + '.' + jtem.prop" :width="jtem.width" v-for="(jtem, jndex) in item.children" :key="jndex">
                        <template #default="scope" v-if="jtem.prop === 'shop_paly_option'">
                            <div class="flex-box-c">
                                <slot name="contentShop" :scope="scope"></slot>
                            </div>
                        </template>
                        <template #default="scope" v-else-if="jtem.prop === 'user_paly_option'">
                            <div class="flex-box-c">
                                <slot name="contentUser" :scope="scope"></slot>
                            </div>
                        </template>
                        <template #default="scope" v-else>
                            <div class="flex-box-c">
                                <slot name="contentInput" :scope="scope" :prop1="item.prop" :prop2="jtem.prop"></slot>
                            </div>
                        </template>
                    </el-table-column>
                </template>
                <template v-else #default="scope">
                    <div v-if="item.prop == 'name'" style="line-height: 24px;">
                        <div>
                            <span style="color: #333; font-weight: bold">{{ scope.row.name }}</span>
                        </div>
                        <div>
                            <span>{{ scope.row.price }}</span>
                            <span>元/</span>
                            <span>{{ scope.row.valid_time }}</span>
                            <span>个月</span>
                        </div>
                    </div>
                    <div v-else>
                        <span>{{ scope.row[item.prop] }}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'tablePlayInvite',
    inject: ['reload'], //刷新引用
    props: {
        tabletitle: {
            type: Array,
            default() {
                return []
            }
        },
        tabledata: {
            type: Array,
            default() {
                return []
            }
        }
    },
    watch: {
        tabletitle: {
            handler(newVal) {
                this.tableTitle = newVal
            },
            deep: true,
            immediate: true
        },
        tabledata: {
            handler(newVal) {
                this.tableData = newVal
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            tableTitle: [],
            tableData: [],

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')) //获取权限
        }
    },
    methods: {},
    created() {},
    components: {}
})
</script>
<style scoped></style>
