<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box"  >
        <div class="box_title"  >
          <div class="box_title_font">审核文档</div>
          <div class="box_title_button">
            <!--可添加按钮-->
            <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="7">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="9">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="120px"
                class="demo-ruleForm"
              >
                <!-- 分类 -->
                <el-form-item label="分类：">
                  <div v-for="(datas, l) in ruleForm.catefile" :key="l">
                    <el-row :gutter="10" style="margin-top: 10px">
                      <!-- 平台来源 -->
                      <el-col :span="24" :xs="24" :sm="24" :lg="10">
                        <el-select v-model="datas.type" placeholder="请选择">
                          <el-option
                            :label="getsales.cate_name"
                            :value="getsales.id"
                            v-for="(getsales, chicundex) in this.gettype"
                            :key="chicundex"
                            @click="finduserSaleList(getsales, l)"
                          ></el-option>
                        </el-select>
                      </el-col>
                      <!-- 子分类-多级联选 -->
                      <!-- :props="{value:'id',label:'name'}" 自定义字段-->
                      <el-col :span="24" :xs="24" :sm="24" :lg="10">
                        <el-cascader
                          v-model="datas.child"
                          :options="gettypechild"
                          :show-all-levels="false"
                          :props="{
                            value: 'id',
                            label: 'cate_name',
                            children: 'child',
                            checkStrictly: true,
                          }"
                          clearable
                          :disabled="datas.disabled"
                          @change="handleChange"
                        ></el-cascader>
                      </el-col>
                      <!-- 添加&删除按钮 -->
                      <el-col :span="24" :xs="24" :sm="24" :lg="4">
                        <el-button
                          type="text"
                          v-if="datas.addisshow"
                          @click="addRechargeEvent(ruleForm.catefile)"
                          >添加</el-button
                        >
                        <el-button
                          type="text"
                          v-if="!datas.addisshow"
                          @click="delRechargeEvent(l, ruleForm.catefile)"
                          >删除</el-button
                        >
                      </el-col>
                    </el-row>
                  </div>
                </el-form-item>
                <el-form-item label="文档：" prop="sourcefile">
                  <mainimgesup
                    v-model="ruleForm.sourcefile"
                    :myimgesup="myimgesup"
                    :myimgesupisshow="myimgesupisshow"
                  ></mainimgesup>
                </el-form-item>
                <el-form-item label="文档名称：" prop="sourcename">
                  <el-input v-model="ruleForm.sourcename"></el-input>
                </el-form-item>
                <el-form-item label="选择标签：" prop="tabinfo">
                  <el-select
                    v-model="tabinfo.value1"
                    multiple
                    placeholder="请选择标签"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in tabinfo.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="收益类型：" prop="income_type">
                  <el-radio-group v-model="ruleForm.income_type">
                    <el-radio label="1">共享文档</el-radio>
                    <el-radio label="2">收费文档</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="文档价格："
                  prop="print_price"
                  v-if="ruleForm.income_type === '2'"
                >
                  <el-input v-model="ruleForm.print_price"></el-input>
                </el-form-item>
                <el-form-item label="角标：" prop="jioabiao">
                  <el-radio-group v-model="ruleForm.jiaobiao">
                    <el-radio label="1">热门</el-radio>
                    <el-radio label="2">精选</el-radio>
                    <el-radio label="3">推荐</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="排序：" prop="weight">
                  <el-input
                    v-model="ruleForm.weight"
                    placeholder="数字越大排序越高"
                  ></el-input>
                </el-form-item>
                <el-form-item label="虚拟打印次数：" prop="fictitious">
                  <el-input v-model="ruleForm.fictitious"></el-input>
                </el-form-item>
                <el-form-item label="虚拟收藏数：" prop="collect_fictitious">
                  <el-input v-model="ruleForm.collect_fictitious"></el-input>
                </el-form-item>
                <el-form-item label="打印模式：" prop="print_mode">
                  <el-radio-group v-model="ruleForm.print_mode">
                    <el-radio label="1">黑白</el-radio>
                    <el-radio label="2">彩色</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="状态：" prop="status">
                  <el-radio-group v-model="ruleForm.status">
                    <el-radio label="1">启用</el-radio>
                    <el-radio label="0">禁用</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm('ruleForm')"
                    style="width: 40%; margin-right: 10px"
                    >审核通过
                  </el-button>
                  <el-button @click="resetForm('ruleForm')">审核拒绝</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainimgesup from "@/components/Mainimgesup.vue";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import axios from "axios";

export default {
  name: "vresourcesexamine",
  data() {
    return {
      mainonebread: [
        {
          name: "教育",
        },
        {
          name: "文档管理",
        },
        {
          name: "打印资源库",
          path: "/printresources",
        },
        {
          name: "审核文档",
          path: "/resourcesexamine",
        },
      ], // 当前页-【面包屑导航】

      myselectcityisshow: true, //form表单-selectcity选框
      myselectcity: {
        selectwidth: "100%",
        select: {
          ismust: true,
          name: "area_code",
          valuesheng: "",
          valueshi: "",
          valuequ: "",
          disshi: true,
          disqu: true,
        },
      },
      myimgesupisshow: true, //form表单-imgesuploade上传图片
      myimgesup: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        ismust: true, //是否必填，true表示必填
        title: "附件：",
        tips: "",
      },
      //分类-配置
      gettype: [],
      // 分类-子分类
      gettypechild: [],
      //标签
      tabinfo: {
        options: ref([
          {
            value: "Option1",
            label: "Option1",
          },
          {
            value: "Option2",
            label: "Option2",
          },
          {
            value: "Option3",
            label: "Option3",
          },
          {
            value: "Option4",
            label: "Option4",
          },
          {
            value: "Option5",
            label: "Option5",
          },
        ]),
        value1: ref([]),
      },

      ruleForm: {
        catefile: [
          {
            type: 1, //来源平台
            child: "1", //子分类
            addisshow: true,
            disabled: true,
          },
        ],
        sourcefile: "",
        sourcename: "",
        tabinfo: "",
        income_type: ref("1"),
        print_price: "",
        jiaobiao: ref("1"),
        weight: "",
        fictitious: "",
        collect_fictitious: "",
        print_mode: ref("1"),
        status: ref("1"),
      }, //form表单
      rules: {
        path: [
          {
            required: true,
            message: "图片为必填项",
            trigger: "blur",
          },
        ],
        key: [
          {
            required: true,
            message: "图片键值为必填项",
            trigger: "blur",
          },
        ],
        cid: [
          {
            required: true,
            message: "请选择所属分类",
            trigger: "change",
          },
        ],
        display: [
          {
            required: true,
            message: "排序为必填项",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: false,
            message: "请至少选择一个状态",
            trigger: "change",
          },
        ],
      }, //form表单-必填规则

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
    };
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //通过API获取配置数据-无限级分类列表
      axios
        .post(
          "/admin/SharePrintSource/sourceCateInfo",
          {
            id: -1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var getdata = response.data;
          console.log(JSON.stringify(getdata));
          this.gettype = getdata;
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });

      //通过Ajax向后台获取数据
      axios
        .post(
          "/admin/sharePrintSource/viewSource",
          {
            id: this.$route.params.id,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var getdata = response.data;
          console.log(JSON.stringify(getdata));
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    }
  },
  methods: {
    //关联价格-添加事件
    addRechargeEvent(fromdata, times) {
      if (times) {
        if (fromdata.recharge.length < times) {
          var oneAddChat = {
            addisshow: false,
            disabled: true,
          };
          // js中通过push向数组最后新增指定的元素
          fromdata.push(oneAddChat);
        } else {
          ElMessage({
            type: "warning",
            message: "最多增加" + times + "条！",
          });
        }
      } else {
        var oneAddChats = {
          addisshow: false,
          disabled: true,
        };
        // js中通过push向数组最后新增指定的元素
        fromdata.push(oneAddChats);
      }
    },
    //关联价格-删除事件
    delRechargeEvent(ide, formdata) {
      console.log("获取的删除id为：" + ide);
      // js中通过splice删除指定的元素
      formdata.splice(ide, 1);
    },
    //用于下拉框-点击&选择事件-控制框是否可选择
    finduserSaleList(data, ldex) {
      if (data.child) {
        this.ruleForm.catefile[ldex].disabled = false;
        this.gettypechild = data.child;
      } else {
        this.ruleForm.catefile[ldex].disabled = true;
      }
    },
    // 多级联选-点击事件
    handleChange(value) {
      console.log(value);
    },
    // 提交-审核通过
    submitForm(formName) {
      console.log(this.$refs[formName].model);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("success submit!!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //提交-审核拒绝
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  components: {
    mainone,
    mainimgesup,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}
</style>