<template>
    <div class="flex-box">
        <div style="flex: 2; padding-right: 40px; border-right: 1px solid #e7e7e7">
            <el-form ref="formRef" :model="form" :rules="rules" label-width="140px">
                <el-form-item label="公众号名称：" prop="office_config_id">
                    <el-select v-model="form.office_config_id" placeholder="请选择" style="width: 100%">
                        <el-option :label="item.content.name" :value="item.id" v-for="(item, index) in office_config_option" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模板ID：" prop="template_id">
                    <el-input v-model="form.template_id" clearable maxlength="240" />
                </el-form-item>
                <el-form-item :label="item + '：'" :prop="index" v-for="(item, index) in active_info_option" :key="index" :rules="rules.keyword">
                    <el-input v-model="form[index]" clearable maxlength="240" />
                </el-form-item>
                <el-form-item label="模板跳转类型：" prop="jump_type">
                    <el-radio-group v-model="form.jump_type" class="ml-4">
                        <el-radio label="1">网页</el-radio>
                        <el-radio label="2">小程序</el-radio>
                    </el-radio-group>
                    <div v-if="form.jump_type == 1" class="flex-box-little">
                        <el-form-item label="地址：" prop="jump_content.url" :rules="rules.url" label-width="80px" label-position="left">
                            <el-input v-model="form.jump_content.url" clearable maxlength="240" />
                        </el-form-item>
                    </div>
                    <div v-if="form.jump_type == 2" class="flex-box-little">
                        <el-form-item style="margin-bottom: 20px" label="小程序AppID：" prop="jump_content.appid" :rules="rules.appid" label-width="160px" label-position="left">
                            <el-input v-model="form.jump_content.appid" clearable maxlength="240" />
                        </el-form-item>
                        <el-form-item label="小程序页面路径：" prop="jump_content.pagepath" :rules="rules.pagepath" label-width="160px" label-position="left">
                            <el-input v-model="form.jump_content.pagepath" clearable maxlength="240" />
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="状态：" prop="status">
                    <el-radio-group v-model="form.status" class="ml-4">
                        <el-radio label="1">开启</el-radio>
                        <el-radio label="-1">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="width: 200px" @click="submitEvent('formRef')" :disabled="button_disable">保存</el-button>
                    <el-button
                        @click="
                            () => {
                                this.$emit('request', false)
                                this.$refs['formRef'].resetFields()
                            }
                        "
                        >取消</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
        <!-- 模板 -->
        <div style="flex: 1; padding-left: 20px; line-height: 28px">
            <div class="model-box">
                <div><h2>打印完成通知</h2></div>
                <div>
                    <div><span>门店名称：</span><span>XXX门店</span></div>
                    <div><span>订单编号：</span><span>169476912984276</span></div>
                    <div><span>订单时间：</span><span>2023-09-15 17:15:30</span></div>
                    <div v-for="(item, index) in active_info_option" :key="index" class="no-warp-1">
                        <span>{{ item }}：</span>
                        <span>{{ form[index] }}</span>
                    </div>
                </div>
                <div class="model-box-footer">
                    <div>
                        <el-image src="./yingdaoicon.png" style="width: 40px; vertical-align: middle"></el-image>
                    </div>
                    <div>
                        <div>
                            <span>打印</span>
                            <el-divider direction="vertical" style="background-color: #d8d8d8 !important" />
                            <span>打印文库</span>
                        </div>
                        <div><span>相片证件照</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'backNewsSetAddOrEdit',
    inject: ['reload'], //刷新引用
    props: {
        formData: {
            type: Object,
            default() {
                return {
                    id: '',
                    office_config_id: '',
                    template_id: '',
                    keyword4: '',
                    jump_type: '1',
                    jump_content: { url: '' },
                    status: ''
                }
            }
        },
        activeInfoOption: {
            type: Object,
            default() {
                return {}
            }
        },
        officeConfigOption: {
            type: Array,
            default() {
                return []
            }
        }
    },
    watch: {
        formData: {
            handler(newVal) {
                this.form = this.deepcopy(newVal)
            },
            deep: true,
            immediate: true
        },
        activeInfoOption: {
            handler(newVal) {
                this.active_info_option = this.deepcopy(newVal)
            },
            deep: true,
            immediate: true
        },
        officeConfigOption: {
            handler(newVal) {
                this.office_config_option = this.deepcopy(newVal)
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            form: {
                id: '',
                office_config_id: '',
                template_id: '',
                keyword4: '',
                jump_type: '1',
                jump_content: { url: '' },
                status: ''
            },
            rules: {
                keyword: [
                    {
                        required: true,
                        message: '必填项不能为空',
                        trigger: 'blur'
                    }
                ],
                office_config_id: [
                    {
                        required: true,
                        message: '公众号名称不能为空',
                        trigger: 'blur'
                    }
                ],
                template_id: [
                    {
                        required: true,
                        message: '模板ID为空',
                        trigger: 'blur'
                    }
                ],
                jump_type: [
                    {
                        required: true,
                        message: '跳转类型不能为空',
                        trigger: 'blur'
                    }
                ],
                url: [
                    {
                        required: true,
                        message: '地址不能为空',
                        trigger: 'blur'
                    }
                ],
                appid: [
                    {
                        required: true,
                        message: '小程序AppID不能为空',
                        trigger: 'blur'
                    }
                ],
                pagepath: [
                    {
                        required: true,
                        message: '小程序页面路径不能为空',
                        trigger: 'blur'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '状态不能为空',
                        trigger: 'blur'
                    }
                ]
            },

            // 活的字段集
            active_info_option: {},

            // 公众号名称下拉
            office_config_option: [],

            // 前端防抖
            button_disable: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')) //获取权限
        }
    },
    methods: {
        // 深拷贝
        deepcopy(obj, cache = []) {
            function find(list, f) {
                return list.filter(f)[0]
            }

            // just return if obj is immutable value
            if (obj === null || typeof obj !== 'object') {
                return obj
            }

            // if obj is hit, it is in circular structure
            const hit = find(cache, (c) => c.original === obj)
            if (hit) {
                return hit.copy
            }

            const copy = Array.isArray(obj) ? [] : {}
            // put the copy into cache at first
            // because we want to refer it in recursive deepCopy
            cache.push({
                original: obj,
                copy
            })

            Object.keys(obj).forEach((key) => {
                copy[key] = this.deepcopy(obj[key], cache)
            })

            return copy
        },
        // 提交-保存
        submitEvent(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.button_disable = true
                    axios
                        .post('/admin/office/replyMsgEdit', getdatas, {
                            headers: {
                                Authentication: localStorage.getItem('token')
                            }
                        })
                        .then((response) => {
                            if (response.data.code == 0) {
                                ElMessage({
                                    type: 'success',
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.button_disable = false
                                        this.reload()
                                    }
                                })
                            } else {
                                this.button_disable = false
                            }
                        })
                        .catch(function (error) {
                            this.button_disable = false
                        })
                } else {
                    return false
                }
            })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.$router.push('/').catch((error) => error)
        }
    },
    components: {}
})
</script>
<style scoped>
.flex-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 20px;
}

.flex-box-little {
    padding: 10px 10px 20px;
    border: 1px dashed var(--el-color-primary);
    border-radius: 4px;
}

.model-box {
    border-radius: 2px;
    border: 1px solid #f8f8f8;
    border-radius: 4px;
    padding: 10px 20px 20px;
    box-shadow: 6.7px 6.7px 65.7px rgba(0, 0, 0, 0.045), 22.3px 22.3px 63.6px rgba(0, 0, 0, 0.026), 100px 100px 80px rgba(0, 0, 0, 0.07);
}

.model-box-footer {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e7e7e7;
    line-height: 26px;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
}

.no-warp-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
</style>
