<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <div>
      <!--标题-->
      <div class="box"  >
        <div class="box_title"  >
          <div class="box_title_font">添加分类</div>
          <div class="box_title_button">
            <!--可添加按钮-->
            <!--<el-button type="primary" class="max_search" @click="exportDatas(mainthreetableData)">导出数据</el-button>-->
          </div>
        </div>
        <!--内容-->
        <div class="box_content">
          <el-row style="margin-top: 20px">
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="7">
              <div style="width: 100%"></div>
            </el-col>
            <!--主表样式-->
            <el-col :span="24" :xs="24" :sm="24" :lg="9">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="120px"
                class="demo-ruleForm"
              >
                <el-form-item label="分类名称：" prop="cate_name">
                  <el-input v-model="ruleForm.cate_name"></el-input>
                </el-form-item>
                <!-- 上级分类 -->
                <el-form-item label="上级分类：">
                  <el-row :gutter="10" style="margin-top: 10px">
                    <el-col :span="24" :xs="24" :sm="24" :lg="12">
                      <el-select
                        v-model="ruleForm.version"
                        placeholder="请选择"
                      >
                        <el-option
                          :label="getsales.cate_name"
                          :value="getsales.id"
                          v-for="(getsales, chicundex) in this.gettype"
                          :key="chicundex"
                          @click="finduserSaleList(getsales)"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <!-- 子分类-多级联选 -->
                    <!-- :props="{value:'id',label:'name'}" 自定义字段-->
                    <el-col :span="24" :xs="24" :sm="24" :lg="12">
                      <el-cascader
                        v-model="ruleForm.version_data"
                        :options="gettypechild"
                        :show-all-levels="false"
                        :props="{
                          value: 'id',
                          label: 'cate_name',
                          children: 'child',
                          checkStrictly: true,
                        }"
                        clearable
                        :disabled="ruleForm.disabled"
                        @change="handleChange"
                      ></el-cascader>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="分类图标：" prop="icon">
                  <mainimgesup
                    v-model="ruleForm.sourcefile"
                    :myimgesup="myimgesup"
                    :myimgesupisshow="myimgesupisshow"
                  ></mainimgesup>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                  <el-input
                    v-model="ruleForm.sort"
                    placeholder="数字越大排序越高"
                  ></el-input>
                </el-form-item>
                <el-form-item label="是否显示：" prop="status">
                  <el-radio-group v-model="ruleForm.status">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm('ruleForm')"
                    style="width: 40%; margin-right: 10px"
                    >确认
                  </el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <!--占位符-->
            <el-col :span="24" :xs="24" :sm="24" :lg="8">
              <div style="width: 100%"></div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import mainimgesup from "@/components/Mainimgesup.vue";
import { ref } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";

export default {
  name: "vuseradd",
  data() {
    return {
      mainonebread: [
        {
          name: "教育",
        },
        {
          name: "文档管理",
        },
        {
          name: "文档分类",
          path: "/fileclassify",
        },
        {
          name: "添加分类",
          path: "/fileclassifyadd",
        },
      ], // 当前页-【面包屑导航】

      myimgesupisshow: true, //form表单-imgesuploade上传图片
      myimgesup: {
        uploadsize: 1, //选择上传图片的展现框-1表示小框，2表示中等框，3表示大框
        ismust: true, //是否必填，true表示必填
        title: "附件：",
        tips: "",
      },

      gettype: [], //分类-平台
      gettypechild: [], // 分类-子分类
      ruleForm: {
        cate_name: "",
        version: "",
        version_data: "",
        disabled: true,
        icon: "",
        sort: "",
        status: ref("1"),
      }, //form表单
      rules: {
        cate_name: [
          {
            required: true,
            message: "分类名称为必填项",
            trigger: "blur",
          },
        ],
      }, //form表单-必填规则
      loading: false,
      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token

      data: [],
    };
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      //通过API获取配置数据-无限级分类列表
      axios
        .post(
          "/admin/SharePrintSource/sourceCateInfo",
          {
            id: -1,
          },
          {
            headers: {
              Authentication: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          var getdata = response.data;
          this.gettype = getdata;
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    }
  },
  mounted() {},
  methods: {
    //用于下拉框-点击&选择事件-控制框是否可选择
    finduserSaleList(data) {
      if (data.child) {
        this.ruleForm.disabled = false;
        this.gettypechild = data.child;
      } else {
        this.ruleForm.disabled = true;
        this.ruleForm.version_data = "";
      }
    },
    //提交form表单-并判断是否必填
    submitForm(formName) {
      console.log(
        "this.$refs[formName].model is :" +
          JSON.stringify(this.$refs[formName].model)
      );
      var getdatas = this.$refs[formName].model;
      var pid = "";
      if (getdatas.version_data === "") {
        pid = getdatas.version;
      } else {
        pid = getdatas.version_data.pop();
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(
              "/admin/SharePrintSource/addCate",
              {
                pid: pid,
                cate_name: getdatas.cate_name,
                status: getdatas.status,
                sort: getdatas.sort,
                icon: getdatas.icon,
              },
              {
                headers: {
                  Authentication: localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.code === 0) {
                ElMessage({
                  type: "success",
                  message: "提交成功！",
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //重置form表单中填写的数据
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  components: {
    mainone,
    mainimgesup,
  },
};
</script>

<style scoped>
.box {
  background-color: #ffffff;
  border-radius: 4px;
  color: #333333;
  padding: 10px 20px;
}

.box_title {
  line-height: 40px;
  height: 40px;
}

.box_title_font {
  float: left;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.box_title_button {
  float: right;
  font-weight: 400;
  font-size: 14px;
  color: #94a3d2;
}

.box_title_button:hover {
  cursor: pointer;
}

.box_content {
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
}
</style>