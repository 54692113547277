<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <div v-loading="loading" element-loading-text="正在飞速加载中...">
            <!--标题-->
            <div class="box"  >
                <div class="box_title"  >
                    <div class="box_title_font">编辑业务用户</div>
                    <div class="box_title_button"></div>
                </div>
                <!--内容-->
                <div class="box_content">
                    <el-row style="margin-top: 20px">
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="7">
                            <div style="width: 100%"></div>
                        </el-col>
                        <!--主表样式-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="9">
                            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
                                <el-form-item label="登录用户名：" prop="username">
                                    <el-input v-model="ruleForm.username" clearable maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="姓名：" prop="truename">
                                    <el-input v-model="ruleForm.truename" clearable maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="所属区域：" prop="area">
                                    <el-input v-model="ruleForm.area" clearable maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item label="直属上级：">
                                    <div>
                                        <treeCascaderBox
                                            :ModelValue="ruleForm.pid"
                                            :Placeholder="'请选择直属上级'"
                                            :Options="pid_options"
                                            :Props="pid_options_props"
                                            @changedatas="changedatasEvent"
                                        />
                                    </div>
                                </el-form-item>
                                <el-form-item label="登录密码：">
                                    <el-input
                                        v-model="ruleForm.pass"
                                        type="pass"
                                        autocomplete="off"
                                        show-password
                                        clearable
                                        maxlength="50"
                                        @blur="inputPassOne"
                                        @clear="clearPassOne"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="确认密码：">
                                    <el-input
                                        v-model="ruleForm.checkPass"
                                        type="pass"
                                        autocomplete="off"
                                        show-password
                                        clearable
                                        maxlength="50"
                                        @input="inputPassCheck"
                                        @blur="blurPassCheck"
                                        @clear="clearPassCheck"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="备注信息：" prop="remark">
                                    <el-input v-model="ruleForm.remark" type="textarea"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitForm('ruleForm')" :disabled="mydisabled" style="width: 40%; margin-right: 10px">确认 </el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <!--占位符-->
                        <el-col :span="24" :xs="24" :sm="24" :lg="8">
                            <div style="width: 100%"></div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default {
    name: 'vuseradd',
    inject: ['reload'],
    data() {
        return {
            mainonebread: [
                {
                    name: '权限'
                },
                {
                    name: '权限管理'
                },
                {
                    name: '业务用户管理',
                    path: '/serviceuser'
                },
                {
                    name: '编辑业务用户'
                }
            ], // 当前页-【面包屑导航】

            ruleForm: {
                username: '',
                truename: '',
                area: '',
                pid: '',
                pass: '',
                checkPass: '',
                remark: ''
            }, //form表单
            rules: {
                username: [
                    {
                        required: true,
                        message: '登录用户名为必填项',
                        trigger: 'blur'
                    }
                ],
                truename: [
                    {
                        required: true,
                        message: '姓名为必填项',
                        trigger: 'blur'
                    }
                ],
                area: [
                    {
                        required: true,
                        message: '所属区域为必选项',
                        trigger: 'blur'
                    }
                ],
                pid: [
                    {
                        required: false,
                        message: '直属上级为必选项',
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '',
                        trigger: 'blur'
                    }
                ]
            }, //form表单-必填规则

            pid_options_props: { value: 'id', label: 'truename', children: 'child', checkStrictly: true },
            pid_options: [], // 直属上级id下拉

            loading: false,
            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token

            mydisabled: false
        }
    },
    methods: {
        //选择上级
        changedatasEvent(data) {
            console.log(data)
            this.ruleForm.pid = data
        },
        // 填写密码
        inputPassOne() {
            if (this.ruleForm.checkPass != '' && this.ruleForm.pass != '') {
                if (this.ruleForm.pass != this.ruleForm.checkPass) {
                    ElMessage({
                        type: 'error',
                        message: '登录密码与确认密码不同！'
                    })

                    return true
                }
            }
        },
        // 清空密码
        clearPassOne() {
            this.ruleForm.pass = ''
            this.ruleForm.checkPass = ''
        },
        // 确认密码
        inputPassCheck() {
            if (this.ruleForm.pass == '') {
                ElMessage({
                    type: 'error',
                    message: '请先输入登录密码！'
                })
                setTimeout(() => {
                    this.ruleForm.checkPass = ''
                }, 100)
            }
        },
        // 确认密码-blur
        blurPassCheck() {
            if (this.ruleForm.checkPass != '' && this.ruleForm.pass != '') {
                if (this.ruleForm.pass != this.ruleForm.checkPass) {
                    ElMessage({
                        type: 'error',
                        message: '登录密码与确认密码不同！'
                    })
                    return true
                }
            }
            if (this.ruleForm.pass != '' && this.ruleForm.checkPass == '') {
                ElMessage({
                    type: 'error',
                    message: '请输入确认密码！'
                })
                return true
            }
        },
        // 清空确认密码
        clearPassCheck() {
            this.ruleForm.checkPass = ''
        },

        //提交form表单-并判断是否必填
        submitForm(formName) {
            var getdatas = this.$refs[formName].model
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let istrue = false
                    // 校验-是否有登录密码
                    if (getdatas.pass != '' || (getdatas.pass == '' && getdatas.checkPass != '')) {
                        istrue = this.inputPassCheck()
                        istrue = this.blurPassCheck()
                    }

                    if (!istrue) {
                        this.endPostAxios(getdatas)
                    }
                } else {
                    return false
                }
            })
        },
        // 最终提交表单接口
        endPostAxios(getdatas) {
            // 前端防抖
            this.mydisabled = true

            axios
                .post(
                    '/admin/winUser/userEdit',
                    {
                        id: this.$route.query.id,
                        username: getdatas.username,
                        truename: getdatas.truename,
                        area: getdatas.area,
                        pid: Array.isArray(getdatas.pid) ? getdatas.pid.slice(-1)[0] : 0,
                        password: getdatas.pass,
                        confirm_password: getdatas.checkPass,
                        remark: getdatas.remark
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    if (response.data.code === 0) {
                        ElMessage({
                            type: 'success',
                            message: '提交成功',
                            duration: 1000,
                            onClose: () => {
                                // this.reload(); //刷新
                                this.$router.go(-1) // 返回上一页

                                setTimeout(() => {
                                    this.mydisabled = false
                                }, 100)
                            }
                        })
                    } else {
                        this.mydisabled = false
                    }
                })
                .catch(function(error) {
                    console.log(error)
                    this.mydisabled = false
                })
        },
        //重置form表单中填写的数据
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.ruleForm.pass = ''
            this.ruleForm.checkPass = ''
            this.ruleForm.pid = []
        },
        finduserPidOptions() {
            axios
                .post(
                    '/admin/winUser/list',
                    {},
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    this.pid_options = response.data.result
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
        // // 递归刷id
        // getidsEvent(child, contrast, ids) {
        //     if (child) {
        //         for (let i = 0; i < child.length; i++) {
        //             let id = child[i].id
        //             if (id != contrast) {
        //                 ids.push(id)
        //                 let result = this.getidsEvent(child[i].child, contrast, ids)
        //                 // 递归中-数据的返回，这一步很重要！！！
        //                 if (result) {
        //                     return result
        //                 }
        //             } else {
        //                 ids.push(id)
        //                 return ids
        //             }
        //         }
        //     }
        // }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.loading = true
            this.finduserPidOptions()

            // 详情
            axios
                .post(
                    '/admin/winUser/Info',
                    {
                        id: this.$route.query.id
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem('token')
                        }
                    }
                )
                .then((response) => {
                    var routedatas = response.data.result
                    var ruleform = this.ruleForm
                    ruleform.username = routedatas.username //通过路由获取的登录用户名
                    ruleform.truename = routedatas.truename //通过路由获取姓名

                    // 反显-直属上级
                    var points = routedatas.select_win_user_ids
                        .split(',')
                        .map(Number)
                        .filter(Boolean)

                    if (points.length > 0) {
                        // 将上级值反向输出
                        let new_points = []
                        for (let i = points.length; i >= 0; i--) {
                            new_points.push(points[i])
                        }
                        ruleform.pid = new_points.filter((n) => n)
                    } else {
                        ruleform.pid = 0
                    }

                    ruleform.area = routedatas.area //所属区域
                    ruleform.pass = '' //登录密码
                    ruleform.checkPass = '' //确认密码
                    ruleform.remark = routedatas.remark //备注

                    this.loading = false
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    },
    components: {
        mainone
    }
}
</script>

<style scoped>
.box {
    background-color: #ffffff;
    border-radius: 4px;
    color: #333333;
    padding: 10px 20px;
}

.box_title {
    line-height: 40px;
    height: 40px;
}

.box_title_font {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.box_title_button {
    float: right;
    font-weight: 400;
    font-size: 14px;
    color: #94a3d2;
}

.box_title_button:hover {
    cursor: pointer;
}

.box_content {
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
}
</style>
